import React from 'react';

import Big from 'big.js';
import { OptionGroupData } from 'rc-select/lib/interface';
import styled from 'styled-components';

import { APIOrderRow, APITopic, APIWorkPackage } from '../../../../types/api';

import { highlightText } from '../../../../components/HighlightText';

import * as big from '../../../../utils/big';

import defaultTheme from '../../../../styles/theme';

type OrderRowDivProps = {
  width: number;
  index?: number;
};

export const OrderRowDiv = styled.div<OrderRowDivProps>`
  width: ${(props) => props.width}px;

  display: grid;

  overflow: hidden;

  justify-items: start;

  grid-template-columns: 1fr 6fr 1fr;
  grid-template-rows: 1.5rem;
`;

export const PriceText = styled.div`
  margin-left: ${({ theme: { margin } }) => margin[4]};
  padding: 0 ${(props) => props.theme.margin[4]};
  display: flex;
  color: ${(props) => props.theme.color.graphiteB57};
`;

const NumberSpan = styled.span`
  color: ${({ theme }) => theme.color.graphiteB57};
`;

export const OrderRowDescDiv = styled.div`
  height: 1.5rem;
  min-width: 0;

  display: flex;
  flex-direction: column;
  align-items: center;

  flex: 1;

  overflow: hidden;
`;

export const OrderRowText = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Paragraph = styled.p<OrderRowDivProps>`
  width: ${(props) => props.width}px;
  margin-block-start: ${(props) =>
    props.index === 0 ? props.theme.margin[24] : '0'};
  margin-block-end: ${(props) =>
    props.index === 0 ? props.theme.margin[24] : '0'};
`;

export const InfoSpanText = styled.span`
  margin-left: ${({ theme: { margin } }) => margin[4]};
  border-left: 1px solid ${(props) => props.theme.color.graphiteB76};
  padding: 0 ${(props) => props.theme.margin[4]};
  color: ${(props) => props.theme.color.graphiteB57};
`;

export const DescriptionText = styled.span`
  padding: 0 ${(props) => props.theme.margin[4]} 0 0;
`;

const StyledNewTopicDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export type Topic = APITopic & {
  allChecked: boolean;
  allDisabled: boolean;
  createNewTopic?: boolean;
};

export const newTopicId = 'newTopic-for-workPackage-';

export const orderRowStyle = {
  paddingLeft: '1rem',
};

export const createOptionsForTopics = (
  topics: Topic[],
  orderRows: APIOrderRow[],
  workPackages: APIWorkPackage[],
  widthForDropdown: number,
  newTopicText?: string,
  moreWorkSectionsText?: string,
  orderRowShortLabelFormat?: (orderRow: APIOrderRow) => string,
  orderRowValueFormat?: (orderRow: APIOrderRow) => string,
  searchString?: string,
  defaultValue?: string
): OptionGroupData[] => {
  const existingTopics = topics.map((topic) => {
    const rows = orderRows?.filter((row) => row.topicId === topic.id);

    const workPackage = workPackages?.filter(
      (row) => row.id === topic.workPackageId
    )[0];

    const options = rows.map((option) => {
      const value = orderRowValueFormat
        ? orderRowValueFormat(option)
        : `orderRow-${option.id}`;

      const isDefaultValue = defaultValue === value;

      return {
        value: value,
        title: `${option.rowNumber.toString()} ${
          option.description
        } ${big.priceFormat(option.unitPrice ?? new Big('0'))}/${option.unit} ${
          topic.name
        } ${workPackage ? `${workPackage.code} ${workPackage.name}` : null} `,
        label: (
          <OrderRowDiv width={widthForDropdown - 40}>
            <NumberSpan>
              {highlightText(option.rowNumber.toString(), searchString)}
            </NumberSpan>
            <OrderRowDescDiv>
              <OrderRowText>
                {highlightText(option.description, searchString)}
              </OrderRowText>
            </OrderRowDescDiv>
            <PriceText>
              {highlightText(
                `${big.priceFormat(option.unitPrice ?? new Big('0'))}/${option.unit}`,
                searchString
              )}
            </PriceText>
          </OrderRowDiv>
        ),
        shortlabel: orderRowShortLabelFormat
          ? orderRowShortLabelFormat(option)
          : option.rowNumber.toString(),
        style: isDefaultValue
          ? {
              ...orderRowStyle,
              background: defaultTheme.color['M3/ref/neutral/neutral90'],
            }
          : orderRowStyle,
      };
    });

    const group = {
      label: (
        <Paragraph width={widthForDropdown - 40}>
          <DescriptionText>
            {highlightText(topic.name, searchString)}
          </DescriptionText>
          <InfoSpanText>
            {workPackage ? (
              <>
                {highlightText(
                  `${workPackage.code} ${workPackage.name}`,
                  searchString
                )}
              </>
            ) : null}
          </InfoSpanText>
        </Paragraph>
      ),
      title: topic.name,
      shortlabel: `aihe ${topic.id}`,
      options,
      topicId: topic.id,
      workPackage: workPackage
        ? {
            code: workPackage.code,
            name: workPackage.name,
          }
        : undefined,
    };

    return group;
  });

  let newTopics: OptionGroupData[] = [];

  if (newTopicText && moreWorkSectionsText) {
    newTopics = workPackages.map((workPackage, index) => {
      const group = {
        label: (
          <>
            {index === 0 ? (
              <StyledNewTopicDiv>
                <Paragraph width={widthForDropdown - 40} index={0}>
                  {highlightText(
                    `---- ${moreWorkSectionsText} ----`,
                    searchString
                  )}
                </Paragraph>
                <Paragraph width={widthForDropdown - 40}>
                  <DescriptionText>
                    {highlightText(
                      `+ ${workPackage.name} (${newTopicText})`,
                      searchString
                    )}
                  </DescriptionText>
                  <InfoSpanText>
                    {workPackage
                      ? highlightText(
                          `${workPackage.code} ${workPackage.name}`,
                          searchString
                        )
                      : null}
                  </InfoSpanText>
                </Paragraph>
              </StyledNewTopicDiv>
            ) : (
              <Paragraph width={widthForDropdown - 40}>
                <DescriptionText>
                  {highlightText(
                    `+ ${workPackage.name} (${newTopicText})`,
                    searchString
                  )}
                </DescriptionText>
                <InfoSpanText>
                  {workPackage
                    ? highlightText(
                        `${workPackage.code} ${workPackage.name}`,
                        searchString
                      )
                    : null}
                </InfoSpanText>
              </Paragraph>
            )}
          </>
        ),
        title: `+ ${workPackage.name} (${newTopicText})`,
        shortlabel: `workpackage ${workPackage.id}`,
        options: [],
        topicId: null,
        workPackage: workPackage
          ? {
              code: workPackage.code,
              name: workPackage.name,
              id: workPackage.id,
            }
          : undefined,
      };

      return group;
    });
  }

  return [...existingTopics, ...newTopics];
};

interface CreateOptionsParams {
  topics: Topic[];
  orderRows: APIOrderRow[];
  projectWorkPackages: APIWorkPackage[];
  widthForDropdown: number;
  newTopicText?: string;
  moreWorkSectionsText?: string;
  searchString?: string;
  notSelectedLabel: string;
  newOrderRowsLabel: string;
  newOrderRowShortLabel: string;
  defaultValue?: string;
}

export const createOptions = ({
  topics,
  orderRows,
  projectWorkPackages,
  widthForDropdown,
  newTopicText,
  moreWorkSectionsText,
  searchString,
  notSelectedLabel,
  newOrderRowsLabel,
  newOrderRowShortLabel,
  defaultValue,
}: CreateOptionsParams): {
  topicOptions: OptionGroupData[];
  optionsWithNewRow: OptionGroupData[];
} => {
  const topicOptions = createOptionsForTopics(
    topics,
    orderRows,
    projectWorkPackages,
    widthForDropdown,
    newTopicText,
    moreWorkSectionsText,
    undefined,
    undefined,
    searchString,
    defaultValue
  );

  const optionsWithNewRow = addOptionsForNoneAndNew(
    topicOptions,
    notSelectedLabel,
    newOrderRowsLabel,
    newOrderRowShortLabel,
    widthForDropdown,
    searchString
  );

  return { topicOptions, optionsWithNewRow };
};

export const addOptionsForNoneAndNew = (
  topicOptions: OptionGroupData[],
  notSelectedLabelText: string,
  newOrderRowLabelText: string,
  newOrderRowLabelTextShort: string,
  widthForDropdown: number,
  searchString?: string
): OptionGroupData[] => [
  {
    options: [
      {
        value: 'NONE',
        title: notSelectedLabelText,
        label: notSelectedLabelText,
        shortlabel: '-',
      },
    ],
  },
  ...topicOptions.map((group) => {
    const newRowOption = {
      value:
        group.topicId !== null
          ? `newRowForTopic-${group.topicId}`
          : `newRowForWorkPackage-${group?.workPackage?.id}`,
      title: `${newOrderRowLabelText} ${group.title} ${
        group.workPackage
          ? `${group.workPackage.code} ${group.workPackage.name}`
          : null
      }`,
      label: (
        <OrderRowDiv width={widthForDropdown - 40}>
          <span>{highlightText('+', searchString)}</span>
          <OrderRowDescDiv>
            <OrderRowText>
              {highlightText(newOrderRowLabelText, searchString)}
            </OrderRowText>
          </OrderRowDescDiv>
        </OrderRowDiv>
      ),
      shortlabel: newOrderRowLabelTextShort,
      style: orderRowStyle,
    };

    return { ...group, options: [...group.options, newRowOption] };
  }),
];

interface LineItemInfo {
  orderRowId: string;
  netPrice: Big;
  unit: string;
}

export const optionsWithNewRowForLineItem = (
  notSelectedLabelText: string,
  options: OptionGroupData[],
  newOrderRowLabelText: string,
  lineItemInfo: LineItemInfo,
  widthForDropdown: number,
  searchString: string,
  newOrderRowLabelTextShort: string
): OptionGroupData[] => {
  return [
    {
      options: [
        {
          value: 'NONE',
          title: notSelectedLabelText,
          label: notSelectedLabelText,
          shortlabel: '-',
        },
      ],
    },
    ...options.map((group) => {
      const newRowOption = {
        value:
          group.topicId !== null
            ? `newRowForTopic-${group.topicId}`
            : `newRowForWorkPackage-${group?.workPackage?.id}`,
        title: `${newOrderRowLabelText} ${group.title} ${
          group.workPackage
            ? `${group.workPackage.code} ${group.workPackage.name}`
            : null
        } ${big.priceFormat(lineItemInfo.netPrice ?? new Big('0'))}/${
          lineItemInfo.unit
        }`,
        label: (
          <OrderRowDiv width={widthForDropdown - 40}>
            <PlusSpan>+</PlusSpan>
            <OrderRowDescDiv>
              <OrderRowText>
                {highlightText(newOrderRowLabelText, searchString)}
              </OrderRowText>
            </OrderRowDescDiv>
            <PriceText>
              {highlightText(
                `${big.priceFormat(lineItemInfo.netPrice ?? new Big('0'))}/${
                  lineItemInfo.unit
                }`,
                searchString
              )}
            </PriceText>
          </OrderRowDiv>
        ),
        shortlabel: newOrderRowLabelTextShort,
        style: orderRowStyle,
      };

      return { ...group, options: [...group.options, newRowOption] };
    }),
  ];
};

const PlusSpan = styled.span`
  color: ${({ theme }) => theme.color.graphiteB57};
`;
