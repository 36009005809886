import Big from 'big.js';
import { Reducer } from 'redux';

import { ID } from '../../types/general';

import {
  assertActionPayloadIsNotApiUpdatedEntities,
  Selector,
  isUpdatedEntitiesActionType,
} from './utils';
import * as api from '../../utils/api';
import { isDefined } from '../../utils/general';
import normalizeBy from '../../utils/normalizeBy';
import * as remoteData from '../../utils/remoteData';
import { isSuccess } from '../../utils/remoteData';

import { ActionTypes } from '../actionTypes';
import { getInvoiceHeaderById } from './invoiceHeader';

import { AppState } from '.';

export interface InvoiceLine {
  id: ID;
  purchaseInvoiceLineExternalId: string;
  rowIndex: number;
  purchaseInvoiceExternalId: string;
  orderRowId: ID | null;
  arrivalRowId: ID | null;
  lineType: string;
  purchaseOrderNumber: string | null;
  purchaseOrderLineNumber: string;
  productCode: string | null;
  productName: string | null;
  quantity: Big;
  unit: string;
  netPrice: Big;
  netTotal: Big;
  grossPrice: Big;
  grossTotal: Big;
  taxPercent: Big;
  taxSum: Big;
  discountSum: Big;
  discountPercent: Big;
  additionalCostName: string | null;
  additionalCostKeyword: string | null;
  createdAt: Date;
  updatedAt: Date;
}

type Err = api.BackendError | undefined;

export type InvoiceLineState = {
  requests: Partial<Record<string, remoteData.RemoteData<undefined, Err>>>;
  convertRequests: Partial<Record<string, remoteData.RemoteData<string, Err>>>;
  data: Partial<Record<string, InvoiceLine>>;
};

const initialState: InvoiceLineState = {
  requests: {},
  convertRequests: {},
  data: {},
};

const invoiceLineReducer: Reducer<InvoiceLineState, ActionTypes> = (
  state = initialState,
  action
): InvoiceLineState => {
  switch (action.type) {
    case 'GET_INVOICE_LINES_STARTED': {
      const { orderId } = action.payload;
      const requests = { ...state.requests, [orderId]: remoteData.loading };

      return {
        ...state,
        requests,
      };
    }
    case 'GET_INVOICE_LINES_FAILURE': {
      const { orderId, error } = action.payload;
      const requests = { ...state.requests, [orderId]: remoteData.fail(error) };

      return { ...state, requests };
    }
    case 'GET_INVOICE_LINES_SUCCESS': {
      const { orderId, invoiceLines } = action.payload;

      const requests = {
        ...state.requests,
        [orderId]: remoteData.succeed(undefined),
      };

      const data = {
        ...state.data,
        ...normalizeBy('id', invoiceLines),
      };

      return {
        convertRequests: state.convertRequests,
        requests,
        data,
      };
    }
    case 'PUT_INVOICE_LINES_CONVERT_STARTED': {
      const { requestId } = action.payload;

      return {
        ...state,
        convertRequests: {
          ...state.convertRequests,
          [requestId]: remoteData.loading,
        },
      };
    }
    case 'PUT_INVOICE_LINES_CONVERT_FAILURE': {
      const { requestId, error } = action.payload;

      return {
        ...state,
        convertRequests: {
          ...state.convertRequests,
          [requestId]: remoteData.fail(error),
        },
      };
    }
    case 'PUT_INVOICE_LINES_CONVERT_SUCCESS': {
      const {
        purchaseInvoiceLines: updatedInvoiceLines = [],
        invoiceHeaderId,
      } = action.payload;

      if (updatedInvoiceLines.length === 0) {
        return state;
      }

      const data = { ...state.data };

      updatedInvoiceLines.forEach((invoiceLine) => {
        const { id } = invoiceLine;

        data[id] = invoiceLine;
      });

      const { requestId } = action.payload;

      return {
        ...state,
        convertRequests: {
          ...state.convertRequests,
          [requestId]: remoteData.succeed(invoiceHeaderId),
        },
        data,
      };
    }
  }

  if (isUpdatedEntitiesActionType(action)) {
    const { purchaseInvoiceLines: updatedInvoiceLines = [] } = action.payload;

    if (updatedInvoiceLines.length === 0) {
      return state;
    }

    const data = { ...state.data };

    updatedInvoiceLines.forEach((invoiceLine) => {
      const { id } = invoiceLine;

      data[id] = invoiceLine;
    });

    return {
      ...state,
      data,
    };
  }

  assertActionPayloadIsNotApiUpdatedEntities(action);

  return state;
};

export const selectOrderInvoiceLinesRequests =
  (orderId: string) =>
  ({
    invoiceLines: {
      requests: { [orderId]: requestState = remoteData.notAsked },
    },
  }: AppState) =>
    requestState;

export const getInvoiceLineById =
  (id: string) =>
  ({
    invoiceLines: {
      data: { [id]: invoiceLine },
    },
  }: AppState): InvoiceLine | undefined =>
    invoiceLine;

export const getInvoiceLinesByInvoiceHeaderId =
  (invoiceHeaderId: string | null) =>
  (appState: AppState): InvoiceLine[] => {
    if (!invoiceHeaderId) {
      return [] as InvoiceLine[];
    }

    const invoiceLines =
      getInvoiceHeaderById(invoiceHeaderId)(appState)?.purchaseInvoiceLineIds ??
      [];

    return invoiceLines
      .map((id) => getInvoiceLineById(id)(appState))
      .filter(isDefined);
  };

export function getConvertRequestState(
  requestId: string
): Selector<remoteData.RemoteData['kind']> {
  return ({
    invoiceLines: {
      convertRequests: { [requestId]: requestState = remoteData.notAsked },
    },
  }) => requestState.kind;
}

export const getInvoiceLineConverts =
  () =>
  ({ invoiceLines: { convertRequests } }: AppState) => {
    const successfulConverts = Object.values(convertRequests)
      .filter(isDefined)
      .filter(isSuccess)
      .map((entry) => entry.value);

    return successfulConverts;
  };

export default invoiceLineReducer;
