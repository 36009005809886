import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useFormik } from 'formik';
import styled, { css } from 'styled-components';
import * as Yup from 'yup';

import { InvoiceHeader } from '../../../../../store/reducers/invoiceHeader';
import {
  isSentSuccessfully,
  isLoading,
} from '../../../../../store/reducers/purchaseInvoiceReclamation';
import { getReclaimTemplateState } from '../../../../../store/reducers/purchaseInvoiceReclamationTemplate';
import {
  getUserEmail,
  selectUserLanguage,
} from '../../../../../store/reducers/user';

import { requestInvoiceAttachmentFilesForOrder } from '../../../../../store/actions/invoiceAttachmentFiles';
import { fileInvoiceComplaintHeader } from '../../../../../store/actions/purchaseInvoiceReclamation';
import { fetchReclamationTemplateFields } from '../../../../../store/actions/purchaseInvoiceReclamationTemplate';

import useRouteParams from '../../../../../hooks/useRouteParams';
import useTxt from '../../../../../hooks/useTxt';

import {
  ButtonGroup,
  SecondaryButton,
  PrimaryButton,
} from '../../../../../components/Buttons';
import FileUpload from '../../../../../components/FileUpload/FileUpload';
import Checkbox from '../../../../../components/Input/Checkbox';
import {
  RowSelectLabel,
  RowSelect,
} from '../../../../../components/Input/Select';
import TextAreaWithLabel from '../../../../../components/Input/TextAreaWithLabel';
import TextInput, {
  ErrorMessage,
} from '../../../../../components/Input/TextInput';
import { Spinner } from '../../../../../components/Loading';
import Modal, {
  Content,
  Footer,
  Header,
} from '../../../../../components/Modal/Modal';

type FileInvoiceComplaintModalProps = {
  invoiceHeader: InvoiceHeader;
  onClose: () => void;
};
interface FormValues {
  receiver: string;
  copyAddress: string;
  emailTitle: string;
  emailContent: string;
  reason: string;
  response: string;
}

export const FileInvoiceComplaintModal = ({
  onClose,
  invoiceHeader,
}: React.PropsWithChildren<FileInvoiceComplaintModalProps>) => {
  const { orderId } = useRouteParams();

  const [uploadData, setUploadData] = useState<{ files: File[] }>({
    files: [],
  });

  const [sendEmail, setSendEmail] = useState(true);

  const dispatch = useDispatch();
  const emailTemplate = useSelector(getReclaimTemplateState());
  const language = useSelector(selectUserLanguage) ?? '1';

  const isComplaintSent = useSelector(isSentSuccessfully(invoiceHeader.id));
  const isComplaLoading = useSelector(isLoading(invoiceHeader.id));

  const userEmail = useSelector(getUserEmail);

  const initialValues: FormValues = {
    receiver: '',
    copyAddress: userEmail,
    emailTitle: '',
    emailContent: '',
    reason: '',
    response: userEmail,
  };

  const receiverFieldRequired = useTxt(
    'order.receiveMode.invoiceCorrection.formField.required.receiver'
  );

  const responseFieldRequired = useTxt(
    'order.receiveMode.invoiceCorrection.formField.required.response'
  );

  const emailTitleFieldRequired = useTxt(
    'order.receiveMode.invoiceCorrection.formField.required.emailTitle'
  );

  const emailContentFieldRequired = useTxt(
    'order.receiveMode.invoiceCorrection.formField.required.emailContent'
  );

  const reasonFieldRequired = useTxt(
    'order.receiveMode.invoiceCorrection.formField.required.reason'
  );

  const maxLengthError = useTxt('common.maxCharacters', { value: 255 });

  const validateFormFields = sendEmail
    ? Yup.object().shape({
        receiver: Yup.string()
          .required(receiverFieldRequired)
          .max(255, maxLengthError),
        emailTitle: Yup.string()
          .required(emailTitleFieldRequired)
          .max(255, maxLengthError),
        copyAddress: Yup.string().max(255, maxLengthError),
        emailContent: Yup.string().required(emailContentFieldRequired),
        reason: Yup.string()
          .required(reasonFieldRequired)
          .max(255, maxLengthError),
        response: Yup.string()
          .required(responseFieldRequired)
          .max(255, maxLengthError),
      })
    : Yup.object().shape({
        receiver: Yup.string().max(255, maxLengthError),
        emailTitle: Yup.string().max(255, maxLengthError),
        emailContent: Yup.string().max(255, maxLengthError),
        reason: Yup.string()
          .required(reasonFieldRequired)
          .max(255, maxLengthError),
        response: Yup.string().max(255, maxLengthError),
      });

  const onSubmit = (values: FormValues) => {
    if (sendEmail) {
      dispatch(
        fileInvoiceComplaintHeader(
          invoiceHeader.id,
          {
            receiverEmail: values.receiver,
            ccEmail: values.copyAddress,
            responseEmail: values.response,
            reason: values.reason,
            emailTitle: values.emailTitle,
            emailMessage: values.emailContent,
          },
          uploadData.files,
          () => dispatch(requestInvoiceAttachmentFilesForOrder(orderId))
        )
      );
    } else {
      dispatch(
        fileInvoiceComplaintHeader(
          invoiceHeader.id,
          {
            receiverEmail: null,
            ccEmail: null,
            responseEmail: null,
            emailTitle: '',
            reason: values.reason,
            emailMessage: values.emailContent,
          },
          []
        )
      );
    }
  };

  const formik = useFormik<FormValues>({
    initialValues,
    validationSchema: validateFormFields,
    onSubmit,
    validateOnMount: true,
  });

  const {
    values,
    errors,
    handleChange,
    setFieldValue,
    isValid,
    isSubmitting,
    validateForm,
    handleSubmit,
  } = formik;

  useEffect(() => {
    if (emailTemplate.request.kind === 'NotAsked') {
      dispatch(fetchReclamationTemplateFields(language, invoiceHeader.id));
    }

    if (isComplaintSent) {
      onClose();
    }
  }, [
    emailTemplate.request.kind,
    dispatch,
    invoiceHeader.id,
    language,
    isComplaintSent,
    onClose,
  ]);

  const memoizedValidateForm = React.useCallback(validateForm, [validateForm]);

  useEffect(() => {
    memoizedValidateForm();
  }, [sendEmail, memoizedValidateForm]);

  const sendEmailToSupplierTxt = useTxt(
    'order.receiveMode.complaintModal.sendEmailToSupplier'
  );

  const supplierFieldPlaceholderTxt = useTxt(
    'order.receiveMode.complaintModal.formField.placeholder.supplier'
  );

  const emailCcFieldPlaceholderTxt = useTxt(
    'order.receiveMode.complaintModal.formField.placeholder.cc'
  );

  const responseFieldPlaceholderTxt = useTxt(
    'order.receiveMode.complaintModal.formField.placeholder.response'
  );

  const optionPlaceholderTxt = useTxt(
    'order.receiveMode.complaintModal.formField.placeholder.reason'
  );

  const emailTitleFieldPlaceholderTxt = useTxt(
    'order.receiveMode.complaintModal.formField.placeholder.emailTitle'
  );

  const emailContentFieldPlaceholderTxt = useTxt(
    'order.receiveMode.complaintModal.formField.placeholder.emailContent'
  );

  const reasonLabelTxt = useTxt('order.receiveMode.complaintModal.reason');

  const reasonOptions = emailTemplate.data.map((template) => template.reason);

  const modalTitleText = useTxt('order.receiveMode.complaintModal.title');

  const receiverTxt = useTxt('order.receiveMode.complaintModal.receiver');
  const copyTxt = useTxt('order.receiveMode.complaintModal.copyAddress');
  const emailTitleTxt = useTxt('order.receiveMode.complaintModal.emailTitle');

  const emailContentTxt = useTxt(
    sendEmail
      ? 'order.receiveMode.complaintModal.emailContent'
      : 'order.receiveMode.comment'
  );

  const cancelText = useTxt('common.cancel');
  const sendText = useTxt('common.send');
  const responseText = useTxt('order.receiveMode.complaintModal.response');

  const updateData = (newFiles: File[]) =>
    setUploadData({ ...uploadData, files: newFiles });

  const updateEmailFields = (value: number) => {
    if (sendEmail) {
      requestAnimationFrame(() => {
        setFieldValue('emailTitle', emailTemplate.data[value].title);
      });

      setFieldValue('emailContent', emailTemplate.data[value].message);
    }
  };

  const sendEmailCheckboxToggle = () => {
    setSendEmail((prev) => !prev);
  };

  return (
    <Modal onClose={onClose}>
      <FormContainer>
        <Header>
          <h2>
            {modalTitleText} | {invoiceHeader.supplierName}
          </h2>
        </Header>
        <form onSubmit={handleSubmit}>
          <StyledContent>
            <CheckboxDiv>
              <Label invalid={false}>{sendEmailToSupplierTxt}:</Label>
              <Checkbox
                checked={sendEmail}
                onChange={() => sendEmailCheckboxToggle()}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    e.stopPropagation();
                    e.preventDefault();
                    sendEmailCheckboxToggle();
                  }
                }}
              />
            </CheckboxDiv>
            {sendEmail ? (
              <>
                <TextInput
                  paddingBottom="0"
                  label={receiverTxt}
                  name="receiver"
                  value={values.receiver}
                  onChange={handleChange}
                  errorMessage={errors.receiver}
                  placeholder={supplierFieldPlaceholderTxt}
                />
                <TextInput
                  paddingBottom="0"
                  label={copyTxt}
                  name="copyAddress"
                  value={values.copyAddress}
                  onChange={handleChange}
                  errorMessage={errors.copyAddress}
                  placeholder={emailCcFieldPlaceholderTxt}
                />
                <TextInput
                  paddingBottom="0"
                  label={responseText}
                  name="response"
                  value={values.response}
                  onChange={handleChange}
                  errorMessage={errors.response}
                  placeholder={responseFieldPlaceholderTxt}
                />
              </>
            ) : null}
            <StyledRowSelectLabel htmlFor="reason-select">
              <Label invalid={!!errors.reason}>{reasonLabelTxt}</Label>
              <StyledRowSelect
                required
                name="reason"
                value={
                  values.reason !== ''
                    ? reasonOptions.indexOf(values.reason)
                    : ''
                }
                onChange={(event) => {
                  const indexNumber = parseInt(event.target.value, 10);
                  const selectedValue = reasonOptions[indexNumber];
                  setFieldValue('reason', selectedValue);
                  updateEmailFields(indexNumber);
                }}
                invalid={!!errors.reason}
              >
                <option value="" disabled>
                  {optionPlaceholderTxt}
                </option>
                {reasonOptions.map((reasonOption, index) => (
                  <option key={reasonOption} value={index}>
                    {reasonOption}
                  </option>
                ))}
              </StyledRowSelect>
              {errors.reason ? (
                <ErrorMessage>{errors.reason}</ErrorMessage>
              ) : null}
            </StyledRowSelectLabel>
            {sendEmail ? (
              <TextInput
                paddingBottom="1rem"
                label={emailTitleTxt}
                name="emailTitle"
                value={values.emailTitle}
                onChange={handleChange}
                errorMessage={errors.emailTitle}
                placeholder={emailTitleFieldPlaceholderTxt}
              />
            ) : null}
            <TextAreaWithLabel
              label={emailContentTxt}
              id="emailContent"
              value={values.emailContent}
              setFieldValue={(value) => setFieldValue('emailContent', value)}
              errorMessage={errors.emailContent}
              placeholder={emailContentFieldPlaceholderTxt}
            />
            {sendEmail ? (
              <FileUpload
                additionalInfoTextId="order.receiveMode.complaintModal.fileUpload.note"
                lowerHeight
                multiple
                updateFilesCb={updateData}
              />
            ) : null}
          </StyledContent>
          <Footer>
            <CenteredButtonGroup>
              <SecondaryButton type="button" onClick={onClose}>
                {cancelText}
              </SecondaryButton>
              <PrimaryButton type="submit" disabled={!isValid || isSubmitting}>
                {isComplaLoading ? <Spinner size="1rem" /> : sendText}
              </PrimaryButton>
            </CenteredButtonGroup>
          </Footer>
        </form>
      </FormContainer>
    </Modal>
  );
};

interface LabelProps {
  invalid: boolean;
}

const Label = styled.label<LabelProps>`
  font-weight: bold;
  ${(props) =>
    props.invalid &&
    css`
      color: red;
    `}
`;

const StyledRowSelectLabel = styled(RowSelectLabel)`
  margin-top: 1rem;
  display: block;
`;

const CheckboxDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
`;

interface StyledRowSelectProps {
  invalid: boolean;
}

const StyledRowSelect = styled(RowSelect)<StyledRowSelectProps>`
  margin-left: 0;
  padding: 0.5rem;
  width: 100%;

  ${(props) =>
    props.invalid &&
    css`
      border-color: red;
    `}
`;

const FormContainer = styled.div`
  box-shadow: 0px 4px 42px rgba(0, 0, 0, 0.25);
  width: 50vw;
  display: flex;
  flex-direction: column;
`;

const StyledContent = styled(Content)`
  padding: ${(props) => `${props.theme.margin[32]}`};
  max-height: calc(85vh - ${(props) => `${props.theme.margin[112]}`});
  min-height: auto;
  overflow-y: scroll;
`;

const CenteredButtonGroup = styled(ButtonGroup)`
  margin: ${({ theme }) => theme.margin[16]} 0;
  justify-content: center;
`;
