import { Reducer } from 'redux';

import { BackendError } from '../../../utils/api';
import * as remoteData from '../../../utils/remoteData';
import { Selector } from '../utils';

import { ActionTypes } from '../../actionTypes';

type RemoteData<A> = remoteData.RemoteData<A, BackendError | undefined>;

export type PossibleRevenueLinksState = Partial<
  Record<string, remoteData.RemoteData<string[], BackendError | undefined>>
>;

const initialState = {};

const possibleRevenueLinksReducer: Reducer<
  PossibleRevenueLinksState,
  ActionTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_POSSIBLE_ANALYSIS_LINKS_FOR_REVENUE_STARTED': {
      const { revenueId } = action.payload;

      return { ...state, [revenueId]: remoteData.loading };
    }
    case 'GET_POSSIBLE_ANALYSIS_LINKS_FOR_REVENUE_FAILURE': {
      const { revenueId, error } = action.payload;

      return { ...state, [revenueId]: remoteData.fail(error) };
    }
    case 'GET_POSSIBLE_ANALYSIS_LINKS_FOR_REVENUE_SUCCESS': {
      const { revenueId, analysisRowIds } = action.payload;

      return { ...state, [revenueId]: remoteData.succeed(analysisRowIds) };
    }
    case 'POST_ANALYSIS_ROW_SUCCESS':
    case 'DELETE_ANALYSIS_ROW_SUCCESS':
    case 'REMOVE_REVENUE_ANALYSIS_LINK_SUCCESS':
    case 'POST_REVENUE_ANALYSIS_LINK_SUCCESS':
      return initialState;
    default:
      return state;
  }
};

export default possibleRevenueLinksReducer;

export const getPossibleAnalysisRowIdsforRevenue: (
  revenueId: string
) => Selector<RemoteData<string[]>> =
  (revenueId) =>
  ({
    analysis: {
      possibleRevenueLinks: { [revenueId]: remoteRowIds = remoteData.notAsked },
    },
  }) =>
    remoteRowIds;
