import React from 'react';
import ReactTooltip from 'react-tooltip';

import styled from 'styled-components';

export interface TooltipProps {
  tip: string;
  disable?: boolean;
  place?: 'top' | 'bottom' | 'left' | 'right';
  children?: any;
  tipId?: string;
  className?: string;
  delayHide?: number;
  delayShow?: number;
  noWrap?: boolean;
}

const ToolTip = (props: TooltipProps) => {
  const {
    children,
    tip,
    place,
    disable,
    tipId,
    className,
    delayHide,
    delayShow,
    noWrap,
  } = props;

  const escapedTipId = tipId ? CSS.escape(tipId) : CSS.escape(`global_${tip}`);

  if (noWrap) {
    return (
      <NoWrapSpan data-tip={tip} data-for={escapedTipId}>
        {children}
        <StyledTooltip
          disable={disable}
          effect="solid"
          type="dark"
          place={place || 'top'}
          id={escapedTipId}
          className={className}
          delayHide={delayHide}
          delayShow={delayShow}
          role="tooltip"
          html
        />
      </NoWrapSpan>
    );
  }

  return (
    <span data-tip={tip} data-for={escapedTipId}>
      {children}
      <StyledTooltip
        disable={disable}
        effect="solid"
        type="dark"
        place={place || 'top'}
        id={escapedTipId}
        className={className}
        delayHide={delayHide}
        delayShow={delayShow}
        role="tooltip"
        html
      />
    </span>
  );
};

export const StyledTooltip = styled(ReactTooltip)`
  /* stylelint-disable selector-max-class -- disable temporary until good solution is found */
  &.unsettled-costs-tooltip {
    border: 1px dotted ${(props) => props.theme.color.amethystSmoke};
    border-bottom: 1px solid ${(props) => props.theme.color.pitch};

    padding: ${(props) => props.theme.margin[10]};

    background-color: ${(props) => props.theme.color.graphiteB97};

    text-align: left;
    color: ${(props) => props.theme.color.pitch};
    font-weight: 600;

    z-index: 2;
    /* stylelint-disable max-nesting-depth  -- disable temporary until good solution is found */
    /* stylelint-disable selector-max-type  -- disable temporary until good solution is found */
    & h3 {
      padding-top: ${(props) => props.theme.margin[2]};
      color: ${(props) => props.theme.color.amethystSmoke};
      font-weight: 700;
      font-size: ${(props) => props.theme.fontSize.base};
    }
  }

  &.invalid_indicator {
    border: 1px solid ${(props) => props.theme.color.graphiteB76};
    background-color: ${(props) => props.theme.color.white};
    color: ${(props) => props.theme.color.red};
  }
  &.hoverable-tooltip {
    max-width: 20vw;
    white-space: normal;
    cursor: text;
    pointer-events: auto;
    &:hover {
      opacity: 1;
      visibility: visible;
    }
  }

  &.compressed-tooltip {
    max-width: 15vw;

    background-color: ${(props) => props.theme.color.graphite};

    text-align: right;
    line-height: 0.875rem;
    font-weight: normal;
    white-space: normal;
  }

  &.line-break-compressed-tooltip {
    max-width: 15vw;

    background-color: ${(props) => props.theme.color.graphite};

    text-align: right;
    line-height: 0.875rem;
    font-weight: normal;
    white-space: pre-line;
  }

  &.wp-info-tooltip {
    box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.15);
    max-width: 12rem;
    background-color: ${(props) => props.theme.color.white};
    color: ${(props) => props.theme.color.graphite};
  }
`;

const NoWrapSpan = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export default ToolTip;
