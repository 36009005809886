import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Big from 'big.js';
import styled from 'styled-components';

import {
  getOrderSummary,
  getProjectOrders,
} from '../store/reducers/order/order';
import { getBillingFilteringActive } from '../store/reducers/ui';

import { fetchOrdersForProject } from '../store/actions';
import { uiStateToggleBillingProcurementAreas } from '../store/actions/ui';

import useRemoteData from '../hooks/useRemoteData';
import useRouteParams from '../hooks/useRouteParams';
import useTxt from '../hooks/useTxt';

import { IconInvoiceHeaders, IconActualCosts } from '../assets';

import { generateUrl, routes } from '../routes';

import { BigValue, BigPriceValue } from './BigValue';
import { BaseButton } from './Buttons';
import HTMLTooltip from './HTMLTooltip';
import { NumberIndicator } from './Navigation/Components';

type SummaryProps = {
  orderId?: string;
  projectLevelSummary?: boolean;
  documentType: 'invoice' | 'actualCost';
  tipId?: string;
  orderStatusFilterValues?: string[];
  isDisabled?: boolean;
};

const UnsettledCostsButton: React.FC<SummaryProps> = ({
  orderId,
  projectLevelSummary,
  documentType,
  tipId,
  orderStatusFilterValues,
  isDisabled,
}) => {
  const { projectId } = useRouteParams();

  const summary = useSelector(getOrderSummary(orderId ?? ''));

  const billingFilterIsActive = useSelector(getBillingFilteringActive);

  const filteredOrders =
    useRemoteData(
      getProjectOrders(
        projectId,
        billingFilterIsActive,
        orderStatusFilterValues
      ),
      fetchOrdersForProject(projectId)
    ) ?? [];

  const projectInvoicesUnsettledCount = filteredOrders.reduce(
    (previous, current) => previous.add(current.invoicesUnsettledCount),
    new Big(0)
  );

  const projectInvoicesUnsettledTotal = filteredOrders.reduce(
    (previous, current) => previous.add(current.invoicesUnsettledTotal),
    new Big(0)
  );

  const projectInvoiceComplaintsCount = filteredOrders.reduce(
    (previous, current) => previous.add(current.invoiceComplaintsCount),
    new Big(0)
  );

  const projectInvoiceComplaintsTotal = filteredOrders.reduce(
    (previous, current) => previous.add(current.invoiceComplaintsTotal),
    new Big(0)
  );

  const projectInvoiceCorrectionsCount = filteredOrders.reduce(
    (previous, current) => previous.add(current.invoiceCorrectionsCount),
    new Big(0)
  );

  const projectInvoiceCorrectionsTotal = filteredOrders.reduce(
    (previous, current) => previous.add(current.invoiceCorrectionsTotal),
    new Big(0)
  );

  const projectActualCostsPendingCount = filteredOrders.reduce(
    (previous, current) => previous.add(current.actualcostsPendingCount),
    new Big(0)
  );

  const projectActualCostsPendingTotal = filteredOrders.reduce(
    (previous, current) => previous.add(current.actualcostsPendingTotal),
    new Big(0)
  );

  if (projectLevelSummary) {
    return (
      <UnsettledButton
        isDisabled={isDisabled}
        orderId={orderId}
        invoicesUnsettledCount={projectInvoicesUnsettledCount}
        invoicesUnsettledTotal={projectInvoicesUnsettledTotal}
        invoiceComplaintsCount={projectInvoiceComplaintsCount}
        invoiceComplaintsTotal={projectInvoiceComplaintsTotal}
        invoiceCorrectionsCount={projectInvoiceCorrectionsCount}
        invoiceCorrectionsTotal={projectInvoiceCorrectionsTotal}
        actualCostsPendingCount={projectActualCostsPendingCount}
        actualCostsPendingTotal={projectActualCostsPendingTotal}
        documentType={documentType}
        tipId={tipId}
      />
    );
  }

  if (summary) {
    return (
      <UnsettledButton
        orderId={orderId}
        invoicesUnsettledCount={summary.invoicesUnsettledCount}
        invoicesUnsettledTotal={summary.invoicesUnsettledTotal}
        invoiceComplaintsCount={summary.invoiceComplaintsCount}
        invoiceComplaintsTotal={summary.invoiceComplaintsTotal}
        invoiceCorrectionsCount={summary.invoiceCorrectionsCount}
        invoiceCorrectionsTotal={summary.invoiceCorrectionsTotal}
        actualCostsPendingCount={summary.actualcostsPendingCount}
        actualCostsPendingTotal={summary.actualcostsPendingTotal}
        documentType={documentType}
        tipId={tipId}
      />
    );
  }

  return null;
};

type UnsettledButtonProps = {
  orderId: string | undefined;
  invoicesUnsettledCount: Big;
  invoicesUnsettledTotal: Big;
  invoiceComplaintsCount: Big;
  invoiceComplaintsTotal: Big;
  invoiceCorrectionsCount: Big;
  invoiceCorrectionsTotal: Big;
  actualCostsPendingCount: Big;
  actualCostsPendingTotal: Big;
  documentType: 'invoice' | 'actualCost';
  tipId?: string;
  isDisabled?: boolean;
};

const UnsettledButton = ({
  orderId,
  invoicesUnsettledCount,
  invoicesUnsettledTotal,
  invoiceComplaintsCount,
  invoiceComplaintsTotal,
  invoiceCorrectionsCount,
  invoiceCorrectionsTotal,
  actualCostsPendingCount,
  actualCostsPendingTotal,
  documentType,
  tipId,
  isDisabled,
}: UnsettledButtonProps) => {
  const { projectId, showTargetRows } = useRouteParams();
  const history = useHistory();
  const dispatch = useDispatch();

  // Project and procurementArea initialization checks are already being done
  // in ProjectRoutes (App.tsx)
  const navigateToReceivePage = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();

    if (orderId) {
      const nextUrl = generateUrl({
        route: routes.ORDER,
        projectId,
        orderId,
        viewMode: 'receive',
        subViewMode: 'invoices',
        showTargetRows,
      });

      history.push(nextUrl);
    } else {
      dispatch(uiStateToggleBillingProcurementAreas(''));
    }
  };

  const invoicesUnsettledText = useTxt('common.invoicesUnsettledCount');
  const invoiceCorrectionsText = useTxt('common.invoiceCorrectionsCount');
  const invoiceComplaintsText = useTxt('common.invoiceComplaintsCount');
  const otherExpensesPendingText = useTxt('common.actualcostsPendingCount');
  const openOrCloseText = useTxt('common.openOrClose');

  if (documentType === 'actualCost' && Number(actualCostsPendingCount) === 0) {
    return null;
  }

  if (
    documentType === 'invoice' &&
    Number(invoicesUnsettledCount) === 0 &&
    Number(invoiceCorrectionsCount) === 0 &&
    Number(invoiceComplaintsCount) === 0
  ) {
    return null;
  }

  return (
    <IconButtonContainer
      onClick={navigateToReceivePage}
      value={openOrCloseText}
      data-testid={
        documentType === 'actualCost'
          ? 'unhandled-actualcosts-container'
          : 'unhandled-invoices-container'
      }
      disabled={!!isDisabled}
    >
      <HTMLTooltip
        htmlTip={
          documentType === 'actualCost' ? (
            <CountAndTotalTable>
              <CountCell>
                <BigValue value={actualCostsPendingCount} decimals={0} />
                &nbsp;
                {otherExpensesPendingText}
              </CountCell>
              <TotalCell>
                <BigPriceValue value={actualCostsPendingTotal} />
              </TotalCell>
            </CountAndTotalTable>
          ) : (
            <CountAndTotalTable>
              {Number(invoicesUnsettledCount) !== 0 ? (
                <>
                  <CountCell>
                    <BigValue value={invoicesUnsettledCount} decimals={0} />
                    &nbsp;
                    {invoicesUnsettledText}
                  </CountCell>
                  <TotalCell>
                    <BigPriceValue value={invoicesUnsettledTotal} />
                  </TotalCell>
                </>
              ) : null}
              {Number(invoiceCorrectionsCount) !== 0 ? (
                <>
                  <CountCell>
                    <BigValue value={invoiceCorrectionsCount} decimals={0} />
                    &nbsp;
                    {invoiceCorrectionsText}
                  </CountCell>
                  <TotalCell>
                    <BigPriceValue value={invoiceCorrectionsTotal} />
                  </TotalCell>
                </>
              ) : null}
              {Number(invoiceComplaintsCount) !== 0 ? (
                <>
                  <CountCell>
                    <BigValue value={invoiceComplaintsCount} decimals={0} />
                    &nbsp;
                    {invoiceComplaintsText}
                  </CountCell>
                  <TotalCell>
                    <BigPriceValue value={invoiceComplaintsTotal} />
                  </TotalCell>
                </>
              ) : null}
            </CountAndTotalTable>
          )
        }
        tipId={tipId}
        className="unsettled-costs-tooltip"
        place="left"
      >
        <IconButtonImage
          src={
            documentType === 'actualCost' ? IconActualCosts : IconInvoiceHeaders
          }
          alt={
            documentType === 'actualCost'
              ? 'other expenses indicator'
              : 'invoices indicator'
          }
        />
        <NumberIndicator>
          {Number(
            documentType === 'actualCost'
              ? actualCostsPendingCount
              : invoicesUnsettledCount
                  .add(invoiceComplaintsCount)
                  .add(invoiceCorrectionsCount)
          )}
        </NumberIndicator>
      </HTMLTooltip>
    </IconButtonContainer>
  );
};

const CountAndTotalTable = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 0.4rem 1rem;
`;

const CountCell = styled.div``;

const TotalCell = styled.div`
  text-align: right;
  color: ${(props) => props.theme.color.darkpurple};
`;

const IconButtonContainer = styled(BaseButton)`
  position: relative;

  border-radius: 50%;
  border: 0;

  width: 1.5rem;
  height: 1.5rem;

  display: flex;
  justify-content: center;

  background: 'transparent';

  font-size: 0.625rem;
  text-align: center;
  line-height: 1.5rem;
  color: ${(props) => props.theme.color.darkpurple};
`;

const IconButtonImage = styled.img`
  padding-top: ${(props) => props.theme.margin[2]};
`;

export default UnsettledCostsButton;
