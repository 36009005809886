import React from 'react';

import Big from 'big.js';
import { OptionGroupData } from 'rc-select/lib/interface';
import styled, { css } from 'styled-components';

import { getOrderTopics } from '../../../../store/reducers/topic';
import { getProjectWorkPackages } from '../../../../store/reducers/workPackage';

import * as Actions from '../../../../store/actions';

import { APIOrderRow } from '../../../../types/api';
import { InvoiceLineSelectionPayload } from '../../../../types/general';

import useRemoteData from '../../../../hooks/useRemoteData';
import useTxt from '../../../../hooks/useTxt';

import DropDownSelect from '../../../../components/DropDownSelect';
import Tooltip from '../../../../components/Tooltip';

import { IconReceive } from '../../../../assets';

import { createOptions, optionsWithNewRowForLineItem } from './Options';
import defaultTheme from '../../../../styles/theme';

interface LineItemInfo {
  orderRowId: string;
  netPrice: Big;
  unit: string;
}

type Props = {
  orderRows: APIOrderRow[];
  lineItemInfo?: LineItemInfo;
  selectionState: InvoiceLineSelectionPayload | undefined;
  allowedUser: boolean;
  onChange: (selection: string, primary?: boolean) => void | undefined;
  widthForDropdown: number;
  alreadyAssigned: boolean;
  setSearchString?: (searchString: string) => void;
  projectId: string | null;
  orderId: string | null;
};

function convertStateToSelectionValue(
  selectionState: InvoiceLineSelectionPayload | undefined
): string | undefined {
  if (!selectionState) {
    return undefined;
  }

  if (selectionState.linkedOrderRowId) {
    return `orderRow-${selectionState.linkedOrderRowId}`;
  }

  if (selectionState.linkedTopicId) {
    return `newRowForTopic-${selectionState.linkedTopicId}`;
  }

  if (selectionState.workPackageId) {
    return `newRowForWorkPackage-${selectionState.workPackageId}`;
  }

  return undefined;
}

const OrderRowDropdown = ({
  orderRows,
  lineItemInfo,
  selectionState,
  allowedUser,
  onChange,
  widthForDropdown,
  alreadyAssigned,
  projectId,
  orderId,
}: Props) => {
  const [searchString, setSearchString] = React.useState<string>('');

  const projectWorkPackages =
    useRemoteData(
      getProjectWorkPackages(projectId ?? ''),
      Actions.fetchWorkPackagesForProject(projectId ?? ''),
      !!projectId
    ) ?? [];

  const topics = (
    useRemoteData(
      getOrderTopics(orderId ?? ''),
      Actions.fetchTopicsForOrder(orderId ?? ''),
      !!orderId
    ) ?? []
  ).map((topic) => ({
    ...topic,
    allChecked: false,
    allDisabled: true,
  }));

  const newTopicText = useTxt('common.newTopic');
  const moreWorkSectionsText = useTxt('common.moreWorkSections');

  const notSelectedLabel = useTxt('order.invoiceLines.row.notSelectedLabel');
  const newOrderRowsLabel = useTxt('order.invoiceLines.row.newOrderRows');

  const newOrderRowShortLabel = useTxt(
    'order.invoiceLines.row.newOrderRowShortLabel'
  );

  const notSelectedLabelText = useTxt(
    'order.invoiceLines.row.notSelectedLabel'
  );

  const newOrderRowLabelText = useTxt('order.invoiceLines.row.newOrderRow');

  const newOrderRowLabelTextShort = useTxt(
    'order.invoiceLines.row.newOrderRowShortLabel'
  );

  const selectedValue = convertStateToSelectionValue(selectionState);

  let options: OptionGroupData[] = [];

  const defaultValue =
    lineItemInfo && lineItemInfo.orderRowId
      ? `orderRow-${lineItemInfo.orderRowId}`
      : 'NONE';

  const placeholderDefault = orderRows.find(
    (row) => row.id === defaultValue.replace('orderRow-', '')
  );

  const placeholderText = placeholderDefault
    ? placeholderDefault.rowNumber
    : '-';

  const altArrivalRows = useTxt(
    'order.invoiceLines.convertModal.table.uppertierheader.arrivalRows'
  );

  const tooltipText = useTxt('order.invoiceLines.row.tooltip.received', {
    orderRowDescription: `${
      placeholderDefault
        ? `${placeholderDefault.rowNumber} ${placeholderDefault.description}`
        : ''
    }`,
  });

  const { topicOptions, optionsWithNewRow } = createOptions({
    topics,
    orderRows,
    projectWorkPackages,
    widthForDropdown,
    newTopicText,
    moreWorkSectionsText,
    notSelectedLabel,
    newOrderRowsLabel,
    newOrderRowShortLabel,
    searchString,
    defaultValue: selectedValue ? undefined : defaultValue,
  });

  if (lineItemInfo) {
    options = topicOptions;

    const optionRows = optionsWithNewRowForLineItem(
      notSelectedLabelText,
      options,
      newOrderRowLabelText,
      lineItemInfo,
      widthForDropdown,
      searchString,
      newOrderRowLabelTextShort
    );

    if (orderRows && allowedUser && alreadyAssigned) {
      return (
        <StyledDiv>
          <Tooltip
            tip={tooltipText}
            className="hoverable-tooltip"
            place="top"
            delayShow={500}
          >
            <img src={IconReceive} alt={altArrivalRows} />
          </Tooltip>
        </StyledDiv>
      );
    }

    const flattenedOptions = optionRows.reduce((acc, item) => {
      const title = item.title;

      if (title && typeof title === 'string') {
        acc.push(title);
      }

      if (item.options) {
        for (const option of item.options) {
          acc.push(option.value.toString());
        }
      }

      return acc;
    }, [] as string[]);

    const scrollToIndex =
      flattenedOptions.findIndex((option) => option === defaultValue) + 1;

    if (orderRows && allowedUser) {
      return (
        <StyledDropdown
          alternatePlaceholderStyle={!selectedValue && defaultValue !== 'NONE'}
          hideBorder
          dropdownMatchSelectWidth={widthForDropdown}
          optionFilterProp="title"
          onChange={onChange}
          placeholder={placeholderText}
          optionLabelProp="shortlabel"
          options={optionRows}
          value={selectedValue}
          disabled={alreadyAssigned}
          setSearchString={setSearchString}
          additionalContainerStyles={
            !selectedValue && defaultValue !== 'NONE'
              ? additionalContainerStyling
              : undefined
          }
          scrollToIndex={selectedValue ? undefined : scrollToIndex}
        />
      );
    }

    return null;
  } else {
    if (orderRows && allowedUser && alreadyAssigned) {
      return (
        <StyledDiv>
          <Tooltip
            tip={tooltipText}
            className="hoverable-tooltip"
            place="top"
            delayShow={500}
          >
            <img src={IconReceive} alt={altArrivalRows} />
          </Tooltip>
        </StyledDiv>
      );
    }

    if (orderRows && allowedUser) {
      return (
        <StyledDropdown
          alternatePlaceholderStyle={!selectedValue && defaultValue !== 'NONE'}
          hideBorder
          dropdownMatchSelectWidth={widthForDropdown}
          optionFilterProp="title"
          onChange={onChange}
          placeholder={placeholderText}
          optionLabelProp="shortlabel"
          options={optionsWithNewRow}
          value={selectedValue}
          disabled={alreadyAssigned}
          setSearchString={setSearchString}
          additionalContainerStyles={
            !selectedValue && defaultValue !== 'NONE'
              ? additionalContainerStyling
              : undefined
          }
        />
      );
    }

    return null;
  }
};

export default OrderRowDropdown;

type StyledDropdownProps = {
  alternatePlaceholderStyle: boolean;
};

const StyledDropdown = styled(DropDownSelect)<StyledDropdownProps>`
  ${(props) => {
    return props.alternatePlaceholderStyle
      ? css`
          /* stylelint-disable selector-max-class -- Third party libraries need to be styled either by type or by classnames */
          .rc-select-selection-item,
          .rc-select-selection-placeholder {
            color: ${({ theme }) => theme.color.graphiteB86};
          }
        `
      : '';
  }}
`;

const additionalContainerStyling: React.CSSProperties = {
  color: defaultTheme.color.graphiteB91,
};

const StyledDiv = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
`;
