import { NextgenScheduleData } from '../../../types/api';

import { makeAction, ExtractActionTypes } from '../../../utils/actionCreators';
import {
  GET,
  BackendError,
  apiErrorHandlingWithDecode,
} from '../../../utils/api';
import { createAsyncThunk } from '../../../utils/thunk';

import { isLoading } from '../../reducers/schedule/nextgenScheduleData';

const actionCreators = {
  ...makeAction('getNextgenScheduleDataStarted')<{
    projectId: string;
  }>(),
  ...makeAction('getNextgenScheduleDataSuccess')<{
    projectId: string;
    nextgenScheduleData: NextgenScheduleData | null;
  }>(),
  ...makeAction('getNextgenScheduleDataFailure')<{
    projectId: string;
    error: BackendError | undefined;
  }>(),
};

export type NextgenScheduleDataAction = ExtractActionTypes<
  typeof actionCreators
>;

const {
  getNextgenScheduleDataStarted,
  getNextgenScheduleDataSuccess,
  getNextgenScheduleDataFailure,
} = actionCreators;

const requestNextgenScheduleDataForProject = async ({
  projectId,
}: {
  projectId: string;
}): Promise<NextgenScheduleData | null> => {
  const response = await GET<NextgenScheduleData | null>(
    `v1/projects/${projectId}/schedule-data`
  );

  return response;
};

export const fetchNextgenScheduleDataForProject = ({
  projectId,
}: {
  projectId: string;
}) =>
  createAsyncThunk(requestNextgenScheduleDataForProject, {
    args: [{ projectId }],
    initialAction: getNextgenScheduleDataStarted({ projectId }),
    isPending: isLoading(projectId),
    failureActionCreator: (error) =>
      getNextgenScheduleDataFailure({
        projectId,
        error: apiErrorHandlingWithDecode(error),
      }),
    successActionCreator: (nextgenScheduleData) =>
      getNextgenScheduleDataSuccess({
        projectId,
        nextgenScheduleData,
      }),
  });
