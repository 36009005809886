import React from 'react';

import { ColumnDef } from '@tanstack/react-table';
import styled from 'styled-components';

import { APIProject } from '../../../../store/reducers/project';

import { IconButton } from '../../../../components/Buttons';
import { ColorBox, TaskStatusIcon } from '../../../../components/ColorBox';
import CheckboxComponent from '../../../../components/Input/Checkbox';
import Txt from '../../../../components/Txt';

import { dateFormat } from '../../../../utils/format';
import { isClickOrKeyboardSelection } from '../../../../utils/mouseOrKeyInteraction';
import { ExtendedScheduleWorkPackageType } from '../utils';

import {
  IconCollapseMinus,
  IconExpandPlus,
  IconSchedule,
  IconCloseCircle,
} from '../../../../assets/svg';

import { ExpandCollapseButton } from '../NewScheduleTree';
import { NameCellContent } from '../NewScheduleTreeRow';

const textIdHead = 'worksection.workpackage.';

export const tableColumns = {
  scheduleWorkPackage: { width: 11, fixedWidth: false },
  virtualSpaceName: { width: 12, fixedWidth: true },
  workSectionClassName: { width: 12, fixedWidth: true },
  team: { width: 12, fixedWidth: true },
  startDate: { width: 6, fixedWidth: true },
  endDate: { width: 6, fixedWidth: true },
  status: { width: 6, fixedWidth: true },
  buttons: { width: 5.5, fixedWidth: true },
};

export default function useScheduleTreeTableColumns(
  deSelectWorkPackage?: (id: string) => void,
  project?: APIProject | undefined,
  showCheckBoxes?: boolean
) {
  const onClickOrKeyboardSelection = (
    e: React.MouseEvent | React.KeyboardEvent,
    callback: () => void
  ) => {
    e.stopPropagation();

    if (isClickOrKeyboardSelection(e)) {
      e.preventDefault();
      callback();
    }
  };

  const columns = React.useMemo<ColumnDef<ExtendedScheduleWorkPackageType>[]>(
    () => [
      {
        accessorKey: 'scheduleWorkPackage',
        header: ({ table }) => {
          return (
            <FlexRowDiv>
              {showCheckBoxes ? (
                <CheckboxComponent
                  checked={table.getIsAllRowsSelected()}
                  indeterminate={table.getIsSomeRowsSelected()}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      e.stopPropagation();
                      e.preventDefault();
                      table.toggleAllRowsSelected();
                    }
                  }}
                  onChange={() => table.toggleAllRowsSelected()}
                />
              ) : null}
              <HeaderTextSpan>
                <Txt id={`${textIdHead}scheduleWorkPackage` as const} />
              </HeaderTextSpan>
              <ButtonDiv>
                <ExpandCollapseButton
                  icon={IconExpandPlus}
                  customSize="12px"
                  data-testid="expand-all-button"
                  onClick={() => table.toggleAllRowsExpanded(true)}
                />
                <ExpandCollapseButton
                  icon={IconCollapseMinus}
                  customSize="12px"
                  data-testid="collapse-all-button"
                  onClick={() => table.toggleAllRowsExpanded(false)}
                />
              </ButtonDiv>
            </FlexRowDiv>
          );
        },
        cell: ({ row, virtualRowStart }) => {
          return (
            <NameCellContent
              row={row}
              virtualRowStart={virtualRowStart}
              showCheckBoxes={showCheckBoxes}
            />
          );
        },
        meta: {
          align: 'left',
        },
        minSize: tableColumns.scheduleWorkPackage.width,
      },
      {
        accessorKey: 'virtualSpaceName',
        header: () => {
          return <Txt id={`${textIdHead}location` as const} />;
        },
        cell: ({ getValue }) => {
          const value =
            getValue<ExtendedScheduleWorkPackageType['virtualSpaceName']>();

          return (
            <RowDiv>
              <HiddenOverflowSpan>{value}</HiddenOverflowSpan>
            </RowDiv>
          );
        },
        meta: {
          align: 'left',
        },
        size: tableColumns.virtualSpaceName.width,
      },
      {
        accessorKey: 'workSectionClassName',
        header: () => {
          return <Txt id="common.workSectionClass" />;
        },
        cell: ({ getValue }) => {
          const value =
            getValue<ExtendedScheduleWorkPackageType['virtualSpaceName']>();

          return (
            <RowDiv>
              <HiddenOverflowSpan>{value}</HiddenOverflowSpan>
            </RowDiv>
          );
        },
        meta: {
          align: 'left',
        },
        size: tableColumns.workSectionClassName.width,
      },
      {
        accessorKey: 'team',
        header: () => {
          return <Txt id={`${textIdHead}team` as const} />;
        },
        cell: ({ row }) => {
          const { teamName, teamColor, type: rowType } = row.original;

          return (
            <FlexDiv>
              {rowType === 'sum' ? null : (
                <>
                  <TeamColorBox color={teamColor ?? 'white'} />
                  <HiddenOverflowSpan>{teamName ?? ''}</HiddenOverflowSpan>
                </>
              )}
            </FlexDiv>
          );
        },
        meta: {
          align: 'left',
        },
        size: tableColumns.team.width,
      },
      {
        accessorKey: 'startDate',
        header: () => {
          return <Txt id={`${textIdHead}startDate` as const} />;
        },
        cell: ({ row }) => {
          const value = row.original.planned_start;

          return value ? dateFormat.format(new Date(value)) : null;
        },
        meta: {
          align: 'left',
        },
        size: tableColumns.startDate.width,
      },
      {
        accessorKey: 'endDate',
        header: () => {
          return <Txt id={`${textIdHead}endDate` as const} />;
        },
        cell: ({ row }) => {
          const value = row.original.planned_end;

          return value ? dateFormat.format(new Date(value)) : null;
        },
        meta: {
          align: 'left',
        },
        size: tableColumns.endDate.width,
      },
      {
        accessorKey: 'status',
        header: () => {
          return <Txt id={`${textIdHead}status` as const} />;
        },
        cell: ({ row }) => {
          const value = row.original.progress_percentage;

          const statusColor = row.original.statusColor;

          return (
            <FlexDiv>
              <StyledStatusIcon statusColor={statusColor} />
              <HiddenOverflowSpan>{value.toFixed(0)}%</HiddenOverflowSpan>
            </FlexDiv>
          );
        },
        meta: {
          align: 'left',
        },
        size: tableColumns.status.width,
      },
      {
        accessorKey: 'buttons',
        header: () => null,
        cell: ({ row }) => {
          function scheduleLinkClicked() {
            const url = project?.externalUrl;

            if (url) {
              window.open(`${url}/work-packages/${row.id}`, '_blank');
            }
          }

          return (
            <FlexDiv>
              <StyledIconButton
                icon={IconSchedule}
                onClick={(e) =>
                  onClickOrKeyboardSelection(e, () => scheduleLinkClicked())
                }
                onKeyDown={(e) =>
                  onClickOrKeyboardSelection(e, () => scheduleLinkClicked())
                }
              />
              <StyledIconButton
                icon={IconCloseCircle}
                disabled={row.original.deSelectDisabled}
                onClick={(e) =>
                  onClickOrKeyboardSelection(e, () => {
                    if (deSelectWorkPackage) {
                      deSelectWorkPackage(row.id);
                    }
                  })
                }
                onKeyDown={(e) =>
                  onClickOrKeyboardSelection(e, () => {
                    if (deSelectWorkPackage) {
                      deSelectWorkPackage(row.id);
                    }
                  })
                }
              />
            </FlexDiv>
          );
        },
        meta: {
          align: 'left',
        },
        size: tableColumns.buttons.width,
      },
    ],
    [deSelectWorkPackage, project?.externalUrl, showCheckBoxes]
  );

  return columns;
}

const RowDiv = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

const HeaderTextSpan = styled.span`
  flex: 1 1 0%;
`;

const ButtonDiv = styled.div`
  display: flex;
  flex: 0 0 ${(props) => props.theme.margin[32]};
`;

const FlexRowDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  flex-grow: 1;

  gap: ${(props) => props.theme.margin[8]};
`;

const HiddenOverflowSpan = styled.span`
  width: 100%;

  display: inline-block;
  align-items: center;

  line-height: 1rem;
  white-space: nowrap;
  text-overflow: ellipsis;

  overflow: hidden;

  :hover {
    white-space: unset;
  }
`;

const FlexDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledStatusIcon = styled(TaskStatusIcon)`
  margin-right: ${(props) => props.theme.margin[8]};
  margin-bottom: ${(props) => props.theme.margin[2]};
`;

export const TeamColorBox = styled(ColorBox)`
  margin-right: ${(props) => props.theme.margin[8]};
`;

const StyledIconButton = styled(IconButton)`
  margin-right: ${(props) => props.theme.margin[16]};
`;
