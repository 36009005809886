import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import styled from 'styled-components';

import { getActualCostSortOrderFor } from '../../../../../store/reducers/actualCosts/sortActualCosts';
import { getInvoiceSortOrderFor } from '../../../../../store/reducers/invoice/sortInvoiceHeaders';
import { getIsOuterBarOpen } from '../../../../../store/reducers/ui';

import { toggleActualcostSort } from '../../../../../store/actions/actualCost';
import { toggleInvoiceHeaderSort } from '../../../../../store/actions/invoiceHeader';

import useLocalization from '../../../../../hooks/useLocalization';
import useTxt from '../../../../../hooks/useTxt';

import { IconButton } from '../../../../../components/Buttons';
import Checkbox from '../../../../../components/Input/Checkbox';
import {
  CompactTableHeader,
  CompactHeaderTh,
} from '../../../../../components/Table';

import { mapValues } from '../../../../../utils/record';

import { IconUp, IconDown } from '../../../../../assets/svg';

type ListType = 'actualCost' | 'invoiceHeader' | 'unassigned';
type invoiceType = 'handled' | 'unhandled';

type Props = {
  allSelected?: boolean;
  listType: ListType;
  onSelectAll?: () => void;
  allowedUser?: boolean;
  invoiceType?: invoiceType;
};

const dateLabels = {
  invoiceHeader: 'order.receiveMode.arrivalRowTH.invoiceDate',
  actualCost: 'order.receiveMode.arrivalRowTH.actualCostDate',
  unassigned: 'meta.none',
} as const;

const shortDateLabels = {
  invoiceHeader: 'order.receiveMode.arrivalRowTH.invoiceDate.short',
  actualCost: 'order.receiveMode.arrivalRowTH.actualCostDate.short',
  unassigned: 'meta.none',
} as const;

const ArrivalRowTableHeader = ({
  allSelected,
  onSelectAll,
  listType,
  allowedUser,
  invoiceType = 'handled',
}: Props) => {
  const localization = useLocalization();

  const labels = mapValues(dateLabels, (id) =>
    localization.formatMessage({ id })
  );

  const shortLabels = mapValues(shortDateLabels, (id) =>
    localization.formatMessage({ id })
  );

  const dateLabel = labels[listType];
  const shortDateLabel = shortLabels[listType];
  const chooseRowText = useTxt('order.receiveMode.arrivalRowTH.chooseRow');
  const chooseAllText = useTxt('order.receiveMode.arrivalRowTH.chooseAll');

  const arrivalAbbrvText = useTxt(
    'order.receiveMode.arrivalRowTH.arrivalAbbreviation'
  );
  const rowNoText = useTxt('order.receiveMode.arrivalRowTH.rowNo');

  const statusText = useTxt(
    listType === 'unassigned'
      ? 'common.description'
      : 'order.receiveMode.arrivalRowTH.status'
  );

  const statusShortText = useTxt(
    listType === 'unassigned'
      ? 'common.description'
      : 'order.receiveMode.arrivalRowTH.statusShort'
  );

  const supplierText = useTxt(
    listType === 'unassigned'
      ? 'meta.empty'
      : 'order.receiveMode.arrivalRowTH.supplier'
  );
  const procurementText = useTxt('order.receiveMode.arrivalRowTH.procurement');

  const invoiceAmountText = useTxt(
    listType === 'unassigned'
      ? 'meta.empty'
      : 'order.receiveMode.arrivalRowTH.invoiceAmount'
  );

  const workPackageCodeText = useTxt(
    'order.receiveMode.arrivalRowTH.workSection'
  );

  const workPackageCodeShortText = useTxt(
    'order.receiveMode.arrivalRowTH.workSection.short'
  );
  const quantityText = useTxt('order.receiveMode.arrivalRowTH.quantity');
  const unitText = useTxt('order.receiveMode.arrivalRowTH.unit');
  const unitPriceText = useTxt('order.receiveMode.arrivalRowTH.unitPrice');
  const amountText = useTxt('order.receiveMode.arrivalRowTH.amount');
  const dateText = useTxt('order.receiveMode.arrivalRowTH.date');
  const vatCodeText = useTxt('order.receiveMode.arrivalRowTH.vatCode');
  const accountCodeText = useTxt('order.receiveMode.arrivalRowTH.accountCode');
  const optionsText = useTxt('order.receiveMode.arrivalRowTH.options');

  const shouldShowCheckbox = allSelected !== undefined && onSelectAll;

  const outerBarOpen = useSelector(getIsOuterBarOpen());
  const dispatch = useDispatch();

  // sort orders
  const invoiceSortOrder = useSelector(
    getInvoiceSortOrderFor('dueDate', invoiceType)
  );
  const actualCostsortOrder = useSelector(getActualCostSortOrderFor('date'));

  const sortOrders = {
    invoiceHeader: invoiceSortOrder,
    actualCost: actualCostsortOrder,
    unassigned: null,
  } as const;

  const sortOrder = sortOrders[listType];

  const toggleSort = () => {
    switch (listType) {
      case 'actualCost':
        dispatch(toggleActualcostSort({ sortableKey: 'date' }));
        break;

      case 'invoiceHeader':
        dispatch(
          toggleInvoiceHeaderSort({
            sortableKey: 'dueDate',
            invoiceType,
          })
        );
        break;

      default:
        break;
    }
  };

  return (
    <CompactTableHeader>
      <tr>
        <CompactHeaderTh width="2.5rem" aria-label={chooseRowText}>
          {shouldShowCheckbox ? (
            <Checkbox
              checked={allSelected}
              onChange={onSelectAll}
              onKeyPress={(e) => {
                if (e.key === 'Enter' && onSelectAll) {
                  e.stopPropagation();
                  e.preventDefault();
                  onSelectAll();
                }
              }}
              aria-label={chooseAllText}
              disabled={allowedUser}
            />
          ) : null}
        </CompactHeaderTh>
        <CompactHeaderTh width="3.5rem">{arrivalAbbrvText}</CompactHeaderTh>
        <CompactHeaderTh width="3.5rem">{rowNoText}</CompactHeaderTh>
        <CompactHeaderTh width={outerBarOpen ? '2.5rem' : 'auto'}>
          <FlexDiv justifyContent="flex-start">
            {outerBarOpen ? (
              statusShortText
            ) : (
              <NoOverflowSpan>{statusText}</NoOverflowSpan>
            )}
          </FlexDiv>
        </CompactHeaderTh>
        {!outerBarOpen && listType === 'invoiceHeader' ? (
          <CompactHeaderTh width="auto">
            <FlexDiv justifyContent="flex-start">
              <NoOverflowSpan>{procurementText}</NoOverflowSpan>
            </FlexDiv>
          </CompactHeaderTh>
        ) : null}
        <CompactHeaderTh
          width="auto"
          colSpan={outerBarOpen || listType === 'invoiceHeader' ? 1 : 2}
        >
          <FlexDiv justifyContent="flex-start">
            <NoOverflowSpan>
              {outerBarOpen && listType === 'invoiceHeader'
                ? `${procurementText}/${supplierText}`
                : supplierText}
            </NoOverflowSpan>
          </FlexDiv>
        </CompactHeaderTh>
        <PaddedRightAlignHeader width="8rem" paddingRight="1.25rem">
          {invoiceAmountText}
        </PaddedRightAlignHeader>
        <CompactHeaderTh width="6rem" onClick={toggleSort}>
          <FlexDiv justifyContent="flex-start">
            {outerBarOpen
              ? `${
                  shortDateLabel !== ' ' ? `${shortDateLabel}/` : ''
                }${workPackageCodeShortText}`
              : dateLabel}
            {listType !== 'unassigned' ? (
              <IconButton
                icon={sortOrder === 'Ascending' ? IconDown : IconUp}
              />
            ) : null}
          </FlexDiv>
        </CompactHeaderTh>
        {!outerBarOpen ? (
          <>
            <CompactHeaderTh width="3rem">
              <CellDiv>{workPackageCodeText}</CellDiv>
            </CompactHeaderTh>
            <RightAlignHeader width="5rem">{quantityText}</RightAlignHeader>
            <CompactHeaderTh width="3rem">{unitText}</CompactHeaderTh>
            <RightAlignHeader width="5rem">{unitPriceText}</RightAlignHeader>
            <PaddedRightAlignHeader width="10rem" paddingRight="2rem">
              {amountText}
            </PaddedRightAlignHeader>
          </>
        ) : (
          <RightAlignHeader width="7rem">{amountText}</RightAlignHeader>
        )}
        {!outerBarOpen ? (
          <CompactHeaderTh width="4.5rem">{dateText}</CompactHeaderTh>
        ) : null}
        {listType === 'invoiceHeader' ? (
          <>
            <CompactHeaderTh width={outerBarOpen ? '5rem' : '6rem'}>
              {vatCodeText}
            </CompactHeaderTh>
            <CompactHeaderTh width={outerBarOpen ? '5rem' : '6rem'}>
              {accountCodeText}
            </CompactHeaderTh>
          </>
        ) : null}
        <CompactHeaderTh width="6rem" aria-label={optionsText} />
      </tr>
    </CompactTableHeader>
  );
};

const RightAlignHeader = styled(CompactHeaderTh)`
  text-align: right;
`;

type PaddedRightAlignHeaderProps = {
  paddingRight: string;
};

const PaddedRightAlignHeader = styled(
  RightAlignHeader
)<PaddedRightAlignHeaderProps>`
  padding-right: ${(props) => props.paddingRight};
`;

type FlexDivProps = {
  justifyContent: 'flex-end' | 'flex-start';
};

const FlexDiv = styled.div<FlexDivProps>`
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => props.justifyContent};
  align-items: center;
`;

const CellDiv = styled.div`
  direction: rtl;
`;

const NoOverflowSpan = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export default ArrivalRowTableHeader;
