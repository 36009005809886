import React, { PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';

import styled from 'styled-components';

import { getIsOuterBarOpen } from '../store/reducers/ui';

type SDContextProps = {
  outerBarContainer: HTMLDivElement | null;
};

export const AppContext = React.createContext<SDContextProps>({
  outerBarContainer: null,
});

const OuterBarContainer = styled.div<{ isOuterBarOpen: boolean }>`
  height: 100%;

  display: flex;
  flex-direction: column;
  flex-wrap: 1;

  flex: ${({ isOuterBarOpen }) => (isOuterBarOpen ? 1 : 0)} 1;
`;

export const AppContextProvider = ({ children }: PropsWithChildren<{}>) => {
  const isOuterBarOpen = useSelector(getIsOuterBarOpen());
  const outerBarContainer = React.useRef<HTMLDivElement | null>(null);
  const theBar = (outerBarContainer && outerBarContainer.current) || null;

  return (
    <AppContext.Provider value={{ outerBarContainer: theBar }}>
      {children}
      <OuterBarContainer
        ref={outerBarContainer}
        isOuterBarOpen={isOuterBarOpen}
      />
    </AppContext.Provider>
  );
};
