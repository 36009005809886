import React from 'react';
import { useSelector } from 'react-redux';
import useMeasure from 'react-use-measure';

import styled from 'styled-components';

import { getProjectById } from '../../../../store/reducers/project';

import { TargetRowOrTargetRowHierarchyEntry } from '../../hooks/useTargetViewData';

import Txt from '../../../../components/Txt';

import { routes, useParams } from '../../../../routes';

import TargetTable from '../TargetTable';

import { FilterState } from '.';

type PreviewTableProps = {
  validatedData: TargetRowOrTargetRowHierarchyEntry[];
  filterState?: FilterState;
};

const PreviewTable = ({ validatedData, filterState }: PreviewTableProps) => {
  const [state, setState] = React.useState<FilterState | undefined>(
    filterState
  );
  const { projectId } = useParams(routes.TARGET);

  const tableRef = React.useRef<{
    toggleExpandAll: (expand: boolean) => void;
    selectAllLowestLevelItems: () => void;
    selectLowestLevel: () => void;
    selectNextLevel: () => void;
  }>(null);

  const project = useSelector(getProjectById(projectId));
  const [measureRef, measures] = useMeasure({ debounce: 100 });

  React.useEffect(() => {
    if (state !== filterState && filterState && tableRef.current) {
      tableRef.current.toggleExpandAll(true);
      setState(filterState);
    }

    if (state !== filterState && !filterState && tableRef.current) {
      setState(filterState);
    }
  }, [filterState, state]);

  if (!project) {
    return null;
  }

  const { width, height } = measures;

  return (
    <>
      <PreviewTableContainer ref={measureRef}>
        <HeaderText>
          <Txt id="target.targetImportModal.previewTable.header" />
        </HeaderText>
        <TargetTable
          projectId={projectId}
          data={validatedData}
          parentMeasures={{ width, height }}
          ref={tableRef}
        />
      </PreviewTableContainer>
    </>
  );
};

const PreviewTableContainer = styled.div`
  position: relative;

  margin: 0;

  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const HeaderText = styled.h1`
  margin: ${({ theme }) => `0 0 ${theme.margin[8]}`} 0;
  display: flex;
  align-items: center;
`;

export default PreviewTable;
