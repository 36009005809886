import React, { useRef } from 'react';
import useMeasure from 'react-use-measure';

import styled from 'styled-components';

import useTxt from '../../../../hooks/useTxt';

import {
  CenteredButtonGroup,
  PrimaryButton,
  SecondaryButton,
} from '../../../../components/Buttons';
import Modal, {
  Content,
  Header,
  Container,
  FilterTextInput,
  Footer,
} from '../../../../components/Modal/Modal';
import Txt from '../../../../components/Txt';

import { useDebouncedValue } from '../../../../utils/hooks';
import { ExtendedScheduleWorkPackageType } from '../utils';

import WorkPackageList from './WorkPackageList';

interface LinkWorkPackagesModalProps {
  onClose: () => void;
  scheduleWorkPackages: ExtendedScheduleWorkPackageType[];
  headerInfo: { code: string; name: string };
  selectedBasedOnWorkSection: string[];
  manualLinkedWorkPackages: string[];
  setLinkedWorkPackages: (workPackages: string[]) => void;
}

const LinkWorkPackagesModal = ({
  onClose,
  scheduleWorkPackages,
  headerInfo,
  selectedBasedOnWorkSection,
  manualLinkedWorkPackages,
  setLinkedWorkPackages,
}: LinkWorkPackagesModalProps) => {
  const [filter, setFilterText] = React.useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  const tableRef = React.useRef<{
    toggleExpandAll: (expand: boolean) => void;
    setFilter: (filter: string) => void;
    setRowSelection: React.Dispatch<
      React.SetStateAction<Record<string, boolean>>
    >;
  }>(null);

  const [selectedPackages, setSelectedPackages] = React.useState<string[]>([
    ...new Set([...selectedBasedOnWorkSection, ...manualLinkedWorkPackages]),
  ]);

  const debouncedFilter = useDebouncedValue(filter, 1000);

  const [measureRef, measures] = useMeasure({ debounce: 100 });

  React.useEffect(() => {
    if (tableRef.current) {
      tableRef.current.setFilter(debouncedFilter);
      tableRef.current.toggleExpandAll(true);
    }
  }, [debouncedFilter]);

  const placeHolder = useTxt(
    'worksection.workpackage.editModal.searchScheduleWorkPackages'
  );

  React.useLayoutEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <Modal onClose={onClose}>
      <StyledContainer>
        <Header>
          <Txt
            id="worksection.workpackage.editModal.linkScheduleWorkPackages"
            values={headerInfo}
          />
        </Header>
        <StyledContent noMaxHeight>
          <FilterTextInput
            name="filter"
            id="filter"
            placeholder={placeHolder}
            onChange={(e) => setFilterText(e.target.value)}
            ref={inputRef}
          />
          <WorkPackageListParentContainer ref={measureRef}>
            <WorkPackageList
              workPackages={scheduleWorkPackages}
              setSelectedIds={setSelectedPackages}
              selectedBasedOnWorkSection={selectedBasedOnWorkSection}
              manualLinkedWorkPackages={manualLinkedWorkPackages}
              ref={tableRef}
              parentMeasures={{
                width: measures.width ?? 0,
                height: measures.height ?? 0,
              }}
            />
          </WorkPackageListParentContainer>
        </StyledContent>
        <Footer>
          <StyledButtonGroup>
            <SecondaryButton
              type="button"
              onClick={() => {
                if (tableRef.current) {
                  const newState = selectedBasedOnWorkSection.reduce(
                    (acc, id) => {
                      acc[id] = true;

                      return acc;
                    },
                    {} as Record<string, boolean>
                  );
                  tableRef.current.setRowSelection(newState);
                }

                setSelectedPackages(selectedBasedOnWorkSection);
                setLinkedWorkPackages([]);
              }}
            >
              <Txt id="worksection.workpackage.editModal.linkScheduleWorkPackages.unLinkButton" />
            </SecondaryButton>
            <SecondaryButton type="button" onClick={onClose}>
              <Txt id="common.cancel" />
            </SecondaryButton>
            <PrimaryButton
              type="submit"
              onClick={() => {
                setLinkedWorkPackages(selectedPackages);
                onClose();
              }}
            >
              <Txt
                id="worksection.workpackage.editModal.linkScheduleWorkPackages.linkButton"
                values={{ count: selectedPackages.length }}
              />
            </PrimaryButton>
          </StyledButtonGroup>
        </Footer>
      </StyledContainer>
    </Modal>
  );
};

export default LinkWorkPackagesModal;

const StyledContainer = styled(Container)`
  width: 75vw;
  max-height: 80vh;
  min-height: 80vh;
`;

const StyledContent = styled(Content)`
  padding-right: ${(props) => `${props.theme.margin[32]}`};
`;

const StyledButtonGroup = styled(CenteredButtonGroup)`
  padding: 0 ${(props) => props.theme.margin[18]};
  width: 100%;
  justify-content: right;
`;

const WorkPackageListParentContainer = styled.div`
  flex-grow: 1;
`;
