import { Reducer } from 'redux';

import { APIArrival } from '../../types/api';
import { GlobalState } from '../../types/general';

import {
  assertActionPayloadIsNotApiUpdatedEntities,
  isUpdatedEntitiesActionType,
} from './utils';
import {
  isDefined,
  initialGlobalState,
  updateGlobalStateDerivative,
} from '../../utils/general';

import { ActionTypes } from '../actionTypes';
import { getArrivalRowById } from './arrivalRow';
import { getGlobalStateById } from './helpers/globalStateSelector';
import { getOrderById } from './order/order';

import { AppState } from '.';

export type ArrivalState = GlobalState<APIArrival>;

const initialState: ArrivalState = initialGlobalState;

const arrivalReducer: Reducer<ArrivalState, ActionTypes> = (
  state = initialState,
  action
): ArrivalState => {
  switch (action.type) {
    case 'GET_ARRIVALS_SUCCESS':
      return updateGlobalStateDerivative(state, action.payload);
  }

  if (isUpdatedEntitiesActionType(action)) {
    return updateGlobalStateDerivative(state, action.payload.arrivals);
  }

  assertActionPayloadIsNotApiUpdatedEntities(action);

  return state;
};

export const getArrivalById = getGlobalStateById('arrivals');

export const getArrivalByArrivalRowId =
  (arrivalRowId: string) => (appState: AppState) => {
    const arrivalRow = getArrivalRowById(arrivalRowId)(appState);

    return arrivalRow
      ? getArrivalById(arrivalRow.arrivalId)(appState)
      : undefined;
  };

export const getArrivals = (state: AppState) => state.arrivals.data;

export const getArrivalsByIds =
  (arrivalIds: string[]) =>
  ({ arrivals: { data } }: AppState): APIArrival[] => {
    const emptyArray: APIArrival[] = [];

    const arrivals = arrivalIds.reduce((array, id) => {
      const arrival = data[id];

      if (arrival) {
        const newArray = array.concat(arrival);

        return newArray;
      }

      return array;
    }, emptyArray);

    return arrivals;
  };

export const getArrivalsByOrderId =
  (orderId: string) =>
  (appState: AppState): APIArrival[] => {
    const orderArrivalIds = getOrderById(orderId)(appState)?.arrivalIds ?? [];

    return orderArrivalIds
      .map((id) => getArrivalById(id)(appState))
      .filter(isDefined);
  };

export const getNewArrivalNumber =
  (orderId: string) =>
  (appState: AppState): number => {
    const orderArrivals = getArrivalsByOrderId(orderId)(appState);

    return (
      orderArrivals.reduce(
        (result, current) => Math.max(result, current.rowNumber),
        0
      ) + 1
    );
  };

export default arrivalReducer;
