import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getProjectOrders } from '../../../store/reducers/order/order';
import { getProcurementAreasByProjectId } from '../../../store/reducers/procurementArea';
import {
  getBillingFilteringActive,
  getSelectedOrderStatuses,
} from '../../../store/reducers/ui';

import { fetchOrdersForProject } from '../../../store/actions';
import {
  setOrderStatusToggled,
  updateProjectColumns,
} from '../../../store/actions/ui';

import useRemoteData from '../../../hooks/useRemoteData';

import { Table } from '../../../components/Table';

import ProcurementArea from './ProcurementArea';
import ProcurementAreasTableHeader from './ProcurementAreasTableHeader';

type Props = {
  projectId: string;
};

const ProcurementAreasTable = ({ projectId }: Props) => {
  const dispatch = useDispatch();

  const onUpdateColumn = (column: any) => {
    dispatch(updateProjectColumns(column));
  };

  const orderStatusFilterValues = useSelector(getSelectedOrderStatuses);
  const billingFilteringActive = useSelector(getBillingFilteringActive);

  const procurementAreas = useSelector(
    getProcurementAreasByProjectId(projectId)
  );

  const toggleOrderStatusFilter = (value: string) => {
    dispatch(setOrderStatusToggled(value));
  };

  const filteredOrders =
    useRemoteData(
      getProjectOrders(
        projectId,
        billingFilteringActive,
        orderStatusFilterValues
      ),
      fetchOrdersForProject(projectId)
    ) ?? [];

  let filteredProcurementAreas = procurementAreas;

  if (billingFilteringActive || orderStatusFilterValues.length !== 0) {
    filteredProcurementAreas = procurementAreas.filter((area) =>
      area.orderIds.some((orderInArea) =>
        filteredOrders.map((order) => order.id).includes(orderInArea)
      )
    );
  }

  return (
    <Table>
      <ProcurementAreasTableHeader
        projectId={projectId}
        onChangeColumn={onUpdateColumn}
        toggleOrderStatusFilter={toggleOrderStatusFilter}
        orderStatusFilterValues={orderStatusFilterValues}
        billingFilteringActive={billingFilteringActive}
      />
      {filteredProcurementAreas.map((procurementArea) => (
        <ProcurementArea
          key={`procurement-area-row-${procurementArea.id}`}
          procurementAreaId={procurementArea.id}
          orderStatusFilterValues={orderStatusFilterValues}
        />
      ))}
    </Table>
  );
};

export default ProcurementAreasTable;
