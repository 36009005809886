import React from 'react';

import styled from 'styled-components';

import { TableHeader as BaseHeader } from '../../../components/Table';
import Txt from '../../../components/Txt';

const Tr = styled.tr`
  line-height: ${({ theme }) => theme.margin[32]};
`;

export const tableColumns = {
  empty: { align: 'left', width: '4rem', position: '0' },
  code: { align: 'left', width: '4rem', position: '0' },
  value: { align: 'left', width: '22rem', position: '8rem' },
  paymentProgramRowsAmount: {
    align: 'right',
    width: '8rem',
    position: '30rem',
  },
  orderRowsAmount: { align: 'right', width: '8rem', position: '38rem' },
  targetRowsAmount: { align: 'right', width: '8rem', position: '46rem' },
  arrivalsAmount: { align: 'right', width: '8rem', position: '54rem' },
  attributeValues: { align: 'right', width: '40rem', position: undefined },
  delete: { align: 'left', width: '4rem', position: undefined },
  none: { align: 'right', width: '40rem', position: undefined },
};

const textIdHead = 'analysis.table.header.';

type ThProps = {
  name: keyof typeof tableColumns;
  colSpan?: number;
};

const Th = styled.th<ThProps>`
  padding: ${({ theme: { margin } }) =>
    `${margin[8]} ${margin[16]} ${margin[8]} ${margin[8]}`};
  text-align: ${({ name }) => tableColumns[name].align};
`;

const TxtTh = ({ name, colSpan }: ThProps) => (
  <Th name={name} colSpan={colSpan}>
    <Txt id={`${textIdHead}${name}` as const} />
  </Th>
);

export const TableHeader = () => {
  return (
    <BaseHeader>
      <Tr>
        <TxtTh name="empty" />
        <TxtTh name="empty" />
        <TxtTh name="value" />
        <TxtTh name="paymentProgramRowsAmount" />
        <TxtTh name="orderRowsAmount" />
        <TxtTh name="targetRowsAmount" />
        <TxtTh name="arrivalsAmount" />
        <TxtTh colSpan={2} name="none" />
        <TxtTh name="delete" />
      </Tr>
    </BaseHeader>
  );
};
