import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';

import { getDetailedSnapshotsCsvFetchRequest } from '../../store/reducers/snapshot';

import {
  getWorkPackageTimelineForProject,
  getWorkPackageGroupTimelineForProject,
  getProjectTimelineForProject,
  fetchDetailedSnapshotsCsv,
} from '../../store/actions';

import { AddSnapshotModal } from './components/AddSnapshotModal';
import SnapshotTable from './components/SnapshotTable';
import Header from '../../components/Header';
import { Spinner } from '../../components/Loading';
import TabIconTextButton from '../../components/Navigation/TabIconTextButton';
import ProjectInfo from '../../components/ProjectInfo';
import Txt from '../../components/Txt';

import CAN, { CaslProjectRequestParams } from '../../utils/caslUserPermissions';

import { IconCsvDocument, IconPlusDocument } from '../../assets/svg';

import { routes, useParams } from '../../routes';

const ReportingView = () => {
  const [showAddSnapshotModal, setShowAddSnapshotModal] = useState(false);
  const { projectId } = useParams(routes.REPORTING);
  const dispatch = useDispatch();

  const csvRequestState = useSelector(
    getDetailedSnapshotsCsvFetchRequest(projectId)
  ).kind;

  // Fetch project-related timeline data here every time project changes / user re-enters view.
  React.useEffect(() => {
    dispatch(getWorkPackageTimelineForProject({ projectId }));
    dispatch(getWorkPackageGroupTimelineForProject({ projectId }));
    dispatch(getProjectTimelineForProject({ projectId }));
  }, [projectId, dispatch]);

  const toggleModal = () => {
    setShowAddSnapshotModal(!showAddSnapshotModal);
  };

  const loadCsvDataFromBackend = () => {
    dispatch(fetchDetailedSnapshotsCsv(projectId));
  };

  const onClose = () => {
    setShowAddSnapshotModal(false);
  };

  const ability = new CaslProjectRequestParams(projectId);
  const allowedUser = CAN('write', ability);

  return (
    <Container>
      {showAddSnapshotModal ? (
        <AddSnapshotModal projectId={projectId} onClose={onClose} />
      ) : null}
      <Header>
        <Txt id="reporting.header" />
      </Header>
      <ProjectInfo projectId={projectId} />
      <EditButtonContainer>
        <StyledTabButton
          isActive={false}
          onClick={loadCsvDataFromBackend}
          icon={IconCsvDocument}
          disabled={csvRequestState === 'Loading'}
          id={
            csvRequestState === 'Loading'
              ? 'meta.none'
              : 'common.button.csvExport'
          }
        >
          {csvRequestState === 'Loading' ? (
            <SpinnerDiv>
              <Spinner size="1rem" />
            </SpinnerDiv>
          ) : null}
        </StyledTabButton>
        <TabIconTextButton
          disabled={!allowedUser}
          isActive={showAddSnapshotModal}
          onClick={toggleModal}
          icon={IconPlusDocument}
          id="reporting.button.addNewSnapshot"
        />
      </EditButtonContainer>
      <SnapshotTable projectId={projectId} />
    </Container>
  );
};

const Container = styled.div`
  margin: ${({ theme }) => theme.margin[24]} ${({ theme }) => theme.margin[40]};
`;

const EditButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  line-height: ${(props) => props.theme.margin[32]};
`;

const StyledTabButton = styled(TabIconTextButton)`
  min-width: 8rem;
`;

const SpinnerDiv = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export default ReportingView;
