import Big from 'big.js';

import { ActualCost } from '../store/reducers/actualCost';
import { ActualCostLine } from '../store/reducers/actualCostLine';
import { ArrivalRow } from '../store/reducers/arrivalRow';
import { BatchGroup } from '../store/reducers/batchGroup';
import { InvoiceHeader } from '../store/reducers/invoiceHeader';
import { InvoiceLine } from '../store/reducers/invoiceLine';
import { OrderChangeLogEntry } from '../store/reducers/order/changeLogEntries';
import { OrderSnapshot } from '../store/reducers/order/snapshots';
import { PaymentProgramRowGroup } from '../store/reducers/paymentProgramRowGroup';
import { APIProject } from '../store/reducers/project';
import { Revenue } from '../store/reducers/revenue/revenue';
import { Snapshot } from '../store/reducers/snapshot';
import { TargetChangeLogEntry } from '../store/reducers/target/changeLogEntries';
import { WorkPackageGroup } from '../store/reducers/workPackageGroup';

import {
  AnalysisRow,
  AnalysisGroup,
  TargetRow,
  TargetRowHierarchyEntry,
} from '../store/actions';
import { OrderChatMessage } from '../store/actions/order/chatMessages';
import { OrderOption } from '../store/actions/order/options';

import {
  APIOrder,
  APITopic,
  APIArrival,
  APIProcurementArea,
  APIOrderRow,
  APIWorkPackage,
  APIPurchaseInvoiceAttachment,
  APITask,
} from '../types/api';
import { NotificationFeedItem } from '../types/general';

import { BackendError } from '../utils/api';

export const fillRevenue = (partialRevenue: Partial<Revenue>): Revenue => ({
  id: '1',
  projectId: '1',
  paymentBatchGroupId: '1',
  paymentProgramRowGroupId: '1',

  batchCode: 'A1',
  description: 'Test revenue',
  unit: 'kpl',

  quantity: new Big(2),
  unitPrice: new Big(44),
  vat: new Big(0),
  actualizedBilling: new Big(0),

  grossPrice: new Big(88),
  netPrice: new Big(88),

  status: 'Preliminary',
  isBilled: false,

  billingDate: new Date('2020-01-01:12:00'),

  analysisRowIds: ['1'],

  isDeleted: false,
  isDeletable: true,

  updatedAt: new Date('2021-01-01T12:00'),

  predictionChangeFromLatest: new Big(0),
  previousBillingDateFromLatest: new Date('2020-01-01:12:00'),

  ...partialRevenue,
});

export const fillBatchGroup = (partialBatchGroup: Partial<BatchGroup>) => ({
  id: '1',
  paymentBatchGroupCode: '1',
  description: 'Test batch group',

  updatedAt: '2021-01-01T12:00',
  createdAt: '2021-01-01T12:00',

  ...partialBatchGroup,
});

export const fillPaymentProgramRowGroup = (
  partialGroup: Partial<PaymentProgramRowGroup>
) => ({
  isDeletable: false,
  isDeleted: false,
  paymentProgramRowIds: ['1'],

  id: '1',
  projectId: '1',
  paymentBatchGroupId: '1',
  code: '1',
  description: 'Test group',

  updatedAt: new Date('2021-01-01T12:00'),
  createdAt: new Date('2021-01-01T12:00'),

  ...partialGroup,
});

export const fillOrder = (partialOrder: Partial<APIOrder>): APIOrder => ({
  id: '1',
  projectId: '1',
  procurementAreaId: '1234',
  defaultWorkPackageId: '123',
  rowNumber: 1,
  orderNumber: '2003510-06610',
  name: 'Testi order',
  vatCode: null,
  costType: null,
  accountingAccount: null,
  paymentTerm: null,
  contractor: 'Testi Oy',
  isDeleted: false,
  createdAt: '2020-08-04T15:15:40.059Z',
  updatedAt: '2020-08-04T15:15:40.059Z',
  topicIds: [],
  arrivalIds: [],
  purchaseInvoiceHeaderIds: [],
  actualCostIds: [],
  targetTotal: new Big(0),
  additionalTargetTotal: new Big(0),
  predictionTotal: new Big(0),
  contractTotal: new Big(0),
  receivedTotal: new Big(0),
  reservesTotal: new Big(0),
  predictionChangeFromLatest: new Big(0),
  changeOrdersTotal: new Big(0),
  invoicesUnsettledCount: new Big(0),
  invoicesUnsettledTotal: new Big(0),
  invoiceComplaintsTotal: new Big(0),
  invoiceComplaintsCount: new Big(0),
  invoiceCorrectionsTotal: new Big(0),
  invoiceCorrectionsCount: new Big(0),
  actualcostsPendingTotal: new Big(0),
  actualcostsPendingCount: new Big(0),
  visibleCode: '000-00',
  statusId: '1',
  isDeletable: false,
  isSpecified: true,
  ...partialOrder,
});

export const fillTopic = (partialTopic: Partial<APITopic>): APITopic => ({
  id: '1',
  workPackageId: '1',
  orderId: '123',
  name: 'Oletusaihe',
  isDeleted: false,
  defaultTopic: false,
  orderRowIds: [],
  predictionChangeFromLatest: new Big(1000),
  changeOrdersTotal: new Big(0),
  reservesTotal: new Big(0),
  targetTotal: new Big(2000),
  additionalTargetTotal: new Big(0),
  contractTotal: new Big(0),
  updatedAt: '2020-08-04T15:15:40.075Z',
  createdAt: '2020-08-04T15:15:40.075Z',
  ...partialTopic,
});

export const fillTasks = (partialTask: Partial<APITask>): APITask => ({
  id: '1',
  assignerUserId: '1',
  assigneeUserId: '44',
  purchaseInvoiceHeaderId: '1',
  projectId: '1',
  createdAt: '2020-08-05T13:03:47.250Z',
  updatedAt: '2020-08-05T13:03:47.250Z',
  description: 'Valtuuskunta',
  statusId: '1',
  isDeleted: false,
  ...partialTask,
});

export const fillArrival = (
  partialArrival: Partial<APIArrival>
): APIArrival => ({
  id: '1',
  isDeleted: false,
  userId: '1000',
  userName: 'user 1',
  orderId: '123',
  rowNumber: 1,
  createdAt: '2020-08-05T13:03:47.250Z',
  updatedAt: '2020-08-05T13:03:47.250Z',
  arrivalRowIds: [],
  ...partialArrival,
});

export const fillProject = (
  partialProject: Partial<APIProject>
): APIProject => ({
  id: '1',
  companyId: '1234',
  name: 'Test project',
  code: '2003510',
  status: '1',
  isClosed: false,
  procurementAreaIds: [],
  workPackageIds: ['1'],
  simplified: false,

  receivedTotal: new Big('90000'),
  targetTotal: new Big('100000'),
  additionalTargetTotal: new Big('10000'),
  costPredictionTotal: new Big('100000'),
  contractTotal: new Big('50000'),
  changeOrdersTotal: new Big('40000'),
  reservesTotal: new Big('10000'),
  revenueTotal: new Big('120000'),

  latestSnapshotTargetTotal: new Big('100000'),
  latestSnapshotCostsTotal: new Big('95000'),
  latestSnapshotContractTotal: new Big('45000'),
  latestSnapshotChangeOrdersTotal: new Big('40000'),
  latestSnapshotReservesTotal: new Big('10000'),
  latestSnapshotRevenueTotal: new Big('120000'),

  targetChangeFromLatest: new Big('0'),
  costPredictionChangeFromLatest: new Big('5000'),
  contractChangeFromLatest: new Big('5000'),
  changeOrdersChangeFromLatest: new Big('0'),
  reservesChangeFromLatest: new Big('0'),
  revenuePredictionChangeFromLatest: new Big('0'),
  nextgenProjectId: null,
  nextgenDefaultScheduleViewId: null,

  externalDimensionsUpdateEnabled: false,
  externalPaymentProgramUpdateEnabled: false,
  externalTargetUpdateEnabled: false,
  externalOrderRowUpdateEnabledByDefault: false,
  ...partialProject,
});

export const fillInvoiceLine = (
  partialInvoiceLine: Partial<InvoiceLine>
): InvoiceLine => ({
  id: '1',
  purchaseInvoiceLineExternalId: '20f3cfed163a43e8994424c212a35356-1',
  rowIndex: 1,
  purchaseInvoiceExternalId: '20f3cfed163a43e8994424c212a35356',
  orderRowId: null,
  arrivalRowId: null,
  lineType: 'type1',
  purchaseOrderNumber: '1234',
  purchaseOrderLineNumber: '1',
  productCode: 'PC-4321',
  productName: 'gravel',
  quantity: new Big('1'),
  unit: 'kpl',
  netPrice: new Big('1000'),
  netTotal: new Big('1000'),
  grossPrice: new Big('1000'),
  grossTotal: new Big('1000'),
  taxPercent: new Big('0'),
  taxSum: new Big('0'),
  discountSum: new Big('0'),
  discountPercent: new Big('0'),
  additionalCostName: '',
  additionalCostKeyword: '',
  createdAt: new Date('2020-08-06T11:29:53.661Z'),
  updatedAt: new Date('2020-08-06T11:29:53.661Z'),
  ...partialInvoiceLine,
});

export const fillWorkPackage = (
  partialWorkPackage: Partial<APIWorkPackage>
): APIWorkPackage => ({
  id: '1',
  projectId: '1',
  name: 'Test Work Package',
  code: 'AABB',
  endDate: '2022-03-08T01:20:17.978Z',
  startDate: '2022-03-08T01:20:17.978Z',
  workPackageGroupId: '1',
  description: 'A talon tasoitukset',
  topicIds: [],
  scheduleTaskIds: [],
  latestSnapshotTotal: '0',
  predictionTotal: new Big(8_532_322.51),
  ordersTotal: new Big(5_000),
  receivedTotal: new Big(4_939_505_142.06),
  notOrderedTotal: new Big(10),
  predictionChangeFromLatest: new Big(10_169_904),
  contractTotal: new Big(4_946_032_897.4),
  changeOrdersTotal: new Big(268_288.83),
  reservesTotal: new Big(-4_937_768_863.72),
  targetTotal: new Big(10_169_904),
  additionalTargetTotal: new Big(1000),
  isDeleted: false,
  isSpecified: true,
  workSectionClassId: null,
  workPackageVirtualSpaceLinkages: [],
  ...partialWorkPackage,
});

export const fillWorkPackageGroup = (
  partialWorkPackageGroup: Partial<WorkPackageGroup>
): WorkPackageGroup => ({
  id: '1',
  projectId: '1337',
  name: 'Test procurement area',
  code: '001',
  workPackageIds: [],
  ...partialWorkPackageGroup,
});

export const fillProcurementArea = (
  partialProcurementArea: Partial<APIProcurementArea>
): APIProcurementArea => ({
  id: '1',
  isDeleted: false,
  projectId: '1337',
  name: 'Test procurement area',
  code: '001',
  orderIds: [],
  ...partialProcurementArea,
});

export const fillArrivalRow = (
  partialArrivalRow: Partial<ArrivalRow>
): ArrivalRow => ({
  id: '10',
  isDeleted: false,
  arrivalId: '90',
  orderRowId: '123',
  orderId: '100',
  quantity: new Big('1000'),
  unitPrice: new Big('54.5'),
  purchaseInvoiceHeaderId: null,
  actualCostId: null,
  createdAt: new Date('2020-08-06T11:29:53.661Z'),
  updatedAt: new Date('2020-08-06T11:29:53.661Z'),
  accountId: null,
  vatCodeId: null,
  description: null,
  ...partialArrivalRow,
});

export const fillOrderRow = (
  partialOrderRow: Partial<APIOrderRow>
): APIOrderRow => ({
  id: '1',
  orderId: '1234',
  topicId: '10',
  rowNumber: 1,
  description: 'Test orderRow',
  productCode: '123',
  quantity: new Big(1500),
  unit: 'm2',
  unitPrice: new Big(23.659),
  arrivalQuantity: new Big(1000),
  arrivalTotal: new Big(23659),
  statusId: '2',
  isDeleted: false,
  isRemovable: true,
  isEditable: true,
  isPriceEditable: true,
  createdAt: '2020-08-07T11:50:37.110Z',
  updatedAt: '2020-08-07T11:50:37.110Z',
  analysisListItemIds: ['1'],
  targetRowIds: [],
  arrivalRowIds: ['10'],
  ...partialOrderRow,
});

export const fillTargetRow = (
  partialTargetRow: Partial<TargetRow>
): TargetRow => ({
  id: '1',

  orderId: '1234',
  topicId: '10',
  orderRowId: '1',

  referenceNumber: '1.1.1.1.1000 1',
  description: 'Test targetRow',

  quantity: new Big(1500),
  unitPrice: new Big(23.659),
  unit: 'm2',
  totalPrice: new Big(35488.5),

  isDeleted: false,
  isOriginal: true,
  isAntiRow: false,
  targetRowHierarchyEntryId: '1',
  projectId: '1',
  createdAt: new Date('2020-08-07T11:50:37.110Z'),
  updatedAt: new Date('2020-08-07T11:50:37.110Z'),

  isDeletable: false,
  isSplitFrom: null,
  isDisabled: false,
  analysisListItemIds: ['1'],
  externalCode: '1-1',
  externalHierarchyEntryId: '1-1-1',
  ...partialTargetRow,
});

export const fillTargetRowHierarchyEntry = (
  partialTargetRowHierarchyEntry: Partial<TargetRowHierarchyEntry>
): TargetRowHierarchyEntry => ({
  id: '1',

  projectId: '1',
  parentId: null,

  referenceNumber: '1.',
  description: 'Test hierarchy entry',
  externalId: '1-1-1',
  externalParentId: null,

  quantity: new Big(1500),
  unitPrice: new Big(23.659),
  unit: 'm2',

  isDeleted: false,

  childIds: [],
  targetRowIds: [],

  depth: 1,
  totalAmount: new Big(35488.5),

  createdAt: new Date('2020-08-07T11:50:37.110Z'),
  updatedAt: new Date('2020-08-07T11:50:37.110Z'),
  ...partialTargetRowHierarchyEntry,
});

export const fillInvoiceHeader = (
  partialInvoiceHeader: Partial<InvoiceHeader>
): InvoiceHeader => ({
  id: '123',
  isDeleted: false,
  orderId: '6789',
  purchaseInvoiceExternalId: '20f3cfed163a43e8994424c212a35356',
  projectId: '1',
  supplierId: null,
  supplierName: null,
  orderDate: new Date('2019-09-12T21:00:00.000Z'),
  postingDate: new Date('2019-09-03T21:00:00.000Z'),
  paymentDate: new Date('2019-09-17T21:00:00.000Z'),
  dueDate: new Date('2019-09-17T21:00:00.000Z'),
  documentDate: new Date('2019-09-03T21:00:00.000Z'),
  currencyCode: 'EUR',
  currencyFactor: new Big('1.0'),
  amount: new Big('22972.01'),
  amountIncludingVat: new Big('28485.29'),
  invoiceNumber: 'KOL-2003222',
  yourReference: null,
  comment: null,
  vendorOrderNo: '12345',
  vendorInvoiceNo: '58/2019',
  canceled: false,
  paid: true,
  approved: true,
  approvalDate: new Date('2019-09-03T21:00:00.000Z'),
  remainingAmount: new Big('0'),
  invoiceLink: 'https://example-url.com/invoice.pdf',
  messageType: '0',
  messageTypeText: 'Viitenro',
  invoiceMessage: '2189',
  invoiceMessage2: null,
  statusId: '2',
  handlingState: 'TooMuchReceived',
  handleNetAmountPending: new Big('100'),
  handleVatAmountPending: new Big('0'),
  createdAt: new Date('2020-08-26T11:24:50.370Z'),
  updatedAt: new Date('2020-08-26T11:24:50.370Z'),
  purchaseInvoiceLineIds: [],
  purchaseInvoiceAttachmentIds: [],
  latestUpdater: null,
  latestStatusChangeAt: null,
  isMovable: false,
  isSpreadAvailable: false,
  ...partialInvoiceHeader,
});

export const fillOrderLevelNotificationFeedItem = (
  partialNotificationFeedItem: Partial<NotificationFeedItem>
): NotificationFeedItem => ({
  createdAt: new Date('2020-01-01T01:00:00.000Z'),
  description: 'Lasku käsittelemättä',
  id: '100511',
  isDeleted: false,
  isRead: true,
  notificationType: '1',
  firstDueDate: new Date('2020-01-01T01:00:00.000Z'),
  order: {
    id: '1334',
    label: 'Order',
  },
  project: { id: '984', label: 'Project' },
  purchaseInvoiceHeaders: [
    {
      id: '427',
      orderId: '1334',
      orderName: 'Order',
      amount: new Big('100'),
      attachment: 'http://',
      vendorInvoiceNumber: '12124',
      dueDate: new Date('2020-01-01T01:00:00.000Z'),
      taskStatus: null,
    },
  ],
  actualCosts: [
    {
      id: '11',
      amount: new Big('200'),
      attachment: 'http://',
      documentNumber: '44343',
      date: new Date('2020-01-01T01:00:00.000Z'),
    },
  ],
  orderChatMessages: [
    {
      id: '100999',
      senderId: '12123',
      senderName: 'John Doe',
      text: 'test message from John Doe',
    },
  ],
  updatedAt: new Date('2020-01-01T01:00:00.000Z'),
  userId: '100007',
  ...partialNotificationFeedItem,
});

export const fillComplaintPendingNotificationFeedItem = (
  partialNotificationFeedItem: Partial<NotificationFeedItem>
): NotificationFeedItem => ({
  createdAt: new Date('2020-01-01T01:00:00.000Z'),
  description: 'Reklamoidut laskut',
  id: '100512',
  isDeleted: false,
  isRead: true,
  notificationType: '3',
  order: null,
  project: { id: '984', label: 'Project' },
  firstDueDate: new Date('2020-01-01T01:00:00.000Z'),
  purchaseInvoiceHeaders: [
    {
      id: '428',
      orderId: '1334',
      orderName: 'Order',
      amount: new Big('300'),
      attachment: 'http://',
      vendorInvoiceNumber: '12125',
      dueDate: new Date('2020-01-01T01:00:00.000Z'),
      taskStatus: null,
    },
  ],
  actualCosts: [],
  orderChatMessages: [],
  updatedAt: new Date('2020-01-01T01:00:00.000Z'),
  userId: '100007',
  ...partialNotificationFeedItem,
});

export const fillActualCost = (
  partialActualCost: Partial<ActualCost>
): ActualCost => ({
  id: '',
  entryNo: 'someString',
  orderId: '123',
  projectId: '1',
  date: new Date('2020-01-01T01:00:00.000Z'),
  documentNumber: '11-22',
  description: 'Description field',
  amount: new Big('178'),
  createdAt: new Date('2020-08-26T11:24:50.370Z'),
  updatedAt: new Date('2020-08-26T11:24:50.370Z'),
  statusId: '1', // NOT handled actual cost
  supplierId: null,
  supplierName: null,
  invoiceLink: 'someLink',
  handleAmountPending: new Big(100),
  handlingState: 'TooMuchReceived',
  actualCostsDetailLineIds: ['1'],
  actualCostsAttachmentIds: [],
  latestUpdater: null,
  latestStatusChangeAt: null,
  isMovable: true,
  comment: null,
  ...partialActualCost,
});

export const fillActualCostLine = (
  partialActualCostLine: Partial<ActualCostLine>
): ActualCostLine => ({
  id: '1',
  actualCostsDetailLineExternalId: '44f3cfed163a4448994424c214435356-1',
  actualCostsEntryNo: 'someString',
  orderRowId: null,
  arrivalRowId: null,
  costType: 'type1',
  description: 'concrete',
  quantity: new Big('100'),
  unit: 'm3',
  unitPrice: new Big('5.55'),
  accountCode: '',
  costControlItemCode: '',
  date: new Date('2020-08-06T11:29:53.661Z'),
  createdAt: new Date('2020-08-06T11:29:53.661Z'),
  updatedAt: new Date('2020-08-06T11:29:53.661Z'),
  ...partialActualCostLine,
});

export const fillPurchaseInvoiceAttachment = (
  partialPurchaseInvoiceAttachment: Partial<APIPurchaseInvoiceAttachment>
): APIPurchaseInvoiceAttachment => ({
  id: '',
  isDeleted: false,
  purchaseInvoiceExternalId: 'xyz',
  attachmentLink: 'http://example.com',
  description: 'A test invoice attachment',
  createdAt: new Date('2020-08-26T11:24:50.370Z'),
  updatedAt: new Date('2020-08-26T11:24:50.370Z'),
  ...partialPurchaseInvoiceAttachment,
});

export const authenticationError: BackendError = {
  status: 401,
  message: 'Unauthorized',
};

export const csrfError: BackendError = {
  status: 400,
  message: 'invalid csrf token',
};

export const fillAccount = (
  partialAccount: Partial<OrderOption>
): OrderOption => ({
  id: '',
  name: 'TEST VALUE',
  externalCode: 'aabbcc',
  createdAt: new Date('2020-08-26T11:24:50.370Z'),
  updatedAt: new Date('2020-08-26T11:24:50.370Z'),
  ...partialAccount,
});

export const fillAnalysisGroup = (
  partialAnalysisGroup: Partial<AnalysisGroup>
): AnalysisGroup => ({
  id: '1',
  projectId: '1',
  name: 'Group 1',
  customFieldType: 'SelectionList',
  analysisTypeId: '1',
  isOrderRowField: true,
  isTargetRowField: true,
  isPaymentProgramRowField: true,
  isDeleted: false,
  createdAt: new Date('2020-08-26T11:24:50.370Z'),
  updatedAt: new Date('2020-08-26T11:24:50.370Z'),
  orderRowsAmountTotal: new Big(0),
  targetRowsAmountTotal: new Big(0),
  arrivalsAmountTotal: new Big(0),
  paymentProgramRowsAmountTotal: new Big(0),
  attributeNames: ['Status', 'DueDate'],
  attributeIds: ['1', '2'],
  listItemIds: ['1'],
  ...partialAnalysisGroup,
});

export const fillAnalysisRow = (
  partialAnalysisRow: Partial<AnalysisRow>
): AnalysisRow => ({
  id: '',
  definitionId: '',
  projectId: '1',
  value: 'Some value',
  code: 'SOM123',
  orderNumber: 1,
  orderRowsAmount: new Big(0),
  targetRowsAmount: new Big(0),
  arrivalsAmount: new Big(0),
  paymentProgramRowsAmount: new Big(80),
  attributeValues: [],
  linkedOrderRowIds: [],
  linkedTargetRowIds: [],
  linkedPaymentProgramRowIds: [],
  linkedPurchaseInvoiceHeaderIds: [],
  linkedActualCostIds: [],
  createdAt: new Date('2020-08-26T11:24:50.370Z'),
  updatedAt: new Date('2020-08-27T11:24:50.370Z'),
  isDeleted: false,
  ...partialAnalysisRow,
});

export const fillChatMessage = (
  partialOrderChatMessage: Partial<OrderChatMessage>
): OrderChatMessage => ({
  id: '1',
  orderId: '100',
  senderId: '1',
  senderName: 'unknown',
  taggedUserIds: ['2', '3'],
  text: 'Enter text here',
  isDeleted: false,
  createdAt: new Date('2020-08-26T11:24:50.370Z'),
  updatedAt: new Date('2020-08-26T11:24:50.370Z'),
  ...partialOrderChatMessage,
});

export const fillOrderChangeLogEntry = (
  partialOrderChangeLogEntry: Partial<OrderChangeLogEntry>
): OrderChangeLogEntry => ({
  id: '1',
  userId: '1',
  userName: 'unknown',
  snapshotId: '1',
  timestamp: new Date('2021-08-26T11:24:50.370Z'),
  orderId: '100',
  orderRowId: '10000',
  orderRowNo: 1,
  orderRowDescription: 'order row sample',
  changeType: 'changeOfCosts',
  differenceForOrderTotal: new Big(100),
  quantityChangeInfo: '0 --> 100',
  ...partialOrderChangeLogEntry,
});

export const fillTargetChangeLogEntry = (
  partialTargetChangeLogEntry: Partial<TargetChangeLogEntry>
): TargetChangeLogEntry => ({
  id: '1',
  userId: '1',
  userName: 'unknown',
  snapshotId: '1',
  timestamp: new Date('2021-08-26T11:24:50.370Z'),
  orderId: '100',
  targetRowId: '10000',
  referenceNo: '1.1.1.1.1000',
  targetRowDescription: 'target row sample',
  changeType: 'changeOfCosts',
  differenceForOrderTotal: new Big(100),
  quantityChangeInfo: '0 --> 100',
  ...partialTargetChangeLogEntry,
});

export const fillOrderSnapshot = (
  partialOrderSnapshot: Partial<OrderSnapshot>
): OrderSnapshot => ({
  snapshotId: '1',
  snapshotDescription: '2021-08',
  companyReportingPeriodDescription: '2021-08',
  orderId: '1',
  projectId: '1',
  predictionChangeBeforeLocking: new Big(400),
  targetChangeBeforeLocking: new Big(0),
  arrivalsChangeBeforeLocking: new Big(0),
  targetTotal: new Big(900),
  additionalTargetTotal: new Big(10),
  costPredictionTotal: new Big(900),
  contractTotal: new Big(300),
  changeOrdersTotal: new Big(400),
  reservesTotal: new Big(200),
  orderIsDeleted: false,
  orderName: 'Order name here',
  orderVisibleCode: '13330',
  current: false,
  createdAt: new Date('2021-08-26T11:24:50.370Z'),
  updatedAt: new Date('2021-08-26T11:24:50.370Z'),
  ...partialOrderSnapshot,
});

export const fillSnapshot = (partialSnapshot: Partial<Snapshot>): Snapshot => ({
  id: '1',
  userId: '1',
  userName: 'Test user',
  projectId: '1',
  description: 'Insert description here',
  companyReportingPeriodDescription: '2022-01',
  predictionChangeBeforeLocking: new Big(400),
  targetChangeBeforeLocking: new Big(0),
  revenuePredictionChangeBeforeLocking: new Big(0),
  targetTotal: new Big(1000000),
  additionalTargetTotal: new Big(10000),
  costPredictionTotal: new Big(1000400),
  contractTotal: new Big(1000000),
  changeOrdersTotal: new Big(400),
  reservesTotal: new Big(0),
  revenueTotal: new Big(1100000),
  isDeleted: false,
  statusId: '1',
  createdAt: new Date('2021-08-26T11:24:50.370Z'),
  updatedAt: new Date('2021-08-26T11:24:50.370Z'),
  snapshotTypeId: '1',
  snapshotTypeComment: '',
  ...partialSnapshot,
});
