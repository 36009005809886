import { RawAPIUpdatedEntities, APIUpdatedEntities } from '../../../types/api';
import { mapRawUpdatedEntities } from '../../../types/mappers';

import { makeAction, ExtractActionTypes } from '../../../utils/actionCreators';
import {
  PUT,
  BackendError,
  apiErrorHandlingWithDecode,
} from '../../../utils/api';
import { createAsyncThunk } from '../../../utils/thunk';

import { isLoading } from '../../reducers/order/moveOrderRows';

const actionCreators = {
  ...makeAction('moveOrderRowsToTopicStarted')<{
    requestId: string;
  }>(),
  ...makeAction('moveOrderRowsToTopicSuccess')<
    {
      requestId: string;
      orderRowIds: string[];
    } & APIUpdatedEntities
  >(),
  ...makeAction('moveOrderRowsToTopicFailure')<{
    requestId: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('moveOrderRowsToOrderStarted')<{
    requestId: string;
  }>(),
  ...makeAction('moveOrderRowsToOrderSuccess')<
    {
      requestId: string;
      orderRowIds: string[];
    } & APIUpdatedEntities
  >(),
  ...makeAction('moveOrderRowsToOrderFailure')<{
    requestId: string;

    error: BackendError | undefined;
  }>(),
};

export type MoveOrderRowsAction = ExtractActionTypes<typeof actionCreators>;

const {
  moveOrderRowsToTopicStarted,
  moveOrderRowsToTopicSuccess,
  moveOrderRowsToTopicFailure,
  moveOrderRowsToOrderStarted,
  moveOrderRowsToOrderSuccess,
  moveOrderRowsToOrderFailure,
} = actionCreators;

const requestMoveOrderRowsToTopic = async ({
  topicId,
  orderRowIds,
}: {
  topicId: string;
  orderRowIds: string[];
}): Promise<APIUpdatedEntities> => {
  const response = await PUT<RawAPIUpdatedEntities>(
    `v1/topics/${topicId}/move-order-rows`,
    {
      orderRowIds,
    }
  );

  return mapRawUpdatedEntities(response);
};

export const moveOrderRowsToTopic = ({
  requestId,
  topicId,
  orderRowIds,
}: {
  requestId: string;
  topicId: string;
  orderRowIds: string[];
}) =>
  createAsyncThunk(requestMoveOrderRowsToTopic, {
    args: [{ topicId, orderRowIds }],
    initialAction: moveOrderRowsToTopicStarted({ requestId }),
    isPending: isLoading(requestId),
    failureActionCreator: (error) =>
      moveOrderRowsToTopicFailure({
        requestId,
        error: apiErrorHandlingWithDecode(error),
      }),
    successActionCreator: (updatedEntities) =>
      moveOrderRowsToTopicSuccess({
        requestId,
        orderRowIds,
        ...updatedEntities,
      }),
  });

const requestMoveOrderRowsToOrder = async ({
  orderId,
  orderRowIds,
}: {
  orderId: string;
  orderRowIds: string[];
}): Promise<APIUpdatedEntities> => {
  const response = await PUT<RawAPIUpdatedEntities>(
    `v1/orders/${orderId}/move-order-rows`,
    {
      orderRowIds,
    }
  );

  return mapRawUpdatedEntities(response);
};

export const moveOrderRowsToOrder = ({
  requestId,
  orderId,
  orderRowIds,
}: {
  requestId: string;
  orderId: string;
  orderRowIds: string[];
}) =>
  createAsyncThunk(requestMoveOrderRowsToOrder, {
    args: [{ orderId, orderRowIds }],
    initialAction: moveOrderRowsToOrderStarted({ requestId }),
    isPending: isLoading(requestId),
    failureActionCreator: (error) =>
      moveOrderRowsToOrderFailure({
        requestId,
        error: apiErrorHandlingWithDecode(error),
      }),
    successActionCreator: (updatedEntities) =>
      moveOrderRowsToOrderSuccess({
        requestId,
        orderRowIds,
        ...updatedEntities,
      }),
  });
