import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Big from 'big.js';
import { FormikErrors, useFormik } from 'formik';
import styled from 'styled-components';
import * as Yup from 'yup';

import { AppState } from '../../../../../../store/reducers';
import { getInvoiceAssignedArrivalRowsByInvoiceId } from '../../../../../../store/reducers/arrivalRow';
import { InvoiceHeader } from '../../../../../../store/reducers/invoiceHeader';
import {
  getVatCodes,
  getAccounts,
  getDisallowedCombinations,
} from '../../../../../../store/reducers/order/options';
import { getOrderById } from '../../../../../../store/reducers/order/order';
import { getOrderRowById } from '../../../../../../store/reducers/orderRow';
import { selectHasUnassignedPresettledInvoiceRows } from '../../../../../../store/reducers/presettledInvoiceRows';
import { getActiveProjectId } from '../../../../../../store/reducers/ui';

import { createArrival, updateOrderRow } from '../../../../../../store/actions';
import { getDropDowns } from '../../../../../../store/actions/order/options';

import useTxt from '../../../../../../hooks/useTxt';

import {
  SecondaryButton,
  PrimaryButton,
  IconTextButton,
} from '../../../../../../components/Buttons';
import {
  RowSelectLabel,
  RowSelect,
} from '../../../../../../components/Input/Select';
import TextInput from '../../../../../../components/Input/TextInput';
import { Spinner } from '../../../../../../components/Loading';
import RemoteData from '../../../../../../components/RemoteData';
import Txt from '../../../../../../components/Txt';
import { TableErrorNotification } from '../../../../components/TableErrorNotification';

import * as big from '../../../../../../utils/big';
import { filterDisallowedCombinations } from '../../../../../../utils/general';
import { withDefault } from '../../../../../../utils/remoteData';

import { IconCheckmark } from '../../../../../../assets/svg';

import { TextId } from '../../../../../../localization';

import { generateUrl, routes } from '../../../../../../routes';

import {
  AmountArea,
  CenteredButtonGroup,
  DetailsSpan,
  Form,
} from './Components';
import { CrossOrderLabelAndSelect } from './LabelAndSelect';
import {
  constructArrivalRequest,
  calculateRemainingQuantity,
  calculateExcessQuantity,
  constructQuantityIncreaseRequest,
} from '../../receiveModeUtils';

type ManualEntryModalProps = {
  invoiceHeader: InvoiceHeader;
  onClose: () => void;
};

type FormValues = {
  vatCodeId: string;
  accountCodeId: string;
  receiveQuantity: string;
  orderId: string | null;
  orderRowId: string | null;
};

type QuantityError = {
  message: TextId;
  values:
    | {
        quantityAndUnit: string;
        excessQuantityAndUnit: string;
        changeAmountEur: string;
      }
    | undefined;
} | null;

const excessQuantityMessageId = 'validation.excessQuantity' as const;

const CrossOrderReceiveForm = ({
  invoiceHeader,
  onClose,
}: React.PropsWithChildren<ManualEntryModalProps>) => {
  const dispatch = useDispatch();

  const projectId = useSelector(getActiveProjectId) ?? '';

  const [isAutomaticQuantitySetState, setAutomaticQuantity] = React.useState<{
    [id: string]: boolean;
  }>({});

  const [isAutomaticOrderDropdownsSetState, setAutomaticOrderDropdowns] =
    React.useState<{
      [id: string]: boolean;
    }>({});

  const [orderRowQuantity, setOrderRowQuantity] = React.useState<string | null>(
    null
  );

  const disallowedCombinations = withDefault(
    useSelector(getDisallowedCombinations),
    []
  );

  const [quantityError, setQuantityError] = React.useState<QuantityError>(null);

  const arrivalRowsForInvoice = useSelector(
    getInvoiceAssignedArrivalRowsByInvoiceId(invoiceHeader.id)
  );

  const hasPresettledInvoiceRows = useSelector((state: AppState) =>
    invoiceHeader
      ? selectHasUnassignedPresettledInvoiceRows(invoiceHeader.id)(state)
      : false
  );

  const amountLeft = invoiceHeader.amount.minus(
    arrivalRowsForInvoice.reduce((acc, curr) => {
      return acc.add(curr.quantity.mul(curr.unitPrice));
    }, new Big(0))
  );

  const cancelText = useTxt('common.cancel');
  const submitText = useTxt('order.manualEntryModal.submit');

  const linkToProcurementText = useTxt(
    'order.manualEntryModal.linkToProcurement'
  );

  const chooseText = useTxt('common.choose');
  const sumText = useTxt('order.manualEntryModal.sum');

  const quantityRemainingText = useTxt(
    'order.manualEntryModal.quantityRemainingToBeReceived'
  );
  const receiveQuantityText = useTxt('order.manualEntryModal.receive.quantity');
  const withoutAccountText = useTxt('order.manualEntryModal.withoutAccount');

  const requiredFieldError = useTxt('order.manualEntryModal.requiredField');
  const hasToBeNumberError = 'order.manualEntryModal.hasToBeNumber' as const;

  /* NOTE! This is an antipattern: using two different validation mechanisms

Had to divide the validation to two places, because Yup handles string and
object validation well, but we have our own good validations built for
localized decimals (floats with comma as decimal marker). */

  const validateAmount = ({
    receiveQuantity,
  }: FormValues): FormikErrors<FormValues> => {
    const errors: FormikErrors<FormValues> = {};

    try {
      const valueAsBig = big.fromInputString(receiveQuantity);

      if (memoizedOrderRow) {
        const quantityLeft = calculateRemainingQuantity(memoizedOrderRow);

        const excessQuantity = calculateExcessQuantity(
          memoizedOrderRow,
          valueAsBig
        );

        if (!excessQuantity.eq(0)) {
          const changeAmountEur = big.priceFormat(
            excessQuantity.mul(memoizedOrderRow.unitPrice ?? 0)
          );

          errors.receiveQuantity = excessQuantityMessageId;

          setQuantityError({
            message: excessQuantityMessageId,
            values: {
              quantityAndUnit: `${big.amountFormat(quantityLeft)} ${
                memoizedOrderRow.unit
              }`,
              excessQuantityAndUnit: `${big.amountFormat(excessQuantity)} ${
                memoizedOrderRow.unit
              }`,
              changeAmountEur,
            },
          });
        }
      }
    } catch (err) {
      errors.receiveQuantity = hasToBeNumberError;
      setQuantityError({
        message: hasToBeNumberError,
        values: undefined,
      });
    }

    if (!errors.receiveQuantity) {
      setQuantityError(null);
    }

    return errors;
  };

  const validationForOtherFields = Yup.object().shape({
    vatCodeId: !hasPresettledInvoiceRows
      ? Yup.string().required(requiredFieldError)
      : undefined,
    accountCodeId: !hasPresettledInvoiceRows
      ? Yup.string().required(requiredFieldError)
      : undefined,
    orderId: Yup.string().required(requiredFieldError),
    orderRowId: Yup.string().required(requiredFieldError),
  });

  const accountOptions = useSelector(getAccounts);
  const vatCodeOptions = useSelector(getVatCodes);

  const resetFieldValue = (
    field: 'orderId' | 'orderRowId',
    values: FormValues,
    setValues: any
  ) => {
    const newValues: FormValues = {
      ...values,
      [field]: null,
      receiveQuantity: '0',
    };

    setValues({ ...newValues });
  };

  const formik = useFormik<FormValues>({
    initialValues: {
      vatCodeId: '',
      accountCodeId: '',
      orderId: null,
      orderRowId: null,
      receiveQuantity: '0',
    },
    validate: validateAmount,
    validateOnMount: true,
    validationSchema: validationForOtherFields,
    onSubmit: (values) => {
      if (values.orderId !== null && values.orderRowId !== null) {
        const body = constructArrivalRequest(
          {
            [values.orderRowId]: {
              amount: values.receiveQuantity,
              valid: true,
              allSelected: false,
            },
          },
          invoiceHeader.id
        );

        const arrivalRows = body.arrivalRows.map((arrivalRow) => ({
          ...arrivalRow,
          vatCodeId: values.vatCodeId === '' ? null : values.vatCodeId,
          accountCodeId:
            values.accountCodeId === '' ? null : values.accountCodeId,
        }));

        dispatch(createArrival(values.orderId, { ...body, arrivalRows }));
      }

      onClose();
    },
  });

  const orderRow = useSelector(getOrderRowById(formik.values.orderRowId ?? ''));

  const order = useSelector(getOrderById(formik.values.orderId ?? ''));

  const memoizedOrderRow = React.useMemo(() => orderRow, [orderRow]);

  const memoizedOrder = React.useMemo(() => order, [order]);

  // track changes to order row quantity (can be updated via error notification)
  React.useEffect(() => {
    const memoizedQuantity = memoizedOrderRow?.quantity
      ? memoizedOrderRow?.quantity.toString()
      : null;

    if (memoizedQuantity !== orderRowQuantity && !formik.isValidating) {
      formik.validateForm();
      setOrderRowQuantity(memoizedQuantity);
    }
  }, [memoizedOrderRow, formik, orderRowQuantity]);

  // recalculate receive quantity if order row changes
  React.useEffect(() => {
    const isAutomaticQuantitySet = memoizedOrderRow?.id
      ? isAutomaticQuantitySetState[memoizedOrderRow?.id]
      : false;

    if (memoizedOrderRow && !isAutomaticQuantitySet && !formik.isValidating) {
      const unitPrice = memoizedOrderRow.unitPrice ?? new Big(0);

      if (unitPrice.eq(0)) {
        formik.setFieldValue('receiveQuantity', '0');
        setAutomaticQuantity({ [memoizedOrderRow.id]: true });
      } else {
        formik.setFieldValue(
          'receiveQuantity',
          big.toInputString(amountLeft.div(unitPrice).round(4))
        );
        setAutomaticQuantity({ [memoizedOrderRow.id]: true });
      }
    }
  }, [memoizedOrderRow, formik, amountLeft, isAutomaticQuantitySetState]);

  // automatically set order dropdowns if order is selected
  React.useEffect(() => {
    const isAutomaticStateSet = memoizedOrder?.id
      ? isAutomaticOrderDropdownsSetState[memoizedOrder?.id]
      : false;

    if (memoizedOrder && !isAutomaticStateSet && !formik.isValidating) {
      const { vatCodeId, accountCodeId } = memoizedOrder;

      if (vatCodeId) {
        formik.setFieldValue('vatCodeId', vatCodeId);
      }

      if (accountCodeId) {
        formik.setFieldValue('accountCodeId', accountCodeId);
      }

      setAutomaticOrderDropdowns({ [memoizedOrder.id]: true });
    }
  }, [memoizedOrder, formik, isAutomaticOrderDropdownsSetState]);

  const accountingSum = big
    .fromInputString(formik.values.receiveQuantity, 0)
    .mul(memoizedOrderRow?.unitPrice ?? new Big(0));

  const quantityLeftTobeReceived = memoizedOrderRow
    ? calculateRemainingQuantity(memoizedOrderRow)
    : new Big(0);

  const onClickIncreaseAmount = () => {
    if (memoizedOrderRow) {
      dispatch(
        updateOrderRow(
          memoizedOrderRow.id,
          constructQuantityIncreaseRequest(
            memoizedOrderRow,
            formik.values.receiveQuantity
          )
        )
      );
    }
  };

  const history = useHistory();

  const navigateToSelectedOrder = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();

    if (formik.values.orderId) {
      const nextUrl = generateUrl({
        route: routes.ORDER,
        projectId,
        orderId: formik.values.orderId,
        viewMode: 'edit',
      });

      history.push(nextUrl);
    }
  };

  const selections = {
    ...(formik.values.vatCodeId ? { vatCodeId: formik.values.vatCodeId } : {}),
    ...(formik.values.accountCodeId
      ? { accountId: formik.values.accountCodeId }
      : {}),
  };

  return (
    <Form name="crossOrderReceiveForm" onSubmit={formik.handleSubmit}>
      <CrossOrderLabelAndSelect
        chooseText={chooseText}
        selectedOrderId={formik.values.orderId}
        selection={formik.values.orderId}
        entity="order"
        onChange={(e) => {
          const newValues: FormValues = {
            ...formik.values,
            orderId: e,
            receiveQuantity: '0',
          };

          formik.setValues(newValues);
        }}
        disabled={formik.isSubmitting}
        htmlId="crossOrderReceiveForm-order-select"
        resetFieldValue={() => {
          resetFieldValue('orderId', formik.values, formik.setValues);
        }}
      />
      <CrossOrderLabelAndSelect
        chooseText={chooseText}
        selectedOrderId={formik.values.orderId}
        selection={formik.values.orderRowId}
        entity="orderRow"
        onChange={(e) => {
          const newValues: FormValues = {
            ...formik.values,
            orderRowId: e,
            receiveQuantity: '0',
          };

          formik.setValues(newValues);
        }}
        disabled={formik.isSubmitting}
        htmlId="crossOrderReceiveForm-orderRow-select"
        resetFieldValue={() => {
          resetFieldValue('orderRowId', formik.values, formik.setValues);
        }}
      />
      {memoizedOrderRow ? (
        <RowSelectLabel htmlFor="quantityLeft">
          <span />
          <AmountLeftDiv>
            <AmountLeftSpan>{`${quantityRemainingText}: ${big.amountFormat(
              quantityLeftTobeReceived
            )} ${memoizedOrderRow?.unit ?? ''} (${big.priceFormat(
              quantityLeftTobeReceived.mul(memoizedOrderRow?.unitPrice ?? 0)
            )})`}</AmountLeftSpan>
          </AmountLeftDiv>
        </RowSelectLabel>
      ) : null}
      <RowSelectLabel htmlFor="vat-code-select">
        <Txt
          id="order.manualEntryModal.vatCode"
          values={{ required: hasPresettledInvoiceRows ? '' : '*' }}
          component="b"
        />
        <RemoteData data={vatCodeOptions} fetchData={getDropDowns}>
          {(vatCodes) => {
            const filteredVatCodes = filterDisallowedCombinations(
              vatCodes,
              'vatCodeId',
              disallowedCombinations,
              selections
            );

            return (
              <RowSelect
                required={!hasPresettledInvoiceRows}
                name="vatCodeId"
                data-testid="vat-code-select"
                value={formik.values.vatCodeId}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
                invalid={
                  !hasPresettledInvoiceRows && formik.values.vatCodeId === ''
                }
                id="vat-code-select"
              >
                <option value="">{chooseText}</option>
                {filteredVatCodes.map((code) => (
                  <option key={code.id} value={code.id}>
                    {`${code.externalCode} - ${code.name}`}
                  </option>
                ))}
              </RowSelect>
            );
          }}
        </RemoteData>
      </RowSelectLabel>
      <RowSelectLabel htmlFor="account-select">
        <Txt
          id="order.manualEntryModal.account"
          values={{ required: hasPresettledInvoiceRows ? '' : '*' }}
          component="b"
        />
        <RemoteData data={accountOptions} fetchData={getDropDowns}>
          {(accounts) => {
            const filteredAccounts = filterDisallowedCombinations(
              accounts,
              'accountId',
              disallowedCombinations,
              selections
            );

            return (
              <RowSelect
                required={!hasPresettledInvoiceRows}
                name="accountCodeId"
                data-testid="account-code-select"
                value={formik.values.accountCodeId}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
                invalid={
                  !hasPresettledInvoiceRows &&
                  formik.values.accountCodeId === ''
                }
                id="account-select"
              >
                <option value="">{chooseText}</option>
                {filteredAccounts.map((acc) => (
                  <option key={acc.id} value={acc.id}>
                    {`${acc.externalCode} ${acc.name}`}
                  </option>
                ))}
              </RowSelect>
            );
          }}
        </RemoteData>
      </RowSelectLabel>
      <AmountArea>
        <AmountRelativeContainer>
          <TextInput
            required
            id="cross-order-receive-form-amount-received"
            label={receiveQuantityText}
            name="receiveQuantity"
            value={formik.values.receiveQuantity}
            onChange={formik.handleChange}
            disabled={formik.isSubmitting || !memoizedOrderRow}
            transparentLabel={
              memoizedOrderRow?.unit && memoizedOrderRow.unit.length > 0
                ? memoizedOrderRow.unit
                : '\u00A0'
            }
            paddingBottom="0"
            errorMessage={formik.errors.receiveQuantity ? ' ' : null}
            customErrorElement={
              <ReceiveQuantityErrorElement
                quantityError={quantityError}
                onClickIncreaseAmount={onClickIncreaseAmount}
              />
            }
          />
        </AmountRelativeContainer>
        <DetailsSpan>{`${sumText}: ${big.priceFormat(
          accountingSum
        )}`}</DetailsSpan>
        <DetailsSpan>{`${withoutAccountText}: ${big.priceFormat(
          amountLeft
        )}`}</DetailsSpan>
      </AmountArea>
      <CenteredButtonGroup>
        {formik.values.orderId ? (
          <SecondaryButton
            type="button"
            disabled={formik.isSubmitting}
            onClick={navigateToSelectedOrder}
          >
            {formik.isSubmitting ? (
              <Spinner size="1rem" light />
            ) : (
              linkToProcurementText
            )}
          </SecondaryButton>
        ) : null}
        <SecondaryButton type="button" onClick={onClose}>
          {cancelText}
        </SecondaryButton>
        <PrimaryButton
          type="submit"
          disabled={!formik.isValid || formik.isSubmitting}
        >
          {formik.isSubmitting ? <Spinner size="1rem" light /> : submitText}
        </PrimaryButton>
      </CenteredButtonGroup>
    </Form>
  );
};

type ReceiveQuantityErrorElementProps = {
  quantityError: QuantityError;
  onClickIncreaseAmount: () => void;
};

const ReceiveQuantityErrorElement = ({
  quantityError,
  onClickIncreaseAmount,
}: ReceiveQuantityErrorElementProps) => {
  return (
    <>
      {quantityError?.message ? (
        <StyledTableErrorNotification data-testid="validation-error">
          <div>
            <Txt id={quantityError.message} values={quantityError.values} />
          </div>
          {quantityError?.message === excessQuantityMessageId ? (
            <ErrorButtonContainer>
              <IconTextButton
                icon={IconCheckmark}
                onClick={onClickIncreaseAmount}
              >
                <Txt id="common.increase" />
              </IconTextButton>
            </ErrorButtonContainer>
          ) : null}
        </StyledTableErrorNotification>
      ) : null}
    </>
  );
};

const ErrorButtonContainer = styled.div`
  margin-top: ${(props) => props.theme.margin[8]};
  border-top: 1px solid ${({ theme }) => theme.color.graphiteB96A};
  padding-top: ${(props) => props.theme.margin[8]};
`;

const AmountRelativeContainer = styled.div`
  position: relative;
`;

const StyledTableErrorNotification = styled(TableErrorNotification)`
  top: 80px;
  width: 100%;
`;

const AmountLeftDiv = styled.div`
  width: 400px;
`;

const AmountLeftSpan = styled.span`
  font-size: ${({ theme }) => theme.fontSize.small};
  color: ${({ theme }) => theme.color.graphiteB76};
`;

export default CrossOrderReceiveForm;
