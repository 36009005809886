import React from 'react';
import { useWindowSize } from 'react-use';
import useMeasure from 'react-use-measure';

import {
  getCoreRowModel,
  getFilteredRowModel,
  getExpandedRowModel,
  useReactTable,
  ExpandedState,
  FilterFnOption,
} from '@tanstack/react-table';
import { useVirtualizer } from '@tanstack/react-virtual';
import styled from 'styled-components';

import { TargetRowOrTargetRowHierarchyEntry } from '../../hooks/useTargetViewData';

import { ToolTip } from '../../../../components/Cell';

import { UpdateProcurementRow } from './utils';
import { searchFunctionWithFields } from '../../../../utils/search';
import { getSelectedRowsFlattenedWithSubrows } from '../../../../utils/tableUtils';

import PreviewTableRow from './PreviewTableRow';
import SelectionEditSection from './SelectionEditSection';
import Th from './Th';
import usePreviewTableColumns from './usePreviewTableColumns';

type TooltipProps =
  | {
      text?: string;
      place?: 'top' | 'bottom' | 'left' | 'right';
      parentMeasures?: {
        width: number;
        height: number;
        top: number;
        left: number;
        right: number;
        bottom: number;
        virtualRowStart: number | undefined;
      };
    }
  | undefined;

interface ToolTipContextProps {
  tooltipProps: TooltipProps;
  setToolTipProps: React.Dispatch<React.SetStateAction<TooltipProps>>;
  resetDataForTargetRows: (ids: string[]) => void;
}

export const PreviewTableContext = React.createContext<ToolTipContextProps>({
  tooltipProps: undefined,
  setToolTipProps: () => {},
  resetDataForTargetRows: () => {},
});

const filterFunction: FilterFnOption<UpdateProcurementRow> = (
  row,
  _,
  value
) => {
  const isPassed = searchFunctionWithFields(
    row.original,
    ['description', 'code', 'parentDescriptions', 'parentCodes'],
    value
  );

  return isPassed;
};

const UpdateProcurementsPreviewTable = ({
  parsedData,
  unparsedData,
  setData,
  parentMeasures,
  isAnythingSelected,
  setIsAnythingSelected,
  resetDataForTargetRows,
}: {
  parsedData: UpdateProcurementRow[];
  unparsedData: TargetRowOrTargetRowHierarchyEntry[];
  setData: React.Dispatch<
    React.SetStateAction<TargetRowOrTargetRowHierarchyEntry[]>
  >;
  parentMeasures: { width: number; height: number };
  isAnythingSelected: boolean;
  setIsAnythingSelected: (value: boolean) => void;
  resetDataForTargetRows: (ids: string[]) => void;
}) => {
  const columns = usePreviewTableColumns();

  const [globalFilter, setGlobalFilter] = React.useState('');
  const [expanded, setExpanded] = React.useState<ExpandedState>({});

  const [rowSelection, setRowSelection] = React.useState<
    Record<string, boolean>
  >({});

  const [tooltipProps, setToolTipProps] = React.useState<TooltipProps>();

  const isAnySelected = React.useMemo(() => {
    return Object.values(rowSelection).some((value) => value === true);
  }, [rowSelection]);

  React.useEffect(() => {
    if (isAnySelected !== isAnythingSelected) {
      setIsAnythingSelected(isAnySelected);
    }
  }, [isAnySelected, isAnythingSelected, setIsAnythingSelected]);

  const table = useReactTable({
    data: parsedData,
    columns,
    state: {
      globalFilter,
      expanded,
      rowSelection,
    },
    onGlobalFilterChange: setGlobalFilter,
    onExpandedChange: setExpanded,
    onRowSelectionChange: setRowSelection,
    getRowId: (row) => row.id,
    globalFilterFn: filterFunction,
    getSubRows: (row) => row.subRows,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    manualSorting: true,
    filterFromLeafRows: true,
  });

  const { rows } = table.getRowModel();

  const rowsLength = React.useMemo(() => rows.length, [rows]);

  const [measureRef, measures] = useMeasure({ debounce: 100 });
  const windowSize = useWindowSize();

  const tableContainerRef = React.useRef<HTMLDivElement | null>(null);

  const rowVirtualizer = useVirtualizer({
    count: rowsLength,
    getScrollElement: () => tableContainerRef.current,
    estimateSize: React.useCallback(() => 40, []), // estimate row height for accurate scrollbar dragging
    overscan: 40,
    measureElement:
      typeof window !== 'undefined' &&
      navigator.userAgent.indexOf('Firefox') === -1
        ? (element) => element?.getBoundingClientRect().height
        : undefined,
  });

  const measureElement = React.useCallback(
    (node: Element | null) => {
      return rowVirtualizer.measureElement(node);
    },
    [rowVirtualizer]
  );

  const containerWidth = React.useMemo(() => {
    return parentMeasures.width;
  }, [parentMeasures]); // 40px is the margin of target view container

  const containerHeight = React.useMemo(() => {
    return parentMeasures.height;
  }, [parentMeasures]);

  const virtualItems = rowVirtualizer.getVirtualItems();

  const visibleRows = React.useMemo(() => {
    return virtualItems.map((virtualRow) => ({
      ...virtualRow,
      row: rows[virtualRow.index],
    }));
  }, [virtualItems, rows]);

  // filtering and filterFromLeafRows: true doesn't work with getRowModel().flatRows
  const flatRows = getSelectedRowsFlattenedWithSubrows(
    table.getRowModel().rows
  );

  const emptyRowSelection = React.useCallback(() => {
    setRowSelection({});
  }, []);

  const calculateTooltipPlacement = (): TooltipDivProps => {
    let left: number | undefined;
    let right: number | undefined;

    if (
      tooltipProps?.parentMeasures?.left &&
      tooltipProps?.parentMeasures?.left < windowSize.width / 2
    ) {
      left = tooltipProps?.parentMeasures?.left
        ? tooltipProps?.parentMeasures?.left - measures.left + 24
        : 0;
    } else {
      right = tooltipProps?.parentMeasures?.right
        ? measures.right - tooltipProps?.parentMeasures?.right + 24
        : 0;
    }

    return {
      left,
      right,
      virtualRowStart: tooltipProps?.parentMeasures?.virtualRowStart,
    };
  };

  const tooltipPlacement = calculateTooltipPlacement();

  return (
    <>
      <div ref={measureRef}>
        <StyledContainer
          ref={tableContainerRef}
          height={containerHeight}
          width={containerWidth}
        >
          <PreviewTableContext.Provider
            value={{ tooltipProps, setToolTipProps, resetDataForTargetRows }}
          >
            <StyledTable>
              <StyledThead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <StyledTr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      const totalWidth = headerGroup.headers
                        .map((h) => h.getSize())
                        .reduce((a, b) => a + b, 0);

                      const thWidth = Math.round(
                        (header.getSize() / totalWidth) * (containerWidth - 20)
                      );

                      return (
                        <Th
                          key={header.id}
                          width={thWidth}
                          context={header.getContext()}
                          align={header.column.columnDef?.meta?.align}
                          borderWidthRight={
                            header.column.columnDef?.meta?.borderWidthRight
                          }
                          headerDef={header.column.columnDef.header}
                        />
                      );
                    })}
                  </StyledTr>
                ))}
              </StyledThead>
              <StyledTBody height={rowVirtualizer.getTotalSize()}>
                {visibleRows.map((virtualRow) => {
                  const isRowSelected = virtualRow.row.getIsSelected();

                  return (
                    <PreviewTableRow
                      key={virtualRow.row.id}
                      row={virtualRow.row}
                      virtualRow={virtualRow}
                      measureElement={measureElement}
                      containerWidth={containerWidth - 20}
                      isSelected={isRowSelected}
                    />
                  );
                })}
              </StyledTBody>
            </StyledTable>
            {tooltipProps ? (
              <StyledTooltip {...tooltipPlacement}>
                {tooltipProps?.text ?? ''}
              </StyledTooltip>
            ) : null}
          </PreviewTableContext.Provider>
          {isAnySelected ? (
            <SelectionEditSection
              selectionState={rowSelection}
              unparsedData={unparsedData}
              parsedData={flatRows}
              setData={setData}
              emptyRowSelection={emptyRowSelection}
            />
          ) : null}
        </StyledContainer>
      </div>
    </>
  );
};

type ContainerProps = {
  height: number;
  width: number;
};

const StyledContainer = styled.div<ContainerProps>`
  position: relative;

  height: ${({ height }) => height}px;
  width: ${({ width }) => width}px;

  display: flex;
  flex-wrap: wrap;
  align-content: space-between;

  overflow: auto;

  transform: translate3d(0, 0, 0);
`;

type TBodyProps = {
  height: number;
};

const StyledTBody = styled.tbody<TBodyProps>`
  position: relative;
  height: ${({ height }) => height}px;
  display: grid;
`;

const StyledTable = styled.table`
  display: grid;
  align-items: center;

  border-collapse: collapse;
  border-spacing: 0;

  table-layout: fixed;
`;

const StyledThead = styled.thead`
  position: sticky;
  top: 0;

  display: grid;

  background-color: white;

  z-index: 1;
`;

const StyledTr = styled.tr`
  border-bottom: 2px solid ${(props) => props.theme.color.rowBorder};
  height: 48px;
  width: 100%;
  display: flex;
`;

type TooltipDivProps = {
  left?: number;
  right?: number;
  virtualRowStart?: number;
};

const StyledTooltip = styled(ToolTip).attrs<TooltipDivProps>(
  ({ virtualRowStart }) => ({
    style: { transform: `translateY(${(virtualRowStart ?? 0) + 48}px)` },
  })
)<TooltipDivProps>`
  left: ${({ left }) => (left ? `${left}px` : 'auto')};
  right: ${({ right }) => (right ? `${right}px` : 'auto')};
  top: auto;

  width: 300px;

  display: inline-block;

  white-space: pre-line;

  pointer-events: none;
`;

export default UpdateProcurementsPreviewTable;
