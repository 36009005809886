import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { v4 } from 'uuid';

import { Revenue } from '../../../../store/reducers/revenue/revenue';

import { removeRevenueAnalysisLinkage } from '../../../../store/actions/analysis/revenueLinkage';

import useRouteParams from '../../../../hooks/useRouteParams';
import useTxt from '../../../../hooks/useTxt';

import { SecondaryRow } from '../../../../components/Table';
import UnlinkIconButton from '../../../../components/UnlinkIconButton';

import { priceFormat } from '../../../../utils/big';
import CAN, {
  CaslAnalysisDefinitionRequestParams,
} from '../../../../utils/caslUserPermissions';

import { IconToLinkedEntity } from '../../../../assets';

import { generateUrl, routes } from '../../../../routes';

import { RightPaddedCell, StyledCell } from '../ModalComponents';

type TableRowProps = {
  orderRow: Revenue;
  analysisRowId: string;
};

export const TableRow = ({
  analysisRowId,
  orderRow: { id, description, netPrice, batchCode },
}: TableRowProps) => {
  const dispatch = useDispatch();

  const { projectId } = useRouteParams();
  const requestId = v4();

  const linkedEntityAltText = useTxt('analysis.modal.linkedEntity.altText');

  const unlinkOrderRow = () =>
    dispatch(
      removeRevenueAnalysisLinkage({
        entityId: id,
        projectId,
        requestId,
        analysisRowId,
      })
    );

  const ability = new CaslAnalysisDefinitionRequestParams(projectId);
  const allowedUser = CAN('write', ability);

  return (
    <SecondaryRow key={id} data-testid="revenue-order-row-modal-order-row">
      <StyledCell>{`${batchCode}`}</StyledCell>
      <StyledCell>{description}</StyledCell>
      <StyledCell>
        <UnlinkIconButton
          onClick={unlinkOrderRow}
          testId={`unlink-analysis-row-${id}`}
          disabled={!allowedUser}
        />
      </StyledCell>
      <StyledCell>
        {allowedUser ? (
          <Link
            data-testid={`navigate-to-revenue-row-${id}`}
            to={generateUrl({
              route: routes.REVENUE_WITH_ORDER_ROW_FOCUSED,
              viewMode: 'edit',
              projectId,
              revenueId: id,
            })}
          >
            <img src={IconToLinkedEntity} alt={linkedEntityAltText} />
          </Link>
        ) : null}
      </StyledCell>
      <RightPaddedCell align="right">{priceFormat(netPrice)}</RightPaddedCell>
    </SecondaryRow>
  );
};
