import styled, { css } from 'styled-components';

export const ViewContainer = styled.div`
  ${({ theme }) => css`
    padding: ${theme.margin[24]} ${theme.margin[40]} ${theme.margin[16]}
      ${theme.margin[40]};
  `}
`;

type OrderViewProps = {
  outerBarOpen?: boolean;
};

// Shared base style for the receive pages bottom CONTENT area.
const OrderViewContentBase = styled.div<OrderViewProps>`
  margin: 0;
  padding: 0 ${(props) => (props.outerBarOpen ? '1rem' : '2.5rem')} 2rem 2.5rem;
`;

// Bottom content style for others than the receive mode.
export const OrderViewContent = styled(OrderViewContentBase)`
  padding-bottom: 7rem;
`;

// Bottom content style for receive mode.

export const OrderReceiveViewContent = styled(OrderViewContentBase)``;

// ensures that the Footer buttons can be scrolled so user input is not blocked
export const OrderViewEmptyDiv = styled.div`
  height: 5rem;
`;

// Shared base style for the receive pages bottom BUTTON area.
const OrderViewFooterBase = styled.div<OrderViewProps>`
  margin: 0;
  padding: 0 ${(props) => (props.outerBarOpen ? '1rem' : '2.5rem')} 2rem 2.5rem;

  /* Appearing animation */
  @keyframes slideUp {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0);
    }
  }
  will-change: transform;
  animation: 200ms ease-in 0s 1 slideUp;
`;

// Buttons container style for others than the receive mode.
export const OrderViewFooter = styled(OrderViewFooterBase)`
  position: fixed;
  bottom: 0;

  /*
    Width is wider by 11px in chrome and 6px in FF because
    divs with scrollbars do not affect viewport size and
    fixed positions rely on viewport.
  */
  left: calc(50vw - ${(props) => props.theme.sizes.sideNavWidth} - 5.5px);

  min-height: auto;

  display: flex;
  justify-content: center;
`;

// Buttons container style for receive mode.
export const OrderReceiveViewFooter = styled(OrderViewFooterBase)`
  min-height: 30vh;
`;

export const InputContainer = styled.div`
  padding: ${(props) => props.theme.margin[8]} 0;
`;

export const OrderRowLeftBorderContainer = styled.div`
  border-left: 1px solid ${(props) => props.theme.color.graphiteB76};
  padding-left: ${({ theme }) => theme.margin[16]};
  padding-right: ${({ theme }) => theme.margin[16]};
  white-space: nowrap;
`;

export const OrderRowRightPaddingContainer = styled.div`
  position: relative;

  padding-right: ${({ theme }) => theme.margin[24]};

  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-end;
`;

export const OrderRowSmallerRightPaddingContainer = styled.div`
  position: relative;

  padding-right: ${({ theme }) => theme.margin[8]};

  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-end;
`;

export const OrderRowLeftPaddingContainer = styled.div`
  position: relative;

  padding-left: ${({ theme }) => theme.margin[16]};

  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-end;
`;
