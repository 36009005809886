import type React from 'react';
import { AuthProvider, useFirebaseApp } from 'reactfire';

import { connectAuthEmulator, getAuth } from 'firebase/auth';

interface FirebaseAuthProviderProps {
  children?: React.ReactNode | React.ReactNode[];
}

function FirebaseAuthProvider({ children }: FirebaseAuthProviderProps) {
  const app = useFirebaseApp();
  const auth = getAuth(app);

  const EMULATOR_URL = process.env.REACT_APP_FIREBASE_AUTH_EMULATOR_URL;

  if (EMULATOR_URL !== '' && EMULATOR_URL !== undefined) {
    connectAuthEmulator(auth, EMULATOR_URL, { disableWarnings: true });
  }

  return <AuthProvider sdk={auth}>{children}</AuthProvider>;
}

export default FirebaseAuthProvider;
