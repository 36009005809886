import React from 'react';

import styled from 'styled-components';

import { IconTextButton } from '../Buttons';

const Typography = styled.span`
  margin: 0px;
  font-family: Graphik, Helvetica, Arial, sans-serif;
  line-height: 1.167;
`;

const Title = styled(Typography)`
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
  white-space: pre-line;
`;

export const AuthTitle = ({
  children,
  ...props
}: React.ComponentProps<typeof Title>) => {
  return <Title {...props}>{children}</Title>;
};

export const AuthText = styled(Typography)`
  font-size: 0.875rem;
  text-align: center;
`;

type AuthButtonProps = {
  backgroundColor?: string;
  hoverColor?: string;
  fontColor?: string;
  value?: string | number;
  name?: string;
};

export const AuthButton = styled(IconTextButton)<AuthButtonProps>`
  border-radius: 28px;
  box-shadow:
    rgba(0, 0, 0, 0.2) 0px 3px 1px -2px,
    rgba(0, 0, 0, 0.14) 0px 2px 2px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;

  padding: 0.5rem 1rem;

  height: 2.5rem;
  min-width: 64px;

  align-items: center;
  justify-content: normal;

  background-color: ${({ backgroundColor }) => backgroundColor ?? '#6750A4'};

  color: white;
  font-family: Graphik, Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.75;

  /* stylelint-disable selector-max-class -- disable temporary until good solution is found */
  .svg.button-icon {
    margin-left: 0;
    margin-right: 1rem;
  }
`;
