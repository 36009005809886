import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';

import { APIUpdatedEntities, RawAPIUpdatedEntities } from '../../../types/api';
import { mapRawUpdatedEntities } from '../../../types/mappers';

import { makeAction, ExtractActionTypes } from '../../../utils/actionCreators';
import {
  apiErrorHandlingWithDecode,
  BackendError,
  GET,
  PUT,
} from '../../../utils/api';
import { flow } from '../../../utils/function';
import * as remoteData from '../../../utils/remoteData';
import { createAsyncThunk, Thunk } from '../../../utils/thunk';

import { getAnalysisRowUpdateRequest } from '../../reducers/analysis/row';
import { getAnalysisSelectListItemsForProject } from '../../reducers/analysis/selectListItem';

const actionCreators = {
  ...makeAction('getAnalysisSelectListItemsStarted')<{ projectId: string }>(),
  ...makeAction('getAnalysisSelectListItemsFailure')<{
    projectId: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('getAnalysisSelectListItemsSuccess')<{
    projectId: string;
    analysisSelectListItems: AnalysisSelectListItem[];
  }>(),
  ...makeAction('putAnalysisAttributeValueStarted')<{
    requestId: string;
    projectId: string;
  }>(),
  ...makeAction('putAnalysisAttributeValueFailure')<{
    requestId: string;
    projectId: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('putAnalysisAttributeValueSuccess')<
    APIUpdatedEntities & { requestId: string; projectId: string }
  >(),
};
export const {
  getAnalysisSelectListItemsStarted,
  getAnalysisSelectListItemsFailure,
  getAnalysisSelectListItemsSuccess,
  putAnalysisAttributeValueStarted,
  putAnalysisAttributeValueFailure,
  putAnalysisAttributeValueSuccess,
} = actionCreators;

export type AnalysisSelectListItemAction = ExtractActionTypes<
  typeof actionCreators
>;

const apiAnalysisSelectListItem = t.exact(
  t.type({
    id: t.string,
    attributeId: t.string,
    value: t.string,
    isDeleted: t.boolean,
  })
);

export type AnalysisSelectListItem = t.TypeOf<typeof apiAnalysisSelectListItem>;

export async function decodeAnalysisSelectListItems(
  u: unknown
): Promise<AnalysisSelectListItem[]> {
  return tPromise.decode(t.array(apiAnalysisSelectListItem), u);
}

type ProjectId = {
  projectId: string;
};

const fetchAnalysisSelectionListItems = async ({
  projectId,
}: ProjectId): Promise<AnalysisSelectListItem[]> => {
  return decodeAnalysisSelectListItems(
    await GET(`v1/projects/${projectId}/analysis-attribute-list-items`)
  );
};

export const requestAnalysisSelectionListItems = ({ projectId }: ProjectId) =>
  createAsyncThunk(fetchAnalysisSelectionListItems, {
    args: [{ projectId }],
    isPending: flow(
      getAnalysisSelectListItemsForProject(projectId),
      remoteData.isLoading
    ),
    initialAction: getAnalysisSelectListItemsStarted({ projectId }),
    failureActionCreator: (error) =>
      getAnalysisSelectListItemsFailure({
        projectId,
        error: apiErrorHandlingWithDecode(error),
      }),
    successActionCreator: (analysisSelectListItems) =>
      getAnalysisSelectListItemsSuccess({
        projectId,
        analysisSelectListItems,
      }),
  });

type UpdateAnalysisAttributeParams = {
  attributeValueId: string;
  value: string | undefined;
  isSelectList: boolean;
  updatedAt: Date;
};

// If attribute is a select list, API expects the value with a different property name
const updateAnalysisAttributeValue = async ({
  attributeValueId,
  value,
  isSelectList,
  updatedAt,
}: UpdateAnalysisAttributeParams): Promise<APIUpdatedEntities> => {
  const response = await PUT<RawAPIUpdatedEntities>(
    `v1/custom-fields/list-item-attribute-values/${attributeValueId}`,
    {
      ...(isSelectList ? { attributeListItemId: value } : { value }),
      updatedAt,
    }
  );

  return mapRawUpdatedEntities(response);
};

type AnalysisAttributeUpdateOptions = {
  requestId: string;
  projectId: string;
  attributeValueId: string;
  value: string | undefined;
  isSelectList: boolean;
  forcedRequest?: boolean;
  updatedAt: Date;
};

export const requestAnalysisAttributeUpdate =
  ({
    requestId,
    projectId,
    attributeValueId,
    value,
    isSelectList = false,
    forcedRequest,
    updatedAt,
  }: AnalysisAttributeUpdateOptions): Thunk =>
  (dispatch) => {
    dispatch(
      createAsyncThunk(updateAnalysisAttributeValue, {
        args: [{ attributeValueId, value, isSelectList, updatedAt }],
        isPending: forcedRequest
          ? undefined
          : flow(getAnalysisRowUpdateRequest(requestId), remoteData.isLoading),
        initialAction: putAnalysisAttributeValueStarted({
          requestId,
          projectId,
        }),
        successActionCreator: (updatedEntities) =>
          putAnalysisAttributeValueSuccess({
            ...updatedEntities,
            requestId,
            projectId,
          }),
        failureActionCreator: (error) =>
          putAnalysisAttributeValueFailure({
            requestId,
            projectId,
            error: apiErrorHandlingWithDecode(error),
          }),
      })
    );
  };
