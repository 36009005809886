/* eslint-disable camelcase */
import { Reducer } from 'redux';

import { NextgenScheduleData } from '../../../types/api';
import {
  SchedulePageQuery,
  SchedulePageWorkPackagesQuery,
  TaskStatus,
  TaskType,
} from '../../../types/schedule';

import { BackendError } from '../../../utils/api';
import { flow } from '../../../utils/function';
import { isPresent } from '../../../utils/general';
import * as remoteData from '../../../utils/remoteData';
import { Selector } from '../utils';

import theme from '../../../styles/theme';
import { ActionTypes as Action } from '../../actionTypes';

export const NIL_UUID = '00000000-0000-0000-0000-000000000000';

const { statusColors } = theme.color;

type Err = BackendError | undefined;

type State = {
  requests: Partial<Record<string, remoteData.RemoteData<undefined, Err>>>;
  data: Record<string, NextgenScheduleData | null>;
};

const initialState: State = {
  requests: {},
  data: {},
};

const reducer: Reducer<State, Action> = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_NEXTGEN_SCHEDULE_DATA_STARTED': {
      const { projectId } = action.payload;
      const requests = { ...state.requests, [projectId]: remoteData.loading };

      return {
        ...state,
        requests,
      };
    }
    case 'GET_NEXTGEN_SCHEDULE_DATA_FAILURE': {
      const { projectId, error } = action.payload;

      const requests = {
        ...state.requests,
        [projectId]: remoteData.fail(error),
      };

      return { ...state, requests };
    }

    case 'GET_NEXTGEN_SCHEDULE_DATA_SUCCESS': {
      const { projectId, nextgenScheduleData } = action.payload;

      const requests = {
        ...state.requests,
        [projectId]: remoteData.succeed(undefined),
      };

      return {
        requests,
        data: { ...state.data, [projectId]: nextgenScheduleData },
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;

export function getRequestState(
  projectId: string
): Selector<remoteData.RemoteData['kind']> {
  return ({
    schedule: {
      nextgenScheduleData: {
        requests: { [projectId]: requestState = remoteData.notAsked },
      },
    },
  }) => requestState.kind;
}

export function isLoading(projectId: string): Selector<boolean> {
  return flow(
    getRequestState(projectId),
    (requestState) => requestState === 'Loading'
  );
}

export const getNextgenScheduleDataForProject: (
  projectId: string
) => Selector<remoteData.RemoteData<NextgenScheduleData | null>> =
  (projectId) =>
  ({
    schedule: {
      nextgenScheduleData: {
        requests: { [projectId]: requestState = remoteData.notAsked },
        data,
      },
    },
  }) =>
    remoteData.map(requestState, (_) => {
      const nextgenData = data[projectId];

      if (!nextgenData) {
        return null;
      }

      return nextgenData;
    });

export type WorkSectionClass = NonNullable<
  NonNullable<
    NonNullable<SchedulePageQuery['projectBySvId']>['work_sections_namespace']
  >['work_sections']
>[0];

export const getWorkSectionClasses: (
  projectId: string
) => Selector<WorkSectionClass[]> =
  (projectId) =>
  ({
    schedule: {
      nextgenScheduleData: { data },
    },
  }) => {
    const response = data[projectId];

    const nextgenData =
      response?.schedulePageData.projectBySvId?.work_sections_namespace
        ?.work_sections;

    if (!nextgenData) {
      return [];
    }

    return nextgenData;
  };

type WorkPackageCommon = {
  id: string;
  name: string;
  description: string | null | undefined;
  parent_id: string | null;
  work_section_id: string | null;
  virtual_space_id: string | null;
  statusColor?: string;
  tasks: ListTask[];
  nextgen_work_package_id: string;
  order_number?: number | undefined | null;
  instanceIds?: Set<string>;
};

type WorkPackageType = 'instance' | 'master' | 'sum' | 'initial';

type WorkPackageInstanceFragment = Omit<
  NonNullable<
    NonNullable<
      SchedulePageWorkPackagesQuery['scheduleView']['work_package_data_nested']['work_packages'][0]
    >['instances']
  >[0],
  '__typename'
>;

type WorkPackageInstanceData = Omit<WorkPackageInstanceFragment, 'tasks'> &
  WorkPackageCommon & {
    type: WorkPackageType;
  };

type WorkPackageMasterFragment = Omit<
  NonNullable<
    NonNullable<
      SchedulePageWorkPackagesQuery['scheduleView']['work_package_data_nested']['work_packages'][0]
    >['master']
  >[0],
  '__typename'
>;

type WorkPackageMasterData = Omit<WorkPackageMasterFragment, 'tasks'> &
  WorkPackageCommon & {
    type: WorkPackageType;
  };

export type NextgenWorkPackage =
  | WorkPackageInstanceData
  | WorkPackageMasterData;

export function getWorkPackageId(task: {
  id: string;
  virtual_space_id?: string | null;
}) {
  if (task.virtual_space_id == null) {
    return task.id;
  }

  return [task.id, task.virtual_space_id].join('_');
}

export function parseWorkPackageId(parsable: string) {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const [id, virtual_space_id = null] = parsable.split('_');

  return { id, virtual_space_id };
}

type TaskCommonDataFragment = Omit<
  NonNullable<
    NonNullable<
      SchedulePageWorkPackagesQuery['scheduleView']['work_package_data_nested']['work_packages']
    >[0]['tasks']
  >[0],
  '__typename'
>;

type TaskMasterDataFragment = NonNullable<
  NonNullable<
    NonNullable<
      SchedulePageWorkPackagesQuery['scheduleView']['work_package_data_nested']['work_packages']
    >[0]['master']
  >[0]['tasks']
>[0];

export type TaskInstanceDataFragment = NonNullable<
  NonNullable<
    NonNullable<
      SchedulePageWorkPackagesQuery['scheduleView']['work_package_data_nested']['work_packages']
    >[0]['instances']
  >[0]['tasks']
>[0];

type ListTask = Omit<TaskMasterDataFragment, '__typename'> &
  Omit<TaskCommonDataFragment, '__typename'> & {
    progress_percentage: number;
    s_low: number;
    s_high: number;
  };

export const isTaskCompleted = (task: Pick<ListTask, 'status'>) =>
  task.status === TaskStatus.Completed;

const hasIncompleteObstacles = (
  workPackage: Pick<NextgenWorkPackage, 'tasks' | 'status'>
) =>
  workPackage.tasks?.find(
    (task) => task.class_type === TaskType.Roadblock && !isTaskCompleted(task)
  ) != null;

const hasPreconditionsDone = (task: Pick<NextgenWorkPackage, 'tasks'>) => {
  return (
    task.tasks
      .filter((item) => item.class_type === TaskType.LegacyPrerequisite)
      .filter((item) => item.actual_end == null).length === 0
  );
};

function startedColors(
  wp: Pick<NextgenWorkPackage, 'tasks' | 'planned_end' | 'status'>
) {
  if (hasIncompleteObstacles(wp)) {
    return statusColors.obstacle;
  }

  return new Date(wp.planned_end) < new Date()
    ? statusColors.delayed
    : statusColors.started;
}

function doneColors(wp: Pick<NextgenWorkPackage, 'tasks' | 'status'>) {
  if (hasIncompleteObstacles(wp)) {
    return statusColors.obstacle;
  }

  return statusColors.done;
}

function plannedColors(
  task: Pick<NextgenWorkPackage, 'tasks' | 'planned_end' | 'status'>
) {
  if (hasIncompleteObstacles(task)) {
    return statusColors.obstacle;
  }

  if (new Date(task.planned_end) < new Date()) {
    return statusColors.delayed;
  }

  return hasPreconditionsDone(task)
    ? statusColors.readyToStart
    : statusColors.planned;
}

export function getStatusColor(
  task: Pick<NextgenWorkPackage, 'status' | 'tasks' | 'planned_end'>
) {
  switch (task.status) {
    case TaskStatus.Completed:
      return doneColors(task);
    case TaskStatus.InProgress:
      return startedColors(task);
    case TaskStatus.Planned:
      return plannedColors(task);
    default:
      return statusColors.planned;
  }
}

export const getWorkPackages: (
  projectId: string
) => Selector<NextgenWorkPackage[]> =
  (projectId) =>
  ({
    schedule: {
      nextgenScheduleData: { data },
    },
  }) => {
    const response = data[projectId];

    if (!response) {
      return [];
    }

    const virtualSpaces = (
      response.schedulePageData.projectBySvId?.virtual_spaces_namespaces
        ?.map((vs) => vs.virtual_spaces)
        .flat() ?? []
    ).filter(isPresent);

    const vsOrderNumbers = new Map(
      virtualSpaces.map((vs) => [vs.id, vs.order_number])
    );

    const wpOrderNumbers = new Map(
      response.schedulePageData.scheduleView.work_package_order.map((o) => [
        o.work_package_id,
        o.order_number,
      ])
    );

    const nextgenData =
      response.schedulePageWorkPackageData.scheduleView.work_package_data_nested
        .work_packages;

    if (!nextgenData) {
      return [];
    }

    function combineTasks(
      wp: WorkPackageInstanceFragment | WorkPackageMasterFragment,
      commonTasks: TaskCommonDataFragment[]
    ) {
      const tasks: Array<ListTask> = [];

      const workPackageTasks: (
        | TaskInstanceDataFragment
        | TaskMasterDataFragment
      )[] = wp.tasks ?? [];

      workPackageTasks.forEach((task) => {
        const commonTask = commonTasks.find((t) => t.id === task.id);

        if (!commonTask) {
          return;
        }

        // Whether or not this task is a placeholder to give the work package a time and a place. An "empty" work package instance in UI actually contains a placeholder task definition and task instance(s).
        // opposed to schedule frontend, these are the ones we are interested in
        if (!commonTask.placeholder) {
          tasks.push({
            s_high: wp.s_high,
            s_low: wp.s_low,
            progress_percentage: wp.progress_percentage,
            ...task,
            ...commonTask,
          });
        }
      });

      return { tasks };
    }

    const processedWorkPackageData: NextgenWorkPackage[] = [];

    const groupedWorkPackageInstances: Record<string, NextgenWorkPackage[]> =
      {};
    const groupedMasters: Record<string, NextgenWorkPackage[]> = {};

    function getInstanceIds(
      wp: NextgenWorkPackage,
      depth: number
    ): { instanceIds: Set<string> } {
      let instanceIds = new Set<string>();

      const instances = groupedWorkPackageInstances[wp.id] ?? [];

      for (const instance of instances) {
        instanceIds.add(instance.id);
      }

      for (const child of groupedMasters[wp.id] ?? []) {
        const ids = getInstanceIds(child, depth + 1);
        instanceIds = new Set([...instanceIds, ...ids.instanceIds]);
      }

      return { instanceIds };
    }

    for (const common of nextgenData) {
      if (common.master !== null && common.master.length === 1) {
        let type: WorkPackageType = common.is_sum ? 'sum' : 'master';

        for (const instance of common.instances) {
          if (instance.virtual_space_id === NIL_UUID) {
            if (type === 'master') {
              type = 'initial';
            }
          }

          if (instance.virtual_space_id !== NIL_UUID) {
            type = 'master';

            const mappedInstance = {
              ...instance,
              type: 'instance' as const,
              id: getWorkPackageId({
                id: common.id,
                virtual_space_id: instance.virtual_space_id,
              }),
              name: common.name,
              description: common.description,
              parent_id: common.id,
              work_section_id: common.work_section_id ?? null,
              nextgen_work_package_id: common.id,
              order_number: vsOrderNumbers.get(instance.virtual_space_id),
              ...combineTasks(instance, common.tasks),
            };

            const statusColor = getStatusColor(mappedInstance);

            processedWorkPackageData.push({ ...mappedInstance, statusColor });
            groupedWorkPackageInstances[common.id] ??= [];
            groupedWorkPackageInstances[common.id].push({
              ...mappedInstance,
              statusColor,
            });
          }
        }

        const master = common.master[0];

        const mappedInstance = {
          ...master,
          type,
          id: common.id,
          name: common.name,
          description: common.description,
          parent_id: common.parent_id ?? null,
          work_section_id: common.work_section_id ?? null,
          virtual_space_id: null,
          nextgen_work_package_id: common.id,
          order_number: wpOrderNumbers.get(common.id),
          ...combineTasks(master, common.tasks),
        };

        const statusColor = getStatusColor(mappedInstance);

        processedWorkPackageData.push({ ...mappedInstance, statusColor });

        if (!common.parent_id) {
          groupedMasters['root'] ??= [];
          groupedMasters['root'].push({
            ...mappedInstance,
            statusColor,
          });
        } else {
          groupedMasters[common.parent_id] ??= [];
          groupedMasters[common.parent_id].push({
            ...mappedInstance,
            statusColor,
          });
        }
      }
    }

    const wpsWithInstanceIds: NextgenWorkPackage[] = [];

    for (const workPackage of processedWorkPackageData) {
      wpsWithInstanceIds.push({
        ...workPackage,
        instanceIds: getInstanceIds(workPackage, 0).instanceIds,
      });
    }

    return wpsWithInstanceIds;
  };

export type VirtualSpace = NonNullable<
  NonNullable<
    NonNullable<
      NonNullable<
        SchedulePageQuery['projectBySvId']
      >['virtual_spaces_namespaces']
    >[0]
  >['virtual_spaces']
>[0];

export const getVirtualNamespaces: (
  projectId: string
) => Selector<VirtualSpace[]> =
  (projectId) =>
  ({
    schedule: {
      nextgenScheduleData: { data },
    },
  }) => {
    const response = data[projectId];

    if (!response) {
      return [];
    }

    const nextgenData =
      response.schedulePageData.projectBySvId?.virtual_spaces_namespaces;

    if (!nextgenData) {
      return [];
    }

    const virtualSpaces = nextgenData
      .map((namespace) => namespace.virtual_spaces)
      .filter(isPresent)
      .flat();

    return virtualSpaces;
  };

export type Team = NonNullable<
  NonNullable<NonNullable<SchedulePageQuery['projectBySvId']>['site']>['teams']
>[0];

export const getTeams: (projectId: string) => Selector<Team[]> =
  (projectId) =>
  ({
    schedule: {
      nextgenScheduleData: { data },
    },
  }) => {
    const response = data[projectId];

    if (!response) {
      return [];
    }

    const nextgenData = response.schedulePageData.projectBySvId?.site?.teams;

    if (!nextgenData) {
      return [];
    }

    return nextgenData;
  };

type CalendarInterface = NonNullable<
  SchedulePageWorkPackagesQuery['scheduleView']['work_package_data_nested']['calendar'][0]
>;

export const getCalendar: (
  projectId: string
) => Selector<remoteData.RemoteData<CalendarInterface[]>> =
  (projectId) =>
  ({
    schedule: {
      nextgenScheduleData: {
        requests: { [projectId]: requestState = remoteData.notAsked },
        data,
      },
    },
  }) =>
    remoteData.map(requestState, (_) => {
      const response = data[projectId];

      if (!response) {
        return [];
      }

      const nextgenData =
        response.schedulePageWorkPackageData.scheduleView
          .work_package_data_nested.calendar;

      if (!nextgenData) {
        return [];
      }

      return nextgenData;
    });
