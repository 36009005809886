import { useSelector } from 'react-redux';

import Big from 'big.js';
import { uniq } from 'lodash';
import { merge } from 'lodash/fp';
import { Reducer } from 'redux';
import { v4 } from 'uuid';

import {
  ID,
  OuterBarState,
  ReceiveAmountState,
  SelectedArrivalRowsState,
  SelectedOrderRowsState,
  SelectedPaymentProgramRowsState,
} from '../../types/general';

import { Selector } from './utils';
import * as big from '../../utils/big';
import { flow } from '../../utils/function';
import { isDefined } from '../../utils/general';
import * as remoteData from '../../utils/remoteData';

import { emptyReceiveAmountStatePiece } from '../../views/OrderView/ViewModeWrappers/Receive/receiveModeUtils';
import { ActionTypes } from '../actionTypes';
import {
  loadProjectColumns,
  saveProjectColumns,
  projColumns,
  ColumnType,
  loadWorkSectionViewColumns,
  workSectionColumns,
  saveWorkSectionViewColumns,
} from '../localStorage';
import { getOrderRowsByIds } from './orderRow';
import { getPaymentProgramRowGroups } from './paymentProgramRowGroup';
import { getProcurementAreasByProjectId } from './procurementArea';
import { getTopicsByOrderId } from './topic';
import { getWorkPackageGroupsByProjectId } from './workPackageGroup';

import { AppState } from '.';

export const activeProjectLocalStorageKey = 'sdpay_active_project';

export type DraftTargetRow = {
  rowId: string;
  orderId: string;
  topicId: string;
  description: string;
  quantity: string;
  unit: string;
  unitPrice: string;
  analysisId: string;
  createAlsoOrder: boolean;
};

export interface SplitDraftTargetRow
  extends Omit<DraftTargetRow, 'createAlsoOrder'> {
  splitFromTargetRowId: string;
  orderRowId: string | null;
}

export type UIState = {
  activeProject?: ID;
  // only holds projectpage + workSectionView columms for now
  columns: {
    projectColumns: ColumnType[];
    workSectionViewColumns: ColumnType[];
  };
  // List of procurement areas that have been opened in the project
  openProcurementAreas: ID[];
  // Last active procurement area = the procurement area from which the user
  // last clicked on an order.
  lastActiveProcurementArea?: ID;
  // Saved last active procurement area to enable continuing receiving after visiting
  // some other page
  openWorkPackageGroups: ID[];
  // Checks if all worksection details are open or not
  areAllWorkSectionDetailsOpen: boolean;
  // Last active WorkPackageGroup = the WorkPackageGroup from which the user
  // last clicked on an work package.
  lastActiveWorkPackageGroup?: ID;
  // Saved last active work package gorup to enable continuing receiving after visiting
  // some other page
  savedReceiveAmountState: {
    [orderId: string]: ReceiveAmountState;
  };
  // State of what has been selected for viewing in "OuterBar"
  outerBarState: OuterBarState | null;
  // List of topics that have been opened in the procurement area
  openTopics: ID[];
  // Selected arrival rows for each order.
  openSnapshots: ID[];
  selectedArrivalRows: SelectedArrivalRowsState;
  selectedOrderRows: SelectedOrderRowsState;
  selectedPaymentProgramRows: SelectedPaymentProgramRowsState;
  selectedOrderStatuses: ID[];
  billingFilteringActive: boolean;
  draftTargetRows: {
    [orderId: string]: {
      [rowId: string]: DraftTargetRow;
    };
  };
  targetView: {
    filterSearchWord: string;
    targetRowIds?: string[];
  };
  draftOrderChatMessages: {
    [orderId: string]: string;
  };
};

export const initialState: UIState = {
  activeProject:
    localStorage.getItem(activeProjectLocalStorageKey) || undefined,
  columns: {
    projectColumns: loadProjectColumns() || projColumns,
    workSectionViewColumns: loadWorkSectionViewColumns() || workSectionColumns,
  },
  openProcurementAreas: [],
  lastActiveProcurementArea: undefined,
  openWorkPackageGroups: [],
  areAllWorkSectionDetailsOpen: false,
  lastActiveWorkPackageGroup: undefined,
  savedReceiveAmountState: {},
  outerBarState: null,
  openTopics: [],
  openSnapshots: [],
  selectedArrivalRows: {},
  selectedOrderRows: {},
  selectedPaymentProgramRows: {},
  selectedOrderStatuses: [],
  draftTargetRows: {},
  targetView: {
    filterSearchWord: '',
  },
  draftOrderChatMessages: {},
  billingFilteringActive: false,
};

const uiReducer: Reducer<UIState, ActionTypes> = (
  state = initialState,
  action
): UIState => {
  switch (action.type) {
    case 'SET_ACTIVE_PROJECT':
      return {
        ...state,
        activeProject: action.payload,
        // Reset opened procurement areas too when project changes
        openProcurementAreas: [],
        // ...and also last active procurement area
        lastActiveProcurementArea: undefined,
        // Reset opened work package groups too when project changes
        openWorkPackageGroups: [],
        // ...and also last active work package group
        lastActiveWorkPackageGroup: undefined,
        // Reset also opened topics when project changes
        openTopics: [],
        openSnapshots: [],
      };
    case 'UI_STATE_OPEN_SEVERAL_PROCUREMENT_AREAS': {
      return {
        ...state,
        openProcurementAreas: action.payload,
      };
    }
    case 'UI_STATE_TOGGLE_BILLING_PROCUREMENT_AREAS': {
      return {
        ...state,
        billingFilteringActive: !state.billingFilteringActive,
      };
    }

    case 'UI_STATE_CLOSE_ALL_PROCUREMENT_AREAS':
      return {
        ...state,
        openProcurementAreas: [],
      };
    case 'UI_STATE_PROCUREMENT_AREA_CLOSED':
      return {
        ...state,
        openProcurementAreas: state.openProcurementAreas.filter(
          (procurementAreaId) => procurementAreaId !== action.payload
        ),
      };
    case 'UI_STATE_PROCUREMENT_AREA_OPENED':
      return {
        ...state,
        openProcurementAreas: uniq([
          ...state.openProcurementAreas,
          action.payload,
        ]),
      };
    case 'SET_LAST_ACTIVE_PROCUREMENT_AREA':
      return {
        ...state,
        lastActiveProcurementArea: action.payload,
      };
    case 'UI_STATE_OPEN_SEVERAL_WORK_PACKAGE_GROUPS': {
      return {
        ...state,
        openWorkPackageGroups: action.payload,
      };
    }
    case 'UI_STATE_CLOSE_ALL_WORK_PACKAGE_GROUPS':
      return {
        ...state,
        openWorkPackageGroups: [],
      };
    case 'UI_STATE_WORK_PACKAGE_GROUP_CLOSED':
      return {
        ...state,
        openWorkPackageGroups: state.openWorkPackageGroups.filter(
          (workPackageGroupId) => workPackageGroupId !== action.payload
        ),
      };
    case 'UI_STATE_WORK_PACKAGE_GROUP_OPENED':
      return {
        ...state,
        openWorkPackageGroups: uniq([
          ...state.openWorkPackageGroups,
          action.payload,
        ]),
      };
    case 'UI_STATE_TOGGLE_OPEN_ALL_WORK_SECTIONS':
      return {
        ...state,
        areAllWorkSectionDetailsOpen: !state.areAllWorkSectionDetailsOpen,
      };

    case 'UI_STATE_TOGGLE_OPEN_ALL_TOPICS':
      return {
        ...state,
        openTopics: action.payload,
      };
    case 'SET_LAST_ACTIVE_WORK_PACKAGE_GROUP':
      return {
        ...state,
        lastActiveWorkPackageGroup: action.payload,
      };
    case 'SAVE_RECEIVE_AMOUNT_STATE': {
      const { orderId, state: savedState } = action.payload;

      return {
        ...state,
        savedReceiveAmountState: {
          ...state.savedReceiveAmountState,
          [orderId]: savedState,
        },
      };
    }
    case 'CLEAR_RECEIVE_AMOUNT_STATE': {
      const orderId = action.payload;

      const { [orderId]: _, ...otherSavedStates } =
        state.savedReceiveAmountState;

      return { ...state, savedReceiveAmountState: otherSavedStates };
    }

    case 'PUT_INVOICE_HEADER_MOVE_SUCCESS':
    case 'PUT_ACTUAL_COST_CONVERT_SUCCESS':
    case 'PUT_ACTUAL_COST_LINES_CONVERT_SUCCESS':
    case 'PUT_ACTUAL_COST_MOVE_SUCCESS':
    case 'PUT_INVOICE_HEADER_CONVERT_SUCCESS':
    case 'PUT_INVOICE_LINES_CONVERT_SUCCESS': {
      if (action.payload.orderRows) {
        return action.payload.orderRows.reduce((tempState, orderRow) => {
          const { orderId, id: orderRowId } = orderRow;

          const orderState = state.savedReceiveAmountState[orderId] && {
            [orderId]: {
              ...tempState.savedReceiveAmountState[orderId],
              [orderRowId]: emptyReceiveAmountStatePiece,
            },
          };

          return {
            ...tempState,
            savedReceiveAmountState: {
              ...state.savedReceiveAmountState,
              ...orderState,
            },
          };
        }, state);
      }

      return state;
    }

    case 'UI_TOPIC_TOGGLED': {
      const isOpen = state.openTopics.includes(action.payload);

      return {
        ...state,
        openTopics: isOpen
          ? state.openTopics.filter((id) => id !== action.payload)
          : [...state.openTopics, action.payload],
      };
    }
    case 'UI_SNAPSHOT_TOGGLED': {
      const isOpen = state.openSnapshots.includes(action.payload);

      return {
        ...state,
        openSnapshots: isOpen
          ? state.openSnapshots.filter((id) => id !== action.payload)
          : [...state.openSnapshots, action.payload],
      };
    }
    case 'SELECT_ARRIVAL_ROWS':
      return {
        ...state,
        selectedArrivalRows: {
          ...state.selectedArrivalRows,
          ...action.payload,
        },
      };
    case 'POST_ARRIVAL_SUCCESS':
      // For automatic selection of arrival rows.
      if (action.payload.arrivalRows) {
        /// FIXME: When backend provides the value, we only want automatic
        // selection to happen when order has unhandled invoices.
        // if (!action.payload.orders[0].hasUnhandledInvoices) return state;
        return {
          ...state,
          selectedArrivalRows: {
            ...state.selectedArrivalRows,
            ...action.payload.arrivalRows.reduce(
              (selected, row) => ({ ...selected, [row.id]: true }),
              {}
            ),
          },
        };
      }

      return state;
    case 'SET_OUTER_BAR_STATE': {
      return { ...state, outerBarState: action.payload };
    }
    case 'DELETE_MULTIPLE_ORDER_ROWS_STARTED': {
      return {
        ...state,
        selectedOrderRows: {},
      };
    }
    case 'ORDER_ROW_SELECTION_TOGGLED': {
      const { topicId, orderRowId } = action.payload;

      const oldOrderRowIdsForTopic = state.selectedOrderRows[topicId] ?? [];

      const isOrderRowAlreadyInState = isOrderRowInSelected(
        state.selectedOrderRows,
        topicId,
        orderRowId
      );

      let newOrderRowIds = [...oldOrderRowIdsForTopic];

      if (isOrderRowAlreadyInState) {
        newOrderRowIds = newOrderRowIds.filter((id) => id !== orderRowId);
      } else {
        newOrderRowIds = [...newOrderRowIds, orderRowId];
      }

      return {
        ...state,
        selectedOrderRows: {
          ...state.selectedOrderRows,
          [topicId]: newOrderRowIds,
        },
      };
    }
    case 'ALL_ORDER_ROWS_SELECTION_TOGGLED': {
      const { topicId, orderRowIds } = action.payload;

      const areAllOrderRowsAlreadyInState = multipleOrderRowsInState(
        state.selectedOrderRows,
        topicId,
        orderRowIds
      );

      let newOrderRows: string[] = [];

      if (!areAllOrderRowsAlreadyInState) {
        newOrderRows = [...orderRowIds];
      }

      return {
        ...state,
        selectedOrderRows: {
          ...state.selectedOrderRows,
          [topicId]: newOrderRows,
        },
      };
    }
    case 'PAYMENT_PROGRAM_ROW_SELECTION_TOGGLED': {
      const { paymentProgramRowGroupId, paymentProgramRowId } = action.payload;

      const oldPaymentProgramRowIdsForGroup =
        state.selectedPaymentProgramRows[paymentProgramRowGroupId] ?? [];

      const isRowAlreadyInState = isPaymentProgramRowInSelected(
        state.selectedPaymentProgramRows,
        paymentProgramRowGroupId,
        paymentProgramRowId
      );

      let newRowIds = [...oldPaymentProgramRowIdsForGroup];

      if (isRowAlreadyInState) {
        newRowIds = newRowIds.filter((id) => id !== paymentProgramRowId);
      } else {
        newRowIds = [...newRowIds, paymentProgramRowId];
      }

      return {
        ...state,
        selectedPaymentProgramRows: {
          ...state.selectedPaymentProgramRows,
          [paymentProgramRowGroupId]: newRowIds,
        },
      };
    }
    case 'ALL_PAYMENT_PROGRAM_ROWS_SELECTION_TOGGLED': {
      const { paymentProgramRowGroupId, paymentProgramRowIds } = action.payload;

      const areAllPaymentProgramRowsAlreadyInState =
        multiplePaymentProgramRowsInState(
          state.selectedPaymentProgramRows,
          paymentProgramRowGroupId,
          paymentProgramRowIds
        );

      let newPaymentProgramRows: string[] = [];

      if (!areAllPaymentProgramRowsAlreadyInState) {
        newPaymentProgramRows = [...paymentProgramRowIds];
      }

      return {
        ...state,
        selectedPaymentProgramRows: {
          ...state.selectedPaymentProgramRows,
          [paymentProgramRowGroupId]: newPaymentProgramRows,
        },
      };
    }
    case 'MOVE_PAYMENT_PROGRAM_ROWS_STARTED': {
      return {
        ...state,
        selectedPaymentProgramRows: {},
      };
    }
    case 'MOVE_ORDER_ROWS_TO_TOPIC_STARTED':
    case 'MOVE_ORDER_ROWS_TO_ORDER_STARTED': {
      return {
        ...state,
        selectedOrderRows: {},
      };
    }
    case 'DELETE_ORDER_ROW_SUCCESS': {
      const { orderRows: updatedOrderRows } = action.payload;

      if (!updatedOrderRows) {
        return state;
      }

      const newState = updatedOrderRows.reduce((nextState, orderRow) => {
        const { id, topicId, isDeleted } = orderRow;

        if (isDeleted) {
          return {
            ...nextState,
            selectedOrderRows: {
              ...nextState.selectedOrderRows,
              [topicId]: nextState.selectedOrderRows[topicId]?.filter(
                (rowId) => rowId !== id
              ),
            },
          };
        }

        return nextState;
      }, state);

      return newState;
    }
    case 'POST_TARGET_ROWS_SUCCESS':
    case 'SPLIT_TARGET_ROWS_SUCCESS':
    case 'DELETE_TARGET_ROW_SUCCESS': {
      // TODO: remove created draft target row from UI state
      return state;
    }

    case 'UPDATE_PROJECT_COLUMNS': {
      const { payload } = action;
      const { projectColumns: columns } = state.columns;

      if (columns.length) {
        const index = columns?.findIndex((el: any) => el.id === payload.id);
        columns[index] = {
          ...payload,
        };
        saveProjectColumns([...columns]);
      }

      return {
        ...state,
        columns: {
          projectColumns: [...columns],
          workSectionViewColumns: state.columns.workSectionViewColumns,
        },
      };
    }
    case 'UPDATE_WORK_SECTION_VIEW_COLUMNS': {
      const { payload } = action;
      const { workSectionViewColumns: columns } = state.columns;

      if (columns.length) {
        const index = columns?.findIndex((el: any) => el.id === payload.id);
        columns[index] = {
          ...payload,
        };
        saveWorkSectionViewColumns([...columns]);
      }

      return {
        ...state,
        columns: {
          workSectionViewColumns: [...columns],
          projectColumns: state.columns.projectColumns,
        },
      };
    }
    case 'CREATE_DRAFT_TARGET_ROW_IN_STORE': {
      const { orderId, defaultTopicId, rowId = v4() } = action.payload;

      const blankRow = {
        [rowId]: {
          description: '',
          quantity: '',
          unit: '',
          unitPrice: '',
          orderId,
          topicId: defaultTopicId,
          analysisId: '',
          createAlsoOrder: false,
        },
      };

      return merge(state, {
        draftTargetRows: {
          [orderId]: blankRow,
        },
      });
    }
    case 'UPDATE_DRAFT_ORDER_CHAT_MESSAGE': {
      const { orderId, message } = action.payload;

      return {
        ...state,
        draftOrderChatMessages: {
          ...state.draftOrderChatMessages,
          [orderId]: message,
        },
      };
    }
    // Not used currently, but most likely will be soon
    case 'DRAFT_TARGET_ROW_CHANGED': {
      const { orderId, rowId, fieldName, fieldValue } = action.payload;

      const result = merge(state, {
        draftTargetRows: {
          [orderId]: {
            [rowId]: {
              [fieldName]: fieldValue,
            },
          },
        },
      });

      return result;
    }
    case 'UI_STATE_TARGET_VIEW_SEARCH': {
      const { targetRowIds, searchString } = action.payload;

      return {
        ...state,
        targetView: {
          filterSearchWord: searchString,
          targetRowIds,
        },
      };
    }
    case 'SET_ORDER_STATUS_TOGGLED': {
      const isSelected = state.selectedOrderStatuses.includes(action.payload);

      return {
        ...state,
        selectedOrderStatuses: isSelected
          ? state.selectedOrderStatuses.filter((id) => id !== action.payload)
          : [...state.selectedOrderStatuses, action.payload],
      };
    }
    default:
      return state;
  }
};

export const getUIState = ({ ui }: AppState): UIState => ui;
export const getSavedReceiveAmountStateForOrder: (
  orderId: string
) => (appState: AppState) => ReceiveAmountState | undefined = (orderId) =>
  flow(
    getUIState,
    ({ savedReceiveAmountState: { [orderId]: receiveAmountState } }) =>
      receiveAmountState
  );

export const isTopicOpen =
  (id: string) =>
  ({ ui: { openTopics } }: AppState) =>
    openTopics.includes(id);

export const isSnapshotOpen =
  (id: string) =>
  ({ ui: { openSnapshots } }: AppState) =>
    openSnapshots.includes(id);

export const getOuterBarState =
  () =>
  ({ ui: { outerBarState } }: AppState) =>
    outerBarState;

export const getIsOuterBarOpen =
  () =>
  ({ ui: { outerBarState } }: AppState) => {
    const isOpen = !!outerBarState;

    return isOpen;
  };

export const getActiveProjectId = ({ ui: { activeProject } }: AppState) =>
  activeProject;

export const getNotificationFeed = (state: AppState) => state.notification.feed;

export const getColumns = ({ ui: { columns } }: AppState) => columns;

export const getBillingFilteringActive = ({
  ui: { billingFilteringActive },
}: AppState) => billingFilteringActive;

export const getSelectedOrderStatuses = ({
  ui: { selectedOrderStatuses },
}: AppState) => selectedOrderStatuses;

export const getAllProjectProcurementAreasAreOpen =
  (projectId: string) =>
  ({ ui: { openProcurementAreas } }: AppState) => {
    const allAreaIds = useSelector(
      getProcurementAreasByProjectId(projectId)
    ).map(({ id }) => id);

    return allAreaIds.every((area) => openProcurementAreas.includes(area));
  };

export const getAllProjectWorkPackageGroupsAreOpen =
  (projectId: string) =>
  ({ ui: { openWorkPackageGroups } }: AppState) => {
    const allGroupIds = useSelector(
      getWorkPackageGroupsByProjectId(projectId)
    ).map(({ id }) => id);

    return allGroupIds.every((area) => openWorkPackageGroups.includes(area));
  };

export const getAreAllWorkSectionsOpen =
  () =>
  ({ ui: { areAllWorkSectionDetailsOpen } }: AppState) => {
    return areAllWorkSectionDetailsOpen;
  };

export const getAreAllTopicsOpen =
  () =>
  ({ ui: { openTopics } }: AppState) => {
    return openTopics;
  };

export const isOrderRowInSelected = (
  state: SelectedOrderRowsState,
  topicId: ID,
  orderRowId: ID
): boolean => {
  const selectedOrderRowIdsForTopic = state[topicId] ?? [];

  return !!selectedOrderRowIdsForTopic.find(
    (orderId) => orderId === orderRowId
  );
};

export const isPaymentProgramRowInSelected = (
  state: SelectedPaymentProgramRowsState,
  paymentProgramRowGroupId: ID,
  paymentProgramRowId: ID
): boolean => {
  const selectedpaymentProgramRowIdsForGroup =
    state[paymentProgramRowGroupId] ?? [];

  return !!selectedpaymentProgramRowIdsForGroup.find(
    (rowId) => rowId === paymentProgramRowId
  );
};

export const isOrderRowSelected =
  (topicId: string, orderRowId: string) =>
  ({
    ui: {
      selectedOrderRows: { [topicId]: orderRows },
    },
  }: AppState) => {
    const selectedOrderRowIdsForTopic = orderRows ?? [];

    return !!selectedOrderRowIdsForTopic.find(
      (orderId) => orderId === orderRowId
    );
  };

export const multipleOrderRowsInState = (
  state: SelectedOrderRowsState,
  topicId: ID,
  orderRowIds: ID[]
): boolean => {
  const selectedOrderRowIdsForTopic = state[topicId] ?? [];

  /* Inspecting at the .length here, because if it isn't inspected,
  * when a topic doesn't have any order rows, and the array is empty,
  * then the .every condition is met and the topic row's checkbox will
  * be checked, even though under it there are no order rows to be selected.

  * As this selection tells the user, to what order rows they are making an
  * action (moving/deleting), having the topic's checkbox checked would
  * signify, that under this topic there are some order rows, that are being
  * operated. (The user might not have the topic opened, so they don't see
  * if there are any order rows under it.)
  */

  return (
    orderRowIds.length !== 0 &&
    orderRowIds.every((item) => selectedOrderRowIdsForTopic.includes(item))
  );
};

export const multiplePaymentProgramRowsInState = (
  state: SelectedPaymentProgramRowsState,
  paymentProgramRowGroupId: ID,
  paymentProgramRowIds: ID[]
): boolean => {
  const selectedPaymentProgramRowIdsForGroup =
    state[paymentProgramRowGroupId] ?? [];

  return (
    paymentProgramRowIds.length !== 0 &&
    paymentProgramRowIds.every((item) =>
      selectedPaymentProgramRowIdsForGroup.includes(item)
    )
  );
};

export const getSelectedOrderRowsForOrder =
  (orderId: string) =>
  (appState: AppState): string[] => {
    const { selectedOrderRows } = appState.ui;

    const topics = Object.values(getTopicsByOrderId(orderId)(appState)).filter(
      isDefined
    );
    const topicIds = topics.map((topic) => topic.id);

    const initialValue: string[] = [];

    const filtered = Object.keys(selectedOrderRows)
      .filter((key) => topicIds.includes(key))
      .reduce((previous, key) => {
        const orderRowIds = selectedOrderRows[key] ?? [];

        return previous.concat(orderRowIds);
      }, initialValue);

    return filtered;
  };

export const getSelectedPaymentProgramRows =
  (projectId: string) =>
  (appState: AppState): string[] => {
    const { selectedPaymentProgramRows } = appState.ui;

    const groups = remoteData.withDefault(
      getPaymentProgramRowGroups(projectId)(appState),
      []
    );

    const groupIds = groups.map((group) => group.id);

    const initialValue: string[] = [];

    const filtered = Object.keys(selectedPaymentProgramRows)
      .filter((key) => groupIds.includes(key))
      .reduce((previous, key) => {
        const paymentProgramRowIds = selectedPaymentProgramRows[key] ?? [];

        return previous.concat(paymentProgramRowIds);
      }, initialValue);

    return filtered;
  };

export const getTotalPricesSumForOrderRowsWithIds =
  (ids: string[], orderId: string) => (appState: AppState) => {
    const orderRows = getOrderRowsByIds(ids, orderId)(appState);

    return big.sum(
      ...orderRows.map((row) => {
        const unitPrice = row.unitPrice ?? new Big('0');
        const quantity = row.quantity ?? new Big('0');

        return unitPrice.mul(quantity);
      })
    );
  };

export const getDraftTargetRows =
  (orderId: string) =>
  ({ ui: { draftTargetRows } }: AppState) => {
    return draftTargetRows[orderId];
  };

export const getDraftOrderChatMessage =
  (orderId: string) =>
  ({ ui: { draftOrderChatMessages } }: AppState) => {
    return draftOrderChatMessages[orderId];
  };

export function getTargetRowUpdateRequest(
  requestId: string
): Selector<remoteData.RemoteAction> {
  return ({
    target: {
      targetRows: {
        requests: { [requestId]: request },
      },
    },
  }) => request ?? remoteData.notAsked;
}

export const getTargetViewFilters =
  () =>
  ({ ui: { targetView } }: AppState) => {
    return targetView;
  };

export default uiReducer;
