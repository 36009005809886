import React from 'react';

import * as orderRow from '../../../store/reducers/orderRow';

import StatusPill from '../../../components/StatusPill';

const pills = {
  Contract: {
    textId: 'orderRow.status.Contract',
    colorName: 'logan',
  },
  Reserves: {
    textId: 'orderRow.status.Reserves',

    colorName: 'buttonPrimaryDisabled',
  },
  ChangeOrder: {
    textId: 'orderRow.status.ChangeOrder',
    colorName: 'wildBlueYonder',
  },
} as const;

type StatusPillProps = {
  status: orderRow.Status;
  toggle?: () => void;
};

const OrderRowStatusPill = ({ status, toggle }: StatusPillProps) => {
  return (
    <StatusPill {...pills[status]} onClick={toggle} isClickable={!!toggle} />
  );
};

export default OrderRowStatusPill;
