import styled, { DefaultTheme } from 'styled-components';

import defaultTheme from '../styles/theme';
import { CircleIconButtonContainer } from './Navigation/Components';

export type ThemeColor = keyof DefaultTheme['color'];

type UserInitialsBadgeProps = {
  backgroundColor?: ThemeColor;
};

export const UserInitialsBadge = styled(
  CircleIconButtonContainer
)<UserInitialsBadgeProps>`
  margin: 0;
  background-color: ${({ backgroundColor, theme }) =>
    backgroundColor ?? theme.color.amethyst};
  color: ${({ theme }) => theme.color.white};
`;

const assignedColors: Record<string, ThemeColor> = {};

// returns a color for the given author: either one that has been assigned to them
// previously, or a new one in order of defined colors
export const getAuthorColor = (senderName: string) => {
  if (assignedColors[senderName]) {
    return assignedColors[senderName];
  }

  const authorColors = [
    defaultTheme.color.indigo,
    defaultTheme.color.orchid,
    defaultTheme.color.darkpurple,
    defaultTheme.color.amethystSmoke,
    defaultTheme.color.wildBlueYonder,
  ];

  const index = Object.keys(assignedColors).length % authorColors.length;

  const color = authorColors[index] as ThemeColor;

  // add the color to the list of assigned colors
  assignedColors[senderName] = color;

  return color;
};
