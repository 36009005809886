import React from 'react';

import { DefaultTheme } from 'styled-components';

import { APIOrder } from '../../../types/api';

import StatusPill from '../../../components/StatusPill';

import { TextId } from '../../../localization';

type Color = keyof DefaultTheme['color'];
type StatusId = APIOrder['statusId'];

type PillDescriptor = {
  textId: TextId;
  colorName: Color;
};

const pillDescriptors: Record<StatusId, PillDescriptor> = {
  '1': { textId: 'common.status.Planned', colorName: 'logan' },
  '2': { textId: 'common.status.Accepted', colorName: 'amethystSmoke' },
  '3': { textId: 'common.status.Closed', colorName: 'buttonPrimaryDisabled' },
};

type StatusPillProps = Pick<APIOrder, 'statusId'>;

const OrderStatusPill = ({ statusId }: StatusPillProps) => (
  <StatusPill {...pillDescriptors[statusId]} />
);

export default OrderStatusPill;
