import React, { Fragment, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useUpdateEffect } from 'react-use';

import Big from 'big.js';
import * as t from 'io-ts';
import moment from 'moment';
import styled, { css } from 'styled-components';

import { BatchGroup } from '../../../../store/reducers/batchGroup';
import { PaymentProgramRowGroup as PaymentProgramRowGroupType } from '../../../../store/reducers/paymentProgramRowGroup';
import {
  Revenue,
  getProjectRevenue,
} from '../../../../store/reducers/revenue/revenue';
import { getSortOrders } from '../../../../store/reducers/revenue/sortRevenue';
import {
  getUIState,
  isPaymentProgramRowInSelected,
  multiplePaymentProgramRowsInState,
} from '../../../../store/reducers/ui';

import {
  PutPaymentProgramRowGroupParams,
  requestDeletePaymentProgramRowGroup,
  requestPaymentProgramRowGroupUpdate,
} from '../../../../store/actions/paymentProgramRowGroup';
import {
  requestRevenueUpdate,
  requestRevenues,
  requestDeleteRevenue,
} from '../../../../store/actions/revenue';
import {
  allPaymentProgramRowsSelectionToggled,
  paymentProgramRowSelectionToggled,
} from '../../../../store/actions/ui';

import useRemoteData from '../../../../hooks/useRemoteData';
import useTxt from '../../../../hooks/useTxt';

import { TagsContainer } from '../../../../components/Analysis/AnalysisTags';
import ArrowIcon from '../../../../components/ArrowIcon';
import { IconButton } from '../../../../components/Buttons';
import Cell, { LeftPaddedCell } from '../../../../components/Cell';
import DropDownSelect from '../../../../components/DropDownSelect';
import EditableCell from '../../../../components/EditableCell';
import Checkbox from '../../../../components/Input/Checkbox';
import { PrimaryRow, SecondaryRow } from '../../../../components/Table';

import * as big from '../../../../utils/big';
import {
  bigInputString,
  bigPercentage,
  maxLengthString,
  uniqueCode,
} from '../../../../utils/decoders';
import { longDateMonthAndYearFormat } from '../../../../utils/format';
import {
  capitalizeFirstLetter,
  getEOMonth,
  momentValueFromInput,
} from '../../../../utils/general';
import { useDebounce } from '../../../../utils/hooks';
import { isClickOrKeyboardSelection } from '../../../../utils/mouseOrKeyInteraction';
import { mapValues } from '../../../../utils/record';

import { IconDelete } from '../../../../assets/svg';

import { useParams, routes } from '../../../../routes';

import { MAX_GROUP_DESCRIPTION_LENGTH } from '../CreateGroupForm';
import AnalysisTags from './AnalysisTags';
import BillingDate from './BillingDate';
import ControlRow from './ControlRow';
import MonthlyRow from './MonthlyRevenueRow';
import StatusPill from './StatusPill';
import { tableColumns } from './TableHeader';
import {
  MAX_CODE_LENGTH,
  MAX_DESCRIPTION_LENGTH,
  MAX_UNIT_LENGTH,
} from '../ExcelImporter/utils';

type PaymentProgramRowGroupProps = {
  group: PaymentProgramRowGroupType;
  rows: Revenue[];
  batchGroups: BatchGroup[];
};

const PaymentProgramRowGroup = ({
  group,
  rows,
  batchGroups,
}: PaymentProgramRowGroupProps) => {
  const dispatch = useDispatch();
  const isInEditMode = useParams(routes.REVENUE)?.viewMode === 'edit';

  const sortOrder = useSelector(getSortOrders);

  const isSortedByDate = sortOrder[0]?.key === 'billingDate';

  const labelText = useTxt('common.paymentBatchGroup');
  const notFoundText = useTxt('common.paymentBatchGroup.notFound');
  const noDateText = useTxt('common.noDate');

  const [update, setUpdate] = React.useState<
    undefined | Partial<PaymentProgramRowGroupType>
  >(undefined);

  const updatePaymentProgramRowGroupType =
    <K extends keyof PaymentProgramRowGroupType>(key: K) =>
    (value: PaymentProgramRowGroupType[K]) =>
      setUpdate({ ...update, [key]: value });

  const debouncedUpdate = useDebounce(
    (updatedFields: PutPaymentProgramRowGroupParams) => {
      dispatch(
        requestPaymentProgramRowGroupUpdate(
          {
            description: updatedFields.description,
            updatedAt: updatedFields.updatedAt,
            paymentBatchGroupId: updatedFields.paymentBatchGroupId,
          },
          group.id,
          true
        )
      );
    },
    1000
  );

  const memoizedState = useMemo(() => update, [update]);

  useUpdateEffect(() => {
    if (memoizedState) {
      const description = memoizedState.description ?? group.description;

      const paymentBatchGroupId =
        memoizedState.paymentBatchGroupId ?? group.paymentBatchGroupId;

      if (description && paymentBatchGroupId) {
        debouncedUpdate({
          description,
          updatedAt: group.updatedAt,
          paymentBatchGroupId,
        });
      }
    }
  }, [memoizedState]);

  const [isOpen, setIsOpen] = React.useState(true);

  const toggle = (e: React.MouseEvent | React.KeyboardEvent) => {
    e.stopPropagation();

    if (isClickOrKeyboardSelection(e)) {
      e.preventDefault();
      setIsOpen(!isOpen);
    }
  };

  const loadingText = useTxt('meta.loading');
  const name = group.description ?? loadingText;

  const netPriceTotal = rows.reduce((previous, current) => {
    const sum = previous.add(current.netPrice);

    return sum;
  }, new Big(0));

  const actualizedTotal = rows.reduce((previous, current) => {
    const sum = previous.add(current.actualizedBilling);

    return sum;
  }, new Big(0));

  const removeText = useTxt('revenue.table.group.remove');

  const batchDescription = batchGroups.find(
    (batch) => batch.id === group.paymentBatchGroupId
  )?.description;

  const onDeletePaymentProgramRowGroup = () => {
    dispatch(requestDeletePaymentProgramRowGroup(group.id));
  };

  const handleClick = (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    e.stopPropagation();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation();
  };

  const selectedState = useSelector(getUIState);

  const areAllSelected = multiplePaymentProgramRowsInState(
    selectedState.selectedPaymentProgramRows,
    group.id,
    group.paymentProgramRowIds
  );

  const onChoose = (
    paymentProgramRowGroupId: string,
    paymentProgramRowIds: string[]
  ) => {
    dispatch(
      allPaymentProgramRowsSelectionToggled({
        paymentProgramRowGroupId,
        paymentProgramRowIds,
      })
    );
  };

  const renderRows = () => {
    if (isSortedByDate) {
      const dateSortOrder =
        sortOrder.find(({ key }) => key === 'billingDate')?.sortOrder ??
        ('Ascending' as const);

      const mappedRows = rows.map((revenue) => {
        const billingDate = revenue.billingDate
          ? new Date(revenue.billingDate)
          : null;

        const eoMonthDate = billingDate
          ? getEOMonth(billingDate).getTime()
          : null;

        const firstDate = billingDate
          ? new Date(
              billingDate.getFullYear(),
              billingDate.getMonth(),
              1
            ).getTime()
          : null;

        return {
          ...revenue,
          groupingDate: dateSortOrder === 'Ascending' ? eoMonthDate : firstDate,
        };
      });

      const monthGroups = mappedRows.map(({ groupingDate }) => groupingDate);

      const uniqueMonths = [...new Set(monthGroups)].sort((a, b) => {
        const dateA = a ?? 0;
        const dateB = b ?? 0;

        return dateSortOrder === 'Ascending' ? dateA - dateB : dateB - dateA;
      });

      const groupedRows = uniqueMonths.map((month) => {
        const rowsInMonth = mappedRows.filter(
          ({ groupingDate }) => groupingDate === month
        );

        const description = month
          ? longDateMonthAndYearFormat.format(month)
          : noDateText;

        const date = month ?? 'noDate';

        const dateString = month
          ? momentValueFromInput(new Date(month)).format(moment.HTML5_FMT.DATE)
          : null;

        const netPrice = rowsInMonth.reduce(
          (previous, current) => previous.add(current.netPrice),
          new Big(0)
        );

        const grossPrice = rowsInMonth.reduce(
          (previous, current) => previous.add(current.grossPrice),
          new Big(0)
        );

        const actualizedBilling = rowsInMonth.reduce(
          (previous, current) => previous.add(current.actualizedBilling),
          new Big(0)
        );

        return {
          description: capitalizeFirstLetter(description),
          date,
          dateString,
          netPrice,
          grossPrice,
          actualizedBilling,
          rows: rowsInMonth,
        };
      });

      return (
        <>
          {groupedRows.map((groupRow, index) => {
            return (
              <Fragment key={`monthly-row-${group.id}-${groupRow.date}`}>
                <MonthlyRow
                  monthlyRowId={`${group.id}-${groupRow.date}`}
                  description={groupRow.description}
                  netPrice={groupRow.netPrice}
                  grossPrice={groupRow.grossPrice}
                  actualizedBilling={groupRow.actualizedBilling}
                  index={index}
                />
                {groupRow.rows.map((revenue) => {
                  const isRowSelected = isPaymentProgramRowInSelected(
                    selectedState.selectedPaymentProgramRows,
                    group.id,
                    revenue.id
                  );

                  return (
                    <Row
                      key={revenue.id}
                      revenue={revenue}
                      isSelected={isRowSelected}
                    />
                  );
                })}
                {isInEditMode ? (
                  <ControlRow
                    paymentProgramRowGroupId={group.id}
                    rows={rows}
                    date={groupRow.dateString ?? undefined}
                  />
                ) : null}
              </Fragment>
            );
          })}
        </>
      );
    }

    return (
      <>
        {rows.map((revenue) => {
          const isRowSelected = isPaymentProgramRowInSelected(
            selectedState.selectedPaymentProgramRows,
            group.id,
            revenue.id
          );

          return (
            <Row
              key={revenue.id}
              revenue={revenue}
              isSelected={isRowSelected}
            />
          );
        })}
        {isInEditMode ? (
          <ControlRow paymentProgramRowGroupId={group.id} rows={rows} />
        ) : null}
      </>
    );
  };

  return (
    <>
      <PrimaryRow
        key={group.id}
        onKeyPress={toggle}
        onClick={toggle}
        tabIndex={0}
        data-testid={`payment-program-row-group-${group.id}`}
      >
        {isInEditMode ? (
          <Cell align="center">
            <Checkbox
              checked={areAllSelected}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  e.stopPropagation();
                  e.preventDefault();
                  onChoose(group.id, group.paymentProgramRowIds);
                }
              }}
              onChange={() => {
                onChoose(group.id, group.paymentProgramRowIds);
              }}
            />
          </Cell>
        ) : null}
        <LeftPaddedCell>
          <ArrowIcon
            isOpen={isOpen}
            openAltTextKey="revenue.table.mainRow.open"
            closedAltTextKey="revenue.table.mainRow.closed"
          />
        </LeftPaddedCell>
        {isInEditMode ? (
          <EditableCell
            align="left"
            initialValue={name}
            codec={maxLengthString(MAX_GROUP_DESCRIPTION_LENGTH)}
            onClick={handleClick}
            onKeyDown={handleKeyDown}
            onChange={updatePaymentProgramRowGroupType('description')}
            route={routes.REVENUE}
            onBlur={() => debouncedUpdate.flush()}
          />
        ) : (
          <Cell align="left">
            <StyledParagraph>{`${name}`}</StyledParagraph>
            <i>{batchDescription ? `| ${batchDescription}` : ''}</i>
          </Cell>
        )}
        <Cell colSpan={3}>
          {isInEditMode && batchGroups.length > 0 ? (
            <FullSizeDropDownSelect
              label={labelText}
              notFound={notFoundText}
              options={[
                ...batchGroups.map((s) => ({
                  key: s.id,
                  value: s.id,
                  label: s.description,
                })),
              ]}
              defaultValue={group.paymentBatchGroupId ?? undefined}
              onChange={updatePaymentProgramRowGroupType('paymentBatchGroupId')}
            />
          ) : null}
        </Cell>
        <Cell align="right">{big.priceFormat(netPriceTotal)}</Cell>
        <Cell colSpan={3} />
        <Cell align="right">{big.priceFormat(actualizedTotal)}</Cell>
        <Cell colSpan={3} />
        <Cell align="center">
          {' '}
          {isInEditMode && group.isDeletable ? (
            <IconButton
              icon={IconDelete}
              onClick={onDeletePaymentProgramRowGroup}
              aria-label={removeText}
            />
          ) : null}
        </Cell>
      </PrimaryRow>
      {isOpen ? renderRows() : null}
    </>
  );
};

export default PaymentProgramRowGroup;

type RowProps = {
  revenue: Revenue;
  isSelected: boolean;
};

const Row = ({
  revenue: {
    batchCode,
    description,
    unitPrice,
    unit,
    netPrice,
    vat,
    quantity,
    grossPrice,
    billingDate,
    previousBillingDateFromLatest,
    actualizedBilling,
    status,
    isBilled,
    isDeletable,
    id: revenueId,
    paymentProgramRowGroupId,
    predictionChangeFromLatest,
  },
  isSelected,
}: RowProps) => {
  const aligns = mapValues(tableColumns, ({ align }) => align);

  const history = useHistory();
  const { hash } = history.location;
  const highlightedId = hash.replace('#revenueId-', '');

  const dispatch = useDispatch();
  const rowRef = useRef<HTMLTableRowElement>(null);

  const { projectId, viewMode } = useParams(routes.REVENUE);

  const allProjectRevenueRows =
    useRemoteData(getProjectRevenue(projectId), requestRevenues(projectId)) ??
    [];

  const allCodesInUse = allProjectRevenueRows.map((row) => row.batchCode);

  const allOtherCodes = allCodesInUse.filter((code) => code !== batchCode);

  const [update, setUpdate] = React.useState<undefined | Partial<Revenue>>(
    undefined
  );

  // rows are ordered based on batchCode or billingDate -> scroll into view if there is a change
  const [scrollingState, setScrollingState] = React.useState({
    batchCode,
    billingDate,
  });

  const memoizedScrollingState = React.useMemo(
    () => scrollingState,
    [scrollingState]
  );

  const onDeletePaymentBatchRow = () => {
    dispatch(
      requestDeleteRevenue({ requestId: revenueId, projectId, revenueId })
    );
  };

  const updateRevenue =
    <K extends keyof Revenue>(key: K) =>
    (value: Revenue[K]) =>
      setUpdate({ ...update, [key]: value });

  const debouncedUpdate = useDebounce(
    (updatedFields: Partial<Revenue>, forcedRequest?: boolean) => {
      dispatch(
        requestRevenueUpdate(
          updatedFields,
          {
            revenueId,
            requestId: `revenueUpdate-${revenueId}`,
            projectId,
          },
          forcedRequest
        )
      );
    },
    1000
  );

  useUpdateEffect(() => {
    if (update) {
      debouncedUpdate(update, true);
    }
  }, [update]);

  const bigNumToolTipMsg = useTxt('validation.number.toolTip');
  const uniqueCodeToolTipMsg = useTxt('validation.unique.string.toolTip');
  const percentageToolTip = useTxt('revenue.table.input.percentage.toolTip');
  const removeText = useTxt('revenue.table.rows.remove');

  useEffect(() => {
    if (rowRef.current && revenueId === highlightedId) {
      rowRef.current.scrollIntoView({ block: 'center' });
    }

    if (
      rowRef.current &&
      (memoizedScrollingState.batchCode !== batchCode ||
        memoizedScrollingState.billingDate !== billingDate)
    ) {
      rowRef.current.scrollIntoView({ block: 'center' });
      setScrollingState({
        batchCode,
        billingDate,
      });
    }
  }, [
    memoizedScrollingState,
    billingDate,
    batchCode,
    revenueId,
    highlightedId,
  ]);

  const onOneRowSelect = () => {
    dispatch(
      paymentProgramRowSelectionToggled({
        paymentProgramRowGroupId,
        paymentProgramRowId: revenueId,
      })
    );
  };

  return (
    <EditableRevenueRowWithHighlight
      rowHighlighted={highlightedId === revenueId}
      data-testid={`revenue-row-${revenueId}`}
      ref={rowRef}
    >
      {viewMode === 'edit' ? (
        <Cell align={aligns.select}>
          <Checkbox
            checked={isSelected}
            onChange={() => onOneRowSelect()}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                e.stopPropagation();
                e.preventDefault();
                onOneRowSelect();
              }
            }}
          />
        </Cell>
      ) : null}
      <EditableCell
        align={aligns.batchCode}
        initialValue={batchCode}
        codec={t.intersection([
          uniqueCode(allOtherCodes),
          maxLengthString(MAX_CODE_LENGTH),
        ])}
        onChange={updateRevenue('batchCode')}
        route={routes.REVENUE}
        leftPadded
        disabled={isBilled}
        toolTipMsg={uniqueCodeToolTipMsg}
        onBlur={() => {
          debouncedUpdate.flush();
        }}
      />

      <EditableCell
        align={aligns.description}
        initialValue={description}
        codec={maxLengthString(MAX_DESCRIPTION_LENGTH)}
        onChange={updateRevenue('description')}
        route={routes.REVENUE}
        onBlur={() => {
          debouncedUpdate.flush();
        }}
      />

      <EditableCell
        align={aligns.unitPrice}
        initialValue={big.priceFormat(unitPrice)}
        codec={bigInputString}
        onChange={updateRevenue('unitPrice')}
        route={routes.REVENUE}
        toolTipMsg={bigNumToolTipMsg}
        onBlur={() => {
          debouncedUpdate.flush();
        }}
      />

      <EditableCell
        align={aligns.quantity}
        initialValue={big.amountFormat(quantity)}
        codec={bigInputString}
        onChange={updateRevenue('quantity')}
        route={routes.REVENUE}
        toolTipMsg={bigNumToolTipMsg}
        onBlur={() => {
          debouncedUpdate.flush();
        }}
      />

      <EditableCell
        align={aligns.unit}
        initialValue={unit}
        codec={maxLengthString(MAX_UNIT_LENGTH)}
        onChange={updateRevenue('unit')}
        route={routes.REVENUE}
        onBlur={() => {
          debouncedUpdate.flush();
        }}
      />

      <Cell contentContainer={NetPriceCellDiv} align={aligns.netPrice}>
        <span>{big.priceFormat(netPrice)}</span>
        {predictionChangeFromLatest.abs().gte(1) ? (
          <PredictionChangeSpan predictionChange={predictionChangeFromLatest}>
            {`${predictionChangeFromLatest.gt(0) ? '+' : ''}${big.priceFormat(
              predictionChangeFromLatest
            )}`}
          </PredictionChangeSpan>
        ) : null}
      </Cell>

      <EditableCell
        align={aligns.vat}
        initialValue={`${big.amountFormat(vat)}%`}
        codec={bigPercentage}
        onChange={updateRevenue('vat')}
        toolTipMsg={percentageToolTip}
        route={routes.REVENUE}
        onBlur={() => {
          debouncedUpdate.flush();
        }}
      />

      <Cell align={aligns.grossPrice}>{big.priceFormat(grossPrice)}</Cell>

      <Cell align={aligns.billingDate}>
        {billingDate ? (
          <BillingDate
            previousDate={previousBillingDateFromLatest}
            date={billingDate}
            isBilled={isBilled}
            revenueId={revenueId}
            netPrice={netPrice}
          />
        ) : null}
      </Cell>

      <Cell align={aligns.actualizedBilling}>
        {big.priceFormat(actualizedBilling)}
      </Cell>

      <Cell contentContainer={TagsContainer}>
        <AnalysisTags revenueId={revenueId} />
      </Cell>

      <Cell align={aligns.status}>
        <StatusPill status={status} />
      </Cell>

      <Cell align={tableColumns.attachment.align} />
      <Cell align={tableColumns.delete.align}>
        {viewMode === 'edit' && isDeletable ? (
          <IconButton
            icon={IconDelete}
            onClick={onDeletePaymentBatchRow}
            aria-label={removeText}
          />
        ) : null}
      </Cell>
    </EditableRevenueRowWithHighlight>
  );
};

const FullSizeDropDownSelect = styled(DropDownSelect)`
  flex: 1;
`;

const StyledParagraph = styled.p`
  margin-right: ${(props) => props.theme.margin[8]};
`;

const NetPriceCellDiv = styled.div`
  position: relative;

  display: flex;
  flex-flow: column wrap;
  align-items: flex-end;
  justify-content: center;
`;

type PredictionChangeSpanProps = {
  predictionChange: Big;
};

const PredictionChangeSpan = styled.span<PredictionChangeSpanProps>`
  margin-top: ${({ theme }) => theme.margin[4]};
  color: ${({ predictionChange, theme }) =>
    predictionChange.gt(0)
      ? theme.color.positiveGreen
      : theme.color.negativeRed};
`;

type EditableRevenueRowWithHighlightProps = {
  rowHighlighted: boolean;
};

const EditableRevenueRowWithHighlight = styled(
  SecondaryRow
)<EditableRevenueRowWithHighlightProps>`
  @keyframes flash-animation {
    from {
      background: ${(props) => props.theme.color.sidebarBackground};
      opacity: 0.3;
    }
    to {
      background: default;
    }
  }

  ${({ rowHighlighted }) =>
    rowHighlighted
      ? css`
          animation: flash-animation linear 1.5s 1;
        `
      : css``}
`;
