import React from 'react';

import styled from 'styled-components';

import { APITask, APIProjectUser } from '@customtypes/api';

import useTxt from '../../../../../../hooks/useTxt';

import Tooltip from '../../../../../../components/Tooltip';

import { IconInfo } from '../../../../../../assets';

interface AssignerViewProps {
  assignee?: APIProjectUser;
  task: APITask;
}

const AssignerView = ({ assignee, task }: AssignerViewProps) => {
  const delegationStatusDictionary: Record<string, string> = {
    '1': useTxt('order.receiveMode.invoice.delegate.inProgress'),
    '2': useTxt('common.status.ok'),
    '3': useTxt('order.receiveMode.invoice.delegate.rejected'),
    '4': useTxt('order.receiveMode.invoice.delegate.ignored'),
  };

  return (
    <DelegatedItem>
      <AssigneeName>{assignee?.name}</AssigneeName> {' -'}
      <Tooltip tip={task.denyReason ?? ''}>
        <DelegationStatus>
          {delegationStatusDictionary[task.statusId]}
          {task.statusId === '3' ? <Icon src={IconInfo} /> : null}
        </DelegationStatus>
      </Tooltip>
    </DelegatedItem>
  );
};

const DelegatedItem = styled.div`
  display: grid;
  grid-template-columns: 1fr 1rem 1fr;
`;

const AssigneeName = styled.p`
  margin: 0;
  align-self: center;
`;

const DelegationStatus = styled.p`
  margin: 0;
  display: flex;
  align-items: center;
  gap: 0.4rem;
`;

const Icon = styled.img`
  border-radius: 50%;
  width: ${({ theme }) => theme.margin[18]};
  height: ${({ theme }) => theme.margin[18]};
  background-color: ${({ theme }) => theme.color.orange};
`;

export default AssignerView;
