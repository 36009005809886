import React, { useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Big from 'big.js';
import styled from 'styled-components';

import {
  selectWorkPackageGroupCurrentPeriodActualPoC,
  selectWorkPackageGroupCurrentPeriodLatestSnapshotPlannedPoC,
} from '../../../store/reducers/schedule/workPackageGroupTimeline';
import { getColumns } from '../../../store/reducers/ui';
import {
  getWorkPackageGroupById,
  getWorkPackageGroupTotals,
} from '../../../store/reducers/workPackageGroup';

import useTxt from '../../../hooks/useTxt';

import { IconTextButton } from '../../../components/Buttons';
import Cell from '../../../components/Cell';
import { BoldedPrimaryRow } from '../../../components/Table';
import ToolTip from '../../../components/Tooltip';
import Txt from '../../../components/Txt';

import { isClickOrKeyboardSelection } from '../../../utils/mouseOrKeyInteraction';

import { IconDown, IconRight } from '../../../assets';
import { IconInvalid, IconPlus } from '../../../assets/svg';

import { TextId } from '../../../localization';

import AddWorkPackageForm from './CreateWorkPackage';
import { CellContentValue } from './WorkPackageRow';
import { ColumnType } from '../../../store/localStorage';

type Props = {
  workPackageGroupId: string;
  projectId: string;
  isOpen: boolean;
  shouldFocusOnMount: boolean;
  onClick: () => void;
  navigateToDetailsPage: (id: string) => void;
  tasksOrDatesNotInUse: boolean;
};

const WorkPackageGroupRow = ({
  workPackageGroupId,
  projectId,
  isOpen,
  shouldFocusOnMount,
  onClick,
  navigateToDetailsPage,
  tasksOrDatesNotInUse,
}: Props) => {
  const [addWorkPackage, setAddWorkPackage] = useState<boolean>(false);
  const rowRef = useRef<HTMLTableRowElement>(null);
  const { workSectionViewColumns } = useSelector(getColumns);

  const workPackageGroup = useSelector(
    getWorkPackageGroupById(workPackageGroupId)
  );

  const actualPoC = useSelector(
    selectWorkPackageGroupCurrentPeriodActualPoC({
      workPackageGroupId,
      projectId,
    })
  );

  const latestSnapshotPoC = useSelector(
    selectWorkPackageGroupCurrentPeriodLatestSnapshotPlannedPoC({
      workPackageGroupId,
      projectId,
    })
  );

  const {
    targetTotal,
    predictionTotal,
    predictionChangeTotal,
    changeOrderTotal,
    contractTotal,
    receivedTotal,
    reservesTotal,
    additionalTargetTotal,
  } = useSelector(getWorkPackageGroupTotals(workPackageGroupId));

  useEffect(() => {
    if (rowRef.current && shouldFocusOnMount) {
      rowRef.current.focus();
    }
  }, [shouldFocusOnMount]);

  const latestSnapshotPercentageOfCompletion =
    latestSnapshotPoC?.percentageOfCompletion ?? null;

  const percentageOfCompletion = actualPoC?.percentageOfCompletion ?? null;

  const differenceToPreviousPoC =
    percentageOfCompletion?.sub(
      latestSnapshotPercentageOfCompletion ?? new Big(0)
    ) ?? null;

  if (!workPackageGroup) {
    return null;
  }

  const onKeyPress = (e: React.KeyboardEvent<HTMLTableRowElement>) => {
    if (isClickOrKeyboardSelection(e)) {
      e.preventDefault();
      onClick();
    }
  };

  const onAddWorkPackage = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    setAddWorkPackage(!addWorkPackage);
  };

  const totals: Partial<Record<TextId, Big | null | string>> = {
    'worksection.table.header.code': workPackageGroup.code,
    'worksection.table.header.name': workPackageGroup.name,
    'worksection.table.header.percentageOfCompletion': percentageOfCompletion,
    'worksection.table.header.differenceToPreviousPoC': differenceToPreviousPoC,
    'worksection.table.header.previousPoC':
      latestSnapshotPercentageOfCompletion,
    'worksection.table.header.targetTotal': targetTotal,
    'worksection.table.header.additionalTargetTotal': additionalTargetTotal,
    'worksection.table.header.predictionTotal': predictionTotal,
    'worksection.table.header.receivedTotal': receivedTotal,
    'worksection.table.header.predictionChangeFromLatest':
      predictionChangeTotal,
    'worksection.table.header.contractTotal': contractTotal,
    'worksection.table.header.changeOrdersTotal': changeOrderTotal,
    'worksection.table.header.reservesTotal': reservesTotal,
    'worksection.table.header.remaining': predictionTotal.minus(receivedTotal),
    'worksection.table.header.differenceToTarget':
      targetTotal.minus(predictionTotal),
  };

  return (
    <>
      <BoldedPrimaryRow
        clickable
        onClick={onClick}
        onKeyPress={onKeyPress}
        tabIndex={0}
        ref={rowRef}
      >
        {workSectionViewColumns
          .filter((item) => item.selected)
          .map((header) => {
            return (
              <CellContent
                totals={totals}
                column={header}
                key={`${header.name}-workPackageGroup-${workPackageGroup.id}`}
                setAddWorkPackage={onAddWorkPackage}
                isOpen={isOpen}
                invalid={tasksOrDatesNotInUse}
              />
            );
          })}
      </BoldedPrimaryRow>
      {addWorkPackage ? (
        <AddWorkPackageForm
          workPackageGroupId={workPackageGroupId}
          onClose={() => setAddWorkPackage(false)}
          navigateToDetailsPage={navigateToDetailsPage}
        />
      ) : null}
    </>
  );
};

type CellContentProps = {
  column: ColumnType;
  totals: Partial<Record<TextId, Big | null | string>>;
  isOpen: boolean;
  setAddWorkPackage: (e: React.SyntheticEvent) => void;
  invalid?: boolean;
};

const CellContent = ({
  column,
  totals,
  isOpen,
  setAddWorkPackage,
  invalid,
}: CellContentProps) => {
  const workPackageGroupOpenText = useTxt(
    'worksection.table.workPackageGroup.open'
  );

  const workPackageGroupClosedText = useTxt(
    'worksection.table.workPackageGroup.closed'
  );

  const tip = useTxt('worksection.table.header.workPackageGroup.PoC.toolTip');

  switch (column.name) {
    case 'worksection.table.header.code':
      return (
        <Cell>
          {isOpen ? (
            <img src={IconDown} alt={workPackageGroupOpenText} />
          ) : (
            <img src={IconRight} alt={workPackageGroupClosedText} />
          )}
          <CodeDiv>{totals[column.name]?.toString()}</CodeDiv>
        </Cell>
      );

    case 'worksection.table.header.none':
      return (
        <Cell>
          <IconTextButton
            icon={IconPlus}
            onClick={setAddWorkPackage}
            visibleOnlyOnHover
          >
            <Txt id="worksection.actionButton.addWorkPackage" />
          </IconTextButton>
        </Cell>
      );
    case 'worksection.table.header.percentageOfCompletion':
      return (
        <Cell align={column.align}>
          {invalid ? (
            <ToolTip
              tip={tip}
              className="line-break-compressed-tooltip"
              place="top"
            >
              <StyledInvalidIcon data-testid="poc-data-missing-work-package-group-icon" />
            </ToolTip>
          ) : null}
          <CellContentValue
            styling={column.numberStyling}
            price={totals[column.name]}
            showNullAsDash
          />
        </Cell>
      );
    default:
      return (
        <Cell align={column.align}>
          <CellContentValue
            styling={column.numberStyling}
            price={totals[column.name]}
            showNullAsDash={
              column.numberStyling === 'percentage' ||
              column.numberStyling === 'percentageDelta'
            }
          />
        </Cell>
      );
  }
};

const StyledInvalidIcon = styled(IconInvalid)`
  margin-right: ${(props) => props.theme.margin[8]};
  width: ${(props) => props.theme.sizes.iconButtonSmall};
  height: ${(props) => props.theme.sizes.iconButtonSmall};
`;

const CodeDiv = styled.div`
  padding-left: ${(props) => props.theme.margin[4]};
`;

export default WorkPackageGroupRow;
