import React, { useEffect, useRef, useState } from 'react';
import { MentionsInput, Mention } from 'react-mentions';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';
import { v1 } from 'uuid';

import {
  getOrderChangeLogEntries,
  getOrderChangeLogEntriesFetchRequest,
} from '../../../store/reducers/order/changeLogEntries';
import {
  getOrderChatMessages,
  getOrderChatFetchRequest,
  getOrderChatCreateRequest,
} from '../../../store/reducers/order/chatMessages';
import { getOrderById } from '../../../store/reducers/order/order';
import { getOrderSnapshots } from '../../../store/reducers/order/snapshots';
import { selectProjectUsers } from '../../../store/reducers/projectUsers';
import {
  getTargetChangeLogEntries,
  getTargetChangeLogEntriesFetchRequest,
} from '../../../store/reducers/target/changeLogEntries';
import {
  getActiveProjectId,
  getDraftOrderChatMessage,
} from '../../../store/reducers/ui';

import { fetchOrderSnapshots, fetchProjectUsers } from '../../../store/actions';
import { fetchOrderChangeLogEntries } from '../../../store/actions/order/changeLogEntries';
import {
  createOrderChatMessage,
  fetchOrderChatMessages,
  OrderChatMessage,
} from '../../../store/actions/order/chatMessages';
import { fetchTargetChangeLogEntries } from '../../../store/actions/target/changeLogEntries';
import { updateDraftOrderChatMessage } from '../../../store/actions/ui';

import useRemoteData from '../../../hooks/useRemoteData';
import useTxt from '../../../hooks/useTxt';

import { IconButton } from '../../../components/Buttons';
import SearchInput from '../../../components/Input/SearchInput';
import { Spinner } from '../../../components/Loading';
import Modal from '../../../components/Modal/Modal';
import { getUserInitials } from '../../../components/Navigation/UserSettings';
import Txt from '../../../components/Txt';
import {
  getAuthorColor,
  UserInitialsBadge,
} from '../../../components/UserInitialsBadge';

import { dateTimeFormat } from '../../../utils/format';
import { useDebounce, useInitialStateChange } from '../../../utils/hooks';
import { searchFilter } from '../../../utils/search';

import { IconSendMessage } from '../../../assets/svg';

import { ChangeLogTable } from './ChangeLogTable';
import { defaultStyle, mentionsStyle } from '../../../styles/mentionsStyle';

const MAX_MESSAGE_LENGTH = 5000;

type CommentModalProps = {
  orderId: string;
  onClose: () => void;
};

export const CommentModal = ({ orderId, onClose }: CommentModalProps) => {
  const [messageText, setMessageText] = useState('');
  const [taggedUserIds, settaggedUserIds] = useState<string[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isChatScrolled, setIsChatScrolled] = useState(false);
  const [isLogScrolled, setIsLogScrolled] = useState(false);
  const [filterValue, setFilterValue] = useState<undefined | string>(undefined);

  const bottomScrollRefChat = useRef<HTMLDivElement>(null);
  const bottomScrollRefLog = useRef<HTMLDivElement>(null);

  const storedMessageText =
    useSelector(getDraftOrderChatMessage(orderId)) ?? '';

  const dispatch = useDispatch();
  const [requestId] = useState(v1());

  // placeholder text for comment TextArea
  const placeholder = useTxt('comments.modal.comments.placeHolder');
  const projectId = useSelector(getActiveProjectId) ?? '';

  // refetch change log data everytime comment log is opened
  useEffect(() => {
    dispatch(fetchOrderChatMessages(orderId));
    dispatch(fetchOrderChangeLogEntries(orderId));
    dispatch(fetchOrderSnapshots(orderId));
    dispatch(fetchTargetChangeLogEntries(orderId));
  }, [dispatch, orderId]);

  useInitialStateChange(() => {
    if (storedMessageText.length > 0) {
      setMessageText(storedMessageText);
    }
  }, undefined);

  const projectUsers =
    useRemoteData(
      selectProjectUsers(projectId),
      fetchProjectUsers(projectId)
    ) ?? [];

  const chatMessages =
    useRemoteData(
      getOrderChatMessages(orderId),
      fetchOrderChatMessages(orderId)
    ) ?? [];

  const orderChangeLogEntries =
    useRemoteData(
      getOrderChangeLogEntries(orderId),
      fetchOrderChangeLogEntries(orderId)
    ) ?? [];

  const targetChangeLogEntries =
    useRemoteData(
      getTargetChangeLogEntries(orderId),
      fetchTargetChangeLogEntries(orderId)
    ) ?? [];

  const snapshots =
    useRemoteData(getOrderSnapshots(orderId), fetchOrderSnapshots(orderId)) ??
    [];

  const filteredOrderChangeLogEntries = searchFilter(
    orderChangeLogEntries,
    filterValue
  );

  const filteredTargetChangeLogEntries = searchFilter(
    targetChangeLogEntries,
    filterValue
  );

  const { visibleCode, name: orderName } = {
    ...useSelector(getOrderById(orderId)),
  };

  const createChatRequestState = useSelector(
    getOrderChatCreateRequest(requestId)
  );

  const orderLogRequestState = useSelector(
    getOrderChangeLogEntriesFetchRequest(orderId)
  );

  const targetLogRequestState = useSelector(
    getTargetChangeLogEntriesFetchRequest(orderId)
  );

  const chatRequestState = useSelector(getOrderChatFetchRequest(orderId)).kind;

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setIsSubmitting(true);

    dispatch(
      createOrderChatMessage({
        requestId,
        orderId,
        message: messageText.replaceAll('_', ''),
        taggedUserIds,
      })
    );
  };

  useEffect(() => {
    if (createChatRequestState === 'Success') {
      setIsSubmitting(false);
      setMessageText('');
      dispatch(updateDraftOrderChatMessage({ orderId, message: '' }));
      setIsChatScrolled(false);
    } else if (createChatRequestState === 'Failure') {
      setIsSubmitting(false);
    }
  }, [createChatRequestState, orderId, dispatch]);

  useEffect(() => {
    if (orderLogRequestState === 'Loading') {
      setIsLogScrolled(false);
    }

    if (targetLogRequestState === 'Loading') {
      setIsLogScrolled(false);
    }

    if (chatRequestState === 'Loading') {
      setIsChatScrolled(false);
    }

    if (isChatScrolled && isLogScrolled) {
      return;
    }

    if (
      bottomScrollRefChat.current &&
      bottomScrollRefLog.current &&
      orderLogRequestState === 'Success' &&
      targetLogRequestState === 'Success' &&
      chatRequestState === 'Success'
    ) {
      bottomScrollRefChat.current.scrollIntoView();
      bottomScrollRefLog.current.scrollIntoView();

      setIsLogScrolled(true);
      setIsChatScrolled(true);
    }
  }, [
    bottomScrollRefChat,
    isChatScrolled,
    bottomScrollRefLog,
    isLogScrolled,
    orderLogRequestState,
    chatRequestState,
    targetLogRequestState,
  ]);

  const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterValue(e.target.value);
  };

  const debouncedUpdate = useDebounce((message: string) => {
    dispatch(updateDraftOrderChatMessage({ orderId, message }));
  }, 1000);

  const onMessageChange = (event: { target: { value: string } }) => {
    setMessageText(event.target.value);
    debouncedUpdate(event.target.value);
  };

  const onTagAdd = (userId: string | number) => {
    const taggedIds = [...taggedUserIds, `${userId}`];
    settaggedUserIds([...new Set(taggedIds)]);
  };

  return (
    <Modal onClose={onClose}>
      <FormContainer onSubmit={onSubmit}>
        <Header>
          <Txt
            id="comments.modal.title.order"
            values={{ orderName, visibleCode }}
          />
        </Header>
        <Row>
          <LeftColumn>
            <Description>
              <Txt id="comments.modal.comments.header" />
            </Description>
            <ChatMessageList>
              {chatMessages.length ? (
                chatMessages.map((item) => (
                  <ChatMessage key={item.id} {...item} />
                ))
              ) : (
                <Txt
                  id="comments.modal.comments.noComments"
                  component={CenteredText}
                />
              )}
              <div ref={bottomScrollRefChat} />
            </ChatMessageList>
            <InputContainer>
              <MentionsInput
                value={messageText}
                placeholder={placeholder}
                style={defaultStyle}
                maxLength={MAX_MESSAGE_LENGTH}
                onChange={onMessageChange}
              >
                <Mention
                  // define markup if needed
                  markup="@__display___"
                  trigger="@"
                  data={projectUsers}
                  appendSpaceOnAdd
                  style={mentionsStyle}
                  displayTransform={(_, _d) => {
                    // transforms text before adding mentions
                    const _txt = `@${_d}`;

                    return _txt;
                  }}
                  onAdd={onTagAdd}
                />
              </MentionsInput>
              {isSubmitting ? (
                <StyledSpinner size="1rem" />
              ) : (
                <StyledSubmit
                  type="submit"
                  disabled={
                    isSubmitting ||
                    messageText.length === 0 ||
                    messageText.length > MAX_MESSAGE_LENGTH
                  }
                  icon={IconSendMessage}
                />
              )}
            </InputContainer>
          </LeftColumn>
          <RightColumn>
            <Description>
              <Txt id="comments.modal.changes.header" />
            </Description>
            <FilterInputContainer>
              <SearchInput
                onChange={onSearchInputChange}
                value={filterValue}
                handleClearButtonClick={() => setFilterValue('')}
              />
            </FilterInputContainer>
            <ChangeList>
              <ChangeLogTable
                changeLogEntries={[
                  ...filteredOrderChangeLogEntries,
                  ...filteredTargetChangeLogEntries,
                ]}
                snapshots={snapshots}
              />
              <div ref={bottomScrollRefLog} />
            </ChangeList>
          </RightColumn>
        </Row>
      </FormContainer>
    </Modal>
  );
};

const ChatMessage = ({ senderName, updatedAt, text }: OrderChatMessage) => {
  const authorColor = getAuthorColor(senderName);

  return (
    <Container>
      <AuthorIconAndLeftPadding>
        <UserInitialsBadge backgroundColor={authorColor} disabled>
          {getUserInitials(senderName)}
        </UserInitialsBadge>
      </AuthorIconAndLeftPadding>
      <AuthorName>{senderName}</AuthorName>
      <Timestamp>{dateTimeFormat.format(updatedAt)}</Timestamp>
      <MessageContent>{text}</MessageContent>
    </Container>
  );
};

const Container = styled.div`
  margin-bottom: ${({ theme }) => theme.margin[8]};

  border: 1px solid ${({ theme }) => theme.color.buttonPrimaryDisabled};
  border-radius: ${({ theme }) => theme.margin[4]};

  padding: ${({ theme }) => theme.margin[10]};
  padding: ${({ theme }) => `${theme.margin[16]}`};

  min-height: ${({ theme }) => `${theme.margin[96]}`};

  display: grid;

  background-color: rgba(217, 217, 217, 0.14);

  overflow-wrap: break-word;

  /* reset content direction set by parent container */
  direction: ltr;

  /* stylelint-disable declaration-block-no-redundant-longhand-properties -- Easier to read and edit when NOT shorthanded... */
  grid-template-columns: 0.3fr 1.5fr 1.2fr;
  grid-template-rows: 0.2fr 1fr 1fr;
  gap: ${({ theme }) => `${theme.margin[10]} ${theme.margin[10]}`};
  grid-auto-flow: row;
  grid-template-areas:
    'AuthorIconAndLeftPadding AuthorName Timestamp'
    'AuthorIconAndLeftPadding MessageContent MessageContent'
    'AuthorIconAndLeftPadding MessageContent MessageContent';
  /* stylelint-enable declaration-block-no-redundant-longhand-properties */
`;

const AuthorIconAndLeftPadding = styled.div`
  grid-area: AuthorIconAndLeftPadding;
`;

const AuthorName = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;

  font-weight: bold;

  grid-area: AuthorName;
`;

const Timestamp = styled.div`
  font-weight: bold;
  grid-area: Timestamp;
`;

const MessageContent = styled.div`
  white-space: pre-wrap;
  grid-area: MessageContent;
`;

const FormContainer = styled.form`
  box-shadow: 0px 4px 42px rgba(0, 0, 0, 0.25);

  min-height: 514px;
  width: 80vw;

  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  border-radius: 0 0 ${({ theme }) => theme.radius.medium}
    ${({ theme }) => theme.radius.medium};

  width: 100%;
  height: 80vh;

  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: stretch;

  background: ${(props) => props.theme.color.white};
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
`;

const LeftColumn = styled(Column)`
  margin: ${(props) =>
    `${props.theme.margin[24]} ${props.theme.margin[24]} ${props.theme.margin[36]} ${props.theme.margin[24]}`};
  border-right: 1px solid ${({ theme }) => theme.color.graphiteB76};
  padding-right: ${({ theme }) => theme.margin[8]};
  justify-content: space-between;
`;

const RightColumn = styled(Column)`
  padding: ${({ theme }) =>
      `${theme.margin[24]} ${theme.margin[16]} ${theme.margin[24]}`}
    0;
  flex: 2 1 0%;
`;

const ChatMessageList = styled.div`
  padding: ${({ theme }) =>
    `${theme.margin[16]} ${theme.margin[24]} ${theme.margin[16]} ${theme.margin[10]}`};
  height: 40vh;
  flex-grow: 4;
  overflow-y: auto;
`;

const InputContainer = styled.div`
  position: relative;
  padding: ${({ theme }) =>
    `${theme.margin[16]} ${theme.margin[24]} 0 ${theme.margin[10]}`};
`;

const Header = styled.div`
  border-radius: ${({ theme }) => theme.radius.medium}
    ${({ theme }) => theme.radius.medium} 0 0;

  padding: ${({ theme }) => `${theme.margin[16]}`};

  height: ${({ theme }) => theme.margin[48]};

  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: ${({ theme }) => theme.color.modalTitleBarBackground};

  color: ${({ theme }) => theme.color.white};
`;

const Description = styled.span`
  padding-bottom: ${({ theme }) => `${theme.margin[8]}`};
  font-weight: bold;
`;

const FilterInputContainer = styled.div`
  position: absolute;
  right: 2rem;

  padding-bottom: ${({ theme }) => `${theme.margin[16]}`};

  width: 30%;

  display: flex;
  justify-content: flex-end;
`;

const ChangeList = styled.div`
  margin: ${({ theme }) => `${theme.margin[16]}`} 0;

  padding-right: ${({ theme }) => `${theme.margin[4]}`};

  max-height: 70vh;
  min-height: 20rem;

  overflow-y: auto;
`;

const StyledSubmit = styled(IconButton)`
  position: absolute;
  right: ${({ theme }) => `${theme.margin[36]}`};
  bottom: ${({ theme }) => `${theme.margin[16]}`};

  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  &:disabled {
    background: transparent;
  }
`;

const StyledSpinner = styled(Spinner)`
  position: absolute;
  right: ${({ theme }) => `${theme.margin[36]}`};
  bottom: ${({ theme }) => `${theme.margin[16]}`};

  display: flex;
  align-items: center;
  justify-content: center;
`;

const CenteredText = styled.div`
  font-style: italic;
  text-align: center;
`;
