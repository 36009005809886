import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';

import {
  makeApiActions,
  ExtractActionTypes,
  makeAction,
} from '../../utils/actionCreators';
import { BackendError, GET, apiErrorHandlingWithDecode } from '../../utils/api';
import { bigString } from '../../utils/decoders';
import { flow } from '../../utils/function';
import * as remoteData from '../../utils/remoteData';
import { createAsyncThunk, Thunk } from '../../utils/thunk';

import {
  APIProject,
  selectAllProjectsRequest,
  selectProjectRequest,
} from '../reducers/project';

const actionCreators = {
  ...makeApiActions('get', 'projects')<APIProject[]>(),
  ...makeAction('getProjectStarted')<{ projectId: string }>(),
  ...makeAction('getProjectSuccess')<{
    projectId: string;
    projects: APIProject[];
  }>(),
  ...makeAction('getProjectFailure')<{
    projectId: string;
    error: BackendError | undefined;
  }>(),
};

export const {
  getProjectsStarted,
  getProjectsSuccess,
  getProjectsFailure,
  getProjectStarted,
  getProjectSuccess,
  getProjectFailure,
} = actionCreators;
export type ProjectAction = ExtractActionTypes<typeof actionCreators>;

const apiProjectType = t.exact(
  t.type({
    id: t.string,
    companyId: t.string,
    name: t.string,
    code: t.string,
    status: t.string,
    isClosed: t.boolean,
    externalUrl: t.union([t.string, t.null]),
    procurementAreaIds: t.array(t.string),
    workPackageIds: t.array(t.string),
    simplified: t.boolean,

    receivedTotal: t.union([bigString, t.undefined]),
    targetTotal: t.union([bigString, t.undefined]),
    additionalTargetTotal: t.union([bigString, t.undefined]),
    costPredictionTotal: t.union([bigString, t.undefined]),
    contractTotal: t.union([bigString, t.undefined]),
    changeOrdersTotal: t.union([bigString, t.undefined]),
    reservesTotal: t.union([bigString, t.undefined]),
    revenueTotal: t.union([bigString, t.undefined]),

    latestSnapshotTargetTotal: t.union([bigString, t.undefined]),
    latestSnapshotCostsTotal: t.union([bigString, t.undefined]),
    latestSnapshotContractTotal: t.union([bigString, t.undefined]),
    latestSnapshotChangeOrdersTotal: t.union([bigString, t.undefined]),
    latestSnapshotReservesTotal: t.union([bigString, t.undefined]),
    latestSnapshotRevenueTotal: t.union([bigString, t.undefined]),

    targetChangeFromLatest: t.union([bigString, t.undefined]),
    costPredictionChangeFromLatest: t.union([bigString, t.undefined]),
    contractChangeFromLatest: t.union([bigString, t.undefined]),
    changeOrdersChangeFromLatest: t.union([bigString, t.undefined]),
    reservesChangeFromLatest: t.union([bigString, t.undefined]),
    revenuePredictionChangeFromLatest: t.union([bigString, t.undefined]),

    nextgenProjectId: t.union([t.string, t.null]),
    nextgenDefaultScheduleViewId: t.union([t.string, t.null]),

    externalDimensionsUpdateEnabled: t.boolean,
    externalPaymentProgramUpdateEnabled: t.boolean,
    externalTargetUpdateEnabled: t.boolean,
    externalOrderRowUpdateEnabledByDefault: t.boolean,
  })
);

export async function toProjects(u: unknown): Promise<APIProject[]> {
  const apiProjects = await tPromise.decode(t.array(apiProjectType), u);

  return apiProjects;
}

const getProjects = async () => {
  const response = await GET<APIProject[]>('v1/projects');

  return toProjects(response);
};

export const fetchProjects = (): Thunk =>
  createAsyncThunk(getProjects, {
    args: [],
    isPending: flow(selectAllProjectsRequest(), remoteData.isLoading),
    initialAction: getProjectsStarted(),
    successActionCreator: (projects) => getProjectsSuccess(projects),
    failureActionCreator: (error) =>
      getProjectsFailure(apiErrorHandlingWithDecode(error)),
  });

const getProject = async (id: string) => {
  const response = await GET<APIProject>(`v1/projects/${id}`);

  return toProjects([response]);
};

export const fetchProject = (projectId: string): Thunk =>
  createAsyncThunk(getProject, {
    args: [projectId],
    isPending: flow(selectProjectRequest(projectId), remoteData.isLoading),
    initialAction: getProjectStarted({ projectId }),
    successActionCreator: (projects) =>
      getProjectSuccess({ projectId, projects }),
    failureActionCreator: (error) =>
      getProjectFailure({
        projectId,
        error: apiErrorHandlingWithDecode(error),
      }),
  });
