import { useIntl, IntlShape } from 'react-intl';

import { TextId } from '../localization';

export interface LocalizationShape extends IntlShape {
  formatMessage(
    descriptor: { id: TextId },
    values?: Record<string, string | number>
  ): string;
}

export default function useLocalization(): LocalizationShape {
  return useIntl();
}
