import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useFormik, FormikErrors } from 'formik';
import styled from 'styled-components';
import { css } from 'styled-components';
import { v4 } from 'uuid';

import { getBatchGroups } from '../../../store/reducers/batchGroup';
import { getPaymentProgramRowGroupPostRequest } from '../../../store/reducers/paymentProgramRowGroup';

import {
  PostPaymentProgramRowGroupParams,
  requestNewPaymentProgramRowGroup,
} from '../../../store/actions';
import { requestBatchGroups } from '../../../store/actions/batchGroups';

import useRemoteData from '../../../hooks/useRemoteData';
import useTxt from '../../../hooks/useTxt';

import {
  CenteredButtonGroup,
  SecondaryButton,
  PrimaryButton,
} from '../../../components/Buttons';
import { InputContainer } from '../../../components/Containers';
import DropDownSelect from '../../../components/DropDownSelect';
import { SelectLabel } from '../../../components/Input/Select';
import TextInput from '../../../components/Input/TextInput';
import { Spinner } from '../../../components/Loading';
import Txt from '../../../components/Txt';

import theme from '../../../styles/theme';

type Props = {
  projectId: string;
  onClose: () => void;
};

export const MAX_GROUP_DESCRIPTION_LENGTH = 200;

const CreateGroupForm = ({ projectId, onClose }: Props) => {
  const dispatch = useDispatch();

  const [requestId] = React.useState(v4());

  const batchOptions = useRemoteData(getBatchGroups, requestBatchGroups) ?? [];

  const requestState = useSelector(
    getPaymentProgramRowGroupPostRequest(requestId)
  );

  type FormValues = {
    description: string;
    projectId: string;
    code: string;
    paymentBatchGroupId: string;
  };

  type ErrorTextId =
    | 'revenue.addGroup.formik.error.mandatory'
    | 'revenue.addGroup.formik.error.description.length'
    | 'revenue.addGroup.formik.error.code.length';

  function isErrorTextId(value: string | undefined): value is ErrorTextId {
    return [
      'revenue.addGroup.formik.error.mandatory',
      'revenue.addGroup.formik.error.description.length',
      'revenue.addGroup.formik.error.code.length',
    ].includes(value as ErrorTextId);
  }

  const validate = (values: FormValues): FormikErrors<FormValues> => {
    const errors: FormikErrors<FormValues> = {};

    if (values.description === '') {
      errors.description = 'revenue.addGroup.formik.error.mandatory';
    }

    if (values.description.length > MAX_GROUP_DESCRIPTION_LENGTH) {
      errors.description = 'revenue.addGroup.formik.error.description.length';
    }

    if (values.code === '') {
      errors.code = 'revenue.addGroup.formik.error.mandatory';
    }

    if (values.code.length > 10) {
      errors.code = 'revenue.addGroup.formik.error.code.length';
    }

    if (
      values.paymentBatchGroupId === '' ||
      values.paymentBatchGroupId === 'NONE'
    ) {
      errors.paymentBatchGroupId = 'revenue.addGroup.formik.error.mandatory';
    }

    return errors;
  };

  useEffect(() => {
    if (requestState.kind === 'Success') {
      onClose();
    }
  }, [onClose, requestState.kind]);

  const formik = useFormik<FormValues>({
    initialValues: {
      description: '',
      projectId,
      code: '',
      paymentBatchGroupId: 'NONE',
    },
    validate,
    onSubmit: (values) => {
      const body: PostPaymentProgramRowGroupParams = {
        description: values.description,
        projectId,
        code: values.code,
        paymentBatchGroupId: values.paymentBatchGroupId,
      };

      dispatch(requestNewPaymentProgramRowGroup(requestId, body));
    },
    validateOnMount: true,
  });

  const codeText = useTxt('common.code');
  const descriptionText = useTxt('common.description');

  const descriptionErrorId = isErrorTextId(formik.errors.description)
    ? formik.errors.description
    : undefined;

  const codeErrorId = isErrorTextId(formik.errors.code)
    ? formik.errors.code
    : undefined;

  const batchGroupErrorId = isErrorTextId(formik.errors.paymentBatchGroupId)
    ? formik.errors.paymentBatchGroupId
    : undefined;

  const descriptionError = useTxt(descriptionErrorId);
  const codeError = useTxt(codeErrorId);

  const batchGroupError = useTxt(batchGroupErrorId);
  const notFoundText = useTxt('common.paymentBatchGroup.notFound');

  return (
    <StyledForm name="edit-order-form" onSubmit={formik.handleSubmit}>
      <InputContainer>
        <TextInput
          label={descriptionText}
          name="description"
          value={formik.values.description}
          onChange={formik.handleChange}
          disabled={formik.isSubmitting}
          paddingBottom="1rem"
          errorMessage={descriptionError === '' ? undefined : descriptionError}
        />
        <TextInput
          label={codeText}
          name="code"
          value={formik.values.code}
          onChange={formik.handleChange}
          disabled={formik.isSubmitting}
          paddingBottom="1rem"
          errorMessage={codeError === '' ? undefined : codeError}
        />
        <StyledSelectLabel htmlFor="payment-batch-group-select">
          <LabelTextSpan invalid={!!formik.errors.paymentBatchGroupId}>
            <Txt id="revenue.addGroup.formik.paymentBatchGroup" component="b" />
          </LabelTextSpan>

          <DropDownSelect
            additionalStyles={additionalStyling}
            additionalContainerStyles={additionalContainerStyling}
            onChange={(value) => {
              const selection = value === 'NONE' ? '' : value;
              formik.setFieldValue('paymentBatchGroupId', selection);
            }}
            disabled={formik.isSubmitting}
            id="paymentBatchGroup-select"
            label="paymentBatchGroup-select"
            notFound={notFoundText}
            required
            invalid={!!formik.errors.paymentBatchGroupId}
            options={[
              ...batchOptions.map((s) => ({
                key: s.id,
                value: s.id,
                label: s.description,
              })),
            ]}
          />
          {formik.errors.paymentBatchGroupId ? (
            <ErrorDiv className="error">{batchGroupError}</ErrorDiv>
          ) : null}
        </StyledSelectLabel>
      </InputContainer>

      <CenteredButtonGroup>
        <SecondaryButton type="button" onClick={onClose}>
          <Txt id="common.cancel" />
        </SecondaryButton>
        <PrimaryButton type="submit" disabled={formik.isSubmitting}>
          {formik.isSubmitting ? (
            <Spinner size="1rem" light />
          ) : (
            <Txt id="common.save" />
          )}
        </PrimaryButton>
      </CenteredButtonGroup>
    </StyledForm>
  );
};

export default CreateGroupForm;

const StyledSelectLabel = styled(SelectLabel)`
  padding-bottom: ${(props) => props.theme.margin[16]};
`;

const StyledForm = styled.form`
  margin-bottom: ${(props) => props.theme.margin[16]};
`;

const ErrorDiv = styled.div`
  padding: ${(props) => props.theme.margin[4]}
    ${(props) => props.theme.margin[16]};
  color: ${(props) => props.theme.color.red};
`;

const LabelTextSpan = styled.span<{ invalid: boolean }>`
  padding: ${(props) => props.theme.margin[16]}
    ${(props) => props.theme.margin[16]} ${(props) => props.theme.margin[8]} 0;
  ${(props) =>
    props.invalid
      ? css`
          color: ${props.theme.color.red};
        `
      : ''}
`;

const additionalStyling: React.CSSProperties = {
  marginTop: theme.margin[8],
  marginBottom: 0,
  borderRadius: theme.margin[4],
  background: theme.color.dropdownBg,
  fontSize: theme.fontSize.base,
  appearance: 'none',
};

const additionalContainerStyling: React.CSSProperties = {
  padding: `${theme.margin[4]} ${theme.margin[4]} ${theme.margin[4]} ${theme.margin[16]}`,
  height: theme.margin[36],
  backgroundColor: 'transparent',
  margin: `${theme.margin[4]} 0 ${theme.margin[4]} ${theme.margin[16]}`,
};
