import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useMeasure from 'react-use-measure';

import styled from 'styled-components';

import { getProjectById } from '../../store/reducers/project';
import { hasProjectAnyTargetRows } from '../../store/reducers/target/targetRows';
import { getRequestStateByProjectId } from '../../store/reducers/topic';
import { selectCurrentUser } from '../../store/reducers/user';

import * as Actions from '../../store/actions';

import useTargetViewData from './hooks/useTargetViewData';
import useRemoteData from '../../hooks/useRemoteData';
import useTxt from '../../hooks/useTxt';

import Summary from './components/Summary';
import { TargetImportModal } from './components/TargetImportModal';
import TargetTable from './components/TargetTable';
import Header from '../../components/Header';
import TabIconTextButton, {
  StickyTabIconContainer,
} from '../../components/Navigation/TabIconTextButton';
import ProjectInfo from '../../components/ProjectInfo';
import { ToolBeltContainer } from '../../components/ToolBelt';
import ToolTip from '../../components/Tooltip';
import Txt from '../../components/Txt';

import CAN, {
  CaslUserRightsRequestParams,
} from '../../utils/caslUserPermissions';

import {
  IconUpLoad,
  IconEdit,
  IconCopy,
  IconArrowRight,
  IconDown,
  IconUp,
  IconFunnel,
  IconFunnelBlack,
} from '../../assets/svg';

import { generateUrl, routes, useParams } from '../../routes';

export const ToolbarContainer = styled.div`
  height: ${(props) => props.theme.margin[32]};

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  line-height: ${(props) => props.theme.margin[32]};
`;

const TargetView = () => {
  const [toolbeltButtonSelectionState, setToolbeltButtonSelectionState] =
    React.useState<{ isAnySelected: boolean; isHighestLevelSelected: boolean }>(
      { isAnySelected: false, isHighestLevelSelected: false }
    );

  const [isFilterLinkedToOrderRows, setIsFilterLinkedToOrderRows] =
    React.useState(false);

  const [showTargetImportModal, setShowTargetImportModal] =
    React.useState(false);

  const [showUpdateProcurementsModal, setUpdateProcurementsModal] =
    React.useState(false);

  const { projectId, viewMode } = useParams(routes.TARGET);

  const tableRef = React.useRef<{
    toggleExpandAll: (expand: boolean) => void;
    selectAllLowestLevelItems: () => void;
    selectLowestLevel: () => void;
    selectNextLevel: () => void;
  }>(null);

  const project = useSelector(getProjectById(projectId));
  const currentUser = useSelector(selectCurrentUser);

  useRemoteData(
    getRequestStateByProjectId(projectId),
    Actions.fetchTopicsForProject(projectId)
  );

  const [measureRef, measures] = useMeasure({ debounce: 100 });

  const projectHasTargetRows = useSelector(hasProjectAnyTargetRows(projectId));

  const updateProcurementsTooltip = useTxt(
    'target.toolbelt.button.edit.tooltip'
  );

  const disabledToolTipText = useTxt(
    'target.toolbelt.button.updateTarget.disabled'
  );

  const importFromCsvToolTipText = useTxt(
    'target.targetImportModal.uploadFile.paragraph',
    { projectCodeName: `${project?.code} ${project?.name}` }
  );

  const history = useHistory();

  const toggleMode = () => {
    history.push(
      generateUrl({
        route: routes.TARGET,
        projectId,
        viewMode: viewMode === 'edit' ? undefined : 'edit',
      })
    );
  };

  const onClickShowTargetImportModal = () => {
    setShowTargetImportModal(true);
    history.push(
      generateUrl({
        route: routes.TARGET,
        projectId,
        viewMode: 'edit',
      })
    );
  };

  const data = useTargetViewData(
    isFilterLinkedToOrderRows ? 'noOrderRowLink' : undefined
  );

  // needs admin rights to add new original target
  const ability = new CaslUserRightsRequestParams(currentUser?.id ?? '');
  const allowedUser = CAN('write', ability);

  const externalTargetUpdateEnabled =
    project?.externalTargetUpdateEnabled ?? true;

  const onSelectAllLowestLevelItemsRows = () => {
    if (tableRef.current) {
      tableRef.current.selectAllLowestLevelItems();

      tableRef.current.toggleExpandAll(true);
    }
  };

  const onSelectLowestLevel = () => {
    if (tableRef.current) {
      tableRef.current.selectLowestLevel();

      tableRef.current.toggleExpandAll(true);
    }
  };

  const onSelectNextLevel = () => {
    if (tableRef.current) {
      tableRef.current.selectNextLevel();

      tableRef.current.toggleExpandAll(true);
    }
  };

  /**
   * TODO: This is a hack, but with current version of @tanstack/react-virtual the useVirtualizer hook
   * produces error when the parent element (scrollElement) size is changed.
   *
   * Error only shows itself to developers and does not affect the end user experience.
   *
   * Future versions of the library
   * have enabled-option which might solve the issue. Other option would be to copy table state to higher
   * component and re-render the table out of scratch when the size changes.
   */

  window.addEventListener('error', (e) => {
    if (
      e.message === 'ResizeObserver loop limit exceeded' ||
      e.message ===
        'ResizeObserver loop completed with undelivered notifications.'
    ) {
      const resizeObserverErrDiv = document.getElementById(
        'webpack-dev-server-client-overlay-div'
      );

      const resizeObserverErr = document.getElementById(
        'webpack-dev-server-client-overlay'
      );

      if (resizeObserverErr) {
        resizeObserverErr.setAttribute('style', 'display: none');
      }

      if (resizeObserverErrDiv) {
        resizeObserverErrDiv.setAttribute('style', 'display: none');
      }
    }
  });

  return (
    <>
      {showTargetImportModal ? (
        <TargetImportModal onClose={() => setShowTargetImportModal(false)} />
      ) : null}
      <Container>
        <Header>
          <Txt id="target.header" />
        </Header>
        <ProjectInfo projectId={projectId} />
        <StickyTabIconContainer>
          <ToolTip tip={updateProcurementsTooltip}>
            <TabIconTextButton
              id="target.toolbelt.button.edit"
              icon={StyledIconProject}
              onClick={() => toggleMode()}
              disabled={!allowedUser}
              isActive={viewMode ? viewMode === 'edit' : false}
            />
          </ToolTip>
          <ToolTip
            tip={
              externalTargetUpdateEnabled
                ? disabledToolTipText
                : importFromCsvToolTipText
            }
            className="compressed-tooltip"
          >
            <TabIconTextButton
              id={
                projectHasTargetRows
                  ? 'target.toolbelt.button.updateTarget'
                  : 'target.toolbelt.button.importTarget'
              }
              icon={IconUpLoad}
              onClick={() => onClickShowTargetImportModal()}
              disabled={!allowedUser || externalTargetUpdateEnabled}
              isActive={showTargetImportModal}
            />
          </ToolTip>
        </StickyTabIconContainer>
        {viewMode === 'edit' ? (
          <StyledToolBeltContainer>
            <TabIconTextButton
              icon={IconCopy}
              disabled={!toolbeltButtonSelectionState.isAnySelected}
              id="target.toolbelt.button.updateProcurements.convertToOrderRows"
              isActive={showUpdateProcurementsModal}
              onClick={() => setUpdateProcurementsModal(true)}
            />
            <TabIconTextButton
              id="target.toolbelt.button.updateProcurements.filterLinkedToOrderRows"
              icon={isFilterLinkedToOrderRows ? IconFunnelBlack : IconFunnel}
              onClick={() => setIsFilterLinkedToOrderRows((prev) => !prev)}
              isActive={isFilterLinkedToOrderRows}
            />
            <TabIconTextButton
              icon={IconUp}
              id="target.toolbelt.button.updateProcurements.selectNextLevel"
              isActive={false}
              disabled={
                toolbeltButtonSelectionState.isHighestLevelSelected ||
                !toolbeltButtonSelectionState.isAnySelected
              }
              onClick={() => onSelectNextLevel()}
            />
            <TabIconTextButton
              id="target.toolbelt.button.updateProcurements.selectLowestLevel"
              isActive={false}
              icon={IconDown}
              onClick={() => onSelectLowestLevel()}
            />
            <TabIconTextButton
              id="target.toolbelt.button.updateProcurements.selectAllLowestLevelItemsRows"
              isActive={false}
              icon={IconArrowRight}
              onClick={() => onSelectAllLowestLevelItemsRows()}
            />
          </StyledToolBeltContainer>
        ) : null}
        <Summary projectId={projectId} />
        <GhostDiv ref={measureRef} />
        {showTargetImportModal ? (
          // hide table while modal is open
          <EmptyTable />
        ) : (
          <TargetTable
            ref={tableRef}
            projectId={projectId}
            data={data}
            parentMeasures={{
              width: measures.width,
              height:
                viewMode === 'edit'
                  ? 'calc(100vh - 184px)'
                  : 'calc(100vh - 147px)',
            }}
            showUpdateProcurementsModal={showUpdateProcurementsModal}
            setUpdateProcurementsModal={setUpdateProcurementsModal}
            toolbeltButtonSelectionState={
              viewMode === 'edit' ? toolbeltButtonSelectionState : undefined
            }
            setToolbeltButtonSelectionState={
              viewMode === 'edit' ? setToolbeltButtonSelectionState : undefined
            }
          />
        )}
      </Container>
    </>
  );
};

const GhostDiv = styled.div`
  height: 0px;
  width: 100%;
`;

const StyledToolBeltContainer = styled(ToolBeltContainer)`
  padding: 0;
`;

const Container = styled.div`
  margin: ${({ theme }) => theme.margin[24]} ${({ theme }) => theme.margin[40]}
    0;
`;

const EmptyTable = styled.table`
  width: 100%;
  table-layout: fixed;
`;

const StyledIconProject = styled(IconEdit)`
  width: 16px;
  height: 16px;
  /* stylelint-disable selector-max-type -- svgs are most easily styled with selectors */
  path {
    fill: ${({ theme }) => theme.color.pitch};
  }
`;

export default TargetView;
