import React from 'react';
import { Link } from 'react-router-dom';

import { generateUrl, routes } from '../../routes';

import Txt from '../Txt';

interface BaseInvoiceHeaderProps {
  orderId: string;
  orderVisibleCodeName: string;
  projectId: string | null;
}

interface InvoiceProps extends BaseInvoiceHeaderProps {
  invoiceHeaderId: string;
  actualCostNumber?: never;
  vendorInvoiceNo: string;
  actualCostId?: never;
}

interface ActualCostProps extends BaseInvoiceHeaderProps {
  actualCostNumber: string;
  vendorInvoiceNo?: never;
  invoiceHeaderId?: never;
  actualCostId: string;
}

export type InvoiceHeaderMovedNotificationProps =
  | ActualCostProps
  | InvoiceProps;

const InvoiceHeaderMovedNotification: React.FC<
  InvoiceHeaderMovedNotificationProps
> = (props) => {
  const { orderId, orderVisibleCodeName, projectId } = props;

  const { actualCostNumber } = props || { actualCostNumber: null };

  const { vendorInvoiceNo } = props || {
    vendorInvoiceNo: null,
  };

  const { invoiceHeaderId } = props || { invoiceHeaderId: null };

  const expenseId = actualCostNumber || vendorInvoiceNo;

  const orderTextId = invoiceHeaderId
    ? 'order.editMode.moveModal.information.invoice'
    : 'order.editMode.moveModal.information.actualCost';

  if (!projectId) {
    return null;
  }

  return (
    <>
      <Txt id={orderTextId} values={{ expenseId }} />{' '}
      <Link
        to={generateUrl({
          route: routes.ORDER,
          orderId,
          viewMode: 'receive',
          subViewMode: 'invoices',
          projectId,
        })}
      >
        {orderVisibleCodeName}
      </Link>
    </>
  );
};

export default InvoiceHeaderMovedNotification;
