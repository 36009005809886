import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import useMeasure from 'react-use-measure';

import { flexRender, Row } from '@tanstack/react-table';
import { VirtualItem } from '@tanstack/react-virtual';
import styled, { css } from 'styled-components';

import { tableColumns } from './hooks/useScheduleTreeTableColumns';
import useTxt from '../../../hooks/useTxt';

import { IconButton } from '../../../components/Buttons';
import { ColorBox } from '../../../components/ColorBox';
import CheckboxComponent from '../../../components/Input/Checkbox';
import { StyledTooltip } from '../../../components/Tooltip';

import { ExtendedScheduleWorkPackageType } from './utils';

import {
  IconCollapseMinus,
  IconExclamationMark,
  IconExpandPlus,
} from '../../../assets/svg';

import { generateUrl, routes, useParams } from '../../../routes';

import { ExpandCollapseButton } from './NewScheduleTree';
import { ScheduleTreeTableContext } from './ScheduleTreeTable';

type ScheduleTreeRowProps = React.ComponentProps<typeof TableRow> & {
  virtualRow: VirtualItem;
  measureElement: (node: Element | null) => void;
  scheduleWorkPackage: Row<ExtendedScheduleWorkPackageType>;
  containerWidth: number;
  showButtons?: boolean;
  isSelected?: boolean;
  indeterminate?: boolean;
};
interface RowContextProps {
  isHovered: boolean;
  setIsHovered: React.Dispatch<React.SetStateAction<boolean>>;
}

const ScheduleTreeRowContext = React.createContext<RowContextProps>({
  isHovered: false,
  setIsHovered: () => {},
});

const NewScheduleTreeRow = React.memo(
  ({
    scheduleWorkPackage,
    virtualRow,
    measureElement,
    containerWidth,
    showButtons = true,
    ...props
  }: ScheduleTreeRowProps) => {
    const includedInPoC = scheduleWorkPackage.original.includedInPoC;

    const tipId = 'worksection.workpackage.editModal.notIncludedInPoc.tooltip';

    const tip = useTxt(
      'worksection.workpackage.editModal.notIncludedInPoc.tooltip'
    );

    const needsTooltip = () => {
      if (
        scheduleWorkPackage.original.existingLinkages &&
        scheduleWorkPackage.original.existingLinkages.length > 0
      ) {
        return true;
      }

      return false;
    };

    const showTooltip = needsTooltip();

    const memoizedVirtualRowStart = React.useMemo(() => {
      if (showTooltip) {
        return virtualRow.start;
      }

      return undefined;
    }, [virtualRow.start, showTooltip]);

    const columns = scheduleWorkPackage
      .getVisibleCells()
      .map((cell) => cell.column)
      .filter((column) => showButtons || column.id !== 'buttons');

    const cells = scheduleWorkPackage
      .getVisibleCells()
      .filter((cell) => showButtons || cell.column.id !== 'buttons');

    return (
      <ToolTipTableRow
        ref={(node) => measureElement(node)}
        includedInPoC={!!includedInPoC}
        tip={tip}
        tipId={tipId}
        virtualRow={virtualRow}
        data-index={virtualRow.index}
        {...props}
      >
        {cells.map((cell) => {
          const headerId = cell.column.id as keyof typeof tableColumns;

          const thWidth = tableColumns[headerId]?.width * 16; // 1rem = 16px
          const fixedWidth = tableColumns[headerId]?.fixedWidth;

          const containerWidthMinusFixedWidths =
            columns.reduce((acc, currentHeader) => {
              const currentHeaderId =
                currentHeader.id as keyof typeof tableColumns;

              const currentHeaderFixedWidth =
                tableColumns[currentHeaderId]?.fixedWidth;

              return (
                acc -
                (currentHeaderFixedWidth
                  ? tableColumns[currentHeaderId]?.width * 16
                  : 0)
              );
            }, containerWidth) - 20;

          const dynamicWidthTotal = columns.reduce((acc, currentHeader) => {
            const currentHeaderId =
              currentHeader.id as keyof typeof tableColumns;

            const currentHeaderFixedWidth =
              tableColumns[currentHeaderId]?.fixedWidth;

            return (
              acc +
              (!currentHeaderFixedWidth
                ? tableColumns[currentHeaderId]?.width * 16
                : 0)
            );
          }, 0);

          const dynamicWidth = Math.max(
            (thWidth / dynamicWidthTotal) * containerWidthMinusFixedWidths,
            thWidth // use as minimum width
          );

          return (
            <ScheduleCell
              key={cell.id}
              width={fixedWidth ? thWidth : dynamicWidth}
              align={cell.column.columnDef?.meta?.align}
            >
              {flexRender(cell.column.columnDef.cell, {
                ...cell.getContext(),
                virtualRowStart: memoizedVirtualRowStart,
              })}
            </ScheduleCell>
          );
        })}
      </ToolTipTableRow>
    );
  }
);

export default NewScheduleTreeRow;

type ToolTipTableRowProps = React.ComponentProps<typeof TableRow> & {
  children: React.ReactNode;
  includedInPoC: boolean;
  tipId: string;
  tip: string;
  virtualRow: VirtualItem;
  'data-index': number;
};

const ToolTipTableRow = React.forwardRef(
  (
    {
      children,
      includedInPoC,
      tipId,
      tip,
      virtualRow,
      ...props
    }: ToolTipTableRowProps,
    ref: React.ForwardedRef<HTMLTableRowElement>
  ) => {
    const [isHovered, setIsHovered] = React.useState(false);

    if (!includedInPoC) {
      return (
        <ScheduleTreeRowContext.Provider value={{ isHovered, setIsHovered }}>
          <TableRow
            includedInPoC={includedInPoC}
            data-tip={tip}
            isHovered={isHovered}
            data-for={tipId ?? `global_${tip}`}
            virtualRow={virtualRow}
            ref={ref}
            {...props}
          >
            {children}
          </TableRow>
          {!includedInPoC ? (
            <NoHeightTr virtualRow={virtualRow}>
              <td>
                <StyledTooltip
                  effect="solid"
                  type="dark"
                  place="top"
                  offset={{ top: -5 }}
                  class="compressed-tooltip"
                  id={tipId ?? `global_${tip}`}
                  delayShow={200}
                  role="tooltip"
                  html
                />
              </td>
            </NoHeightTr>
          ) : null}
        </ScheduleTreeRowContext.Provider>
      );
    }

    return (
      <ScheduleTreeRowContext.Provider value={{ isHovered, setIsHovered }}>
        <TableRow
          includedInPoC={includedInPoC}
          virtualRow={virtualRow}
          isHovered={isHovered}
          ref={ref}
          {...props}
        >
          {children}
        </TableRow>
      </ScheduleTreeRowContext.Provider>
    );
  }
);

type CellProps = {
  noBorder?: boolean;
  width?: number;
  inline?: boolean;
};

export const ScheduleCell = styled.td<CellProps>`
  border-left: none;
  border-right: ${(props) =>
    props.noBorder
      ? 'none'
      : `1px solid ${props.theme.color['M3/ref/neutral/neutral90']}`};
  border-bottom: ${(props) =>
    props.noBorder
      ? 'none'
      : `1px solid ${props.theme.color['M3/ref/neutral/neutral90']}`};
  border-top: ${(props) =>
    props.noBorder
      ? 'none'
      : `1px solid ${props.theme.color['M3/ref/neutral/neutral90']}`};

  padding-left: ${(props) => props.theme.margin[16]};
  padding-right: ${(props) => props.theme.margin[8]};

  ${({ inline }) =>
    inline
      ? css`
          display: inline;
        `
      : null}

  height: ${(props) => props.theme.margin[32]};
  width: ${({ width }) => (width ? `${width}px` : 'auto')};
  max-width: ${({ width }) => (width ? `${width}px` : 'auto')};
`;

type RowProps = {
  includedInPoC?: boolean;
  virtualRow: VirtualItem;
  isHovered?: boolean;
};

const TableRow = styled.tr.attrs<RowProps>(({ virtualRow }) => ({
  style: { transform: `translateY(${virtualRow.start}px)` },
}))<RowProps>`
  position: absolute;
  width: 100%;
  height: ${({ isHovered }) => (isHovered ? 'auto' : 'unset')};
  background-color: ${(props) =>
    !props.includedInPoC ? props.theme.color.graphiteB91 : 'unset'};
`;

const NoHeightTr = styled.tr.attrs<RowProps>(({ virtualRow }) => ({
  style: { transform: `translateY(${virtualRow.start}px)` },
}))<RowProps>`
  position: absolute;
  width: 100%;
  height: 0;
  z-index: 2;
`;

export const TeamColorBox = styled(ColorBox)`
  margin-right: ${(props) => props.theme.margin[8]};
`;

export const NameCellContent = ({
  row,
  virtualRowStart,
  showCheckBoxes,
}: {
  row: Row<ExtendedScheduleWorkPackageType>;
  virtualRowStart: number | undefined;
  showCheckBoxes?: boolean;
}) => {
  const { projectId } = useParams(routes.WORKSECTION_EXPANDED);
  const history = useHistory();
  const value = row.original.name;

  const [isMouseHover, setIsMouseHover] = React.useState<undefined | boolean>();
  const [measureRef, measures] = useMeasure({ debounce: 100 });
  const tooltipContext = useContext(ScheduleTreeTableContext);
  const rowContext = useContext(ScheduleTreeRowContext);

  const rowToggleExpand = row.getToggleExpandedHandler();

  const { top, left, height, width, right, bottom } = measures;

  const isExpanded = row.getIsExpanded();

  const showCollapseExpand = row.subRows.length > 0;

  const existingLinkages = row.original.existingLinkages ?? [];

  const toEditMode = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    workPackageId: string
  ) => {
    e.stopPropagation();

    const url = generateUrl({
      route: routes.WORKSECTION_EXPANDED,
      projectId,
      viewMode: 'edit',
      workPackageId,
    });

    history.push(url);
  };

  const toolTipText = existingLinkages
    .map((linkage) => `${linkage.code} ${linkage.name}`)
    .join(', ');

  const toggleSelection = row.getToggleSelectedHandler();

  const leafRows = row.getLeafRows();

  const isChildSelected = leafRows.some((childRow) => childRow.getIsSelected());

  return (
    <FlexDiv>
      {showCheckBoxes ? (
        <CheckboxComponent
          checked={row.getIsSelected()}
          onChange={toggleSelection}
          indeterminate={isChildSelected}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              e.stopPropagation();
              e.preventDefault();
              toggleSelection(e);
            }
          }}
          disabled={row.original.deSelectDisabled}
        />
      ) : null}
      <CellDiv depth={row.depth}>
        {showCollapseExpand ? (
          <ExpandCollapseButton
            icon={isExpanded ? IconCollapseMinus : IconExpandPlus}
            customSize="12px"
            onClick={() => rowToggleExpand()}
          />
        ) : (
          <EmptyDiv />
        )}
        <HiddenOverflowDiv
          isHovered={rowContext.isHovered}
          onMouseEnter={() => rowContext.setIsHovered(true)}
          onMouseLeave={() => rowContext.setIsHovered(false)}
        >
          {value}
        </HiddenOverflowDiv>
        {existingLinkages.length > 0 ? (
          <ExistingIcon
            icon={OrangeExclamationMark}
            ref={measureRef}
            onClick={(e) => toEditMode(e, existingLinkages[0].id)}
            onMouseLeave={() => {
              if (isMouseHover === true) {
                tooltipContext.setToolTipProps(undefined);
              }
            }}
            onMouseEnter={() => {
              tooltipContext.setToolTipProps({
                parentMeasures: {
                  top,
                  left,
                  height,
                  width,
                  right,
                  bottom,
                  virtualRowStart,
                },
                text: toolTipText,
                place: 'top',
              });
              setIsMouseHover(true);
            }}
          />
        ) : null}
      </CellDiv>
    </FlexDiv>
  );
};

const CellDiv = styled.div<{ depth: number }>`
  padding-left: ${({ depth }) => `${depth}rem`};
  width: 100%;
  display: flex;
  align-items: center;
`;

const HiddenOverflowDiv = styled.div<{ isHovered: boolean }>`
  align-items: center;

  flex-shrink: 1;

  white-space: ${({ isHovered }) => (isHovered ? 'unset' : 'nowrap')};
  text-overflow: ellipsis;

  overflow: ${({ isHovered }) => (isHovered ? 'visible' : 'hidden')};
`;

const EmptyDiv = styled.div`
  margin: 0 4px;
  height: 12px;
  width: 12px;
  display: inline-block;
`;

const ExistingIcon = styled(IconButton)`
  margin-right: ${(props) => props.theme.margin[8]};
  margin-left: auto;
`;

const OrangeExclamationMark = styled(IconExclamationMark)`
  fill: ${(props) => props.theme.color.orange};
`;

const FlexDiv = styled.div`
  display: flex;
  flex-direction: row;
`;
