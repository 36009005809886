import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';

import { ActualCost } from '../../../../../store/reducers/actualCost';
import {
  ArrivalRow,
  getArrivalRowDeleteRequest,
  isAnyDeleteRequestLoading,
} from '../../../../../store/reducers/arrivalRow';
import {
  InvoiceHeader,
  selectUnHandledInvoiceHeadersByOrderId,
} from '../../../../../store/reducers/invoiceHeader';

import {
  assignArrivalRowsToInvoice,
  deleteMultipleArrivalRows,
} from '../../../../../store/actions';
import { fetchInvoiceHeadersForOrder } from '../../../../../store/actions/invoiceHeader';
import { selectArrivalRows } from '../../../../../store/actions/ui';

import { ID } from '../../../../../types/general';

import { useArrivalRowSelection } from '../../../../../hooks/ui';
import useTxt from '../../../../../hooks/useTxt';

import {
  PrimaryButtonSmall,
  IconTextButton,
} from '../../../../../components/Buttons';
import { Spinner } from '../../../../../components/Loading';
import RemoteData from '../../../../../components/RemoteData';
import { Select } from '../../../../../components/Selects';

import * as big from '../../../../../utils/big';
import { isLoading } from '../../../../../utils/remoteData';

import { IconDelete } from '../../../../../assets/svg';

import { AssignmentBoxContainer } from './Components';

type Props = {
  arrivalRows: ArrivalRow[];
  invoiceHeaders: InvoiceHeader[];
  actualCosts: ActualCost[];
  defaultSelection: AssignmentSelection;
  onAssignPurchaseInvoice: (id: ID) => void;
  onAssignActualCost: (id: ID) => void;
  orderId: string;
  allowedUser: boolean;
  selectedUnAssignedArrivalRowIds: string[];
};

export type AssignmentSelection =
  | {
      kind: 'invoice' | 'cost';
      id: string;
    }
  | { kind: 'unassigned' };

// Serialize selection to a string
const serializeAssignmentSelection = (
  selection: AssignmentSelection | undefined
): string => {
  if (selection?.kind === 'invoice' || selection?.kind === 'cost') {
    return `${selection.kind}-${selection.id}`;
  }

  return 'unassigned';
};

// Deserialize a string to a selection
const deserializeAssignmentSelection = (
  selectionAsString: string
): AssignmentSelection => {
  const { 0: kind, 1: id } = selectionAsString.split('-');

  if ((kind === 'invoice' || kind === 'cost') && id) {
    return { kind, id };
  }

  return { kind: 'unassigned' };
};

const AssignmentTool = ({
  arrivalRows,
  invoiceHeaders,
  defaultSelection,
  actualCosts,
  onAssignActualCost,
  onAssignPurchaseInvoice,
  orderId,
  allowedUser,
  selectedUnAssignedArrivalRowIds,
}: Props) => {
  const dispatch = useDispatch();

  const fetchData = fetchInvoiceHeadersForOrder(orderId);

  const remoteInvoiceHeaders = useSelector(
    selectUnHandledInvoiceHeadersByOrderId(orderId)
  );

  const [, setSelectedArrivalRows] = useArrivalRowSelection();
  const [submitting, setSubmitting] = useState(false);

  const [selectedAssignment, setSelectedAssignment] = useState<
    AssignmentSelection | undefined
  >(defaultSelection);

  const assignText = useTxt('order.receiveMode.assignmentTool.assign');
  const rowsText = useTxt('order.receiveMode.assignmentTool.rows');
  const amountText = useTxt('order.receiveMode.assignmentTool.amount');
  const unAssignedText = useTxt('order.receiveMode.assignmentTool.unAssigned');

  const toActualCostText = useTxt(
    'order.receiveMode.assignmentTool.toActualCost'
  );
  const toInvoiceText = useTxt('order.receiveMode.assignmentTool.toInvoice');

  const deleteMultipleArrivalRowsText = useTxt(
    'order.receiveMode.deleteMultipleArrivalRows'
  );

  const multipleArrivalRowDeleteRequestId = 'unassigned-arrival-rows';

  const showSpinner = isLoading(
    useSelector(getArrivalRowDeleteRequest(multipleArrivalRowDeleteRequestId))
  );

  const isDeleteRequestsPending = useSelector(isAnyDeleteRequestLoading());

  const onDeleteMultipleArrivalRows = () => {
    if (selectedUnAssignedArrivalRowIds.length > 0) {
      dispatch(
        selectArrivalRows(
          selectedUnAssignedArrivalRowIds.reduce(
            (map, id) => ({ ...map, [id]: false }),
            {}
          )
        )
      );

      dispatch(
        deleteMultipleArrivalRows(
          multipleArrivalRowDeleteRequestId,
          selectedUnAssignedArrivalRowIds
        )
      );
    }
  };

  const onSelectionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedAssignment(deserializeAssignmentSelection(e.target.value));
  };

  const total = big.sum(
    ...arrivalRows.map((row) => row.quantity.mul(row.unitPrice))
  );

  const memoDefaultValue = useMemo(() => defaultSelection, [defaultSelection]);
  // This effect makes sure the selection actually exists in the lists,
  // as it may update in one of the parent components.
  React.useEffect(() => {
    if (
      (selectedAssignment?.kind === 'invoice' &&
        !invoiceHeaders.some((e) => e.id === selectedAssignment.id)) ||
      (selectedAssignment?.kind === 'cost' &&
        !actualCosts.some((e) => e.id === selectedAssignment.id))
    ) {
      setSelectedAssignment(defaultSelection);
    }
  }, [defaultSelection, invoiceHeaders, actualCosts, selectedAssignment]);

  // Use memoized default value to prevent rerender loop
  React.useEffect(() => {
    setSelectedAssignment(memoDefaultValue);
  }, [memoDefaultValue]);

  // If user enters site via direct url the data is not fetched yet and
  // unassigned is selected even if there is data. This effect makes sure
  // default assignmentSelection is used when the request ends.

  const onSubmit = () => {
    const arrivalRowIds = arrivalRows.map((row) => row.id);
    setSubmitting(true);

    if (selectedAssignment?.kind === 'invoice') {
      onAssignPurchaseInvoice(selectedAssignment.id);
    } else if (selectedAssignment?.kind === 'cost') {
      onAssignActualCost(selectedAssignment.id);
    }

    dispatch(
      assignArrivalRowsToInvoice({
        arrivalRowIds,
        purchaseInvoiceHeaderId:
          selectedAssignment?.kind === 'invoice' ? selectedAssignment.id : null,
        actualCostId:
          selectedAssignment?.kind === 'cost' ? selectedAssignment.id : null,
      })
    );

    setSelectedArrivalRows(arrivalRowIds, false);
    setSubmitting(false);
  };

  const multipleArrivalRowsSelected =
    selectedUnAssignedArrivalRowIds.length > 1 || showSpinner;

  return (
    <AssignmentBoxContainer>
      <StyledDetailsContainer>
        {assignText} {arrivalRows.length} {rowsText}{' '}
        <strong>
          {amountText} {big.priceFormat(total)}
        </strong>
      </StyledDetailsContainer>
      <StyledSelectsContainer>
        <RemoteData
          loadingElement={<span>...</span>}
          data={remoteInvoiceHeaders}
          fetchData={fetchData}
        >
          {(invoices) => (
            <Select
              disabled={submitting || arrivalRows.length === 0}
              onChange={onSelectionChange}
              value={serializeAssignmentSelection(selectedAssignment)}
            >
              <option value="unassigned">{unAssignedText}</option>
              {invoices.length > 0 ? (
                <optgroup label={toInvoiceText}>
                  {invoiceHeaders.map((header) => (
                    <option
                      value={serializeAssignmentSelection({
                        kind: 'invoice',
                        id: header.id,
                      })}
                      key={`select-invoice-option-${header.id}`}
                    >
                      {header.vendorInvoiceNo} ({big.priceFormat(header.amount)}
                      )
                    </option>
                  ))}
                </optgroup>
              ) : null}
              {actualCosts.length > 0 ? (
                <optgroup label={toActualCostText}>
                  {actualCosts.map((cost) => (
                    <option
                      value={serializeAssignmentSelection({
                        kind: 'cost',
                        id: cost.id,
                      })}
                      key={`select-invoice-option-${cost.id}`}
                    >
                      {cost.documentNumber} ({big.priceFormat(cost.amount)})
                    </option>
                  ))}
                </optgroup>
              ) : null}
            </Select>
          )}
        </RemoteData>
      </StyledSelectsContainer>
      <StyledButtonsContainer>
        {multipleArrivalRowsSelected ? (
          <DeleteButton
            icon={WhiteDeleteIcon}
            data-testid={`assignment-tool-delete-multiple-arrival-rows-${multipleArrivalRowDeleteRequestId}`}
            disabled={allowedUser || isDeleteRequestsPending}
            onClick={() => onDeleteMultipleArrivalRows()}
          >
            {showSpinner ? <Spinner size="1rem" light /> : ''}
            {!showSpinner ? deleteMultipleArrivalRowsText : ''}
          </DeleteButton>
        ) : null}
        <PrimaryButtonSmall
          onClick={onSubmit}
          disabled={submitting || arrivalRows.length === 0 || allowedUser}
        >
          {submitting ? <Spinner size="1rem" light /> : assignText}
        </PrimaryButtonSmall>
      </StyledButtonsContainer>
    </AssignmentBoxContainer>
  );
};

const StyledDetailsContainer = styled.div`
  flex: 1 1 50%;
`;

const StyledSelectsContainer = styled.div`
  flex: 1 1 50%;
  text-align: right;
`;

const StyledButtonsContainer = styled.div`
  margin-left: 1rem;
  display: flex;
  white-space: nowrap;
`;

const DeleteButton = styled(IconTextButton)`
  margin: 0 1rem 0 0;

  border-radius: ${(props) => props.theme.margin[24]};
  /* Transparent border to match SecondaryButton */
  border: 1px solid rgba(0, 0, 0, 0);

  padding: ${(props) => props.theme.margin[4]};

  height: 1.5rem;
  width: 12rem;
  min-width: 6.5rem; /* 104px */

  background: ${(props) => props.theme.color.pitch};

  text-align: center;
  font-size: ${(props) => props.theme.fontSize.small};
  font-weight: 500;
  color: ${(props) => props.theme.color.white};

  :focus {
    outline-color: ${(props) => props.theme.color.inputBorder};
  }

  :disabled {
    background: ${(props) => props.theme.color.buttonPrimaryDisabled};
    color: ${(props) => props.theme.color.white};
    cursor: not-allowed;
  }

  /* stylelint-disable selector-max-type -- svgs inside the button are most easily
  styled with type selectors */
  /* stylelint-disable selector-max-class -- svgs inside the button are most easily
  styled with type selectors */
  svg.button-icon path {
    fill: ${(props) => props.theme.color.white};
  }

  :disabled svg.button-icon path {
    fill: ${(props) => props.theme.color.white};
  }
`;

const WhiteDeleteIcon = styled(IconDelete)`
  margin-left: ${(props) => props.theme.margin[4]};
`;

export default AssignmentTool;
