import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getWorkSectionRows } from '../../store/reducers/workPackage';

import * as Actions from '../../store/actions';

import Order from './components/Order';
import RemoteData from '../../components/RemoteData';
import { Table } from '../../components/Table';

import TableHeaderRow from './SectionTableHeader';
import { Toggle } from '../../context/toggleContext';

type SectionTableProps = {
  workPackageId: string;
  projectId: string;
};

const SectionTable = ({ workPackageId, projectId }: SectionTableProps) => {
  const remoteRows = useSelector(
    getWorkSectionRows({ workPackageId, projectId })
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(Actions.fetchOrderRowsForWorkPackage(workPackageId));
  }, [workPackageId, dispatch]);

  return (
    <Table>
      <Toggle>
        <thead>
          <TableHeaderRow hasOpenAllButton />
        </thead>

        <RemoteData
          data={remoteRows}
          fetchData={Actions.fetchWorkSectionRows({ projectId, workPackageId })}
          loadingElement={<tbody />}
        >
          {(rows) => (
            <tbody>
              {rows.map(({ order }, index) => (
                <Order
                  hasHeaderAfter={index !== rows.length - 1}
                  key={order.id}
                  order={order}
                />
              ))}
            </tbody>
          )}
        </RemoteData>
      </Toggle>
    </Table>
  );
};

export default SectionTable;
