import Big from 'big.js';
import { sortBy, groupBy } from 'lodash';
import { Reducer } from 'redux';

import { ID } from '../../types/general';

import {
  assertActionPayloadIsNotApiUpdatedEntities,
  Selector,
  isUpdatedEntitiesActionType,
} from './utils';
import * as api from '../../utils/api';
import * as big from '../../utils/big';
import { isDefined } from '../../utils/general';
import normalizeBy from '../../utils/normalizeBy';
import * as remoteData from '../../utils/remoteData';

import { ActionTypes } from '../actionTypes';
import { getArrivalsByOrderId, getArrivals } from './arrival';

import { AppState } from '.';

export type ArrivalRow = {
  id: ID;
  arrivalId: ID;
  orderRowId: ID | null;
  orderId: ID | null;
  purchaseInvoiceHeaderId: ID | null;
  actualCostId: ID | null;
  quantity: Big;
  unitPrice: Big;
  createdAt: Date;
  updatedAt: Date;
  isDeleted: boolean;
  vatCodeId: string | null;
  accountId: string | null;
  description: string | null;
};

type Err = api.BackendError | undefined;

export type ArrivalRowState = {
  requests: Partial<Record<string, remoteData.RemoteData<undefined, Err>>>;
  deleteRequests: Partial<
    Record<string, remoteData.RemoteData<undefined, Err>>
  >;
  analysisRowRequests: Partial<
    Record<string, remoteData.RemoteData<undefined, Err>>
  >;
  data: Partial<Record<string, ArrivalRow>>;
};

const initialState: ArrivalRowState = {
  requests: {},
  deleteRequests: {},
  analysisRowRequests: {},
  data: {},
};

const arrivalRowReducer: Reducer<ArrivalRowState, ActionTypes> = (
  state = initialState,
  action
): ArrivalRowState => {
  switch (action.type) {
    case 'DELETE_ARRIVAL_ROW_STARTED': {
      const { arrivalRowId } = action.payload;

      return {
        ...state,
        deleteRequests: {
          ...state.deleteRequests,
          [arrivalRowId]: remoteData.loading,
        },
      };
    }
    case 'DELETE_ARRIVAL_ROW_FAILURE': {
      const { arrivalRowId } = action.payload;

      return {
        ...state,
        deleteRequests: {
          ...state.deleteRequests,
          [arrivalRowId]: remoteData.fail(action.payload.error),
        },
      };
    }
    case 'DELETE_ARRIVAL_ROW_SUCCESS': {
      const { arrivalRows: updatedArrivalRows = [], arrivalRowId } =
        action.payload;

      if (updatedArrivalRows.length === 0) {
        return state;
      }

      const data = { ...state.data };

      updatedArrivalRows.forEach((arrivalRow) => {
        const { id, isDeleted } = arrivalRow;

        if (isDeleted) {
          delete data[id];
        } else {
          data[id] = arrivalRow;
        }
      });

      return {
        ...state,
        deleteRequests: {
          ...state.deleteRequests,
          [arrivalRowId]: remoteData.succeed(undefined),
        },
        data,
      };
    }
    case 'GET_ARRIVAL_ROWS_STARTED': {
      const { orderId } = action.payload;
      const requests = { ...state.requests, [orderId]: remoteData.loading };

      return {
        ...state,
        requests,
      };
    }
    case 'DELETE_MULTIPLE_ARRIVAL_ROWS_STARTED': {
      const { arrivalRowIds, requestId } = action.payload;

      const idDeleteRequests = arrivalRowIds.reduce((prev, arrivalRowId) => {
        return {
          ...prev,
          [arrivalRowId]: remoteData.loading,
        };
      }, {});

      return {
        ...state,
        deleteRequests: {
          ...state.deleteRequests,
          ...idDeleteRequests,
          [requestId]: remoteData.loading,
        },
      };
    }
    case 'DELETE_MULTIPLE_ARRIVAL_ROWS_FAILURE': {
      const { arrivalRowIds, requestId } = action.payload;

      const idDeleteRequests = arrivalRowIds.reduce((prev, arrivalRowId) => {
        return {
          ...prev,
          [arrivalRowId]: remoteData.fail(action.payload.error),
        };
      }, {});

      return {
        ...state,
        deleteRequests: {
          ...state.deleteRequests,
          ...idDeleteRequests,
          [requestId]: remoteData.fail(action.payload.error),
        },
      };
    }
    case 'DELETE_MULTIPLE_ARRIVAL_ROWS_SUCCESS': {
      const {
        arrivalRowIds,
        requestId,
        arrivalRows: updatedArrivalRows = [],
      } = action.payload;

      if (updatedArrivalRows.length === 0) {
        return state;
      }

      const data = { ...state.data };

      updatedArrivalRows.forEach((arrivalRow) => {
        const { id, isDeleted } = arrivalRow;

        if (isDeleted) {
          delete data[id];
        } else {
          data[id] = arrivalRow;
        }
      });

      const idDeleteRequests = arrivalRowIds.reduce((prev, arrivalRowId) => {
        return {
          ...prev,
          [arrivalRowId]: remoteData.succeed(undefined),
        };
      }, {});

      return {
        ...state,
        deleteRequests: {
          ...state.deleteRequests,
          ...idDeleteRequests,
          [requestId]: remoteData.succeed(undefined),
        },
        data,
      };
    }
    case 'GET_ARRIVAL_ROWS_FAILURE': {
      const { orderId, error } = action.payload;
      const requests = { ...state.requests, [orderId]: remoteData.fail(error) };

      return { ...state, requests };
    }
    case 'GET_ARRIVAL_ROWS_SUCCESS': {
      const { orderId, arrivalRows } = action.payload;

      const requests = {
        ...state.requests,
        [orderId]: remoteData.succeed(undefined),
      };

      const data = {
        ...state.data,
        ...normalizeBy('id', arrivalRows),
      };

      return {
        ...state,
        requests,
        data,
      };
    }
    case 'GET_ARRIVAL_ROWS_FOR_ANALYSIS_ROW_STARTED': {
      const { analysisRowId } = action.payload;

      const analysisRowRequests = {
        ...state.analysisRowRequests,
        [analysisRowId]: remoteData.loading,
      };

      return {
        ...state,
        analysisRowRequests,
      };
    }
    case 'GET_ARRIVAL_ROWS_FOR_ANALYSIS_ROW_FAILURE': {
      const { analysisRowId, error } = action.payload;

      const analysisRowRequests = {
        ...state.analysisRowRequests,
        [analysisRowId]: remoteData.fail(error),
      };

      return { ...state, analysisRowRequests };
    }
    case 'GET_ARRIVAL_ROWS_FOR_ANALYSIS_ROW_SUCCESS': {
      const { analysisRowId, arrivalRows } = action.payload;

      const analysisRowRequests = {
        ...state.analysisRowRequests,
        [analysisRowId]: remoteData.succeed(undefined),
      };

      const data = {
        ...state.data,
        ...normalizeBy('id', arrivalRows),
      };

      return {
        ...state,
        analysisRowRequests,
        data,
      };
    }
  }

  if (isUpdatedEntitiesActionType(action)) {
    const { arrivalRows: updatedArrivalRows = [] } = action.payload;

    if (updatedArrivalRows.length === 0) {
      return state;
    }

    const data = { ...state.data };

    updatedArrivalRows.forEach((arrivalRow) => {
      const { id, isDeleted } = arrivalRow;

      if (isDeleted) {
        delete data[id];
      } else {
        data[id] = arrivalRow;
      }
    });

    return {
      ...state,
      data,
    };
  }

  assertActionPayloadIsNotApiUpdatedEntities(action);

  return state;
};

export const selectOrderArrivalRowsRequests =
  (orderId: string) =>
  ({
    arrivalRows: {
      requests: { [orderId]: requestState = remoteData.notAsked },
    },
  }: AppState) =>
    requestState;

export function getArrivalRowDeleteRequest(
  requestId: string
): Selector<remoteData.RemoteAction> {
  return ({
    arrivalRows: {
      deleteRequests: { [requestId]: request },
    },
  }) => request ?? remoteData.notAsked;
}

export function isAnyDeleteRequestLoading(): Selector<boolean> {
  return ({ arrivalRows: { deleteRequests } }) => {
    const requestStates = Object.values(deleteRequests);

    return requestStates.some((state) => state && remoteData.isLoading(state));
  };
}

export const getArrivalRows = ({ arrivalRows: { data } }: AppState) =>
  Object.values(data).filter(isDefined);

export const getArrivalRowById =
  (id: string) =>
  ({
    arrivalRows: {
      data: { [id]: arrivalRow },
    },
  }: AppState) =>
    arrivalRow;

export default arrivalRowReducer;

export const getArrivalRowsByOrderId =
  (orderId: string) =>
  (appState: AppState): ArrivalRow[] => {
    const orderArrivals = getArrivalsByOrderId(orderId)(appState);

    return orderArrivals.flatMap(({ arrivalRowIds = [] }) =>
      arrivalRowIds
        .map((id) => getArrivalRowById(id)(appState))
        .filter(isDefined)
    );
  };

export const getArrivalRowsByOrderRowId =
  (orderRowId: string) =>
  (appState: AppState): ArrivalRow[] => {
    const arrivalRows = getArrivalRows(appState).filter(
      (row) => row.orderRowId === orderRowId
    );

    return arrivalRows;
  };

export const getUnassignedArrivalRowsByOrderId =
  (orderId: string) =>
  (appState: AppState): ArrivalRow[] => {
    const orderArrivalRows = getArrivalRowsByOrderId(orderId)(appState);

    const unassignedOrderArrivalRows = orderArrivalRows.filter(
      (row) => row.purchaseInvoiceHeaderId === null && row.actualCostId === null
    );
    const allArrivals = getArrivals(appState);

    const sortedArrivalRows = sortBy(unassignedOrderArrivalRows, [
      ({ arrivalId }) => -allArrivals[arrivalId].rowNumber,
      ({ id }) => Number(id),
    ]);

    return sortedArrivalRows;
  };

export const getInvoiceAssignedArrivalRowsByOrderId =
  (orderId: string) =>
  (appState: AppState): Record<string, ArrivalRow[]> => {
    const orderArrivalRows = getArrivalRowsByOrderId(orderId)(appState);

    return groupBy(
      orderArrivalRows.filter((row) => row.purchaseInvoiceHeaderId !== null),
      'purchaseInvoiceHeaderId'
    );
  };

export const getInvoiceIdsAssignedArrivalRowsByOrderId =
  (orderId: string) =>
  (appState: AppState): Record<string, string[]> => {
    const orderArrivalRows = getArrivalRowsByOrderId(orderId)(appState).reduce(
      (prev, row) => {
        if (row.purchaseInvoiceHeaderId) {
          const invoiceId = row.purchaseInvoiceHeaderId;
          const currentArrivalRowIds = prev[invoiceId] ?? [];

          return {
            ...prev,
            [invoiceId]: [...currentArrivalRowIds, row.id],
          };
        }

        return prev;
      },
      {} as Record<string, string[]>
    );

    return orderArrivalRows;
  };

export const selectHasArrivalRowsForInvoiceId =
  (invoiceId: string): Selector<boolean> =>
  ({ arrivalRows: { data } }) =>
    Object.values(data).find(
      (row) => row !== undefined && row.purchaseInvoiceHeaderId === invoiceId
    ) !== undefined;

export const getInvoiceAssignedArrivalRowsByInvoiceId =
  (invoiceId: string) =>
  (appState: AppState): ArrivalRow[] => {
    const arrivalRows = getArrivalRows(appState);

    const invoiceAssignedArrivalRows = arrivalRows.filter(
      (row) => row.purchaseInvoiceHeaderId === invoiceId
    );

    return invoiceAssignedArrivalRows;
  };

export const getInvoiceAssignedArrivalRowIdsByInvoiceId =
  (invoiceId: string) =>
  (appState: AppState): string[] => {
    const arrivalRows = getArrivalRows(appState);

    return arrivalRows
      .filter((row) => row.purchaseInvoiceHeaderId === invoiceId)
      .map((row) => row.id);
  };

export const getInvoiceAssignedArrivalRowIdsByInvoiceIdForSameOrder =
  (invoiceId: string, orderId: string) =>
  (appState: AppState): string[] => {
    const arrivalRows = getArrivalRows(appState);

    return arrivalRows
      .filter(
        (row) =>
          row.purchaseInvoiceHeaderId === invoiceId && row.orderId === orderId
      )
      .map((row) => row.id);
  };

export const getInvoiceAssignedArrivalRowIdsByInvoiceIdForOtherOrders =
  (invoiceId: string, orderId: string) =>
  (appState: AppState): Partial<Record<string, ArrivalRow[]>> => {
    const arrivalRows = getArrivalRows(appState);

    const filteredArrivalRows = arrivalRows.filter(
      (row) =>
        row.purchaseInvoiceHeaderId === invoiceId && row.orderId !== orderId
    );

    const groupedArrivalRows = groupBy(filteredArrivalRows, 'orderId');

    return groupedArrivalRows;
  };

export const getAssignedArrivalRowsTotal =
  (invoiceId: string) =>
  (appState: AppState): string => {
    const arrivalRows =
      getInvoiceAssignedArrivalRowsByInvoiceId(invoiceId)(appState);

    const sum = big.sum(
      ...arrivalRows.map((row) => row.quantity.mul(row.unitPrice))
    );

    return big.priceFormat(sum);
  };

export const getActualCostAssignedArrivalRowsByOrderId =
  (orderId: string) =>
  (appState: AppState): Record<string, ArrivalRow[]> => {
    const orderArrivalRows = getArrivalRowsByOrderId(orderId)(appState);

    const actualCostAssignedArrivalRows = groupBy(
      orderArrivalRows.filter((row) => row.actualCostId !== null),
      'actualCostId'
    );

    return actualCostAssignedArrivalRows;
  };

export const getActualCostIdsAssignedArrivalRowsByOrderId =
  (orderId: string) =>
  (appState: AppState): Record<string, string[]> => {
    const orderArrivalRows = getArrivalRowsByOrderId(orderId)(appState).reduce(
      (prev, row) => {
        if (row.actualCostId) {
          const { actualCostId } = row;
          const currentArrivalRowIds = prev[actualCostId] ?? [];

          return {
            ...prev,
            [actualCostId]: [...currentArrivalRowIds, row.id],
          };
        }

        return prev;
      },
      {} as Record<string, string[]>
    );

    return orderArrivalRows;
  };

type ActualCostParams = {
  actualCostId: string;
  orderId: string;
};

export const getActualCostAssignedArrivalRowsByActualCostId =
  ({ actualCostId, orderId }: ActualCostParams) =>
  (appState: AppState) => {
    const allArrivals = getArrivals(appState);

    const actualCostAssignedArrivalRows =
      getActualCostAssignedArrivalRowsByOrderId(orderId)(appState);

    const arrivalRowsForThisActualCost =
      actualCostAssignedArrivalRows[actualCostId] ?? [];

    return sortBy(arrivalRowsForThisActualCost, [
      (arrivalRow) => -Number(allArrivals[arrivalRow.arrivalId].rowNumber),
      (arrivalRow) => Number(arrivalRow.id),
    ]);
  };

export const getActualCostAssignedArrivalRowIdsByActualCostId =
  ({ actualCostId, orderId }: ActualCostParams) =>
  (appState: AppState) => {
    const arrivalRows = getActualCostAssignedArrivalRowsByActualCostId({
      actualCostId,
      orderId,
    })(appState);

    return arrivalRows.map((row) => row.id);
  };

export const getAssignedArrivalRowsForActualCostTotal =
  ({ actualCostId, orderId }: ActualCostParams) =>
  (appState: AppState): string => {
    const arrivalRows = getActualCostAssignedArrivalRowsByActualCostId({
      actualCostId,
      orderId,
    })(appState);

    const sum = big.sum(
      ...arrivalRows.map((row) => row.quantity.mul(row.unitPrice))
    );

    return big.priceFormat(sum);
  };
