import React from 'react';

import Big from 'big.js';
import styled from 'styled-components';

import { APIWorkPackage } from '@customtypes/api';

import useTxt from '../../../hooks/useTxt';

import {
  BigPriceValue,
  DeltaBigCostValue,
  BigPercentageValue,
} from '../../../components/BigValue';
import Txt from '../../../components/Txt';

interface WorkPackageSummaryProps {
  workPackage: APIWorkPackage;
  poc: Big;
  isScheduleInUse: boolean;
}

const WorkPackageSummary = ({
  workPackage,
  poc,
  isScheduleInUse,
}: WorkPackageSummaryProps) => {
  const {
    targetTotal,
    predictionTotal,
    predictionChangeFromLatest,
    contractTotal,
    changeOrdersTotal,
    reservesTotal,
    receivedTotal,
  } = workPackage;

  const textIdHead = 'worksection.table.header.';

  const titleText = useTxt('worksection.graph.title');

  return (
    <SummaryListContainer>
      <HeaderText>{titleText}</HeaderText>
      <StyledListItem>
        <Txt id={`${textIdHead}${'targetTotal'}` as const} />
        <BigPriceValue value={targetTotal} decimals={0} />
      </StyledListItem>
      <StyledParentListItem>
        <Txt id={`${textIdHead}${'predictionTotal'}` as const} />
        <section className="prediction_change">
          <BigPriceValue value={predictionTotal} decimals={0} />
          <DeltaBigCostValue value={predictionChangeFromLatest} decimals={0} />
        </section>

        <ul>
          <StyledListItem>
            <Txt id={`${textIdHead}${'contractTotal'}` as const} />
            <BigPriceValue value={contractTotal} decimals={0} />
          </StyledListItem>
          <StyledListItem>
            <Txt id={`${textIdHead}${'changeOrdersTotal'}` as const} />
            <BigPriceValue value={changeOrdersTotal} decimals={0} />
          </StyledListItem>
          <StyledListItem>
            <Txt id={`${textIdHead}${'reservesTotal'}` as const} />
            <BigPriceValue value={reservesTotal} decimals={0} />
          </StyledListItem>
        </ul>
      </StyledParentListItem>

      <StyledListItem>
        <Txt id={`${textIdHead}${'receivedTotal'}` as const} />
        <BigPriceValue value={receivedTotal} decimals={0} />
      </StyledListItem>
      <StyledListItem>
        <Txt
          id={
            `${textIdHead}${
              isScheduleInUse
                ? 'percentageOfCompletion'
                : 'percentageOfCompletion.eoMonth'
            }` as const
          }
        />
        <BigPercentageValue value={poc} decimals={0} />
      </StyledListItem>
    </SummaryListContainer>
  );
};

const SummaryListContainer = styled.ul`
  margin: 0;

  padding: 1.75rem 0 0 0;

  min-width: 20rem;

  display: flex;
  flex-direction: column;

  list-style: none;

  /* stylelint-disable selector-max-class -- TODO  */
  /* stylelint-disable max-nesting-depth  -- TODO */
  /* stylelint-disable selector-max-type -- TODO  */

  span {
    font-weight: bold;
  }

  ul {
    margin: 0.5rem 0 0.25rem 1rem;
  }
`;

const HeaderText = styled.header`
  padding-bottom: ${({ theme }) => theme.margin[32]};
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSize.h2};
`;

const StyledListItem = styled.li`
  margin-bottom: 0.5rem;
  display: grid;
  grid-template-columns: 12rem auto;
`;

const StyledParentListItem = styled.li`
  display: grid;
  grid-template-columns: 12rem auto;

  section.prediction_change {
    display: flex;
    justify-content: left;
    gap: 2rem;
  }

  ul {
    padding: 0;

    span {
      margin-left: -1rem;
    }
  }
`;

export default WorkPackageSummary;
