import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { AppState } from '../store/reducers';

import { selectArrivalRows } from '../store/actions/ui';

import { ID, SelectedArrivalRowsState } from '../types/general';

import { calculateElapsedTimeFrom, formatElapsedTime } from '../utils/function';

import useTxt from './useTxt';

const selectSelectedArrivalRows = (state: AppState) =>
  state.ui.selectedArrivalRows;

export const useArrivalRowSelection = (): [
  SelectedArrivalRowsState,
  (rowIds: ID[], selected: boolean) => void,
] => {
  const dispatch = useDispatch();

  return [
    useSelector(selectSelectedArrivalRows, shallowEqual),
    (rowIds: ID[], selected: boolean) =>
      dispatch(
        selectArrivalRows(
          rowIds.reduce((map, id) => ({ ...map, [id]: selected }), {})
        )
      ),
  ];
};

export const useElapsedTime = (timestamp: Date): string => {
  const justNow = useTxt('navigation.notifications.justNow');
  const ago = useTxt('navigation.notifications.ago');
  const minutes = useTxt('navigation.notifications.minutes');
  const hours = useTxt('navigation.notifications.hours');

  const time = formatElapsedTime(calculateElapsedTimeFrom(timestamp));
  const hrs = time.hours > 1 ? hours : 'h';
  const mins = time.minutes > 1 ? minutes : 'min';

  const formattedhours = `${time.hours !== 0 ? time.hours + hrs : ''}`;
  const formattedMins = `${time.minutes !== 0 ? time.minutes + mins : ''}`;

  const text = formattedhours === '' && formattedMins === '' ? justNow : ago;

  return `${formattedhours} ${formattedMins} ${text}`;
};
