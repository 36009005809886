import { Form as FormikForm } from 'formik';
import styled from 'styled-components';

import { ButtonGroup } from '../../../../../../components/Buttons';

export const CenteredButtonGroup = styled(ButtonGroup)`
  margin-top: ${({ theme }) => `${theme.margin[32]}`};
  justify-content: end;
`;

export const StyledFormikForm = styled(FormikForm)`
  margin-top: ${({ theme }) => `${theme.margin[32]}`};
`;

export const Form = styled.form`
  margin-top: ${({ theme }) => `${theme.margin[32]}`};
`;

export const AmountArea = styled.div`
  margin-top: ${({ theme }) => `${theme.margin[16]}`};

  padding: ${({ theme }) => `${theme.margin[16]}`};

  display: flex;
  flex-direction: column;

  background-color: ${({ theme }) => theme.color.primaryRowBackground};
`;

export const DetailsSpan = styled.span`
  margin-bottom: ${({ theme }) => `${theme.margin[4]}`};
  font-size: ${({ theme }) => theme.fontSize.small};
  text-align: right;
`;
