import React from 'react';

import { getAnalysisRowsForGroup } from '../../../store/reducers/analysis/row';

import * as Actions from '../../../store/actions';

import useRemoteData from '../../../hooks/useRemoteData';

import { Table } from '../../../components/Table';

import { routes, useParams } from '../../../routes';

import { AnalysisRow } from './AnalysisRow';
import { Col } from './AnalysisTable';
import { Thead, TxtTh, SortableTh } from './Th';

type AnalysisProps = {
  analysisGroupId: string;
  dynamicColumnHeaderNames: string[];
  dynamicColumnAttributeIds: string[];
  isOrderRowField: boolean;
  isPaymentProgramRowField: boolean;
  isTargetRowField: boolean;
};

export const Analysis = ({
  analysisGroupId,
  dynamicColumnHeaderNames,
  dynamicColumnAttributeIds,
  isOrderRowField,
  isPaymentProgramRowField,
  isTargetRowField,
}: AnalysisProps) => {
  const { projectId } = useParams(routes.ANALYSIS);

  const analysisRows =
    useRemoteData(
      getAnalysisRowsForGroup(projectId, analysisGroupId),
      Actions.requestAnalysisRows({ projectId })
    ) ?? [];

  const mappedAttributeColumns = dynamicColumnAttributeIds.map((id, i) => {
    return { id, name: dynamicColumnHeaderNames[i] };
  });

  return (
    <Table>
      <colgroup>
        <Col name="empty" />
        <Col name="empty" />
        <Col name="value" />
        <Col name="paymentProgramRowsAmount" />
        <Col name="orderRowsAmount" />
        <Col name="targetRowsAmount" />
        <Col name="arrivalsAmount" />
        <Col />
      </colgroup>
      <Thead>
        <tr>
          <SortableTh
            name="code"
            justifyContent="flex-end"
            analysisGroupId={analysisGroupId}
            colSpan={2}
          />
          <SortableTh
            name="value"
            justifyContent="flex-start"
            analysisGroupId={analysisGroupId}
          />
          <SortableTh
            name="paymentProgramRowsAmount"
            justifyContent="flex-end"
            analysisGroupId={analysisGroupId}
            noText={!isPaymentProgramRowField}
          />
          <SortableTh
            name="orderRowsAmount"
            justifyContent="flex-end"
            analysisGroupId={analysisGroupId}
            noText={!isOrderRowField}
          />
          <SortableTh
            name="targetRowsAmount"
            justifyContent="flex-end"
            analysisGroupId={analysisGroupId}
            noText={!isTargetRowField}
          />
          <SortableTh
            name="arrivalsAmount"
            justifyContent="flex-end"
            analysisGroupId={analysisGroupId}
            noText={!isOrderRowField}
          />
          {mappedAttributeColumns.map((column) => (
            <SortableTh
              key={column.name}
              name="attributeValues"
              justifyContent="flex-end"
              attributeName={column.name}
              analysisGroupId={analysisGroupId}
              attributeId={column.id}
            />
          ))}
          <TxtTh name="none" />
          <TxtTh name="delete" />
        </tr>
      </Thead>
      <tbody>
        {analysisRows.map((row) => (
          <AnalysisRow key={row.id} {...row} />
        ))}
      </tbody>
    </Table>
  );
};
