import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import FocusLock from 'react-focus-lock';

import styled, { css } from 'styled-components';

import { IconCloseBlack, IconCloseWhite } from '../../assets/svg';

import { IconButton } from '../Buttons';

type SidebarProps = {
  children: React.ReactNode;
  ariaLabelledBy?: string;
  onClose: () => void;
  isDarkBackground?: boolean;
  align?: 'left' | 'right';
  blockEscapeKey?: boolean;
};

export const SidebarOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  display: flex;
  align-items: stretch;
  justify-content: flex-end;

  background: rgba(0, 0, 0, 0.5);

  z-index: 30;
`;

export const StyledHeader = styled.h3`
  padding: ${(props) => props.theme.margin[8]} 0;
  font-size: 18px;
`;

export const SidebarContent = styled.div`
  padding: ${(props) =>
    `0 ${props.theme.margin[32]} ${props.theme.margin[24]} ${props.theme.margin[32]}`};
  background: ${(props) => props.theme.color.white};
`;

export const SidebarContainer = styled.div<
  Pick<SidebarProps, 'isDarkBackground' | 'align'>
>`
  ${({ theme, isDarkBackground, align }) => css`
    position: absolute;
    top: 0;

    height: calc(100vh);
    width: 30rem;
    min-width: 30rem;
    max-width: 30rem;

    display: flex;
    flex-direction: column;

    background: ${isDarkBackground
      ? theme.color.sidebarBackground
      : theme.color.white};

    color: ${isDarkBackground ? theme.color.white : 'black'};

    ${align === 'left'
      ? css`
          left: ${theme.sizes.sideNavWidth};
        `
      : `right: 0;`}
  `}
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const CloseButton = styled(IconButton)`
  margin: ${(props) => props.theme.sizes.sidebarButtonPadding};
  align-self: flex-end;
`;

const ScrollingColumn = styled(FlexColumn)`
  height: calc(100vh - 5rem);
  overflow-y: scroll;
  overflow-x: hidden;
`;

const Sidebar = ({
  children,
  ariaLabelledBy,
  onClose,
  isDarkBackground,
  align,
  blockEscapeKey,
}: SidebarProps) => {
  // Close modal if user presses ESC key
  const onKeyPress = (e: KeyboardEvent) => {
    if (e.code === 'Escape' && !blockEscapeKey) {
      onClose();
    }
  };

  useEffect(() => {
    window.addEventListener('keyup', onKeyPress);

    return () => {
      window.removeEventListener('keyup', onKeyPress);
    };
  });

  const stopPropagation = (e: React.SyntheticEvent) => {
    e.stopPropagation();
  };

  const sidebarContent = (
    <SidebarOverlay onClick={onClose}>
      <SidebarContainer
        isDarkBackground={isDarkBackground}
        role="dialog"
        onClick={stopPropagation}
        aria-labelledby={ariaLabelledBy}
        align={align}
      >
        <FocusLock>
          <FlexColumn>
            <CloseButton
              onClick={onClose}
              icon={isDarkBackground ? IconCloseWhite : IconCloseBlack}
            />
            <ScrollingColumn>{children}</ScrollingColumn>
          </FlexColumn>
        </FocusLock>
      </SidebarContainer>
    </SidebarOverlay>
  );

  return ReactDOM.createPortal(sidebarContent, document.body);
};

export default Sidebar;
