import { Selector } from './utils';
import { isDefined } from '../../utils/general';
import * as remoteData from '../../utils/remoteData';

import { ActionTypes as Action } from '../actionTypes';

import { AppState } from '.';

export type CompanyReportingPeriod = {
  id: string;
  externalReportingPeriodCode: string;
  description: string;
  validFrom: Date;
  validTo: Date;
  isDeleted: boolean;
  createdAt: Date;
  updatedAt: Date;
};

type Mapping<A> = Partial<Record<string, A>>;
export type CompanyReportingPeriods = Mapping<CompanyReportingPeriod>;
export type State = remoteData.RemoteData<CompanyReportingPeriods>;
const initalState = remoteData.notAsked;

export default function reducer(
  state: State = initalState,
  action: Action
): State {
  switch (action.type) {
    case 'GET_COMPANY_REPORTING_PERIODS_STARTED': {
      return remoteData.loading;
    }
    case 'GET_COMPANY_REPORTING_PERIODS_FAILURE': {
      return remoteData.fail(undefined);
    }
    case 'GET_COMPANY_REPORTING_PERIODS_SUCCESS': {
      const { payload: companyReportingPeriods } = action;

      return remoteData.succeed(
        companyReportingPeriods.reduce(
          (
            acc: Partial<Record<string, CompanyReportingPeriod>>,
            companyReportingPeriod
          ) => ({
            ...acc,
            [companyReportingPeriod.id]: companyReportingPeriod,
          }),
          {}
        )
      );
    }
    default: {
      return state;
    }
  }
}

export function getCompanyReportingPeriods({
  companyReportingPeriods,
}: Pick<
  AppState,
  'companyReportingPeriods'
>): AppState['companyReportingPeriods'] {
  return companyReportingPeriods;
}

export const getCurrentCompanyReportingPeriod: () => Selector<
  remoteData.RemoteData<CompanyReportingPeriod | undefined>
> =
  () =>
  ({ companyReportingPeriods: reportingPeriods = remoteData.notAsked }) =>
    remoteData.map(reportingPeriods, (_) => {
      if (reportingPeriods.kind !== 'Success') {
        return undefined;
      }

      const data = reportingPeriods.value;
      const nowDate = new Date(Date.now());

      const value = Object.values(data)
        .filter(isDefined)
        .find(
          (reportingPeriod) =>
            reportingPeriod.validFrom <= nowDate &&
            reportingPeriod.validTo >= nowDate
        );

      return value;
    });
