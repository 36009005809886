import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink, match } from 'react-router-dom';

import styled from 'styled-components';

import { getActiveProjectId } from '../../store/reducers/ui';

import { isClickOrKeyboardSelection } from '../../utils/mouseOrKeyInteraction';

import {
  IconRevenue,
  IconProjectLarge,
  IconAnalysisLarge,
  IconWorksection,
  IconReport,
  IconTarget,
} from '../../assets';
import SiteDriveIcon from '../../assets/icons/sd_logo.svg';

import { generateUrl, routes } from '../../routes';

import Txt from '../Txt';
import NotificationIcon from './NotificationIcon';
import ProjectsIcon from './ProjectsIcon';
import UserSettings from './UserSettings';

const SideNav = styled.div`
  position: sticky;
  left: 0;

  min-width: ${(props) => props.theme.sizes.sideNavWidth};

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  background-color: ${(props) => props.theme.color.sidebarBackground};

  overflow: hidden;
`;

const ToolIcons = styled.div`
  position: sticky;
  top: 0;

  padding-bottom: ${(props) => props.theme.margin[64]};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;

  flex: 1;
`;

const NavigationIcons = styled.div`
  position: sticky;
  bottom: 0;

  padding-bottom: ${(props) => props.theme.margin[64]};

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SitedriveLogo = styled.img`
  margin-top: ${(props) => props.theme.margin[16]};
  margin-bottom: ${(props) => props.theme.margin[64]};
  width: ${(props) => props.theme.sizes.sitepayLogoWidth};
  height: ${(props) => props.theme.sizes.sitepayLogoHeight};
`;

const NavLinkWithIcon = styled(NavLink)`
  width: ${(props) => props.theme.sizes.navItemSize};
  height: ${(props) => props.theme.sizes.navItemSize};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  color: white;
  text-decoration: none;
  font-size: ${(props) => props.theme.fontSize.small};

  &.${(props) => props.activeClassName} {
    border-color: ${(props) => props.theme.color.toolbarHighlight};
    border-left: 2px solid;
    background-color: ${(props) =>
      props.theme.color.sidebarBackgroundHighlight};
  }
`;

// Classnames and styled-components do not mix well, but here this solution
// https://github.com/styled-components/styled-components/issues/184#issuecomment-284300950

// Because React-router could change the classname which represents
// active state, the classname is given through a prop, not as hardcoded
NavLinkWithIcon.defaultProps = {
  activeClassName: 'active',
};

const NavIcon = styled.img`
  margin-bottom: ${(props) => props.theme.margin[10]};
  height: ${(props) => props.theme.margin[24]};
  width: ${(props) => props.theme.margin[24]};
`;

type RouteName =
  | 'project'
  | 'order'
  | 'revenue'
  | 'worksection'
  | 'analysis'
  | 'reporting'
  | 'target';

interface HistoryLocation {
  pathname: string;
  search: string;
  state: unknown;
  hash: string;
  key?: string;
}

const isPathActive =
  (partOfUrl: RouteName) =>
  (matchObject: match | null, location: HistoryLocation) => {
    if (!matchObject) {
      return false;
    }

    if (location.pathname.includes(partOfUrl) && matchObject.isExact) {
      return true;
    }

    return location.pathname.includes(partOfUrl) && partOfUrl !== 'project';
  };

/**
 * LeftSideNav contains the navigation of SDPay app.
 *
 * TODO FIXME: This component will contain also the ProjectMenuButton, UserSettings and
 * FeedSidebar, once they have a design for how to behave in the LeftSideNav.
 *
 * Because LeftSideNav is outside of Route components, it doesn't get the match.params
 * object, and therefore we cannot check for the active NavLink that way, but are
 * comparing the match.url and seeing if it includes "/project" or "/revenue" or
 * some other view related url start words.
 *
 * Therefore, DO NOT use these url starting words in child routes inside the views,
 * so there shouldn't be a url that is "project/:projectId/revenue", because then this
 * component will have a bug.
 */

type LeftSideNavProps = {
  flexColumnRef: React.MutableRefObject<HTMLDivElement | null>;
};

const LeftSideNav = ({ flexColumnRef }: LeftSideNavProps) => {
  const projectId = useSelector(getActiveProjectId) ?? 'missing';

  const scrollToTop = () => {
    flexColumnRef.current?.scrollTo({ top: 0 });
  };

  const onClickOrKeyboardSelection = (
    e: React.MouseEvent | React.KeyboardEvent,
    callback: () => void
  ) => {
    if (isClickOrKeyboardSelection(e)) {
      callback();
    }
  };

  return (
    <SideNav>
      <SitedriveLogo src={SiteDriveIcon} />

      <NavigationIcons>
        <NavLinkWithIcon
          to={generateUrl({ route: routes.PROJECT, projectId })}
          isActive={(matchObject, location) =>
            isPathActive('project')(matchObject, location) ||
            isPathActive('order')(matchObject, location)
          }
          onKeyDown={(e) => onClickOrKeyboardSelection(e, () => scrollToTop())}
          onClick={(e) => onClickOrKeyboardSelection(e, () => scrollToTop())}
        >
          <NavIcon src={IconProjectLarge} />
          <Txt id="navigation.project" />
        </NavLinkWithIcon>
        <NavLinkWithIcon
          to={generateUrl({ route: routes.WORKSECTIONS, projectId })}
          isActive={isPathActive('worksection')}
          onKeyDown={(e) => onClickOrKeyboardSelection(e, () => scrollToTop())}
          onClick={(e) => onClickOrKeyboardSelection(e, () => scrollToTop())}
        >
          <NavIcon src={IconWorksection} />
          <Txt id="navigation.worksection" />
        </NavLinkWithIcon>
        <NavLinkWithIcon
          to={generateUrl({ route: routes.REVENUE, projectId })}
          isActive={isPathActive('revenue')}
          onKeyDown={(e) => onClickOrKeyboardSelection(e, () => scrollToTop())}
          onClick={(e) => onClickOrKeyboardSelection(e, () => scrollToTop())}
        >
          <NavIcon src={IconRevenue} />
          <Txt id="navigation.revenue" />
        </NavLinkWithIcon>
        <NavLinkWithIcon
          to={generateUrl({ route: routes.ANALYSIS, projectId })}
          isActive={isPathActive('analysis')}
          onKeyDown={(e) => onClickOrKeyboardSelection(e, () => scrollToTop())}
          onClick={(e) => onClickOrKeyboardSelection(e, () => scrollToTop())}
        >
          <NavIcon src={IconAnalysisLarge} />
          <Txt id="navigation.analysis" />
        </NavLinkWithIcon>
        <NavLinkWithIcon
          to={generateUrl({ route: routes.REPORTING, projectId })}
          isActive={isPathActive('reporting')}
          onKeyDown={(e) => onClickOrKeyboardSelection(e, () => scrollToTop())}
          onClick={(e) => onClickOrKeyboardSelection(e, () => scrollToTop())}
        >
          <NavIcon src={IconReport} />
          <Txt id="navigation.reporting" />
        </NavLinkWithIcon>
        <NavLinkWithIcon
          to={generateUrl({ route: routes.TARGET, projectId })}
          isActive={isPathActive('target')}
          onKeyDown={(e) => onClickOrKeyboardSelection(e, () => scrollToTop())}
          onClick={(e) => onClickOrKeyboardSelection(e, () => scrollToTop())}
        >
          <NavIcon src={IconTarget} />
          <Txt id="navigation.target" />
        </NavLinkWithIcon>
      </NavigationIcons>
      <ToolIcons>
        <NotificationIcon />
        <UserSettings />
        <ProjectsIcon />
      </ToolIcons>
    </SideNav>
  );
};

export default LeftSideNav;
