import React, { useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Big from 'big.js';

import { getActualCostById } from '../../../../store/reducers/actualCost';
import {
  ActualCostLine as ActualCostLineOrigType,
  getActualCostLinesByActualCostId,
} from '../../../../store/reducers/actualCostLine';
import { getOrderRows } from '../../../../store/reducers/orderRow';
import { getOrderTopics } from '../../../../store/reducers/topic';
import { getProjectWorkPackages } from '../../../../store/reducers/workPackage';

import * as Actions from '../../../../store/actions';
import {
  fetchDetailLinesForActualCost,
  moveActualCostLineNotification,
} from '../../../../store/actions';

import { APIOrderRow } from '../../../../types/api';
import {
  InvoiceLineSelectionPayload,
  SelectedInvoiceLinesState,
} from '../../../../types/general';

import useRemoteData from '../../../../hooks/useRemoteData';
import useTxt from '../../../../hooks/useTxt';

import { PrimaryButtonSmall } from '../../../../components/Buttons';
import Checkbox from '../../../../components/Input/Checkbox';
import { PrimaryRow, Table } from '../../../../components/Table';
import Txt from '../../../../components/Txt';

import * as big from '../../../../utils/big';
import CAN, {
  CaslPaymentProgramRowRequestParams,
} from '../../../../utils/caslUserPermissions';

import { ConvertModal } from '../ActualCostLineConvertModal';
import {
  CompactCell,
  SectionSpacer,
  SectionTitleContainer,
  SelectionContainer,
  SelectionContainerText,
  Title,
} from '../LineTablesCommon';
import OrderRowDropdown from '../OrderRowDropdown';
import ActualCostLine from './ActualCostLine';
import ActualCostLineTableHeader from './ActualCostLineTableHeader';
import {
  createOptionsForTopics,
  newTopicId,
  Topic,
  DescriptionText,
  InfoSpanText,
} from '../OrderRowDropdown/Options';

type Props = {
  actualCostId: string | null;
  orderId: string | null;
  projectId: string | null;
};

type ActualCostLineType = ActualCostLineOrigType & {
  checked: boolean;
};

const ActualCostLinesTable = ({ actualCostId, projectId, orderId }: Props) => {
  const [selectionState, setSelections] =
    React.useState<SelectedInvoiceLinesState>({});
  const [checked, setChecked] = React.useState<string[]>([]);

  const [highlightedLineId, setHighlightedLineId] = React.useState<string>();
  const [tableWidth, setTableWidth] = React.useState<number>();
  const [isRendered, setRendered] = React.useState<boolean>(false);

  const ref = React.useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    setRendered(true);
  }, []);

  useEffect(() => {
    setSelections({});
  }, [actualCostId]);

  useEffect(() => {
    if (ref.current && isRendered) {
      setTableWidth(ref.current.offsetWidth);
    }
  }, [isRendered]);

  const [showConvertModal, setShowConvertModal] = React.useState(false);

  const allActualCostLines =
    useRemoteData(
      getActualCostLinesByActualCostId(actualCostId),
      fetchDetailLinesForActualCost(actualCostId)
    ) ?? [];

  const filteredChecked = checked.filter((id) =>
    allActualCostLines.find((line) => line.id === id)
  );

  if (checked.length > filteredChecked.length) {
    setChecked(filteredChecked);
  }

  const onToggle = (actualCostLineId: string) =>
    setChecked(
      checked.find((id) => id === actualCostLineId)
        ? checked.filter((id) => id !== actualCostLineId)
        : [actualCostLineId, ...checked]
    );

  const onToggleAll = () =>
    setChecked(
      allActualCostLines.length === checked.length
        ? []
        : allActualCostLines.map((line) => line.id)
    );

  const onToggleTopic = (isChecked: boolean, actualCostLineIds: string[]) =>
    setChecked(
      isChecked
        ? checked.filter((id) => !actualCostLineIds.find((id2) => id === id2))
        : [
            ...actualCostLineIds.filter(
              (id) => !checked.find((id2) => id === id2)
            ),
            ...checked,
          ]
    );

  const actualCost = useSelector(getActualCostById(actualCostId ?? ''));

  const ability = new CaslPaymentProgramRowRequestParams(
    actualCost?.projectId ?? ''
  );
  const allowedUser = CAN('write', ability);

  const orderRows: APIOrderRow[] =
    useRemoteData(
      getOrderRows({ orderId: orderId ?? '' }),
      Actions.fetchOrderRowsForOrder(orderId ?? ''),
      !!orderId
    ) ?? [];

  const topics = (
    useRemoteData(
      getOrderTopics(orderId ?? ''),
      Actions.fetchTopicsForOrder(orderId ?? ''),
      !!orderId
    ) ?? []
  ).map((topic) => ({
    ...topic,
    allChecked: false,
    allDisabled: true,
  }));

  const projectWorkPackages =
    useRemoteData(
      getProjectWorkPackages(projectId ?? ''),
      Actions.fetchWorkPackagesForProject(projectId ?? ''),
      !!projectId
    ) ?? [];

  const buttonTxt = useTxt('order.actualCostLines.addButton');
  const newTopicText = useTxt('common.newTopic');
  const moreWorkSectionsText = useTxt('common.moreWorkSections');

  const unselectedTopicId = 'unselected-actual-cost-lines';

  const unselectedTopic: Topic = {
    id: unselectedTopicId,
    orderId: orderId ?? '',
    name: useTxt('order.actualCostLines.group.unassigned'),
    workPackageId: null,
    defaultTopic: false,
    isDeleted: false,
    orderRowIds: [],
    predictionChangeFromLatest: new Big(0),
    changeOrdersTotal: new Big(0),
    reservesTotal: new Big(0),
    targetTotal: new Big(0),
    additionalTargetTotal: new Big(0),
    contractTotal: new Big(0),
    updatedAt: '',
    createdAt: '',
    allChecked: false,
    allDisabled: true,
  };

  let allLinesChecked = true;
  let allLinesDisabled = true;
  let checkedTotalCount = 0;
  let checkedTotalAmount = new Big(0);

  const actualCostLinesByTopic = allActualCostLines.reduce(
    (
      result: Map<
        string,
        {
          topic: Topic;
          workPackageName: string | null;
          sum: Big;
          actualCostLines: ActualCostLineType[];
        }
      >,
      actualCostLine
    ) => {
      let foundOrderRowId: string | undefined;
      let foundTopicId: string | undefined;
      let foundWorkPackageId: string | undefined;
      let topic: Topic;
      // add found workPackageId

      const selection = (Object.entries(selectionState)?.find(
        (entry) =>
          entry[0] === actualCostLine.id &&
          (entry[1]?.linkedOrderRowId !== null ||
            entry[1]?.linkedTopicId !== null ||
            entry[1]?.workPackageId)
      ) ?? [undefined, undefined])[1];

      if (selection) {
        foundOrderRowId = selection.linkedOrderRowId ?? undefined;
        foundTopicId = selection.linkedTopicId ?? undefined;
        foundWorkPackageId = selection.workPackageId ?? undefined;
      } else {
        foundOrderRowId = actualCostLine.orderRowId ?? undefined;
      }

      if (foundOrderRowId && !foundTopicId) {
        foundTopicId = orderRows.find(
          (or) => or.id === foundOrderRowId
        )?.topicId;
      }

      topic =
        (foundTopicId
          ? topics.find((t) => t.id === foundTopicId)
          : undefined) ?? unselectedTopic;

      if (foundWorkPackageId) {
        const workPackage = projectWorkPackages.find(
          (wp) => wp.id === foundWorkPackageId
        );

        // map projectWorkPackages into "fake" topics
        topic = {
          ...topic,
          id: `${newTopicId}${workPackage?.id}`,
          workPackageId: workPackage?.id ?? null,
          name: `+ ${workPackage?.name} (${newTopicText})`,
          createNewTopic: true,
        };
      }

      const workPackage = topic.workPackageId
        ? projectWorkPackages.find((wp) => wp.id === topic.workPackageId)
        : undefined;
      const oldEntry = result.get(topic.id);

      const lineChecked =
        checked.find((id) => id === actualCostLine.id) !== undefined;

      if (!oldEntry) {
        topic.allChecked = lineChecked;
        topic.allDisabled = actualCostLine.arrivalRowId !== null;
      } else if (topic.allChecked && !lineChecked) {
        topic.allChecked = false;
      }

      if (actualCostLine.arrivalRowId === null) {
        allLinesDisabled = false;
        topic.allDisabled = false;
      }

      if (lineChecked) {
        checkedTotalCount += 1;
        checkedTotalAmount = checkedTotalAmount.add(
          actualCostLine.quantity.mul(actualCostLine.unitPrice)
        );
      } else {
        allLinesChecked = false;
      }

      result.set(topic.id, {
        ...(oldEntry ?? {
          topic,
          workPackageName: workPackage
            ? `${workPackage.code} ${workPackage.name}`
            : null,
        }),
        sum: (oldEntry?.sum ?? new Big(0)).add(
          actualCostLine.quantity.mul(actualCostLine.unitPrice)
        ),
        actualCostLines: [
          ...(oldEntry?.actualCostLines ?? []),
          {
            ...actualCostLine,
            checked: lineChecked,
          },
        ],
      });

      return result;
    },
    new Map()
  );

  const selectionTxt = useTxt('order.invoiceLines.selected', {
    count: String(checkedTotalCount),
    sum: big.priceFormat(checkedTotalAmount),
  });

  const buttonDisabled = actualCostLinesByTopic.size === 0;

  const modifySelections = (
    actualCostLineIds: string[],
    selectedOrderRowIdOrTopicId?: string | null
  ) => {
    if (actualCostLineIds.length === 0) {
      return;
    }

    const newState = { ...selectionState };

    let topicId: string | undefined;
    let payload: InvoiceLineSelectionPayload;
    let workPackageId: string | undefined;

    if (!selectedOrderRowIdOrTopicId) {
      payload = {
        linkedOrderRowId: null,
        linkedTopicId: null,
      };
    } else if (selectedOrderRowIdOrTopicId?.startsWith('orderRow-')) {
      const orderRowId = selectedOrderRowIdOrTopicId.replace('orderRow-', '');

      topicId = orderRows.find((row) => row.id === orderRowId)?.topicId;

      payload = {
        linkedOrderRowId: orderRowId ?? null,
        linkedTopicId: null,
      };
    } else if (selectedOrderRowIdOrTopicId?.startsWith('newRowForTopic-')) {
      topicId = selectedOrderRowIdOrTopicId.replace('newRowForTopic-', '');

      payload = {
        linkedOrderRowId: null,
        linkedTopicId: topicId ?? null,
      };
    } else if (
      selectedOrderRowIdOrTopicId?.startsWith('newRowForWorkPackage-')
    ) {
      workPackageId = selectedOrderRowIdOrTopicId.replace(
        'newRowForWorkPackage-',
        ''
      );

      payload = {
        linkedOrderRowId: null,
        linkedTopicId: null,
        workPackageId,
      };
    }

    actualCostLineIds.forEach((actualCostLineId) => {
      newState[actualCostLineId] = { ...payload };
    });

    setSelections(newState);

    const topicName = topicId
      ? topics.find((t) => t.id === topicId)?.name
      : undefined;

    setHighlightedLineId(undefined);

    dispatch(
      moveActualCostLineNotification(
        () => setHighlightedLineId(actualCostLineIds[0]),
        topicName
      )
    );
  };

  const setLinkedOrderRowOrTopicId = (
    actualCostLineId: string,
    selectedOrderRowIdOrTopicId?: string | null
  ) => modifySelections([actualCostLineId], selectedOrderRowIdOrTopicId);

  const setSelectedLinkedOrderRowId = (selectedOrderRowOrTopicId: string) =>
    modifySelections(
      checked,
      selectedOrderRowOrTopicId === 'NONE' ? null : selectedOrderRowOrTopicId
    );

  const widthForDropdown = tableWidth ? tableWidth - 40 : 400;

  const topicOptions = createOptionsForTopics(
    topics,
    orderRows,
    projectWorkPackages,
    widthForDropdown,
    newTopicText,
    moreWorkSectionsText
  );

  const selectedTopics = Array.from(actualCostLinesByTopic.values())
    .map((entry) => entry.topic)
    .filter((topic) => topic.id !== unselectedTopicId);

  const distinctSelectedTopics = [...new Set(selectedTopics)];

  return (
    <>
      {showConvertModal && actualCost ? (
        <ConvertModal
          selectionState={selectionState}
          actualCostLines={allActualCostLines}
          selectedTopics={distinctSelectedTopics}
          orderRows={orderRows}
          actualCost={actualCost}
          workPackages={projectWorkPackages}
          onClose={() => setShowConvertModal(false)}
          onConvertSuccess={() => setSelections({})}
        />
      ) : null}
      <SectionSpacer ref={ref}>
        <SectionTitleContainer>
          <Txt id="order.actualCostLines.headerTitle" component={Title} />
          {allActualCostLines.length > 0 ? (
            <PrimaryButtonSmall
              disabled={buttonDisabled || !allowedUser}
              onClick={() => setShowConvertModal(true)}
            >
              {buttonTxt}
            </PrimaryButtonSmall>
          ) : null}
        </SectionTitleContainer>
        {checked.length > 0 ? (
          <SelectionContainer>
            <SelectionContainerText>{selectionTxt}</SelectionContainerText>
            <OrderRowDropdown
              allowedUser={allowedUser}
              selectionState={undefined}
              orderRows={orderRows}
              widthForDropdown={widthForDropdown}
              alreadyAssigned={false}
              onChange={setSelectedLinkedOrderRowId}
              projectId={projectId}
              orderId={orderId}
            />
          </SelectionContainer>
        ) : null}
        <Table>
          <ActualCostLineTableHeader
            boxChecked={allLinesChecked}
            boxDisabled={allLinesDisabled}
            onToggle={onToggleAll}
            showSelectionContainer={checked.length > 0}
          />
          <tbody>
            {Array.from(actualCostLinesByTopic.entries())
              .sort(([a], [b]) => a.localeCompare(b))
              .map(
                (
                  [topicId, { topic, workPackageName, sum, actualCostLines }],
                  idx
                ) => (
                  <React.Fragment key={`actual-cost-line-topic-${topicId}`}>
                    {idx > 0 || topicId !== unselectedTopicId ? (
                      <PrimaryRow>
                        <CompactCell align="center" paddingDisabled>
                          {!topic.allDisabled ? (
                            <Checkbox
                              checked={topic.allChecked}
                              onChange={() =>
                                onToggleTopic(
                                  topic.allChecked,
                                  actualCostLines.map((line) => line.id)
                                )
                              }
                              onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  onToggleTopic(
                                    topic.allChecked,
                                    actualCostLines.map((line) => line.id)
                                  );
                                }
                              }}
                              variant="secondary"
                            />
                          ) : null}
                        </CompactCell>
                        <CompactCell colSpan={4}>
                          <DescriptionText>{topic.name}</DescriptionText>
                          <InfoSpanText>{workPackageName}</InfoSpanText>
                        </CompactCell>
                        <CompactCell align="right">
                          {big.priceFormat(sum)}
                        </CompactCell>
                        <CompactCell colSpan={2} />
                      </PrimaryRow>
                    ) : null}
                    {actualCostLines.map((actualCostLine) => (
                      <ActualCostLine
                        allowedUser={allowedUser}
                        key={`actual-cost-line-${actualCostLine.id}`}
                        orderRows={orderRows}
                        actualCostLine={actualCostLine}
                        selectionState={selectionState[actualCostLine.id]}
                        isAllRendered={isRendered}
                        isHighlighted={highlightedLineId === actualCostLine.id}
                        onChange={setLinkedOrderRowOrTopicId}
                        widthForDropdown={tableWidth ? tableWidth - 40 : 400}
                        checked={actualCostLine.checked}
                        options={topicOptions}
                        onToggle={onToggle}
                        projectId={projectId}
                        orderId={orderId}
                      />
                    ))}
                  </React.Fragment>
                )
              )}
          </tbody>
        </Table>
      </SectionSpacer>
    </>
  );
};

export default ActualCostLinesTable;
