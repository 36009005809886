import React from 'react';

import styled, { css } from 'styled-components';

import { IconAccordionUp, IconAccordionDown } from '../assets/svg';

import { IconButton } from './Buttons';

type VerticalAccordionProps = React.PropsWithChildren<{
  isOpen: boolean;
  toggle: () => void;
}>;

type VerticalAccordionContainerProps = {
  isOpen: boolean;
};

const VerticalAccordionContainer = styled.div`
  margin-top: 1rem;

  height: auto;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const VerticalAccordionContent = styled.div<VerticalAccordionContainerProps>`
  position: relative;
  margin-top: 1rem;
  display: none;
  ${(props) =>
    props.isOpen
      ? css`
          display: block;
        `
      : ''}
`;

/**
 * Accordion component which takes default isOpen state and onToggle callback for
 * listening the accordions open/closed state.
 */
// eslint-disable-next-line import/prefer-default-export
export const VerticalAccordion = ({
  toggle,
  isOpen,
  children,
}: VerticalAccordionProps) => (
  <VerticalAccordionContainer>
    <IconButton
      icon={isOpen ? IconAccordionUp : IconAccordionDown}
      large
      onClick={() => {
        toggle();
      }}
    />
    <VerticalAccordionContent isOpen={isOpen}>
      {children}
    </VerticalAccordionContent>
  </VerticalAccordionContainer>
);
