import React from 'react';

import styled, { css } from 'styled-components';

import useOnClickOutside from '../hooks/useOnClickOutside';
import useRoveFocus from '../hooks/useRoveFocus';

/**
 * DropDownList is a styled component, that creates a floating container for list items.
 * DropDownList is a flex column, whose children are aligned to left.
 *
 * It is recommended to use DropDownList inside a <Cell> component
 * so that the DropDownList has a close containing block
 * (https://developer.mozilla.org/en-US/docs/Web/CSS/Containing_Block), and that it
 * doesn't get positioned to some random place in the page (closest containing block might be very far).
 *
 * For the future:
 * Currently the top and right proporties are taken from the theme, because this component
 * is used in very few places, and these numbers suit the usage there (the containing block are
 * colspan 1, centered components that contain only one icon).
 * If the right and top properties need to be adjusted, when/if this component is used in more
 * places, probably best idea is to give them as props, so that there's no need to create a new component.
 *
 * @param parentRef is used to exclude the button that opens the dropdown from the click outside listener.
 * @param children it's preferred that you use only HTML elements as children, because the focus
 */

type DropDownListProps = React.PropsWithChildren<
  React.ComponentProps<typeof StyledDropDownList>
> & {
  onClose?: () => void;
  parentRef?: React.MutableRefObject<HTMLElement | null>;
};

export const DropDownList = (props: DropDownListProps) => {
  const childRefs = React.useRef<(HTMLElement | null)[]>([]);

  const { onClose, parentRef, children, ...rest } = props;

  const ref = useOnClickOutside(
    () => {
      if (onClose) {
        onClose();
      }
    },
    parentRef ? [parentRef] : undefined
  );

  const onKeyPress = (e: KeyboardEvent) => {
    if (e.code === 'Escape' && onClose) {
      onClose();
    }
  };

  const count = React.Children.count(children);

  const [focus, setFocus] = useRoveFocus(count, ref);
  const [hasFocusedFirstTime, setFocusedFirstTime] = React.useState(false);

  React.useEffect(() => {
    window.addEventListener('keyup', onKeyPress);

    return () => {
      window.removeEventListener('keyup', onKeyPress);
    };
  });

  React.useEffect(() => {
    if (childRefs.current[focus]) {
      childRefs.current[focus]?.focus();
    }
  }, [focus]);

  React.useLayoutEffect(() => {
    if (!hasFocusedFirstTime && childRefs.current) {
      const firstNonNull = childRefs.current.findIndex((element) => !!element);
      setFocus(firstNonNull);
      setFocusedFirstTime(true);
    }
  }, [hasFocusedFirstTime, setFocus]);

  return (
    <StyledDropDownList {...rest} ref={ref}>
      {React.Children.map(children, (child, index) => {
        if (React.isValidElement(child)) {
          const existingOnClick = child.props.onClick;
          const existingRef = child.props.ref;

          return React.cloneElement(child, {
            ref: (el: HTMLElement) => {
              childRefs.current[index] = el;

              if (existingRef) {
                existingRef.current = el;
              }
            },
            onClick: (event: React.MouseEvent) => {
              if (existingOnClick) {
                existingOnClick(event);
              }

              setFocus(index);
            },
          } as React.DOMAttributes<HTMLElement>);
        }

        return child;
      })}
    </StyledDropDownList>
  );
};

export const StyledDropDownList = styled.div`
  position: absolute;
  right: ${(props) => props.theme.margin[8]};
  top: ${(props) => props.theme.margin[24]};

  border: 1px solid ${({ theme }) => theme.color.graphiteB76};
  border-radius: ${(props) => props.theme.margin[2]};
  box-shadow: 0px 4px 40px -10px rgba(0, 0, 0, 0.25);

  padding: ${({ theme }) => `${theme.margin[32]} 0 `};

  display: flex;
  flex-direction: column;

  background: ${(props) => props.theme.color.white};

  cursor: initial;

  z-index: 30;
`;

type DropDownItemProps = {
  disabled?: boolean;
};

const IconSize = '1.125rem';

export const DropDownItem = styled.span<DropDownItemProps>`
  padding: ${(props) => `${props.theme.margin[8]} ${props.theme.margin[24]}`};

  display: flex;
  align-items: center;

  font-size: ${(props) => props.theme.fontSize.base};
  font-weight: normal;
  white-space: nowrap;
  text-align: left;
  color: ${(props) =>
    props.disabled ? props.theme.color.graphiteB76 : props.theme.color.pitch};

  /* stylelint-disable selector-max-type -- svgs have to be styled with type */
  svg {
    margin-right: ${(props) => props.theme.margin[24]};
    width: ${IconSize};
    height: ${IconSize};
  }
  svg path {
    stroke: ${(props) => (props.disabled ? props.theme.color.graphiteB76 : ``)};
  }

  :hover,
  :focus {
    ${({ disabled, theme }) =>
      disabled
        ? css`
            user-select: none;
            cursor: not-allowed;
          `
        : css`
            background-color: ${theme.color.primaryRowBackground};
            cursor: pointer;
          `}
  }
`;
