type GenericTableRow = {
  id: string;
  subRows?: GenericTableRow[];
};

type TableRowWithParentChildIds = GenericTableRow & {
  childIds: string[];
  parentId: string | null;
  depth: number;
};

type TableRowWithParentId = GenericTableRow & {
  parentId: string | null;
  depth: number;
};

export function getAllRowsFlattened<T extends GenericTableRow>(
  rows: T[],
  mappedRows: Omit<T, 'subrows'>[] = []
) {
  for (let i = 0; i < rows.length; i++) {
    const row = rows[i] as any;
    const { subRows, ...rest } = row;
    mappedRows.push(rest);

    if (subRows) {
      getAllRowsFlattened(subRows, mappedRows);
    }
  }

  return mappedRows;
}

// Searches all childIds until depth = 0
export const collectAllParentIds = <T extends TableRowWithParentChildIds>(
  startDepth: number,
  childIds: Set<string>,
  entries: T[]
): string[] => {
  let newIds: string[] = [];

  for (let i = 0; i < entries.length; i++) {
    const entry = entries[i];

    if (
      entry.depth === startDepth &&
      entry.childIds.some((id) => childIds.has(id))
    ) {
      newIds.push(entry.id);
    }
  }

  const allIds = new Set([...childIds, ...newIds]);
  const nextDepth = startDepth - 1;

  if (nextDepth < 0) {
    return [...allIds];
  }

  return collectAllParentIds(nextDepth, allIds, entries);
};

// Searches all parentIds until depth = 0
export const collectAllChildIds = <T extends TableRowWithParentId>(
  currentDepth: number,
  maxDepth: number,
  parentIds: Set<string>,
  entries: T[]
): string[] => {
  let newIds: string[] = [];

  for (let i = 0; i < entries.length; i++) {
    const entry = entries[i];

    if (
      entry.depth === currentDepth &&
      entry.parentId &&
      parentIds.has(entry.parentId)
    ) {
      newIds.push(entry.id);
    }
  }

  const allIds = new Set([...parentIds, ...newIds]);
  const nextDepth = currentDepth + 1;

  if (nextDepth > maxDepth) {
    return [...allIds];
  }

  return collectAllChildIds(nextDepth, maxDepth, allIds, entries);
};

export const getSelectedRowsFlattenedWithSubrows = <T extends GenericTableRow>(
  rows: T[],
  selectedIds?: Set<string>,
  mappedRows: T[] = []
) => {
  for (let i = 0; i < rows.length; i++) {
    const row = rows[i];

    if (selectedIds && selectedIds.has(row.id)) {
      mappedRows.push(row);
    }

    if (!selectedIds) {
      mappedRows.push(row);
    }

    if (row.subRows) {
      getSelectedRowsFlattenedWithSubrows(row.subRows, selectedIds, mappedRows);
    }
  }

  return mappedRows;
};
