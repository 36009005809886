import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useHistory } from 'react-router-dom';

import styled from 'styled-components';

import {
  getFirstHandleableActualCostByOrderId,
  getUnhandledUnhandledActualCostCountByOrderId,
} from '../../../../../store/reducers/actualCost';
import {
  getActualCostIdsAssignedArrivalRowsByOrderId,
  getInvoiceIdsAssignedArrivalRowsByOrderId,
} from '../../../../../store/reducers/arrivalRow';
import {
  getFirstHandleableInvoiceHeaderByOrderId,
  getUnhandledInvoiceHeaderCountByOrderId,
} from '../../../../../store/reducers/invoiceHeader';
import { getInvoiceLineConverts } from '../../../../../store/reducers/invoiceLine';
import { getOrderById } from '../../../../../store/reducers/order/order';
import { getIsOuterBarOpen } from '../../../../../store/reducers/ui';

import { setOuterBarState } from '../../../../../store/actions/ui';

import { ID } from '../../../../../types/general';

import useRouteParams from '../../../../../hooks/useRouteParams';
import useTxt from '../../../../../hooks/useTxt';

import { IconTextButton } from '../../../../../components/Buttons';
import SearchInput from '../../../../../components/Input/SearchInput';
import { VerticalAccordion } from '../../../../../components/VerticalAccordion';

import { IconEye } from '../../../../../assets/svg';

import { generateUrl, routes } from '../../../../../routes';

import ActualCostsTable from './ActualCostsTable';
import { AssignmentSelection } from './AssignmentTool';
import { ToggleBar } from './Components';
import InvoicesTable from './InvoicesTable';
import UnassignedArrivalRowsTable from './UnassignedArrivalRowsTable';

const ArrivalAssignmentLaari = () => {
  const { projectId, orderId, subViewMode, showTargetRows } = useRouteParams();
  const history = useHistory();

  const { hash } = history.location;

  const hashInvoiceId = hash.replace('#invoiceHeaderId-', '');

  const [searchText, setSearchText] = useState<{
    text: string;
    idFilter?: string;
  }>({ text: '', idFilter: hashInvoiceId });

  const { search } = history.location;

  const query = React.useMemo(() => new URLSearchParams(search), [search]);

  const dispatch = useDispatch();
  const order = useSelector(getOrderById(orderId), shallowEqual);

  const successfulInvoiceLineConverts = useSelector(
    getInvoiceLineConverts(),
    shallowEqual
  );

  const firstHandleableInvoice = useSelector(
    getFirstHandleableInvoiceHeaderByOrderId(orderId),
    shallowEqual
  );

  const firstHandleableActualCost = useSelector(
    getFirstHandleableActualCostByOrderId(orderId),
    shallowEqual
  );
  const [isShownBasedOnUrl, setShown] = useState(false);
  const [showHandledRows, setShowHandledRows] = useState(false);
  const [expandedConverts, setExpandedConverts] = useState<string[]>([]);
  const [expandedInvoiceIds, setExpandedInvoiceIds] = useState<string[]>([]);
  const toggleShowHandledRows = () => setShowHandledRows(!showHandledRows);
  const outerBarState = useSelector(getIsOuterBarOpen());

  // add state that tracks if invoice count changes and trigger effects based on that
  const [unhandledCountState, setUnhandledCount] = useState<{
    initialLoadDone: boolean;
    count: number;
  }>({ initialLoadDone: false, count: 0 });

  const unhandledInvoiceHeadersCount = useSelector(
    getUnhandledInvoiceHeaderCountByOrderId(orderId)
  );

  const unhandledActualCostsCount = useSelector(
    getUnhandledUnhandledActualCostCountByOrderId(orderId)
  );

  const unhandledCount = useMemo(
    () => unhandledActualCostsCount + unhandledInvoiceHeadersCount,
    [unhandledInvoiceHeadersCount, unhandledActualCostsCount]
  );

  const [selectedPurchaseInvoiceId, setSelectedPurchaseInvoiceId] =
    React.useState<string | undefined>(undefined);

  const [selectedActualCostId, setSelectedActualCostId] = React.useState<
    string | undefined
  >(undefined);

  const [
    unAssignedArrivalRowsDefaultSelection,
    setUnAssignedArrivalRowsDefault,
  ] = React.useState<AssignmentSelection>({ kind: 'unassigned' });

  const toggleExpandInvoice = useCallback((id: string, newState?: boolean) => {
    setExpandedInvoiceIds((expandedIds) => {
      if (newState !== undefined) {
        if (newState) {
          return [...new Set([...expandedIds, id])];
        }

        return expandedIds.filter((i) => i !== id);
      }

      if (expandedIds.includes(id)) {
        return expandedIds.filter((i) => i !== id);
      }

      const openIds = [...new Set([...expandedIds, id])];

      return openIds;
    });
  }, []);

  React.useEffect(() => {
    if (
      successfulInvoiceLineConverts.some((id) => !expandedConverts.includes(id))
    ) {
      setExpandedInvoiceIds((previousIds) => [
        ...new Set([...previousIds, ...successfulInvoiceLineConverts]),
      ]);
      setExpandedConverts(successfulInvoiceLineConverts);
    }
  }, [successfulInvoiceLineConverts, setExpandedInvoiceIds, expandedConverts]);

  // clear selections when unhandled count changes so that they can be automatically reselected
  // with the other useEffect
  React.useEffect(() => {
    // don't clear selections at initial load
    if (
      !unhandledCountState.initialLoadDone &&
      unhandledCount !== unhandledCountState.count
    ) {
      return setUnhandledCount({
        initialLoadDone: true,
        count: unhandledCount,
      });
    }

    if (unhandledCount !== unhandledCountState.count) {
      setSelectedPurchaseInvoiceId(undefined);
      setSelectedActualCostId(undefined);
      setUnhandledCount({ initialLoadDone: true, count: unhandledCount });
    }
  }, [
    unhandledCount,
    unhandledCountState.count,
    unhandledCountState.initialLoadDone,
  ]);

  React.useEffect(() => {
    if (isShownBasedOnUrl) {
      return;
    }

    if (query.get('showAll') === 'true') {
      setShowHandledRows(true);
      setShown(true);
    }
  }, [isShownBasedOnUrl, query]);

  // Arrival rows that have been assigned to an invoice, grouped by invoice
  // (as in purchaseInvoiceHeaderId)
  const invoiceAssignedArrivalRows = useSelector(
    getInvoiceIdsAssignedArrivalRowsByOrderId(orderId),
    (a, b) => JSON.stringify(a) === JSON.stringify(b)
  );

  // Arrival rows that have been assigned to an actual cost, grouped by actual cost
  // (as in actualCostId)
  const actualCostAssignedArrivalRows = useSelector(
    getActualCostIdsAssignedArrivalRowsByOrderId(orderId),
    (a, b) => JSON.stringify(a) === JSON.stringify(b)
  );

  const setSelectedPurchaseInvoice = useCallback(
    (selection: string | undefined, onlyOuterBar?: boolean) => {
      if (outerBarState) {
        dispatch(
          setOuterBarState(
            selection
              ? {
                  type: 'invoice_header',
                  contentId: selection,
                }
              : null
          )
        );
      }

      if (!onlyOuterBar) {
        if (selection) {
          setUnAssignedArrivalRowsDefault({ kind: 'invoice', id: selection });
        }
      }

      setSelectedPurchaseInvoiceId(selection);
      setSelectedActualCostId(undefined);
    },
    [dispatch, outerBarState]
  );

  const setSelectedActualCost = useCallback(
    (selection: string | undefined, onlyOuterBar?: boolean) => {
      if (outerBarState) {
        dispatch(
          setOuterBarState(
            selection
              ? {
                  type: 'actual_cost',
                  contentId: selection,
                }
              : null
          )
        );
      }

      if (!onlyOuterBar) {
        if (selection) {
          setUnAssignedArrivalRowsDefault({ kind: 'cost', id: selection });
        }
      }

      setSelectedActualCostId(selection);
      setSelectedPurchaseInvoiceId(undefined);
    },
    [dispatch, outerBarState]
  );

  // Select first handleable purchase invoice header / actual cost
  // triggers when selections are empty (at render + after unhandled count changes)
  React.useEffect(() => {
    if (!selectedPurchaseInvoiceId && !selectedActualCostId) {
      if (firstHandleableInvoice) {
        setSelectedPurchaseInvoiceId(firstHandleableInvoice.id);

        if (!expandedInvoiceIds.includes(firstHandleableInvoice.id)) {
          setExpandedInvoiceIds((previousIds) => {
            const openIds = [
              ...new Set([...previousIds, firstHandleableInvoice.id]),
            ];

            return openIds;
          });
        }

        if (outerBarState) {
          dispatch(
            setOuterBarState(
              firstHandleableInvoice.id
                ? {
                    type: 'invoice_header',
                    contentId: firstHandleableInvoice.id,
                  }
                : null
            )
          );
        }

        return setUnAssignedArrivalRowsDefault({
          kind: 'invoice',
          id: firstHandleableInvoice.id,
        });
      }

      if (firstHandleableActualCost) {
        setSelectedActualCostId(firstHandleableActualCost.id);

        if (outerBarState) {
          dispatch(
            setOuterBarState(
              firstHandleableActualCost.id
                ? {
                    type: 'actual_cost',
                    contentId: firstHandleableActualCost.id,
                  }
                : null
            )
          );
        }

        return setUnAssignedArrivalRowsDefault({
          kind: 'cost',
          id: firstHandleableActualCost.id,
        });
      }
    }
  }, [
    outerBarState,
    selectedPurchaseInvoiceId,
    setSelectedPurchaseInvoiceId,
    invoiceAssignedArrivalRows,
    firstHandleableInvoice,
    selectedActualCostId,
    setSelectedActualCostId,
    firstHandleableActualCost,
    actualCostAssignedArrivalRows,
    expandedInvoiceIds,
    dispatch,
  ]);

  const showText = useTxt('order.receiveMode.showHandled');
  const hideText = useTxt('order.receiveMode.hideHandled');

  const searchTextPlaceholder = useTxt(
    'order.receiveMode.unassignedArrivalRows.searchPlaceholder'
  );

  const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText({ text: e.target.value });
  };

  if (!order) {
    return null;
  }

  const receiveUrl = generateUrl({
    route: routes.ORDER,
    projectId,
    orderId,
    viewMode: 'receive',
    showTargetRows,
  });

  const receiveInvoicesUrl = generateUrl({
    route: routes.ORDER,
    projectId,
    orderId,
    viewMode: 'receive',
    subViewMode: 'invoices',
    showTargetRows,
  });

  return (
    <>
      <UnassignedArrivalRowsTable
        orderId={order.id}
        projectId={projectId}
        defaultSelection={unAssignedArrivalRowsDefaultSelection}
        onAssignPurchaseInvoice={(id: ID) => {
          if (!expandedInvoiceIds.includes(id)) {
            toggleExpandInvoice(id);
          }

          setSelectedPurchaseInvoice(id);
          history.push(receiveInvoicesUrl);
        }}
        onAssignActualCost={(id: ID) => {
          setSelectedActualCostId(id);
          history.push(receiveInvoicesUrl);
        }}
      />
      <VerticalAccordion
        isOpen={subViewMode === 'invoices'}
        toggle={() => {
          if (subViewMode === 'invoices') {
            history.push(receiveUrl);
          } else {
            history.push(receiveInvoicesUrl);
          }
        }}
      >
        <ToggleBar>
          <ToggleButton
            icon={IconEye}
            onClick={toggleShowHandledRows}
            data-testid="toggle-show-handled"
          >
            {showHandledRows ? hideText : showText}
          </ToggleButton>
        </ToggleBar>
        <StyledSearchInput
          onChange={onSearchInputChange}
          placeholder={searchTextPlaceholder}
          value={searchText.text}
          handleClearButtonClick={() => setSearchText({ text: '' })}
          backgroundColor="white"
        />
        <InvoicesTable
          orderId={orderId}
          showHandledRows={showHandledRows}
          selectedId={selectedPurchaseInvoiceId}
          setSelectedId={setSelectedPurchaseInvoice}
          expandedInvoiceIds={expandedInvoiceIds}
          toggleExpandInvoice={toggleExpandInvoice}
          searchText={searchText}
          setSearchText={setSearchText}
        />
        <ActualCostsTable
          orderId={orderId}
          showHandledRows={showHandledRows}
          selectedId={selectedActualCostId}
          setSelectedId={setSelectedActualCost}
          searchText={searchText}
          setSearchText={setSearchText}
        />
      </VerticalAccordion>
    </>
  );
};

const ToggleButton = styled(IconTextButton)`
  margin-left: auto;
  padding: ${({ theme }) => theme.margin[8]};
  height: ${({ theme }) => theme.margin[32]};
  flex: 0 1 auto;
`;

const StyledSearchInput = styled(SearchInput)`
  position: absolute;
  right: 0;

  margin: 0;

  padding: 0 2.5rem;

  width: 20rem;
`;

export default ArrivalAssignmentLaari;
