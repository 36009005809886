import { APIUser, APIProjectUser } from '../../types/api';
import { ID } from '../../types/general';
import { toAPIProjectUser } from '../../types/mappers';

import { ExtractActionTypes, makeAction } from '../../utils/actionCreators';
import {
  GET,
  BackendError,
  apiErrorHandlingWithDecode,
  PUT,
  POST,
} from '../../utils/api';
import { flow } from '../../utils/function';
import * as remoteData from '../../utils/remoteData';
import { createAsyncThunk } from '../../utils/thunk';

import {
  selectPostUserRequest,
  selectAllUsers,
  selectProjectUsers,
} from '../reducers/projectUsers';

const actionCreators = {
  ...makeAction('getProjectUsersStarted')<{ projectId: string }>(),
  ...makeAction('getProjectUsersFailure')<{
    projectId: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('getProjectUsersSuccess')<{
    projectId: string;
    users: APIProjectUser[];
  }>(),
  ...makeAction('getAllUsersStarted')<undefined>(),
  ...makeAction('getAllUsersFailure')<{
    error: BackendError | undefined;
  }>(),
  ...makeAction('getAllUsersSuccess')<{
    users: APIProjectUser[];
  }>(),
  ...makeAction('putProjectUserRightsStarted')<{ projectId: string }>(),
  ...makeAction('putProjectUserRightsFailure')<{
    projectId: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('putProjectUserRightsSuccess')<{
    projectId: string;
    users: APIProjectUser[];
  }>(),
  ...makeAction('postUserStarted')<{ requestId: string }>(),
  ...makeAction('postUserFailure')<{
    requestId: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('postUserSuccess')<{
    requestId: string;
    user: APIProjectUser;
  }>(),
};

export const {
  getProjectUsersStarted,
  getProjectUsersSuccess,
  getProjectUsersFailure,
  getAllUsersStarted,
  getAllUsersSuccess,
  getAllUsersFailure,
  putProjectUserRightsStarted,
  putProjectUserRightsFailure,
  putProjectUserRightsSuccess,
  postUserStarted,
  postUserFailure,
  postUserSuccess,
} = actionCreators;

export type ProjectUsersAction = ExtractActionTypes<typeof actionCreators>;
interface ProjectRole {
  /**
   * 2 = project editor
   * 3 = read only
   * 4 = project invoice handler
   */
  roleId: string;
  /**
   * null if valid from today onwards
   */
  validFrom: Date | string | null;
  /**
   * null if valid to forever
   */
  validTo: Date | string | null;
  isDeleted: boolean;
}

interface PutProjectUserRightsBodyParams {
  userId: string;
  roles: ProjectRole[];
}
export interface PutMultipleProjectUserRightsBodyParams {
  data: PutProjectUserRightsBodyParams[];
}

const getProjectUsers = async (projectId: string) => {
  const apiUsers = await GET<APIUser[]>(`v1/projects/${projectId}/users`);

  return apiUsers.map(toAPIProjectUser);
};

export const fetchProjectUsers = (projectId: ID) =>
  createAsyncThunk(getProjectUsers, {
    args: [projectId],
    isPending: flow(selectProjectUsers(projectId), remoteData.isLoading),
    initialAction: getProjectUsersStarted({ projectId }),
    successActionCreator: (users) =>
      getProjectUsersSuccess({ projectId, users }),
    failureActionCreator: (error) =>
      getProjectUsersFailure({
        projectId,
        error: apiErrorHandlingWithDecode(error),
      }),
  });

const requestUpdateProjectUserRights = async (
  projectId: ID,
  body: PutMultipleProjectUserRightsBodyParams
) => {
  const apiUsers = await PUT<APIUser[]>(
    `v1/projects/${projectId}/user-rights`,
    { ...body }
  );

  return apiUsers.map(toAPIProjectUser);
};

export const updateProjectUserRights = (
  projectId: ID,
  body: PutMultipleProjectUserRightsBodyParams
) =>
  createAsyncThunk(requestUpdateProjectUserRights, {
    args: [projectId, body],
    isPending: flow(selectProjectUsers(projectId), remoteData.isLoading),
    initialAction: putProjectUserRightsStarted({ projectId }),
    successActionCreator: (users) =>
      putProjectUserRightsSuccess({ projectId, users }),
    failureActionCreator: (error) =>
      putProjectUserRightsFailure({
        projectId,
        error: apiErrorHandlingWithDecode(error),
      }),
  });

const getAllUsers = async () => {
  const apiUsers = await GET<APIUser[]>(`v1/users`);

  return apiUsers.map(toAPIProjectUser);
};

export const fetchAllUsers = () =>
  createAsyncThunk(getAllUsers, {
    args: [],
    isPending: flow(selectAllUsers(), remoteData.isLoading),
    initialAction: getAllUsersStarted(undefined),
    successActionCreator: (users) => getAllUsersSuccess({ users }),
    failureActionCreator: (error) =>
      getAllUsersFailure({
        error: apiErrorHandlingWithDecode(error),
      }),
  });

export interface UpdateInsertProjectUserParams {
  email: string;
  name: string;
  languageId?: string;
  roles: {
    projectId: string;
    /**
     * 2 = project editor
     * 3 = project read only
     * 4 = project invoice handler
     */
    roleId: string;
    /**
     * if null then valid from is set to today
     */
    validFrom: Date | string | null;
    /**
     * if null then valid to is set to 2090-01-01
     */
    validTo: Date | string | null;
    isDeleted: boolean;
  }[];
}

const postUser = async (params: UpdateInsertProjectUserParams) => {
  const response = await POST<APIUser>(`v1/users`, { ...params });

  return toAPIProjectUser(response);
};

export const createUser = (
  requestId: string,
  params: UpdateInsertProjectUserParams
) =>
  createAsyncThunk(postUser, {
    args: [params],
    isPending: flow(selectPostUserRequest(requestId), remoteData.isLoading),
    initialAction: postUserStarted({ requestId }),
    successActionCreator: (user) => postUserSuccess({ requestId, user }),
    failureActionCreator: (error) =>
      postUserFailure({
        requestId,
        error: apiErrorHandlingWithDecode(error),
      }),
  });
