import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';
import * as uuid from 'uuid';

import { getActiveProjectId } from '../../store/reducers/ui';

import { RevenueLinkRequest } from '../../store/actions/analysis/revenueLinkage';
import { AnalysisRow } from '../../store/actions/analysis/row';

import { Thunk } from '../../utils/thunk';

import UnlinkIconButton from '../UnlinkIconButton';

type RowProps = {
  analysisRow: AnalysisRow;
  linkedEntityId: string;
  existingLinks: string[];
  // close: () => void | undefined;
  linker: (params: RevenueLinkRequest) => Thunk;
  unlinker: (params: RevenueLinkRequest) => Thunk;
};

const Row = ({
  analysisRow: { id, code, value },
  linkedEntityId,
  existingLinks,
  // close,
  linker,
  unlinker,
}: RowProps) => {
  const projectId = useSelector(getActiveProjectId) ?? '';
  const [requestId] = React.useState(uuid.v4());

  const isLinked = existingLinks.includes(id);

  const dispatch = useDispatch();

  const link = () => {
    if (!isLinked) {
      dispatch(
        linker({
          projectId,
          requestId,
          entityId: linkedEntityId,
          analysisRowId: id,
        })
      );
    }
  };

  const stopPropagation = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    link();
  };

  const unLink = () => {
    if (isLinked) {
      dispatch(
        unlinker({
          projectId,
          requestId,
          entityId: linkedEntityId,
          analysisRowId: id,
        })
      );
    }
  };

  return (
    <Container
      data-testid="analysis-link-container"
      onClick={link}
      tabIndex={0}
      onKeyPress={stopPropagation}
    >
      <Code>{code}</Code>
      <Text>{value}</Text>
      {isLinked ? (
        <UnlinkIconButton
          onClick={unLink}
          testId={`unlink-analysis-row-${id}`}
        />
      ) : null}
    </Container>
  );
};

export default Row;

const Container = styled.div`
  border: none;

  padding-right: ${(props) => props.theme.margin[16]};
  padding-left: ${(props) => props.theme.margin[24]};

  display: grid;
  align-items: center;

  background: ${({ theme: { color } }) => color.white};

  font-family: 'Graphik', sans-serif;
  font-size: 0.75rem;
  font-weight: 400;

  grid-template-columns: 1fr 4fr 1fr;
  grid-template-rows: 2.5rem;

  /* stylelint-disable selector-max-class -- The classname is styling an svg path
  that has this classname, and since there are multiple paths inside the same
  sgv (unlinked.svg), using classnames was the most effective way to style it */
  &:hover .separator {
    opacity: 1;
    transition: opacity 0.5s ease-out;
  }

  &:hover {
    background: ${({ theme: { color } }) => color.toolbarHighlight};
  }
`;

const Code = styled.span`
  width: 4.5rem;
  text-align: left;
`;

const Text = styled.span`
  max-height: 2.5rem;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
`;
