import React, { useEffect, useMemo, useState } from 'react';

import { Field, FieldProps, FormikErrors, getIn } from 'formik';
import { orderBy } from 'lodash';
import styled from 'styled-components';

import { getAnalysisRowsForProject } from '../../../../../store/reducers/analysis/row';
import { getProjectOrders } from '../../../../../store/reducers/order/order';
import { getOrderTopics } from '../../../../../store/reducers/topic';
import { DraftTargetRow } from '../../../../../store/reducers/ui';
import { getProjectWorkPackages } from '../../../../../store/reducers/workPackage';

import {
  fetchOrdersForProject,
  fetchTopicsForOrder,
  fetchWorkPackagesForProject,
  requestAnalysisRows,
} from '../../../../../store/actions';

import useRemoteData from '../../../../../hooks/useRemoteData';
import useRouteParams from '../../../../../hooks/useRouteParams';
import useTxt from '../../../../../hooks/useTxt';

import { IconButton } from '../../../../../components/Buttons';
import Cell from '../../../../../components/Cell';
import DropDownSelect from '../../../../../components/DropDownSelect';
import Checkbox from '../../../../../components/Input/Checkbox';
import TableTextInput from '../../../../../components/Input/TableTextInput';
import { BaseRow } from '../../../../../components/Table';

import { isDefined } from '../../../../../utils/general';

import { IconDelete } from '../../../../../assets/svg';

import theme from '../../../../../styles/theme';

type TargetRowProps = {
  row: DraftTargetRow;
  index: number;
  errors: FormikErrors<{ rows: DraftTargetRow[] }>;
  isDeleteDisabled: boolean;
  onChange: (e: React.ChangeEvent<any>) => void;
  onRemove: (index: number) => void;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
};

export const TargetRow = (props: TargetRowProps) => {
  const {
    row,
    index,
    onChange,
    onRemove,
    setFieldValue,
    errors,
    isDeleteDisabled,
    ...inputFieldProps
  } = props;

  const { projectId } = useRouteParams();

  const [selectedOrderId, setSelectedOrderId] = useState(row.orderId!);

  const orders =
    useRemoteData(
      getProjectOrders(projectId),
      fetchOrdersForProject(projectId)
    ) ?? [];

  const sortedOrders = orderBy(orders, ['visibleCode'], ['desc']);

  const workPackages =
    useRemoteData(
      getProjectWorkPackages(projectId),
      fetchWorkPackagesForProject(projectId)
    ) ?? [];

  const topics =
    useRemoteData(
      getOrderTopics(selectedOrderId),
      fetchTopicsForOrder(selectedOrderId),
      selectedOrderId !== ''
    )?.filter(isDefined) ?? [];

  const defaultTopicId =
    topics.find(({ defaultTopic }) => defaultTopic)?.id ?? '';

  const onOrderSelect = (order: string) => {
    setSelectedOrderId(order);
  };

  const topicIdInOptions = topics
    .map((topic) => topic.id)
    .concat([''])
    .includes(row.topicId);

  const topicIdInOptionsMemo = useMemo(
    () => topicIdInOptions,
    [topicIdInOptions]
  );

  // Ensure topics and default topic have resolved and manually set selected topic
  // prevent preselected topicId (copied from previous row) to be changed automatically to default
  useEffect(() => {
    if (defaultTopicId !== '' && !topicIdInOptionsMemo) {
      setFieldValue(`rows.${index}.topicId`, defaultTopicId);
    }
  }, [setFieldValue, index, defaultTopicId, topicIdInOptionsMemo]);

  const analysisRows =
    useRemoteData(
      getAnalysisRowsForProject(projectId),
      requestAnalysisRows({ projectId })
    ) ?? [];

  const descriptionPh = useTxt(
    'order.addTargetRowsModal.placeholder.description'
  );

  const quantityPh = useTxt('order.addTargetRowsModal.placeholder.quantity');
  const unitPh = useTxt('order.addTargetRowsModal.placeholder.unit');
  const unitPricePh = useTxt('order.addTargetRowsModal.placeholder.unitPrice');
  const orderPh = useTxt('order.addTargetRowsModal.placeholder.order');
  const topicPh = useTxt('order.addTargetRowsModal.placeholder.topic');
  const analysisPh = useTxt('order.addTargetRowsModal.placeholder.analysis');

  return (
    <BaseRow data-testid={`new-target-row-${index}`}>
      <PaddedCell>
        <Field name="description">
          {(fieldProps: FieldProps<string>) => (
            <Input
              {...inputFieldProps}
              {...fieldProps.field}
              name={`rows.${index}.description`}
              onChange={onChange}
              invalid={Boolean(getIn(errors, `rows.${index}.description`))}
              placeholder={descriptionPh}
              value={row.description}
            />
          )}
        </Field>
      </PaddedCell>
      <PaddedCell>
        <Field name="quantity">
          {(fieldProps: FieldProps<string>) => (
            <Input
              {...inputFieldProps}
              {...fieldProps.field}
              name={`rows.${index}.quantity`}
              onChange={onChange}
              invalid={Boolean(getIn(errors, `rows.${index}.quantity`))}
              placeholder={quantityPh}
              value={(row.quantity || '').toString()}
            />
          )}
        </Field>
      </PaddedCell>
      <PaddedCell>
        <Field name="unit">
          {(fieldProps: FieldProps<string>) => (
            <Input
              {...inputFieldProps}
              {...fieldProps.field}
              name={`rows.${index}.unit`}
              onChange={onChange}
              invalid={Boolean(getIn(errors, `rows.${index}.unit`))}
              placeholder={unitPh}
              value={row.unit}
            />
          )}
        </Field>
      </PaddedCell>
      <PaddedCell>
        <Field name="unitPrice">
          {(fieldProps: FieldProps<string>) => (
            <Input
              {...inputFieldProps}
              {...fieldProps.field}
              name={`rows.${index}.unitPrice`}
              onChange={onChange}
              invalid={Boolean(getIn(errors, `rows.${index}.unitPrice`))}
              placeholder={unitPricePh}
              value={(row.unitPrice || '').toString()}
            />
          )}
        </Field>
      </PaddedCell>
      <PaddedCell>
        <Field name="orderId">
          {(fieldProps: FieldProps<string>) => (
            <DropDownSelect
              {...fieldProps.field}
              required
              invalid={Boolean(getIn(errors, `rows.${index}.orderId`))}
              additionalStyles={additionalStyling}
              additionalContainerStyles={additionalContainerStyling}
              optionFilterProp="title"
              value={row.orderId && row.orderId !== '' ? row.orderId : 'NONE'}
              onChange={(e) => {
                const selection = e === 'NONE' ? '' : e;
                setFieldValue(`rows.${index}.orderId`, selection);
                onOrderSelect(selection);
              }}
              options={[
                {
                  key: 'NONE',
                  value: 'NONE',
                  title: orderPh,
                  label: orderPh,
                },
                ...sortedOrders.map((item) => ({
                  key: item.id,
                  value: item.id,
                  title: `${item.visibleCode} ${item.name} ${item.contractor}`,
                  label: (
                    <NowrapParagraph>
                      <DescriptionText className="order-description">{`${item.visibleCode} ${item.name}`}</DescriptionText>
                      <ExtraInfoText className="order-extra-info">
                        {item.contractor ? ` ${item.contractor}` : null}
                      </ExtraInfoText>
                    </NowrapParagraph>
                  ),
                })),
              ]}
            />
          )}
        </Field>
      </PaddedCell>

      <PaddedCell>
        <Field name="topicId">
          {(fieldProps: FieldProps<string>) => (
            <DropDownSelect
              {...fieldProps.field}
              required
              invalid={Boolean(getIn(errors, `rows.${index}.topicId`))}
              additionalStyles={additionalStyling}
              additionalContainerStyles={additionalContainerStyling}
              value={row.topicId && row.topicId !== '' ? row.topicId : 'NONE'}
              disabled={selectedOrderId === ''}
              optionFilterProp="title"
              onChange={(e) => {
                const selection = e === 'NONE' ? '' : e;
                setFieldValue(`rows.${index}.topicId`, selection);
              }}
              options={[
                {
                  key: 'NONE',
                  value: 'NONE',
                  title: topicPh,
                  label: topicPh,
                },
                ...topics.map((item) => {
                  const linkedWorkPackage = workPackages.filter(
                    (workPackage) => workPackage.id === item.workPackageId
                  )[0];

                  const workPackageCodeName = `${linkedWorkPackage.code} ${linkedWorkPackage.name}`;

                  return {
                    key: item.id,
                    value: item.id,
                    title: `${item.name} ${workPackageCodeName}`,
                    label: (
                      <NowrapParagraph>
                        <DescriptionText className="topic-description">
                          {item.name.length > 0 ? item.name : '-'}
                        </DescriptionText>
                        <ExtraInfoText className="topic-extra-info">
                          {workPackageCodeName}
                        </ExtraInfoText>
                      </NowrapParagraph>
                    ),
                  };
                }),
              ]}
            />
          )}
        </Field>
      </PaddedCell>
      <PaddedCell>
        <Field name="analysisId">
          {(fieldProps: FieldProps<string>) => (
            <DropDownSelect
              {...fieldProps.field}
              invalid={Boolean(getIn(errors, `rows.${index}.analysisId`))}
              additionalStyles={additionalStyling}
              additionalContainerStyles={additionalContainerStyling}
              value={
                row.analysisId && row.analysisId !== ''
                  ? row.analysisId
                  : 'NONE'
              }
              onChange={(e) => {
                const selection = e === 'NONE' ? '' : e;
                setFieldValue(`rows.${index}.analysisId`, selection);
              }}
              options={[
                {
                  key: 'NONE',
                  value: 'NONE',
                  label: analysisPh,
                },
                ...analysisRows.map((item) => ({
                  key: item.id,
                  value: item.id,
                  label: `${item.code}`,
                })),
              ]}
            />
          )}
        </Field>
      </PaddedCell>
      <PaddedCell>
        <Field name="createAlsoOrder">
          {(fieldProps: FieldProps<string>) => (
            <Checkbox
              {...inputFieldProps}
              {...fieldProps.field}
              onChange={onChange}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  e.stopPropagation();
                  e.preventDefault();
                  onChange(e);
                }
              }}
              name={`rows.${index}.createAlsoOrder`}
              checked={row.createAlsoOrder || false}
            />
          )}
        </Field>
      </PaddedCell>
      <PaddedCell>
        <IconButton
          icon={IconDelete}
          type="button"
          onClick={() => onRemove(index)}
          disabled={isDeleteDisabled}
        />
      </PaddedCell>
    </BaseRow>
  );
};

const PaddedCell = styled(Cell)`
  padding: ${(props) => props.theme.margin[4]};
`;

const Input = styled(TableTextInput)`
  border-radius: ${(props) => props.theme.margin[4]};
  padding: 0 ${(props) => props.theme.margin[16]};
  height: ${(props) => props.theme.margin[40]};

  ::placeholder {
    opacity: 0.5;
    text-align: left;
  }
`;

const additionalStyling: React.CSSProperties = {
  borderRadius: theme.margin[4],
  background: theme.color.dropdownBg,
  fontSize: theme.fontSize.base,
  appearance: 'none',
  width: '100%',
};

const additionalContainerStyling: React.CSSProperties = {
  padding: `${theme.margin[4]} ${theme.margin[4]} ${theme.margin[4]} ${theme.margin[16]}`,
  height: theme.margin[40],
  backgroundColor: 'transparent',
  margin: `${theme.margin[4]} 0 ${theme.margin[4]} ${theme.margin[16]}`,
  width: '100%',
};

const NowrapParagraph = styled.p`
  white-space: no-wrap;
  margin-block-start: 0;
  margin-block-end: 0;
`;

const ExtraInfoText = styled.span`
  margin-left: ${({ theme: { margin } }) => margin[4]};
  border-left: 1px solid ${(props) => props.theme.color.graphiteB76};
  padding: 0 ${(props) => props.theme.margin[4]};
  color: ${(props) => props.theme.color.graphiteB57};
`;

const DescriptionText = styled.span`
  padding: 0 ${(props) => props.theme.margin[4]} 0 0;
`;
