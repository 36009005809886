import {
  ID,
  ReceiveAmountState,
  SelectedArrivalRowsState,
  AllOrderRowsSelectionPayload,
  OrderRowSelectionPayload,
  OuterBarState,
  PaymentProgramRowSelectionPayload,
  AllPaymentProgramRowsSelectionPayload,
} from '../../types/general';
import { SelectOption as Column } from '../../types/ui';

import { makeAction, ExtractActionTypes } from '../../utils/actionCreators';

// TODO FIXME Do not use setters with redux.
export type UIAction = ExtractActionTypes<typeof actionCreators>;
export const actionCreators = {
  ...makeAction('updateProjectColumns')<Column>(),
  ...makeAction('updateWorkSectionViewColumns')<Column>(),
  ...makeAction('uiStateProcurementAreaOpened')<ID>(),
  ...makeAction('uiStateProcurementAreaClosed')<ID>(),
  ...makeAction('uiStateOpenSeveralProcurementAreas')<ID[]>(),
  ...makeAction('uiStateToggleBillingProcurementAreas')(),
  ...makeAction('uiStateCloseAllProcurementAreas')<ID>(),
  ...makeAction('setLastActiveProcurementArea')<ID>(),
  ...makeAction('uiStateWorkPackageGroupOpened')<ID>(),
  ...makeAction('uiStateWorkPackageGroupClosed')<ID>(),
  ...makeAction('uiStateOpenSeveralWorkPackageGroups')<ID[]>(),
  ...makeAction('uiStateCloseAllWorkPackageGroups')<ID>(),
  ...makeAction('setLastActiveWorkPackageGroup')<ID>(),
  ...makeAction('uiStateToggleOpenAllWorkSections')(),
  ...makeAction('uiStateToggleOpenAllTopics')<ID[]>(),
  ...makeAction('uiTopicToggled')<ID>(),
  ...makeAction('uiSnapshotToggled')<ID>(),
  ...makeAction('setActiveProject')<ID>(),
  ...makeAction('setOrderStatusToggled')<ID>(),
  ...makeAction('saveReceiveAmountState')<{
    orderId: ID;
    state: ReceiveAmountState;
  }>(),
  ...makeAction('clearReceiveAmountState')<ID>(),
  ...makeAction('selectArrivalRows')<SelectedArrivalRowsState>(),
  ...makeAction('setOuterBarState')<OuterBarState | null>(),
  ...makeAction('orderRowSelectionToggled')<OrderRowSelectionPayload>(),
  ...makeAction('allOrderRowsSelectionToggled')<AllOrderRowsSelectionPayload>(),
  ...makeAction(
    'paymentProgramRowSelectionToggled'
  )<PaymentProgramRowSelectionPayload>(),
  ...makeAction(
    'allPaymentProgramRowsSelectionToggled'
  )<AllPaymentProgramRowsSelectionPayload>(),
  ...makeAction('createDraftTargetRowInStore')<{
    orderId: string;
    defaultTopicId: string;
    rowId?: string;
  }>(),
  ...makeAction('updateDraftOrderChatMessage')<{
    orderId: string;
    message: string;
  }>(),
  ...makeAction('draftTargetRowChanged')<{
    orderId: ID;
    rowId: ID;
    fieldName: string;
    fieldValue: string;
  }>(),
  ...makeAction('uiStateTargetViewSearch')<{
    searchString: string;
    targetRowIds?: string[];
  }>(),
};
export const {
  updateProjectColumns,
  updateWorkSectionViewColumns,
  uiStateProcurementAreaOpened,
  uiStateProcurementAreaClosed,
  uiStateOpenSeveralProcurementAreas,
  uiStateToggleBillingProcurementAreas,
  uiStateCloseAllProcurementAreas,
  setLastActiveProcurementArea,
  uiStateWorkPackageGroupOpened,
  uiStateWorkPackageGroupClosed,
  uiStateOpenSeveralWorkPackageGroups,
  uiStateCloseAllWorkPackageGroups,
  uiStateToggleOpenAllWorkSections,
  setLastActiveWorkPackageGroup,
  uiTopicToggled,
  uiSnapshotToggled,
  setActiveProject,
  saveReceiveAmountState,
  clearReceiveAmountState,
  selectArrivalRows,
  setOuterBarState,
  orderRowSelectionToggled,
  paymentProgramRowSelectionToggled,
  allOrderRowsSelectionToggled,
  allPaymentProgramRowsSelectionToggled,
  createDraftTargetRowInStore,
  draftTargetRowChanged,
  uiStateTargetViewSearch,
  updateDraftOrderChatMessage,
  uiStateToggleOpenAllTopics,
  setOrderStatusToggled,
} = actionCreators;
