import React from 'react';

import styled, { StyledComponent, DefaultTheme, css } from 'styled-components';

import { SelectOption } from '../../types/ui';

import useOnClickOutside from '../../hooks/useOnClickOutside';
import useTxt from '../../hooks/useTxt';

import CheckboxComponent from '../Input/Checkbox';
import Tooltip from '../Tooltip';
import Txt from '../Txt';

interface MultiSelectProps {
  toggleShow: () => void;
  listItems: { title?: string; listItemsArray: SelectOption[] };
  onChange: (option: SelectOption) => void;
  container?: StyledComponent<'div', DefaultTheme, {}, never>;
  noListBorder?: boolean;
}

export default function MultiSelect({
  onChange,
  toggleShow,
  listItems,
  container: ContentContainer = DefaultContainer,
  noListBorder,
}: MultiSelectProps) {
  const ref = useOnClickOutside(() => {
    toggleShow();
  });

  const onKeyPress = (e: KeyboardEvent) => {
    if (e.code === 'Escape') {
      toggleShow();
    }
  };

  React.useEffect(() => {
    window.addEventListener('keyup', onKeyPress);

    return () => {
      window.removeEventListener('keyup', onKeyPress);
    };
  });

  const { listItemsArray, title } = listItems;

  return (
    <ContentContainer ref={ref}>
      {title ? <Title>{title}</Title> : null}
      <List noBorder={noListBorder}>
        {listItemsArray.map((item) => Item(item, onChange))}
      </List>
    </ContentContainer>
  );
}

const Item = (item: SelectOption, onChange: (option: SelectOption) => void) => {
  const dataCy = useTxt(item.name);

  const tip = useTxt(item.tooltipInfo);

  return (
    <StyledDiv
      key={item.id}
      disable={item.disabled}
      data-cy="column-settings-container"
    >
      <CheckboxComponent
        checked={item.selected}
        onChange={(e) => onChange({ ...item, selected: e.target.checked })}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            e.stopPropagation();
            e.preventDefault();
            onChange({ ...item, selected: !item.selected });
          }
        }}
        disabled={item.disabled}
        data-cy={dataCy}
      />
      <Label>
        {item.tooltipInfo ? (
          <Tooltip tip={tip}>
            <Txt id={item.name} />
          </Tooltip>
        ) : (
          <Txt id={item.name} />
        )}
      </Label>
    </StyledDiv>
  );
};

const DefaultContainer = styled.div`
  position: absolute;
  right: ${(props) => props.theme.margin[2]};

  border: 1px solid lightgrey;
  border-radius: 5px 0 5px 2px;
  box-shadow: 5px 7px 11px -12px ${(props) => props.theme.color.black};

  min-width: 16rem;

  background: ${(props) => props.theme.color.graphiteB96A};
`;

const Title = styled.h3`
  margin: 15px 15px;
  font-weight: 500;
`;

type ListProps = {
  noBorder?: boolean;
};

const List = styled.div<ListProps>`
  position: relative;

  ${(props) =>
    props.noBorder
      ? ''
      : css`
          border-top: 1px solid lightgrey;
          border-radius: 2px 2px 5px 5px;
        `}

  padding: 0.75rem;
  padding-bottom: 1.5rem;
  background: ${(props) => props.theme.color.graphiteB99};
`;

type StyleDisable = {
  disable?: boolean;
};

const StyledDiv = styled.div<StyleDisable>`
  padding: 0.5rem;
  display: flex;
  column-gap: 7px;
  &:hover {
    background: ${({ disable }) => (!disable ? '#f2f2f2' : false)};
  }
`;

const Label = styled.h3`
  font-weight: 500;
  font-size: ${(props) => props.theme.fontSize.base};
  white-space: nowrap;
  text-overflow: ellipsis;

  overflow: hidden;
`;
