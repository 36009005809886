import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useFormik, FormikErrors } from 'formik';
import styled from 'styled-components';

import {
  getSuppliers,
  getVatCodes,
  getCostTypes,
  getAccounts,
  getStatuses,
  getDisallowedCombinations,
} from '../../../../store/reducers/order/options';
import { getOrderById } from '../../../../store/reducers/order/order';

import { editOrder } from '../../../../store/actions';
import {
  getDropDowns,
  Supplier,
} from '../../../../store/actions/order/options';

import { APIOrderPutBody } from '../../../../types/api';

import useRemoteData from '../../../../hooks/useRemoteData';
import useTxt from '../../../../hooks/useTxt';

import {
  CenteredButtonGroup,
  SecondaryButton,
  PrimaryButton,
} from '../../../../components/Buttons';
import { InputContainer } from '../../../../components/Containers';
import DropDownSelect from '../../../../components/DropDownSelect';
import { SelectLabel, Select } from '../../../../components/Input/Select';
import TextInput from '../../../../components/Input/TextInput';
import { Spinner } from '../../../../components/Loading';
import RemoteData from '../../../../components/RemoteData';
import Txt from '../../../../components/Txt';

import { nullifyIfEmpty } from '../../../../utils/format';
import { filterDisallowedCombinations } from '../../../../utils/general';

import theme from '../../../../styles/theme';

type Props = {
  orderId: string;
  onClose: () => void;
  isSubmitting: (formikSubmitState: boolean) => void;
};

const EditOrderForm = ({ orderId, onClose, isSubmitting }: Props) => {
  const dispatch = useDispatch();

  const order = useSelector(getOrderById(orderId));
  const remoteSupplierOptions = useSelector(getSuppliers);
  const remoteVatCodeOptions = useSelector(getVatCodes);
  const remoteCostTypeOptions = useSelector(getCostTypes);
  const remoteAccountOptions = useSelector(getAccounts);
  const remoteStatusOptions = useSelector(getStatuses);

  const vatCodeOptions = useRemoteData(getVatCodes, getDropDowns) ?? [];
  const costTypeOptions = useRemoteData(getCostTypes, getDropDowns) ?? [];
  const accountOptions = useRemoteData(getAccounts, getDropDowns) ?? [];

  const disallowedCombinations =
    useRemoteData(getDisallowedCombinations, getDropDowns) ?? [];

  type FormValues = {
    name: string;
    vatCodeId: string;
    supplierId: string;
    costTypeId: string;
    accountCodeId: string;
    statusId: string;
  };

  type NameErrorTextId =
    | 'order.options.formik.error.name.length'
    | 'order.options.formik.error.name.mandatory';

  function isNameErrorTextId(
    value: string | undefined
  ): value is NameErrorTextId {
    return [
      'order.options.formik.error.name.length',
      'order.options.formik.error.name.mandatory',
    ].includes(value as NameErrorTextId);
  }

  const validate = (values: FormValues): FormikErrors<FormValues> => {
    const errors: FormikErrors<FormValues> = {};

    if (values.name === '') {
      errors.name = 'order.options.formik.error.name.mandatory';
    }

    if (values.name.length > 200) {
      errors.name = 'order.options.formik.error.name.length';
    }

    return errors;
  };

  const onEditSuccess = () => {
    onClose();
  };

  const formik = useFormik<FormValues>({
    initialValues: {
      name: order?.name ? order.name : '',
      vatCodeId: order?.vatCodeId ? order?.vatCodeId : '',
      supplierId: order?.supplierId ? order?.supplierId : '',
      costTypeId: order?.costTypeId ? order?.costTypeId : '',
      accountCodeId: order?.accountCodeId ? order?.accountCodeId : '',
      statusId: order?.statusId || '1',
    },
    validate,
    onSubmit: (values) => {
      const body: APIOrderPutBody = {
        name: values.name,
        vatCodeId: nullifyIfEmpty(values.vatCodeId),
        supplierId: nullifyIfEmpty(values.supplierId),
        costTypeId: nullifyIfEmpty(values.costTypeId),
        accountCodeId: nullifyIfEmpty(values.accountCodeId),
        updatedAt: new Date().toJSON(),
        contractor: null,
        statusId: values.statusId,
      };

      dispatch(editOrder(orderId, body, onEditSuccess));
    },
  });

  useEffect(() => {
    isSubmitting(formik.isSubmitting);
  }, [isSubmitting, formik.isSubmitting]);

  const chooseText = useTxt('common.choose');
  const nameText = useTxt('order.options.name');

  const nameErrorId = isNameErrorTextId(formik.errors.name)
    ? formik.errors.name
    : undefined;

  const nameError = useTxt(nameErrorId);

  const nameErrorText = nameError.length === 0 ? null : nameError;

  const onSupplierChange = (value: string, suppliers: Supplier[]) => {
    const selectedSupplier = suppliers.find(
      (supplier) => supplier.id === value
    );

    if (!selectedSupplier) {
      return formik.setFieldValue('supplierId', value);
    }

    const { defaultVatCode, defaultAccountCode, defaultCostType } =
      selectedSupplier;

    const costTypeId = costTypeOptions.find(
      (costType) => costType.externalCode === defaultCostType
    )?.id;

    const accountCodeId = accountOptions.find(
      (account) => account.externalCode === defaultAccountCode
    )?.id;

    const vatCodeId = vatCodeOptions.find(
      (vatCode) => vatCode.externalCode === defaultVatCode
    )?.id;

    if (costTypeId) {
      formik.setFieldValue('costTypeId', costTypeId);
    }

    if (accountCodeId) {
      formik.setFieldValue('accountCodeId', accountCodeId);
    }

    if (vatCodeId) {
      formik.setFieldValue('vatCodeId', vatCodeId);
    }

    return formik.setFieldValue('supplierId', value);
  };

  const selections = {
    ...(formik.values.vatCodeId ? { vatCodeId: formik.values.vatCodeId } : {}),
    ...(formik.values.accountCodeId
      ? { accountId: formik.values.accountCodeId }
      : {}),
  };

  return (
    <StyledForm name="edit-order-form" onSubmit={formik.handleSubmit}>
      <InputContainer>
        <TextInput
          label={nameText}
          name="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          disabled={formik.isSubmitting}
          errorMessage={nameErrorText}
        />

        <SelectLabel htmlFor="supplier-select">
          <Txt id="order.options.supplier" component="b" />
          <RemoteData data={remoteSupplierOptions} fetchData={getDropDowns}>
            {(suppliers) => (
              <>
                <DropDownSelect
                  additionalStyles={additionalStyling}
                  additionalContainerStyles={additionalContainerStyling}
                  defaultValue={formik.values.supplierId}
                  onChange={(value) => {
                    const selection = value === 'NONE' ? '' : value;
                    onSupplierChange(selection, suppliers);
                  }}
                  disabled={formik.isSubmitting}
                  id="supplier-select"
                  options={[
                    {
                      key: 'NONE',
                      value: 'NONE',
                      label: chooseText,
                    },
                    ...suppliers.map((s) => ({
                      key: s.id,
                      value: s.id,
                      label: `${s.name} (${s.businessId})`,
                    })),
                  ]}
                />
                {formik.errors.supplierId ? (
                  <div className="error">{formik.errors.supplierId}</div>
                ) : null}
              </>
            )}
          </RemoteData>
        </SelectLabel>

        <SelectLabel htmlFor="cost-type-select">
          <Txt id="order.options.costType" component="b" />
          <RemoteData data={remoteCostTypeOptions} fetchData={getDropDowns}>
            {(costTypes) => {
              return (
                <Select
                  name="costTypeId"
                  value={formik.values.costTypeId}
                  onChange={formik.handleChange}
                  disabled={formik.isSubmitting}
                  id="cost-type-select"
                >
                  <option value="">{chooseText}</option>
                  {costTypes.map((ct) => (
                    <option key={ct.id} value={ct.id}>
                      {ct.name}
                    </option>
                  ))}
                </Select>
              );
            }}
          </RemoteData>
        </SelectLabel>

        <SelectLabel htmlFor="vat-code-select">
          <Txt id="order.options.vatCode" component="b" />
          <RemoteData data={remoteVatCodeOptions} fetchData={getDropDowns}>
            {(vatCodes) => {
              const filteredVatCodes = filterDisallowedCombinations(
                vatCodes,
                'vatCodeId',
                disallowedCombinations,
                selections
              );

              return (
                <Select
                  name="vatCodeId"
                  value={formik.values.vatCodeId}
                  onChange={formik.handleChange}
                  disabled={formik.isSubmitting}
                  id="vat-code-select"
                >
                  <option value="">{chooseText}</option>
                  {filteredVatCodes.map((code) => (
                    <option key={code.id} value={code.id}>
                      {`${code.externalCode} - ${code.name}`}
                    </option>
                  ))}
                </Select>
              );
            }}
          </RemoteData>
        </SelectLabel>

        <SelectLabel htmlFor="account-select">
          <Txt id="order.options.account" component="b" />
          <RemoteData data={remoteAccountOptions} fetchData={getDropDowns}>
            {(accounts) => {
              const filteredAccounts = filterDisallowedCombinations(
                accounts,
                'accountId',
                disallowedCombinations,
                selections
              );

              return (
                <Select
                  name="accountCodeId"
                  value={formik.values.accountCodeId}
                  onChange={formik.handleChange}
                  disabled={formik.isSubmitting}
                  id="account-select"
                >
                  <option value="">{chooseText}</option>
                  {filteredAccounts.map((acc) => (
                    <option key={acc.id} value={acc.id}>
                      {`${acc.externalCode} - ${acc.name}`}
                    </option>
                  ))}
                </Select>
              );
            }}
          </RemoteData>
        </SelectLabel>

        <SelectLabel htmlFor="status-select">
          <Txt id="order.options.status" component="b" />
          <RemoteData data={remoteStatusOptions} fetchData={getDropDowns}>
            {(statuses) => (
              <Select
                name="statusId"
                value={formik.values.statusId}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
                id="status-select"
              >
                {statuses.map(({ id, name }) => (
                  <option key={id} value={id}>
                    {name}
                  </option>
                ))}
              </Select>
            )}
          </RemoteData>
        </SelectLabel>
      </InputContainer>

      <CenteredButtonGroup>
        <SecondaryButton type="button" onClick={onClose}>
          <Txt id="common.cancel" />
        </SecondaryButton>
        <PrimaryButton type="submit" disabled={formik.isSubmitting}>
          {formik.isSubmitting ? (
            <Spinner size="1rem" light />
          ) : (
            <Txt id="common.save" />
          )}
        </PrimaryButton>
      </CenteredButtonGroup>
    </StyledForm>
  );
};

export default EditOrderForm;

const StyledForm = styled.form`
  margin-bottom: ${(props) => props.theme.margin[16]};
`;

const additionalStyling: React.CSSProperties = {
  marginTop: theme.margin[8],
  marginBottom: theme.margin[20],
  borderRadius: theme.margin[4],
  background: theme.color.dropdownBg,
  fontSize: theme.fontSize.base,
  appearance: 'none',
};

const additionalContainerStyling: React.CSSProperties = {
  padding: `${theme.margin[4]} ${theme.margin[4]} ${theme.margin[4]} ${theme.margin[16]}`,
  height: theme.margin[36],
  backgroundColor: 'transparent',
  margin: `${theme.margin[4]} 0 ${theme.margin[4]} ${theme.margin[16]}`,
};
