import React from 'react';

import useTxt from '../../hooks/useTxt';

import { AuthTitle } from '../../components/Auth';
import AuthContainer from '../../components/Auth/AuthPageContainer';

function EmailSentPage() {
  const emailSentText = useTxt('auth.email.signIn.emailSent');

  return (
    <AuthContainer>
      <AuthTitle>{emailSentText}</AuthTitle>
    </AuthContainer>
  );
}

export default EmailSentPage;
