import { Reducer } from 'redux';

import * as remoteData from '../../../utils/remoteData';

import { AppState } from '..';
import { AnalysisSelectListItem } from '../../actions/analysis';
import { ActionTypes } from '../../actionTypes';

export type AnalysisSelectListItemState = Record<
  string,
  remoteData.RemoteData<AnalysisSelectListItem[]>
>;

const initialState: AnalysisSelectListItemState = {};

export const selectListItemReducer: Reducer<
  AnalysisSelectListItemState,
  ActionTypes
> = (state = initialState, action): AnalysisSelectListItemState => {
  switch (action.type) {
    case 'GET_ANALYSIS_SELECT_LIST_ITEMS_STARTED': {
      const { projectId } = action.payload;

      return {
        ...state,
        [projectId]: remoteData.loading,
      };
    }
    case 'GET_ANALYSIS_SELECT_LIST_ITEMS_FAILURE': {
      const { projectId, error } = action.payload;

      return {
        ...state,
        [projectId]: remoteData.fail(error),
      };
    }
    case 'GET_ANALYSIS_SELECT_LIST_ITEMS_SUCCESS': {
      const { projectId, analysisSelectListItems } = action.payload;

      return {
        ...state,
        [projectId]: remoteData.succeed(analysisSelectListItems),
      };
    }
    default: {
      return state;
    }
  }
};

export default selectListItemReducer;

export const getAnalysisSelectListItemsForProject =
  (projectId: string) =>
  ({
    analysis: {
      selectListItems: {
        [projectId]: analysisSelectListItems = remoteData.notAsked,
      },
    },
  }: AppState): remoteData.RemoteData<AnalysisSelectListItem[]> =>
    analysisSelectListItems;

type GetSelectListItemsOptions = {
  projectId: string;
  attributeId: string;
};

export const getAnalysisSelectListItemsForAttribute =
  ({ projectId, attributeId }: GetSelectListItemsOptions) =>
  (appState: AppState): remoteData.RemoteData<AnalysisSelectListItem[]> => {
    const projectSelectListItems =
      getAnalysisSelectListItemsForProject(projectId)(appState);

    const filteredList = remoteData.map(projectSelectListItems, (items) =>
      items.filter((item) => item.attributeId === attributeId)
    );

    return filteredList;
  };
