import React from 'react';

/**
 * useStateRef is a hook that allows you to use a ref-like pattern with a state
 * variable. This is useful when you need to keep track of a value that is
 * derived from a DOM node, but you don't want to use a ref.
 *
 * @param processNode A function that processes a node and returns a new state
 * @returns A tuple containing the current state and a setter function
 */
function useStateRef<T>(processNode: (_: any) => T | null) {
  const [node, setNode] = React.useState<T | null>(null);

  const setRef = React.useCallback(
    (newNode) => {
      const newState = processNode(newNode);

      if (newState !== null && newState !== node) {
        setNode(newState);
      }
    },
    [processNode, node]
  );

  return [node, setRef] as const;
}

export default useStateRef;
