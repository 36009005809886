import React, { memo, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import useMeasure from 'react-use-measure';

import { Row } from '@tanstack/react-table';
import Big from 'big.js';
import styled, { css } from 'styled-components';

import useLocalization from '../../../../hooks/useLocalization';
import useTxt from '../../../../hooks/useTxt';
import { TargetRowOrTargetRowHierarchyEntry } from '../../hooks/useTargetViewData';

import ArrowIcon from '../../../../components/ArrowIcon';
import Checkbox from '../../../../components/Input/Checkbox';

import { priceFormat } from '../../../../utils/big';
import { isClickOrKeyboardSelection } from '../../../../utils/mouseOrKeyInteraction';

import {
  IconSplitRow,
  IconInvalid,
  IconCloseCircle,
  IconEdit,
  IconPlus,
  IconQuestionmark,
} from '../../../../assets';

import { isTextId } from '../../../../localization';

import { generateUrl, routes, useParams } from '../../../../routes';

import {
  dataFields,
  UpdateInformationTargetImportData,
  ValidatedTargetImportData,
} from '../TargetImportModal/utils';

import { TargetTableTooltipContext } from '.';

type TargetRowDescriptionProps = {
  row: Row<TargetRowOrTargetRowHierarchyEntry>;
  virtualRowStart: number | undefined;
};

export const TargetRowDescription = ({
  row,
  virtualRowStart,
}: TargetRowDescriptionProps) => {
  const { viewMode } = useParams(routes.TARGET);
  const history = useHistory();
  const value = row.original.description;

  const splitToTargetRowInfo = row.original.splitTo
    ?.filter((targetRow) => targetRow.isAntiRow === false)
    ?.map((targetRow) => {
      return `${targetRow.description} (${targetRow.orderVisibleCodeAndName}) ${priceFormat(targetRow.totalPrice, 0)}`;
    })
    .join(', \n');

  const splitTargetTip = useTxt(
    'order.targetMode.splitIcon.tooltip.splitFrom',
    {
      targetRowInfo: `${row.original.splitFrom?.referenceNumber ?? ''} ${
        row.original.splitFrom?.description
      } (${row.original.splitFrom?.orderVisibleCodeAndName}) ${priceFormat(row.original.splitFrom?.totalPrice ?? new Big(0), 0)}`,
    }
  );

  const linkedToOrderRowTip = useTxt(
    'target.table.updateProcurements.filterLinkedToOrderRows.toolTip'
  );

  const [isMouseHover, setIsMouseHover] = React.useState<undefined | boolean>();
  const [measureRef, measures] = useMeasure({ debounce: 100 });

  const tooltipContext = useContext(TargetTableTooltipContext);

  const { top, left, height, width, right, bottom } = measures;

  const disabledSplitTargetTip = useTxt(
    'order.targetMode.splitIcon.tooltip.disabled',
    { targetRowInfo: splitToTargetRowInfo ?? '' }
  );

  const navigateToSplitTargetView = () => {
    const splitIds: string[] = [row.original.originalId];

    const { projectId } = row.original;

    if (
      row.original.isSplitFrom &&
      row.original.isDisabled &&
      row.original.splitFrom &&
      row.original.splitTo
    ) {
      splitIds.push(
        row.original.splitFrom?.id,
        ...row.original.splitTo.map((targetRow) => targetRow.id)
      );
    } else if (row.original.isSplitFrom && row.original.splitFrom) {
      splitIds.push(row.original.splitFrom?.id);
    } else if (row.original.splitTo) {
      splitIds.push(...row.original.splitTo.map((targetRow) => targetRow.id));
    }

    if (splitIds.length > 0) {
      history.push(
        generateUrl({
          route: routes.TARGET_WITH_TARGET_ROW_FOCUSED,
          projectId,
          targetRowIds: splitIds.toString(),
        })
      );
    }
  };

  const onSplitIconPress = (e: React.KeyboardEvent | React.MouseEvent) => {
    e.stopPropagation();

    if (isClickOrKeyboardSelection(e)) {
      navigateToSplitTargetView();
    }
  };

  const tip = () => {
    const tipText: string[] = [];

    if (viewMode === 'edit' && row.original.orderRowId) {
      tipText.push(linkedToOrderRowTip);
    }

    if (row.original.isSplitFrom) {
      tipText.push(splitTargetTip);
    }

    if (row.original.isDisabled) {
      tipText.push(disabledSplitTargetTip);
    }

    return tipText.join(', \n');
  };

  return (
    <>
      <EmptyIconDiv depth={row.depth} />
      {row.getCanExpand() ? (
        <ArrowIcon
          isOpen={row.getIsExpanded()}
          openAltTextKey="target.table.hierarchies.open"
          closedAltTextKey="target.table.hierarchies.closed"
        />
      ) : (
        <EmptyIconDiv depth={1} />
      )}
      <InvalidIconAndTooltip
        row={row}
        keys={['description']}
        virtualRowStart={virtualRowStart}
      />
      <NoWrapSpan>{value}</NoWrapSpan>
      {row.original.isSplitFrom ||
      row.original.isDisabled ||
      (viewMode === 'edit' && row.original.orderRowId) ? (
        <StyledIcon
          src={
            row.original.isSplitFrom || row.original.isDisabled
              ? IconSplitRow
              : IconQuestionmark
          }
          onClick={onSplitIconPress}
          onKeyPress={onSplitIconPress}
          alt="split_icon"
          ref={measureRef}
          onMouseLeave={() => {
            if (isMouseHover === true) {
              tooltipContext.setToolTipProps(undefined);
            }
          }}
          onMouseEnter={() => {
            tooltipContext.setToolTipProps({
              parentMeasures: {
                top,
                left,
                height,
                width,
                right,
                bottom,
                virtualRowStart,
              },
              text: tip(),
              place: 'top',
            });
            setIsMouseHover(true);
          }}
        />
      ) : null}
    </>
  );
};

type EmptyIconDivProps = {
  depth: number;
};

const EmptyIconDiv = styled.div<EmptyIconDivProps>`
  width: ${({ depth }) => depth * 16}px;
  height: 16px;
  flex-shrink: 0;
`;

const StyledIcon = styled.img`
  margin: 0 ${({ theme }) => theme.margin[8]};

  height: 16px;
  width: 16px;

  flex-shrink: 0;

  cursor: pointer;
`;

const NoWrapSpan = styled.span`
  flex-shrink: 2;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

type IconProps = {
  fillColor?: string;
};

const Icon = styled.img<IconProps>`
  margin: 0 ${({ theme }) => theme.margin[8]};
  width: 16px;
  height: 16px;

  /* stylelint-disable selector-max-type -- svgs have to be styled with type */
  ${(props) =>
    props.fillColor
      ? css`
          & path {
            fill: ${props.fillColor};
          }
        `
      : null}
`;

export const InvalidIconAndTooltip = ({
  row,
  keys,
  virtualRowStart,
}: {
  row: Row<TargetRowOrTargetRowHierarchyEntry>;
  keys: (keyof ValidatedTargetImportData)[];
  virtualRowStart: number | undefined;
}) => {
  const validations = row.original.validationInformation;

  const localization = useLocalization();

  const invalidText = useTxt(
    'target.targetImportModal.previewTable.invalidTooltip'
  );

  const workPackageValidation = row.original.invalidWorkPackage;
  const orderValidation = row.original.invalidOrder;

  const validationsRelatedToKeys = validations
    ? keys
        .map((key) => ({
          translatedKey: localization.formatMessage({
            id: dataFields[key].translation,
          }),
          kind: 'Decoded',
          customMessage: undefined,
          invalidValue: undefined,
          ...validations[key],
        }))
        .filter(
          (validationResult) =>
            validationResult && validationResult.kind === 'Invalid'
        )
    : [];

  if (
    workPackageValidation &&
    workPackageValidation.kind === 'Invalid' &&
    keys.includes('workSectionCode')
  ) {
    validationsRelatedToKeys.push({
      ...workPackageValidation,
      translatedKey: localization.formatMessage({
        id: dataFields.workSectionCode.translation,
      }),
    });
  }

  if (
    orderValidation &&
    orderValidation.kind === 'Invalid' &&
    keys.includes('procurementCode')
  ) {
    validationsRelatedToKeys.push({
      ...orderValidation,
      translatedKey: localization.formatMessage({
        id: dataFields.procurementCode.translation,
      }),
    });
  }

  if (row.original.invalid && validationsRelatedToKeys.length > 0) {
    const invalidValues = validationsRelatedToKeys.map((validation) => {
      let translatedCustomMessage = '';

      if (validation.kind === 'Invalid') {
        const { customMessage } = validation;

        if (customMessage) {
          translatedCustomMessage = isTextId(customMessage)
            ? ` ${localization.formatMessage({
                id: customMessage,
              })}`
            : ` ${customMessage}`;
        }
      }

      return `${validation.translatedKey}: ${String(
        validation.kind === 'Invalid' ? validation.invalidValue : ''
      )}${translatedCustomMessage}`;
    });

    return (
      <ToolTipAndIcon
        icon={IconInvalid}
        tipText={`${invalidText}\n${invalidValues.join('\n')}`}
        virtualRowStart={virtualRowStart}
        alt="invalid_icon"
      />
    );
  }

  return null;
};

export const UpdateInformationAndIcon = ({
  row,
  virtualRowStart,
}: {
  row: Row<TargetRowOrTargetRowHierarchyEntry>;
  virtualRowStart: number | undefined;
}) => {
  const updateInformation = row.original.updateInformation;

  const localization = useLocalization();

  const updateText = useTxt(
    'target.targetImportModal.previewTable.updateTooltip'
  );

  if (!updateInformation) {
    return null;
  }

  if (updateInformation.changeType === 'noChange') {
    return null;
  }

  if (updateInformation.changeType === 'delete') {
    return <Icon src={IconCloseCircle} alt="delete_icon" />;
  }

  if (updateInformation.changeType === 'create') {
    return <Icon src={IconPlus} alt="create_icon" />;
  }

  if (updateInformation.changeType === 'update') {
    const keys = Object.keys(
      updateInformation
    ) as (keyof UpdateInformationTargetImportData)[];

    const changedKeys = keys.reduce(
      (acc, key) => {
        if (key !== 'changeType' && updateInformation[key] !== undefined) {
          const newValue = updateInformation[key]?.newData;
          const oldValue = updateInformation[key]?.oldData;

          return [
            ...acc,
            {
              translatedKey: localization.formatMessage({
                id: dataFields[key].translation,
              }),
              newValue,
              oldValue,
            },
          ];
        }

        return acc;
      },
      [] as {
        translatedKey: string;
        newValue: string | boolean | Big | null | undefined;
        oldValue: string | boolean | Big | null | undefined;
      }[]
    );

    const changedKeysAsString = changedKeys.map(
      (changedKey) =>
        `${changedKey.translatedKey}: ${changedKey.oldValue} -> ${changedKey.newValue}`
    );
    const toolTipText = `${updateText}\n${changedKeysAsString.join(',\n')}`;

    return (
      <ToolTipAndIcon
        tipText={toolTipText}
        icon={IconEdit}
        virtualRowStart={virtualRowStart}
        alt="update_icon"
      />
    );
  }

  return null;
};

const ToolTipAndIcon = memo(
  (
    props: React.ImgHTMLAttributes<HTMLImageElement> & {
      tipText: string;
      icon: string;
      virtualRowStart?: number;
    }
  ) => {
    const { tipText, icon, virtualRowStart } = props;

    const [isMouseHover, setIsMouseHover] = React.useState<
      undefined | boolean
    >();
    const [measureRef, measures] = useMeasure({ debounce: 100 });

    const tooltipContext = useContext(TargetTableTooltipContext);

    const { top, left, height, width, right, bottom } = measures;

    return (
      <Icon
        {...props}
        ref={measureRef}
        src={icon}
        onMouseLeave={() => {
          if (isMouseHover === true) {
            tooltipContext.setToolTipProps(undefined);
          }
        }}
        onMouseEnter={() => {
          tooltipContext.setToolTipProps({
            parentMeasures: {
              top,
              left,
              height,
              width,
              right,
              bottom,
              virtualRowStart,
            },
            text: tipText,
            place: 'top',
          });
          setIsMouseHover(true);
        }}
      />
    );
  }
);

export const StyledCheckbox = styled(Checkbox)`
  margin-right: ${(props) => props.theme.margin[8]};
`;
