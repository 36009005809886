import { Reducer } from 'redux';

import { BackendError } from '../../../utils/api';
import * as remoteData from '../../../utils/remoteData';
import { Selector } from '../utils';

import { ActionTypes } from '../../actionTypes';

export type PossibleOrderRowLinksState = Partial<
  Record<string, remoteData.RemoteData<string[], BackendError | undefined>>
>;

const initialState = {};

const possibleOrderRowLinksReducer: Reducer<
  PossibleOrderRowLinksState,
  ActionTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_POSSIBLE_ANALYSIS_LINKS_FOR_ORDER_ROW_STARTED': {
      const { orderRowId } = action.payload;

      return { ...state, [orderRowId]: remoteData.loading };
    }
    case 'GET_POSSIBLE_ANALYSIS_LINKS_FOR_ORDER_ROW_FAILURE': {
      const { orderRowId, error } = action.payload;

      return { ...state, [orderRowId]: remoteData.fail(error) };
    }
    case 'GET_POSSIBLE_ANALYSIS_LINKS_FOR_ORDER_ROW_SUCCESS': {
      const { orderRowId, analysisRowIds } = action.payload;

      return { ...state, [orderRowId]: remoteData.succeed(analysisRowIds) };
    }
    case 'POST_ANALYSIS_ROW_SUCCESS':
    case 'DELETE_ANALYSIS_ROW_SUCCESS':
    case 'REMOVE_ORDER_ROW_ANALYSIS_LINK_SUCCESS':
    case 'REMOVE_TARGET_ROW_ANALYSIS_LINK_SUCCESS':
    case 'POST_TARGET_ROW_ANALYSIS_LINK_SUCCESS':
    case 'POST_ORDER_ROW_ANALYSIS_LINK_SUCCESS':
      return initialState;
    default:
      return state;
  }
};

export default possibleOrderRowLinksReducer;

export const getPossibleAnalysisRowIdsforOrderRow: (
  orderRowId: string
) => Selector<remoteData.RemoteData<string[]>> =
  (orderRowId) =>
  ({
    analysis: {
      possibleOrderRowLinks: {
        [orderRowId]: remoteRowIds = remoteData.notAsked,
      },
    },
  }) =>
    remoteRowIds;
