import React from 'react';

import styled from 'styled-components';

import { InvoiceHeader } from '../../../../../../store/reducers/invoiceHeader';

import useTxt from '../../../../../../hooks/useTxt';

import { ToggleButton } from '../../../../../../components/Buttons';
import Modal, {
  Content,
  Header,
} from '../../../../../../components/Modal/Modal';
import Txt from '../../../../../../components/Txt';

import * as big from '../../../../../../utils/big';

import { IconCheckmark } from '../../../../../../assets/svg';

import CrossOrderReceiveForm from './CrossOrderReceiveForm';
import ManualEntryForm from './ManualEntryForm';

type ManualEntryModalProps = {
  invoiceHeader: InvoiceHeader;
  onClose: () => void;
};

export type FormDimension = {
  id: string;
  valueId?: string;
};

const ManualEntryModal = ({
  invoiceHeader,
  onClose,
}: React.PropsWithChildren<ManualEntryModalProps>) => {
  const [isCrossOrderReceive, setIsCrossOrderReceive] =
    React.useState<boolean>(false);

  const headerText = useTxt('order.manualEntryModal.header');

  const invoiceName = `${invoiceHeader.vendorInvoiceNo}, ${
    invoiceHeader.supplierName ? `${invoiceHeader.supplierName}` : null
  } (${big.priceFormat(invoiceHeader.amount)})`;

  const toggleButtonIcon = (showCheckMark: boolean) => {
    return showCheckMark ? IconCheckmark : () => <></>;
  };

  return (
    <Modal onClose={onClose}>
      <FormContainer>
        <StyledHeader>{`${headerText} ${invoiceName}`}</StyledHeader>
        <StyledContent>
          <StyledToggleButtonGroup>
            <ToggleButton
              side="left"
              type="button"
              icon={toggleButtonIcon(!isCrossOrderReceive)}
              selected={!isCrossOrderReceive}
              onClick={() => setIsCrossOrderReceive(false)}
            >
              <StyledButtonText>
                <Txt id="order.manualEntryModal.allocateToOtherProject" />
              </StyledButtonText>
            </ToggleButton>
            <ToggleButton
              side="right"
              type="button"
              icon={toggleButtonIcon(isCrossOrderReceive)}
              selected={isCrossOrderReceive}
              onClick={() => setIsCrossOrderReceive(true)}
            >
              <StyledButtonText>
                <Txt id="order.manualEntryModal.allocateToOtherOrder" />
              </StyledButtonText>
            </ToggleButton>
          </StyledToggleButtonGroup>
          {isCrossOrderReceive ? (
            <CrossOrderReceiveForm
              invoiceHeader={invoiceHeader}
              onClose={onClose}
            />
          ) : (
            <ManualEntryForm invoiceHeader={invoiceHeader} onClose={onClose} />
          )}
        </StyledContent>
      </FormContainer>
    </Modal>
  );
};

export default ManualEntryModal;

const FormContainer = styled.div`
  box-shadow: 0px 4px 42px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
`;

const StyledHeader = styled(Header)`
  padding: ${({ theme }) => `${theme.margin[16]}`};
  height: 3rem;
`;

const StyledContent = styled(Content)`
  border-radius: 0 0 ${({ theme }) => theme.radius.medium}
    ${({ theme }) => theme.radius.medium};
  padding: ${({ theme }) => `${theme.margin[32]}`};
  min-height: 16rem;
  max-height: none;
`;

const StyledButtonText = styled.span`
  margin-left: ${(props) => props.theme.margin[4]};
`;

const StyledToggleButtonGroup = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;

  overflow: hidden;
`;
