import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { FirebaseAppProvider } from 'reactfire';

import { ThemeProvider } from 'styled-components';

import { Buffer } from 'buffer';

import App from './App';
import { firebaseConfig } from './config/firebase';
import LocaleWrapper from './context/locale';
import FirebaseAuthProvider from './firebase/FirebaseAuthProvider';
import { FirebaseUserProvider } from './firebase/FirebaseUserProvider';
import { store } from './store';
import GlobalStyle from './styles/global';
import theme from './styles/theme';

import './styles/fonts.css';
import './styles/normalize.css';

window.Buffer = window.Buffer || Buffer;

const app = (
  <Provider store={store}>
    <LocaleWrapper>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <React.StrictMode>
          <BrowserRouter>
            <FirebaseAppProvider firebaseConfig={firebaseConfig}>
              {process.env.REACT_APP_AUTH_PROVIDER === 'firebase' ? (
                <FirebaseAuthProvider>
                  <FirebaseUserProvider>
                    <App />
                  </FirebaseUserProvider>
                </FirebaseAuthProvider>
              ) : (
                <App />
              )}
            </FirebaseAppProvider>
          </BrowserRouter>
        </React.StrictMode>
      </ThemeProvider>
    </LocaleWrapper>
  </Provider>
);

ReactDOM.render(app, document.getElementById('root'));
