import React from 'react';

import styled from 'styled-components';

type ColorBoxProps = { size?: string; color: string };

export function ColorBox({ size = '1rem', color, ...props }: ColorBoxProps) {
  return <Box width={size} height={size} color={color} {...props} />;
}

type TaskStatusIconProps = {
  statusColor?: string;
  size?: string;
};

export function TaskStatusIcon({
  size = '1rem',
  statusColor,
  ...props
}: TaskStatusIconProps) {
  return (
    <StatusColorBox size={size} color={statusColor || 'white'} {...props} />
  );
}

export const StatusColorBox = styled(ColorBox)`
  border-radius: 50%;
  flex-shrink: 0;
`;

const Box = styled.div<{ width: string; height: string }>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  display: inline-block;
  background-color: ${(props) => props.color};
`;
