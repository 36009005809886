import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';

import { toRenderableOrderRow } from '../../../../../store/reducers/orderRow';
import {
  getOrderRowTargetRows,
  toTargetOrderRow,
} from '../../../../../store/reducers/target/targetRows';
import { getIsOuterBarOpen } from '../../../../../store/reducers/ui';

import { updateOrderRow } from '../../../../../store/actions';
import * as Actions from '../../../../../store/actions';

import { APIOrderRow } from '../../../../../types/api';
import { ReceiveViewModeOptions } from '../../../../../types/general';

import useRemoteData from '../../../../../hooks/useRemoteData';
import useRouteParams from '../../../../../hooks/useRouteParams';
import useTxt from '../../../../../hooks/useTxt';

import {
  IconTextButton,
  BaseButtonWithUnderline,
} from '../../../../../components/Buttons';
import Cell from '../../../../../components/Cell';
import {
  OrderRowLeftBorderContainer,
  OrderRowRightPaddingContainer,
  OrderRowLeftPaddingContainer,
  OrderRowSmallerRightPaddingContainer,
} from '../../../../../components/Containers';
import TableTextInput from '../../../../../components/Input/TableTextInput';
import ProgressPill from '../../../../../components/ProgressPill';
import { SecondaryRow } from '../../../../../components/Table';
import Txt from '../../../../../components/Txt';
import { InvoicesPerOrderRowModal } from '../../../components/InvoicesPerOrderRowModal';
import { TableErrorNotification } from '../../../components/TableErrorNotification';
import TargetedRow, {
  TargetCell,
} from '../../../components/Target/TargetedRow';

import { validate, ValidationAction } from './utils';
import * as big from '../../../../../utils/big';
import CAN, {
  CaslOrderRequestParams,
} from '../../../../../utils/caslUserPermissions';
import { useDebounce } from '../../../../../utils/hooks';

import { IconCheckmark } from '../../../../../assets/svg';

import {
  orderRowFullyReceived,
  constructQuantityIncreaseRequest,
  emptyReceiveAmountStatePiece,
} from '../receiveModeUtils';

type Props = {
  orderRow: APIOrderRow;
  viewModeOptions: ReceiveViewModeOptions;
};

const ReceiveOrderRow = ({ orderRow, viewModeOptions }: Props) => {
  const dispatch = useDispatch();
  const { projectId, showTargetRows } = useRouteParams();
  const outerBarOpen = useSelector(getIsOuterBarOpen());

  // We keep "select all" state in parent for convenience reasons as it does not
  // have update very often and then we can implement e.g. setting whole topic
  // as selected more easily. parentAmount is used to sync parent changes to
  // children, see the useEffect below.
  const { allSelected, amount: parentAmount } =
    viewModeOptions.state[orderRow.id] || emptyReceiveAmountStatePiece;

  // Initialize local state to match parent's value. We keep input value in
  // local state and sync it with debounce to parent in order to keep things
  // performant.
  const [amount, setAmount] = useState(parentAmount);

  const [isInvoiceModalVisible, setInvoiceModalVisibility] =
    React.useState(false);

  const [validationError, setValidationError] = useState(
    validate(amount, orderRow)
  );
  const isValid = validationError === null;

  // Sync amount to ReceiveModeWrapper using 500ms debounce
  const debouncedSync = useDebounce((value: string) => {
    viewModeOptions.updateAmount(orderRow.id, value);
  }, 500);

  // Re-run validation if order row quantity changes
  useEffect(() => {
    setValidationError(validate(amount, orderRow));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderRow.quantity]);

  // Sync validity state to ReceiveModeWrapper instantly when it changes
  useEffect(() => {
    // we must flush debounce queue in this case because otherwise there will be
    // a brief moment (well, 500ms) where user can click submit with invalid
    // values.
    if (isValid === true) {
      debouncedSync.flush();
    }

    viewModeOptions.updateValidity(orderRow.id, isValid);
    // Including viewModeOptions & orderRow.id in deps will cause the effect
    // to cause a never-ending loop, thus let's not include them.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid]);

  // Sync local state to match parent state if different. This is the case for
  // example when user clicks the cancel button.
  useEffect(() => {
    if (amount !== parentAmount) {
      setAmount(parentAmount);
      setValidationError(validate(parentAmount, orderRow));
    }
    // We don't really want to depend on amount here as we only want to run on
    // parentAmount change.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentAmount]);

  const onChangeAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    setAmount(value);

    const validationResult = validate(value, orderRow);

    if (validationResult !== null) {
      setValidationError(validationResult);
    }

    // Reset validation error if input is no longer invalid
    if (validationResult === null && validationError) {
      setValidationError(null);
    }

    debouncedSync(value);
  };

  const everythingReceived = orderRowFullyReceived(orderRow);

  const onClickIncreaseAmount = () => {
    dispatch(
      updateOrderRow(
        orderRow.id,
        constructQuantityIncreaseRequest(orderRow, amount)
      )
    );
  };

  const targetRows =
    useRemoteData(
      getOrderRowTargetRows({
        orderId: orderRow.orderId,
        orderRowId: orderRow.id,
      }),
      Actions.requestTargetRows({ orderId: orderRow.orderId })
    ) ?? [];

  const targetOrderRow = toTargetOrderRow(orderRow, targetRows);

  const renderableOrderRow = toRenderableOrderRow(
    orderRow,
    outerBarOpen ? 2 : undefined
  );

  const {
    rowNumber,
    description,
    quantity,
    unit,
    unitPrice,
    arrivalQuantity,
    arrivalTotal,
    totalPrice,
    remainingAmount,
    percentage,
    arrivalRowIds,
  } = renderableOrderRow;
  const arrived = `${arrivalQuantity} ${unit}`;

  const showInvoiceButton = arrivalRowIds.length > 0;

  const ability = new CaslOrderRequestParams(projectId);
  const allowedUser = CAN('write', ability);

  const unitPriceMissingText = useTxt('order.receiveMode.unitPriceMissing');
  const toBeReceivedText = useTxt('common.toBeReceived');
  const quantityText = useTxt('common.quantity').toLowerCase();

  return (
    <>
      {isInvoiceModalVisible ? (
        <InvoicesPerOrderRowModal
          orderRow={renderableOrderRow}
          onClose={() => setInvoiceModalVisibility(false)}
        />
      ) : null}
      <SecondaryRow data-testid={`order-row-${orderRow.id}`}>
        <Cell align="center">{rowNumber}</Cell>
        <Cell>{description}</Cell>
        {outerBarOpen ? (
          <Cell align="right">{`${quantity} ${unit}` ?? '-'}</Cell>
        ) : (
          <>
            <Cell align="right">{quantity ?? '-'}</Cell>
            <Cell align="center">{unit}</Cell>
          </>
        )}
        <Cell align="right">{unitPrice ?? '- €'}</Cell>
        {showTargetRows ? (
          <TargetCell align="right">
            {targetOrderRow.target.eq(0)
              ? ''
              : big.priceFormatRounded(
                  targetOrderRow.target,
                  outerBarOpen ? 0 : undefined
                )}
          </TargetCell>
        ) : null}
        <Cell
          align="right"
          contentContainer={
            showTargetRows || outerBarOpen
              ? OrderRowSmallerRightPaddingContainer
              : OrderRowRightPaddingContainer
          }
        >
          {totalPrice ?? '- €'}
        </Cell>
        {showTargetRows ? (
          <TargetCell align="right">
            {targetOrderRow.difference.eq(0)
              ? ''
              : big.priceFormatRounded(
                  targetOrderRow.difference,
                  outerBarOpen ? 0 : undefined
                )}
          </TargetCell>
        ) : null}
        <Cell align="right" contentContainer={OrderRowLeftBorderContainer}>
          {arrived}
        </Cell>
        <Cell align="right" contentContainer={OrderRowLeftPaddingContainer}>
          {showInvoiceButton ? (
            <BaseButtonWithUnderline
              onClick={() => setInvoiceModalVisibility(true)}
            >
              {arrivalTotal}
            </BaseButtonWithUnderline>
          ) : (
            arrivalTotal
          )}
        </Cell>
        {orderRow.unitPrice !== null ? (
          <>
            <Cell
              contentContainer={
                outerBarOpen
                  ? OrderRowSmallerRightPaddingContainer
                  : OrderRowLeftPaddingContainer
              }
            >
              <TableTextInput
                align="right"
                value={amount}
                onChange={onChangeAmount}
                disabled={allSelected || !allowedUser}
                aria-label={`${toBeReceivedText} ${quantityText}`}
                invalid={validationError !== null}
                placeholder={orderRow.unit}
                onBlur={() => debouncedSync.flush()}
              />
              {validationError ? (
                <TableErrorNotification data-testid="validation-error">
                  <div>
                    <Txt
                      id={validationError.message}
                      values={validationError.values}
                    />
                  </div>
                  {validationError.action ===
                  ValidationAction.INCREASE_AMOUNT ? (
                    <ErrorButtonContainer>
                      <IconTextButton
                        icon={IconCheckmark}
                        onClick={onClickIncreaseAmount}
                      >
                        <Txt id="common.increase" />
                      </IconTextButton>
                    </ErrorButtonContainer>
                  ) : null}
                </TableErrorNotification>
              ) : null}
            </Cell>
            <Cell align="right">
              <ProgressPill
                percentage={percentage || 0}
                active={everythingReceived || allSelected}
                onClick={() =>
                  viewModeOptions.toggleSelectAllForSingleRow(orderRow.id)
                }
                isDisabled={everythingReceived || !CAN('write', ability)}
              />
            </Cell>
            <Cell align="right">{remainingAmount}</Cell>
          </>
        ) : (
          <Cell colSpan={3}>{unitPriceMissingText}</Cell>
        )}
      </SecondaryRow>
      {showTargetRows ? (
        <>
          {targetRows.map((row) => (
            <TargetedRow key={`targeted-row-${row.id}`} row={row} />
          ))}
        </>
      ) : null}
    </>
  );
};

const ErrorButtonContainer = styled.div`
  margin-top: ${(props) => props.theme.margin[8]};
  border-top: 1px solid ${({ theme }) => theme.color.graphiteB96A};
  padding-top: ${(props) => props.theme.margin[8]};
`;

export default ReceiveOrderRow;
