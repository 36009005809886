import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useAuth, useUser } from 'reactfire';

import { User } from 'firebase/auth';

type FirebaseUserContextType = {
  user: User | null;
  token: string | null;
};

export const FirebaseUserContext =
  createContext<FirebaseUserContextType | null>(null);

export const FirebaseUserProvider = ({ children }: { children: ReactNode }) => {
  const { data: user } = useUser();
  const auth = useAuth();
  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    const unregisterAuthObserver = auth.onIdTokenChanged(
      async (currentUser) => {
        if (currentUser) {
          try {
            const newToken = await currentUser.getIdToken();
            setToken(newToken);
          } catch (e) {
            setToken(null);
          }
        } else {
          setToken(null);
        }
      }
    );

    return () => unregisterAuthObserver();
  }, [user, auth]);

  return (
    <FirebaseUserContext.Provider value={{ user, token }}>
      {children}
    </FirebaseUserContext.Provider>
  );
};

export const useFirebaseUser = () => useContext(FirebaseUserContext);
