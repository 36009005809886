import React from 'react';
import { useSelector } from 'react-redux';
import { useWindowSize } from 'react-use';
import useMeasure from 'react-use-measure';

import { getExpandedRowModel, useReactTable } from '@tanstack/react-table';
import { getCoreRowModel } from '@tanstack/react-table';
import { useVirtualizer } from '@tanstack/react-virtual';
import styled from 'styled-components';

import { getProjectById } from '../../../store/reducers/project';
import { isLoading } from '../../../store/reducers/schedule/nextgenScheduleData';
import { getActiveProjectId } from '../../../store/reducers/ui';

import useScheduleTreeTableColumns, {
  tableColumns,
} from './hooks/useScheduleTreeTableColumns';
import useTxt from '../../../hooks/useTxt';

import { PrimaryButtonSmall } from '../../../components/Buttons';
import { ToolTip } from '../../../components/Cell';
import { Spinner } from '../../../components/Loading';
import NoDataInfoBox from '../../../components/Table/NoDataInfoBox';
import Txt from '../../../components/Txt';

import { ExtendedScheduleWorkPackageType } from './utils';

import NewScheduleTreeRow, { ScheduleCell } from './NewScheduleTreeRow';
import Th from './ScheduleTreeTh';

type TooltipProps =
  | {
      text?: string;
      place?: 'top' | 'bottom' | 'left' | 'right';
      parentMeasures?: {
        width: number;
        height: number;
        top: number;
        left: number;
        right: number;
        bottom: number;
        virtualRowStart: number | undefined;
      };
    }
  | undefined;

interface ToolTipContextProps {
  tooltipProps: TooltipProps;
  setToolTipProps: React.Dispatch<React.SetStateAction<TooltipProps>>;
}

export const ScheduleTreeTableContext =
  React.createContext<ToolTipContextProps>({
    tooltipProps: undefined,
    setToolTipProps: () => {},
  });

type ScheduleTreeTableProps = {
  deSelectWorkPackage: (id: string) => void;
  scheduleTreeSelection: {
    workSectionClassId?: string | null | undefined;
    workPackageVirtualSpaceLinkages?: {
      nextgenWorkPackageId: string | null;
      virtualSpaceId: string | null;
    }[];
  };
  scheduleTreeTableData: ExtendedScheduleWorkPackageType[];
  anyDataAvailable: boolean;
  parentMeasures: { width: number; height: number | string };
};

const ScheduleTreeTable = ({
  deSelectWorkPackage,
  scheduleTreeSelection,
  scheduleTreeTableData,
  anyDataAvailable,
  parentMeasures,
}: ScheduleTreeTableProps) => {
  const [isAllLoaded, setIsAllLoaded] = React.useState(false);
  const projectId = useSelector(getActiveProjectId) ?? '';

  const [tooltipProps, setToolTipProps] = React.useState<TooltipProps>();

  const isDataLoading = useSelector(isLoading(projectId));
  const project = useSelector(getProjectById(projectId));
  const columns = useScheduleTreeTableColumns(deSelectWorkPackage, project);

  const selectedWorkSectionClass = scheduleTreeSelection.workSectionClassId;

  const tableData = scheduleTreeTableData;

  const table = useReactTable({
    data: tableData,
    columns,
    getRowId: (row) => row.id,
    getSubRows: (row) => row.subRows,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    manualSorting: true,
  });

  const { rows } = table.getRowModel();

  const rowsLength = React.useMemo(() => rows.length, [rows]);

  const tableContainerRef = React.useRef<HTMLDivElement | null>(null);

  const measureElementFunction = () => {
    if (typeof window === 'undefined') {
      return;
    }

    if (navigator.userAgent.indexOf('Firefox') === -1) {
      return (element: Element) => {
        return element?.getBoundingClientRect().height;
      };
    }
  };

  const rowVirtualizer = useVirtualizer({
    count: rowsLength,
    getScrollElement: () => tableContainerRef.current,
    estimateSize: React.useCallback(() => 32, []), // estimate row height for accurate scrollbar dragging
    overscan: 40,
    paddingEnd: 87,
    measureElement: measureElementFunction(),
  });

  const measureElement = React.useCallback(
    (node: Element | null) => {
      const measurement = rowVirtualizer.measureElement(node);

      return measurement;
    },
    [rowVirtualizer]
  );

  const virtualItems = rowVirtualizer.getVirtualItems();

  const visibleRows = React.useMemo(() => {
    return virtualItems.map((virtualRow) => ({
      ...virtualRow,
      row: rows[virtualRow.index],
    }));
  }, [virtualItems, rows]);

  const [measureRef, measures] = useMeasure({ debounce: 100 });
  const windowSize = useWindowSize();

  const animationRef = React.useRef<number>();

  const expandAll = React.useCallback(() => {
    table.toggleAllRowsExpanded(true);
  }, [table]);

  React.useEffect(() => {
    if (
      rowsLength !== 0 &&
      isAllLoaded === false &&
      tableContainerRef.current
    ) {
      animationRef.current = requestAnimationFrame(() => {
        expandAll();
        setIsAllLoaded(true);
      });
    }

    return () => cancelAnimationFrame(animationRef.current ?? 0);
  }, [expandAll, isAllLoaded, rowsLength]);

  const containerWidth = parentMeasures.width;

  const containerHeight = parentMeasures.height;

  const calculateTooltipPlacement = (): TooltipDivProps => {
    let left: number | undefined;
    let right: number | undefined;

    if (
      tooltipProps?.parentMeasures?.left &&
      tooltipProps?.parentMeasures?.left < windowSize.width / 2
    ) {
      left = tooltipProps?.parentMeasures?.left
        ? tooltipProps?.parentMeasures?.left - measures.left + 24
        : 0;
    } else {
      right = tooltipProps?.parentMeasures?.right
        ? measures.right - tooltipProps?.parentMeasures?.right + 24
        : 0;
    }

    return {
      left,
      right,
      virtualRowStart: tooltipProps?.parentMeasures?.virtualRowStart,
    };
  };

  const tooltipPlacement = calculateTooltipPlacement();

  const selectedWorkSectionClassWithoutNone =
    selectedWorkSectionClass === 'NONE' ? null : selectedWorkSectionClass;

  const noWorkpackagesAvailableHeader = useTxt(
    'worksection.workpackage.noWorkpackagesAvailable'
  );

  const noWorkpackagesAvailableBody = useTxt(
    'worksection.workpackage.noWorkpackagesAvailable.body'
  );

  const noWorkpackagesAvailableLink = useTxt(
    'worksection.workpackage.noWorkpackagesAvailable.link'
  );

  function scheduleLinkClicked() {
    const url = project?.externalUrl;

    if (url) {
      window.open(url, '_blank');
    }
  }

  const renderTable = () => {
    if (isDataLoading && !anyDataAvailable) {
      return (
        <tr>
          <ScheduleCell inline noBorder>
            <SpinnerDiv>
              <Spinner size="4rem" />
            </SpinnerDiv>
          </ScheduleCell>
        </tr>
      );
    }

    if (
      scheduleTreeTableData.length === 0 &&
      selectedWorkSectionClassWithoutNone !== null
    ) {
      return (
        <tr>
          <ScheduleCell inline noBorder>
            <NoDataInfoBox
              headerText={noWorkpackagesAvailableHeader}
              bodyText={noWorkpackagesAvailableBody}
            >
              <PrimaryButtonSmall onClick={scheduleLinkClicked}>
                {noWorkpackagesAvailableLink}
              </PrimaryButtonSmall>
            </NoDataInfoBox>
          </ScheduleCell>
        </tr>
      );
    }

    return scheduleTreeTableData.length === 0 ? (
      <tr>
        <ScheduleCell inline noBorder>
          <SpinnerDiv>
            <Txt id="worksection.workpackage.noLinkages" />
          </SpinnerDiv>
        </ScheduleCell>
      </tr>
    ) : (
      visibleRows.map((virtualRow) => {
        return (
          <NewScheduleTreeRow
            key={virtualRow.row.id}
            scheduleWorkPackage={virtualRow.row}
            measureElement={measureElement}
            virtualRow={virtualRow}
            containerWidth={containerWidth}
          />
        );
      })
    );
  };

  return (
    <div ref={measureRef}>
      <UusiDiv height={containerHeight}>
        <StyledContainer ref={tableContainerRef}>
          <ScheduleTreeTableContext.Provider
            value={{ tooltipProps, setToolTipProps }}
          >
            <StyledTable>
              <StyledThead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <StyledTr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      const headerId = header.id as keyof typeof tableColumns;

                      const thWidth = tableColumns[headerId]?.width * 16; // 1rem = 16px
                      const fixedWidth = tableColumns[headerId]?.fixedWidth;

                      const containerWidthMinusFixedWidths =
                        headerGroup.headers.reduce((acc, currentHeader) => {
                          const currentHeaderId =
                            currentHeader.id as keyof typeof tableColumns;

                          const currentHeaderFixedWidth =
                            tableColumns[currentHeaderId]?.fixedWidth;

                          return (
                            acc -
                            (currentHeaderFixedWidth
                              ? tableColumns[currentHeaderId]?.width * 16
                              : 0)
                          );
                        }, containerWidth) - 20;

                      const dynamicWidthTotal = headerGroup.headers.reduce(
                        (acc, currentHeader) => {
                          const currentHeaderId =
                            currentHeader.id as keyof typeof tableColumns;

                          const currentHeaderFixedWidth =
                            tableColumns[currentHeaderId]?.fixedWidth;

                          return (
                            acc +
                            (!currentHeaderFixedWidth
                              ? tableColumns[currentHeaderId]?.width * 16
                              : 0)
                          );
                        },
                        0
                      );

                      const dynamicWidth = Math.max(
                        (thWidth / dynamicWidthTotal) *
                          containerWidthMinusFixedWidths,
                        thWidth // use as minimum width
                      );

                      return (
                        <Th
                          key={header.id}
                          header={header}
                          width={fixedWidth ? thWidth : dynamicWidth}
                        />
                      );
                    })}
                  </StyledTr>
                ))}
              </StyledThead>
              <StyledTBody height={rowVirtualizer.getTotalSize()}>
                {renderTable()}
              </StyledTBody>
            </StyledTable>
            {tooltipProps ? (
              <StyledTooltip {...tooltipPlacement}>
                {tooltipProps?.text ?? ''}
              </StyledTooltip>
            ) : null}
          </ScheduleTreeTableContext.Provider>
        </StyledContainer>
      </UusiDiv>
    </div>
  );
};

export default React.memo(ScheduleTreeTable);

const StyledContainer = styled.div`
  position: relative;

  height: 100%;
  width: 100%;

  display: flex;
  flex-wrap: wrap;
  align-content: space-between;

  overflow: auto;

  transform: translate3d(0, 0, 0);
`;

const SpinnerDiv = styled.div`
  margin: ${(props) => props.theme.margin[16]};
  display: flex;
  justify-content: center;
  align-items: center;
`;

type ContainerProps = {
  height: number | string;
};

const UusiDiv = styled.div<ContainerProps>`
  height: ${({ height }) =>
    typeof height === 'string' ? height : `${height}px`};
  width: 100%;
`;

export const StyledTable = styled.table`
  display: grid;
  align-items: center;

  border-collapse: collapse;
  border-spacing: 0;

  table-layout: fixed;
`;

export const StyledThead = styled.thead`
  position: sticky;
  top: 0;

  box-shadow:
    white -1px 0px,
    white 1px 0px,
    grey 0px 1px 3px;

  display: grid;

  background-color: white;

  z-index: 1;
`;

type TBodyProps = {
  height: number;
};

export const StyledTBody = styled.tbody<TBodyProps>`
  position: relative;
  height: ${({ height }) => height}px;
  display: grid;
`;

export const StyledTr = styled.tr`
  border-bottom: 2px solid ${(props) => props.theme.color.rowBorder};
  width: 100%;
  display: flex;
`;

type TooltipDivProps = {
  left?: number;
  right?: number;
  virtualRowStart?: number;
};

const StyledTooltip = styled(ToolTip).attrs<TooltipDivProps>(
  ({ virtualRowStart }) => ({
    style: { transform: `translateY(${(virtualRowStart ?? 0) + 48}px)` },
  })
)<TooltipDivProps>`
  left: ${({ left }) => (left ? `${left}px` : 'auto')};
  right: ${({ right }) => (right ? `${right}px` : 'auto')};
  top: auto;

  width: 300px;

  display: inline-block;

  white-space: pre-line;

  pointer-events: none;
`;
