import styled from 'styled-components';

export const Summary = styled.div`
  @keyframes flash-animation {
    from {
      border-top: 2px solid ${({ theme }) => theme.color.graphiteB76};
      border-bottom: 2px solid ${({ theme }) => theme.color.graphiteB76};
      border-right: 2px solid ${({ theme }) => theme.color.graphiteB76};
      border-left: 2px solid ${({ theme }) => theme.color.graphiteB76};

      background-color: ${({ theme }) => theme.color.graphiteB91};
    }
    to {
      border-top: 2px solid ${({ theme }) => theme.color.graphiteB76};
      border-bottom: 2px solid white;
      border-right: 2px solid white;
      border-left: 2px solid white;

      background-color: ${({ theme }) => theme.color.white};
      opacity: 1;
    }
  }
  position: sticky;
  bottom: 0;

  border-top: 2px solid ${({ theme }) => theme.color.graphiteB76};
  border-bottom: 2px solid white;
  border-right: 2px solid white;
  border-left: 2px solid white;

  padding: ${({ theme }) => theme.margin[32]} 0;

  width: 100%;

  display: flex;

  background-color: ${({ theme }) => theme.color.white};

  animation: flash-animation linear 1s 1;

  z-index: 1;
`;
