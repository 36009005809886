import React from 'react';

import styled from 'styled-components';

import useTxt from '../../../../../../hooks/useTxt';

import { TableErrorNotification } from '../../../../components/TableErrorNotification';

import { ValueAndError } from './utils';

import { IconInvalid } from '../../../../../../assets';

export const Container = styled.div`
  padding: ${(props) => `${props.theme.margin[24]} ${props.theme.margin[32]}`};

  width: min(90vw, 80rem);
  height: 80vh;
  max-height: 80vh;

  display: flex;
  flex-flow: column nowrap;

  background: ${(props) => props.theme.color.white};
`;

export const TitleContainer = styled.div`
  padding-bottom: ${(props) => props.theme.margin[24]};
  flex: 0;
`;

export const InfoContainer = styled.div``;

export const InputContainer = styled.div`
  padding: ${(props) => props.theme.margin[16]} 0;
  flex: 0;
`;

export const TableContainer = styled.div`
  height: 0px; /* Needed for overflow-y: scroll to work with flexbox */
  flex: 1 0 auto;
  overflow-y: scroll;
`;

export const ErrorText = styled.div`
  color: ${(props) => props.theme.color.red};
`;

export const BottomControlsContainer = styled.div`
  padding-top: ${(props) => props.theme.margin[16]};

  display: flex;
  justify-content: space-between;
  align-items: center;

  flex: 0;
`;

const InvalidityIndicatorContainer = styled.div`
  margin-left: ${(props) => props.theme.margin[8]};

  width: ${(props) => props.theme.margin[24]};
  height: ${(props) => props.theme.margin[24]};

  display: inline-block;

  background: transparent url(${IconInvalid}) no-repeat scroll 50% 50% /
    ${(props) => props.theme.margin[24]} ${(props) => props.theme.margin[24]};

  /* stylelint-disable selector-max-class -- TODO Should be refactored */
  .error-description {
    visibility: hidden;
  }

  /* stylelint-disable max-nesting-depth -- TODO Should be refactored */
  &:hover {
    .error-description {
      visibility: visible;
    }
  }
`;

type InvalidityIndicatorProps = {
  field: ValueAndError;
  showValue?: boolean;
};

export const InvalidityIndicator = ({
  field,
  showValue,
}: InvalidityIndicatorProps) => {
  const errorText = useTxt(
    field.validationError ?? undefined,
    field.validationErrorVariables
  );
  const invalidValueText = useTxt('validation.invalidValue');

  if (field.validationError === null) {
    return null;
  }

  return (
    <InvalidityIndicatorContainer>
      <TableErrorNotification className="error-description">
        {showValue ? `${invalidValueText}: ${field.value}.` : null}
        {errorText}
      </TableErrorNotification>
    </InvalidityIndicatorContainer>
  );
};
