import React from 'react';

import {
  getProjectPlannedPoCEntries,
  getProjectPreviouslyPlannedPoCEntries,
  getProjectSnapshotPoCEntries,
  getProjectActualAndPlannedPoCEntries,
  getProjectPoCBasedOnPreviouslyPlannedEntries,
} from '../../../../../store/reducers/schedule/projectTimeline';

import { getProjectTimelineForProject } from '../../../../../store/actions/schedule/projectTimeline';

import useRemoteData from '../../../../../hooks/useRemoteData';
import useTxt from '../../../../../hooks/useTxt';

import {
  DescriptionCell,
  FuturePoCCell,
  SnapshotPoCCell,
} from './components/Cell';
import { BaseRow } from '../../../../../components/Table';

type ProjectRowProps = {
  projectId: string;
  months: Date[];
  snapshotIds: string[];
  currentPeriod: Date;
  selectedSnapshotTypeId: '1' | '2' | '3';
};

const ProjectRow = ({
  projectId,
  months,
  currentPeriod,
  snapshotIds,
  selectedSnapshotTypeId,
}: ProjectRowProps) => {
  const plannedEntries = useRemoteData(
    getProjectPlannedPoCEntries({ projectId }),
    getProjectTimelineForProject({ projectId })
  );

  const actualAndPlannedEntries =
    useRemoteData(
      getProjectActualAndPlannedPoCEntries({ projectId }),
      getProjectTimelineForProject({ projectId })
    ) ?? [];

  const PoCBasedOnPreviouslyPlanned =
    useRemoteData(
      getProjectPoCBasedOnPreviouslyPlannedEntries({ projectId }),
      getProjectTimelineForProject({ projectId })
    ) ?? [];

  const previouslyPlannedEntries =
    useRemoteData(
      getProjectPreviouslyPlannedPoCEntries({ projectId }),
      getProjectTimelineForProject({ projectId })
    ) ?? [];

  const snapshotEntries =
    useRemoteData(
      getProjectSnapshotPoCEntries({ projectId }),
      getProjectTimelineForProject({ projectId })
    ) ?? [];

  const description = useTxt('common.total');

  if (!plannedEntries) {
    return null;
  }

  const mappedEntries = () => {
    switch (selectedSnapshotTypeId) {
      case '1':
        return actualAndPlannedEntries.map((entry) => {
          return { date: entry.date, percentage: entry.percentageOfCompletion };
        });
      case '2':
        return plannedEntries.map((entry) => {
          return { date: entry.date, percentage: entry.percentageOfCompletion };
        });
      case '3':
        return PoCBasedOnPreviouslyPlanned.map((entry) => {
          return { date: entry.date, percentage: entry.percentageOfCompletion };
        });
    }
  };

  const mappedPreviousEntries = previouslyPlannedEntries.map((entry) => {
    return { date: entry.date, percentage: entry.percentageOfCompletion };
  });

  const mappedSnapshotEntries = snapshotEntries.map((entry) => {
    return {
      snapshotId: entry.snapshotId,
      percentage: entry.percentageOfCompletion,
    };
  });

  return (
    <BaseRow>
      <DescriptionCell fontWeight="900" borderBottom="dark">
        {description}
      </DescriptionCell>
      {snapshotIds.map((id) => {
        return (
          <SnapshotPoCCell
            snapshotId={id}
            timelineEntries={mappedSnapshotEntries}
            key={`project-snapshot-${id}`}
            borderBottom="dark"
          />
        );
      })}
      {months.map((month) => {
        return (
          <FuturePoCCell
            month={month}
            currentPeriod={currentPeriod}
            timelineEntries={mappedEntries()}
            previouslyPlannedEntries={mappedPreviousEntries}
            key={`project-${month.toString()}`}
            borderBottom="dark"
          />
        );
      })}
    </BaseRow>
  );
};

export default ProjectRow;
