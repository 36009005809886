import React, { useContext } from 'react';
import useMeasure from 'react-use-measure';

import { ColumnDef } from '@tanstack/react-table';
import { Row } from '@tanstack/react-table';
import Big from 'big.js';
import styled from 'styled-components';

import useTxt from '../../../../hooks/useTxt';

import ArrowIcon from '../../../../components/ArrowIcon';
import Txt from '../../../../components/Txt';
import { StyledCheckbox } from '../../components/TargetTable/TableCells';

import { UpdateProcurementRow } from './utils';
import * as big from '../../../../utils/big';
import { isClickOrKeyboardSelection } from '../../../../utils/mouseOrKeyInteraction';

import { IconQuestionmark } from '../../../../assets';

import { PreviewTableContext } from './PreviewTable';
import { CodeTh, TargetTableSearchInput } from './Th';

const textIdHead = 'target.updateProcurementsModal.table.header.';

export default function usePreviewTableColumns() {
  const columns = React.useMemo<ColumnDef<UpdateProcurementRow>[]>(
    () => [
      {
        accessorKey: 'code',
        header: ({ table }) => {
          return <CodeTh table={table} />;
        },
        cell: ({ getValue, row, table }) => {
          const value = getValue<UpdateProcurementRow['code']>();

          const tableSearchInput = String(table.getState().globalFilter);

          const highlight =
            tableSearchInput.length > 0
              ? !!value?.toLowerCase().includes(tableSearchInput.toLowerCase())
              : false;

          return (
            <RowDiv>
              <StyledCheckbox
                checked={row.getIsSelected()}
                onChange={row.getToggleSelectedHandler()}
                disabled={!row.getCanSelect()}
              />
              <HighlightedSpan highlight={highlight}>{value}</HighlightedSpan>
            </RowDiv>
          );
        },
        size: 70,
        meta: {
          align: 'left',
        },
      },
      {
        accessorKey: 'description',
        header: ({ table }) => {
          const setGlobalFilter = table.setGlobalFilter;

          const globalFilterState = String(table.getState().globalFilter);

          return (
            <StyledDescriptionDiv>
              <Txt id={`${textIdHead}description` as const} component="b" />
              <TargetTableSearchInput
                setGlobalFilter={setGlobalFilter}
                globalFilter={globalFilterState}
              />
            </StyledDescriptionDiv>
          );
        },
        cell: ({ getValue, row, table, virtualRowStart }) => {
          const value = getValue<UpdateProcurementRow['description']>();

          const tableSearchInput = String(table.getState().globalFilter);

          const highlight =
            tableSearchInput.length > 0
              ? !!value?.toLowerCase().includes(tableSearchInput.toLowerCase())
              : false;

          return (
            <DescriptionCellContent
              highlight={highlight}
              row={row}
              virtualRowStart={virtualRowStart}
            />
          );
        },
        size: 250,
        meta: {
          align: 'left',
          borderWidthRight: 2,
        },
      },
      {
        accessorKey: 'quantity',
        header: () => {
          return <Txt id={`${textIdHead}quantity` as const} component="b" />;
        },
        cell: ({ getValue }) => {
          const value = getValue<UpdateProcurementRow['quantity']>();

          return value ? big.amountFormat(value) : null;
        },
        size: 50,
        meta: {
          align: 'right',
          borderWidthRight: 1,
        },
      },
      {
        accessorKey: 'unit',
        header: () => {
          return <Txt id={`${textIdHead}unit` as const} component="b" />;
        },
        cell: ({ getValue }) => {
          const value = getValue<UpdateProcurementRow['unit']>();

          return value;
        },
        size: 30,
        meta: {
          align: 'left',
          borderWidthRight: 1,
        },
      },
      {
        accessorKey: 'unitPrice',
        header: () => {
          return <Txt id={`${textIdHead}unitPrice` as const} component="b" />;
        },
        cell: ({ getValue }) => {
          const value = getValue<UpdateProcurementRow['unitPrice']>();

          return value ? big.amountFormat(value) : null;
        },
        size: 50,
        meta: {
          align: 'right',
          borderWidthRight: 1,
        },
      },
      {
        accessorKey: 'amount',
        header: () => {
          return <Txt id={`${textIdHead}amount` as const} component="b" />;
        },
        cell: ({ getValue }) => {
          const value = getValue<UpdateProcurementRow['amount']>();

          return big.priceFormat(value ?? new Big(0));
        },
        size: 70,
        meta: {
          align: 'right',
          borderWidthRight: 1,
        },
      },
    ],
    []
  );

  return columns;
}

const StyledDescriptionDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const RowDiv = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

type EmptyIconDivProps = {
  depth: number;
};

const EmptyIconDiv = styled.div<EmptyIconDivProps>`
  width: ${({ depth }) => depth * 16}px;
  height: 16px;
  flex-shrink: 0;
`;

const HighlightedSpan = styled.span<{ highlight: boolean }>`
  background-color: ${({ highlight }) =>
    highlight ? 'yellow' : 'transparent'};
`;
type DescriptionCellContentProps = {
  row: Row<UpdateProcurementRow>;
  virtualRowStart: number | undefined;
  highlight: boolean;
};

const DescriptionCellContent = ({
  row,
  virtualRowStart,
  highlight,
}: DescriptionCellContentProps) => {
  const value = row.original.description;

  const unsavedTooltip = useTxt(
    'target.updateProcurementsModal.table.row.unsavedChanges.tooltip'
  );

  const originalOrderCode =
    row.original.hasUnSavedChanges && row.original.hasUnSavedChanges !== true
      ? row.original.hasUnSavedChanges.originalOrderCode
      : '';

  const originalOrderName =
    row.original.hasUnSavedChanges && row.original.hasUnSavedChanges !== true
      ? row.original.hasUnSavedChanges.originalOrderName
      : '';

  const originalWorkPackageCode =
    row.original.hasUnSavedChanges && row.original.hasUnSavedChanges !== true
      ? row.original.hasUnSavedChanges.originalWorkPackageCode
      : '';

  const originalWorkPackageName =
    row.original.hasUnSavedChanges && row.original.hasUnSavedChanges !== true
      ? row.original.hasUnSavedChanges.originalWorkPackageName
      : '';

  const targetRowToolTip = useTxt(
    'target.updateProcurementsModal.table.row.unsavedChanges.tooltip.targetRow',
    {
      order: `${originalOrderCode} ${originalOrderName}`,
      workPackage: `${originalWorkPackageCode} ${originalWorkPackageName}`,
    }
  );

  const [isMouseHover, setIsMouseHover] = React.useState<undefined | boolean>();
  const [measureRef, measures] = useMeasure({ debounce: 100 });

  const previewTableContext = useContext(PreviewTableContext);

  const { top, left, height, width, right, bottom } = measures;

  const tooltipText =
    row.original.type === 'targetRow' ? targetRowToolTip : unsavedTooltip;

  const resetChanges = () => {
    const relatedTargetRowIds = [
      ...row.getLeafRows().map((leafRow) => leafRow.id),
      row.id,
    ];

    previewTableContext.resetDataForTargetRows(relatedTargetRowIds);
    previewTableContext.setToolTipProps(undefined);
  };

  const onIconPress = (e: React.KeyboardEvent | React.MouseEvent) => {
    e.stopPropagation();

    if (isClickOrKeyboardSelection(e)) {
      resetChanges();
    }
  };

  return (
    <>
      <EmptyIconDiv depth={row.depth} />
      {row.getCanExpand() ? (
        <ArrowIcon
          isOpen={row.getIsExpanded()}
          openAltTextKey="target.table.hierarchies.open"
          closedAltTextKey="target.table.hierarchies.closed"
        />
      ) : (
        <EmptyIconDiv depth={1} />
      )}
      <HighlightedSpan highlight={highlight}>{value}</HighlightedSpan>
      {!!row.original.hasUnSavedChanges ? (
        <StyledIcon
          src={IconQuestionmark}
          alt="split_icon"
          ref={measureRef}
          onClick={onIconPress}
          onKeyPress={onIconPress}
          onMouseLeave={() => {
            if (isMouseHover === true) {
              previewTableContext.setToolTipProps(undefined);
            }
          }}
          onMouseEnter={() => {
            previewTableContext.setToolTipProps({
              parentMeasures: {
                top,
                left,
                height,
                width,
                right,
                bottom,
                virtualRowStart,
              },
              text: tooltipText,
              place: 'top',
            });
            setIsMouseHover(true);
          }}
        />
      ) : null}
    </>
  );
};

const StyledIcon = styled.img`
  margin: 0 ${({ theme }) => theme.margin[8]};

  height: 16px;
  width: 16px;

  flex-shrink: 0;

  cursor: pointer;
`;
