import React from 'react';
import { useSelector } from 'react-redux';

import { OptionsType } from 'rc-select/lib/interface';
import styled from 'styled-components';

import { getProjectById } from '../../../../store/reducers/project';

import Cell from '../../../../components/Cell';
import DropDownSelect from '../../../../components/DropDownSelect';
import { Table, StickyTableHeader } from '../../../../components/Table';
import Txt from '../../../../components/Txt';

import { dataFields, TargetImportData } from './utils';
import { isPresent } from '../../../../utils/general';
import getWidths from '../../../../utils/headerWidth';

import { IconCheckmark } from '../../../../assets/svg';

import { routes, useParams } from '../../../../routes';

import defaultTheme from '../../../../styles/theme';

type SelectColumnsPageProps = {
  initialData: unknown[];
  mapping: Partial<Record<keyof TargetImportData, string>> | undefined;
  setMapping: React.Dispatch<
    React.SetStateAction<
      Partial<Record<keyof TargetImportData, string>> | undefined
    >
  >;
};

const tableColumns = {
  linked: { align: 'left', relativeWidth: 1 },
  key: { align: 'left', relativeWidth: 3 },
  csvColumn: { align: 'left', relativeWidth: 3 },
  preview: {
    align: 'left',
    relativeWidth: 6,
  },
};
const getColumnPercent = getWidths(tableColumns);

const textIdHead = 'target.targetImportModal.selectColumns.';

type ThProps = {
  name: keyof typeof tableColumns;
};

const Th = styled.th<ThProps>`
  border-top: 1px solid ${({ theme }) => theme.color.rowBorder};
  border-bottom: 3px solid ${({ theme }) => theme.color.rowBorder};

  padding: ${({ theme: { margin } }) => `${margin[16]} ${margin[16]}`};

  width: ${({ name }) => `${getColumnPercent(name)}%`};

  align-items: center;

  text-align: ${({ name }) => tableColumns[name].align};
`;

const TxtTh = ({ name }: ThProps) => (
  <Th name={name}>
    <Txt id={`${textIdHead}${name}` as const} component="b" />
  </Th>
);

const SelectColumnsPage = ({
  initialData,
  mapping,
  setMapping,
}: SelectColumnsPageProps) => {
  const { projectId } = useParams(routes.TARGET);

  const project = useSelector(getProjectById(projectId));

  const distinctDataKeys = [
    ...new Set(
      initialData
        .map((data) =>
          typeof data === 'object' && data !== null ? Object.keys(data) : []
        )
        .flat()
    ),
  ];

  const options = distinctDataKeys.map((key) => ({
    value: key,
    label: key,
    key,
  }));

  const targetDataFields = [
    'rowCode',
    'parentCode',
    'referenceNo',
    'description',
    'quantity',
    'unit',
    'unitPrice',
    'target',
    'isDeleted',
  ] as const;

  const procurementDataFields = ['procurementCode', 'procurementName'] as const;

  const workSectionDataFields = ['workSectionCode', 'workSectionName'] as const;

  if (!project) {
    return null;
  }

  const setField = (field: string, value: any) => {
    setMapping((prev) => ({ ...prev, [field]: value }));
  };

  return (
    <>
      <SelectColumnsPageContainer>
        <HeaderText>
          <Txt id="target.targetImportModal.selectColumns.header" />
        </HeaderText>
        <ParagraphText>
          <Txt
            id="target.targetImportModal.selectColumns.paragraph"
            values={{ projectCodeName: `${project.code} ${project.name}` }}
          />
        </ParagraphText>
        <ScrollDiv>
          <StyledTable>
            <StickyTableHeader>
              <tr>
                <TxtTh name="linked" />
                <TxtTh name="key" />
                <TxtTh name="csvColumn" />
                <TxtTh name="preview" />
              </tr>
            </StickyTableHeader>
            <tbody>
              <TableRow>
                <StyledCell colSpan={4}>
                  <Txt id="common.target" component="b" />
                </StyledCell>
              </TableRow>
              {targetDataFields.map((field) => {
                return (
                  <LinkingRow
                    field={field}
                    linked={!!mapping?.[field]}
                    selectedValue={mapping?.[field]}
                    previewData={initialData}
                    options={options}
                    setField={setField}
                    key={`selectColumns-tableRow-${field}`}
                  />
                );
              })}
              <TableRow>
                <StyledCell colSpan={4}>
                  <Txt id="procurement.header" component="b" />
                </StyledCell>
              </TableRow>
              {procurementDataFields.map((field) => {
                return (
                  <LinkingRow
                    field={field}
                    linked={!!mapping?.[field]}
                    selectedValue={mapping?.[field]}
                    previewData={initialData}
                    options={options}
                    setField={setField}
                    key={`selectColumns-tableRow-${field}`}
                  />
                );
              })}
              <TableRow>
                <StyledCell colSpan={4}>
                  <Txt id="worksection.header" component="b" />
                </StyledCell>
              </TableRow>
              {workSectionDataFields.map((field) => {
                return (
                  <LinkingRow
                    field={field}
                    linked={!!mapping?.[field]}
                    selectedValue={mapping?.[field]}
                    previewData={initialData}
                    options={options}
                    setField={setField}
                    key={`selectColumns-tableRow-${field}`}
                  />
                );
              })}
            </tbody>
          </StyledTable>
        </ScrollDiv>
      </SelectColumnsPageContainer>
    </>
  );
};

const SelectColumnsPageContainer = styled.div`
  position: relative;

  margin: 0;

  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const ScrollDiv = styled.div`
  overflow-y: auto;
`;

const TableRow = styled.tr``;

const StyledTable = styled(Table)`
  border: 1px solid ${({ theme }) => theme.color.rowBorder};
  border-collapse: separate;
`;

const StyledCell = styled(Cell)`
  border-bottom: 1px solid ${({ theme }) => theme.color.rowBorder};
  padding: ${({ theme: { margin } }) => `${margin[16]} ${margin[16]}`};
`;

const HeaderText = styled.h1`
  margin: ${({ theme }) => `0 0 ${theme.margin[8]}`} 0;
  display: flex;
  align-items: center;
`;

const ParagraphText = styled.p`
  margin: ${({ theme }) => `${theme.margin[16]}`} 0;

  width: 50%;

  display: flex;
  align-items: center;

  color: ${({ theme }) => theme.color.graphite};
`;

const CircleCheckMarkIcon = styled.div<{ linked: boolean }>`
  border-radius: 50%;
  width: 24px;
  height: 24px;
  background-color: ${({ linked, theme }) =>
    linked ? theme.color.positiveGreen : theme.color.graphiteB76};
`;

const StyledCheckMark = styled(IconCheckmark)`
  margin: 2px;
  width: 20px;
  height: 20px;
  /* stylelint-disable-next-line selector-max-type -- easiest way to edit svg */
  path {
    fill: white;
  }
`;

const LinkingRow = ({
  field,
  linked,
  options,
  setField,
  previewData,
  selectedValue,
}: {
  field: keyof typeof dataFields;
  linked: boolean;
  options: OptionsType;
  selectedValue: string | undefined;
  setField: (field: string, value: any) => void;
  previewData?: unknown[];
}) => {
  const mappedPreviewData = selectedValue
    ? previewData
        ?.map((data: any) => data[selectedValue])
        .filter((value) => isPresent(value) && value !== '')
        .map((value) => String(value))
        .slice(0, 3)
    : [];

  return (
    <TableRow key={`selectColumns-tableRow-${field}`}>
      <StyledCell align="center">
        <CircleCheckMarkIcon linked={linked}>
          <StyledCheckMark />
        </CircleCheckMarkIcon>
      </StyledCell>
      <StyledCell>
        <Txt id={dataFields[field].translation} />
      </StyledCell>
      <StyledCell>
        <DropDownSelect
          additionalContainerStyles={additionalContainerStyling}
          onChange={(value) => setField(field, value)}
          id={field}
          value={selectedValue}
          options={options}
          label={`selectColumns-dropdown-${field}`}
        />
      </StyledCell>
      <StyledCell>
        <ul>
          {mappedPreviewData?.map((data, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <li key={`preview-${field}-${index}`}>{data}</li>
          ))}
        </ul>
      </StyledCell>
    </TableRow>
  );
};

const additionalContainerStyling: React.CSSProperties = {
  width: '100%',
  height: defaultTheme.margin[36],
  margin: `${defaultTheme.margin[4]} 0 ${defaultTheme.margin[4]} ${defaultTheme.margin[8]}`,
};

export default SelectColumnsPage;
