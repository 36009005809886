import styled from 'styled-components';
import * as uuid from 'uuid';

export const highlightText = (text: string, input?: string) => {
  if (!input) {
    return [text];
  }

  const escapedInput = input.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');

  const regex = new RegExp(`(${escapedInput})`, 'gi');
  const parts = text.split(regex);

  return parts.map((part) =>
    part.toLowerCase() === input.toLowerCase() ? (
      <HighlightedSpan key={uuid.v4()}>{part}</HighlightedSpan>
    ) : (
      part
    )
  );
};

const HighlightedSpan = styled.span`
  background-color: yellow;
`;
