import { en } from './en';
import { fi } from './fi';

export const texts = {
  fi,
  en,
};

export type Language = keyof typeof texts;
type FiTextId = keyof typeof fi;

type EnTextId = keyof typeof en;

export type TextId = Extract<FiTextId, EnTextId>;

export const isTextId = (textId: string): textId is TextId => {
  return textId in fi && textId in en;
};
