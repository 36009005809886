import { APIUpdatedEntities, RawAPIUpdatedEntities } from '../../../types/api';
import { mapRawUpdatedEntities } from '../../../types/mappers';

import { makeAction, ExtractActionTypes } from '../../../utils/actionCreators';
import {
  POST,
  BackendError,
  apiErrorHandlingWithDecode,
} from '../../../utils/api';
import { createAsyncThunk } from '../../../utils/thunk';

const actionCreators = {
  ...makeAction('postOrderRowAnalysisLinkStarted')<{
    projectId: string;
    requestId: string;
  }>(),
  ...makeAction('postOrderRowAnalysisLinkFailure')<{
    requestId: string;
    projectId: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('postOrderRowAnalysisLinkSuccess')<
    {
      projectId: string;
      requestId: string;
    } & APIUpdatedEntities
  >(),

  ...makeAction('removeOrderRowAnalysisLinkStarted')<{
    projectId: string;
    requestId: string;
  }>(),
  ...makeAction('removeOrderRowAnalysisLinkFailure')<{
    projectId: string;
    requestId: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('removeOrderRowAnalysisLinkSuccess')<
    {
      projectId: string;
      requestId: string;
    } & APIUpdatedEntities
  >(),
};

export const {
  postOrderRowAnalysisLinkStarted,
  postOrderRowAnalysisLinkFailure,
  postOrderRowAnalysisLinkSuccess,
  removeOrderRowAnalysisLinkStarted,
  removeOrderRowAnalysisLinkFailure,
  removeOrderRowAnalysisLinkSuccess,
} = actionCreators;

export type OrderRowLinkageAction = ExtractActionTypes<typeof actionCreators>;

type OrderRowAnalysisLink = {
  entityId: string;
  analysisRowId: string;
};

type OrderRowLinkRequest = {
  projectId: string;
  requestId: string;
} & OrderRowAnalysisLink;

const postOrderRowAnalysisLink =
  (url: string) =>
  async ({ analysisRowId, entityId }: OrderRowAnalysisLink) => {
    const response = await POST<RawAPIUpdatedEntities>(url, {
      analysisListItemId: analysisRowId,
      orderRowId: entityId,
    });

    return mapRawUpdatedEntities(response);
  };

const addOrderRowAnalysisLink = postOrderRowAnalysisLink(
  'v1/order-rows/analysis-list-item-linkage'
);

export const createOrderRowAnalysisLinkage = ({
  entityId: orderRowId,
  projectId,
  requestId,
  analysisRowId,
}: OrderRowLinkRequest) =>
  createAsyncThunk(addOrderRowAnalysisLink, {
    args: [{ entityId: orderRowId, analysisRowId }],
    initialAction: postOrderRowAnalysisLinkStarted({ projectId, requestId }),
    failureActionCreator: (error) =>
      postOrderRowAnalysisLinkFailure({
        projectId,
        requestId,
        error: apiErrorHandlingWithDecode(error),
      }),
    successActionCreator: (updatedEntities) =>
      postOrderRowAnalysisLinkSuccess({
        ...updatedEntities,
        projectId,
        requestId,
      }),
  });

const deleteOrderRowAnalysisLink = postOrderRowAnalysisLink(
  'v1/order-rows/remove-analysis-list-item-linkage'
);

export const removeOrderRowAnalysisLinkage = ({
  entityId: orderRowId,
  projectId,
  requestId,
  analysisRowId,
}: OrderRowLinkRequest) =>
  createAsyncThunk(deleteOrderRowAnalysisLink, {
    args: [{ entityId: orderRowId, analysisRowId }],
    initialAction: removeOrderRowAnalysisLinkStarted({ projectId, requestId }),
    failureActionCreator: (error) =>
      removeOrderRowAnalysisLinkFailure({
        projectId,
        requestId,
        error: apiErrorHandlingWithDecode(error),
      }),
    successActionCreator: (updatedEntities) =>
      removeOrderRowAnalysisLinkSuccess({
        ...updatedEntities,
        projectId,
        requestId,
      }),
  });
