import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';

import { APIUpdatedEntities, RawAPIUpdatedEntities } from '../../../types/api';
import { mapRawUpdatedEntities } from '../../../types/mappers';

import { makeAction, ExtractActionTypes } from '../../../utils/actionCreators';
import {
  GET,
  BackendError,
  apiErrorHandlingWithDecode,
  POST,
  PUT,
  DELETE,
  downloadFile,
} from '../../../utils/api';
import { dateString, bigString } from '../../../utils/decoders';
import { flow } from '../../../utils/function';
import * as remoteData from '../../../utils/remoteData';
import { createAsyncThunk, Thunk } from '../../../utils/thunk';

import {
  getAnalysisRow,
  getAnalysisRowCreateRequest,
  getAnalysisRowsForProject,
  getAnalysisRowUpdateRequest,
  getDetailedAnalysisCsvFetchRequest,
} from '../../reducers/analysis/row';
import { SortableKey } from '../../reducers/analysis/sortOrders';

const actionCreators = {
  ...makeAction('getAnalysisRowsStarted')<{ projectId: string }>(),
  ...makeAction('getAnalysisRowsFailure')<{
    projectId: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('getAnalysisRowsSuccess')<{
    projectId: string;
    analysisRows: AnalysisRow[];
  }>(),

  ...makeAction('postAnalysisRowStarted')<{
    requestId: string;
    projectId: string;
  }>(),
  ...makeAction('postAnalysisRowFailure')<{
    requestId: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('postAnalysisRowSuccess')<
    APIUpdatedEntities & { requestId: string; projectId: string }
  >(),
  ...makeAction('putAnalysisRowStarted')<{
    requestId: string;
    projectId: string;
  }>(),
  ...makeAction('putAnalysisRowFailure')<{
    requestId: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('putAnalysisRowSuccess')<
    APIUpdatedEntities & { requestId: string; projectId: string }
  >(),
  ...makeAction('deleteAnalysisRowStarted')<{
    requestId: string;
    projectId: string;
  }>(),
  ...makeAction('deleteAnalysisRowFailure')<{
    requestId: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('deleteAnalysisRowSuccess')<
    APIUpdatedEntities & { requestId: string; projectId: string }
  >(),
  ...makeAction('analysisRowSortToggled')<{
    analysisGroupId: string;
    sortableKey: SortableKey;
    attributeId?: string;
  }>(),
  ...makeAction('getDetailedAnalysisCsvStarted')<{ analysisGroupId: string }>(),
  ...makeAction('getDetailedAnalysisCsvFailure')<{
    analysisGroupId: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('getDetailedAnalysisCsvSuccess')<{ analysisGroupId: string }>(),
};
export const {
  getAnalysisRowsStarted,
  getAnalysisRowsFailure,
  getAnalysisRowsSuccess,
  postAnalysisRowStarted,
  postAnalysisRowFailure,
  postAnalysisRowSuccess,
  putAnalysisRowStarted,
  putAnalysisRowFailure,
  putAnalysisRowSuccess,
  deleteAnalysisRowStarted,
  deleteAnalysisRowFailure,
  deleteAnalysisRowSuccess,
  analysisRowSortToggled,
  getDetailedAnalysisCsvStarted,
  getDetailedAnalysisCsvFailure,
  getDetailedAnalysisCsvSuccess,
} = actionCreators;

export type AnalysisRowAction = ExtractActionTypes<typeof actionCreators>;

type ProjectId = {
  projectId: string;
};

const apiBaseAttribute = t.exact(
  t.type({
    id: t.string,
    attributeId: t.string,
    attributeName: t.string,
    value: t.string,
    updatedAt: dateString,
    createdAt: dateString,
  })
);

const apiSelectionListAttribute = t.intersection([
  apiBaseAttribute,
  t.exact(
    t.type({
      type: t.literal('SelectionList'),
      attributeListItemId: t.string,
    })
  ),
]);

const apiDateAttribute = t.intersection([
  apiBaseAttribute,
  t.exact(
    t.type({
      type: t.literal('Date'),
    })
  ),
]);

const apiNumberAttribute = t.intersection([
  apiBaseAttribute,
  t.exact(
    t.type({
      type: t.literal('Number'),
    })
  ),
]);

const apiTextAttribute = t.intersection([
  apiBaseAttribute,
  t.exact(
    t.type({
      type: t.literal('Text'),
    })
  ),
]);

const apiAttribute = t.union([
  apiSelectionListAttribute,
  apiDateAttribute,
  apiNumberAttribute,
  apiTextAttribute,
]);

const apiAnalysisRow = t.exact(
  t.type({
    id: t.string,
    definitionId: t.string,
    projectId: t.string,
    value: t.string,
    code: t.string,
    orderNumber: t.number,
    orderRowsAmount: t.union([bigString, t.null]),
    targetRowsAmount: t.union([bigString, t.null]),
    arrivalsAmount: t.union([bigString, t.null]),
    paymentProgramRowsAmount: t.union([bigString, t.null]),
    attributeValues: t.array(apiAttribute),
    linkedOrderRowIds: t.array(t.string),
    linkedTargetRowIds: t.array(t.string),
    linkedPaymentProgramRowIds: t.array(t.string),
    linkedPurchaseInvoiceHeaderIds: t.array(t.string),
    linkedActualCostIds: t.array(t.string),
    isDeleted: t.boolean,
    createdAt: dateString,
    updatedAt: dateString,
  })
);

export type AnalysisRow = t.TypeOf<typeof apiAnalysisRow>;
export type AnalysisRowAttribute = t.TypeOf<typeof apiAttribute>;
export type AnalysisRowSelectionListAttribute = t.TypeOf<
  typeof apiSelectionListAttribute
>;

export async function decodeAnalysisRows(u: unknown): Promise<AnalysisRow[]> {
  return tPromise.decode(t.array(apiAnalysisRow), u);
}

const fetchAnalysisRows = async ({
  projectId,
}: ProjectId): Promise<AnalysisRow[]> => {
  return decodeAnalysisRows(
    await GET(`v1/projects/${projectId}/analysis-list-items`)
  );
};

export const requestAnalysisRows = ({ projectId }: ProjectId): Thunk =>
  createAsyncThunk(fetchAnalysisRows, {
    args: [{ projectId }],
    isPending: flow(getAnalysisRowsForProject(projectId), remoteData.isLoading),
    initialAction: getAnalysisRowsStarted({ projectId }),
    failureActionCreator: (error) =>
      getAnalysisRowsFailure({
        projectId,
        error: apiErrorHandlingWithDecode(error),
      }),
    successActionCreator: (analysisRows) =>
      getAnalysisRowsSuccess({ projectId, analysisRows }),
  });

const postNewAnalysisRow = async ({
  analysisGroupId,
}: {
  analysisGroupId: string;
  projectId: string;
}): Promise<APIUpdatedEntities> => {
  const response = await POST<RawAPIUpdatedEntities>(
    `v1/custom-fields/${analysisGroupId}/list-items`,
    {
      code: null,
      value: '',
      orderNumber: 0,
    }
  );

  return mapRawUpdatedEntities(response);
};

type NewAnalysisRowRequest = {
  requestId: string;
  projectId: string;
  analysisGroupId: string;
};

export const requestNewAnalysisRow =
  ({ requestId, projectId, analysisGroupId }: NewAnalysisRowRequest): Thunk =>
  (dispatch) => {
    dispatch(
      createAsyncThunk(postNewAnalysisRow, {
        args: [{ projectId, analysisGroupId }],
        isPending: flow(
          getAnalysisRowCreateRequest(requestId),
          remoteData.isLoading
        ),
        initialAction: postAnalysisRowStarted({ requestId, projectId }),
        successActionCreator: (updatedEntities) =>
          postAnalysisRowSuccess({ ...updatedEntities, requestId, projectId }),
        failureActionCreator: (error) =>
          postAnalysisRowFailure({
            requestId,
            error: apiErrorHandlingWithDecode(error),
          }),
      })
    );
  };

const updateAnalysisRow = async ({
  id,
  code,
  value,
  orderNumber,
  updatedAt,
}: AnalysisRow): Promise<APIUpdatedEntities> => {
  const rowId = id;

  const response = await PUT<RawAPIUpdatedEntities>(
    `v1/custom-fields/list-items/${rowId}`,
    {
      code,
      value,
      orderNumber,
      updatedAt,
    }
  );

  return mapRawUpdatedEntities(response);
};

type AnalysisRowUpdateOptions = {
  requestId: string;
  analysisRowId: string;
  projectId: string;
};

export const requestAnalysisRowUpdate =
  (
    updatedFields: Partial<AnalysisRow>,
    { requestId, analysisRowId, projectId }: AnalysisRowUpdateOptions,
    forcedRequest?: boolean
  ): Thunk =>
  (dispatch, getState) => {
    const analysisRow = getAnalysisRow({ analysisRowId, projectId })(
      getState()
    );

    const currentRow = remoteData.unwrap(analysisRow, {
      unwrapper: (row) => row,
      defaultValue: undefined,
    });

    if (currentRow === undefined) {
      return;
    }

    dispatch(
      createAsyncThunk(updateAnalysisRow, {
        args: [{ ...currentRow, ...updatedFields }],
        isPending: forcedRequest
          ? undefined
          : flow(getAnalysisRowUpdateRequest(requestId), remoteData.isLoading),
        initialAction: putAnalysisRowStarted({ requestId, projectId }),
        successActionCreator: (updatedEntities) =>
          putAnalysisRowSuccess({ ...updatedEntities, requestId, projectId }),
        failureActionCreator: (error) =>
          putAnalysisRowFailure({
            requestId,
            error: apiErrorHandlingWithDecode(error),
          }),
      })
    );
  };

type DeleteAnalysisRowRequest = {
  requestId: string;
  projectId: string;
  analysisRowId: string;
};

const deleteAnalysisRow = async (
  analysisRowId: string
): Promise<APIUpdatedEntities> => {
  const response = await DELETE<RawAPIUpdatedEntities>(
    `v1/custom-fields/list-items/${analysisRowId}`
  );

  return mapRawUpdatedEntities(response);
};

export const makeDeleteAnalysisRowApiRequest = (analysisRowId: string) =>
  DELETE<RawAPIUpdatedEntities>(
    `v1/custom-fields/list-items/${analysisRowId}`
  ).then(mapRawUpdatedEntities);

export const requestDeleteAnalysisRow =
  ({ requestId, projectId, analysisRowId }: DeleteAnalysisRowRequest): Thunk =>
  (dispatch) => {
    dispatch(
      createAsyncThunk(deleteAnalysisRow, {
        args: [analysisRowId],
        isPending: flow(
          getAnalysisRowCreateRequest(requestId),
          remoteData.isLoading
        ),
        initialAction: deleteAnalysisRowStarted({ requestId, projectId }),
        successActionCreator: (updatedEntities) =>
          deleteAnalysisRowSuccess({
            ...updatedEntities,
            requestId,
            projectId,
          }),
        failureActionCreator: (error) =>
          deleteAnalysisRowFailure({
            requestId,
            error: apiErrorHandlingWithDecode(error),
          }),
      })
    );
  };

const getDetailedAnalysisCsv = async (analysisGroupId: string) => {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  await downloadFile(
    `v1/custom-fields/${analysisGroupId}/detailed-analysis-list-items`,
    { timeZone }
  );
};

export const fetchDetailedAnalysesCsv = (analysisGroupId: string) =>
  createAsyncThunk(getDetailedAnalysisCsv, {
    args: [analysisGroupId],
    isPending: flow(
      getDetailedAnalysisCsvFetchRequest(analysisGroupId),
      remoteData.isLoading
    ),
    initialAction: getDetailedAnalysisCsvStarted({ analysisGroupId }),
    successActionCreator: () =>
      getDetailedAnalysisCsvSuccess({
        analysisGroupId,
      }),
    failureActionCreator: (error) =>
      getDetailedAnalysisCsvFailure({
        analysisGroupId,
        error: apiErrorHandlingWithDecode(error),
      }),
  });
