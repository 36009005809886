import React from 'react';
import { useDispatch } from 'react-redux';

import { UserCredential } from 'firebase/auth';
import { isError } from 'lodash';

import * as Actions from '../../store/actions';

import useTxt from '../../hooks/useTxt';

import { AuthTitle } from '../../components/Auth';
import AuthContainer from '../../components/Auth/AuthPageContainer';

import { setBaseUrl, signInWithEmail, useAsyncError } from '../../utils/auth';

function FinishSignInPage() {
  const emailSentText = useTxt('auth.email.signIn.finishSignIn');

  const dispatch = useDispatch();

  const { setError } = useAsyncError();

  const finishAuth = async (authResponse: UserCredential | Error) => {
    try {
      if (isError(authResponse)) {
        setError(authResponse);

        return;
      }

      const navigate = () => {
        window.location.href = '/';
      };

      const { email } = authResponse.user;

      if (email) {
        setBaseUrl(email);
      }

      // TODO: Set back-end env variable

      dispatch(Actions.fetchUserState(navigate, setError));
    } catch (error) {
      // console.log(error);
    }
  };

  React.useEffect(() => {
    signInWithEmail(window.location.href, finishAuth);
  });

  return (
    <AuthContainer>
      <AuthTitle>{emailSentText}</AuthTitle>
    </AuthContainer>
  );
}

export default FinishSignInPage;
