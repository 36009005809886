import Big from 'big.js';
import { sortBy } from 'lodash';
import { Reducer } from 'redux';

import { ID, HandlingState } from '../../types/general';

import {
  assertActionPayloadIsNotApiUpdatedEntities,
  Selector,
  isUpdatedEntitiesActionType,
} from './utils';
import * as api from '../../utils/api';
import {
  isDefined,
  ALL_UNPROCESSED_INVOICE_STATUSES,
} from '../../utils/general';
import normalizeBy from '../../utils/normalizeBy';
import * as remoteData from '../../utils/remoteData';

import { ActionTypes } from '../actionTypes';
import { getAnalysisRowsForProject } from './analysis/row';
import { sortItems } from './helpers/sort';
import { getSortOrders } from './invoice/sortInvoiceHeaders';
import { getOrderById } from './order/order';

import { AppState } from '.';

export type InvoiceHeader = {
  id: ID;
  isDeleted: boolean;
  orderId: ID;
  purchaseInvoiceExternalId: string;
  projectId: ID | null;
  supplierId: ID | null;
  supplierName: String | null;
  orderDate: Date | null;
  postingDate: Date | null;
  paymentDate: Date | null;
  dueDate: Date;
  documentDate: Date;
  currencyCode: string;
  currencyFactor: Big;
  amount: Big;
  amountIncludingVat: Big;
  invoiceNumber: string | null;
  yourReference: string | null;
  comment: string | null;
  vendorOrderNo: string | null;
  vendorInvoiceNo: string;
  canceled: boolean;
  paid: boolean;
  approved: boolean;
  approvalDate: Date | null;
  remainingAmount: Big;
  invoiceLink: string | null;
  messageType: string | null;
  messageTypeText: string | null;
  invoiceMessage: string | null;
  invoiceMessage2: string | null;
  handleVatAmountPending: Big;
  handleNetAmountPending: Big;
  isMovable: boolean;
  handlingState: HandlingState;
  statusId: ID;
  createdAt: Date;
  updatedAt: Date;
  purchaseInvoiceAttachmentIds: ID[];
  purchaseInvoiceLineIds: ID[];
  latestUpdater: string | null;
  latestStatusChangeAt: Date | null;
  isSpreadAvailable: boolean;
};

type Err = api.BackendError | undefined;

export type InvoiceHeaderState = {
  requests: Partial<Record<string, remoteData.RemoteData<undefined, Err>>>;
  putRequests: Partial<Record<string, remoteData.RemoteData<undefined, Err>>>;
  analysisRowRequests: Partial<
    Record<string, remoteData.RemoteData<undefined, Err>>
  >;
  startCorrectionRequests: Partial<
    Record<string, remoteData.RemoteData<undefined, Err>>
  >;
  finishCorrectionRequests: Partial<
    Record<string, remoteData.RemoteData<undefined, Err>>
  >;
  data: Partial<Record<string, InvoiceHeader>>;
};

const initialState: InvoiceHeaderState = {
  requests: {},
  putRequests: {},
  analysisRowRequests: {},
  startCorrectionRequests: {},
  finishCorrectionRequests: {},
  data: {},
};

const invoiceHeaderReducer: Reducer<InvoiceHeaderState, ActionTypes> = (
  state = initialState,
  action
): InvoiceHeaderState => {
  switch (action.type) {
    case 'GET_INVOICE_HEADERS_STARTED': {
      const { orderId } = action.payload;
      const requests = { ...state.requests, [orderId]: remoteData.loading };

      return {
        ...state,
        requests,
      };
    }
    case 'GET_INVOICE_HEADERS_FAILURE': {
      const { orderId, error } = action.payload;
      const requests = { ...state.requests, [orderId]: remoteData.fail(error) };

      return { ...state, requests };
    }
    case 'GET_INVOICE_HEADERS_SUCCESS': {
      const { orderId, invoiceHeaders } = action.payload;

      const requests = {
        ...state.requests,
        [orderId]: remoteData.succeed(undefined),
      };

      const data = {
        ...state.data,
        ...normalizeBy('id', invoiceHeaders),
      };

      return {
        ...state,
        requests,
        data,
      };
    }
    case 'GET_INVOICE_HEADERS_FOR_ANALYSIS_ROW_STARTED': {
      const { analysisRowId } = action.payload;

      const analysisRowRequests = {
        ...state.analysisRowRequests,
        [analysisRowId]: remoteData.loading,
      };

      return {
        ...state,
        analysisRowRequests,
      };
    }
    case 'GET_INVOICE_HEADERS_FOR_ANALYSIS_ROW_FAILURE': {
      const { analysisRowId, error } = action.payload;

      const analysisRowRequests = {
        ...state.analysisRowRequests,
        [analysisRowId]: remoteData.fail(error),
      };

      return { ...state, analysisRowRequests };
    }
    case 'GET_INVOICE_HEADERS_FOR_ANALYSIS_ROW_SUCCESS': {
      const { analysisRowId, invoiceHeaders } = action.payload;

      const analysisRowRequests = {
        ...state.analysisRowRequests,
        [analysisRowId]: remoteData.succeed(undefined),
      };

      const data = {
        ...state.data,
        ...normalizeBy('id', invoiceHeaders),
      };

      return {
        ...state,
        analysisRowRequests,
        data,
      };
    }
    case 'PUT_INVOICE_HEADER_START_CORRECTION_STARTED': {
      const { purchaseInvoiceHeaderId } = action.payload;

      const startCorrectionRequests = {
        ...state.startCorrectionRequests,
        [purchaseInvoiceHeaderId]: remoteData.loading,
      };

      return {
        ...state,
        startCorrectionRequests,
      };
    }
    case 'PUT_INVOICE_HEADER_START_CORRECTION_FAILURE': {
      const { purchaseInvoiceHeaderId, error } = action.payload;

      const startCorrectionRequests = {
        ...state.startCorrectionRequests,
        [purchaseInvoiceHeaderId]: remoteData.fail(error),
      };

      return { ...state, startCorrectionRequests };
    }
    case 'PUT_INVOICE_HEADER_START_CORRECTION_SUCCESS': {
      const {
        purchaseInvoiceHeaderId,
        purchaseInvoiceHeaders: updatedInvoiceHeaders = [],
      } = action.payload;

      if (updatedInvoiceHeaders.length === 0) {
        return state;
      }

      const data = { ...state.data };

      updatedInvoiceHeaders.forEach((invoiceHeader) => {
        const { id, isDeleted } = invoiceHeader;

        if (isDeleted) {
          delete data[id];
        } else {
          data[id] = invoiceHeader;
        }
      });

      const startCorrectionRequests = {
        ...state.startCorrectionRequests,
        [purchaseInvoiceHeaderId]: remoteData.succeed(undefined),
      };

      return {
        ...state,
        startCorrectionRequests,
        data,
      };
    }
    case 'PUT_INVOICE_HEADER_FINISH_CORRECTION_STARTED': {
      const { purchaseInvoiceHeaderId } = action.payload;

      const finishCorrectionRequests = {
        ...state.finishCorrectionRequests,
        [purchaseInvoiceHeaderId]: remoteData.loading,
      };

      return {
        ...state,
        finishCorrectionRequests,
      };
    }
    case 'PUT_INVOICE_HEADER_FINISH_CORRECTION_FAILURE': {
      const { purchaseInvoiceHeaderId, error } = action.payload;

      const finishCorrectionRequests = {
        ...state.finishCorrectionRequests,
        [purchaseInvoiceHeaderId]: remoteData.fail(error),
      };

      return { ...state, finishCorrectionRequests };
    }
    case 'PUT_INVOICE_HEADER_FINISH_CORRECTION_SUCCESS': {
      const {
        purchaseInvoiceHeaderId,
        purchaseInvoiceHeaders: updatedInvoiceHeaders = [],
      } = action.payload;

      if (updatedInvoiceHeaders.length === 0) {
        return state;
      }

      const data = { ...state.data };

      updatedInvoiceHeaders.forEach((invoiceHeader) => {
        const { id, isDeleted } = invoiceHeader;

        if (isDeleted) {
          delete data[id];
        } else {
          data[id] = invoiceHeader;
        }
      });

      const finishCorrectionRequests = {
        ...state.finishCorrectionRequests,
        [purchaseInvoiceHeaderId]: remoteData.succeed(undefined),
      };

      return {
        ...state,
        finishCorrectionRequests,
        data,
      };
    }
    case 'PUT_INVOICE_HEADER_STARTED': {
      const { invoiceHeaderId } = action.payload;

      const putRequests = {
        ...state.putRequests,
        [invoiceHeaderId]: remoteData.loading,
      };

      return {
        ...state,
        putRequests,
      };
    }
    case 'PUT_INVOICE_HEADER_FAILURE': {
      const { invoiceHeaderId, error } = action.payload;

      const putRequests = {
        ...state.putRequests,
        [invoiceHeaderId]: remoteData.fail(error),
      };

      return { ...state, putRequests };
    }
    case 'PUT_INVOICE_HEADER_SUCCESS': {
      const {
        invoiceHeaderId,
        purchaseInvoiceHeaders: updatedInvoiceHeaders = [],
      } = action.payload;

      const putRequests = {
        ...state.putRequests,
        [invoiceHeaderId]: remoteData.succeed(undefined),
      };

      if (updatedInvoiceHeaders.length === 0) {
        return {
          ...state,
          putRequests,
        };
      }

      const data = { ...state.data };

      updatedInvoiceHeaders.forEach((invoiceHeader) => {
        const { id, isDeleted } = invoiceHeader;

        if (isDeleted) {
          delete data[id];
        } else {
          data[id] = invoiceHeader;
        }
      });

      return {
        ...state,
        data,
        putRequests,
      };
    }
  }

  if (isUpdatedEntitiesActionType(action)) {
    const { purchaseInvoiceHeaders: updatedInvoiceHeaders = [] } =
      action.payload;

    if (updatedInvoiceHeaders.length === 0) {
      return state;
    }

    const data = { ...state.data };

    updatedInvoiceHeaders.forEach((invoiceHeader) => {
      const { id, isDeleted } = invoiceHeader;

      if (isDeleted) {
        delete data[id];
      } else {
        data[id] = invoiceHeader;
      }
    });

    return {
      ...state,
      data,
    };
  }

  assertActionPayloadIsNotApiUpdatedEntities(action);

  return state;
};

export const selectOrderInvoiceHeadersRequests =
  (orderId: string) =>
  ({
    invoiceHeaders: {
      invoiceHeaderCollection: {
        requests: { [orderId]: requestState = remoteData.notAsked },
      },
    },
  }: AppState) =>
    requestState;

export const selectAnalysisRowInvoiceHeadersRequests =
  (analysisRowId: string) =>
  ({
    invoiceHeaders: {
      invoiceHeaderCollection: {
        analysisRowRequests: {
          [analysisRowId]: requestState = remoteData.notAsked,
        },
      },
    },
  }: AppState) =>
    requestState;

export const getInvoiceHeaderById =
  (id: string) =>
  ({
    invoiceHeaders: {
      invoiceHeaderCollection: {
        data: { [id]: invoiceHeader },
      },
    },
  }: AppState) =>
    invoiceHeader;

export const getInvoiceHeadersByIds =
  (ids: string[]) =>
  ({
    invoiceHeaders: {
      invoiceHeaderCollection: { data },
    },
  }: AppState) => {
    const emptyArray: InvoiceHeader[] = [];

    const invoices = ids.reduce((array, id) => {
      const invoice = data[id];

      if (invoice) {
        const newArray = array.concat(invoice);

        return newArray;
      }

      return array;
    }, emptyArray);

    return invoices;
  };

export const getInvoiceHeadersByOrderId =
  (orderId: string) => (appState: AppState) => {
    const orderInvoiceHeaders =
      getOrderById(orderId)(appState)?.purchaseInvoiceHeaderIds ?? [];

    return orderInvoiceHeaders
      .map((id) => getInvoiceHeaderById(id)(appState))
      .filter(isDefined);
  };

export const getFirstHandleableInvoiceHeaderByOrderId =
  (orderId: string) => (appState: AppState) => {
    const orderInvoiceHeaders = getInvoiceHeadersByOrderId(orderId)(appState);

    const firstHandleableInvoice = orderInvoiceHeaders.find(
      (invoice) => invoice.handlingState === 'CanBeHandled'
    );

    return firstHandleableInvoice;
  };

export const getSortedInvoiceHeadersByOrderId =
  (orderId: string) => (appState: AppState) => {
    const orderInvoiceHeaders = getInvoiceHeadersByOrderId(orderId)(appState);

    const sortedHeaders = sortBy(
      orderInvoiceHeaders,
      (header) => -Number(header.id)
    );

    return sortedHeaders;
  };

export const getUnhandledInvoiceHeadersByOrderId =
  (orderId: string) => (appState: AppState) => {
    const allInvoices = getSortedInvoiceHeadersByOrderId(orderId)(appState);
    const sortOrder = getSortOrders(appState);

    const unhandledInvoices = allInvoices.filter(({ statusId }) =>
      ALL_UNPROCESSED_INVOICE_STATUSES.includes(statusId)
    );

    return sortItems(unhandledInvoices, sortOrder.unhandled);
  };

export const getUnhandledInvoiceHeaderCountByOrderId =
  (orderId: string) => (appState: AppState) => {
    return getUnhandledInvoiceHeadersByOrderId(orderId)(appState).length;
  };

// TODO: ask why this is named unhandled? seems to get all invoices
export const selectUnHandledInvoiceHeadersByOrderId =
  (orderId: string) =>
  ({
    invoiceHeaders: {
      invoiceHeaderCollection: {
        requests: { [orderId]: requestState = remoteData.notAsked },
        data,
      },
    },
  }: AppState): remoteData.RemoteData<InvoiceHeader[]> =>
    remoteData.map(requestState, (_) =>
      Object.values(data)
        .filter(isDefined)
        .sort((a, b) => (a.id < b.id ? 1 : -1))
    );

export const getHandledInvoiceHeadersByOrderId =
  (orderId: string) => (appState: AppState) => {
    const allInvoices = getSortedInvoiceHeadersByOrderId(orderId)(appState);
    const sortOrder = getSortOrders(appState);

    const handledInvoices = allInvoices.filter(
      ({ statusId }) => !ALL_UNPROCESSED_INVOICE_STATUSES.includes(statusId)
    );

    return sortItems(handledInvoices, sortOrder.handled);
  };

export const getInvoicesForAnalysisRow =
  ({
    projectId,
    analysisRowId,
  }: {
    projectId: string;
    analysisRowId: string;
  }) =>
  (appState: AppState): remoteData.RemoteData<InvoiceHeader[]> => {
    const requestState =
      appState.invoiceHeaders.invoiceHeaderCollection.analysisRowRequests[
        analysisRowId
      ] ?? remoteData.notAsked;

    const { data } = appState.invoiceHeaders.invoiceHeaderCollection;

    const allAnalysisRows = getAnalysisRowsForProject(projectId)(appState);

    const analysisRowWithDefault = remoteData
      .withDefault(allAnalysisRows, [])
      .find((row) => row.id === analysisRowId);

    const remoteState = remoteData.map(requestState, (_) => {
      if (!analysisRowWithDefault) {
        return [];
      }

      const invoiceIds = analysisRowWithDefault.linkedPurchaseInvoiceHeaderIds;

      const invoices = Object.values(data)
        .filter(isDefined)
        .filter((invoice) => invoiceIds.includes(invoice.id));

      return invoices;
    });

    return remoteState;
  };

export const getInvoiceUpdateRequest =
  (invoiceHeaderId: string): Selector<remoteData.RemoteAction> =>
  ({
    invoiceHeaders: {
      invoiceHeaderCollection: {
        putRequests: { [invoiceHeaderId]: request },
      },
    },
  }) =>
    request ?? remoteData.notAsked;

export default invoiceHeaderReducer;
