import React from 'react';

import styled, { css } from 'styled-components';

import { IconInvalid } from '../../assets';

import TextArea from './TextArea';
import { ErrorMessage } from './TextInput';

interface TextAreaWithLabelProps {
  label: string;
  id: string;
  errorMessage: string | undefined;
  placeholder?: string;
  value: string;
  setFieldValue: (value: string) => void;
  rows?: number;
}

const TextAreaWithLabel = ({
  label,
  id,
  placeholder,
  errorMessage,
  setFieldValue,
  value,
  rows = 5,
}: TextAreaWithLabelProps) => {
  return (
    <>
      <Label htmlFor={id} invalid={!!errorMessage}>
        {label}
      </Label>
      <TextAreaWithErrorCapability
        error={!!errorMessage}
        id={id}
        value={value}
        onChange={(e) => setFieldValue(e.target.value)}
        rows={rows}
        placeholder={placeholder}
      />
      {!!errorMessage ? <ErrorMessage>{errorMessage}</ErrorMessage> : null}
    </>
  );
};

interface LabelProps {
  invalid?: boolean;
}

const Label = styled.label<LabelProps>`
  ${(props) => css`
    padding: 0 0 ${props.theme.margin[8]} 0;
  `}

  display: block;
  font-weight: bold;
  ${(props) =>
    props.invalid &&
    css`
      color: ${props.theme.color.red};
    `}
`;

interface TextAreaWithErrorCapabilityProps {
  error: boolean;
}

const TextAreaWithErrorCapability = styled(
  TextArea
)<TextAreaWithErrorCapabilityProps>`
  border: ${({ theme, error }) =>
    error ? '1px solid red;' : `1px solid ${theme.color.graphiteB86}`};
  border-radius: ${({ theme }) => theme.radius.small};
  min-height: ${({ theme }) => theme.margin[96]};

  ${(props) =>
    props.error &&
    `background: ${props.theme.color.white} url(${IconInvalid}) no-repeat;
      background-position: right ${props.theme.margin[8]} top ${props.theme.margin[8]};
    `}
`;

export default TextAreaWithLabel;
