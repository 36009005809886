import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import _ from 'lodash';
import styled from 'styled-components';
import { v4 } from 'uuid';

import { getUnSpecifiedOrder } from '../../../../store/reducers/order/order';
import {
  getOriginalTargetUpdateRequest,
  hasProjectAnyTargetRows,
} from '../../../../store/reducers/target/targetRows';
import { getUnSpecifiedWorkPackage } from '../../../../store/reducers/workPackage';

import {
  fetchProcurementAreasForProject,
  fetchWorkPackagesForProject,
  requestProjectOriginalTargetUpdate,
  fetchTopicsForProject,
} from '../../../../store/actions';

import useLocalization from '../../../../hooks/useLocalization';
import useTargetViewData from '../../hooks/useTargetViewData';

import { StepIndicator } from '../../../../components/Modal/Components';
import Modal, {
  Content,
  HeaderWithSteps,
  HeaderWithStepsCloseButton,
} from '../../../../components/Modal/Modal';
import Txt from '../../../../components/Txt';

import {
  automaticPreMapping,
  ExtendedOrder,
  ExtendedTargetRow,
  ExtendedTargetRowHierarchyEntry,
  ExtendedTopic,
  ExtendedWorkPackage,
  hasAllTargetImportDataFields,
  processCsvData,
  TargetImportData,
  validatedDataToOtherTempData,
  validatedDataToTempHierarchyEntries,
  validatedDataToTempTargetRows,
} from './utils';
import { parseCsv } from '../../../../utils/csvParse';
import { withDefault } from '../../../../utils/remoteData';

import { routes, useParams } from '../../../../routes';

import FileUpload from './FileUpload';
import { TargetModalFooter } from './Footer';
import PreviewTable from './PreviewTable';
import SelectColumnsPage from './SelectColumnsPage';

export type TargetImportModalProps = {
  onClose: () => void;
};

const modalSteps = [
  'target.targetImportModal.steps.uploadFile' as const,
  'target.targetImportModal.steps.selectColumns' as const,
  'target.targetImportModal.steps.previewData' as const,
];

export type FilterState =
  | 'invalid'
  | 'changed'
  | 'create'
  | 'delete'
  | 'update'
  | 'noOrderRowLink';

export type ValidatedDataState = {
  targetRows: ExtendedTargetRow[];
  targetRowHierarchyEntries: ExtendedTargetRowHierarchyEntry[];
  topics: ExtendedTopic[];
  orders: ExtendedOrder[];
  workPackages: ExtendedWorkPackage[];
};

export const TargetImportModal = ({ onClose }: TargetImportModalProps) => {
  const { projectId } = useParams(routes.TARGET);
  const [step, setStep] = useState(0);
  const [requestId] = useState(v4());
  const [isOrderUpdateAllowed, setIsOrderUpdateAllowed] = useState(false);
  const [filterState, setFilterState] = useState<FilterState | undefined>();

  const [uploadData, setUploadData] = useState<{ file: File | null }>({
    file: null,
  });

  const [parsedData, setParsedData] = useState<unknown[]>([]);

  const [fieldMapping, setFieldMapping] = useState<
    Partial<Record<keyof TargetImportData, string>> | undefined
  >(undefined);

  const initialValidatedDataState = {
    targetRows: [],
    targetRowHierarchyEntries: [],
    topics: [],
    orders: [],
    workPackages: [],
  };

  const [validatedData, setValidatedData] = useState<ValidatedDataState>(
    initialValidatedDataState
  );

  const dispatch = useDispatch();

  const workPackageNA = withDefault(
    useSelector(getUnSpecifiedWorkPackage(projectId)),
    undefined
  );

  const orderNA = withDefault(
    useSelector(getUnSpecifiedOrder(projectId)),
    undefined
  );

  const projectHasTargetRows = useSelector(hasProjectAnyTargetRows(projectId));

  const localization = useLocalization();

  const fileReader = new FileReader();

  fileReader.addEventListener(
    'load',
    () => {
      if (fileReader.result !== null) {
        const records = parseCsv(fileReader.result);

        if (Array.isArray(records)) {
          setParsedData(records);
        }
      }
    },
    false
  );

  const updateFileData = async (newFile: File | null) => {
    setUploadData({ file: newFile });
    setFieldMapping(undefined);

    if (newFile) {
      fileReader.readAsArrayBuffer(newFile);
    }
  };

  React.useEffect(() => {
    const automaticMapping = automaticPreMapping(parsedData, localization);

    if (
      !_.isEqual(automaticMapping, fieldMapping) &&
      parsedData.length > 0 &&
      !fieldMapping
    ) {
      setFieldMapping(automaticMapping);
    }
  }, [parsedData, fieldMapping, localization]);

  const invalidRowCount =
    validatedData.targetRows.filter((row) => row.invalid).length +
    validatedData.targetRowHierarchyEntries.filter((row) => row.invalid).length;

  const rowCount =
    validatedData.targetRows.length +
    validatedData.targetRowHierarchyEntries.length;

  const previewData = useTargetViewData(filterState, {
    targetRows: validatedData.targetRows,
    targetRowHierarchyEntries: validatedData.targetRowHierarchyEntries,
    topics: validatedData.topics,
    orders: validatedData.orders,
    workPackages: validatedData.workPackages,
  });

  const requestState = useSelector(
    getOriginalTargetUpdateRequest(requestId)
  ).kind;

  const isDataInvalid =
    invalidRowCount > 0 || rowCount === 0 || requestState === 'Loading';

  React.useEffect(() => {
    if (requestState === 'Success') {
      // needs to refetch these after update
      onClose();
    }
  }, [requestState, onClose, dispatch, projectId]);

  const onClickNext = () => {
    if (step < 2) {
      if (
        step === 1 &&
        parsedData.length > 0 &&
        hasAllTargetImportDataFields(fieldMapping)
      ) {
        const processedData = processCsvData(parsedData, fieldMapping);

        const processedTempTargetRows = validatedDataToTempTargetRows(
          projectId,
          processedData,
          workPackageNA,
          orderNA
        );

        const processedHierarchyEntries = validatedDataToTempHierarchyEntries(
          projectId,
          processedData,
          processedTempTargetRows
        );

        const otherData = validatedDataToOtherTempData(
          projectId,
          processedData,
          workPackageNA,
          orderNA
        );

        setValidatedData({
          targetRows: processedTempTargetRows,
          targetRowHierarchyEntries: processedHierarchyEntries,
          topics: otherData.topics,
          orders: otherData.orders,
          workPackages: otherData.workPackages,
        });
      }

      setStep(() => step + 1);
    }

    if (step === 2 && !isDataInvalid) {
      const callback = () => {
        dispatch(fetchProcurementAreasForProject(projectId));
        dispatch(fetchWorkPackagesForProject(projectId));
        dispatch(fetchTopicsForProject(projectId));
      };

      const data = {
        orders: validatedData.orders
          .map((order) => ({
            code: order.visibleCode,
            name: order.name,
          }))
          // don't send the NA order, update not allowed
          .filter((order) => order.code !== orderNA?.visibleCode),
        targetRows: validatedData.targetRows.map((targetRow) => ({
          code: targetRow.externalCode,
          description: targetRow.description ?? '',
          orderCode: targetRow.procurementCode,
          workPackageCode: targetRow.workSectionCode,
          targetRowHierarchyEntryCode: targetRow.externalHierarchyEntryId,
          referenceNo: targetRow.referenceNumber ?? '',
          quantity: targetRow.quantity ? targetRow.quantity.toFixed(4) : null,
          unit: targetRow.unit ?? '',
          unitPrice: targetRow.unitPrice
            ? targetRow.unitPrice.toFixed(4)
            : null,
          isDeleted: targetRow.isDeleted,
        })),
        targetRowHierarchyEntries: validatedData.targetRowHierarchyEntries.map(
          (entry) => ({
            level: entry.depth,
            description: entry.description ?? '',
            referenceNo: entry.referenceNumber ?? '',
            code: entry.externalId,
            parentCode: entry.externalParentId,
            quantity: entry.quantity ? entry.quantity.toFixed(4) : null,
            unit: entry.unit ?? '',
            isDeleted: entry.isDeleted,
          })
        ),
        workPackages: validatedData.workPackages
          .map((workPackage) => ({
            code: workPackage.code,
            name: workPackage.name,
          }))
          // don't send the NA work package, update not allowed
          .filter((workPackage) => workPackage.code !== workPackageNA?.code),
        isOrderAndWorkPackageUpdateEnabled: isOrderUpdateAllowed,
      };
      dispatch(
        requestProjectOriginalTargetUpdate(data, projectId, requestId, callback)
      );
    }
  };

  const content = () => {
    switch (step) {
      case 0:
        return (
          <FileUpload
            updateFileCb={updateFileData}
            initialFile={uploadData.file}
            projectHasTargetRows={projectHasTargetRows}
          />
        );
      case 1:
        return (
          <SelectColumnsPage
            initialData={parsedData}
            mapping={fieldMapping}
            setMapping={setFieldMapping}
          />
        );
      case 2:
        return (
          <PreviewTable validatedData={previewData} filterState={filterState} />
        );
      default:
        return null;
    }
  };

  const isNextButtonDisabled = () => {
    if (uploadData.file === null || !fieldMapping) {
      return true;
    }

    if (step === 1) {
      return !hasAllTargetImportDataFields(fieldMapping);
    }

    if (step === 2) {
      return isDataInvalid;
    }
  };

  return (
    <Modal
      onClose={onClose}
      closeButton={HeaderWithStepsCloseButton}
      closingConfirmation
    >
      <FormContainer>
        <HeaderWithSteps>
          <Txt
            id={
              projectHasTargetRows
                ? 'target.toolbelt.button.updateTarget'
                : 'target.toolbelt.button.importTarget'
            }
            component={StyledHeader}
          />
          <StepIndicator
            steps={modalSteps}
            selectedIndex={step}
            setStep={setStep}
          />
        </HeaderWithSteps>
        <StyledContent noMaxHeight>{content()}</StyledContent>

        <TargetModalFooter
          setFilterState={setFilterState}
          nextButtonDisabled={!!isNextButtonDisabled()}
          step={step}
          validatedData={validatedData}
          requestId={requestId}
          onClose={onClose}
          onClickNext={onClickNext}
          isOrderUpdateAllowed={isOrderUpdateAllowed}
          setIsOrderUpdateAllowed={setIsOrderUpdateAllowed}
        />
      </FormContainer>
    </Modal>
  );
};

const StyledContent = styled(Content)`
  padding: ${(props) => `${props.theme.margin[32]}`};
  overflow-y: auto;
`;

const StyledHeader = styled.h1`
  flex-grow: 3;
  font-weight: 500;
`;

export const FormContainer = styled.div`
  box-shadow: 0px 4px 42px rgba(0, 0, 0, 0.25);

  width: 90vw;
  height: 90vh;
  min-width: 500px;

  display: flex;
  flex-direction: column;
`;
