import React, { memo } from 'react';

import { flexRender, Row } from '@tanstack/react-table';
import { VirtualItem } from '@tanstack/react-virtual';
import styled, { css, DefaultTheme } from 'styled-components';

import { GridCellTd } from '../../../../components/Cell';

import { UpdateProcurementRow } from './utils';
import { isClickOrKeyboardSelection } from '../../../../utils/mouseOrKeyInteraction';

type UpdateProcurementRowProps = {
  virtualRow: VirtualItem;
  row: Row<UpdateProcurementRow>;
  measureElement: (node: Element | null) => void;
  containerWidth: number;
  isSelected?: boolean;
};

const PreviewTableRow = memo(
  ({
    virtualRow,
    row,
    measureElement,
    containerWidth,
  }: UpdateProcurementRowProps) => {
    const rowWidth = row.getVisibleCells().reduce((acc, cell) => {
      return acc + cell.column.getSize();
    }, 0);

    const toggleRow = () => {
      if (row.getCanExpand()) {
        row.toggleExpanded();
      }
    };

    const onClickOrKeyPressRow = (
      e:
        | React.KeyboardEvent<HTMLTableRowElement>
        | React.MouseEvent<HTMLTableRowElement>
    ) => {
      if (isClickOrKeyboardSelection(e)) {
        e.preventDefault();
        toggleRow();
      }
    };

    const needsTooltip = () => {
      if (!!row.original.hasUnSavedChanges) {
        return true;
      }

      return false;
    };

    const showTooltip = needsTooltip();

    const memoizedVirtualRowStart = React.useMemo(() => {
      if (showTooltip) {
        return virtualRow.start;
      }

      return undefined;
    }, [virtualRow.start, showTooltip]);

    return (
      <StyledTr
        key={row.id}
        virtualRow={virtualRow}
        data-index={virtualRow.index}
        ref={(node) => measureElement(node)}
        parent={row.depth === 0 && row.subRows.length > 0}
        canExpand={row.getCanExpand()}
        depth={row.depth}
        rowType={row.original.type}
        hasUnsavedChanges={!!row.original.hasUnSavedChanges}
        onClick={onClickOrKeyPressRow}
        onKeyPress={onClickOrKeyPressRow}
        tabIndex={0}
      >
        {row.getVisibleCells().map((cell) => {
          return (
            <StyledTd
              key={cell.id}
              width={Math.round(
                (cell.column.getSize() / rowWidth) * containerWidth
              )}
              align={cell.column.columnDef?.meta?.align}
              borderWidthRight={cell.column.columnDef?.meta?.borderWidthRight}
            >
              {flexRender(cell.column.columnDef.cell, {
                ...cell.getContext(),
                virtualRowStart: memoizedVirtualRowStart,
              })}
            </StyledTd>
          );
        })}
      </StyledTr>
    );
  }
);

type TrProps = {
  virtualRow: VirtualItem;
  parent: boolean;
  depth: number;
  canExpand: boolean;
  rowType?: UpdateProcurementRow['type'];
  hasUnsavedChanges: boolean;
};

const backgroundColor = (
  theme: DefaultTheme,
  rowType?: UpdateProcurementRow['type'],
  hasUnsavedChanges?: boolean
) => {
  if (hasUnsavedChanges && rowType === 'order') {
    return css`
      background: rgba(244, 183, 107, 0.2);
    `;
  }

  if (hasUnsavedChanges && rowType === 'topic') {
    return css`
      background: rgba(244, 183, 107, 0.7);
    `;
  }

  if (hasUnsavedChanges && rowType === 'orderRow') {
    return css`
      background: rgba(244, 183, 107, 0.5);
    `;
  }

  if (hasUnsavedChanges) {
    return css`
      background: ${theme.color.statusColors.delayed};
    `;
  }

  if (rowType === 'topic') {
    return css`
      background: ${theme.color.primaryRowBackground};
    `;
  }

  if (rowType === 'targetRow') {
    return css`
      background: ${theme.color.whisper};
    `;
  }

  return css`
    background: white;
  `;
};

const StyledTr = styled.tr.attrs<TrProps>(({ virtualRow }) => ({
  style: { transform: `translateY(${virtualRow.start}px)` },
}))<TrProps>`
  position: absolute;

  border-bottom: ${({ parent }) => (parent ? 2 : 1)}px solid
    ${(props) => props.theme.color.rowBorder};

  width: 100%;
  height: ${({ parent }) => (parent ? 48 : 32)}px;

  display: flex;

  ${({ rowType, theme, hasUnsavedChanges }) =>
    backgroundColor(theme, rowType, hasUnsavedChanges)}

  font-size: ${({ depth, theme }) =>
    depth === 0 ? theme.fontSize.h2 : theme.fontSize.base};
  ${({ canExpand }) =>
    canExpand
      ? css`
          cursor: pointer;
        `
      : ''}
`;

type TdProps = {
  width: number;
  borderWidthRight?: number;
};

const StyledTd = styled(GridCellTd)<TdProps>`
  border-right: ${({ borderWidthRight }) => borderWidthRight ?? 0}px solid
    ${(props) => props.theme.color.rowBorder};
  width: ${({ width }) => width}px;
  flex-flow: nowrap;
`;

export default PreviewTableRow;
