import React from 'react';
import { useHistory } from 'react-router-dom';

import { FormikErrors, useFormik } from 'formik';
import styled from 'styled-components';

import useTxt from '../../hooks/useTxt';

import AuthContainer from '../../components/Auth/AuthPageContainer';
import { AuthTitle, AuthText } from '../../components/Auth/index';
import { PrimaryButton, SecondaryButton } from '../../components/Buttons';
import TextInput from '../../components/Input/TextInput';
import Txt from '../../components/Txt';

import { sendEmailSignInLink, useAsyncError } from '../../utils/auth';

import { generateUrl, routes } from '../../routes';

const ContentsForm = styled.form`
  display: contents;
`;

const Fields = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;

  width: 100%;

  display: flex;
  flex-direction: column;

  gap: 2rem;
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`;

type FormValues = {
  email: string;
};

type EmailErrorTextId = 'auth.email.signIn.form.email.error';

function isEmailErrorTextId(
  value: string | undefined
): value is EmailErrorTextId {
  return ['auth.email.signIn.form.email.error'].includes(
    value as EmailErrorTextId
  );
}

const EmailLoginPage = () => {
  const history = useHistory();
  const emailLabel = useTxt('common.email');

  const { errorMessage, isError, clearError, setError } = useAsyncError();

  const validate = (values: FormValues): FormikErrors<FormValues> => {
    const errors: FormikErrors<FormValues> = {};

    if (!values.email.includes('@') || values.email.length < 4) {
      errors.email = 'auth.email.signIn.form.email.error';
    }

    return errors;
  };

  const formik = useFormik<FormValues>({
    initialValues: {
      email: '',
    },
    validate,
    onSubmit: async (values) => {
      clearError();

      try {
        await sendEmailSignInLink(values.email);
        history.push(generateUrl({ route: routes.LOGIN_EMAIL_SENT }));
      } catch (error) {
        setError(error);
      }
    },
  });

  const emailErrorId = isEmailErrorTextId(formik.errors.email)
    ? formik.errors.email
    : undefined;

  const emailError = useTxt(emailErrorId);

  const emailErrorText = emailError.length === 0 ? null : emailError;

  return (
    <AuthContainer>
      <ContentsForm
        method="post"
        name="login-with-email-form"
        onSubmit={formik.handleSubmit}
      >
        <AuthTitle>
          <Txt id="auth.email.signIn.enterEmail" />
        </AuthTitle>

        <Fields>
          <TextInput
            label={emailLabel}
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            disabled={formik.isSubmitting}
            errorMessage={emailErrorText}
          />
        </Fields>

        <ActionButtons>
          <SecondaryButton
            onClick={() => {
              history.goBack();
            }}
          >
            <Txt id="common.cancel" />
          </SecondaryButton>
          <PrimaryButton disabled={formik.isSubmitting}>
            <Txt id="auth.email.signIn.sendLink" />
          </PrimaryButton>
        </ActionButtons>
        {isError ? <ErrorText>{errorMessage.message}</ErrorText> : null}
      </ContentsForm>
    </AuthContainer>
  );
};

const ErrorText = styled(AuthText)`
  color: red;
`;

export default EmailLoginPage;
