import { APIUpdatedEntities, RawAPIUpdatedEntities } from '../../../types/api';
import { mapRawUpdatedEntities } from '../../../types/mappers';

import { makeAction, ExtractActionTypes } from '../../../utils/actionCreators';
import {
  POST,
  BackendError,
  apiErrorHandlingWithDecode,
} from '../../../utils/api';
import { createAsyncThunk } from '../../../utils/thunk';

const actionCreators = {
  ...makeAction('postRevenueAnalysisLinkStarted')<{
    projectId: string;
    requestId: string;
  }>(),
  ...makeAction('postRevenueAnalysisLinkFailure')<{
    requestId: string;
    projectId: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('postRevenueAnalysisLinkSuccess')<
    {
      projectId: string;
      requestId: string;
    } & APIUpdatedEntities
  >(),

  ...makeAction('removeRevenueAnalysisLinkStarted')<{
    projectId: string;
    requestId: string;
  }>(),
  ...makeAction('removeRevenueAnalysisLinkFailure')<{
    projectId: string;
    requestId: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('removeRevenueAnalysisLinkSuccess')<
    {
      projectId: string;
      requestId: string;
    } & APIUpdatedEntities
  >(),
};

export const {
  postRevenueAnalysisLinkStarted,
  postRevenueAnalysisLinkFailure,
  postRevenueAnalysisLinkSuccess,

  removeRevenueAnalysisLinkStarted,
  removeRevenueAnalysisLinkFailure,
  removeRevenueAnalysisLinkSuccess,
} = actionCreators;

export type RevenueLinkageAction = ExtractActionTypes<typeof actionCreators>;

type RevenueAnalysisLink = {
  entityId: string;
  analysisRowId: string;
};

export type RevenueLinkRequest = {
  projectId: string;
  requestId: string;
} & RevenueAnalysisLink;

const postRevenueAnalysisLink =
  (url: string) =>
  async ({ entityId, analysisRowId }: RevenueAnalysisLink) => {
    const response = await POST<RawAPIUpdatedEntities>(url, {
      analysisListItemId: analysisRowId,
      paymentProgramRowId: entityId,
    });

    return mapRawUpdatedEntities(response);
  };

const addRevenueAnalysisLink = postRevenueAnalysisLink(
  'v1/payment-program-rows/analysis-list-item-linkage'
);

export const createRevenueAnalysisLinkage = ({
  entityId,
  projectId,
  requestId,
  analysisRowId,
}: RevenueLinkRequest) =>
  createAsyncThunk(addRevenueAnalysisLink, {
    args: [{ entityId, analysisRowId }],
    initialAction: postRevenueAnalysisLinkStarted({ projectId, requestId }),
    failureActionCreator: (error) =>
      postRevenueAnalysisLinkFailure({
        projectId,
        requestId,
        error: apiErrorHandlingWithDecode(error),
      }),
    successActionCreator: (updatedEntities) =>
      postRevenueAnalysisLinkSuccess({
        ...updatedEntities,
        projectId,
        requestId,
      }),
  });

const deleteRevenueAnalysisLink = postRevenueAnalysisLink(
  'v1/payment-program-rows/remove-analysis-list-item-linkage'
);

export const removeRevenueAnalysisLinkage = ({
  entityId,
  projectId,
  requestId,
  analysisRowId,
}: RevenueLinkRequest) =>
  createAsyncThunk(deleteRevenueAnalysisLink, {
    args: [{ entityId, analysisRowId }],
    initialAction: removeRevenueAnalysisLinkStarted({ projectId, requestId }),
    failureActionCreator: (error) =>
      removeRevenueAnalysisLinkFailure({
        projectId,
        requestId,
        error: apiErrorHandlingWithDecode(error),
      }),
    successActionCreator: (updatedEntities) =>
      removeRevenueAnalysisLinkSuccess({
        ...updatedEntities,
        projectId,
        requestId,
      }),
  });
