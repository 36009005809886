import React from 'react';

import useTxt from '../../hooks/useTxt';

const PageNotFound = () => {
  const pageNotFoundText = useTxt('meta.pageNotFound');

  return <div>{pageNotFoundText}</div>;
};

export default PageNotFound;
