import Big from 'big.js';

import { APIOrder } from '../types/api';

export const filterOrders = (
  orders: APIOrder[],
  billingFilteringActive: boolean,
  orderStatusId: string[]
) => {
  const billingFilteredOrders = billingFilteringActive
    ? orders.filter(
        (order) =>
          order.actualcostsPendingCount > new Big(0) ||
          order.invoicesUnsettledCount > new Big(0) ||
          order.invoiceComplaintsCount > new Big(0) ||
          order.invoiceCorrectionsCount > new Big(0)
      )
    : orders;

  const statusFilteredOrders = orderStatusId?.length
    ? billingFilteredOrders.filter((order) =>
        orderStatusId.includes(order.statusId)
      )
    : billingFilteredOrders;

  return statusFilteredOrders;
};
