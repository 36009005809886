type RecordKey = string | symbol | number;
type PartialRecord<K extends RecordKey, V> = Partial<Record<K, V>>;

export default function normalizeBy<
  K extends RecordKey,
  A extends Record<K, any>,
  Key extends K,
>(
  k: A[Key] extends RecordKey ? Key : never,
  xs: A[]
): PartialRecord<A[Key], A> {
  const record = xs.reduce((acc: PartialRecord<A[Key], A>, x) => {
    acc[x[k]] = x;

    return acc;
  }, {});

  return record;
}
