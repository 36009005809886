import Big from 'big.js';
import { sortBy } from 'lodash';
import { Reducer } from 'redux';

import { APITopic, APIOrderRow } from '../../../types/api';

import * as api from '../../../utils/api';
import * as big from '../../../utils/big';
import { flow } from '../../../utils/function';
import { isDefined } from '../../../utils/general';
import normalizeBy from '../../../utils/normalizeBy';
import * as remoteData from '../../../utils/remoteData';
import {
  assertActionPayloadIsNotApiUpdatedEntities,
  isUpdatedEntitiesActionType,
  Selector,
} from '../utils';

import { TargetRow } from '../../actions/target/targetRow';
import { ActionTypes as Action } from '../../actionTypes';
import { AppState } from '../index';
import { getOrderRows } from '../orderRow';
import { getOrderTopics } from '../topic';

type Err = api.BackendError | undefined;

type State = {
  requests: Partial<Record<string, remoteData.RemoteData<undefined, Err>>>;
  analysisRowRequests: Partial<
    Record<string, remoteData.RemoteData<undefined, Err>>
  >;
  deleteRequests: Partial<
    Record<string, remoteData.RemoteData<undefined, Err>>
  >;
  projectRequests: Partial<
    Record<string, remoteData.RemoteData<undefined, Err>>
  >;
  putOriginalTargetRequests: Partial<
    Record<string, remoteData.RemoteData<string, Err>>
  >;
  data: Partial<Record<string, TargetRow>>;
};

const initialState: State = {
  requests: {},
  deleteRequests: {},
  projectRequests: {},
  analysisRowRequests: {},
  putOriginalTargetRequests: {},
  data: {},
};

const reducer: Reducer<State, Action> = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_TARGET_ROWS_STARTED': {
      const { orderId } = action.payload;
      const requests = { ...state.requests, [orderId]: remoteData.loading };

      return { ...state, requests };
    }
    case 'GET_TARGET_ROWS_FAILURE': {
      const { orderId, error } = action.payload;
      const requests = { ...state.requests, [orderId]: remoteData.fail(error) };

      return { ...state, requests };
    }
    case 'GET_TARGET_ROWS_SUCCESS': {
      const { orderId, targetRows } = action.payload;

      const requests = {
        ...state.requests,
        [orderId]: remoteData.succeed(undefined),
      };

      const data = {
        ...state.data,
        ...normalizeBy('id', targetRows),
      };

      return {
        ...state,
        requests,
        data,
      };
    }
    case 'GET_TARGET_ROWS_FOR_PROJECT_STARTED': {
      const { projectId } = action.payload;

      const projectRequests = {
        ...state.projectRequests,
        [projectId]: remoteData.loading,
      };

      return { ...state, projectRequests };
    }
    case 'GET_TARGET_ROWS_FOR_PROJECT_FAILURE': {
      const { projectId, error } = action.payload;

      const projectRequests = {
        ...state.projectRequests,
        [projectId]: remoteData.fail(error),
      };

      return { ...state, projectRequests };
    }
    case 'GET_TARGET_ROWS_FOR_PROJECT_SUCCESS': {
      const { projectId, targetRows } = action.payload;

      const projectRequests = {
        ...state.projectRequests,
        [projectId]: remoteData.succeed(undefined),
      };

      const normalizedData = normalizeBy('id', targetRows);

      const data = {
        ...state.data,
        ...normalizedData,
      };

      return {
        ...state,
        projectRequests,
        data,
      };
    }

    case 'GET_TARGET_ROWS_FOR_ANALYSIS_ROW_STARTED': {
      const { analysisRowId } = action.payload;

      const analysisRowRequests = {
        ...state.analysisRowRequests,
        [analysisRowId]: remoteData.loading,
      };

      return { ...state, analysisRowRequests };
    }
    case 'GET_TARGET_ROWS_FOR_ANALYSIS_ROW_FAILURE': {
      const { analysisRowId, error } = action.payload;

      const analysisRowRequests = {
        ...state.analysisRowRequests,
        [analysisRowId]: remoteData.fail(error),
      };

      return { ...state, analysisRowRequests };
    }
    case 'GET_TARGET_ROWS_FOR_ANALYSIS_ROW_SUCCESS': {
      const { targetRows, analysisRowId } = action.payload;

      const analysisRowRequests = {
        ...state.analysisRowRequests,
        [analysisRowId]: remoteData.succeed(undefined),
      };

      const data = { ...state.data, ...normalizeBy('id', targetRows) };

      return { ...state, analysisRowRequests, data };
    }
    case 'POST_TARGET_ROWS_STARTED': {
      const { requestId } = action.payload;

      return {
        ...state,
        requests: { [requestId]: remoteData.loading },
      };
    }

    case 'POST_TARGET_ROWS_FAILURE': {
      const { requestId } = action.payload;

      return {
        ...state,
        requests: { [requestId]: remoteData.fail(action.payload.error) },
      };
    }
    case 'POST_TARGET_ROWS_SUCCESS':
    case 'SPLIT_TARGET_ROWS_SUCCESS': {
      const { requestId, targetRows: updatedTargetRows = [] } = action.payload;

      const data = {
        ...state.data,
        ...normalizeBy('id', updatedTargetRows),
      };

      return {
        ...state,
        data,
        requests: {
          [requestId]: remoteData.succeed(undefined),
        },
      };
    }

    case 'DELETE_TARGET_ROW_STARTED': {
      const { targetRowId } = action.payload;

      return {
        ...state,
        deleteRequests: {
          ...state.deleteRequests,
          [targetRowId]: remoteData.loading,
        },
      };
    }

    case 'DELETE_TARGET_ROW_FAILURE': {
      const { targetRowId } = action.payload;

      return {
        ...state,
        deleteRequests: {
          ...state.deleteRequests,
          [targetRowId]: remoteData.fail(action.payload.error),
        },
      };
    }
    case 'DELETE_TARGET_ROW_SUCCESS': {
      const { targetRowId, targetRows: updatedTargetRows } = action.payload;

      if (!updatedTargetRows) {
        return state;
      }

      const data = { ...state.data };
      updatedTargetRows.forEach((row) => {
        const { isDeleted, id } = row;

        if (isDeleted) {
          delete data[id];
        } else {
          data[id] = row;
        }
      });

      return {
        ...state,
        deleteRequests: {
          ...state.deleteRequests,
          [targetRowId]: remoteData.succeed(undefined),
        },
        data,
      };
    }
    case 'PUT_ORIGINAL_TARGET_STARTED': {
      const { requestId } = action.payload;

      return {
        ...state,
        putOriginalTargetRequests: {
          ...state.putOriginalTargetRequests,
          [requestId]: remoteData.loading,
        },
      };
    }
    case 'PUT_ORIGINAL_TARGET_FAILURE': {
      const { requestId } = action.payload;

      return {
        ...state,
        putOriginalTargetRequests: {
          ...state.putOriginalTargetRequests,
          [requestId]: remoteData.fail(action.payload.error),
        },
      };
    }
    case 'PUT_ORIGINAL_TARGET_SUCCESS': {
      const {
        projectId,
        requestId,
        targetRows: updatedTargetRows = [],
      } = action.payload;

      const data = { ...state.data };
      updatedTargetRows.forEach((row) => {
        const { isDeleted, id } = row;

        if (isDeleted) {
          delete data[id];
        } else {
          data[id] = row;
        }
      });

      return {
        ...state,
        putOriginalTargetRequests: {
          ...state.putOriginalTargetRequests,
          [requestId]: remoteData.succeed(projectId),
        },
        data,
      };
    }
  }

  if (isUpdatedEntitiesActionType(action)) {
    const { targetRows: updatedTargetRows = [] } = action.payload;

    if (updatedTargetRows.length === 0) {
      return state;
    }

    const data = { ...state.data };

    updatedTargetRows.forEach((targetRow) => {
      const { id, isDeleted } = targetRow;

      if (isDeleted) {
        delete data[id];
      } else {
        data[id] = targetRow;
      }
    });

    return {
      ...state,
      data,
    };
  }

  assertActionPayloadIsNotApiUpdatedEntities(action);

  return state;
};

export default reducer;

type OrderId = {
  orderId: string;
};

type ProjectId = {
  projectId: string;
};

export const getTargetRows: (
  orderId: OrderId
) => Selector<remoteData.RemoteData<TargetRow[]>> =
  ({ orderId }) =>
  ({
    target: {
      targetRows: {
        requests: { [orderId]: requestState = remoteData.notAsked },
        data,
      },
    },
  }) =>
    remoteData.map(requestState, (_) =>
      Object.values(data)
        .filter(isDefined)
        .filter((targetRow) => targetRow.orderId === orderId)
    );

export const getTargetRowsForProject: (
  projectId: ProjectId
) => Selector<remoteData.RemoteData<TargetRow[]>> =
  ({ projectId }) =>
  ({
    target: {
      targetRows: {
        projectRequests: { [projectId]: requestState = remoteData.notAsked },
        data,
      },
    },
  }) =>
    remoteData.map(requestState, (_) =>
      Object.values(data)
        .filter(isDefined)
        .filter(
          (targetRow) =>
            targetRow.projectId === projectId && targetRow.isAntiRow === false
        )
    );

export const getTargetRowsByIds =
  (ids: string[], orderId: string) => (appState: AppState) => {
    const allTargetRowsByOrderId = getTargetRows({ orderId })(appState);

    const rowsMatchingIds = remoteData.withDefault(allTargetRowsByOrderId, []);

    return rowsMatchingIds.filter((row) => ids.includes(row.id));
  };

export const getTotalPricesSumForTargetRowsWithIds =
  (ids: string[], orderId: string) => (appState: AppState) => {
    const targetRows = getTargetRowsByIds(ids, orderId)(appState);

    return big.sum(...targetRows.map((row) => row.totalPrice));
  };

export const isPending: (orderId: OrderId) => Selector<boolean> = ({
  orderId,
}) => flow(getTargetRows({ orderId }), remoteData.isLoading);

export const isTargetRowsForProjectPending: (
  projectId: ProjectId
) => Selector<boolean> = ({ projectId }) =>
  flow(getTargetRowsForProject({ projectId }), remoteData.isLoading);

export const hasProjectAnyTargetRows: (
  projectId: string
) => Selector<boolean> = (projectId) =>
  flow(getTargetRowsForProject({ projectId }), (state) => {
    if (state.kind === 'Success') {
      return state.value.length !== 0;
    }

    return true;
  });

export type TargetTopic = APITopic & {
  target: Big;
  prediction: Big;
  difference: Big;
};

function toTargetTopic({
  topic,
  orderRows,
  targetRows,
}: {
  topic: APITopic;
  orderRows: APIOrderRow[];
  targetRows: TargetRow[];
}): TargetTopic {
  const target = targetRows
    .filter(({ topicId }) => topicId === topic.id)
    .reduce((acc, { totalPrice }) => acc.add(totalPrice), new Big(0));

  const prediction = orderRows
    .filter(({ topicId }) => topicId === topic.id)
    .reduce(
      (acc, { unitPrice, quantity }) =>
        acc.add((unitPrice ?? new Big(0)).mul(quantity ?? new Big(0))),
      new Big(0)
    );

  const difference = target.sub(prediction);

  return { ...topic, target, prediction, difference };
}

export function getTargetTopics(
  orderId: string
): Selector<remoteData.RemoteData<TargetTopic[]>> {
  return (appState) => {
    const remoteTopics = getOrderTopics(orderId)(appState);

    if (remoteTopics.kind !== 'Success') {
      return remoteTopics;
    }

    const topics = remoteTopics.value;

    const remoteOrderRows = getOrderRows({ orderId })(appState);

    if (remoteOrderRows.kind !== 'Success') {
      return remoteOrderRows;
    }

    const orderRows = remoteOrderRows.value;

    const remoteTargetRows = getTargetRows({ orderId })(appState);

    if (remoteTargetRows.kind !== 'Success') {
      return remoteTargetRows;
    }

    const targetRows = remoteTargetRows.value;

    const targetTopics = topics.map((topic) =>
      toTargetTopic({
        topic,
        orderRows: orderRows.filter(({ topicId }) => topicId === topic.id),
        targetRows: targetRows.filter(({ topicId }) => topicId === topic.id),
      })
    );

    return remoteData.succeed(targetTopics);
  };
}

export type TargetOrderRow = APIOrderRow & {
  target: Big;
  prediction: Big;
  difference: Big;
};

export function toTargetOrderRow(
  orderRow: APIOrderRow,
  targetRows: TargetRow[]
): TargetOrderRow {
  const prediction = (orderRow.quantity ?? new Big(0)).mul(
    orderRow.unitPrice ?? new Big(0)
  );

  const target = targetRows
    .filter(({ orderRowId }) => orderRowId === orderRow.id)
    .reduce((acc, { totalPrice }) => acc.add(totalPrice), new Big(0));

  const difference =
    targetRows.length > 0 ? target.sub(prediction) : new Big(0);

  return { ...orderRow, prediction, target, difference };
}

export function getTopicTargetOrderRows({
  orderId,
  topicId,
}: {
  orderId: string;
  topicId: string;
}): Selector<remoteData.RemoteData<TargetOrderRow[]>> {
  return (appState) => {
    const remoteOrderRows = getOrderRows({ orderId })(appState);

    if (remoteOrderRows.kind !== 'Success') {
      return remoteOrderRows;
    }

    const orderRows = remoteOrderRows.value;

    const remoteTargetRows = getTargetRows({ orderId })(appState);

    if (remoteTargetRows.kind !== 'Success') {
      return remoteTargetRows;
    }

    const targetRows = remoteTargetRows.value;

    const targetOrderRows = orderRows.map((orderRow) =>
      toTargetOrderRow(
        orderRow,
        targetRows.filter(({ orderRowId }) => orderRowId === orderRow.id)
      )
    );

    const topicTargetOrderRows = targetOrderRows.filter(
      (orderRow) => orderRow.topicId === topicId
    );

    return remoteData.succeed(topicTargetOrderRows);
  };
}

export function getOrderRowTargetRows({
  orderId,
  orderRowId,
}: {
  orderId: string;
  orderRowId: string;
}) {
  return flow(getTargetRows({ orderId }), (remoteTargetRows) =>
    remoteData.map(remoteTargetRows, (targetRows) =>
      targetRows.filter((targetRow) => targetRow.orderRowId === orderRowId)
    )
  );
}

export function getTopicTargetRows({
  orderId,
  topicId,
}: {
  orderId: string;
  topicId: string;
}) {
  return flow(getTargetRows({ orderId }), (remoteTargetRows) =>
    remoteData.map(remoteTargetRows, (targetRows) =>
      targetRows.filter(
        (targetRow) =>
          targetRow.topicId === topicId && targetRow.orderRowId === null
      )
    )
  );
}

export function getAllTargetRowsForTopic({
  orderId,
  topicId,
}: {
  orderId: string;
  topicId: string;
}) {
  return flow(getTargetRows({ orderId }), (remoteTargetRows) =>
    remoteData.map(remoteTargetRows, (targetRows) =>
      targetRows.filter((targetRow) => targetRow.topicId === topicId)
    )
  );
}

export function getTopLevelTargetRows(projectId: ProjectId) {
  return flow(getTargetRowsForProject(projectId), (remoteTargetRows) =>
    remoteData.map(remoteTargetRows, (targetRows) => {
      const filteredRows = targetRows.filter(
        (targetRow) =>
          targetRow.projectId === projectId.projectId &&
          targetRow.targetRowHierarchyEntryId === null
      );

      const sortedAndFiltered = sortBy(filteredRows, [
        (entry) => entry.referenceNumber,
        (entry) => -Number(entry.totalPrice.toNumber()),
      ]);

      return sortedAndFiltered;
    })
  );
}

export function getSplitToRows(projectId: string, targetRowId: string) {
  return flow(getTargetRowsForProject({ projectId }), (remoteTargetRows) =>
    remoteData.map(remoteTargetRows, (targetRows) => {
      const filteredRows = targetRows.filter(
        (targetRow) =>
          targetRow.projectId === projectId &&
          targetRow.isSplitFrom === targetRowId &&
          targetRow.isDeleted === false &&
          targetRow.isDisabled === false
      );

      return filteredRows;
    })
  );
}

export const getTargetRowById =
  (targetRowId: string) =>
  ({
    target: {
      targetRows: { data },
    },
  }: AppState): TargetRow | undefined => {
    return data[targetRowId];
  };

export const getRequestState = (
  requestId: string
): Selector<remoteData.RemoteData['kind']> => {
  return ({
    target: {
      targetRows: {
        requests: { [requestId]: requestState = remoteData.notAsked },
      },
    },
  }) => requestState.kind;
};

export const getDeleteRequestState = (
  targetRowId: string
): Selector<remoteData.RemoteData['kind']> => {
  return ({
    target: {
      targetRows: {
        deleteRequests: { [targetRowId]: requestState = remoteData.notAsked },
      },
    },
  }) => requestState.kind;
};

export function getTargetRowsForAnalysisRow(
  analysisRowId: string
): Selector<remoteData.RemoteData<TargetRow[]>> {
  return ({
    target: {
      targetRows: {
        analysisRowRequests: {
          [analysisRowId]: requestState = remoteData.notAsked,
        },
        data,
      },
    },
  }: AppState) =>
    remoteData.map(requestState, (_) =>
      Object.values(data)
        .filter(isDefined)
        .filter(
          (targetRow) =>
            isDefined(targetRow) &&
            targetRow.analysisListItemIds.some(
              (listItemId) => listItemId === analysisRowId
            )
        )
    );
}

export const getAnalysisTargetRowsSum =
  (analysisRowId: string) =>
  (appState: AppState): Big => {
    const targetRows = getTargetRowsForAnalysisRow(analysisRowId)(appState);

    const targetRowTotals = remoteData.withDefault(targetRows, []).map((o) => {
      const quantity = o.quantity ?? new Big(0);
      const unitPrice = o.unitPrice ?? new Big(0);

      return quantity.mul(unitPrice);
    });

    return big.sum(...targetRowTotals);
  };

export function getOriginalTargetUpdateRequest(
  requestId: string
): Selector<remoteData.RemoteData<string>> {
  return ({
    target: {
      targetRows: {
        putOriginalTargetRequests: { [requestId]: request },
      },
    },
  }) => request ?? remoteData.notAsked;
}
