import moment from 'moment';

import { Revenue } from '../../../../store/reducers/revenue/revenue';

import { APIMultiplePaymentProgramRowBody } from '../../../../types/api';
import { ID, ValidationErrorTextId } from '../../../../types/general';

import * as big from '../../../../utils/big';
import {
  isDefined,
  isNotNull,
  emptyPaymentProgramRow,
  finnishStrictLocalizations,
  otherStrictLocalizations,
} from '../../../../utils/general';

export type ValueAndError = {
  value: string;
  validationError: ValidationErrorTextId | null;
};

export interface RevenueColumnInfo extends ValueAndError {
  column: string;
}

export type ParsedRevenueResultRow = {
  code: RevenueColumnInfo;
  description: RevenueColumnInfo;
  quantity: RevenueColumnInfo;
  unit: RevenueColumnInfo;
  unitPrice: RevenueColumnInfo;
  billingDate: RevenueColumnInfo;
  vat: RevenueColumnInfo;
} | null;

// Removes white space and euro symbols
const tidyNumberField = (value: string): string => value.replace(/(\s|€)/g, '');

const isEmptyRow = (value: string): boolean =>
  value.replace(/\t/g, '').trim() === '';

export const parseRevenueRows = (
  content: string,
  lang: string,
  reveNueRows?: Revenue[]
): ParsedRevenueResultRow[] => {
  const rows = content.split(/\r?\n/);

  const revenueCodes: string[] = [];

  const hasDuplicate = (code: string) => {
    if (revenueCodes.includes(code)) {
      return true;
    }

    revenueCodes.push(code);

    return false;
  };

  return (
    rows
      .map<ParsedRevenueResultRow | undefined>((row) => {
        if (isEmptyRow(row)) {
          return undefined;
        }

        const columns = row.split('\t');

        // If we have invalid amount of columns, let's indicate it explicitly with
        // null and render invalid row message to table.
        if (columns.length !== 7) {
          return null;
        }

        const code = columns[0].trim();
        const description = columns[1].trim();
        const quantity = tidyNumberField(columns[2]);
        const unit = columns[3].trim();
        const unitPrice = tidyNumberField(columns[4]);
        const vat = columns[5].trim();
        const billingDate = columns[6].trim();

        return {
          code: {
            value: code,
            validationError:
              reveNueRows?.map((i) => i.batchCode).includes(code) ||
              hasDuplicate(code)
                ? 'validation.duplicateValue'
                : validateField('code', code, undefined),
            column: 'code',
          },
          description: {
            value: description,
            validationError: validateField(
              'description',
              description,
              undefined
            ),
            column: 'description',
          },
          quantity: {
            value: quantity,
            validationError: validateField('quantity', quantity, undefined),
            column: 'quantity',
          },
          unit: {
            value: unit,
            validationError: validateField('unit', unit, undefined),
            column: 'unit',
          },
          unitPrice: {
            value: unitPrice,
            validationError: validateField('unitPrice', unitPrice, undefined),
            column: 'unitPrice',
          },
          billingDate: {
            value: billingDate,
            validationError: validateField(
              'billingDate',
              billingDate,
              undefined,
              lang
            ),
            column: 'billingDate',
          },
          vat: {
            value: vat,
            validationError: validateField('vat', vat, undefined),
            column: 'vat',
          },
        };
      })
      // Let's filter out empty rows
      .filter(isDefined)
  );
};

export const everythingValid = (result: ParsedRevenueResultRow[]): boolean =>
  result.every(
    (row) =>
      isNotNull(row) &&
      row.description.validationError === null &&
      row.unit.validationError === null &&
      row.unitPrice.validationError === null &&
      row.billingDate?.validationError === null &&
      row.vat?.validationError === null &&
      row.code?.validationError === null &&
      row.quantity.validationError === null
  );

// Assumes that the rows are validated.
export const constructPaymentProgramRowsRequest = (
  result: ParsedRevenueResultRow[],
  paymentProgramRowGroupId: ID,
  projectId: ID,
  lang: string
): APIMultiplePaymentProgramRowBody => ({
  data: (result as NonNullable<ParsedRevenueResultRow>[]).map((row) => ({
    ...emptyPaymentProgramRow(paymentProgramRowGroupId),
    projectId,
    paymentProgramRowGroupId,
    visiblePaymentBatchCode: row.code.value,
    description: row.description.value,
    quantity:
      row.quantity.value === ''
        ? null
        : big.fromInputString(row.quantity.value, 0).toString(),
    unit: row.unit.value,
    unitPriceWithoutVat:
      row.unitPrice.value === ''
        ? null
        : big.fromInputString(row.unitPrice.value, 0).toString(),
    billingDate: moment(
      row.billingDate.value,
      lang === 'fi' ? 'DD.MM.YYYY' : 'MM/DD/YYYY'
    ).format(moment.HTML5_FMT.DATE),
    vatPrc: big.fromInputString(row.vat.value).toString(),
  })),
});

export type EditableRevenueRowState = {
  code?: string;
  status: string;
  description: string;
  quantity: string;
  unit: string;
  unitPrice: string;
  billingDate?: string;
  vat?: string;
};

export type ValidationErrorState = {
  [key in keyof EditableRevenueRowState]: ValidationErrorTextId | null;
};

export const initialValidationErrorState: ValidationErrorState = {
  status: null,
  description: null,
  unit: null,
  quantity: null,
  unitPrice: null,
};

export const MAX_DESCRIPTION_LENGTH = 500;
export const MAX_UNIT_LENGTH = 7;
export const MAX_CODE_LENGTH = 20;

export const validateField = (
  field: keyof EditableRevenueRowState,
  value: string,
  row: Revenue | undefined,
  lang?: string
): ValidationErrorTextId | null => {
  switch (field) {
    case 'code':
      if (value.length > MAX_CODE_LENGTH) {
        return 'common.maxCharacters';
      }

      return null;
    case 'unitPrice':
      try {
        // Fields are allowed to be empty
        if (!row && value === '') {
          return null;
        }

        const quantity = big.fromInputString(value);

        if (!big.hasMaxNWholeNumbers(quantity, 12) && value.startsWith('-')) {
          return 'validation.hasMaxNWholeNumbersNegative';
        }

        if (!big.hasMaxNWholeNumbers(quantity, 12)) {
          return 'validation.hasMaxNWholeNumbersPositive';
        }

        if (!big.hasMaxNDecimals(quantity, 4)) {
          return 'validation.hasMaxNDecimals';
        }
      } catch (err) {
        return 'validation.number.error';
      }

      return null;

    case 'quantity': {
      try {
        // Fields are allowed to be empty
        if (!row && value === '') {
          return null;
        }

        const quantity = big.fromInputString(value);

        if (!big.hasMaxNWholeNumbers(quantity, 12) && value.startsWith('-')) {
          return 'validation.hasMaxNWholeNumbersNegative';
        }

        if (!big.hasMaxNWholeNumbers(quantity, 12)) {
          return 'validation.hasMaxNWholeNumbersPositive';
        }

        if (!big.hasMaxNDecimals(quantity, 4)) {
          return 'validation.hasMaxNDecimals';
        }
      } catch (err) {
        return 'validation.number.error';
      }

      return null;
    }

    case 'description':
      if (value.length > MAX_DESCRIPTION_LENGTH) {
        return 'common.maxCharacters';
      }

      return null;
    case 'unit':
      if (value.length > MAX_UNIT_LENGTH) {
        return 'common.maxCharacters';
      }

      return null;

    case 'billingDate': {
      if (!isValidDate(value, lang ?? 'fi')) {
        return 'validation.billingDate.error';
      }

      return null;
    }
    case 'vat': {
      if (!valueIsInRange(value)) {
        return 'validation.vat.error';
      }

      return null;
    }
    case 'status':
      return null;
    default:
      return 'validation.unknownField';
  }
};

const isValidDate = (dateString: string, lang: string): boolean => {
  const dateFormat =
    lang === 'fi' ? finnishStrictLocalizations : otherStrictLocalizations;

  try {
    return moment(dateString, dateFormat, true).isValid();
  } catch (err) {
    return false;
  }
};

const valueIsInRange = (value: string): boolean => {
  const vatAsBig = big.fromInputString(value, 0);

  return vatAsBig.gte(0) && vatAsBig.lte(100);
};
