import React from 'react';

import Big from 'big.js';
import styled from 'styled-components';

import * as big from '../utils/big';

export const ToolTip = styled.div`
  position: absolute;
  left: 0;
  top: 100%;

  box-shadow: 0.125rem 0.125rem 0.625rem rgba(0, 0, 0, 0.4);
  border-radius: 0.125rem;

  padding: 0.25rem;

  width: 100%;

  display: none;

  background: white;

  z-index: 100;
`;

type CellDivProps = {
  align?: 'center' | 'right' | 'left';
  className?: string;
  condensed?: boolean;
};

type CellTdProps = CellDivProps;

/* Will probably need to add space-* cases too, if the Cells start to
contain many children */

const aligns = {
  center: 'center',
  left: 'flex-start',
  right: 'flex-end',
};
/**
 * Cell component is a styled component, and it contains
 * a <td> element in certain styles:
 *
 * Cell is a div, that is a flex row with wrapping and centering items
 * horizontally (align-items: center, items are not in the top or bottom of
 * the div, but in the middle).
 *
 * Additional positioning can be done with 'align' prop, which accepts values
 * 'left', 'right' and 'center',, which positions the contents either to left,
 * right or center.
 *
 * Currently CellDivs should contain only one child or one item (an icon, one text),
 * but
 *
 * Cell has the position relative, so that any children that have
 * the position absolute, will have Cell as their containing block:
 * this makes for example kebab menus or input error messages, that have
 * position: absolute to be positioned correctly.
 * Note: this element that has the position: relative has to be something else
 * than a <td>, because value 'relative's behaviour is undefined in many table
 * elements (https://developer.mozilla.org/en-US/docs/Web/CSS/position#relative).
 *
 */

export const CellDiv = styled.div<CellDivProps>`
  /* Needed for children and grandchildren, not this component itself */
  position: relative;
  display: flex;
  flex-flow: row wrap;
  align-items: center;

  ${({ align }) => (align ? `justify-content: ${aligns[align]};` : '')}

  &:hover > ${ToolTip} {
    display: block;
  }
`;

export const GridCellTd = styled.td<CellDivProps>`
  /* Needed for children and grandchildren, not this component itself */
  position: relative;

  padding: 0 ${(props) => props.theme.margin[8]};

  display: flex;
  flex-flow: row wrap;
  align-items: center;

  ${({ align }) => (align ? `justify-content: ${aligns[align]};` : '')}

  &:hover > ${ToolTip} {
    display: block;
  }
`;

export const GridCellTh = styled.th<CellDivProps>`
  /* Needed for children and grandchildren, not this component itself */
  position: relative;

  padding: 0 ${(props) => props.theme.margin[8]};

  display: flex;
  flex-flow: row wrap;
  align-items: center;

  ${({ align }) => (align ? `justify-content: ${aligns[align]};` : '')}

  &:hover > ${ToolTip} {
    display: block;
  }
`;

const CellTd = styled.td<CellTdProps>`
  padding: 0 ${(props) => props.theme.margin[8]};
  height: ${(props) =>
    props.condensed ? props.theme.margin[32] : props.theme.margin[40]};
  ${({ align }) => (align ? `text-align: ${align};` : '')}
`;

// FIXME TODO give align prop a better name or something?
export type CellProps = Omit<React.TdHTMLAttributes<HTMLElement>, 'align'> &
  CellDivProps & {
    contentContainer?: React.ComponentType;
  };

/**
 * Cell is actually a CellDiv component, that has been wrapped inside a <td>,
 * so that we are spared by writing the <td> element with CellDiv.
 *
 * @param align Positions the children either to 'left', 'right' or 'center'.
 *
 * @param children Any React nodes that will be rendered as children.
 */

const Cell: React.FC<CellProps> = ({
  align,
  children,
  contentContainer: ContentContainer = CellDiv,
  className,
  condensed,
  ...rest
}) => (
  <CellTd {...rest} align={align} className={className} condensed={condensed}>
    <ContentContainer align={align}>{children}</ContentContainer>
  </CellTd>
);
export default Cell;

export const LeftPaddedCell = styled(Cell)`
  padding-left: ${(props) => props.theme.margin[16]};
`;

type MoneyCellProps = {
  value: Big;
  decimals?: number;
};

export const MoneyCell: React.FC<MoneyCellProps> = ({
  value,
  decimals,
  ...rest
}) => (
  <Cell {...rest} align="right">
    {big.priceFormat(value, decimals)}
  </Cell>
);

export const DeltaMoneyCell: React.FC<MoneyCellProps> = ({
  value,
  ...rest
}) => (
  <DeltaCell {...rest} align="right" value={value}>
    {value.abs().eq(new Big(0)) ? '' : big.priceFormat(value)}
  </DeltaCell>
);

const DeltaCell = styled(Cell)<Partial<MoneyCellProps>>`
  color: ${({
    theme: {
      color: { positiveGreen, negativeRed },
    },
    value = Big(0),
  }) => (value.gte(0) ? positiveGreen : negativeRed)};
`;
