import React, { useState } from 'react';

import styled from 'styled-components';

import { IconDown, IconRight } from '../assets';

import { TextId } from '../localization';

import Txt from './Txt';

interface IAccordionProps {
  children: React.ReactNode;
  isOpen?: boolean;
  titleTextID: TextId;
}

const Accordion = ({
  children,
  titleTextID,
  isOpen = false,
}: IAccordionProps) => {
  const [open, setOpen] = useState(isOpen);
  const imgSrc = open ? IconDown : IconRight;

  return (
    <AccordionContainer>
      <AccordionTitle onClick={() => setOpen(!open)}>
        <img src={imgSrc} alt="accordion_open" />
        <Txt id={titleTextID} />
      </AccordionTitle>
      <AccordionBody>{open ? children : null}</AccordionBody>
    </AccordionContainer>
  );
};

export default Accordion;

const AccordionContainer = styled.div`
  border-radius: 5px 5px 0 0;
`;

const AccordionTitle = styled.h1`
  margin-bottom: ${(props) => props.theme.margin[16]};

  border-radius: 5px 5px 0 0;

  padding: ${(props) => props.theme.margin[8]};

  align-items: center;
  display: flex;

  background: ${(props) => props.theme.color.primaryRowBackground};

  font-size: 18px;
  font-weight: bold;
  font-family: 'Graphik', sans-serif;
`;

const AccordionBody = styled.div``;
