import { APIPurchaseInvoiceAttachment } from '../../types/api';
import { ID } from '../../types/general';

import {
  ExtractActionTypes,
  makeApiActions,
  makeAction,
} from '../../utils/actionCreators';
import {
  BackendError,
  FileResponse,
  GET,
  apiErrorHandlingWithDecode,
  getFileWithCredentials,
} from '../../utils/api';
import { flow } from '../../utils/function';
import * as remoteData from '../../utils/remoteData';
import { createAsyncThunk, Thunk } from '../../utils/thunk';

import { getInvoiceAttachmentFileRequest } from '../reducers/invoiceAttachments';

export type InvoiceAttachmentsAction = ExtractActionTypes<
  typeof actionCreators
>;

const actionCreators = {
  ...makeApiActions('get', 'invoiceAttachments')<
    APIPurchaseInvoiceAttachment[]
  >(),
  ...makeAction('getInvoiceAttachmentFileStarted')<{
    invoiceHeaderId: string;
    url: string;
  }>(),
  ...makeAction('getInvoiceAttachmentFileFailure')<{
    invoiceHeaderId: string;
    url: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('getInvoiceAttachmentFileSuccess')<{
    invoiceHeaderId: string;
    url: string;
    fileResponse: FileResponse;
    popUpBlocked?: boolean;
  }>(),
};
export const {
  getInvoiceAttachmentsStarted,
  getInvoiceAttachmentsSuccess,
  getInvoiceAttachmentsFailure,
  getInvoiceAttachmentFileStarted,
  getInvoiceAttachmentFileFailure,
  getInvoiceAttachmentFileSuccess,
} = actionCreators;

const fetchInvoiceAttachmentsForOrder = (orderId: ID) => {
  return GET<APIPurchaseInvoiceAttachment[]>(
    `v1/orders/${orderId}/purchase-invoice-attachments`
  );
};

export const requestInvoiceAttachmentsForOrder =
  (orderId: ID): Thunk =>
  (dispatch) => {
    dispatch(getInvoiceAttachmentsStarted());
    fetchInvoiceAttachmentsForOrder(orderId).then(
      (response) => {
        dispatch(getInvoiceAttachmentsSuccess(response));
      },
      (error) => {
        dispatch(
          getInvoiceAttachmentsFailure(apiErrorHandlingWithDecode(error))
        );
      }
    );
  };

export const fetchInvoiceAttachmentFile = (
  invoiceHeaderId: ID,
  url: string,
  openInNewTab?: boolean
) =>
  createAsyncThunk(getFileWithCredentials, {
    args: [url],
    isPending: flow(
      getInvoiceAttachmentFileRequest(invoiceHeaderId, url),
      remoteData.isLoading
    ),
    initialAction: getInvoiceAttachmentFileStarted({ invoiceHeaderId, url }),
    successActionCreator: (fileResponse) => {
      let popUpBlocked = false;

      if (openInNewTab) {
        const href = fileResponse.url;

        const { contentType } = fileResponse;

        // open pdf in new tab, other files will be downloaded
        if (contentType && contentType.toLowerCase() === 'application/pdf') {
          const openSuccess = window.open(href, '_blank');

          if (openSuccess === null) {
            popUpBlocked = true;
          }
        } else {
          const fileName = fileResponse.fileName
            ? fileResponse.fileName
            : 'unknown';
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', fileName); // download other type of files
          document.body.appendChild(link);
          link.click();

          document.body.removeChild(link);
        }
      }

      return getInvoiceAttachmentFileSuccess({
        invoiceHeaderId,
        url,
        fileResponse,
        popUpBlocked,
      });
    },
    failureActionCreator: (error) =>
      getInvoiceAttachmentFileFailure({
        invoiceHeaderId,
        url,
        error: apiErrorHandlingWithDecode(error),
      }),
  });
