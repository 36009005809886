import {
  useCallback,
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
  ForwardedRef,
} from 'react';

/**
 * Hook to handle focus on a list of elements.
 * Takes parentRefElement to check if the activeElement is a child of the parent element.
 * This prevents the hook from interfering with other elements on the page.
 * Takes filteringHTMLAttributes to filter out elements that should not be focused
 * if parent has nested multiple roveFocus within it.
 */

function useRoveFocus(
  size: number,
  parentRefElement: ForwardedRef<HTMLElement | undefined>,
  filteringHTMLAttributes?: Record<string, string>
): [number, Dispatch<SetStateAction<number>>] {
  const [currentFocus, setCurrentFocus] = useState(0);

  const handleKeyUp = useCallback(
    (e) => {
      const { activeElement } = document;

      const parentElement =
        typeof parentRefElement === 'object' ? parentRefElement?.current : null;

      const elementMatchesAttributes = (
        element: Element,
        attributes: Record<string, string>
      ): boolean => {
        return Object.entries(attributes).some(
          ([key, value]) => element.getAttribute(key) === value
        );
      };

      if (
        e.keyCode === 40 &&
        activeElement &&
        parentElement &&
        parentElement.contains(activeElement)
      ) {
        // Down arrow
        if (
          !filteringHTMLAttributes ||
          !elementMatchesAttributes(activeElement, filteringHTMLAttributes)
        ) {
          e.preventDefault();
          setCurrentFocus(currentFocus === size - 1 ? 0 : currentFocus + 1);
        }
      } else if (
        e.keyCode === 38 &&
        activeElement &&
        parentElement &&
        parentElement.contains(activeElement)
      ) {
        // Up arrow
        if (
          !filteringHTMLAttributes ||
          !elementMatchesAttributes(activeElement, filteringHTMLAttributes)
        ) {
          e.preventDefault();
          setCurrentFocus(currentFocus === 0 ? size - 1 : currentFocus - 1);
        }
      }
    },
    [
      size,
      currentFocus,
      setCurrentFocus,
      parentRefElement,
      filteringHTMLAttributes,
    ]
  );

  useEffect(() => {
    document.addEventListener('keyup', handleKeyUp, false);

    return () => {
      document.removeEventListener('keyup', handleKeyUp, false);
    };
  }, [handleKeyUp]);

  return [currentFocus, setCurrentFocus];
}

export default useRoveFocus;
