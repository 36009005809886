import * as React from 'react';

import styled from 'styled-components';

import { getRevenueForAnalysisRow } from '../../../../store/reducers/revenue/revenue';

import * as Actions from '../../../../store/actions';

import useRemoteData from '../../../../hooks/useRemoteData';
import useRouteParams from '../../../../hooks/useRouteParams';
import useTxt from '../../../../hooks/useTxt';

import {
  StickyHeaderTh,
  Table,
  TableHeader,
} from '../../../../components/Table';

import { TableRow } from './TableRow';

type AnalysisInspectionTableProps = {
  analysisRowId: string;
};

export const InspectionTable = ({
  analysisRowId,
}: AnalysisInspectionTableProps) => {
  const { projectId } = useRouteParams();

  const orderRows = useRemoteData(
    getRevenueForAnalysisRow(projectId, analysisRowId),
    Actions.requestRevenues(projectId) || []
  );

  const headerBatchCode = useTxt('analysis.table.header.code');
  const headerDescription = useTxt('analysis.table.header.description');

  return (
    <StyledDivTableContainer>
      <Table>
        <TableHeader>
          <tr>
            <StickyHeaderTh width="20rem">{headerBatchCode}</StickyHeaderTh>
            <StickyHeaderTh width="auto">{headerDescription}</StickyHeaderTh>
            <StickyHeaderTh width="3rem" />
            <StickyHeaderTh width="3rem" />
            <StickyHeaderTh width="10rem" />
          </tr>
        </TableHeader>
        {orderRows ? (
          <tbody>
            {orderRows.map((orderRow) => (
              <TableRow
                key={orderRow.id}
                analysisRowId={analysisRowId}
                orderRow={orderRow}
              />
            ))}
          </tbody>
        ) : null}
      </Table>
    </StyledDivTableContainer>
  );
};

const StyledDivTableContainer = styled.div`
  max-height: 70vh;

  display: flex;
  flex-direction: column;

  flex: 1;

  overflow-y: scroll;
`;
