import { RawAPIUpdatedEntities, APIUpdatedEntities } from '../../../types/api';
import { mapRawUpdatedEntities } from '../../../types/mappers';

import { makeAction, ExtractActionTypes } from '../../../utils/actionCreators';
import {
  POST,
  BackendError,
  apiErrorHandlingWithDecode,
} from '../../../utils/api';
import { createAsyncThunk } from '../../../utils/thunk';

import { isLoading } from '../../reducers/target/convertRequests';

const actionCreators = {
  ...makeAction('convertTargetRowsToOrderRowsStarted')<{
    requestId: string;
  }>(),
  ...makeAction('convertTargetRowsToOrderRowsSuccess')<
    {
      requestId: string;
    } & APIUpdatedEntities
  >(),
  ...makeAction('convertTargetRowsToOrderRowsFailure')<{
    requestId: string;
    error: BackendError | undefined;
  }>(),
};

export type ConvertTargetRowAction = ExtractActionTypes<typeof actionCreators>;

const {
  convertTargetRowsToOrderRowsStarted,
  convertTargetRowsToOrderRowsSuccess,
  convertTargetRowsToOrderRowsFailure,
} = actionCreators;

export interface PostConvertTargetRowsToOrderRowParams {
  targetRowHierarchyEntryId: string | null;
  targetRowIds: string[];
  orderId: string;
  workPackageId: string;
  topicName: string;
  topicId: string | null;
}

export interface PostConvertTargetRowsToOrderRowsBodyParams {
  data: PostConvertTargetRowsToOrderRowParams[];
}

const requestConvertTargetRowsToOrderRows = async (
  params: PostConvertTargetRowsToOrderRowParams[]
): Promise<APIUpdatedEntities> => {
  const response = await POST<RawAPIUpdatedEntities>(
    `v1/target-rows/convert-to-order-rows`,
    {
      data: [...params],
    }
  );

  return mapRawUpdatedEntities(response);
};

export const convertTargetRowsToOrderRows = ({
  requestId,
  params,
}: {
  requestId: string;
  params: PostConvertTargetRowsToOrderRowParams[];
}) =>
  createAsyncThunk(requestConvertTargetRowsToOrderRows, {
    args: [params],
    initialAction: convertTargetRowsToOrderRowsStarted({ requestId }),
    isPending: isLoading(requestId),
    failureActionCreator: (error) =>
      convertTargetRowsToOrderRowsFailure({
        requestId,
        error: apiErrorHandlingWithDecode(error),
      }),
    successActionCreator: (updatedEntities) =>
      convertTargetRowsToOrderRowsSuccess({
        requestId,
        ...updatedEntities,
      }),
  });
