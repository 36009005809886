import React, { useState, useRef, useContext } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import styled, { css } from 'styled-components';

import { getProjectOrders } from '../../../../store/reducers/order/order';
import { getProjectById } from '../../../../store/reducers/project';
import { getTargetRowHierarchyEntriesForProject } from '../../../../store/reducers/target/hierarchy';
import { getTargetRowsForProject } from '../../../../store/reducers/target/targetRows';
import { getTopics } from '../../../../store/reducers/topic';
import { getProjectWorkPackages } from '../../../../store/reducers/workPackage';

import {
  fetchOrdersForProject,
  fetchWorkPackagesForProject,
  getTargetRowHierarchyForProject,
  requestTargetRowsForProject,
} from '../../../../store/actions';

import useLocalization from '../../../../hooks/useLocalization';
import useRemoteData from '../../../../hooks/useRemoteData';

import {
  ButtonGroup,
  IconTextButton,
  SecondaryButton,
} from '../../../../components/Buttons';
import { CircledCloseButton } from '../../../../components/Modal/Modal';
import Txt from '../../../../components/Txt';

import {
  dataFields,
  downloadCSV,
  propertyIsNotNull,
  TargetImportData,
  targetRowHierarchyEntryToTargetImportData,
  targetRowToTargetImportData,
} from './utils';
import { isDefined } from '../../../../utils/general';

import {
  IconDelete,
  IconInvalid,
  IconCsvDocument,
  IconQuestionmark,
  IconDownload,
  IconCloseWhite,
} from '../../../../assets/svg';

import { routes, useParams } from '../../../../routes';

import CsvTableIcon from './CsvTableExampleIcon';
import { Context } from '../../../../context/locale';

const KILO_BYTES_PER_BYTE = 1024;

const MAX_FILE_SIZE_IN_MEGABYTES = 50;

const DEFAULT_MAX_FILE_SIZE_IN_BYTES =
  MAX_FILE_SIZE_IN_MEGABYTES * KILO_BYTES_PER_BYTE ** 2;

type FileUploadProps = {
  updateFileCb: (file: File | null) => void;
  initialFile?: File | null;
  projectHasTargetRows: boolean;
};

const FileUpload = ({
  updateFileCb,
  initialFile,
  projectHasTargetRows,
}: FileUploadProps) => {
  const { projectId } = useParams(routes.TARGET);
  const maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES;
  const fileInputField = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File | null>(initialFile ?? null);
  const [showInstructions, setShowInstructions] = useState(false);
  const [highlightError, setHighlightError] = useState(false);
  const [targeted, setTargeted] = useState(false);

  const targetRowHierarchyEntries = useRemoteData(
    getTargetRowHierarchyEntriesForProject(projectId),
    getTargetRowHierarchyForProject({ projectId })
  );

  const targetRows =
    useRemoteData(
      getTargetRowsForProject({ projectId }),
      requestTargetRowsForProject({ projectId })
    ) ?? [];

  const topics = useSelector(getTopics, shallowEqual);

  const orders =
    useRemoteData(
      getProjectOrders(projectId),
      fetchOrdersForProject(projectId)
    ) ?? [];

  const workPackages =
    useRemoteData(
      getProjectWorkPackages(projectId),
      fetchWorkPackagesForProject(projectId)
    ) ?? [];

  const mappedExternalExistingHierarchyEntries =
    targetRowHierarchyEntries
      ?.filter((row) => propertyIsNotNull(row, 'externalId'))
      .map((row) =>
        propertyIsNotNull(row, 'externalId')
          ? targetRowHierarchyEntryToTargetImportData(row)
          : undefined
      ) ?? [];

  const mappedExternalExistingTargetRows = targetRows
    .filter((row) => propertyIsNotNull(row, 'externalCode'))
    .map((row) => {
      if (propertyIsNotNull(row, 'externalCode')) {
        const order = orders.find((o) => o.id === row.orderId);
        const topic = topics.find((t) => t.id === row.topicId);

        const workPackage = workPackages.find(
          (wp) => wp.id === topic?.workPackageId
        );

        return targetRowToTargetImportData(row, order, workPackage);
      }

      return undefined;
    });

  const oldData = [
    ...mappedExternalExistingHierarchyEntries.filter(isDefined),
    ...mappedExternalExistingTargetRows.filter(isDefined),
  ].sort((a, b) => (a.referenceNo ?? '').localeCompare(b.referenceNo ?? ''));

  const localization = useLocalization();

  const { lang } = useContext(Context);

  const project = useSelector(getProjectById(projectId));

  const addNewFile = (newFile: File) => {
    if (
      newFile.size <= maxFileSizeInBytes &&
      newFile.name.toLowerCase().endsWith('.csv')
    ) {
      return newFile;
    }

    setHighlightError(true);

    return null;
  };

  const handleNewFileUpload = (e: { target: { files: any } }) => {
    setHighlightError(false);
    setTargeted(false);
    const newFiles: FileList = e.target.files;

    if (newFiles.length === 1) {
      const updatedFile = addNewFile(newFiles[0]);
      setFile(updatedFile);
      updateFileCb(updatedFile);
    } else {
      setHighlightError(true);
    }
  };

  const handleDragEnter = () => {
    setTargeted(true);
  };

  const handleDragLeave = () => {
    setTargeted(false);
  };

  const removeFile = () => {
    setFile(null);
    updateFileCb(null);
  };

  const onInstructionsClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    setShowInstructions(!showInstructions);
  };

  const onDownloadExampleClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    const keys = Object.keys(dataFields) as (keyof typeof dataFields)[];

    const mappedHeaders = keys.reduce(
      (acc, key) => {
        const header = dataFields[key];

        return {
          ...acc,
          [key]: localization.formatMessage({ id: header.translation }),
        };
      },
      {} as Record<keyof TargetImportData, string>
    );

    const locale = lang === 'fi' ? 'fi-FI' : 'en-US';

    downloadCSV(
      mappedHeaders,
      oldData.length > 0 ? oldData : undefined,
      locale
    );
  };

  const preventDefaultForDraggingOver = (
    e: React.DragEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
  };

  if (!project) {
    return null;
  }

  const preventDefaultForDragging = (e: React.DragEvent<any>) => {
    e.preventDefault();
  };

  return (
    <>
      <FileUploadContainer targeted={targeted}>
        <CsvTableIcon />
        <HeaderText>
          <Txt id="target.targetImportModal.uploadFile.header" />
        </HeaderText>
        <ParagraphText>
          <Txt
            id="target.targetImportModal.uploadFile.paragraph"
            values={{ projectCodeName: `${project.code} ${project.name}` }}
          />
        </ParagraphText>
        {projectHasTargetRows ? (
          <ParagraphText>
            <Txt id="target.targetImportModal.uploadFile.paragraph2" />
          </ParagraphText>
        ) : null}

        {highlightError ? (
          <ParagraphText isError>
            <IconError />
            &nbsp;
            <Txt
              id="target.targetImportModal.uploadFile.error"
              values={{ megabytes: MAX_FILE_SIZE_IN_MEGABYTES }}
            />
          </ParagraphText>
        ) : null}
        <StyledButtonGroup targeted={targeted}>
          <RelativeDiv>
            <StyledIconTextButton
              icon={IconQuestionmark}
              onClick={onInstructionsClick}
              onDragOver={preventDefaultForDraggingOver}
              onDrop={preventDefaultForDragging}
            >
              <Txt id="target.targetImportModal.uploadFile.checkInstructions" />
            </StyledIconTextButton>
            {showInstructions ? (
              <ImportInstructionDiv onClick={(e) => e.stopPropagation()}>
                <RelativeDiv>
                  <div>
                    <StyledIconQuestionmark fill="white" />
                  </div>
                  <Column>
                    <Txt
                      id="target.targetImportModal.uploadFile.instructions.header"
                      component="h1"
                    />
                    <ol>
                      <Txt
                        id="target.targetImportModal.uploadFile.instructions.paragraph1"
                        component={InstructionTextListItem}
                      />
                      <Txt
                        id="target.targetImportModal.uploadFile.instructions.paragraph2"
                        component={InstructionTextListItem}
                      />
                      <Txt
                        id="target.targetImportModal.uploadFile.instructions.paragraph3"
                        component={InstructionTextListItem}
                      />
                      <Txt
                        id="target.targetImportModal.uploadFile.instructions.paragraph4"
                        component={InstructionTextListItem}
                      />
                      <Txt
                        id="target.targetImportModal.uploadFile.instructions.paragraph5"
                        component={InstructionTextListItem}
                      />
                      <ul>
                        <Txt
                          id="target.targetImportModal.uploadFile.instructions.paragraph5.detail1"
                          component={InstructionTextListItem}
                        />
                        <Txt
                          id="target.targetImportModal.uploadFile.instructions.paragraph5.detail2"
                          component={InstructionTextListItem}
                        />
                        <Txt
                          id="target.targetImportModal.uploadFile.instructions.paragraph5.detail3"
                          component={InstructionTextListItem}
                        />
                      </ul>
                      <Txt
                        id="target.targetImportModal.uploadFile.instructions.paragraph6"
                        component={InstructionTextListItem}
                      />
                      <Txt
                        id="target.targetImportModal.uploadFile.instructions.paragraph7"
                        component={InstructionTextListItem}
                      />
                    </ol>
                  </Column>
                  <StyledCircledCloseButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowInstructions(false);
                    }}
                    type="button"
                    icon={StyledIconCloseWhite}
                    tabIndex={0}
                  />
                </RelativeDiv>
              </ImportInstructionDiv>
            ) : null}
          </RelativeDiv>

          <StyledIconTextButton
            icon={IconDownload}
            onClick={onDownloadExampleClick}
            onDragOver={preventDefaultForDraggingOver}
            onDrop={preventDefaultForDragging}
          >
            <Txt
              id={
                oldData.length > 0
                  ? 'target.targetImportModal.uploadFile.downloadExistingData'
                  : 'target.targetImportModal.uploadFile.downloadExample'
              }
            />
          </StyledIconTextButton>
        </StyledButtonGroup>
        <StyledSecondaryButton as="p">
          <Txt id="target.targetImportModal.uploadFile.upload.button" />
        </StyledSecondaryButton>
        <FormField
          type="file"
          ref={fileInputField}
          data-testid="targetImportModal-uploadInput"
          onChange={handleNewFileUpload}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          title=""
          value=""
          accept=".csv"
        />
        {file === null ? null : (
          <StyledSecondaryButton as="p" zIndex={1}>
            <UploadIcon />
            {file.name}
            <DeleteIcon onClick={() => removeFile()} />
          </StyledSecondaryButton>
        )}
      </FileUploadContainer>
    </>
  );
};

const IconError = styled(IconInvalid)`
  margin: 0 0.25rem 0.25rem 0.25rem;
  width: 1.5rem;
  height: 1.5rem;
`;

const StyledButtonGroup = styled(ButtonGroup)<{ targeted: boolean }>`
  z-index: ${({ targeted }) => (targeted ? 'inherit' : 2)};
`;

type StyledSecondaryButtonProps = {
  zIndex?: number;
};

const StyledSecondaryButton = styled(
  SecondaryButton
)<StyledSecondaryButtonProps>`
  margin-top: ${({ theme }) => theme.margin[16]};
  display: flex;
  ${({ zIndex }) =>
    zIndex
      ? css`
          z-index: ${zIndex};
        `
      : ''}
`;

const StyledIconTextButton = styled(IconTextButton)`
  position: relative;
  padding: ${({ theme }) => theme.margin[8]};
  height: ${({ theme }) => theme.sizes.iconButtonLarge};
`;

const FileUploadContainer = styled.div<{ targeted: boolean }>`
  position: relative;

  margin: 0;
  margin-bottom: 15px;

  border: ${({ targeted }) => (targeted ? '2px solid black' : 'none')};
  border-radius: 6px;

  padding: 4rem 2rem;

  display: flex;
  flex-direction: column;
  align-items: center;

  flex-grow: 1;

  background: rgb(231 224 236 / 29%);

  &:hover {
    ${SecondaryButton} {
      background: ${(props) => props.theme.color.graphiteB97};
    }
  }
`;

const UploadIcon = styled(IconCsvDocument)`
  margin-right: 0.5rem;
  width: 1rem;
  height: 1rem;
`;

const FormField = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  border: none;

  width: 100%;

  display: block;

  opacity: 0;
`;

const HeaderText = styled.h1`
  margin: ${({ theme }) => `${theme.margin[16]} 0 ${theme.margin[8]}`} 0;
  display: flex;
  align-items: center;
`;

const ParagraphText = styled.p<{ isError?: boolean }>`
  margin: ${({ theme }) => `${theme.margin[16]}`};
  display: flex;
  align-items: center;
  color: ${({ isError, theme }) =>
    isError ? theme.color.red : theme.color.graphite};
`;

const DeleteIcon = styled(IconDelete)`
  margin-left: ${({ theme }) => theme.margin[8]};
  &:hover {
    border: 1px solid red;
  }
`;

const ImportInstructionDiv = styled.div`
  position: absolute;
  left: -100%;
  top: 100%;

  margin-left: -100%;

  border-radius: 1rem;

  padding: ${({ theme }) => theme.margin[16]};

  background: black;

  color: white;

  z-index: 100;
`;

const InstructionTextListItem = styled.li`
  margin: ${({ theme }) => theme.margin[4]} 0;
`;

const StyledIconQuestionmark = styled(IconQuestionmark)`
  width: 2rem;
  height: 2rem;
`;

const Column = styled.div`
  margin: 0 ${({ theme }) => theme.margin[8]};
  min-width: 800px;
  display: flex;
  flex-direction: column;
`;

const RelativeDiv = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const StyledCircledCloseButton = styled(CircledCloseButton)`
  top: 0;
  right: 0;
`;

const StyledIconCloseWhite = styled(IconCloseWhite)`
  margin: 11px;
`;

export default FileUpload;
