import React from 'react';
import { useSelector } from 'react-redux';

import Big from 'big.js';
import _ from 'lodash';
import styled, { css } from 'styled-components';

import { getProject } from '../../../../store/reducers/project';
import {
  getOriginalTargetUpdateRequest,
  hasProjectAnyTargetRows,
} from '../../../../store/reducers/target/targetRows';

import useTxt from '../../../../hooks/useTxt';
import useTargetViewData from '../../hooks/useTargetViewData';

import {
  ButtonGroup,
  SecondaryButton,
  PrimaryButton,
} from '../../../../components/Buttons';
import Checkbox from '../../../../components/Input/Checkbox';
import { Spinner } from '../../../../components/Loading';
import { Footer } from '../../../../components/Modal/Modal';
import ToolTip from '../../../../components/Tooltip';
import Txt from '../../../../components/Txt';

import { priceFormat } from '../../../../utils/big';
import { withDefault } from '../../../../utils/remoteData';
import { getAllRowsFlattened } from '../../../../utils/tableUtils';

import {
  IconChange,
  IconCloseCircle,
  IconEdit,
  IconPlus,
} from '../../../../assets';

import { routes, useParams } from '../../../../routes';

import { FilterState, ValidatedDataState } from '.';

type TargetModalFooterProps = {
  setFilterState: React.Dispatch<React.SetStateAction<FilterState | undefined>>;
  nextButtonDisabled: boolean;
  validatedData: ValidatedDataState;
  step: number;
  requestId: string;
  onClose: () => void;
  onClickNext: () => void;
  isOrderUpdateAllowed: boolean;
  setIsOrderUpdateAllowed: React.Dispatch<React.SetStateAction<boolean>>;
};

export const TargetModalFooter = ({
  setFilterState,
  nextButtonDisabled,
  step,
  validatedData,
  requestId,
  onClose,
  onClickNext,
  isOrderUpdateAllowed,
  setIsOrderUpdateAllowed,
}: TargetModalFooterProps) => {
  const { projectId } = useParams(routes.TARGET);

  const projectData = withDefault(
    useSelector(getProject(projectId)),
    undefined
  );

  const currentTarget = projectData?.targetTotal ?? new Big(0);

  const isOrderUpdateAllowedTooltip = useTxt(
    'target.targetImportModal.previewTable.isOrderUpdateAllowed.tooltip'
  );

  const requestState = useSelector(
    getOriginalTargetUpdateRequest(requestId)
  ).kind;

  const unfilteredPreviewData = useTargetViewData(undefined, {
    targetRows: validatedData.targetRows,
    targetRowHierarchyEntries: validatedData.targetRowHierarchyEntries,
    topics: validatedData.topics,
    orders: validatedData.orders,
    workPackages: validatedData.workPackages,
  });

  const previewDataFlattened = getAllRowsFlattened(unfilteredPreviewData);

  const projectHasTargetRows = useSelector(hasProjectAnyTargetRows(projectId));

  const invalidRowCount = previewDataFlattened.filter(
    (row) => row.invalid
  ).length;

  const changedRowCount = previewDataFlattened.filter(
    (row) =>
      row.updateInformation && row.updateInformation.changeType !== 'noChange'
  ).length;

  const addedRows = previewDataFlattened.filter(
    (row) =>
      row.updateInformation && row.updateInformation.changeType === 'create'
  );

  const addedAmount = addedRows.reduce((acc, row) => {
    if (row.isDeleted === true || row.type === 'targetRowHierarchyEntry') {
      return acc;
    }

    return acc.plus(row.target ?? 0);
  }, new Big(0));

  const deletedRows = previewDataFlattened.filter(
    (row) =>
      row.updateInformation && row.updateInformation.changeType === 'delete'
  );

  const deletedAmount = deletedRows.reduce((acc, row) => {
    if (row.type === 'targetRowHierarchyEntry') {
      return acc;
    }

    return acc.minus(row.target ?? 0);
  }, new Big(0));

  const updatedRows = previewDataFlattened.filter(
    (row) =>
      row.updateInformation && row.updateInformation.changeType === 'update'
  );

  const updatedAmount = updatedRows.reduce((acc, row) => {
    const targetUpdateInformation = row.updateInformation?.target;

    if (row.type === 'targetRowHierarchyEntry') {
      return acc;
    }

    if (!targetUpdateInformation) {
      return acc;
    }

    const oldValue = targetUpdateInformation.oldData ?? new Big(0);
    const newValue = targetUpdateInformation.newData ?? new Big(0);

    return acc.plus(newValue.minus(oldValue));
  }, new Big(0));

  const rowCount =
    validatedData.targetRows.length +
    validatedData.targetRowHierarchyEntries.length;

  const toggleFilter = (buttonFilter: FilterState) => {
    setFilterState((value) =>
      value === buttonFilter ? undefined : buttonFilter
    );
  };

  const targetAmount = validatedData.targetRows.reduce((acc, row) => {
    if (row.isDeleted === true) {
      return acc;
    }

    return acc.plus(row.totalPrice ?? 0);
  }, new Big(0));

  return (
    <>
      <ExtraFooter>
        {step === 2 ? (
          <>
            <Txt id="target.targetImportModal.previewTable.isOrderUpdateAllowed" />
            <ToolTip
              tip={isOrderUpdateAllowedTooltip}
              noWrap
              className="compressed-tooltip"
            >
              <Checkbox
                checked={isOrderUpdateAllowed}
                onChange={() => setIsOrderUpdateAllowed((prev) => !prev)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    e.stopPropagation();
                    e.preventDefault();
                    setIsOrderUpdateAllowed((prev) => !prev);
                  }
                }}
              />
            </ToolTip>
          </>
        ) : (
          <div />
        )}
      </ExtraFooter>

      <StyledFooter>
        {step === 2 ? (
          <>
            <KeyFiguresDiv flexGrow={1}>
              <Column>
                <KeyFiguresDiv>
                  <RowColorIndicator />
                  <Txt id="target.targetImportModal.previewTable.rowsToBeImported" />
                  <AmountText>({rowCount})</AmountText>
                </KeyFiguresDiv>
                <KeyFiguresDiv onClick={() => toggleFilter('invalid')}>
                  <RowColorIndicator rowType="invalid" />
                  <Txt id="target.targetImportModal.previewTable.invalidRows" />
                  <AmountText>({invalidRowCount})</AmountText>
                </KeyFiguresDiv>
              </Column>
              <Column>
                <KeyFiguresDiv onClick={() => toggleFilter('create')}>
                  <RowColorIndicator rowType="add">
                    <Icon src={IconPlus} />
                  </RowColorIndicator>
                  <Txt id="target.targetImportModal.previewTable.addedRows" />
                  <AmountText>({addedRows.length})</AmountText>
                </KeyFiguresDiv>
                <KeyFiguresDiv onClick={() => toggleFilter('delete')}>
                  <RowColorIndicator rowType="delete">
                    <Icon src={IconCloseCircle} />
                  </RowColorIndicator>
                  <Txt id="target.targetImportModal.previewTable.deletedRows" />
                  <AmountText>({deletedRows.length})</AmountText>
                </KeyFiguresDiv>
              </Column>
              <Column>
                <KeyFiguresDiv onClick={() => toggleFilter('update')}>
                  <RowColorIndicator rowType="change">
                    <Icon src={IconEdit} />
                  </RowColorIndicator>
                  <Txt id="target.targetImportModal.previewTable.updatedRows" />
                  <AmountText>({updatedRows.length})</AmountText>
                </KeyFiguresDiv>
                <KeyFiguresDiv onClick={() => toggleFilter('changed')}>
                  <RowColorIndicator>
                    <Icon src={IconChange} />
                  </RowColorIndicator>
                  <Txt id="target.targetImportModal.previewTable.changedRows" />
                  <AmountText>({changedRowCount})</AmountText>
                </KeyFiguresDiv>
              </Column>
            </KeyFiguresDiv>
            <KeyFiguresDiv>
              <Column>
                <KeyFiguresDiv>
                  <Txt id="target.targetImportModal.previewTable.importedProcurements" />
                  :<AmountText>{validatedData.orders.length}</AmountText>
                </KeyFiguresDiv>
                <KeyFiguresDiv>
                  <Txt id="target.targetImportModal.previewTable.importedWorkSections" />
                  :<AmountText>{validatedData.workPackages.length}</AmountText>
                </KeyFiguresDiv>
              </Column>
              <Column>
                <KeyFiguresDiv>
                  <Txt id="target.targetImportModal.previewTable.hierarchyRowsToBeImported" />
                  :
                  <AmountText fontSize="1.125rem">
                    {validatedData.targetRowHierarchyEntries.length}
                  </AmountText>
                </KeyFiguresDiv>
                <KeyFiguresDiv>
                  <Txt id="target.targetImportModal.previewTable.targetRowsToBeImported" />
                  :
                  <AmountText fontSize="1.125rem">
                    {validatedData.targetRows.length}
                  </AmountText>
                </KeyFiguresDiv>
                <KeyFiguresDiv>
                  <Txt id="target.targetImportModal.previewTable.targetRowsToBeImported.amount" />
                  :
                  <AmountText fontSize="1.125rem">
                    {priceFormat(targetAmount, 0)}
                  </AmountText>
                </KeyFiguresDiv>
              </Column>
              <Column>
                <KeyFiguresDiv>
                  <Txt id="target.targetImportModal.previewTable.currentTargetAmount" />
                  :
                  <AmountText fontSize="1.125rem">
                    {priceFormat(currentTarget, 0)}
                  </AmountText>
                </KeyFiguresDiv>
                <KeyFiguresDiv>
                  <Txt id="target.targetImportModal.previewTable.targetChange" />
                  :
                  <AmountText fontSize="1.125rem">
                    {priceFormat(
                      addedAmount.plus(updatedAmount).plus(deletedAmount),
                      0
                    )}
                  </AmountText>
                </KeyFiguresDiv>
                <KeyFiguresDiv>
                  <Txt id="target.targetImportModal.previewTable.newTargetAmount" />
                  :
                  <AmountText fontSize="1.125rem">
                    {priceFormat(
                      currentTarget
                        .plus(addedAmount)
                        .plus(updatedAmount)
                        .plus(deletedAmount),
                      0
                    )}
                  </AmountText>
                </KeyFiguresDiv>
              </Column>
            </KeyFiguresDiv>
          </>
        ) : (
          <div />
        )}
        <StyledButtonGroup>
          <SecondaryButton type="button" onClick={() => onClose()}>
            <Txt id="common.cancel" />
          </SecondaryButton>
          <PrimaryButton
            type="button"
            disabled={nextButtonDisabled}
            onClick={onClickNext}
          >
            {requestState === 'Loading' ? (
              <Spinner size="1rem" />
            ) : (
              <>
                {step === 2 ? (
                  <Txt
                    id={
                      projectHasTargetRows
                        ? 'target.toolbelt.button.updateTarget'
                        : 'target.toolbelt.button.importTarget'
                    }
                  />
                ) : (
                  <Txt id="common.next" />
                )}
              </>
            )}
          </PrimaryButton>
        </StyledButtonGroup>
      </StyledFooter>
    </>
  );
};

const ExtraFooter = styled.div`
  padding: ${(props) =>
    `${props.theme.margin[16]} ${props.theme.margin[48]} ${props.theme.margin[8]} ${props.theme.margin[32]}`};

  display: flex;
  justify-content: flex-end;
  align-items: center;

  background: ${(props) => props.theme.color.white};

  gap: ${(props) => props.theme.margin[16]};
`;

export const StyledFooter = styled(Footer)`
  box-shadow: none;
  padding: ${(props) =>
    `0 ${props.theme.margin[32]} ${props.theme.margin[32]}`};
  justify-content: space-between;
`;

export const KeyFiguresDiv = styled.div<{ flexGrow?: number }>`
  margin-right: ${(props) => props.theme.margin[16]};
  display: flex;
  flex-direction: row;
  align-items: center;
  ${({ flexGrow }) =>
    flexGrow
      ? css`
          flex-grow: ${flexGrow};
        `
      : null}
  ${({ onClick }) =>
    onClick
      ? css`
          cursor: pointer;
        `
      : null}
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${(props) => props.theme.margin[8]};
`;

const RowColorIndicator = styled.div<{
  rowType?: 'invalid' | 'change' | 'delete' | 'add';
}>`
  margin-right: ${(props) => props.theme.margin[8]};

  border: 1px solid ${({ theme }) => theme.color.graphiteB76};
  border-radius: 10%;

  height: 18px;
  width: 18px;

  ${({ rowType }) => {
    switch (rowType) {
      case 'invalid':
        return css`
          background: ${({ theme }) => theme.color['M3/ref/error/error90']};
        `;
      case 'change':
        return css`
          background: rgb(244 183 107 / 29%);
        `;
      case 'delete':
        return css`
          background: ${({ theme }) => theme.color.graphiteB86};
        `;
      case 'add':
        return css`
          background: rgb(141 222 129 / 29%);
        `;
      default:
        return css`
          background: white;
        `;
    }
  }}
`;

export const AmountText = styled.b<{ fontSize?: string }>`
  margin: 0 ${(props) => props.theme.margin[16]} 0
    ${(props) => props.theme.margin[4]};
  flex-grow: 1;
  text-align: right;
  ${({ fontSize }) =>
    fontSize
      ? css`
          font-size: ${fontSize};
        `
      : null}
`;

type IconProps = {
  fillColor?: string;
};

const Icon = styled.img<IconProps>`
  margin: 0;
  width: 16px;
  height: 16px;

  /* stylelint-disable selector-max-type -- svgs have to be styled with type */
  ${(props) =>
    props.fillColor
      ? css`
          & path {
            fill: ${props.fillColor};
          }
        `
      : null}
`;

const StyledButtonGroup = styled(ButtonGroup)`
  flex-shrink: 0;
`;
