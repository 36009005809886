import React from 'react';
import { useHistory } from 'react-router-dom';

import styled from 'styled-components';

import { AnalysisTable } from './components/AnalysisTable';
import Header from '../../components/Header';
import TabIconTextButton from '../../components/Navigation/TabIconTextButton';
import ProjectInfo from '../../components/ProjectInfo';
import Txt from '../../components/Txt';

import CAN, {
  CaslAnalysisDefinitionRequestParams,
} from '../../utils/caslUserPermissions';

import { IconEdit } from '../../assets/svg';

import { generateUrl, routes, useParams } from '../../routes';

const Container = styled.div`
  margin: ${({ theme }) => theme.margin[24]} ${({ theme }) => theme.margin[40]};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ControlContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ProjectInfoRow = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.color.rowBorder};
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

const Analysis = () => {
  const { projectId, viewMode = 'view' } = useParams(routes.ANALYSIS);

  const history = useHistory();

  // const addAnalysis = () => {};

  const toggleViewMode = () => {
    const nextViewMode = viewMode === 'edit' ? undefined : 'edit';

    const url = generateUrl({
      projectId,
      route: routes.ANALYSIS,
      viewMode: nextViewMode,
    });

    history.push(url);
  };

  const ability = new CaslAnalysisDefinitionRequestParams(projectId);

  const allowedUser = CAN('write', ability);

  return (
    <Container>
      <Header>
        <Txt id="analysis.header" />
      </Header>
      <ProjectInfoRow>
        <ProjectInfo projectId={projectId} />
        <ControlContainer>
          {/* <TabIconTextButton
            isActive={false}
            icon={IconPlus}
            onClick={addAnalysis}
            id="analysis.button.new"
            disabled={!allowedUser}
          /> */}
          <TabIconTextButton
            isActive={viewMode === 'edit'}
            onClick={toggleViewMode}
            icon={IconEdit}
            id="analysis.button.edit"
            disabled={!allowedUser}
          />
        </ControlContainer>
      </ProjectInfoRow>
      <AnalysisTable />
    </Container>
  );
};

export default Analysis;
