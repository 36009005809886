import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Big from 'big.js';
import styled from 'styled-components';
import * as uuid from 'uuid';

import {
  getMovePaymentProgramRowsRequest,
  getPaymentProgramRowGroups,
} from '../../../store/reducers/paymentProgramRowGroup';
import { getRevenuesByIds } from '../../../store/reducers/revenue/revenue';
import { getSelectedPaymentProgramRows } from '../../../store/reducers/ui';

import {
  requestMovePaymentProgramRowsToGroup,
  requestPaymentProgramRowGroups,
} from '../../../store/actions/paymentProgramRowGroup';

import useRemoteData from '../../../hooks/useRemoteData';
import useRouteParams from '../../../hooks/useRouteParams';
import useTxt from '../../../hooks/useTxt';

import { PrimaryButton, SecondaryButton } from '../../../components/Buttons';
import DropDownSelect from '../../../components/DropDownSelect';
import Modal, { Container, Header } from '../../../components/Modal/Modal';
import Txt from '../../../components/Txt';

import * as big from '../../../utils/big';

type MoveModalProps = {
  onClose: () => void | undefined;
};

const MoveModal = ({ onClose }: MoveModalProps) => {
  const { projectId } = useRouteParams();

  const [requestId] = React.useState(uuid.v4());

  const dispatch = useDispatch();

  const [selectedPaymentProgramRowGroup, setSelectedPaymentProgramRowGroup] =
    React.useState<string | undefined>(undefined);

  const paymentProgramRowIds = useSelector(
    getSelectedPaymentProgramRows(projectId)
  );

  const paymentProgramRows = useSelector(
    getRevenuesByIds({ revenueIds: paymentProgramRowIds, projectId })
  );

  const totalPricesSum = paymentProgramRows.reduce((previous, current) => {
    return previous.add(current.netPrice);
  }, new Big(0));

  const paymentProgramRowGroups =
    useRemoteData(
      getPaymentProgramRowGroups(projectId),
      requestPaymentProgramRowGroups(projectId)
    ) ?? [];

  const requestState = useSelector(
    getMovePaymentProgramRowsRequest(requestId)
  ).kind;

  const options = paymentProgramRowGroups.map(({ id, description }) => ({
    key: id,
    value: id,
    label: description,
  }));

  React.useEffect(() => {
    if (requestState === 'Success') {
      onClose();
    }
  }, [requestState, onClose]);

  const isActionButtonDisabled =
    selectedPaymentProgramRowGroup === undefined || requestState === 'Loading';

  const titleText = useTxt('revenue.moveRows.modal.title', {
    amount: paymentProgramRowIds.length,
    totalPrice: big.priceFormatRounded(totalPricesSum),
  });

  const sendRequest = () => {
    if (selectedPaymentProgramRowGroup) {
      dispatch(
        requestMovePaymentProgramRowsToGroup(
          { paymentProgramRowIds },
          selectedPaymentProgramRowGroup,
          requestId
        )
      );
    }
  };

  return (
    <Modal onClose={onClose}>
      <StyledContainer>
        <Header>{titleText}</Header>
        <Content>
          <Txt
            id="revenue.moveRows.modal.dropdownLabel"
            component={ListTitle}
          />
          <DropDownSelect
            onChange={setSelectedPaymentProgramRowGroup}
            options={options}
          />
        </Content>
        <Footer>
          <CancelButton onClick={onClose}>
            <Txt id="common.cancel" />
          </CancelButton>
          <ActionButton onClick={sendRequest} disabled={isActionButtonDisabled}>
            <Txt id="revenue.moveRows.modal.submitButton" />
          </ActionButton>
        </Footer>
      </StyledContainer>
    </Modal>
  );
};

export default MoveModal;

const ListTitle = styled.h2`
  margin-bottom: 1rem;
  font-family: 'Graphik', sans-serif;
  font-size: 0.875rem;
  font-weight: 600;
`;

const CancelButton = styled(SecondaryButton)`
  margin-right: 4px;
`;

const ActionButton = styled(PrimaryButton)`
  margin-right: 4px;
  margin-left: 4px;
`;

export const ButtonGroup = styled.div`
  margin-top: 3rem;
  display: flex;
  justify-content: space-around;
`;

const StyledContainer = styled(Container)`
  max-width: 1000px;
`;

const Footer = styled.div`
  border-radius: 0 0 ${({ theme }) => theme.radius.medium}
    ${({ theme }) => theme.radius.medium};

  padding: ${(props) =>
    `${props.theme.margin[16]} ${props.theme.margin[16]} ${props.theme.margin[16]} ${props.theme.margin[32]}`};

  display: flex;
  justify-content: flex-end;
  align-items: center;

  background: ${(props) => props.theme.color.white};
`;

const Content = styled.div`
  padding: ${(props) =>
    `${props.theme.margin[16]} ${props.theme.margin[16]} ${props.theme.margin[16]} ${props.theme.margin[32]}`};

  display: flex;
  flex-direction: column;

  flex: 1;

  background: ${(props) => props.theme.color.white};
`;
