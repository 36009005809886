import React, { useEffect } from 'react';

import Big from 'big.js';
import { OptionGroupData } from 'rc-select/lib/interface';
import styled, { css } from 'styled-components';

import { ActualCostLine as APIActualCostLine } from '../../../../store/reducers/actualCostLine';

import { APIOrderRow } from '../../../../types/api';
import { InvoiceLineSelectionPayload } from '../../../../types/general';

import useScrollTo from '../../../../hooks/useScrollTo';

import Checkbox from '../../../../components/Input/Checkbox';
import { SecondaryRow } from '../../../../components/Table';

import * as big from '../../../../utils/big';

import { CompactCell } from '../LineTablesCommon';
import HighlightOrderRowDropdown from '../OrderRowDropdown';

type Props = {
  projectId: string | null;
  orderId: string | null;
  actualCostLine: APIActualCostLine;
  orderRows: APIOrderRow[];
  selectionState: InvoiceLineSelectionPayload | undefined;
  isAllRendered: boolean;
  isHighlighted: boolean;
  allowedUser: boolean;
  onChange: (
    actualCostsLineId: string,
    selection?: string | null
  ) => void | undefined;
  widthForDropdown: number;
  checked: boolean;
  onToggle: (actualCostLineId: string) => void | undefined;
  options: OptionGroupData[];
};

const ActualCostLine = ({
  actualCostLine,
  orderRows,
  selectionState,
  isAllRendered,
  isHighlighted,
  onChange,
  allowedUser,
  widthForDropdown,
  checked,
  onToggle,
  orderId,
  projectId,
}: Props) => {
  const [scrollToRef, setShouldScrollTo] = useScrollTo<HTMLTableRowElement>();

  useEffect(() => {
    if (isAllRendered && isHighlighted) {
      setShouldScrollTo(true);
    }
  }, [setShouldScrollTo, isAllRendered, isHighlighted]);

  const lineItemInfo = {
    orderRowId: actualCostLine.id,
    netPrice: actualCostLine.unitPrice ?? new Big('0'),
    unit: actualCostLine.unit,
  };

  return (
    <ActualCostLineWithHighlight
      data-testid={`actual-cost-line-selector-${actualCostLine.id}`}
      highlighted={isHighlighted}
      ref={scrollToRef}
    >
      <CompactCell align="center" paddingDisabled>
        {actualCostLine.arrivalRowId === null ? (
          <Checkbox
            checked={checked}
            onChange={() => onToggle(actualCostLine.id)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                e.stopPropagation();
                e.preventDefault();
                onToggle(actualCostLine.id);
              }
            }}
            variant="secondary"
          />
        ) : null}
      </CompactCell>
      <CompactCell>{actualCostLine.description ?? ''}</CompactCell>
      <CompactCell align="right">
        {big.amountFormat(actualCostLine.quantity)}
      </CompactCell>
      <CompactCell>{actualCostLine.unit}</CompactCell>
      <CompactCell align="right">
        {big.priceFormat(actualCostLine.unitPrice)}
      </CompactCell>
      <CompactCell align="right">
        {big.priceFormat(actualCostLine.quantity.mul(actualCostLine.unitPrice))}
      </CompactCell>
      <CompactCell overflowNotHidden>
        <HighlightOrderRowDropdown
          lineItemInfo={lineItemInfo}
          allowedUser={allowedUser}
          orderRows={orderRows}
          selectionState={selectionState}
          widthForDropdown={widthForDropdown}
          onChange={(selectedOrderRowOrTopicId) => {
            // Add actualCostLineId
            const selection =
              selectedOrderRowOrTopicId === 'NONE'
                ? null
                : selectedOrderRowOrTopicId;
            onChange(actualCostLine.id, selection);
          }}
          alreadyAssigned={actualCostLine.arrivalRowId !== null}
          projectId={projectId}
          orderId={orderId}
        />
      </CompactCell>
    </ActualCostLineWithHighlight>
  );
};

export default ActualCostLine;

type ActualCostLineWithHighlightProps = {
  highlighted: boolean;
};

const ActualCostLineWithHighlight = styled(
  SecondaryRow
)<ActualCostLineWithHighlightProps>`
  @keyframes flash-animation {
    from {
      background: ${(props) => props.theme.color.sidebarBackground};
      opacity: 0.3;
    }
    to {
      background: default;
    }
  }

  ${({ highlighted }) =>
    highlighted
      ? css`
          animation: flash-animation linear 1.5s 1;
        `
      : css``}
`;
