import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { AppState } from '../../store/reducers';
import { ProjectState } from '../../store/reducers/project';

import { ID } from '../../types/general';

import useTxt from '../../hooks/useTxt';

import { ViewContainer } from '../../components/Containers';
import { GlobalLoadingSpinner } from '../../components/Loading';

import { generateUrl, routes } from '../../routes';

type Props = {
  activeProject?: ID;
  projects: ProjectState;
};

// Currently homepage acts as a redirect to last open project.
const HomePageView = ({ activeProject, projects }: Props) => {
  const failureText = useTxt('meta.failure');
  const contactText = useTxt('meta.pleaseContact');
  const noProjectsText = useTxt('meta.noProjects');

  const requestStateKind = projects.allProjectsRequest.kind;
  const projectsData = projects.data;

  // If we have an active project, verify we have access to it.
  if (activeProject) {
    if (requestStateKind === 'Success' && activeProject in projectsData) {
      return (
        <Redirect
          to={generateUrl({ route: routes.PROJECT, projectId: activeProject })}
        />
      );
    }
  }

  // If we do not have an active project or do not have access rights,
  // pick the first project in the list.
  if (requestStateKind === 'Success') {
    const projectIds = Object.keys(projectsData);

    if (projectIds.length > 0) {
      return (
        <Redirect
          to={generateUrl({ route: routes.PROJECT, projectId: projectIds[0] })}
        />
      );
    }
  }

  if (requestStateKind === 'Loading') {
    return <GlobalLoadingSpinner />;
  }

  if (requestStateKind === 'Failure' || requestStateKind === 'NotAsked') {
    return (
      <ViewContainer>
        <h1>{failureText}</h1>
        <p>{contactText}</p>
      </ViewContainer>
    );
  }

  // Otherwise, display an error.
  return (
    <ViewContainer>
      <h1>{noProjectsText}</h1>
      <p>{contactText}</p>
    </ViewContainer>
  );
};

const mapStateToProps = (state: AppState): Props => ({
  activeProject: state.ui.activeProject,
  projects: state.projects,
});

export default connect(mapStateToProps)(HomePageView);
