import React from 'react';

import { css } from 'styled-components';

import theme from './theme';

export const additionalStyle: React.CSSProperties = {
  height: theme.margin[36],
  margin: `${theme.margin[4]} 0 ${theme.margin[4]} ${theme.margin[8]}`,
};

export const fontSizeCss = (depth: number | undefined) => {
  switch (depth) {
    case 1:
      return css`
        height: 2.5rem;
        font-size: 1.125rem;
      `;
    case 2:
      return css`
        height: 2.5rem;
        font-size: 1rem;
      `;
    case 3:
      return css`
        height: 2.5rem;
        font-size: 0.875rem;
      `;
    case 4:
      return css`
        height: 2rem;
        font-size: 0.75rem;
      `;
    default:
      return css`
        height: 1.5rem;
        font-size: 0.625rem;
      `;
  }
};

export const paddingLeftCss = (depth: number | undefined) => {
  if (depth && depth > 0 && depth < 6) {
    return css`
      margin-left: ${(depth - 1) * 0.5}rem;
    `;
  }

  return css`
    margin-left: 2.5rem;
  `;
};

export const backgroundCSS = ({
  isPrimary,
  isHighlighted,
  isDisabled,
}: {
  isPrimary: boolean;
  isHighlighted?: boolean;
  isDisabled?: boolean;
}) => {
  if (isPrimary && isHighlighted) {
    return css`
      background: ${theme.color['M3/ref/primary/primary90']};
    `;
  }

  if (isHighlighted && isDisabled) {
    return css`
      background: ${theme.color['M3/sys/dark/secondary']};
    `;
  }

  if (isHighlighted) {
    return css`
      background: ${theme.color['M3/ref/primary/primary95']};
    `;
  }

  if (isDisabled) {
    return css`
      background: ${theme.color.graphiteB76};
    `;
  }

  if (!isPrimary) {
    return css`
      background: ${theme.color.graphiteB98};
    `;
  }
};

export const stickyCss = (depth: number | undefined) => {
  const heights = [40, 80, 120, 152, 176];

  if (!depth || depth < 1) {
    return;
  }

  const topPosition = depth < 6 ? heights[depth - 1] : 176 + (depth - 5) * 24;

  return css`
    position: sticky;
    top: ${topPosition}px;
    z-index: 1;
  `;
};
