import React from 'react';

import styled, { css } from 'styled-components';

import { IconRightWhite } from '../assets';

import { BoldedPrimaryRow } from './Table';

export const BaseButton = styled.button`
  margin: 0;

  border: 0;

  padding: 0;

  background: none;

  text-align: left;
  color: inherit;
  user-select: none;
  line-height: 1;

  cursor: pointer;

  :disabled {
    cursor: not-allowed;
  }
`;

export const PaginationButton = styled(BaseButton)`
  border: 1px solid ${(props) => props.theme.color.graphiteB86};
  border-radius: ${(props) => props.theme.margin[4]};

  padding: ${(props) => props.theme.margin[8]};

  min-width: ${(props) => props.theme.margin[40]};

  font-size: ${(props) => props.theme.fontSize.base};
  color: ${(props) => props.theme.color.graphiteB38};

  :hover:enabled {
    background: ${(props) => props.theme.color.graphiteB96A};
  }
`;

export const NewPackageButton = styled(BaseButton)`
  height: ${(props) => props.theme.margin[16]};

  display: flex;
  align-items: center;

  font-size: ${(props) => props.theme.fontSize.textButton};
  line-height: ${(props) => props.theme.fontSize.textButton};
  color: ${(props) => props.theme.color.pitch};

  /* stylelint-disable selector-max-type -- svgs inside the button are most easily
  styled with type selectors */
  /* stylelint-disable selector-max-class -- In case there are some other svgs
  inside the button, a classname was added to the selector */
  svg.button-icon {
    margin-right: ${(props) => props.theme.margin[8]};
    min-width: 16px;
  }

  svg.button-icon-right {
    margin-left: ${(props) => props.theme.margin[4]};
    min-width: 16px;
  }

  :disabled {
    color: ${(props) => props.theme.color.buttonPrimaryDisabled};
    cursor: initial;
  }

  :disabled svg.button-icon path {
    fill: ${(props) => props.theme.color.buttonPrimaryDisabled};
  }

  color: transparent;
  svg.button-icon path {
    fill: transparent;
  }

  ${BoldedPrimaryRow}:hover & {
    color: ${(props) => props.theme.color.pitch};
  }
  ${BoldedPrimaryRow}:hover & svg.button-icon path {
    fill: ${(props) => props.theme.color.pitch};
  }

  :focus {
    color: ${(props) => props.theme.color.pitch};
  }

  :focus svg.button-icon path {
    fill: ${(props) => props.theme.color.pitch};
  }

  :hover {
    /* stylelint-disable declaration-no-important -- We need it to override style */
    color: ${(props) => props.theme.color.indigo} !important;
  }
  :hover svg.button-icon path {
    fill: ${(props) => props.theme.color.indigo} !important;
  }
`;

export const BaseButtonWithUnderline = styled(BaseButton)`
  & {
    text-decoration: underline dashed;
  }
`;

export const PrimaryButton = styled(BaseButton)`
  /* Transparent border to match SecondaryButton */
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: ${(props) => props.theme.margin[24]};

  padding: 0.75rem ${(props) => props.theme.margin[24]};

  min-width: 6.5rem; /* 104px */
  height: ${(props) => props.theme.margin[40]};

  background: ${(props) => props.theme.color.pitch};

  text-align: center;
  font-size: ${(props) => props.theme.fontSize.button};
  color: ${(props) => props.theme.color.white};

  :hover {
    background: ${(props) => props.theme.color.graphiteB38};
  }

  :focus {
    outline-color: ${(props) => props.theme.color.inputBorder};
  }

  :disabled {
    background: ${(props) => props.theme.color.buttonPrimaryDisabled};
    cursor: not-allowed;
  }
`;

export const PrimaryButtonSmall = styled(BaseButton)`
  border-radius: ${(props) => props.theme.margin[24]};
  /* Transparent border to match SecondaryButton */
  border: 1px solid rgba(0, 0, 0, 0);

  padding: ${(props) => props.theme.margin[4]};

  height: 1.5rem;
  width: 12rem;
  min-width: 6.5rem; /* 104px */

  background: ${(props) => props.theme.color.pitch} url(${IconRightWhite})
    no-repeat right center;

  text-align: center;
  font-size: ${(props) => props.theme.fontSize.small};
  font-weight: 500;
  color: ${(props) => props.theme.color.white};

  :focus {
    outline-color: ${(props) => props.theme.color.inputBorder};
  }

  :disabled {
    background: ${(props) => props.theme.color.buttonPrimaryDisabled};
    cursor: not-allowed;
  }
`;

export const SecondaryButton = styled(BaseButton)`
  border-radius: ${(props) => props.theme.margin[24]};
  border: 1px solid ${(props) => props.theme.color.graphiteB91};

  padding: 0.75rem ${(props) => props.theme.margin[24]};

  min-width: 7.5rem; /* 120px */
  height: ${(props) => props.theme.margin[40]};

  background: ${(props) => props.theme.color.graphiteB96A};

  text-align: center;
  font-size: ${(props) => props.theme.fontSize.button};

  :hover {
    background: ${(props) => props.theme.color.graphiteB97};
  }

  :disabled {
    background: ${(props) => props.theme.color.graphiteB96A};
    color: ${(props) => props.theme.color.graphiteB76};
    cursor: not-allowed;
  }
`;

type StyledIconTextButtonProps = {
  visibleOnlyOnHover?: boolean;
};

// Made for 16px icons, if you want to use this with 24px icons, maybe add a
// prop and handle them?
const StyledIconTextButton = styled(BaseButton)<StyledIconTextButtonProps>`
  height: ${(props) => props.theme.margin[16]};

  display: flex;
  align-items: center;

  font-size: ${(props) => props.theme.fontSize.textButton};
  line-height: ${(props) => props.theme.fontSize.textButton};
  color: ${(props) => props.theme.color.pitch};

  svg.button-icon {
    margin-right: ${(props) => props.theme.margin[8]};
    min-width: 16px;
  }

  svg.button-icon-right {
    margin-left: ${(props) => props.theme.margin[4]};
    min-width: 16px;
  }

  :disabled {
    color: ${(props) => props.theme.color.buttonPrimaryDisabled};
    cursor: initial;
  }

  :disabled svg.button-icon path {
    fill: ${(props) => props.theme.color.buttonPrimaryDisabled};
  }

  ${({ visibleOnlyOnHover, theme }) =>
    visibleOnlyOnHover
      ? css`
          color: transparent;
          svg.button-icon path {
            fill: transparent;
          }

          :hover,
          :focus,
          :focus-within {
            color: ${theme.color.pitch};
          }
          :hover svg.button-icon path,
          :focus svg.button-icon path,
          :focus-within svg.button-icon path {
            fill: ${theme.color.pitch};
          }
        `
      : ''}
`;

export type SvgComponent = React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined;
  }
>;

export type IconTextButtonProps = {
  icon: SvgComponent;
  visibleOnlyOnHover?: boolean;
};

export const IconTextButton: React.FC<
  IconTextButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({ icon: Icon, visibleOnlyOnHover, children, ...buttonProps }) => (
  <StyledIconTextButton
    visibleOnlyOnHover={visibleOnlyOnHover}
    {...buttonProps}
  >
    <Icon className="button-icon" />
    {children}
  </StyledIconTextButton>
);

export const TextAndIconButton: React.FC<
  IconTextButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({ icon: Icon, visibleOnlyOnHover, children, ...buttonProps }) => (
  <StyledIconTextButton
    visibleOnlyOnHover={visibleOnlyOnHover}
    {...buttonProps}
  >
    {children}
    <Icon className="button-icon-right" />
  </StyledIconTextButton>
);

export const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  button ~ button {
    margin-left: ${(props) => props.theme.margin[24]};
  }
`;

export const CenteredButtonGroup = styled(ButtonGroup)`
  justify-content: center;
`;

type ReceiveAllButtonProps = {
  active: boolean;
};

export const ReceiveAllButton = styled(BaseButton)<ReceiveAllButtonProps>`
  border-radius: 0.75rem;

  padding: 0;

  width: ${(props) => props.theme.margin[48]};

  font-size: 0.5rem;
  text-align: center;
  line-height: 1rem;
  font-weight: 500;

  ${(props) =>
    props.active
      ? css`
          border: 1px solid ${props.theme.color.pitch};
          background: ${props.theme.color.pitch};
          color: ${props.theme.color.white};
        `
      : css`
          border: 1px solid ${({ theme }) => theme.color.graphiteB86};
          background: ${props.theme.color.white};
          color: ${props.theme.color.pitch};
        `}
  :disabled {
    color: ${(props) => props.theme.color.buttonPrimaryDisabled};
    cursor: initial;
  }
`;

export type StyledIconButtonProps = {
  large?: boolean;
  customSize?: string;
};

export const StyledIconButton = styled(BaseButton)<StyledIconButtonProps>`
  align-items: center;
  color: ${(props) => props.theme.color.pitch};
  ${({ theme, large, customSize }) =>
    large
      ? css`
          height: ${customSize ?? theme.sizes.iconButtonLarge};
          width: ${customSize ?? theme.sizes.iconButtonLarge};
        `
      : css`
          height: ${customSize ?? theme.sizes.iconButtonSmall};
          width: ${customSize ?? theme.sizes.iconButtonSmall};
        `}

  svg.button-icon {
    ${({ theme, large, customSize }) =>
      large
        ? css`
            height: ${customSize ?? theme.sizes.iconButtonLarge};
            width: ${customSize ?? theme.sizes.iconButtonLarge};
          `
        : css`
            height: ${customSize ?? theme.sizes.iconButtonSmall};
            width: ${customSize ?? theme.sizes.iconButtonSmall};
          `}
  }

  :disabled {
    color: ${(props) => props.theme.color.buttonPrimaryDisabled};
    cursor: initial;
  }

  :disabled svg.button-icon path {
    fill: ${(props) => props.theme.color.buttonPrimaryDisabled};
  }
`;

export type IconButtonProps = {
  icon: SvgComponent;
} & StyledIconButtonProps;

export const IconButton = React.forwardRef(
  (
    {
      icon: Icon,
      children,
      ...buttonProps
    }: IconButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>,
    ref: React.ForwardedRef<HTMLButtonElement>
  ) => (
    <StyledIconButton {...buttonProps} ref={ref}>
      <Icon className="button-icon" />
      {children}
    </StyledIconButton>
  )
);

type ToggleButtonProps = {
  side: 'left' | 'right';
  selected: boolean;
};

export const ToggleButton = styled(IconButton)<ToggleButtonProps>`
  border-radius: ${(props) =>
    props.side === 'left' ? '1.5rem 0 0 1.5rem' : '0 1.5rem 1.5rem 0'};
  border: 1px ${({ theme }) => theme.color.rowBorderActive} solid;

  min-width: 300px;
  max-width: 300px;
  height: ${(props) => props.theme.margin[40]};

  display: flex;
  align-items: center;
  justify-content: center;

  flex-grow: 1;

  background: ${(props) =>
    props.selected
      ? props.theme.color['M3/sys/light/secondary-container']
      : props.theme.color.white};

  font-size: ${(props) => props.theme.fontSize.textButton};
  font-weight: 700;
  color: ${(props) => props.theme.color['M3/sys/light/on-secondary-container']};

  :hover {
    background: ${(props) => props.theme.color.graphiteB38};
  }
`;
