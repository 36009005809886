import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
/* stylelint-disable selector-max-type -- Resetting global styles with type selectors in
one place is okay, no need to refctor away */
  html {
    height: 100%;
    font-size: 1em; /* 16px by default */
  }

  body {
    height: 100%;

    background: ${(props) => props.theme.color.white};

    color: ${(props) => props.theme.color.pitch};
    font-size: ${(props) => props.theme.fontSize.base};
    font-family: 'Graphik', Arial, Helvetica, sans-serif;

  }

  #root {
    height: 100%;
  }

  * {
    box-sizing: border-box;
  }

  h1, h2, h3 {
    margin: 0;
  }

  h1 {
    font-family: 'BuildingSans', sans-serif;
    font-weight: normal;
    font-size: ${(props) => props.theme.fontSize.h1};
    line-height: ${(props) => props.theme.fontSize.h1};
  }

  h2 {
    font-family: 'BuildingSans', sans-serif;
    font-weight: normal;
    font-size: ${(props) => props.theme.fontSize.h2};
    line-height: ${(props) => props.theme.fontSize.h2};
  }

  h3 {
    font-family: 'Graphik', sans-serif;
    font-size: ${(props) => props.theme.fontSize.h3};
    line-height: ${(props) => props.theme.fontSize.h3};
  }

  /* Reset annoying table styles */
  table,
  thead,
  tbody,
  tfoot,
  tr,
  th,
  td {
    margin: 0;

    border: none;
    border-color: inherit;

    padding: 0;

    text-align: left;
    font-weight: inherit;

    border-spacing: 0;
    border-collapse: inherit;
  }

  /* Stylings for components that cannot be styled elsewhere: */

  /* React-select list styles: */
  /* stylelint-disable selector-max-class -- Third party libraries need to be styled by classnames */
  .rc-virtual-list,
  .rc-select-item-empty {
    position: relative;

    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);

    padding: 0.5rem;

    min-width: 100%;

    background: white;

    z-index: 60;
  }
  .rc-select-item {
    padding: 0 0.5rem;
    display: flex;
    line-height: 1.5rem;
    cursor: pointer;
  }
  .rc-select-item-group {
    font-weight: bold;
  }
  .rc-select-item-option-active {
    background-color: ${({ theme }) => theme.color.graphiteB76};
  }
  .rc-select-dropdown-hidden {
    display: none;
  }

  /* stylelint-disable selector-pseudo-class-allowed-list -- this only is for the react-medium-image-zoom*/
  /* stylelint-disable selector-pseudo-element-allowed-list -- this only is for the react-medium-image-zoom*/
  [data-rmiz-ghost] {
  position: absolute;
  pointer-events: none;
  }
  [data-rmiz-btn-zoom],
  [data-rmiz-btn-unzoom] {
    margin: 0;
  
    border-radius: 50%;
    border: none;
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    outline-offset: 2px;

    padding: 9px;

    height: 40px;
    width: 40px;

    background-color: rgba(0, 0, 0, 0.7);

    color: white;

    touch-action: manipulation;
    appearance: none;
  }
  
  [data-rmiz-btn-zoom]:not(:focus):not(:active) {
    position: absolute;

    height: 1px;
    width: 1px;

    white-space: nowrap;

    overflow: hidden;
    pointer-events: none;

    clip: rect(0 0 0 0);
    clip-path: inset(50%);
  }
  [data-rmiz-btn-zoom] {
    position: absolute;
    cursor: zoom-in;
    inset: 10px 10px auto auto;
  }
  [data-rmiz-btn-unzoom] {
    position: absolute;
    cursor: zoom-out;
    inset: 20px 20px auto auto;
    z-index: 1;
  }
  [data-rmiz-content="found"] img,
  [data-rmiz-content="found"] svg,
  [data-rmiz-content="found"] [role="img"],
  [data-rmiz-content="found"] [data-zoom] {
    cursor: zoom-in;
  }
  [data-rmiz-modal]::backdrop {
    display: none;
  }
  [data-rmiz-modal][open] {
    position: fixed;

    margin: 0;

    border: 0;

    padding: 0;

    width: 100vw;
    height: 100vh;
    max-width: none;
    max-height: none;

    background: transparent;

    overflow: hidden;
  }
  [data-rmiz-modal-overlay] {
    position: absolute;
    transition: background-color 0.3s;
    inset: 0;
  }
  [data-rmiz-modal-overlay="hidden"] {
    background-color: rgba(255, 255, 255, 0);
  }
  [data-rmiz-modal-overlay="visible"] {
    background-color: rgba(255, 255, 255, 1);
  }
  [data-rmiz-modal-content] {
    position: relative;
    width: 100%;
    height: 100%;
  }
  [data-rmiz-modal-img] {
    position: absolute;

    cursor: zoom-out;

    transition: transform 0.3s;
  
    image-rendering: high-quality;

    transform-origin: top left;
  }
`;

export default GlobalStyle;
