import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Big from 'big.js';
import styled from 'styled-components';
import * as uuid from 'uuid';

import { ActualCost } from '../../../../store/reducers/actualCost';
import {
  getConvertRequestState,
  ActualCostLine as APIActualCostLine,
} from '../../../../store/reducers/actualCostLine';

import * as action from '../../../../store/actions';

import { APIOrderRow, APIWorkPackage } from '../../../../types/api';
import { SelectedInvoiceLinesState } from '../../../../types/general';

import { PrimaryButton, SecondaryButton } from '../../../../components/Buttons';
import Modal, {
  Header,
  Content,
  Footer,
} from '../../../../components/Modal/Modal';
import Txt from '../../../../components/Txt';

import * as big from '../../../../utils/big';
import {
  isDefined,
  isNotNull,
  ACTUAL_COST_STATUS_NOT_HANDLED,
} from '../../../../utils/general';
import groupBy from '../../../../utils/groupBy';

import { ActualCostLinesConvertTable } from './Table';
import { Topic, newTopicId } from '../OrderRowDropdown/Options';

type ConvertModalProps = {
  selectionState: SelectedInvoiceLinesState;
  actualCostLines: APIActualCostLine[];
  selectedTopics: Topic[];
  orderRows: APIOrderRow[];
  actualCost: ActualCost;
  workPackages: APIWorkPackage[];
  onClose: () => void;
  onConvertSuccess: () => void;
};

export type NewOrderRow = {
  topicId: string;
  actualCostLine: APIActualCostLine;
};

export type ExistingOrderRow = {
  orderRow: APIOrderRow;
  actualCostLines: APIActualCostLine[];
};

export type ExtendedExistingOrderRow = ExistingOrderRow & {
  orderRowAmount: Big;
  actualCostLinesTotalAmount: Big;
  newArrivalQuantity: Big;
  excessQuantity: Big;
};

const selectionValidation = (
  existingOrderRows: ExistingOrderRow[],
  newOrderRows: NewOrderRow[],
  actualCost: ActualCost
) => {
  if (existingOrderRows.length === 0 && newOrderRows.length === 0) {
    return {
      valid: false,
      reason: 'order.invoiceLines.convertModal.button.noRows',
    } as const;
  }

  if (!ACTUAL_COST_STATUS_NOT_HANDLED.includes(actualCost.statusId)) {
    return {
      valid: false,
      reason: 'order.invoiceLines.convertModal.button.invalidInvoiceStatus',
    } as const;
  }

  if (newOrderRows.some(({ topicId }) => topicId === 'NONE')) {
    return {
      valid: false,
      reason: 'order.invoiceLines.convertModal.invalidData',
    } as const;
  }

  const somePriceIsInvalid = existingOrderRows.some(
    ({ orderRow: { unitPrice } }) =>
      unitPrice === null || unitPrice.toNumber() === 0
  );

  if (somePriceIsInvalid) {
    return {
      valid: false,
      reason: 'order.invoiceLines.convertModal.button.invalidPrice',
    } as const;
  }

  return { valid: true } as const;
};

export const ConvertModal = ({
  selectionState,
  actualCostLines,
  selectedTopics,
  orderRows,
  actualCost,
  workPackages,
  onClose,
  onConvertSuccess,
}: ConvertModalProps) => {
  const dispatch = useDispatch();
  const [requestId] = React.useState(uuid.v4());

  const reGroupedSelections = Object.entries(selectionState)
    .map(([actualCostLineId, selections]) => {
      if (selections) {
        return {
          actualCostLineId,
          newOrderRowTopicId: selections.linkedTopicId,
          orderRowId: selections.linkedOrderRowId,
          workPackageId: selections.workPackageId,
        };
      }

      return undefined;
    })
    .filter(isDefined);

  const newOrderRows = reGroupedSelections
    .filter((row) => isNotNull(row.newOrderRowTopicId) || row.workPackageId)
    .map((selection) => {
      const actualCostLine = actualCostLines.find(
        (line) => line.id === selection.actualCostLineId
      );

      if (actualCostLine) {
        const newTopicTemporaryId = selection.workPackageId
          ? `${newTopicId}${selection.workPackageId}`
          : 'NONE';

        return {
          topicId: selection.newOrderRowTopicId ?? newTopicTemporaryId,
          actualCostLine,
        };
      }

      return undefined;
    })
    .filter(isDefined);

  const existingOrderRowSelections = reGroupedSelections
    .filter((row) => isNotNull(row.orderRowId))
    .map((selection) => {
      return {
        orderRowId: selection.orderRowId as string,
        actualCostLineId: selection.actualCostLineId,
      };
    });

  const groupedExistingOrderRowSelections = Object.entries(
    groupBy('orderRowId', existingOrderRowSelections)
  )
    .map(([orderRowId, selections]) => {
      const orderRow = orderRows.find((row) => row.id === orderRowId);

      const actualCostLineIds = selections.map(
        (selection) => selection.actualCostLineId
      );

      const filteredActualCostLines = actualCostLines.filter((line) =>
        actualCostLineIds.includes(line.id)
      );

      if (orderRow && selections) {
        return {
          orderRow,
          actualCostLines: filteredActualCostLines,
        };
      }

      return undefined;
    })
    .filter(isDefined);

  const selectionsValid = selectionValidation(
    groupedExistingOrderRowSelections,
    newOrderRows,
    actualCost
  ).valid;

  const errorMessage = selectionValidation(
    groupedExistingOrderRowSelections,
    newOrderRows,
    actualCost
  ).reason;

  const dataToBeSent = reGroupedSelections.map((selection) => {
    return {
      actualCostsDetailLineId: selection.actualCostLineId,
      topicId: selection.newOrderRowTopicId,
      linkedOrderRowId: selection.orderRowId,
      workPackageId: selection.workPackageId,
    };
  });

  const sendRequest = () => {
    if (!selectionsValid) {
      return;
    }

    dispatch(
      action.convertActualCostLines(actualCost.id, requestId, {
        autoUpdateOrderRowQuantities: true,
        data: dataToBeSent,
      })
    );
  };

  const requestState = useSelector(getConvertRequestState(requestId));

  React.useEffect(() => {
    if (requestState === 'Success') {
      onConvertSuccess();
      onClose();
    }
  }, [requestState, onClose, onConvertSuccess]);

  const isActionButtonDisabled = !selectionsValid || requestState === 'Loading';

  return (
    <Modal onClose={onClose}>
      <FormContainer>
        <StyledHeader>
          <Txt
            id="order.actualCostLines.convertModal.title"
            values={{
              entryNo: actualCost.entryNo,
              amount: big.priceFormat(actualCost.amount || new Big(0)),
            }}
          />
        </StyledHeader>
        <StyledContent>
          <ActualCostLinesConvertTable
            topicsToDisplay={selectedTopics}
            newOrderRows={newOrderRows}
            existingOrderRows={groupedExistingOrderRowSelections}
            workPackages={workPackages}
            actualCost={actualCost}
          />
          {errorMessage ? (
            <ErrorMessage>
              <Txt id={errorMessage} />
            </ErrorMessage>
          ) : null}
        </StyledContent>
        <Footer>
          <ButtonGroup>
            <CancelButton onClick={onClose}>
              <Txt id="common.cancel" />
            </CancelButton>
            <ActionButton
              onClick={sendRequest}
              disabled={isActionButtonDisabled}
            >
              <Txt id="order.actualCostLines.convertModal.button.action" />
            </ActionButton>
          </ButtonGroup>
        </Footer>
      </FormContainer>
    </Modal>
  );
};

const ErrorMessage = styled.div`
  padding: ${(props) =>
    `${props.theme.margin[4]} 0 0 ${props.theme.margin[16]}`};
  display: flex;
  justify-content: end;
  color: ${(props) => props.theme.color.red};
`;

const StyledHeader = styled(Header)`
  padding: ${({ theme }) => `${theme.margin[16]}`};

  height: 3rem;

  display: flex;
  justify-content: space-between;
  align-items: center;

  align-self: stretch;
`;

const FormContainer = styled.div`
  width: 80vw;
  display: flex;
  flex-direction: column;
`;

const StyledContent = styled(Content)`
  padding: ${({ theme }) => `${theme.margin[32]}`};
  min-height: 16rem;
  max-height: none;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-around;
`;

const CancelButton = styled(SecondaryButton)`
  margin: 0 ${({ theme }) => `${theme.margin[8]}`};
`;

const ActionButton = styled(PrimaryButton)`
  margin: 0 ${({ theme }) => `${theme.margin[8]}`};
`;
