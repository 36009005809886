import { Reducer } from 'redux';

import { APITask } from '../../types/api';

import {
  assertActionPayloadIsNotApiUpdatedEntities,
  isUpdatedEntitiesActionType,
} from './utils';
import { flow } from '../../utils/function';
import { isDefined } from '../../utils/general';
import normalizeBy from '../../utils/normalizeBy';
import * as remoteData from '../../utils/remoteData';

import { ActionTypes } from '../actionTypes';

import { AppState } from '.';

export type TaskState = {
  requestedByOrderId: Record<string, remoteData.RemoteData>;
  data: Partial<Record<string, APITask>>;
};

const initialState: TaskState = {
  requestedByOrderId: {},
  data: {},
};

const tasksReducer: Reducer<TaskState, ActionTypes> = (
  state = initialState,
  action
): TaskState => {
  switch (action.type) {
    case 'GET_TASKS_BY_ORDER_ID_STARTED': {
      const { orderId } = action.payload;

      return {
        ...state,
        requestedByOrderId: {
          ...state.requestedByOrderId,
          [orderId]: remoteData.loading,
        },
      };
    }
    case 'GET_TASKS_BY_ORDER_ID_FAILURE': {
      const { orderId, error } = action.payload;

      return {
        ...state,
        requestedByOrderId: {
          ...state.requestedByOrderId,
          [orderId]: remoteData.fail(error),
        },
      };
    }
    case 'GET_TASKS_BY_ORDER_ID_SUCCESS': {
      const { orderId, tasks } = action.payload;

      return {
        ...state,
        requestedByOrderId: {
          ...state.requestedByOrderId,
          [orderId]: remoteData.succeed(undefined),
        },
        data: {
          ...state.data,
          ...normalizeBy('id', tasks),
        },
      };
    }
  }

  if (isUpdatedEntitiesActionType(action)) {
    const { tasks: updatedTasks } = action.payload;

    if (!updatedTasks) {
      return state;
    }

    const data = { ...state.data };
    updatedTasks.forEach((task) => {
      const { isDeleted, id } = task;

      if (isDeleted) {
        delete data[id];
      } else {
        data[id] = task;
      }
    });

    return {
      ...state,
      data,
    };
  }

  assertActionPayloadIsNotApiUpdatedEntities(action);

  return state;
};

export const getRequestStateByOrderId =
  (orderId: string) =>
  ({
    tasks: {
      requestedByOrderId: { [orderId]: requestState = remoteData.notAsked },
    },
  }: AppState): remoteData.RemoteData =>
    requestState;

export const isFetchingByOrderId = (
  orderId: string
): ((appState: AppState) => boolean) =>
  flow(getTasksByOrderId(orderId), remoteData.isLoading);

// get tasks here
export const getTasks = ({ tasks: { data } }: AppState) =>
  Object.values(data).filter(isDefined);

export const isTasksForInvoiceHeader =
  (invoiceHeaderId: string) => (appState: AppState) => {
    const tasks = getTasks(appState) ?? [];

    return (
      tasks.filter((task) => task.purchaseInvoiceHeaderId === invoiceHeaderId)
        .length > 0
    );
  };

// get filtered tasks by orderId
export const getTasksByOrderId = (orderId: string) => (appState: AppState) => {
  const tasks = getTasks(appState);

  const requestState = getRequestStateByOrderId(orderId)(appState);

  return remoteData.map(requestState, (_) => tasks);
};

export default tasksReducer;
