import { parse } from 'csv-parse/browser/esm/sync';
import chardet from 'jschardet';

import { nullifyIfEmpty } from './format';
import { flow } from './function';
import { isNumberString } from './general';

const possibleDelimiters = [',', ';', '\t', '|'] as const;

function guessDelimiters(text: string) {
  return possibleDelimiters.filter(weedOut);

  function weedOut(delimiter: string) {
    let cache = -1;

    return text.split('\n').every(checkLength);

    function checkLength(line: string) {
      if (!line) {
        return true;
      }

      const { length } = line.split(delimiter);

      if (cache < 0) {
        cache = length;
      }

      return cache === length && length > 1;
    }
  }
}

function guessDecimalSeparator(
  text: string,
  delimiter: (typeof possibleDelimiters)[number]
) {
  const lines = text.split('\n');

  const cells = lines.map((line) => line.split(delimiter)).flat();

  const pointSeparatorRegex = /^[0-9,\s\u00A0]*\.[0-9$€]+$/;
  const commaSeparatorRegex = /^[0-9\s\u00A0]*,[0-9$€]+$/;

  const pointSeparatorMatches = cells.reduce((acc, cell) => {
    if (pointSeparatorRegex.test(cell)) {
      return acc + 1;
    }

    return acc;
  }, 0);

  const commaSeparatorMatches = cells.reduce((acc, cell) => {
    if (commaSeparatorRegex.test(cell)) {
      return acc + 1;
    }

    return acc;
  }, 0);

  if (pointSeparatorMatches > commaSeparatorMatches) {
    return '.';
  }

  return ',';
}

export function parseCsv(input: ArrayBuffer | string): any[] {
  const result = typeof input === 'string' ? input : Buffer.from(input);
  const { encoding } = chardet.detect(result);

  const verifiedEncoding = Buffer.isEncoding(encoding) ? encoding : 'utf8';

  const resultAsString = result.toString(verifiedEncoding);

  const delimiter = guessDelimiters(resultAsString)[0];

  const decimalSeparator = guessDecimalSeparator(resultAsString, delimiter);

  const castEmptyFieldsAsNull = (value: string) => {
    return nullifyIfEmpty(value);
  };

  const usePointAsDecimalSeparator = (value: string) => {
    let parsedValue = value;

    if (isNumberString(parsedValue)) {
      // remove whitespace
      parsedValue = parsedValue
        .replace(/&nbsp;/g, '')
        .replace(' ', '')
        .replace('€', '')
        .replace('$', '');

      if (delimiter !== ',') {
        if (decimalSeparator === ',') {
          parsedValue = parsedValue.replace(',', '.');
        } else {
          parsedValue = parsedValue.replace(',', '');
        }
      }
    }

    return parsedValue;
  };

  const records = parse(result, {
    bom: true,
    columns: true,
    cast: flow(usePointAsDecimalSeparator, castEmptyFieldsAsNull),
    skipEmptyLines: true,
    skipRecordsWithEmptyValues: true,
    encoding: verifiedEncoding,
    delimiter,
  });

  if (Array.isArray(records)) {
    return records;
  }

  return [];
}
