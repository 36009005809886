import React from 'react';
import { useSelector } from 'react-redux';

import styled from 'styled-components';

import {
  getOrderRowsByTopicId,
  toRenderableOrderRow,
} from '../../../store/reducers/orderRow';

import Cell from '../../../components/Cell';
import { SecondaryRow, Table, TableHeader } from '../../../components/Table';
import Txt from '../../../components/Txt';

import { Th } from './Th';
import StatusPill from '../../OrderView/components/StatusPill';

const OrderRowTableHeader = () => (
  <TableHeader>
    <tr>
      <PaddedTh width="6%">&nbsp;</PaddedTh>
      <PaddedTh width="3%">&nbsp;</PaddedTh>
      <PaddedTh width="30%">
        <Txt id="header.orderRow" />
      </PaddedTh>
      <PaddedTh width="5%" textAlign="right">
        <Txt id="order.receiveMode.arrivalRowTH.quantity" />
      </PaddedTh>
      <PaddedTh width="5%" textAlign="center">
        <Txt id="header.UOM" />
      </PaddedTh>
      <PaddedTh textAlign="right">
        <Txt id="order.receiveMode.arrivalRowTH.unitPrice" />
      </PaddedTh>
      <PaddedTh textAlign="right">
        <Txt id="order.invoiceLines.convertModal.table.footer.totalShortHand" />
      </PaddedTh>
      <PaddedTh textAlign="right">
        <Txt id="worksection.table.header.receivedTotal" />
      </PaddedTh>
      <RightPaddedTh width="9%" textAlign="center">
        <Txt id="worksection.orderRow.header.status" />
      </RightPaddedTh>
    </tr>
  </TableHeader>
);

const PaddedTh = styled(Th)`
  padding-right: ${({ theme }) => theme.margin[24]};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: normal;
  font-style: italic;
`;

const RightPaddedTh = styled(PaddedTh)`
  padding-right: ${({ theme }) => theme.margin[64]};
`;

type OrderRowTableProps = {
  topicId: string;
};

export const OrderRowTable = ({ topicId }: OrderRowTableProps) => {
  const orderRowsByTopicId = useSelector(getOrderRowsByTopicId(topicId));

  return (
    <SecondaryRow>
      <PaddedCell colSpan={11}>
        <Table>
          <OrderRowTableHeader />
          <tbody>
            {orderRowsByTopicId
              .map((row) => toRenderableOrderRow(row))
              .map(
                ({
                  rowNumber,
                  description,
                  quantity,
                  unit,
                  unitPrice,
                  totalPrice,
                  arrivalTotal,
                  status,
                }) => (
                  <tr key={rowNumber}>
                    <Cell />
                    <OrderRowCell align="right">{rowNumber}</OrderRowCell>
                    <OrderRowCell>{description}</OrderRowCell>
                    <OrderRowCell align="right">{quantity}</OrderRowCell>
                    <OrderRowCell align="center">{unit}</OrderRowCell>
                    <OrderRowCell align="right">{unitPrice}</OrderRowCell>
                    <OrderRowCell align="right">{totalPrice}</OrderRowCell>
                    <OrderRowCell align="right">{arrivalTotal}</OrderRowCell>
                    <RightPaddedCell align="center">
                      <StatusPill status={status} />
                    </RightPaddedCell>
                  </tr>
                )
              )}
          </tbody>
        </Table>
      </PaddedCell>
    </SecondaryRow>
  );
};

const PaddedCell = styled.td`
  padding-top: ${({ theme }) => theme.margin[8]};
  padding-bottom: ${({ theme }) => theme.margin[8]};
  height: ${(props) => props.theme.margin[40]};
`;

const OrderRowCell = styled(Cell)`
  border-top: 1px solid ${({ theme }) => theme.color.mercury};
  padding-right: ${({ theme }) => theme.margin[24]};
`;

const RightPaddedCell = styled(OrderRowCell)`
  padding-right: ${({ theme }) => theme.margin[64]};
`;
