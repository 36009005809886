import { makeAction, ExtractActionTypes } from '../../../utils/actionCreators';

export type TargetRowSelectionAction = ExtractActionTypes<
  typeof actionCreators
>;

const actionCreators = {
  ...makeAction('toggleTargetRowSelection')<{
    orderId: string;
    targetRowId: string;
  }>(),
  ...makeAction('toggleMultipleTargetRowSelection')<{
    orderId: string;
    targetRowIds: string[];
    chooseAll: boolean;
  }>(),
};

export const { toggleTargetRowSelection, toggleMultipleTargetRowSelection } =
  actionCreators;
