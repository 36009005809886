import React from 'react';
import { useSelector } from 'react-redux';

import Big from 'big.js';
import { isEmpty } from 'lodash';
import { OptionsType } from 'rc-select/lib/interface';
import styled from 'styled-components';

import { APIOrder, APIOrderRow } from '@customtypes/api';

import {
  Dimension,
  getValidItemsForDimension,
} from '../../../../../../store/reducers/manualEntry';
import { getProjectOrdersSelector } from '../../../../../../store/reducers/order/order';
import { getOrderRows } from '../../../../../../store/reducers/orderRow';
import { getProjectById } from '../../../../../../store/reducers/project';
import { getOrderTopics } from '../../../../../../store/reducers/topic';
import { getActiveProjectId } from '../../../../../../store/reducers/ui';
import { getProjectWorkPackages } from '../../../../../../store/reducers/workPackage';

import {
  fetchOrderRowsForOrder,
  fetchOrdersForProject,
  fetchTopicsForOrder,
  fetchWorkPackagesForProject,
} from '../../../../../../store/actions';

import useRemoteData from '../../../../../../hooks/useRemoteData';
import useTxt from '../../../../../../hooks/useTxt';

import DropDownSelect from '../../../../../../components/DropDownSelect';
import { RowSelectLabel } from '../../../../../../components/Input/Select';
import ToolTip from '../../../../../../components/Tooltip';
import {
  createOptionsForTopics,
  Paragraph,
  DescriptionText,
  InfoSpanText,
} from '../../../../components/OrderRowDropdown/Options';

import { priceFormat } from '../../../../../../utils/big';
import { isDefined } from '../../../../../../utils/general';
import { Thunk } from '../../../../../../utils/thunk';

import { FormDimension } from './ManualEntryModal';
import theme from '../../../../../../styles/theme';

type CommonLabelAndSelectProps = {
  chooseText: string;
  htmlId: string;
  onChange: (e: string) => void;
  disabled: boolean;
};

type DimensionLabelAndSelectProps = {
  dimension: Dimension;
  formDimensions: FormDimension[];
  fetchData: Thunk;
  resetFieldValue: (htmlId: string) => void;
} & CommonLabelAndSelectProps;

export const DimensionLabelAndSelect = ({
  dimension,
  chooseText,
  fetchData,
  htmlId,
  onChange,
  disabled,
  formDimensions,
  resetFieldValue,
}: DimensionLabelAndSelectProps) => {
  const validItemsForDimension =
    useRemoteData(getValidItemsForDimension(dimension.id), fetchData) ?? [];

  const activeProjectId = useSelector(getActiveProjectId);

  const projectCode =
    useSelector(getProjectById(activeProjectId ?? ''))?.code ?? '';

  const isProjectDimensionTip = useTxt(
    'order.manualEntryModal.isProjectDimension.tooltip',
    { projectCode }
  );

  const formDim = formDimensions.find(({ id }) => htmlId.indexOf(id) >= 0);

  if (!isDefined(formDim)) {
    return null;
  }

  const hasValue = formDim.valueId !== '';

  const amongValidItems = !isEmpty(
    validItemsForDimension.find(({ id }) => formDim.valueId === id)
  );

  const invalid = !hasValue || !amongValidItems;

  const isProjectDimension = dimension.isProjectDimension ?? false;

  if (hasValue && !amongValidItems) {
    resetFieldValue(htmlId);
  }

  return (
    <LabelAndSelect
      htmlId={htmlId}
      labelName={dimension.name ?? ''}
      tooltip={isProjectDimension ? isProjectDimensionTip : undefined}
      mandatory={dimension.mandatory}
      options={[
        {
          key: '',
          value: '',
          label: chooseText,
          title: '',
          shortlabel: chooseText,
        },
        ...validItemsForDimension.map((item) => ({
          key: item.id,
          value: item.id,
          label: `${item.code} ${item.name}`,
          title: `${item.code} ${item.name}`,
          shortlabel: `${item.code} ${item.name}`,
        })),
      ]}
      invalid={invalid}
      onChange={onChange}
      disabled={disabled}
      selection={formDim.valueId ?? ''}
    />
  );
};

type CrossOrderLabelAndSelectProps = {
  entity: 'orderRow' | 'order';
  selectedOrderId: string | null;
  selection: string | null;
  resetFieldValue: () => void;
} & CommonLabelAndSelectProps;

function isOrder(entry: APIOrder | APIOrderRow): entry is APIOrder {
  const properties = Object.keys(entry);

  return properties.includes('visibleCode');
}

export const CrossOrderLabelAndSelect = ({
  entity,
  chooseText,
  htmlId,
  onChange,
  disabled,
  selectedOrderId,
  selection,
  resetFieldValue,
}: CrossOrderLabelAndSelectProps) => {
  const projectId = useSelector(getActiveProjectId) ?? '';

  const selector =
    entity === 'order'
      ? getProjectOrdersSelector(projectId)
      : getOrderRows({ orderId: selectedOrderId ?? '' });

  const fetchData =
    entity === 'order'
      ? fetchOrdersForProject(projectId)
      : fetchOrderRowsForOrder(selectedOrderId ?? '');

  const validItems =
    useRemoteData<(APIOrder | APIOrderRow)[], unknown>(
      selector,
      fetchData,
      !!selectedOrderId
    ) ?? [];

  const topics = (
    useRemoteData(
      getOrderTopics(selectedOrderId ?? ''),
      fetchTopicsForOrder(selectedOrderId ?? ''),
      !!selectedOrderId
    ) ?? []
  ).map((topic) => ({
    ...topic,
    allChecked: false,
    allDisabled: true,
  }));

  const projectWorkPackages =
    useRemoteData(
      getProjectWorkPackages(projectId),
      fetchWorkPackagesForProject(projectId)
    ) ?? [];

  const labelName = useTxt(
    entity === 'order' ? 'common.order' : 'common.orderRow'
  );

  const hasValue = !!selection;

  const amongValidItems = !isEmpty(
    validItems?.find((item) => selection && item.id === selection)
  );

  const invalid = !hasValue || !amongValidItems;

  React.useEffect(() => {
    if (hasValue && !amongValidItems) {
      resetFieldValue();
    }
  }, [amongValidItems, hasValue, resetFieldValue]);

  let options: OptionsType = [
    {
      key: '',
      value: '',
      label: chooseText,
      title: '',
      shortlabel: chooseText,
    },
  ];

  if (entity === 'order') {
    const orderOptions = validItems.filter(isOrder).map((item) => ({
      key: item.id,
      value: item.id,
      label: (
        <Paragraph width={540}>
          <DescriptionText>{`${item.visibleCode} ${item.name}`}</DescriptionText>
          {item.contractor !== null && item.contractor.length > 0 ? (
            <InfoSpanText>{item.contractor}</InfoSpanText>
          ) : null}
        </Paragraph>
      ),
      title: `${item.visibleCode} ${item.name}${
        item.contractor !== null && item.contractor.length > 0
          ? ` | ${item.contractor}`
          : ''
      }`,
      shortlabel: `${item.visibleCode}, ${item.name}${
        item.contractor !== null && item.contractor.length > 0
          ? ` | ${item.contractor}`
          : ''
      }`,
    }));

    options = options.concat(orderOptions);
  } else {
    const orderRows = validItems.filter(
      (item): item is APIOrderRow => !isOrder(item)
    );

    const orderRowWithTopicsOptions = createOptionsForTopics(
      topics,
      orderRows,
      projectWorkPackages,
      580,
      undefined,
      undefined,
      (orderRow) =>
        `${orderRow.rowNumber} ${orderRow.description} ${`${priceFormat(
          orderRow.unitPrice ?? new Big('0')
        )}/${orderRow.unit}`}`,
      (orderRow) => orderRow.id
    );

    options = options.concat(orderRowWithTopicsOptions);
  }

  return (
    <LabelAndSelect
      htmlId={htmlId}
      labelName={labelName}
      mandatory
      options={options}
      invalid={invalid}
      onChange={onChange}
      disabled={disabled}
      selection={selection}
    />
  );
};

type LabelAndSelectProps = {
  htmlId: string;
  labelName: string;
  tooltip?: string;
  mandatory: boolean;
  options: OptionsType;
  invalid: boolean;
  onChange: (e: string) => void;
  disabled: boolean;
  selection: string | null;
};

const LabelAndSelect = ({
  htmlId,
  labelName,
  tooltip,
  mandatory,
  options,
  invalid,
  onChange,
  disabled,
  selection,
}: LabelAndSelectProps) => {
  return (
    <div>
      <RowSelectLabel htmlFor={htmlId}>
        {tooltip ? (
          <ToolTip tip={tooltip}>
            <b>
              {labelName}
              {mandatory ? <RedSpan>{' *'}</RedSpan> : ''}
            </b>
          </ToolTip>
        ) : (
          <b>
            {labelName}
            {mandatory ? ' *' : ''}
          </b>
        )}

        <DropDownSelect
          required
          invalid={invalid}
          additionalStyles={additionalStyling}
          additionalContainerStyles={additionalContainerStyling}
          onChange={onChange}
          disabled={disabled}
          defaultValue=""
          id={htmlId}
          options={options}
          dropdownMatchSelectWidth={false}
          dropdownAlign={{ offset: [-200] }}
          dropdownStyle={{ minWidth: '150px', width: '600px' }}
          optionFilterProp="title"
          optionLabelProp="shortlabel"
          value={selection ?? ''}
        />
      </RowSelectLabel>
    </div>
  );
};

const additionalStyling: React.CSSProperties = {
  marginTop: theme.margin[8],
  marginBottom: theme.margin[8],
  borderRadius: theme.margin[4],
  background: theme.color.dropdownBg,
  fontSize: theme.fontSize.base,
  appearance: 'none',
  width: 400,
};

const additionalContainerStyling: React.CSSProperties = {
  padding: `${theme.margin[4]} ${theme.margin[4]} ${theme.margin[4]} ${theme.margin[16]}`,
  height: theme.margin[36],
  backgroundColor: 'transparent',
  margin: `${theme.margin[4]} 0 ${theme.margin[4]} ${theme.margin[16]}`,
};

const RedSpan = styled.span`
  color: red;
`;
