import React from 'react';

export const isClickOrKeyboardSelection = (
  e: React.KeyboardEvent | React.MouseEvent | React.SyntheticEvent
): boolean => {
  if (document.getSelection()?.type === 'Range') {
    return false;
  }

  return (
    e.type === 'click' ||
    (e as React.KeyboardEvent).code === 'Enter' ||
    (e as React.KeyboardEvent).code === 'Space' ||
    (e as React.KeyboardEvent).code === 'NumpadEnter'
  );
};
