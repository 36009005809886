import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';

import { RawAPIUpdatedEntities, APIUpdatedEntities } from '../../types/api';
import { mapRawUpdatedEntities } from '../../types/mappers';

import { makeAction, ExtractActionTypes } from '../../utils/actionCreators';
import {
  GET,
  POST,
  PUT,
  BackendError,
  DELETE,
  apiErrorHandlingWithDecode,
} from '../../utils/api';
import { dateString } from '../../utils/decoders';
import { flow } from '../../utils/function';
import * as remoteData from '../../utils/remoteData';
import { createAsyncThunk, Thunk } from '../../utils/thunk';

import {
  getPaymentProgramRowGroupDeleteRequest,
  getPaymentProgramRowGroupUpdateRequest,
  getPaymentProgramRowGroupPostRequest,
  getPaymentProgramRowGroups,
  PaymentProgramRowGroup,
  getMovePaymentProgramRowsRequest,
} from '../reducers/paymentProgramRowGroup';

export type PaymentProgramRowGroupAction = ExtractActionTypes<
  typeof actionCreators
>;

const actionCreators = {
  ...makeAction('getPaymentProgramRowGroupsStarted')<{
    projectId: string;
  }>(),
  ...makeAction('getPaymentProgramRowGroupsSuccess')<{
    projectId: string;
    paymentProgramRowGroups: PaymentProgramRowGroup[];
  }>(),
  ...makeAction('getPaymentProgramRowGroupsFailure')<{
    projectId: string;
    error: BackendError | undefined;
  }>(),

  ...makeAction('putPaymentProgramRowGroupStarted')<{
    paymentProgramRowGroupId: string;
  }>(),
  ...makeAction('putPaymentProgramRowGroupFailure')<{
    paymentProgramRowGroupId: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('putPaymentProgramRowGroupSuccess')<
    APIUpdatedEntities & { paymentProgramRowGroupId: string }
  >(),

  ...makeAction('movePaymentProgramRowsStarted')<{
    requestId: string;
  }>(),
  ...makeAction('movePaymentProgramRowsFailure')<{
    requestId: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('movePaymentProgramRowsSuccess')<
    APIUpdatedEntities & { requestId: string }
  >(),

  ...makeAction('postPaymentProgramRowGroupStarted')<{
    requestId: string;
  }>(),
  ...makeAction('postPaymentProgramRowGroupFailure')<{
    requestId: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('postPaymentProgramRowGroupSuccess')<
    APIUpdatedEntities & {
      requestId: string;
    }
  >(),

  ...makeAction('deletePaymentProgramRowGroupStarted')<{
    paymentProgramRowGroupId: string;
  }>(),
  ...makeAction('deletePaymentProgramRowGroupFailure')<{
    paymentProgramRowGroupId: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('deletePaymentProgramRowGroupSuccess')<
    APIUpdatedEntities & { paymentProgramRowGroupId: string }
  >(),
};

export const {
  getPaymentProgramRowGroupsStarted,
  getPaymentProgramRowGroupsFailure,
  getPaymentProgramRowGroupsSuccess,

  putPaymentProgramRowGroupStarted,
  putPaymentProgramRowGroupFailure,
  putPaymentProgramRowGroupSuccess,

  movePaymentProgramRowsStarted,
  movePaymentProgramRowsFailure,
  movePaymentProgramRowsSuccess,

  deletePaymentProgramRowGroupStarted,
  deletePaymentProgramRowGroupFailure,
  deletePaymentProgramRowGroupSuccess,

  postPaymentProgramRowGroupStarted,
  postPaymentProgramRowGroupSuccess,
  postPaymentProgramRowGroupFailure,
} = actionCreators;

const apiPaymentProgramRowGroupType = t.exact(
  t.type({
    id: t.string,
    code: t.string,
    projectId: t.string,
    description: t.union([t.string, t.null]),
    paymentBatchGroupId: t.union([t.string, t.null]),

    isDeletable: t.boolean,

    paymentProgramRowIds: t.array(t.string),

    isDeleted: t.boolean,
    updatedAt: dateString,
    createdAt: dateString,
  })
);

export async function toPaymentProgramRowGroups(
  u: unknown
): Promise<PaymentProgramRowGroup[]> {
  const apiPaymentProgramRowGroups = await tPromise.decode(
    t.array(apiPaymentProgramRowGroupType),
    u
  );

  return apiPaymentProgramRowGroups;
}

async function fetchPaymentProgramRowGroups(
  projectId: string
): Promise<PaymentProgramRowGroup[]> {
  const response = await GET(
    `v1/projects/${projectId}/payment-program-row-groups`
  );

  return toPaymentProgramRowGroups(response);
}

export const requestPaymentProgramRowGroups = (projectId: string) =>
  createAsyncThunk(fetchPaymentProgramRowGroups, {
    args: [projectId],
    isPending: flow(
      getPaymentProgramRowGroups(projectId),
      remoteData.isLoading
    ),
    initialAction: getPaymentProgramRowGroupsStarted({ projectId }),
    successActionCreator: (paymentProgramRowGroups) =>
      getPaymentProgramRowGroupsSuccess({
        projectId,
        paymentProgramRowGroups,
      }),
    failureActionCreator: (error) =>
      getPaymentProgramRowGroupsFailure({
        projectId,
        error: apiErrorHandlingWithDecode(error),
      }),
  });

interface BasePaymentProgramRowGroupParams {
  paymentBatchGroupId: string;
  description: string;
}

export interface PostPaymentProgramRowGroupParams
  extends BasePaymentProgramRowGroupParams {
  projectId: string;
  code: string;
}

export interface PutPaymentProgramRowGroupParams
  extends BasePaymentProgramRowGroupParams {
  updatedAt: Date;
}

async function updatePaymentProgramRowGroup(
  params: PutPaymentProgramRowGroupParams,
  groupId: string
): Promise<APIUpdatedEntities> {
  const response = await PUT<RawAPIUpdatedEntities>(
    `v1/payment-program-row-groups/${groupId}`,
    { ...params }
  );

  return mapRawUpdatedEntities(response);
}

export const requestPaymentProgramRowGroupUpdate =
  (
    updatedFields: PutPaymentProgramRowGroupParams,
    paymentProgramRowGroupId: string,
    forcedRequest?: boolean
  ): Thunk =>
  (dispatch) => {
    dispatch(
      createAsyncThunk(updatePaymentProgramRowGroup, {
        args: [updatedFields, paymentProgramRowGroupId],
        isPending: forcedRequest
          ? undefined
          : flow(
              getPaymentProgramRowGroupUpdateRequest(paymentProgramRowGroupId),
              remoteData.isLoading
            ),
        initialAction: putPaymentProgramRowGroupStarted({
          paymentProgramRowGroupId,
        }),
        successActionCreator: (updatedEntities) =>
          putPaymentProgramRowGroupSuccess({
            ...updatedEntities,
            paymentProgramRowGroupId,
          }),
        failureActionCreator: (error) =>
          putPaymentProgramRowGroupFailure({
            paymentProgramRowGroupId,
            error: apiErrorHandlingWithDecode(error),
          }),
      })
    );
  };

export interface MovePaymentProgramRowsToGroupParams {
  paymentProgramRowIds: string[];
}

async function movePaymentProgramRowsToGroup(
  params: MovePaymentProgramRowsToGroupParams,
  groupId: string
): Promise<APIUpdatedEntities> {
  const response = await PUT<RawAPIUpdatedEntities>(
    `v1/payment-program-row-groups/${groupId}/move-payment-program-rows`,
    { ...params }
  );

  return mapRawUpdatedEntities(response);
}

export const requestMovePaymentProgramRowsToGroup =
  (
    params: MovePaymentProgramRowsToGroupParams,
    paymentProgramRowGroupId: string,
    requestId: string
  ): Thunk =>
  (dispatch) => {
    dispatch(
      createAsyncThunk(movePaymentProgramRowsToGroup, {
        args: [params, paymentProgramRowGroupId],
        isPending: flow(
          getMovePaymentProgramRowsRequest(requestId),
          remoteData.isLoading
        ),
        initialAction: movePaymentProgramRowsStarted({
          requestId,
        }),
        successActionCreator: (updatedEntities) =>
          movePaymentProgramRowsSuccess({
            ...updatedEntities,
            requestId,
          }),
        failureActionCreator: (error) =>
          movePaymentProgramRowsFailure({
            requestId,
            error: apiErrorHandlingWithDecode(error),
          }),
      })
    );
  };

const postNewPaymentProgramRowGroup = async (
  params: PostPaymentProgramRowGroupParams
): Promise<APIUpdatedEntities> => {
  const response = await POST<RawAPIUpdatedEntities>(
    'v1/payment-program-row-groups',
    {
      ...params,
    }
  );

  return mapRawUpdatedEntities(response);
};

export const requestNewPaymentProgramRowGroup =
  (requestId: string, params: PostPaymentProgramRowGroupParams): Thunk =>
  (dispatch) => {
    dispatch(
      createAsyncThunk(postNewPaymentProgramRowGroup, {
        args: [params],
        isPending: flow(
          getPaymentProgramRowGroupPostRequest(requestId),
          remoteData.isLoading
        ),
        initialAction: postPaymentProgramRowGroupStarted({ requestId }),
        successActionCreator: (updatedEntities) =>
          postPaymentProgramRowGroupSuccess({
            ...updatedEntities,
            requestId,
          }),
        failureActionCreator: (error) =>
          postPaymentProgramRowGroupFailure({
            requestId,
            error: apiErrorHandlingWithDecode(error),
          }),
      })
    );
  };

const deletePaymentProgramRowGroup = async (
  paymentProgramRowGroupId: string
): Promise<APIUpdatedEntities> => {
  const response = await DELETE<RawAPIUpdatedEntities>(
    `v1/payment-program-row-groups/${paymentProgramRowGroupId}`
  );

  return mapRawUpdatedEntities(response);
};

export const requestDeletePaymentProgramRowGroup =
  (paymentProgramRowGroupId: string): Thunk =>
  (dispatch) => {
    dispatch(
      createAsyncThunk(deletePaymentProgramRowGroup, {
        args: [paymentProgramRowGroupId],
        isPending: flow(
          getPaymentProgramRowGroupDeleteRequest(paymentProgramRowGroupId),
          remoteData.isLoading
        ),
        initialAction: deletePaymentProgramRowGroupStarted({
          paymentProgramRowGroupId,
        }),
        successActionCreator: (updatedEntities) =>
          deletePaymentProgramRowGroupSuccess({
            ...updatedEntities,
            paymentProgramRowGroupId,
          }),
        failureActionCreator: (error) =>
          deletePaymentProgramRowGroupFailure({
            paymentProgramRowGroupId,
            error: apiErrorHandlingWithDecode(error),
          }),
      })
    );
  };
