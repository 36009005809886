import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import styled from 'styled-components';

import { APIProjectUser, APITask } from '@customtypes/api';

import { InvoiceHeader } from '../../../../../../store/reducers/invoiceHeader';

import * as Actions from '../../../../../../store/actions';

import useTxt from '../../../../../../hooks/useTxt';

import { BigPriceValue } from '../../../../../../components/BigValue';
import {
  SecondaryButton,
  PrimaryButton,
  ButtonGroup,
} from '../../../../../../components/Buttons';
import TextArea from '../../../../../../components/Input/TextArea';
import Modal from '../../../../../../components/Modal/Modal';

import ProjectUsers from './ProjectUsersList';
import {
  StyledHeader,
  StyledContent,
  Description,
} from '../InvoiceDelegationRow/AssigneeView';

type DelegateInvoiceModalProps = {
  tasks?: APITask[];
  invoiceHeader: InvoiceHeader;
  projectUsers: APIProjectUser[];
  onClose: () => void;
};

const DelegateInvoiceModal = ({
  invoiceHeader,
  onClose,
  tasks,
  projectUsers,
}: React.PropsWithChildren<DelegateInvoiceModalProps>) => {
  const [delegationDescription, setDelegationDescription] = useState('');
  const [assigneeUsers, setAssigneeUsers] = useState<string[]>([]);

  const dispatch = useDispatch();

  const modalTitleDelegate = useTxt(
    'order.receiveMode.invoice.delegate.modal.title'
  );

  const delegationDescriptionTitle = useTxt(
    'order.receiveMode.invoice.delegate.modal.description'
  );
  const invoice = useTxt('navigation.notifications.invoice');
  const cancelText = useTxt('common.cancel');
  const saveText = useTxt('common.send');

  const invoicePlaceholder = useTxt(
    'order.receiveMode.invoice.delegate.modal.placeholder'
  );

  const changeDescription = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDelegationDescription(e.target.value);
  };

  const onSubmitDelegation = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(
      Actions.createTask({
        assigneeUserIds: assigneeUsers,
        purchaseInvoiceHeaderId: invoiceHeader.id,
        description: delegationDescription,
        isDeleted: false,
      })
    );

    onClose();
  };

  return (
    <Modal onClose={onClose}>
      <FormContainer onSubmit={onSubmitDelegation}>
        <StyledHeader>{modalTitleDelegate}</StyledHeader>
        <StyledContent>
          <Description>
            {invoice} {invoiceHeader.vendorInvoiceNo}{' '}
            {invoiceHeader.supplierName}{' '}
            <BigPriceValue value={invoiceHeader.amount} decimals={0} />
          </Description>
          <ProjectUsers
            users={projectUsers}
            assigneeUserIds={assigneeUsers}
            setAssigneeUsers={setAssigneeUsers}
            assignedUserIds={
              tasks
                ?.filter(
                  (task) => task.purchaseInvoiceHeaderId === invoiceHeader.id
                )
                .map((t) => t.assigneeUserId) ?? []
            }
          />
          <Textcontainer>
            <h4>{delegationDescriptionTitle}</h4>
            <TextArea
              onChange={changeDescription}
              placeholder={invoicePlaceholder}
            />
          </Textcontainer>
          <CenteredButtonGroup>
            <SecondaryButton type="button" onClick={onClose}>
              {cancelText}
            </SecondaryButton>
            <PrimaryButton type="submit" disabled={!assigneeUsers.length}>
              {saveText}
            </PrimaryButton>
          </CenteredButtonGroup>
        </StyledContent>
      </FormContainer>
    </Modal>
  );
};

export default DelegateInvoiceModal;

const FormContainer = styled.form`
  width: 30.25rem;
  height: 35.125rem;
`;

const Textcontainer = styled.div`
  /* stylelint-disable selector-max-type -- TODO Should be refactored */
  h4 {
    margin: 0 0 0.5rem 0;
  }
`;

const CenteredButtonGroup = styled(ButtonGroup)`
  padding-top: 1rem;
  width: 100%;
  justify-content: center;
`;
