import React from 'react';
import { useSelector } from 'react-redux';

import { Row } from '@tanstack/react-table';
import Big from 'big.js';
import { orderBy } from 'lodash';
import styled from 'styled-components';

import { getProjectOrders } from '../../../../store/reducers/order/order';
import { getTopics } from '../../../../store/reducers/topic';
import { getProjectWorkPackages } from '../../../../store/reducers/workPackage';

import {
  fetchOrdersForProject,
  fetchWorkPackagesForProject,
} from '../../../../store/actions';

import { useRemoteDataWithDeepCompare } from '../../../../hooks/useRemoteData';
import useTxt from '../../../../hooks/useTxt';
import { TargetRowOrTargetRowHierarchyEntry } from '../../hooks/useTargetViewData';

import { BigPriceValue } from '../../../../components/BigValue';
import { PrimaryButton } from '../../../../components/Buttons';
import DropDownSelect from '../../../../components/DropDownSelect';
import { Summary } from '../../../../components/Table/SelectionSummary';
import Txt from '../../../../components/Txt';

import { UpdateProcurementRow } from './utils';
import { areStringifiedArraysSame } from '../../../../utils/general';

import { useParams, routes } from '../../../../routes';

import defaultTheme from '../../../../styles/theme';

type SelectionEditSectionProps = {
  parsedData: Row<UpdateProcurementRow>[];
  unparsedData: TargetRowOrTargetRowHierarchyEntry[];
  setData: React.Dispatch<
    React.SetStateAction<TargetRowOrTargetRowHierarchyEntry[]>
  >;
  selectionState: Record<string, boolean>;
  emptyRowSelection: () => void;
};

type SelectionState = {
  orderId: undefined | string;
  topicOrWorkPackageId: undefined | string;
};

const SelectionEditSection = ({
  parsedData,
  unparsedData,
  setData,
  selectionState,
  emptyRowSelection,
}: SelectionEditSectionProps) => {
  const { projectId } = useParams(routes.TARGET);

  const [dropDownSelectionState, setDropDownSelectionState] =
    React.useState<SelectionState>({
      orderId: undefined,
      topicOrWorkPackageId: undefined,
    });

  const targetTotalText = useTxt(
    'target.table.selectionSummary.selectionTotal'
  );

  const selectedRows = parsedData.filter(
    (row) => selectionState[row.id] === true
  );

  const selectedRowsChildren = selectedRows
    .map((row) => row.getLeafRows())
    .flat();

  const selectedTargetRows = [
    ...new Set([...selectedRowsChildren, ...selectedRows]),
  ]
    .filter((row) => row.original.type === 'targetRow')
    .map((row) => row.original);

  const moveText = useTxt('target.updateProcurementsModal.move', {
    count: selectedTargetRows.length,
  });

  const moveOneText = useTxt('target.updateProcurementsModal.move.one', {
    count: selectedTargetRows.length,
  });

  const orders =
    useRemoteDataWithDeepCompare(
      getProjectOrders(projectId),
      fetchOrdersForProject(projectId),
      true,
      areStringifiedArraysSame
    ) ?? [];

  const sortedOrders = orderBy(orders, ['visibleCode'], ['asc']);

  const workPackages =
    useRemoteDataWithDeepCompare(
      getProjectWorkPackages(projectId),
      fetchWorkPackagesForProject(projectId),
      true,
      areStringifiedArraysSame
    ) ?? [];

  const topics = useSelector(getTopics, areStringifiedArraysSame) ?? [];

  const selectedAmount = selectedTargetRows.reduce((acc, row) => {
    return acc.plus(row.amount ?? 0);
  }, new Big(0));

  const orderPh = useTxt('order.addTargetRowsModal.placeholder.order');
  const topicPh = useTxt('order.addTargetRowsModal.placeholder.topic');

  const newTopicText = useTxt('common.newTopic');
  const moreWorkSectionsText = useTxt('common.moreWorkSections');

  const newTopics = {
    label: (
      <>
        <StyledNewTopicDiv>
          <NowrapParagraph>{`---- ${moreWorkSectionsText} ----`}</NowrapParagraph>
        </StyledNewTopicDiv>
      </>
    ),
    title: '',
    shortlabel: '',
    options: workPackages.map((workPackage) => ({
      value: `wp-${workPackage.id}`,
      key: `wp-${workPackage.id}`,
      label: (
        <NowrapParagraph>
          <DescriptionText>{`+ ${workPackage.name} (${newTopicText})`}</DescriptionText>
          <ExtraInfoText>
            {workPackage ? `${workPackage.code} ${workPackage.name}` : null}
          </ExtraInfoText>
        </NowrapParagraph>
      ),
      title: `+ ${workPackage.name} (${newTopicText})`,
      shortlabel: `workpackage ${workPackage.code}`,
    })),
  };

  const submitSelection = () => {
    const orderId = dropDownSelectionState.orderId;

    const topicId =
      dropDownSelectionState.topicOrWorkPackageId &&
      !dropDownSelectionState.topicOrWorkPackageId.startsWith('wp-')
        ? dropDownSelectionState.topicOrWorkPackageId
        : undefined;

    const wpId =
      dropDownSelectionState.topicOrWorkPackageId &&
      dropDownSelectionState.topicOrWorkPackageId.startsWith('wp-')
        ? dropDownSelectionState.topicOrWorkPackageId.replace('wp-', '')
        : undefined;

    if (
      orderId &&
      (topicId || wpId) &&
      topicId !== 'NONE' &&
      orderId !== 'NONE'
    ) {
      const selectedRowIds = new Set(selectedTargetRows.map((row) => row.id));

      const workPackageId = wpId
        ? wpId
        : topics.find((topic) => topic.id === topicId)?.workPackageId;

      const orderCode = sortedOrders.find(
        (order) => order.id === orderId
      )?.visibleCode;

      const orderName = sortedOrders.find(
        (order) => order.id === orderId
      )?.name;

      const workPackageCode = workPackages.find(
        (workPackage) => workPackage.id === workPackageId
      )?.code;

      const workPackageName = workPackages.find(
        (workPackage) => workPackage.id === workPackageId
      )?.name;

      const parseNestedTargetRows = (
        row: TargetRowOrTargetRowHierarchyEntry
      ) => {
        let reMappedRow = { ...row };

        if (selectedRowIds.has(row.id)) {
          const originalOrderId =
            row.hasUnSavedChanges?.originalOrderId ?? row.orderId;

          const originalWorkPackageId =
            row.hasUnSavedChanges?.originalWorkPackageId ?? row.workPackageId;

          const originalTopicId =
            row.hasUnSavedChanges?.originalTopicId ?? row.topicId;

          const originalOrderCode =
            row.hasUnSavedChanges?.originalOrderCode ?? row.orderCode;

          const originalOrderName =
            row.hasUnSavedChanges?.originalOrderName ?? row.orderName;

          const originalWorkPackageCode =
            row.hasUnSavedChanges?.originalWorkPackageCode ??
            row.workPackageCode;

          const originalWorkPackageName =
            row.hasUnSavedChanges?.originalWorkPackageName ??
            row.workPackageName;

          const originalTopicName =
            row.hasUnSavedChanges?.originalTopicName ?? row.topicName;

          reMappedRow = {
            ...row,
            orderId: orderId,
            topicId: topicId ?? null,
            workPackageId: workPackageId ?? undefined,
            orderCode: orderCode,
            orderName: orderName,
            workPackageCode: workPackageCode,
            workPackageName: workPackageName,
            hasUnSavedChanges: {
              originalOrderId,
              originalWorkPackageId: originalWorkPackageId ?? null,
              originalTopicId,
              originalOrderCode: originalOrderCode ?? null,
              originalOrderName: originalOrderName ?? null,
              originalWorkPackageCode: originalWorkPackageCode ?? null,
              originalWorkPackageName: originalWorkPackageName ?? null,
              originalTopicName: originalTopicName ?? null,
            },
          };
        }

        if (reMappedRow.subRows) {
          for (let i = 0; i < reMappedRow.subRows.length; i++) {
            const subRow = reMappedRow.subRows[i];
            reMappedRow.subRows[i] = parseNestedTargetRows(subRow);
          }
        }

        return reMappedRow;
      };

      const parsedRows = unparsedData.map(parseNestedTargetRows);

      setData(parsedRows);
      emptyRowSelection();
    }
  };

  return (
    <StyledSummary>
      <DropDownSelect
        required
        id="selection-edit-section-order-dropdown"
        label="selection-edit-section-order-dropdown"
        invalid={dropDownSelectionState.orderId === undefined}
        additionalStyles={additionalStyling}
        additionalContainerStyles={additionalContainerStyling}
        optionFilterProp="title"
        value={dropDownSelectionState.orderId ?? 'NONE'}
        onChange={(e) => {
          const selection = e === 'NONE' ? '' : e;
          setDropDownSelectionState((prev) => {
            if (prev.orderId === selection) {
              return prev;
            }

            return { orderId: selection, topicOrWorkPackageId: undefined };
          });
        }}
        options={[
          {
            key: 'NONE',
            value: 'NONE',
            title: orderPh,
            label: orderPh,
          },
          ...sortedOrders.map((item) => ({
            key: item.id,
            value: item.id,
            title: `${item.visibleCode} ${item.name} ${item.contractor}`,
            label: (
              <NowrapParagraph>
                <DescriptionText className="order-description">{`${item.visibleCode} ${item.name}`}</DescriptionText>
                <ExtraInfoText className="order-extra-info">
                  {item.contractor ? ` ${item.contractor}` : null}
                </ExtraInfoText>
              </NowrapParagraph>
            ),
          })),
        ]}
      />
      <DropDownSelect
        id="selection-edit-section-topic-dropdown"
        label="selection-edit-section-topic-dropdown"
        required
        invalid={dropDownSelectionState.topicOrWorkPackageId === undefined}
        additionalStyles={additionalStyling}
        additionalContainerStyles={additionalContainerStyling}
        value={dropDownSelectionState.topicOrWorkPackageId ?? 'NONE'}
        disabled={
          dropDownSelectionState.orderId === 'NONE' ||
          !dropDownSelectionState.orderId
        }
        optionFilterProp="title"
        onChange={(e) => {
          const selection = e === 'NONE' ? '' : e;
          setDropDownSelectionState((prev) => {
            return { ...prev, topicOrWorkPackageId: selection };
          });
        }}
        options={[
          {
            key: 'NONE',
            value: 'NONE',
            title: topicPh,
            label: topicPh,
          },
          ...topics
            .filter((topic) => topic.orderId === dropDownSelectionState.orderId)
            .map((item) => {
              const linkedWorkPackage = workPackages.filter(
                (workPackage) => workPackage.id === item.workPackageId
              )[0];

              const workPackageCodeName = `${linkedWorkPackage.code} ${linkedWorkPackage.name}`;

              return {
                key: item.id,
                value: item.id,
                title: `${item.name} ${workPackageCodeName}`,
                label: (
                  <NowrapParagraph>
                    <DescriptionText className="topic-description">
                      {item.name.length > 0 ? item.name : '-'}
                    </DescriptionText>
                    <ExtraInfoText className="topic-extra-info">
                      {workPackageCodeName}
                    </ExtraInfoText>
                  </NowrapParagraph>
                ),
              };
            }),
          newTopics,
        ]}
      />
      <Column>
        <StyledSpan>
          {`${selectedTargetRows.length === 1 ? moveOneText : moveText}`}
        </StyledSpan>
        <StyledSpan>
          {`${targetTotalText}: `}
          <b>
            <BigPriceValue value={selectedAmount} decimals={2} />
          </b>
        </StyledSpan>
      </Column>
      <PrimaryButton
        type="button"
        disabled={
          !dropDownSelectionState.topicOrWorkPackageId ||
          dropDownSelectionState.topicOrWorkPackageId === 'NONE'
        }
        onClick={() => submitSelection()}
      >
        <Txt id="common.move" />
      </PrimaryButton>
    </StyledSummary>
  );
};

const StyledSummary = styled(Summary)`
  padding: ${({ theme }) => theme.margin[32]} ${({ theme }) => theme.margin[8]};
  justify-content: flex-end;
  gap: ${({ theme }) => theme.margin[32]};
`;

const StyledSpan = styled.span`
  text-align: right;
  font-size: ${({ theme }) => theme.fontSize.h2};
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.margin[4]};
`;

const additionalStyling: React.CSSProperties = {
  borderRadius: defaultTheme.margin[4],
  background: defaultTheme.color.dropdownBg,
  fontSize: defaultTheme.fontSize.base,
  appearance: 'none',
};

const additionalContainerStyling: React.CSSProperties = {
  padding: `${defaultTheme.margin[4]} ${defaultTheme.margin[4]} ${defaultTheme.margin[4]} ${defaultTheme.margin[16]}`,
  height: defaultTheme.margin[40],
  backgroundColor: 'transparent',
  margin: `${defaultTheme.margin[4]} 0 ${defaultTheme.margin[4]} ${defaultTheme.margin[16]}`,
  width: '30rem',
};

const NowrapParagraph = styled.p`
  white-space: no-wrap;
  margin-block-start: 0;
  margin-block-end: 0;
`;

const ExtraInfoText = styled.span`
  margin-left: ${({ theme: { margin } }) => margin[4]};
  border-left: 1px solid ${(props) => props.theme.color.graphiteB76};
  padding: 0 ${(props) => props.theme.margin[4]};
  color: ${(props) => props.theme.color.graphiteB57};
`;

const DescriptionText = styled.span`
  padding: 0 ${(props) => props.theme.margin[4]} 0 0;
`;

const StyledNewTopicDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export default SelectionEditSection;
