import React from 'react';

import styled, { css } from 'styled-components';

import {
  getWorkPackageGroupPlannedPoCEntries,
  getWorkPackageGroupPreviouslyPlannedPoCEntries,
  getWorkPackageGroupSnapshotPoCEntries,
  getWorkPackageGroupActualAndPlannedPoCEntries,
  getWorkPackageGroupPoCBasedOnPreviouslyPlannedEntries,
} from '../../../../../store/reducers/schedule/workPackageGroupTimeline';
import { getProjectWorkPackages } from '../../../../../store/reducers/workPackage';

import { fetchWorkPackagesForProject } from '../../../../../store/actions';
import { getWorkPackageGroupTimelineForProject } from '../../../../../store/actions/schedule/workPackageGroupTimeline';
import { APIWorkPackageGroup } from '../../../../../store/actions/workPackageGroup';

import useRemoteData from '../../../../../hooks/useRemoteData';

import {
  DescriptionCell,
  FuturePoCCell,
  SnapshotPoCCell,
} from './components/Cell';
import { BaseRow } from '../../../../../components/Table';

import WorkPackageRow from './WorkPackageRow';

import { StyledTable } from '.';

type WorkPackageGroupRowProps = {
  projectId: string;
  workPackageGroup: APIWorkPackageGroup;
  months: Date[];
  snapshotIds: string[];
  currentPeriod: Date;
  selectedSnapshotTypeId: '1' | '2' | '3';
};

const WorkPackageGroupRow = ({
  projectId,
  workPackageGroup,
  snapshotIds,
  months,
  currentPeriod,
  selectedSnapshotTypeId,
}: WorkPackageGroupRowProps) => {
  const [isOpen, setOpen] = React.useState(false);
  const [height, setHeight] = React.useState(0);
  const ref = React.useRef<HTMLTableSectionElement | null>(null);
  const animationRef = React.useRef<number>();

  React.useEffect(() => {
    animationRef.current = requestAnimationFrame(() => {
      if (ref.current && isOpen) {
        setHeight(ref.current.scrollHeight);
      }
    });

    return () => cancelAnimationFrame(animationRef.current ?? 0);
  }, [isOpen]);

  const plannedEntries = useRemoteData(
    getWorkPackageGroupPlannedPoCEntries({
      projectId,
      workPackageGroupId: workPackageGroup.id,
    }),
    getWorkPackageGroupTimelineForProject({ projectId })
  );

  const actualAndPlannedEntries =
    useRemoteData(
      getWorkPackageGroupActualAndPlannedPoCEntries({
        projectId,
        workPackageGroupId: workPackageGroup.id,
      }),
      getWorkPackageGroupTimelineForProject({ projectId })
    ) ?? [];

  const PoCBasedOnPreviouslyPlanned =
    useRemoteData(
      getWorkPackageGroupPoCBasedOnPreviouslyPlannedEntries({
        projectId,
        workPackageGroupId: workPackageGroup.id,
      }),
      getWorkPackageGroupTimelineForProject({ projectId })
    ) ?? [];

  const previouslyPlannedEntries =
    useRemoteData(
      getWorkPackageGroupPreviouslyPlannedPoCEntries({
        projectId,
        workPackageGroupId: workPackageGroup.id,
      }),
      getWorkPackageGroupTimelineForProject({ projectId })
    ) ?? [];

  const snapshotEntries =
    useRemoteData(
      getWorkPackageGroupSnapshotPoCEntries({
        projectId,
        workPackageGroupId: workPackageGroup.id,
      }),
      getWorkPackageGroupTimelineForProject({ projectId })
    ) ?? [];

  const allWorkPackages =
    useRemoteData(
      getProjectWorkPackages(projectId),
      fetchWorkPackagesForProject(projectId)
    ) ?? [];

  const filteredAndSortedWorkPackages = allWorkPackages
    .filter((workPackage) =>
      workPackageGroup.workPackageIds.includes(workPackage.id)
    )
    .sort((a, b) => a.code.localeCompare(b.code));

  if (!plannedEntries) {
    return null;
  }

  const mappedEntries = () => {
    switch (selectedSnapshotTypeId) {
      case '1':
        return actualAndPlannedEntries.map((entry) => {
          return { date: entry.date, percentage: entry.percentageOfCompletion };
        });
      case '2':
        return plannedEntries.map((entry) => {
          return { date: entry.date, percentage: entry.percentageOfCompletion };
        });
      case '3':
        return PoCBasedOnPreviouslyPlanned.map((entry) => {
          return { date: entry.date, percentage: entry.percentageOfCompletion };
        });
    }
  };

  const mappedPreviousEntries = previouslyPlannedEntries.map((entry) => {
    return { date: entry.date, percentage: entry.percentageOfCompletion };
  });

  const mappedSnapshotEntries = snapshotEntries.map((entry) => {
    return {
      snapshotId: entry.snapshotId,
      percentage: entry.percentageOfCompletion,
    };
  });

  const toggleRowOpen = () => {
    setHeight(0);
    setOpen(!isOpen);
  };

  const colSpanLength = months.length + snapshotIds.length + 1;

  return (
    <>
      <BaseRow onClick={toggleRowOpen} clickable>
        <DescriptionCell fontWeight="normal" borderBottom="dark" condensed>
          <b>{workPackageGroup.code}</b>
          <NameSpan>{workPackageGroup.name}</NameSpan>
        </DescriptionCell>
        {snapshotIds.map((id) => {
          return (
            <SnapshotPoCCell
              snapshotId={id}
              timelineEntries={mappedSnapshotEntries}
              key={`workpackagegroup-${workPackageGroup.id}-snapshot-${id}`}
              borderBottom="dark"
              condensed
            />
          );
        })}
        {months.map((month) => {
          return (
            <FuturePoCCell
              month={month}
              currentPeriod={currentPeriod}
              timelineEntries={mappedEntries()}
              previouslyPlannedEntries={mappedPreviousEntries}
              key={`project-${month.toString()}`}
              borderBottom="dark"
              condensed
            />
          );
        })}
      </BaseRow>
      <tr>
        <td colSpan={colSpanLength}>
          <StyledTBody isOpen={isOpen} ref={ref} height={height}>
            {isOpen ? (
              <StyledTable>
                <tbody>
                  {filteredAndSortedWorkPackages.map((workPackage) => {
                    return (
                      <WorkPackageRow
                        workPackageId={workPackage.id}
                        projectId={projectId}
                        snapshotIds={snapshotIds}
                        months={months}
                        currentPeriod={currentPeriod}
                        key={`workPackageRow-${workPackage.id}`}
                        selectedSnapshotTypeId={selectedSnapshotTypeId}
                      />
                    );
                  })}
                </tbody>
              </StyledTable>
            ) : null}
          </StyledTBody>
        </td>
      </tr>
    </>
  );
};

type StyledTBodyProps = {
  isOpen: boolean;
  height?: number;
};

const StyledTBody = styled.div<StyledTBodyProps>`
  transition: height 1s ease;
  ${(props) =>
    props.isOpen
      ? css`
          height: ${props.height}px;
        `
      : css`
          height: 0px;
          overflow: hidden;
        `}
`;

const NameSpan = styled.span`
  margin-left: ${(props) => props.theme.margin[8]};
`;

export default WorkPackageGroupRow;
