import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';
import * as uuid from 'uuid';

import { getActiveProjectId } from '../../../store/reducers/ui';

import { requestNewAnalysisRow } from '../../../store/actions/analysis/row';

import { IconTextButton } from '../../../components/Buttons';
import Cell from '../../../components/Cell';
import { SecondaryRow } from '../../../components/Table';
import Txt from '../../../components/Txt';

import { IconPlus } from '../../../assets/svg';

import { tableColumns } from './TableHeader';

type ControlRowProps = {
  analysisGroupId: string;
};

const columnTotal = Object.keys(tableColumns).length;

export const ControlRow = (props: ControlRowProps) => {
  const { analysisGroupId } = props;

  const [requestId] = React.useState(uuid.v4());
  const projectId = useSelector(getActiveProjectId) ?? '';
  const dispatch = useDispatch();

  const onAddRowClicked = () => {
    dispatch(requestNewAnalysisRow({ requestId, projectId, analysisGroupId }));
  };

  return (
    <SecondaryRow>
      <Cell align="left" colSpan={columnTotal}>
        <StyledIconTextButton icon={IconPlus} onClick={onAddRowClicked}>
          <Txt id="revenue.table.rows.addRow" />
        </StyledIconTextButton>
      </Cell>
    </SecondaryRow>
  );
};

const StyledIconTextButton = styled(IconTextButton)`
  padding-left: 2.5rem;
`;
