import { SnakeCase } from 'type-fest';

import { BackendError } from './api';

export type ActionType<Name extends string, Payload = undefined> = {
  type: Name;
  payload: Payload;
};

export type Action<Name extends string, Payload> = ActionType<Name, Payload>;

// https://www.typescriptlang.org/docs/handbook/functions.html#overloads
export function createAction<Name extends string>(name: Name): ActionType<Name>;
export function createAction<Name extends string, Payload>(
  name: Name,
  payload: Payload
): ActionType<Name, Payload>;
export function createAction<Name extends string, Payload>(
  name: Name,
  payload?: Payload
): ActionType<Name> | ActionType<Name, Payload> {
  if (payload === undefined) {
    return {
      type: name,
      payload: undefined,
    };
  }

  return {
    type: name,
    payload,
  };
}

// https://www.typescriptlang.org/docs/handbook/2/template-literal-types.html
export function toUpperCase<A extends string>(a: A): Uppercase<A> {
  return a.toUpperCase() as Uppercase<A>;
}

export function capitalize<S extends string>(s: S): Capitalize<S> {
  return (s.charAt(0).toUpperCase() + s.slice(1)) as Capitalize<S>;
}

export function camelToSnakeCase<S extends string>(str: S): SnakeCase<S> {
  return str.replace(
    /[A-Z]/g,
    (letter) => `_${letter.toLowerCase()}`
  ) as SnakeCase<S>;
}

type CamelToScreamingSnakeCase<T extends string> = Uppercase<SnakeCase<T>>;
export function camelToScreamingSnakeCase<S extends string>(
  str: S
): CamelToScreamingSnakeCase<S> {
  return toUpperCase(camelToSnakeCase(str)) as CamelToScreamingSnakeCase<S>;
}

export type Method = 'get' | 'put' | 'post' | 'delete';
export type Kind = 'Started' | 'Failure' | 'Success';

export type ActionCreatorName<
  MethodName extends Method,
  Name extends string,
  KindName extends Kind,
> = `${MethodName}${Capitalize<Name>}${KindName}`;

export function actionCreatorName<
  MethodName extends Method,
  Name extends string,
  KindName extends Kind,
>(
  methodName: MethodName,
  name: Name,
  kindName: KindName
): ActionCreatorName<MethodName, Name, KindName> {
  const capitalizedName = capitalize(name);

  return `${methodName}${capitalizedName}${kindName}` as ActionCreatorName<
    MethodName,
    Name,
    KindName
  >;
}

type ActionName<
  MethodName extends Method,
  Name extends string,
  S extends Kind,
> = `${Uppercase<MethodName>}_${CamelToScreamingSnakeCase<Name>}_${Uppercase<S>}`;
export function actionName<
  MethodName extends Method,
  Name extends string,
  KindName extends Kind,
>(
  method: MethodName,
  name: Name,
  kind: KindName
): ActionName<MethodName, Name, KindName> {
  const methodName = toUpperCase(method);
  const screamingName = camelToScreamingSnakeCase(name);
  const kindName = toUpperCase(kind);

  return `${methodName}_${screamingName}_${kindName}` as ActionName<
    MethodName,
    Name,
    KindName
  >;
}

type StartedCreator<
  MethodName extends Method,
  Name extends string,
> = () => ActionType<ActionName<MethodName, Name, 'Started'>>;

type FailureCreator<MethodName extends Method, Name extends string> = (
  error?: BackendError
) => ActionType<
  ActionName<MethodName, Name, 'Failure'>,
  { error: BackendError | undefined }
>;

type SuccessCreator<MethodName extends Method, Name extends string, Payload> = (
  payload: Payload
) => ActionType<ActionName<MethodName, Name, 'Success'>, Payload>;

export type ActionCreatorEntries<
  MethodName extends Method,
  Name extends string,
  Payload,
> =
  | {
      key: ActionCreatorName<MethodName, Name, 'Started'>;
      value: StartedCreator<MethodName, Name>;
    }
  | {
      key: ActionCreatorName<MethodName, Name, 'Failure'>;
      value: FailureCreator<MethodName, Name>;
    }
  | {
      key: ActionCreatorName<MethodName, Name, 'Success'>;
      value: SuccessCreator<MethodName, Name, Payload>;
    };

type ActionCreators<MethodName extends Method, Name extends string, Payload> = {
  [Key in ActionCreatorEntries<MethodName, Name, Payload>['key']]: Extract<
    ActionCreatorEntries<MethodName, Name, Payload>,
    { key: Key }
  >['value'];
};

export type ExtractActionTypes<
  ActionCreatorsObject extends Record<string, (...args: any[]) => any>,
> = {
  [K in keyof ActionCreatorsObject]: ReturnType<ActionCreatorsObject[K]>;
}[keyof ActionCreatorsObject];

export function makeApiActions<MethodName extends Method, Name extends string>(
  method: MethodName,
  name: Name
): <P>() => ActionCreators<MethodName, Name, P> {
  const initialActionCreatorName = actionCreatorName(method, name, 'Started');
  const initialActionName = actionName(method, name, 'Started');

  const failureActionCreatorName = actionCreatorName(method, name, 'Failure');
  const failureActionName = actionName(method, name, 'Failure');

  const successActionCreatorName = actionCreatorName(method, name, 'Success');
  const successActionName = actionName(method, name, 'Success');

  return <Payload>() =>
    ({
      [initialActionCreatorName]: (payload?: Payload) =>
        createAction(initialActionName, payload),
      [failureActionCreatorName]: (error?: BackendError) =>
        createAction(failureActionName, { error }),
      [successActionCreatorName]: (payload: Payload) =>
        createAction(successActionName, payload),
    }) as ActionCreators<MethodName, Name, Payload>;
}

type CustomActionCreator<Name extends string, Payload> = {
  [Key in Name]: (
    payload: Payload
  ) => ActionType<CamelToScreamingSnakeCase<Name>, Payload>;
};
export function makeAction<Name extends string>(
  name: Name
): <P>() => CustomActionCreator<Name, P> {
  const actionTypeName = camelToScreamingSnakeCase(name);

  return <Payload>() =>
    ({
      [name]: (payload: Payload) => ({ type: actionTypeName, payload }),
    }) as CustomActionCreator<Name, Payload>;
}
