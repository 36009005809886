import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import Big from 'big.js';
import styled from 'styled-components';

import { getProjectOrders } from '../../../../store/reducers/order/order';
import {
  getDeleteRequestState,
  getTargetRowById,
  getSplitToRows,
} from '../../../../store/reducers/target/targetRows';
import {
  getIsOuterBarOpen,
  getSelectedOrderRowsForOrder,
} from '../../../../store/reducers/ui';

import { fetchOrdersForProject } from '../../../../store/actions';
import {
  requestDeleteTargetRow,
  TargetRow,
  requestTargetRowsForProject,
} from '../../../../store/actions/target/targetRow';

import useRemoteData from '../../../../hooks/useRemoteData';
import useRouteParams from '../../../../hooks/useRouteParams';
import useTxt from '../../../../hooks/useTxt';

import { TagsContainer } from '../../../../components/Analysis/AnalysisTags';
import { IconButton } from '../../../../components/Buttons';
import Cell, { MoneyCell } from '../../../../components/Cell';
import { BaseRow } from '../../../../components/Table';
import Tooltip from '../../../../components/Tooltip';

import * as big from '../../../../utils/big';
import CAN, {
  CaslPaymentProgramRowRequestParams,
} from '../../../../utils/caslUserPermissions';
import { isClickOrKeyboardSelection } from '../../../../utils/mouseOrKeyInteraction';

import { IconSplitRow, IconToLinkedEntity } from '../../../../assets';
import { IconDelete } from '../../../../assets/svg';

import { generateUrl, routes } from '../../../../routes';

import AnalysisTags from '../AnalysisTags';
import Checkbox from './Checkbox';

type TargetProps = {
  target: TargetRow;
};

const Target = ({
  target: {
    id: targetRowId,
    description,
    orderId,
    quantity,
    unit,
    unitPrice,
    totalPrice,
    isDeletable,
    isDisabled,
    isSplitFrom,
    analysisListItemIds,
    isAntiRow,
  },
}: TargetProps) => {
  const { projectId, viewMode } = useRouteParams();
  const outerBarOpen = useSelector(getIsOuterBarOpen());
  const history = useHistory();

  const requestState = useSelector(getDeleteRequestState(targetRowId));

  const splitFrom = useSelector(getTargetRowById(isSplitFrom ?? ''));

  const orders =
    useRemoteData(
      getProjectOrders(projectId),
      fetchOrdersForProject(projectId)
    ) ?? [];

  const splitTo =
    useRemoteData(
      getSplitToRows(projectId, targetRowId),
      requestTargetRowsForProject({ projectId })
    ) ?? [];

  const isButtonDisabled = requestState !== 'NotAsked';

  const dispatch = useDispatch();

  const isTargetRowSelectionDisabled =
    useSelector(getSelectedOrderRowsForOrder(orderId)).length > 0;

  const ability = new CaslPaymentProgramRowRequestParams(projectId);
  const allowedUser = CAN('write', ability);

  const onDeleteTargetRow = () => {
    dispatch(requestDeleteTargetRow({ targetRowId }));
  };

  const colSpanLength = () => {
    if (viewMode === 'edit' && !outerBarOpen) {
      return 4;
    }

    if (viewMode === 'edit' && outerBarOpen) {
      return 3;
    }

    if (viewMode === 'normal' && outerBarOpen) {
      return 2;
    }

    if (viewMode === 'normal' && !outerBarOpen) {
      return 3;
    }

    if (viewMode === 'receive' && !outerBarOpen) {
      return 4;
    }

    return 4;
  };

  const removeText = useTxt('order.inputs.Remove');

  const splitToTargetRowInfo = splitTo
    .map((row) => {
      const order = orders.find((o) => o.id === row.orderId);

      return `${row.description} (${order?.visibleCode}, ${order?.name})`;
    })
    .join(', ');

  const splitFromOrder = orders.find((o) => o.id === splitFrom?.orderId);

  const splitTargetTip = useTxt(
    'order.targetMode.splitIcon.tooltip.splitFrom',
    {
      targetRowInfo: `${splitFrom?.referenceNumber ?? ''} ${
        splitFrom?.description
      } (${splitFromOrder?.visibleCode}, ${splitFromOrder?.name})`,
    }
  );

  const disabledSplitTargetTip = useTxt(
    'order.targetMode.splitIcon.tooltip.disabled',
    { targetRowInfo: splitToTargetRowInfo }
  );

  const moveToTargetViewTip = useTxt(
    'order.targetMode.moveToTargetView.tooltip'
  );

  if (isAntiRow) {
    return null;
  }

  const tip = () => {
    if (isSplitFrom && isDisabled) {
      return `${splitTargetTip} & ${disabledSplitTargetTip}`;
    }

    if (isSplitFrom) {
      return splitTargetTip;
    }

    return disabledSplitTargetTip;
  };

  const navigateToSplitTargetView = () => {
    const splitIds: string[] = [targetRowId];

    if (isSplitFrom && isDisabled && splitFrom) {
      splitIds.push(splitFrom?.id, ...splitTo.map((row) => row.id));
    } else if (isSplitFrom && splitFrom) {
      splitIds.push(splitFrom?.id);
    } else {
      splitIds.push(...splitTo.map((row) => row.id));
    }

    if (splitIds.length > 0) {
      history.push(
        generateUrl({
          route: routes.TARGET_WITH_TARGET_ROW_FOCUSED,
          projectId,
          targetRowIds: splitIds.toString(),
        })
      );
    }
  };

  const onSplitIconPress = (e: React.KeyboardEvent | React.MouseEvent) => {
    e.stopPropagation();

    if (isClickOrKeyboardSelection(e)) {
      navigateToSplitTargetView();
    }
  };

  return (
    <Tr data-testid={`target-row-${targetRowId}`} isDisabled={isDisabled}>
      <StyledCell align="center">
        {viewMode === 'edit' ? (
          <Checkbox
            targetRowId={targetRowId}
            disabled={
              !allowedUser || isTargetRowSelectionDisabled || isDisabled
            }
          />
        ) : null}
      </StyledCell>
      {viewMode !== 'edit' ? null : <StyledCell />}
      <StyledCell>
        {description}
        {isSplitFrom || isDisabled ? (
          <Tooltip tip={tip()} className="hoverable-tooltip" delayHide={50}>
            <StyledIcon
              src={IconSplitRow}
              onClick={onSplitIconPress}
              onKeyPress={onSplitIconPress}
              alt="split_icon"
              width={16}
              height={16}
            />
          </Tooltip>
        ) : null}
        <Link
          to={generateUrl({
            route: routes.TARGET_WITH_TARGET_ROW_FOCUSED,
            projectId,
            targetRowIds: targetRowId,
          })}
        >
          <Tooltip tip={moveToTargetViewTip}>
            <StyledIcon src={IconToLinkedEntity} alt="link_to_target_view" />
          </Tooltip>
        </Link>
      </StyledCell>
      {outerBarOpen ? (
        <StyledCell align="right">
          {`${big.amountFormat(quantity || new Big(0), 0)} ${unit}`}
        </StyledCell>
      ) : (
        <>
          <StyledCell align="right">
            {big.amountFormat(quantity || new Big(0))}
          </StyledCell>
          <StyledCell align="center">{unit}</StyledCell>
        </>
      )}
      <StyledMoneyCell
        value={unitPrice || new Big(0)}
        decimals={outerBarOpen ? 0 : undefined}
      />
      <StyledMoneyCell
        value={totalPrice}
        decimals={outerBarOpen ? 0 : undefined}
      />
      <StyledCell colSpan={colSpanLength()} />
      {outerBarOpen || viewMode === 'receive' ? null : (
        <>
          <StyledCell align="center" contentContainer={TagsContainer}>
            <AnalysisTags
              targetRowId={targetRowId}
              analysisListItemIds={analysisListItemIds}
            />
          </StyledCell>
        </>
      )}
      <StyledCell colSpan={2} />
      <StyledCell align="center">
        {isDeletable && viewMode === 'edit' ? (
          <IconButton
            icon={IconDelete}
            onClick={onDeleteTargetRow}
            aria-label={removeText}
            disabled={isButtonDisabled}
          />
        ) : null}
      </StyledCell>
    </Tr>
  );
};

type TrProps = {
  isDisabled?: boolean;
};

const Tr = styled(BaseRow)<TrProps>`
  background: ${(props) =>
    props.isDisabled
      ? props.theme.color.graphiteB76
      : props.theme.color.whisper};
  color: ${(props) =>
    props.isDisabled ? props.theme.color.graphiteB48 : props.theme.color.pitch};
`;

const StyledCell = styled(Cell)`
  border-bottom: 1px solid ${({ theme: { color } }) => color.graySuit};
`;

const StyledMoneyCell = styled(MoneyCell)`
  border-bottom: 1px solid ${({ theme: { color } }) => color.graySuit};
`;

export const StyledIcon = styled.img`
  margin: 0 ${({ theme }) => theme.margin[8]};
  cursor: pointer;
`;

export default Target;
