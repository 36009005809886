import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useMeasure from 'react-use-measure';

import Big from 'big.js';
import { cloneDeep } from 'lodash';
import styled from 'styled-components';
import { v4 } from 'uuid';

import { getProject } from '../../../../store/reducers/project';
import { getRequestState } from '../../../../store/reducers/target/convertRequests';

import {
  convertTargetRowsToOrderRows,
  PostConvertTargetRowsToOrderRowParams,
} from '../../../../store/actions/target/convertRequests';

import useTxt from '../../../../hooks/useTxt';
import { TargetRowOrTargetRowHierarchyEntry } from '../../hooks/useTargetViewData';

import {
  SecondaryButton,
  PrimaryButton,
  ButtonGroup,
  PrimaryButtonSmall,
} from '../../../../components/Buttons';
import { Spinner } from '../../../../components/Loading';
import Modal, {
  Content,
  HeaderWithSteps,
  HeaderWithStepsCloseButton,
} from '../../../../components/Modal/Modal';
import NoDataInfoBox from '../../../../components/Table/NoDataInfoBox';
import Txt from '../../../../components/Txt';

import { parseSelections } from './utils';
import { priceFormat } from '../../../../utils/big';
import { withDefault } from '../../../../utils/remoteData';
import {
  getAllRowsFlattened,
  getSelectedRowsFlattenedWithSubrows,
} from '../../../../utils/tableUtils';

import PreviewTable from './PreviewTable';
import {
  AmountText,
  Column,
  KeyFiguresDiv,
  StyledFooter,
} from '../TargetImportModal/Footer';

export type UpdateProcurementsModalProps = {
  data: TargetRowOrTargetRowHierarchyEntry[];
  onClose: () => void;
  selectionState: Record<string, boolean>;
  projectId: string;
};

export const UpdateProcurementsModal = ({
  data,
  onClose,
  selectionState,
  projectId,
}: UpdateProcurementsModalProps) => {
  const [requestId] = React.useState<string>(v4());
  const initialData = cloneDeep(data);

  const dispatch = useDispatch();

  const [unparsedData, setData] =
    React.useState<TargetRowOrTargetRowHierarchyEntry[]>(initialData);

  const unsavedChanges =
    JSON.stringify(initialData) !== JSON.stringify(unparsedData);

  const parsedRows = parseSelections(selectionState, unparsedData);

  const [allRendered, setAllRendered] = React.useState(false);
  const [isAnythingSelected, setIsAnythingSelected] = React.useState(false);

  const [measureRef, measures] = useMeasure({ debounce: 100 });

  const memoizedMeasures = React.useMemo(
    () => ({ width: measures.width, height: measures.height }),
    [measures.width, measures.height]
  );

  const ref = React.useRef<number>();

  const projectData = withDefault(
    useSelector(getProject(projectId)),
    undefined
  );

  const requestState = useSelector(getRequestState(requestId));

  const currentForecast = projectData?.costPredictionTotal ?? new Big(0);

  // memoize measures and wait until rendering is done to pass the width and height to children
  // otherwise will result in developer error "resizeobserver loop completed with undelivered notifications"
  React.useEffect(() => {
    if (memoizedMeasures.width !== 0) {
      ref.current = requestAnimationFrame(() => {
        setAllRendered(true);
      });
    }

    return () => cancelAnimationFrame(ref.current ?? 0);
  }, [memoizedMeasures.width]);

  React.useEffect(() => {
    if (requestState === 'Success') {
      onClose();
    }
  }, [requestState, onClose]);

  const headerText = useTxt('target.updateProcurementsModal.noData.header');

  const bodyText = useTxt('target.updateProcurementsModal.noData.body');

  const closeText = useTxt('common.close');

  const resetData = () => {
    setData(initialData);
  };

  const resetDataForTargetRows = React.useCallback(
    (ids: string[]) => {
      const flattenedInitialData = getAllRowsFlattened(initialData);

      const initialTargetRows: Map<string, TargetRowOrTargetRowHierarchyEntry> =
        new Map();

      for (let i = 0; i < flattenedInitialData.length; i++) {
        const targetRow = flattenedInitialData[i];

        if (ids.includes(targetRow.id)) {
          initialTargetRows.set(targetRow.id, targetRow);
        }
      }

      const resetTargetRow = (
        node: TargetRowOrTargetRowHierarchyEntry
      ): TargetRowOrTargetRowHierarchyEntry => {
        let newNode = node;

        const initialNode = initialTargetRows.get(node.id);

        if (initialNode) {
          newNode = { ...initialNode, subRows: [] };
        }

        const { subRows } = newNode;

        if (subRows) {
          for (let i = 0; i < subRows.length; i++) {
            const targetRow = subRows[i];
            subRows[i] = resetTargetRow(targetRow);
          }
        }

        return newNode;
      };

      const newData = unparsedData.map(resetTargetRow);

      setData(newData);
    },
    [initialData, unparsedData]
  );

  const content = () => {
    if (allRendered === false) {
      return (
        <InfoBoxContainer>
          <Spinner size="4rem" />
        </InfoBoxContainer>
      );
    }

    if (parsedRows.length === 0) {
      return (
        <InfoBoxContainer>
          <NoDataInfoBox headerText={headerText} bodyText={bodyText}>
            <PrimaryButtonSmall onClick={onClose}>
              {closeText}
            </PrimaryButtonSmall>
          </NoDataInfoBox>
        </InfoBoxContainer>
      );
    }

    return (
      <PreviewTable
        parsedData={parsedRows}
        unparsedData={unparsedData}
        setData={setData}
        parentMeasures={{
          width: memoizedMeasures.width,
          height: memoizedMeasures.height,
        }}
        isAnythingSelected={isAnythingSelected}
        setIsAnythingSelected={setIsAnythingSelected}
        resetDataForTargetRows={resetDataForTargetRows}
      />
    );
  };

  const orderRows = getSelectedRowsFlattenedWithSubrows(parsedRows).filter(
    (row) => row.type === 'orderRow'
  );

  const orderRowsAmount = orderRows.reduce((acc, row) => {
    return acc.plus(row.amount);
  }, new Big(0));

  const sendConvertRequest = () => {
    const postParams: PostConvertTargetRowsToOrderRowParams[] = [];

    for (let i = 0; i < orderRows.length; i++) {
      const row = orderRows[i];

      const {
        targetRowHierarchyEntryId,
        orderId,
        workPackageId,
        topicName,
        topicId,
      } = row;

      const targetRowIds =
        row.subRows?.map((subRow) => subRow.originalId) ?? [];

      if (orderId && workPackageId) {
        postParams.push({
          targetRowHierarchyEntryId: targetRowHierarchyEntryId ?? null,
          targetRowIds,
          orderId,
          workPackageId,
          topicName: topicName ?? '',
          topicId: topicId?.includes('new-topic-order')
            ? null
            : topicId ?? null,
        });
      }
    }

    dispatch(convertTargetRowsToOrderRows({ requestId, params: postParams }));
  };

  return (
    <Modal
      onClose={onClose}
      closeButton={HeaderWithStepsCloseButton}
      closingConfirmation
    >
      <FormContainer>
        <HeaderWithSteps>
          <Txt
            id={'target.updateProcurementsModal.header'}
            component={StyledHeader}
          />
        </HeaderWithSteps>
        <StyledContent noMaxHeight>
          <PreviewTableContainer ref={measureRef}>
            {content()}
          </PreviewTableContainer>
        </StyledContent>

        <StyledFooter>
          <KeyFiguresDiv flexGrow={1}>
            <Column>
              <KeyFiguresDiv>
                <Txt id="target.updateProcurementsModal.footer.rowCount" />:
                <AmountText fontSize="1.125rem">{orderRows.length}</AmountText>
              </KeyFiguresDiv>
              <KeyFiguresDiv>
                <Txt id="target.updateProcurementsModal.footer.amount" />:
                <AmountText fontSize="1.125rem">
                  {priceFormat(orderRowsAmount, 0)}
                </AmountText>
              </KeyFiguresDiv>
            </Column>
            <Column>
              <KeyFiguresDiv>
                <Txt id="target.updateProcurementsModal.footer.forecast.before" />
                :
                <AmountText fontSize="1.125rem">
                  {priceFormat(currentForecast, 0)}
                </AmountText>
              </KeyFiguresDiv>
              <KeyFiguresDiv>
                <Txt id="target.updateProcurementsModal.footer.forecast.after" />
                :
                <AmountText fontSize="1.125rem">
                  {priceFormat(currentForecast.plus(orderRowsAmount), 0)}
                </AmountText>
              </KeyFiguresDiv>
            </Column>
            <StyledButtonGroup>
              {unsavedChanges ? (
                <SecondaryButton type="button" onClick={() => resetData()}>
                  <Txt id="common.resetChanges" />
                </SecondaryButton>
              ) : null}
              <SecondaryButton type="button" onClick={() => onClose()}>
                <Txt id="common.cancel" />
              </SecondaryButton>
              <PrimaryButton
                type="button"
                disabled={isAnythingSelected || parsedRows.length === 0}
                onClick={() => sendConvertRequest()}
              >
                {requestState === 'Loading' ? (
                  <Spinner size="1rem" light />
                ) : (
                  <Txt id="common.createRows" />
                )}
              </PrimaryButton>
            </StyledButtonGroup>
          </KeyFiguresDiv>
        </StyledFooter>
      </FormContainer>
    </Modal>
  );
};

const StyledContent = styled(Content)`
  padding: ${(props) => `${props.theme.margin[32]}`};
  overflow-y: auto;
`;

const PreviewTableContainer = styled.div`
  position: relative;

  margin: 0;

  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const StyledHeader = styled.h1`
  flex-grow: 3;
  font-weight: 500;
`;

const FormContainer = styled.div`
  box-shadow: 0px 4px 42px rgba(0, 0, 0, 0.25);

  width: 90vw;
  height: 90vh;
  min-width: 500px;

  display: flex;
  flex-direction: column;
`;

const StyledButtonGroup = styled(ButtonGroup)`
  justify-content: flex-end;
  flex-shrink: 0;
  flex-grow: 1;
`;

const InfoBoxContainer = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
