import React from 'react';

import styled from 'styled-components';

type NoDataInfoBoxProps = {
  children?: React.ReactNode;
  headerText: string;
  bodyText: string;
};

const NoDataInfoBox = ({
  children,
  headerText,
  bodyText,
}: NoDataInfoBoxProps) => {
  return (
    <FlexRowDivCentered>
      <InfoDiv>
        <HeaderSpan>{headerText}</HeaderSpan>
        <BodySpan>{bodyText}</BodySpan>
        {children}
      </InfoDiv>
    </FlexRowDivCentered>
  );
};

const FlexRowDivCentered = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const InfoDiv = styled.div`
  margin: ${(props) => props.theme.margin[24]};

  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);

  padding: ${(props) => props.theme.margin[16]};

  width: 50%;
  min-width: 500px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const HeaderSpan = styled.span`
  margin: ${(props) => props.theme.margin[16]};
  font-size: ${(props) => props.theme.fontSize.h2};
  font-weight: 700;
  text-align: center;
`;

const BodySpan = styled.span`
  margin: ${(props) => props.theme.margin[16]};
  font-size: ${(props) => props.theme.fontSize.base};
  text-align: center;
`;

export default NoDataInfoBox;
