import React from 'react';
import { FormattedDate } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useUpdateEffect } from 'react-use';

import Big from 'big.js';
import * as t from 'io-ts';
import styled from 'styled-components';

import { getAnalysisRowUpdateRequest } from '../../../store/reducers/analysis/row';
import { getAnalysisSelectListItemsForAttribute } from '../../../store/reducers/analysis/selectListItem';

import * as Actions from '../../../store/actions';
import { AnalysisRow as AnalysisRowType } from '../../../store/actions';
import {
  AnalysisRowAttribute,
  AnalysisRowSelectionListAttribute,
  requestAnalysisAttributeUpdate,
} from '../../../store/actions/analysis';

import useRemoteData from '../../../hooks/useRemoteData';
import useTxt from '../../../hooks/useTxt';

import { BigValue } from '../../../components/BigValue';
import Cell, { CellDiv } from '../../../components/Cell';
import { DatePicker } from '../../../components/DatePickerInput';
import EditableCell from '../../../components/EditableCell';
import { Select } from '../../../components/Input/Select';

import assertNever from '../../../utils/assertNever';
import * as big from '../../../utils/big';
import { bigInputString, maxLengthString } from '../../../utils/decoders';
import { useDebounce } from '../../../utils/hooks';
import * as remoteData from '../../../utils/remoteData';

import { routes, useParams } from '../../../routes';

import { EditableCellProps } from './AnalysisRow';

const StyledEditableCell = styled(EditableCell)<EditableCellProps>`
  border-bottom: 1px solid ${(props) => props.theme.color.rowBorder};

  /* stylelint-disable selector-max-type -- TODO Should be refactored */
  input {
    max-width: 130px;
  }
`;

const StyledEditableLeftPaddedCell = styled(EditableCell)<EditableCellProps>`
  border-bottom: 1px solid ${(props) => props.theme.color.rowBorder};
  padding-left: ${(props) => props.theme.margin[24]};
  input {
    max-width: 100%;
  }
`;

type SelectListProps = {
  analysisAttribute: AnalysisRowSelectionListAttribute;
};

const SelectList = ({
  analysisAttribute: { id, attributeId, attributeListItemId, value, updatedAt },
}: SelectListProps): React.ReactElement => {
  const selectionChanged = (
    attributeValueId: string,
    projectId: string,
    selectedValue: string
  ) => {
    dispatch(
      requestAnalysisAttributeUpdate({
        attributeValueId,
        requestId: `analysisAttributeValueUpdate-${attributeId}`,
        projectId,
        value: selectedValue,
        isSelectList: true,
        forcedRequest: true,
        updatedAt,
      })
    );
  };

  const { projectId, viewMode = 'view' } = useParams(routes.ANALYSIS);
  const dispatch = useDispatch();

  const selectOptions =
    useRemoteData(
      getAnalysisSelectListItemsForAttribute({
        projectId,
        attributeId,
      }),
      Actions.requestAnalysisSelectionListItems({ projectId })
    ) ?? [];

  if (viewMode === 'edit') {
    return (
      <StyledSelect
        value={attributeListItemId}
        onChange={(e) => selectionChanged(id, projectId, e.target.value)}
      >
        {selectOptions.map((option) => (
          <option key={option.id} value={option.id}>
            {option.value}
          </option>
        ))}
      </StyledSelect>
    );
  }

  return <>{value}</>;
};

export const DynamicCell = (analysisAttribute: AnalysisRowAttribute) => {
  const { projectId, viewMode = 'view' } = useParams(routes.ANALYSIS);
  const dispatch = useDispatch();

  const {
    attributeId,
    id: attributeValueId,
    value,
    updatedAt,
  } = analysisAttribute;

  const requestId = `analysisAttributeValueUpdate-${attributeId}`;

  const MAX_STRING_LENGTH = 255 as const;
  const bigNumToolTipMsg = useTxt('validation.number.toolTip');

  const tooLongStringToolTipMsg = useTxt('validation.string.length.toolTip', {
    length: MAX_STRING_LENGTH,
  });

  let displayElement: string | React.ReactElement;

  const [update, setUpdate] = React.useState<
    undefined | Partial<AnalysisRowType>
  >(undefined);

  const debouncedUpdate = useDebounce(
    (data: undefined | Partial<AnalysisRowType>) => {
      if (!data) {
        return;
      }

      dispatch(
        requestAnalysisAttributeUpdate({
          attributeValueId,
          requestId,
          projectId,
          value: data.code,
          isSelectList: false,
          forcedRequest: true,
          updatedAt,
        })
      );
      setUpdate(undefined);
    },
    1000
  );

  useUpdateEffect(() => {
    if (update) {
      debouncedUpdate(update);
    }
  }, [update, value]);

  const updateRow =
    <K extends keyof AnalysisRowType>(key: K) =>
    (val: AnalysisRowType[K]) =>
      setUpdate({ ...update, [key]: val });

  const requestState = useSelector(getAnalysisRowUpdateRequest(requestId));

  switch (analysisAttribute.type) {
    case 'SelectionList': {
      displayElement = <SelectList analysisAttribute={analysisAttribute} />;
      break;
    }
    case 'Date':
      if (viewMode === 'view') {
        displayElement = <FormattedDate value={analysisAttribute.value} />;
        break;
      } else {
        displayElement = (
          <StyledPicker>
            <StyledWrapper requestState={requestState.kind}>
              <DatePicker
                date={new Date(analysisAttribute.value)}
                contentContainer={StyledContainer}
                onDayChange={(dateString: Date | string) => {
                  const date = new Date(dateString);

                  return updateRow('code')(
                    `${date.getFullYear()}-${
                      date.getMonth() + 1
                    }-${date.getDate()}`
                  );
                }}
              />
            </StyledWrapper>
          </StyledPicker>
        );

        break;
      }
    case 'Text':
      if (viewMode === 'view') {
        displayElement = analysisAttribute.value;
      } else {
        return (
          <StyledEditableLeftPaddedCell
            align="right"
            initialValue={analysisAttribute.value}
            codec={maxLengthString(MAX_STRING_LENGTH)}
            onChange={updateRow('code')}
            route={routes.ANALYSIS}
            toolTipMsg={tooLongStringToolTipMsg}
            onBlur={() => debouncedUpdate.flush()}
          />
        );
      }

      break;
    case 'Number':
      if (viewMode === 'view') {
        displayElement = (
          <BigValue value={new Big(analysisAttribute.value || 0)} />
        );
        break;
      } else {
        return (
          <StyledEditableCell
            align="right"
            initialValue={big.toInputString(
              new Big(analysisAttribute.value || 0)
            )}
            codec={t.union([bigInputString, t.literal('')])}
            onChange={updateRow('code')}
            route={routes.ANALYSIS}
            toolTipMsg={bigNumToolTipMsg}
            onBlur={() => debouncedUpdate.flush()}
          />
        );
      }
    default:
      throw assertNever(analysisAttribute);
  }

  return (
    <StyledCell
      align="right"
      data-testid={`attributeId-${attributeId}`}
      contentContainer={
        analysisAttribute.type === 'Date' ? DateCellDiv : CellDiv
      }
    >
      {displayElement}
    </StyledCell>
  );
};

// if cell data is of type 'status' import StatusPill and StatusPillProps from ../../../components/StatusPill
// and use the following snippet:
// <StatusPill {...statusPillProps} />;

// TODO: Should have dark gray border according to design
// const statusPillProps: StatusPillProps = {
//   colorName: 'graySuit',
//   isClickable: true,
//   textId: 'analysis.table.header.status',
// };

const DateCellDiv = styled(CellDiv)`
  position: static;
`;

const StyledSelect = styled(Select)`
  margin: 0;
  padding-right: 2rem;
  min-width: 5rem;
`;

const StyledCell = styled(Cell)`
  border-bottom: 1px solid ${(props) => props.theme.color.rowBorder};
  padding-left: ${(props) => props.theme.margin[24]};
  text-align: right;
`;

const StyledPicker = styled.div`
  /* stylelint-disable selector-max-class -- Easiest way to style external component */
  .DayPickerInput-OverlayWrapper {
    position: absolute;
    z-index: 20;
  }
  input {
    text-align: center;
  }
`;

type StyledWrapperProps = {
  requestState: remoteData.RemoteData<unknown>['kind'];
};

const StyledWrapper = styled.div<StyledWrapperProps>`
  input {
    width: 100%;

    ${({ requestState }) =>
      ({
        NotAsked: '',
        Loading: 'color: blue;',
        Failure: 'color: red;',
        Success: '',
      })[requestState]}
  }
`;

type StyledContainerProps = {
  invalid: boolean;
};

const StyledContainer = styled.div<StyledContainerProps>``;
