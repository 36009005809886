import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import styled, { css } from 'styled-components';

import { getArrivalByArrivalRowId } from '../../../../../store/reducers/arrival';
import { getArrivalRowById } from '../../../../../store/reducers/arrivalRow';
import {
  getVatCodes,
  getAccounts,
  getDisallowedCombinations,
} from '../../../../../store/reducers/order/options';
import { getRenderableOrderRowByArrivalRowId } from '../../../../../store/reducers/orderRow';
import { getTopicByArrivalRowId } from '../../../../../store/reducers/topic';
import { getIsOuterBarOpen } from '../../../../../store/reducers/ui';
import { getWorkPackageByArrivalRowId } from '../../../../../store/reducers/workPackage';

import {
  deleteArrivalRow,
  getDropDowns,
  updateArrivalRow,
} from '../../../../../store/actions';

import { useArrivalRowSelection } from '../../../../../hooks/ui';
import useTxt from '../../../../../hooks/useTxt';

import {
  BaseButtonWithUnderline,
  IconButton,
} from '../../../../../components/Buttons';
import DropDownSelect from '../../../../../components/DropDownSelect';
import Checkbox from '../../../../../components/Input/Checkbox';
import RemoteData from '../../../../../components/RemoteData';
import { BaseRow } from '../../../../../components/Table';

import * as big from '../../../../../utils/big';
import CAN, {
  CaslOrderRequestParams,
} from '../../../../../utils/caslUserPermissions';
import { compactDateFormat } from '../../../../../utils/format';
import { filterDisallowedCombinations } from '../../../../../utils/general';
import * as remoteData from '../../../../../utils/remoteData';

import { IconDelete } from '../../../../../assets/svg';

import { generateUrl, routes } from '../../../../../routes';

import {
  CompactCell,
  LeftPaddedCompactCell,
  RightPaddedCompactCell,
  UnitPriceCompactCell,
} from './LaariCells';

type Props = {
  arrivalRowId: string;
  isSelectable: boolean;
  isHandled?: boolean;
  projectId: string;
  crossOrder?: boolean;
};

const ArrivalRow = ({
  arrivalRowId,
  isSelectable,
  isHandled,
  projectId,
  crossOrder,
}: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const arrivalRow = useSelector(getArrivalRowById(arrivalRowId));
  const vatCodeOptions = useSelector(getVatCodes);
  const accountOptions = useSelector(getAccounts);

  const disallowedCombinations = remoteData.withDefault(
    useSelector(getDisallowedCombinations),
    []
  );
  const vatCodeOptionsWithDefault = remoteData.withDefault(vatCodeOptions, []);
  const accountOptionsWithDefault = remoteData.withDefault(accountOptions, []);

  const ability = new CaslOrderRequestParams(projectId);
  const allowedUser = CAN('write', ability);

  const orderRow = useSelector(
    getRenderableOrderRowByArrivalRowId(arrivalRowId)
  );
  const arrival = useSelector(getArrivalByArrivalRowId(arrivalRowId));

  const workPackage = useSelector(getWorkPackageByArrivalRowId(arrivalRowId));
  const topic = useSelector(getTopicByArrivalRowId(arrivalRowId));

  const [selectedArrivalRows, setSelectedArrivalRows] =
    useArrivalRowSelection();

  const selected = isSelectable ? selectedArrivalRows[arrivalRowId] : undefined;

  const onSelect = (isSelected: boolean) => {
    if (isSelectable) {
      setSelectedArrivalRows([arrivalRowId], isSelected);
    }
  };

  const missingOrderRowText = useTxt(
    'order.receiveMode.arrivalRow.missingOrderRow'
  );

  const checkBoxLabelText = useTxt(
    'order.receiveMode.arrivalRow.checkBoxLabel'
  );

  const notSelectedLabelText = useTxt(
    'order.receiveMode.arrivalRow.notSelectedLabel'
  );

  const deleteButtonLabel = useTxt(
    'order.receiveMode.arrivalRow.deleteButtonLabel'
  );

  const outerBarOpen = useSelector(getIsOuterBarOpen());

  // FIXME: You shouldn't be able to delete order rows that have been
  // used in arrivals. Now it's possible, so we have to do this check
  // to avoid a crash
  if (!arrival || !arrivalRow) {
    return (
      <StyledArrivalRow>
        <LeftPaddedCompactCell colSpan={8}>
          {missingOrderRowText}
        </LeftPaddedCompactCell>
      </StyledArrivalRow>
    );
  }

  const onClickCheckbox = () => {
    if (onSelect) {
      onSelect(!selected);
    }
  };

  const onDeleteArrivalRow = () => {
    dispatch(deleteArrivalRow({ arrivalRowId }));
  };

  const onChangeVat = (selection: string) => {
    dispatch(
      updateArrivalRow(arrivalRow.id, {
        vatCodeId: selection !== 'NONE' ? selection : null,
        accountId: arrivalRow.accountId,
      })
    );
  };

  const onChangeAccount = (selection: string) => {
    dispatch(
      updateArrivalRow(arrivalRow.id, {
        vatCodeId: arrivalRow.vatCodeId,
        accountId: selection !== 'NONE' ? selection : null,
      })
    );
  };

  const onWorkPackageClick = () => {
    if (topic) {
      const nextUrl = generateUrl({
        route: routes.ORDER_WITH_OPEN_TOPIC,
        viewMode: 'edit',
        projectId,
        orderId: topic?.orderId,
        topicId: topic?.id,
      });

      history.push(nextUrl);
    }
  };

  const selections = {
    ...(arrivalRow.vatCodeId ? { vatCodeId: arrivalRow.vatCodeId } : {}),
    ...(arrivalRow.accountId ? { accountId: arrivalRow.accountId } : {}),
  };

  const invalidSelection = disallowedCombinations.some(
    (combination) =>
      combination.accountId === arrivalRow.accountId &&
      combination.vatCodeId === arrivalRow.vatCodeId
  );

  return (
    <StyledArrivalRow
      data-testid={`testing-row-${arrivalRowId}`}
      crossOrder={crossOrder}
    >
      <LeftPaddedCompactCell align="center">
        {isSelectable ? (
          <Checkbox
            checked={selected || false}
            onChange={onClickCheckbox}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                e.stopPropagation();
                e.preventDefault();
                onClickCheckbox();
              }
            }}
            disabled={!allowedUser}
            aria-label={checkBoxLabelText}
          />
        ) : null}
      </LeftPaddedCompactCell>
      <CompactCell>{arrival.rowNumber}</CompactCell>
      <CompactCell>{orderRow?.rowNumber ?? ''}</CompactCell>
      <DescriptionCell colSpan={outerBarOpen ? 3 : 5}>
        {arrivalRow.description}
      </DescriptionCell>
      <CompactCell align="right" invalid={workPackage?.isSpecified === false}>
        <BaseButtonWithUnderline onClick={() => onWorkPackageClick()}>
          {`${workPackage?.isSpecified === false ? '*' : ''}${
            workPackage?.code ?? ''
          }`}
        </BaseButtonWithUnderline>
      </CompactCell>
      {!outerBarOpen ? (
        <>
          <CompactCell align="right">
            {big.format(arrivalRow.quantity)}
          </CompactCell>
          <CompactCell>{orderRow?.unit ?? ''}</CompactCell>
          <CompactCell align="right">
            {big.priceFormat(arrivalRow.unitPrice, 4)}
          </CompactCell>
          <UnitPriceCompactCell align="right">
            {big.priceFormat(arrivalRow.unitPrice.mul(arrivalRow.quantity))}
          </UnitPriceCompactCell>
        </>
      ) : (
        <CompactCell align="right">
          {big.priceFormat(arrivalRow.unitPrice.mul(arrivalRow.quantity))}
        </CompactCell>
      )}
      {!outerBarOpen ? (
        <CompactCell>
          {compactDateFormat.format(new Date(arrival.createdAt))}
        </CompactCell>
      ) : null}
      {arrivalRow.purchaseInvoiceHeaderId != null ? (
        // Selection shown only for arrival rows tied to a purchase invoie.
        <>
          <CompactCell>
            {!isHandled ? (
              <RemoteData
                loadingElement={<span>...</span>}
                data={vatCodeOptions}
                fetchData={getDropDowns}
              >
                {(vatCodes) => {
                  const filteredVatCodes = filterDisallowedCombinations(
                    vatCodes,
                    'vatCodeId',
                    disallowedCombinations,
                    selections
                  );

                  const filteredOptions = [
                    {
                      value: 'NONE',
                      label: notSelectedLabelText,
                      shortlabel: '-',
                    },
                    ...filteredVatCodes.map((option) => ({
                      value: option.id,
                      label: `${option.externalCode} ${option.name}`,
                      shortlabel: option.externalCode,
                    })),
                  ];

                  return (
                    <DropDownSelect
                      required
                      hideBorder
                      dropdownMatchSelectWidth={200}
                      onChange={onChangeVat}
                      defaultValue={
                        arrivalRow.vatCodeId !== null
                          ? arrivalRow.vatCodeId
                          : 'NONE'
                      }
                      value={
                        arrivalRow.vatCodeId && arrivalRow.vatCodeId !== null
                          ? arrivalRow.vatCodeId
                          : 'NONE'
                      }
                      optionLabelProp="shortlabel"
                      options={filteredOptions}
                      invalid={invalidSelection}
                    />
                  );
                }}
              </RemoteData>
            ) : (
              vatCodeOptionsWithDefault.find(
                (vatCode) => arrivalRow.vatCodeId === vatCode.id
              )?.externalCode ?? '-'
            )}
          </CompactCell>
          <CompactCell>
            {!isHandled ? (
              <RemoteData
                loadingElement={<span>...</span>}
                data={accountOptions}
                fetchData={getDropDowns}
              >
                {(accountCodes) => {
                  const filteredAccountCodes = filterDisallowedCombinations(
                    accountCodes,
                    'accountId',
                    disallowedCombinations,
                    selections
                  );

                  const filteredOptions = [
                    {
                      value: 'NONE',
                      label: notSelectedLabelText,
                      shortlabel: '-',
                    },
                    ...filteredAccountCodes.map((option) => ({
                      value: option.id,
                      label: `${option.externalCode} ${option.name}`,
                      shortlabel: option.externalCode,
                    })),
                  ];

                  return (
                    <DropDownSelect
                      required
                      hideBorder
                      dropdownMatchSelectWidth={200}
                      onChange={onChangeAccount}
                      defaultValue={arrivalRow.accountId || 'NONE'}
                      value={
                        arrivalRow.accountId && arrivalRow.accountId !== null
                          ? arrivalRow.accountId
                          : 'NONE'
                      }
                      optionLabelProp="shortlabel"
                      options={filteredOptions}
                      invalid={invalidSelection}
                    />
                  );
                }}
              </RemoteData>
            ) : (
              accountOptionsWithDefault.find(
                (accountCode) => arrivalRow.accountId === accountCode.id
              )?.externalCode ?? '-'
            )}
          </CompactCell>
        </>
      ) : null}
      <RightPaddedCompactCell align="center">
        {!isHandled ? (
          <IconButton
            icon={IconDelete}
            disabled={!allowedUser}
            onClick={onDeleteArrivalRow}
            aria-label={deleteButtonLabel}
          />
        ) : null}
      </RightPaddedCompactCell>
    </StyledArrivalRow>
  );
};

const DescriptionCell = styled(CompactCell)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

type ArrivalBaseRowProps = {
  crossOrder?: boolean;
};

export const StyledArrivalRow = styled(BaseRow)<ArrivalBaseRowProps>`
  border-bottom: 1px solid ${(props) => props.theme.color.rowBorder};
  ${(props) =>
    props.crossOrder
      ? css`
          color: ${props.theme.color.graphiteB57};
        `
      : ''}
`;

export default ArrivalRow;
