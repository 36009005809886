import React from 'react';

import * as t from 'io-ts';

import { useParams, routes } from '../routes';

import Cell, { LeftPaddedCell } from './Cell';
import ValidatedInput from './ValidatedInput';

type Alignment = 'left' | 'center' | 'right';

type InputProps<Type, Output> = {
  align: Alignment;
  initialValue: string;
  codec: t.Type<Type, Output>;
  onChange: (value: Type) => void;
  route: routes.ANALYSIS | routes.REVENUE;
  leftPadded?: boolean;
  toolTipMsg?: string;
  disabled?: boolean;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  colSpan?: number;
};

function EditableCell<Type, Output>({
  align,
  initialValue,
  codec,
  onChange,
  route,
  leftPadded,
  toolTipMsg,
  disabled,
  className,
  onClick,
  onKeyDown,
  onBlur,
  colSpan,
}: InputProps<Type, Output>) {
  const { viewMode } = useParams(route);

  const CellComponent = leftPadded ? LeftPaddedCell : Cell;

  return (
    <CellComponent align={align} className={className} colSpan={colSpan}>
      {viewMode !== 'edit' || disabled ? (
        <>{initialValue}</>
      ) : (
        <ValidatedInput
          initialValue={initialValue}
          align={align}
          onChange={onChange}
          codec={codec}
          toolTipMsg={toolTipMsg}
          onClick={onClick}
          onKeyDown={onKeyDown}
          onBlur={onBlur}
        />
      )}
    </CellComponent>
  );
}

export default EditableCell;
