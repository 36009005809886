import React from 'react';

import Big from 'big.js';
import styled, { css } from 'styled-components';

import Cell from '../../../../components/Cell';
import { BaseRow } from '../../../../components/Table';

import * as big from '../../../../utils/big';
import { mapValues } from '../../../../utils/record';

import { routes, useParams } from '../../../../routes';

import { tableColumns } from './TableHeader';

type RowProps = {
  monthlyRowId: string;
  description: string;
  netPrice: Big;
  grossPrice: Big;
  actualizedBilling: Big;
  index: number;
};

const MonthlyRow = ({
  monthlyRowId,
  description,
  netPrice,
  grossPrice,
  actualizedBilling,
  index,
}: RowProps) => {
  const aligns = mapValues(tableColumns, ({ align }) => align);

  const { viewMode } = useParams(routes.REVENUE);

  return (
    <StyledBaseRow
      index={index}
      data-testid={`monthly-revenue-row-${monthlyRowId}`}
    >
      <Cell colSpan={viewMode === 'edit' ? 6 : 5} align={aligns.description}>
        <b>{description}</b>
      </Cell>
      <Cell align={aligns.netPrice}>
        <span>
          <b>{big.priceFormat(netPrice)}</b>
        </span>
      </Cell>
      <Cell colSpan={2} align={aligns.grossPrice}>
        <b>{big.priceFormat(grossPrice)}</b>
      </Cell>
      <Cell colSpan={2} align={aligns.actualizedBilling}>
        <b>{big.priceFormat(actualizedBilling)}</b>
      </Cell>
      <Cell colSpan={4} />
    </StyledBaseRow>
  );
};

type StyledBaseRowProps = {
  index: number;
};

const StyledBaseRow = styled(BaseRow)<StyledBaseRowProps>`
  ${({ index, theme }) =>
    index !== 0
      ? css`
          /* stylelint-disable selector-max-type -- mimicked from SecondaryRow */
          & > td {
            border-top: 1px solid ${theme.color.pitch};
            border-bottom: 1px solid ${theme.color.rowBorder};
          }
        `
      : css`
          /* stylelint-disable no-duplicate-selectors -- mimicked from SecondaryRow */
          & > td {
            border-bottom: 1px solid ${theme.color.rowBorder};
          }
        `}
`;

export default MonthlyRow;
