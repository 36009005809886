import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FormikErrors, useFormik } from 'formik';
import { sortBy } from 'lodash';
import styled, { css } from 'styled-components';
import { v4 } from 'uuid';

import { APIProject } from '../../store/reducers/project';
import {
  selectAllUsers,
  selectProjectUsers,
  selectUpdateRequest,
} from '../../store/reducers/projectUsers';
import { selectCurrentUser } from '../../store/reducers/user';

import {
  fetchAllUsers,
  fetchProjectUsers,
  updateProjectUserRights,
} from '../../store/actions';

import useLocalization from '../../hooks/useLocalization';
import useRemoteData from '../../hooks/useRemoteData';
import useTxt from '../../hooks/useTxt';

import CAN, {
  CaslUserRightsRequestParams,
} from '../../utils/caslUserPermissions';
import { isPresent } from '../../utils/general';
import { isClickOrKeyboardSelection } from '../../utils/mouseOrKeyInteraction';
import { isLoading } from '../../utils/remoteData';
import { searchFilter } from '../../utils/search';

import {
  IconPersonEditor,
  IconPersonInvoice,
  IconPersonReadOnly,
} from '../../assets';
import { IconDelete, IconPersonAdd, IconPlus } from '../../assets/svg';

import defaultTheme from '../../styles/theme';
import {
  ButtonGroup,
  IconButton,
  IconTextButton,
  PrimaryButton,
  SecondaryButton,
} from '../Buttons';
import DropDownSelect from '../DropDownSelect';
import SearchInput from '../Input/SearchInput';
import { Spinner } from '../Loading';
import Modal, { Container, Content, Footer, Header } from '../Modal/Modal';
import { getUserInitials } from '../Navigation/UserSettings';
import Txt from '../Txt';
import { getAuthorColor, UserInitialsBadge } from '../UserInitialsBadge';
import AddUserModal from './AddUserModal';

interface UserRoleModalProps {
  closeModal: () => void;
  project: APIProject;
}

export const roleOptions = [
  {
    value: '4',
    label: 'common.userRights.projectInvoiceHandler' as const,
    key: '4',
    image: IconPersonInvoice,
    requiresAdmin: true,
  },
  {
    value: '2',
    label: 'common.userRights.projectEditor' as const,
    key: '2',
    image: IconPersonEditor,
    requiresAdmin: false,
  },
  {
    value: '3',
    label: 'common.userRights.readOnly' as const,
    key: '3',
    image: IconPersonReadOnly,
    requiresAdmin: false,
  },
];

type FormValues = Array<{
  id: string;
  newUser: boolean;
  userId: string | undefined;
  roles: Array<{ roleId: string | undefined; toBeDeleted?: boolean }>;
}>;

type FormValue = FormValues[number];

const UserRoleModal = ({ closeModal, project }: UserRoleModalProps) => {
  const [searchText, setSearchText] = React.useState('');
  const [isScrolled, setIsScrolled] = React.useState(false);

  const currentUser = useSelector(selectCurrentUser);

  const [addUserModalOpen, setAddUserModalOpen] =
    React.useState<boolean>(false);

  const localization = useLocalization();

  const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const inputRef = React.useRef<HTMLInputElement>(null);
  const toolbarRef = React.useRef<HTMLDivElement>(null);

  const projectCodeName = `${project.code} - ${project.name}`;

  const allUsers = useRemoteData(selectAllUsers(), fetchAllUsers()) ?? [];

  const showSpinner = isLoading(useSelector(selectUpdateRequest(project.id)));

  const projectUsers =
    useRemoteData(
      selectProjectUsers(project.id),
      fetchProjectUsers(project.id)
    ) ?? [];

  const dispatch = useDispatch();

  const removeText = useTxt('common.remove');

  React.useLayoutEffect(() => {
    if (
      inputRef.current &&
      toolbarRef.current &&
      !isScrolled &&
      projectUsers.length > 0
    ) {
      toolbarRef.current.scrollIntoView({
        block: 'center',
        behavior: 'smooth',
        inline: 'center',
      });
      inputRef.current.focus();
      setIsScrolled(true);
    }
  }, [setIsScrolled, isScrolled, projectUsers.length]);

  const validate = (values: FormValues): FormikErrors<FormValues> => {
    const errors: FormikErrors<FormValues> = [];

    values.forEach((value, index) => {
      if (!value.userId) {
        errors[index] = { userId: 'common.validation.required' };
      }

      const roleErrors: FormikErrors<{
        projectId: string | null;
        roleId: string | null;
      }>[] = [];

      value.roles.forEach((role, roleIndex) => {
        roleErrors[roleIndex] = {};

        if (!role.roleId) {
          roleErrors[roleIndex] = { ...roleErrors[index], roleId: 'error' };
        }
      });

      errors[index] = { ...errors[index], roles: roleErrors };

      if (
        errors &&
        errors?.[index] &&
        errors?.[index]?.roles &&
        roleErrors.every((role) => Object.keys(role).length === 0)
      ) {
        delete errors?.[index]?.roles;
      }
    });

    const mappedErrors: FormikErrors<FormValues> = [];

    errors.forEach((error, index) => {
      if (error && Object.keys(error).length > 0) {
        mappedErrors[index] = error;
      }
    });

    return mappedErrors;
  };

  const formik = useFormik<FormValues>({
    initialValues: [],
    validate,
    validateOnMount: true,
    onSubmit: (values) => {
      // make sure these are validated
      const mappedValues = values.map((value) => {
        const mappedRoles = value.roles
          .map((role) => {
            return {
              roleId: role.roleId ?? '',
              validFrom: null,
              validTo: null,
              isDeleted: role.toBeDeleted ?? false,
            };
          })
          .filter((role) => role.roleId !== '');

        return { userId: value.userId ?? '', roles: mappedRoles };
      });
      dispatch(updateProjectUserRights(project.id, { data: mappedValues }));
      formik.resetForm();
    },
  });

  if (!currentUser) {
    return null;
  }

  // if user can write own user rights, they are admin
  // might change in future?
  const ability = new CaslUserRightsRequestParams(currentUser.id);
  const isAdmin = CAN('write', ability);

  const filteredProjectUsers = searchFilter(projectUsers, searchText, [
    'name',
    'emails',
  ]);

  const sortedAndFiltered = sortBy(filteredProjectUsers, (entry) => entry.name);

  const onAddUser = (
    e: React.KeyboardEvent<HTMLButtonElement> | React.MouseEvent
  ) => {
    if (isClickOrKeyboardSelection(e)) {
      e.preventDefault();

      return setAddUserModalOpen(true);
    }
  };

  const addNewProjectUser = (
    e: React.KeyboardEvent<HTMLButtonElement> | React.MouseEvent
  ) => {
    if (isClickOrKeyboardSelection(e)) {
      e.preventDefault();

      const newUser: FormValue = {
        id: v4(),
        newUser: true,
        userId: undefined,
        roles: [],
      };

      formik.setValues([...formik.values, newUser]);
    }
  };

  const removeNewProjectUser = (id: string) => {
    const filteredNewUsers = formik.values.filter((value) => value.id !== id);

    formik.setValues(filteredNewUsers);
  };

  const roleOptionsWithLabel = roleOptions.map((role) => ({
    value: role.value,
    key: role.key,
    label: (
      <RoleOptionDiv disabled={!isAdmin && role.requiresAdmin}>
        <StyledRoleIcon src={role.image} alt={`${role.label}.picture`} />
        <Txt id={role.label} component="b" />
        <span />
        <Txt id={`${role.label}.description` as const} />
      </RoleOptionDiv>
    ),
    shortlabel: localization.formatMessage({ id: role.label }),
    disabled: !isAdmin && role.requiresAdmin,
  }));

  const userOptions = allUsers.map((user) => ({
    value: user.id,
    label:
      user.name.length > 0
        ? `${user.name} (${user.emails[0]})`
        : user.emails[0],
    key: user.id,
  }));

  const filteredUserOptions = userOptions
    .filter(
      (option) => !projectUsers.map((user) => user.id).includes(option.value)
    )
    .sort((a, b) => a.label.localeCompare(b.label));

  const selectedUser = (userId: string | undefined) =>
    userOptions.filter((p) => p.value === userId);

  const existingUserRoleChange = (value: string, userId: string) => {
    const existingSavedRolesForProject = projectUsers
      .find((user) => user.id === userId)
      ?.roles?.filter((role) => role.projectId === project.id)
      ?.map((role) => role.roleId)
      ?.filter(isPresent);

    const formikUsers = formik.values.map((formValue) => formValue.userId);

    let toBeDeleted: { roleId: string; toBeDeleted: boolean }[] = [];

    if (
      existingSavedRolesForProject &&
      existingSavedRolesForProject.length > 0
    ) {
      toBeDeleted = existingSavedRolesForProject.map((role) => ({
        roleId: role,
        toBeDeleted: true,
      }));
    }

    // replace if changes already in formik
    const newValues = formik.values.map((formValue) => {
      if (formValue.userId === userId) {
        // no need to update if existing role is the same
        if (existingSavedRolesForProject?.includes(value)) {
          return null;
        }

        if (toBeDeleted.length > 0) {
          return { ...formValue, roles: [...toBeDeleted, { roleId: value }] };
        }

        return { ...formValue, roles: [{ roleId: value }] };
      }

      return formValue;
    });

    if (!formikUsers.includes(userId)) {
      const newRoles =
        toBeDeleted.length > 0
          ? [...toBeDeleted, { roleId: value }]
          : [{ roleId: value }];

      newValues.push({
        id: v4(),
        userId,
        newUser: false,
        roles: newRoles,
      });
    }

    const valuesWithoutNull = newValues.filter(isPresent);

    // extra check for admin rights
    if (
      !isAdmin &&
      valuesWithoutNull
        .map((formikValue) => formikValue.roles)
        .flat()
        .some(
          (role) =>
            roleOptions.find((option) => role.roleId === option.key)
              ?.requiresAdmin
        )
    ) {
      return;
    }

    formik.setValues(valuesWithoutNull);
  };

  const removeExistingUserRights = (userId: string) => {
    const existingSavedRolesForProject = projectUsers
      .find((user) => user.id === userId)
      ?.roles?.filter((role) => role.projectId === project.id)
      ?.map((role) => role.roleId)
      ?.filter(isPresent);

    const formikWithoutUser = formik.values.filter(
      (formValue) => formValue.userId !== userId
    );

    let toBeDeleted: { roleId: string; toBeDeleted: boolean }[] = [];

    if (
      existingSavedRolesForProject &&
      existingSavedRolesForProject.length > 0
    ) {
      toBeDeleted = existingSavedRolesForProject.map((role) => ({
        roleId: role,
        toBeDeleted: true,
      }));
    }

    formik.setValues([
      ...formikWithoutUser,
      { id: v4(), newUser: false, userId, roles: toBeDeleted },
    ]);
  };

  const setValueForNewUser = (
    value: string,
    id: string,
    field: 'roleId' | 'userId'
  ) => {
    if (
      !isAdmin &&
      field === 'roleId' &&
      roleOptions.find((option) => option.key === value)?.requiresAdmin
    ) {
      return;
    }

    const newValues = formik.values.map((formValue) => {
      if (formValue.id === id && field === 'roleId') {
        return {
          ...formValue,
          roles: [{ ...formValue.roles[0], roleId: value }],
        };
      }

      if (formValue.id === id && field === 'userId') {
        return {
          ...formValue,
          userId: value,
        };
      }

      return formValue;
    });

    formik.setValues(newValues);
  };

  const onCancelButtonClick = () => {
    const shouldCloseModal = formik.values.length === 0;

    formik.resetForm();

    if (shouldCloseModal) {
      closeModal();
    }
  };

  return (
    <>
      {addUserModalOpen ? (
        <AddUserModal
          closeModal={() => setAddUserModalOpen(false)}
          defaultProjectToAdd={project}
        />
      ) : null}
      <Modal onClose={closeModal} blockEscapeKey={addUserModalOpen}>
        <StyledContainer noShadow>
          <StyledForm onSubmit={formik.handleSubmit}>
            <Header>
              <Txt
                id="navigation.projectMenu.userManagement.modal.header"
                values={{ projectCodeName }}
              />
            </Header>
            <StyledContent>
              <Column>
                <SearchAndInviteDiv>
                  <StyledSearchInput
                    data-testid="user-roles-search-input"
                    value={searchText}
                    onChange={onSearchInputChange}
                    handleClearButtonClick={() => setSearchText('')}
                    ref={inputRef}
                  />
                  {isAdmin ? (
                    <StyledIconTextButton
                      icon={IconPersonAdd}
                      type="button"
                      onClick={onAddUser}
                      onKeyPress={onAddUser}
                    >
                      <Txt id="navigation.projectMenu.userManagement.inviteNewUser" />
                    </StyledIconTextButton>
                  ) : null}
                </SearchAndInviteDiv>
                {sortedAndFiltered.map((user) => {
                  const authorColor = getAuthorColor(user.name);

                  const formikValue = formik.values
                    .find((value) => value.userId === user.id)
                    ?.roles.find((role) => role.toBeDeleted !== true)?.roleId;

                  const formikDelete = formik.values
                    .find((value) => value.userId === user.id)
                    ?.roles.find((role) => role.toBeDeleted === true);

                  const value = formikValue ?? user.roles[0].roleId;

                  const requiresAdmin = roleOptions.find(
                    (option) => option.key === value
                  )?.requiresAdmin;

                  const formikChangeType = () => {
                    if (formikValue) {
                      return 'change';
                    }

                    if (formikDelete) {
                      return 'delete';
                    }

                    return undefined;
                  };

                  return (
                    <ProjectUserDiv
                      key={user.id}
                      formikChanges={formikChangeType()}
                      data-testid={`existing-project-user-${user.id}-div`}
                    >
                      <Row>
                        <UserInitialsBadge
                          backgroundColor={authorColor}
                          disabled
                        >
                          {getUserInitials(user.name)}
                        </UserInitialsBadge>

                        <UserNameAndEmailDiv>
                          <UserNameSpan>{user.name}</UserNameSpan>
                          <span>{user.emails[0]}</span>
                        </UserNameAndEmailDiv>
                      </Row>
                      <Row>
                        <DropDownSelect
                          additionalContainerStyles={
                            containerStylingForRoleDropdown
                          }
                          value={value ?? undefined}
                          onChange={(selectedValue) =>
                            existingUserRoleChange(selectedValue, user.id)
                          }
                          label={`user-${user.id}-roleId-select`}
                          id={`user-${user.id}-roleId-select`}
                          optionLabelProp="shortlabel"
                          options={roleOptionsWithLabel}
                          listHeight={400}
                          disabled={!isAdmin && requiresAdmin}
                          required
                        />
                        <IconButton
                          icon={IconDelete}
                          onClick={() => removeExistingUserRights(user.id)}
                          aria-label={removeText}
                          type="button"
                          disabled={!isAdmin && requiresAdmin}
                        />
                      </Row>
                    </ProjectUserDiv>
                  );
                })}
                {formik.values
                  .filter((value) => value.newUser === true)
                  .map((newUserEntry) => {
                    const formikValue = formik.values.find(
                      (value) => value.id === newUserEntry.id
                    );

                    return (
                      <ProjectUserDiv
                        key={`new-project-user-${newUserEntry.id}`}
                        formikChanges="add"
                        data-testid={`new-project-user-${newUserEntry.id}-div`}
                      >
                        <DropDownSelect
                          additionalContainerStyles={
                            containerStylingForUserDropdown
                          }
                          label={`newUser-${newUserEntry.id}-userId-select`}
                          value={formikValue?.userId}
                          onChange={(value) =>
                            setValueForNewUser(value, newUserEntry.id, 'userId')
                          }
                          id={`newUser-${newUserEntry.id}-userId-select`}
                          options={[
                            ...selectedUser(formikValue?.userId),
                            ...filteredUserOptions,
                          ]}
                          required
                          listHeight={400}
                        />
                        <Row>
                          <DropDownSelect
                            additionalContainerStyles={
                              containerStylingForRoleDropdown
                            }
                            value={formikValue?.roles[0]?.roleId}
                            label={`newUser-${newUserEntry.id}-roleId-select`}
                            onChange={(value) =>
                              setValueForNewUser(
                                value,
                                newUserEntry.id,
                                'roleId'
                              )
                            }
                            id={`newUser-${newUserEntry.id}-roleId-select`}
                            optionLabelProp="shortlabel"
                            options={roleOptionsWithLabel}
                            listHeight={400}
                            required
                          />
                          <IconButton
                            icon={IconDelete}
                            onClick={() =>
                              removeNewProjectUser(newUserEntry.id)
                            }
                            aria-label={removeText}
                          />
                        </Row>
                      </ProjectUserDiv>
                    );
                  })}
                <Toolbar ref={toolbarRef}>
                  <IconTextButton
                    icon={IconPlus}
                    id="userRolesModal.button.newUser"
                    type="button"
                    onClick={addNewProjectUser}
                    onKeyPress={addNewProjectUser}
                  >
                    <Txt id="navigation.projectMenu.userManagement.addUser" />
                  </IconTextButton>
                </Toolbar>
              </Column>
            </StyledContent>
            <Footer>
              <ButtonGroup>
                <CancelButton onClick={onCancelButtonClick}>
                  <Txt id="common.cancel" />
                </CancelButton>
                <ActionButton
                  type="submit"
                  disabled={
                    !formik.isValid || showSpinner || formik.values.length === 0
                  }
                >
                  {showSpinner ? (
                    <Spinner size="1rem" />
                  ) : (
                    <Txt id="common.save" />
                  )}
                </ActionButton>
              </ButtonGroup>
            </Footer>
          </StyledForm>
        </StyledContainer>
      </Modal>
    </>
  );
};

export default UserRoleModal;

const StyledForm = styled.form`
  margin-bottom: ${(props) => props.theme.margin[16]};
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
`;

const StyledContent = styled(Content)`
  padding: 0 ${({ theme }) => `${theme.margin[32]}`};
  min-height: 70vh;
  max-height: 70vh;
  overflow-y: scroll;
`;

type ProjectUserDivProps = {
  formikChanges?: 'change' | 'delete' | 'add';
};

const ProjectUserDiv = styled.div<ProjectUserDivProps>`
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: 1px solid ${({ theme: { color } }) => color.dropdownBorder};

  padding: 1rem;

  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  ${({ formikChanges }) => {
    switch (formikChanges) {
      case 'change':
        return css`
          background: ${({ theme }) => theme.color.statusColors.delayed};
        `;
      case 'delete':
        return css`
          background: ${({ theme }) => theme.color.statusColors.obstacle};
        `;
      case 'add':
        return css`
          background: ${({ theme }) => theme.color.statusColors.done};
        `;
      default:
        return null;
    }
  }}
`;

const StyledContainer = styled(Container)`
  min-width: 55rem;
`;

const UserNameAndEmailDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const UserNameSpan = styled.span`
  font-size: ${({ theme }) => theme.fontSize.h3};
  font-weight: 600;
`;

const CancelButton = styled(SecondaryButton)`
  margin: 0 ${({ theme }) => `${theme.margin[8]}`};
`;

const ActionButton = styled(PrimaryButton)`
  margin-right: 4px;
  margin-left: 4px;
`;

const SearchAndInviteDiv = styled.div`
  position: sticky;
  top: 0;

  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: 1px solid ${({ theme: { color } }) => color.dropdownBorder};

  padding: ${(props) => props.theme.margin[16]}
    ${(props) => props.theme.margin[8]} ${(props) => props.theme.margin[8]};

  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  background: white;

  z-index: 1;
`;

const StyledSearchInput = styled(SearchInput)`
  width: 20rem;
  align-self: flex-start;
`;

const StyledIconTextButton = styled(IconTextButton)`
  margin-bottom: ${(props) => props.theme.margin[8]};

  border-radius: ${(props) => props.theme.margin[24]};
  border: 1px solid rgba(0, 0, 0, 0);

  padding: ${(props) => props.theme.margin[16]};

  height: ${(props) => props.theme.margin[32]};
  min-width: 6.5rem;

  justify-content: center;

  background: ${(props) => props.theme.color.graphiteB96A};
`;

type RoleOptionDivProps = {
  disabled?: boolean;
};

export const RoleOptionDiv = styled.div<RoleOptionDivProps>`
  display: grid;

  overflow: hidden;

  justify-items: start;

  grid-template-columns: 3rem 19rem;
  grid-template-rows: 1.5rem 3rem;

  ${({ disabled }) =>
    disabled
      ? css`
          opacity: 0.2;
          cursor: not-allowed;
        `
      : null}
`;

const Toolbar = styled.div`
  margin: ${(props) => `${props.theme.margin[24]}`};

  width: 100%;

  display: flex;
  align-items: left;
  justify-content: left;
`;

const containerStylingForUserDropdown: React.CSSProperties = {
  width: '20rem',
  height: defaultTheme.margin[36],
  margin: `${defaultTheme.margin[4]} 0 ${defaultTheme.margin[4]} ${defaultTheme.margin[8]}`,
};

export const containerStylingForRoleDropdown: React.CSSProperties = {
  width: '25rem',
  height: defaultTheme.margin[36],
  margin: `${defaultTheme.margin[4]} 0 ${defaultTheme.margin[4]} ${defaultTheme.margin[8]}`,
};

export const StyledRoleIcon = styled.img`
  margin-top: ${({ theme }) => theme.margin[8]};
  width: ${({ theme }) => theme.margin[40]};
  height: ${({ theme }) => theme.margin[40]};
`;
