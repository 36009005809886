import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useFormik, FormikErrors } from 'formik';
import styled from 'styled-components';

import {
  getWorkPackageById,
  getWorkPackageCodes,
} from '../../../store/reducers/workPackage';

import {
  updateWorkSection,
  getWorkPackageTimelineForProject,
  getWorkPackageGroupTimelineForProject,
  getProjectTimelineForProject,
} from '../../../store/actions';

import { APIWorkPackagePutBody } from '../../../types/api';

import useTxt from '../../../hooks/useTxt';

import {
  CenteredButtonGroup,
  SecondaryButton,
  PrimaryButton,
} from '../../../components/Buttons';
import { DatePicker } from '../../../components/DatePickerInput';
import TextInput from '../../../components/Input/TextInput';
import { Spinner } from '../../../components/Loading';
import Tooltip from '../../../components/Tooltip';
import Txt from '../../../components/Txt';

import { uniqueCode } from '../../../utils/decoders';
import { MAX_YEARS_FROM_NOW } from '../../../utils/general';

import { IconInfo } from '../../../assets';

import { routes, useParams } from '../../../routes';

type EditWPProps = {
  workPackageId: string;
  onClose: () => void;
  hasScheduledTasks: boolean;
};

const EditWorkPackageForm = ({
  workPackageId,
  onClose,
  hasScheduledTasks,
}: EditWPProps) => {
  const isRequired = useTxt('form.inputError.isRequired');
  const codeAlreadyExists = useTxt('form.inputError.codeAlreadyExists');
  const maxLength = useTxt('form.inputError.maxLength');
  const startDateError = useTxt('form.inputError.startDateError');
  const endDateError = useTxt('form.inputError.endDateError');

  const maxDateError = useTxt('form.inputError.maxDateError', {
    yearsFromNow: MAX_YEARS_FROM_NOW,
  });

  const { projectId } = useParams(routes.WORKSECTION_EXPANDED);

  const dispatch = useDispatch();

  const workPackage = useSelector(getWorkPackageById(workPackageId));

  const workPackageCodes = useSelector(
    getWorkPackageCodes(workPackage?.workPackageGroupId || '')
  );

  type FormValues = {
    name: string;
    code: string;
    startDate: string;
    endDate: string;
  };

  const onEditSuccess = () => {
    dispatch(getWorkPackageTimelineForProject({ projectId }));
    dispatch(getWorkPackageGroupTimelineForProject({ projectId }));
    dispatch(getProjectTimelineForProject({ projectId }));
    onClose();
  };

  const validate = (values: FormValues): FormikErrors<FormValues> => {
    const errors: FormikErrors<FormValues> = {};

    if (values.name === '') {
      errors.name = isRequired;
    }

    if (values.code === '') {
      errors.code = isRequired;
    }

    if (
      !uniqueCode(workPackageCodes).is(values.code) &&
      values.code !== workPackage?.code
    ) {
      errors.code = codeAlreadyExists;
    }

    if (values.startDate === '' && !hasScheduledTasks) {
      errors.startDate = isRequired;
    }

    if (values.endDate === '' && !hasScheduledTasks) {
      errors.endDate = isRequired;
    }

    if (values.endDate && values.startDate) {
      if (values.endDate < values.startDate) {
        errors.endDate = endDateError;
        errors.startDate = startDateError;
      }

      const today = new Date();

      const maxYearsFromNow = new Date(
        today.setFullYear(today.getFullYear() + MAX_YEARS_FROM_NOW)
      );
      const endDateAsDate = new Date(values.endDate);

      if (endDateAsDate > maxYearsFromNow) {
        errors.endDate = maxDateError;
      }
    }

    if (values.name.length > 200) {
      errors.name = maxLength;
    }

    return errors;
  };

  const formik = useFormik<FormValues>({
    initialValues: {
      name: workPackage?.name ? workPackage.name : '',
      code: workPackage?.code ? workPackage?.code : '',
      startDate: workPackage?.startDate ? workPackage.startDate : '',
      endDate: workPackage?.endDate ? workPackage.endDate : '',
    },
    validate,
    onSubmit: (values) => {
      const body: APIWorkPackagePutBody = {
        name: values.name,
        code: values.code,
        startDate: new Date(values.startDate),
        endDate: new Date(values.endDate),
        updatedAt: new Date().toISOString(),
      };

      dispatch(updateWorkSection(workPackageId, body, onEditSuccess));
    },
  });

  // setting date pickers
  const { setFieldValue } = formik;

  const workpackageCode = useTxt('worksection.workpackage.code');
  const workpackageName = useTxt('worksection.workpackage.name');
  const startDate = useTxt('worksection.workpackage.startDate');
  const endDate = useTxt('worksection.workpackage.endDate');

  const wpDatesTooltipInfo = useTxt(
    'worksection.workpackage.wpDatesTooltipInfo'
  );

  return (
    <StyledForm name="edit-wp-form" onSubmit={formik.handleSubmit}>
      <FormContent>
        <TextInput
          label={workpackageName}
          name="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          disabled={formik.isSubmitting}
          errorMessage={formik.errors.name}
        />
        <TextInput
          label={workpackageCode}
          name="code"
          value={formik.values.code}
          onChange={formik.handleChange}
          disabled={formik.isSubmitting}
          errorMessage={formik.errors.code}
        />
        {/* Workpackage Category should be here when we have data... */}
        {/* <StyledDropDownName /> */}
        <StyledDatePickers hasScheduledTasks={hasScheduledTasks}>
          <DatePicker
            name="startDate"
            date={new Date(formik.values.startDate)}
            label={startDate}
            disabled={hasScheduledTasks}
            onDayChange={(val) => setFieldValue('startDate', val)}
            errorMessage={formik.errors.startDate}
          />
          <DatePicker
            name="endDate"
            date={new Date(formik.values.endDate)}
            disabled={hasScheduledTasks}
            label={endDate}
            onDayChange={(val) => setFieldValue('endDate', val)}
            errorMessage={formik.errors.endDate}
          />
          <Tooltip tip={wpDatesTooltipInfo} className="wp-info-tooltip">
            <Icon src={IconInfo} alt="altText" />
          </Tooltip>
          {hasScheduledTasks ? (
            <DisabledInfo>
              <Txt id="worksection.workpackage.disabledDatesInfo" />
            </DisabledInfo>
          ) : null}
        </StyledDatePickers>
      </FormContent>
      <StyledButtonGroup>
        <SecondaryButton type="button" onClick={onClose}>
          <Txt id="common.cancel" />
        </SecondaryButton>
        <PrimaryButton
          type="submit"
          disabled={
            formik.isSubmitting ||
            (!uniqueCode(workPackageCodes).is(formik.values.code) &&
              formik.values.code !== workPackage?.code)
          }
        >
          {formik.isSubmitting ? (
            <Spinner size="1rem" light />
          ) : (
            <Txt id="common.save" />
          )}
        </PrimaryButton>
      </StyledButtonGroup>
    </StyledForm>
  );
};

export default EditWorkPackageForm;

const StyledForm = styled.form`
  height: 10rem;
`;

const FormContent = styled.div`
  padding-top: 0.5rem;
  display: flex;
  gap: 1rem;

  /* stylelint-disable selector-max-type -- todo easier to read */

  input[name='name'] {
    min-width: 20rem;
  }

  input[name='code'] {
    min-width: 5rem;
  }

  label {
    white-space: nowrap;
  }
`;

type StyledDatePickersProps = {
  hasScheduledTasks: boolean;
};

const StyledDatePickers = styled.div<StyledDatePickersProps>`
  position: relative;

  padding: ${({ theme }) => theme.margin[16]};

  display: flex;

  color: ${({ theme, hasScheduledTasks }) =>
    hasScheduledTasks ? theme.color.buttonPrimaryDisabled : 'none'};

  gap: 1rem;

  input {
    border: 1px solid ${(props) => props.theme.color.inputBorder};
    border-radius: ${(props) => props.theme.margin[4]};
    padding: ${(props) => props.theme.margin[10]};
  }
`;

const DisabledInfo = styled.div`
  position: absolute;
  bottom: 1rem;
`;

const StyledButtonGroup = styled(CenteredButtonGroup)`
  position: absolute;
  left: 0;
  bottom: 0;

  box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.15);

  padding: ${(props) => props.theme.margin[18]};

  width: 100%;

  justify-content: right;
`;

const Icon = styled.img`
  border-radius: 50%;
  width: ${({ theme }) => theme.margin[18]};
  height: ${({ theme }) => theme.margin[18]};
  background-color: ${({ theme }) => theme.color.black};
`;
