import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useUpdateEffect } from 'react-use';

import styled, { css } from 'styled-components';

import {
  getNextStatusId,
  toRenderableOrderRow,
} from '../../../../../../store/reducers/orderRow';
import {
  getOrderRowTargetRows,
  toTargetOrderRow,
} from '../../../../../../store/reducers/target/targetRows';
import {
  getIsOuterBarOpen,
  getSelectedOrderRowsForOrder,
} from '../../../../../../store/reducers/ui';

import * as Actions from '../../../../../../store/actions';

import { APIOrderRow, APIOrderRowPutBody } from '../../../../../../types/api';
import { ViewModeOptions } from '../../../../../../types/general';

import useRemoteData from '../../../../../../hooks/useRemoteData';
import useTxt from '../../../../../../hooks/useTxt';

import { TagsContainer } from '../../../../../../components/Analysis/AnalysisTags';
import { IconButton } from '../../../../../../components/Buttons';
import Cell from '../../../../../../components/Cell';
import {
  OrderRowLeftBorderContainer,
  OrderRowRightPaddingContainer,
  OrderRowSmallerRightPaddingContainer,
} from '../../../../../../components/Containers';
import Checkbox from '../../../../../../components/Input/Checkbox';
import TableTextInput from '../../../../../../components/Input/TableTextInput';
import { SecondaryRow } from '../../../../../../components/Table';
import Txt from '../../../../../../components/Txt';
import AnalysisTags from '../../../../components/AnalysisTags';
import StatusPill from '../../../../components/StatusPill';
import { TableErrorNotification } from '../../../../components/TableErrorNotification';
import TargetedRow, {
  TargetCell,
} from '../../../../components/Target/TargetedRow';

import {
  EditableOrderRowState,
  ValidationErrorState,
  initialValidationErrorState,
  constructPutRequest,
  validateField,
  MAX_DESCRIPTION_LENGTH,
  MAX_UNIT_LENGTH,
} from './utils';
import * as big from '../../../../../../utils/big';
import { useDebounce } from '../../../../../../utils/hooks';

import { IconDelete } from '../../../../../../assets/svg';

type Props = {
  orderRow: APIOrderRow;
  isSelected?: boolean;
  onChoose: (id: string) => void;
  className?: string;
  viewModeOptions: ViewModeOptions;
};

const EditableOrderRow = ({
  orderRow: originalOrderRow,
  isSelected,
  onChoose,
  className,
  viewModeOptions,
}: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const rowRef = useRef<HTMLTableRowElement>(null);
  const [isScrolled, setScrolled] = useState(false);

  // rows are ordered based on rowNo -> scroll into view if there is a change
  const [rowNumberState, setRowNumberState] = useState(
    originalOrderRow.rowNumber
  );

  const memoizedRowNumberState = React.useMemo(
    () => rowNumberState,
    [rowNumberState]
  );

  const { hash } = history.location;
  const outerBarOpen = useSelector(getIsOuterBarOpen());

  const { remainingAmount, status } = toRenderableOrderRow(
    originalOrderRow,
    outerBarOpen ? 2 : undefined
  );

  const isTargetRowSelectionDisabled =
    useSelector(getSelectedOrderRowsForOrder(originalOrderRow.orderId)).length >
    0;

  const nextStatusId = useSelector(getNextStatusId(originalOrderRow.id));

  const targetRows =
    useRemoteData(
      getOrderRowTargetRows({
        orderId: originalOrderRow.orderId,
        orderRowId: originalOrderRow.id,
      }),
      Actions.requestTargetRows({ orderId: originalOrderRow.orderId })
    ) ?? [];

  const targetOrderRow = toTargetOrderRow(originalOrderRow, targetRows);

  useEffect(() => {
    if (isScrolled) {
      return;
    }

    // TODO: find a way to define this in routes.ts
    if (hash !== `#orderRowId-${originalOrderRow.id}`) {
      return;
    }

    if (rowRef.current) {
      rowRef.current.scrollIntoView({ block: 'center' });
      setScrolled(true);
    }
  }, [isScrolled, hash, originalOrderRow.id]);

  useEffect(() => {
    if (
      rowRef.current &&
      memoizedRowNumberState !== originalOrderRow.rowNumber
    ) {
      rowRef.current.scrollIntoView({ block: 'center' });
      setRowNumberState(originalOrderRow.rowNumber);
    }
  }, [memoizedRowNumberState, originalOrderRow.rowNumber]);

  // This is the state we keep actual content of the input fields, no matter if
  // they are invalid or not.
  const [orderRow, setOrderRow] = useState<EditableOrderRowState>({
    status: originalOrderRow.statusId,
    description: originalOrderRow.description || '',
    quantity: big.toInputString(originalOrderRow.quantity),
    unit: originalOrderRow.unit || '',
    unitPrice: big.toInputString(originalOrderRow.unitPrice),
    rowNumber: originalOrderRow.rowNumber.toString(),
  });

  // Duplicate the input field state, but this time we only save valid values
  // here. This is used for syncing the values to backend, so we want to keep
  // the last valid value of each field here to enable updates to backend even
  // if some field on the row is invalid.
  const [validatedOrderRow, setValidatedOrderRow] = useState(orderRow);

  const [isFlushed, setFlushed] = useState(true);

  // State to keep track of validation errors. Null means no error, otherwise
  // contains the error as a string.
  const [validationError, setValidationError] = useState<ValidationErrorState>(
    initialValidationErrorState
  );

  // Use our custom debounce hook to create a debounced function to update
  // backend and parent state. This gets executed when it has not been called
  // again for 1 second or when the component unmounts.
  const debouncedUpdate = useDebounce((data: APIOrderRowPutBody) => {
    dispatch(Actions.updateOrderRow(originalOrderRow.id, data));
  }, 1000);

  // Listen to changes in validatedOrderRow and update the new values to
  // backend using debounced request.
  useUpdateEffect(() => {
    debouncedUpdate(
      constructPutRequest(validatedOrderRow, originalOrderRow.updatedAt)
    );
  }, [validatedOrderRow]);

  useUpdateEffect(() => {
    if (!isFlushed) {
      debouncedUpdate.flush();
      setFlushed(true);
    }
  }, [isFlushed]);

  const onDeleteOrderRow = () => {
    dispatch(Actions.deleteOrderRow(originalOrderRow.id));
  };

  const onInputChange =
    (field: keyof EditableOrderRowState) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setOrderRow((state) => ({ ...state, [field]: value }));

      const error = validateField(field, value, originalOrderRow);

      if (error === null) {
        setValidatedOrderRow((state) => ({ ...state, [field]: value }));

        // If there was a previous validation error, reset it.
        if (validationError[field] !== null) {
          setValidationError((state) => ({ ...state, [field]: null }));
        }
      } else {
        setValidationError((state) => ({ ...state, [field]: error }));
      }
    };

  const onSelection = () => {
    if (viewModeOptions.showTargetRows && targetRows.length > 0) {
      const targetRowIds = targetRows.map((row) => row.id);
      dispatch(
        Actions.toggleMultipleTargetRowSelection({
          orderId: originalOrderRow.orderId,
          targetRowIds,
          chooseAll: !isSelected,
        })
      );
    }

    onChoose(originalOrderRow.id);
  };

  const { isEditable } = originalOrderRow;
  const selectText = useTxt('orderRow.inputs.SelectRow');
  const titleText = useTxt('orderRow.inputs.Title');
  const amountText = useTxt('orderRow.inputs.Amount');
  const unitText = useTxt('orderRow.inputs.Unit');
  const priceText = useTxt('orderRow.inputs.UnitPrice');
  const rowNumberText = useTxt('orderRow.inputs.rowNumber');
  const removeText = useTxt('order.inputs.Remove');

  const isDeletable = originalOrderRow.isRemovable && isEditable;

  const statusToggle = () => {
    const newValue = nextStatusId ?? originalOrderRow.statusId;

    setOrderRow((state) => ({
      ...state,
      status: newValue,
    }));

    const error = validateField('status', newValue, originalOrderRow);

    if (error === null) {
      setValidatedOrderRow((state) => ({ ...state, status: newValue }));

      // If there was a previous validation error, reset it.
      if (validationError.status !== null) {
        setValidationError((state) => ({ ...state, status: null }));
      }

      setFlushed(false);
    } else {
      setValidationError((state) => ({ ...state, status: error }));
    }
  };

  return (
    <>
      <SecondaryRow
        ref={rowRef}
        data-testid={`order-row-${originalOrderRow.id}`}
        className={className}
      >
        <StyledCell align="center">
          <Checkbox
            disabled={!isEditable}
            checked={isSelected}
            aria-label={selectText}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                e.stopPropagation();
                e.preventDefault();
                onSelection();
              }
            }}
            onChange={(e) => {
              e.stopPropagation();
              onSelection();
            }}
          />
        </StyledCell>
        <StyledCell outerBarOpen={outerBarOpen}>
          <TableTextInput
            align="right"
            disabled={!isEditable}
            aria-label={rowNumberText}
            onChange={onInputChange('rowNumber')}
            value={orderRow.rowNumber}
            invalid={validationError.rowNumber !== null}
            onBlur={() => debouncedUpdate.flush()}
          />
          {validationError.rowNumber ? (
            <TableErrorNotification position="right">
              <Txt id={validationError.rowNumber} />
            </TableErrorNotification>
          ) : null}
        </StyledCell>
        <StyledCell outerBarOpen={outerBarOpen}>
          <TableTextInput
            disabled={!isEditable}
            aria-label={titleText}
            onChange={onInputChange('description')}
            value={orderRow.description}
            invalid={validationError.description !== null}
            onBlur={() => debouncedUpdate.flush()}
          />
          {validationError.description ? (
            <TableErrorNotification>
              <Txt
                id={validationError.description}
                values={{ value: MAX_DESCRIPTION_LENGTH }}
              />
            </TableErrorNotification>
          ) : null}
        </StyledCell>
        <StyledCell align="center" outerBarOpen={outerBarOpen}>
          <TableTextInput
            align="right"
            disabled={!isEditable}
            aria-label={amountText}
            onChange={onInputChange('quantity')}
            value={orderRow.quantity}
            invalid={validationError.quantity !== null}
            onBlur={() => debouncedUpdate.flush()}
          />
          {validationError.quantity ? (
            <TableErrorNotification>
              <Txt id={validationError.quantity} />
            </TableErrorNotification>
          ) : null}
        </StyledCell>
        {outerBarOpen ? null : (
          <StyledCell align="center">
            <TableTextInput
              align="center"
              disabled={!isEditable}
              aria-label={unitText}
              onChange={onInputChange('unit')}
              value={orderRow.unit}
              invalid={validationError.unit !== null}
              onBlur={() => debouncedUpdate.flush()}
            />
            {validationError.unit ? (
              <TableErrorNotification>
                <Txt
                  id={validationError.unit}
                  values={{ value: MAX_UNIT_LENGTH }}
                />
              </TableErrorNotification>
            ) : null}
          </StyledCell>
        )}
        <StyledCell outerBarOpen={outerBarOpen}>
          <TableTextInput
            align="right"
            disabled={!isEditable || !originalOrderRow.isPriceEditable}
            aria-label={priceText}
            onChange={onInputChange('unitPrice')}
            value={orderRow.unitPrice}
            invalid={validationError.unitPrice !== null}
            onBlur={() => debouncedUpdate.flush()}
          />
          {validationError.unitPrice ? (
            <TableErrorNotification>
              <Txt id={validationError.unitPrice} />
            </TableErrorNotification>
          ) : null}
        </StyledCell>
        {viewModeOptions.showTargetRows ? (
          <TargetCell align="right">
            {targetOrderRow.target.eq(0)
              ? ''
              : big.priceFormatRounded(
                  targetOrderRow.target,
                  outerBarOpen ? 0 : undefined
                )}
          </TargetCell>
        ) : null}
        <StyledCell
          align="right"
          outerBarOpen={outerBarOpen}
          contentContainer={
            viewModeOptions.showTargetRows || outerBarOpen
              ? OrderRowSmallerRightPaddingContainer
              : OrderRowRightPaddingContainer
          }
        >
          {orderRow.unitPrice !== '' &&
          orderRow.quantity !== '' &&
          !validationError.unitPrice &&
          !validationError.quantity
            ? big.priceFormatRounded(
                big
                  .fromInputString(validatedOrderRow.unitPrice, 0)
                  .mul(big.fromInputString(validatedOrderRow.quantity, 0)),
                outerBarOpen ? 0 : undefined
              )
            : '- €'}
        </StyledCell>
        {viewModeOptions.showTargetRows ? (
          <TargetCell align="right">
            {targetOrderRow.difference.eq(0)
              ? ''
              : big.priceFormatRounded(
                  targetOrderRow.difference,
                  outerBarOpen ? 0 : undefined
                )}
          </TargetCell>
        ) : null}
        <StyledCell
          align="right"
          contentContainer={OrderRowLeftBorderContainer}
          outerBarOpen={outerBarOpen}
        >
          {`${big.amountFormat(
            originalOrderRow.arrivalQuantity,
            outerBarOpen ? 0 : undefined
          )} ${validatedOrderRow.unit}`}
        </StyledCell>
        <StyledCell align="right" outerBarOpen={outerBarOpen}>
          {big.priceFormatRounded(
            originalOrderRow.arrivalTotal,
            outerBarOpen ? 0 : undefined
          )}
        </StyledCell>
        {outerBarOpen && viewModeOptions.showTargetRows ? null : (
          <>
            <StyledCell align="center" contentContainer={TagsContainer}>
              <AnalysisTags
                orderRowId={originalOrderRow.id}
                analysisListItemIds={originalOrderRow.analysisListItemIds}
              />
            </StyledCell>
            <StyledCell align="center">
              <StatusPill toggle={statusToggle} status={status} />
            </StyledCell>
          </>
        )}
        <StyledCell align="right" outerBarOpen={outerBarOpen}>
          {remainingAmount}
        </StyledCell>
        <StyledCell align="center" outerBarOpen={outerBarOpen}>
          {isDeletable ? (
            <IconButton
              icon={IconDelete}
              onClick={onDeleteOrderRow}
              aria-label={removeText}
            />
          ) : null}
        </StyledCell>
      </SecondaryRow>
      {viewModeOptions.showTargetRows ? (
        <>
          {targetRows.map((row) => (
            <TargetedRow
              key={`targeted-row-${row.id}`}
              row={row}
              disableCheckbox={isTargetRowSelectionDisabled}
            />
          ))}
        </>
      ) : null}
    </>
  );
};

const StyledCell = styled(Cell)<{ outerBarOpen?: boolean }>`
  ${(props) =>
    props.outerBarOpen
      ? css`
          padding: 0 ${props.theme.margin[2]};
        `
      : null}
`;

export default EditableOrderRow;
