import Big from 'big.js';
import { Reducer } from 'redux';

import { ID } from '../../types/general';

import {
  assertActionPayloadIsNotApiUpdatedEntities,
  Selector,
  isUpdatedEntitiesActionType,
} from './utils';
import * as api from '../../utils/api';
import { isDefined } from '../../utils/general';
import normalizeBy from '../../utils/normalizeBy';
import * as remoteData from '../../utils/remoteData';

import { ActionTypes } from '../actionTypes';
import { getActualCostById } from './actualCost';

import { AppState } from '.';

export interface ActualCostLine {
  id: ID;
  actualCostsDetailLineExternalId: string;
  actualCostsEntryNo: string;
  orderRowId: ID | null;
  arrivalRowId: ID | null;
  accountCode: string | null;
  costControlItemCode: string | null;
  costType: string | null;
  date: Date;
  description: string | null;
  quantity: Big;
  unit: string;
  unitPrice: Big;
  createdAt: Date;
  updatedAt: Date;
}

type Err = api.BackendError | undefined;

export type ActualCostLineState = {
  requests: Partial<Record<string, remoteData.RemoteData<undefined, Err>>>;
  convertRequests: Partial<Record<string, remoteData.RemoteData<string, Err>>>;
  data: Partial<Record<string, ActualCostLine>>;
};

const initialState: ActualCostLineState = {
  requests: {},
  convertRequests: {},
  data: {},
};

const actualCostLineReducer: Reducer<ActualCostLineState, ActionTypes> = (
  state = initialState,
  action
): ActualCostLineState => {
  switch (action.type) {
    case 'GET_ACTUAL_COST_LINES_STARTED': {
      const { actualCostId } = action.payload;

      const requests = {
        ...state.requests,
        [actualCostId]: remoteData.loading,
      };

      return {
        ...state,
        requests,
      };
    }
    case 'GET_ACTUAL_COST_LINES_FAILURE': {
      const { actualCostId, error } = action.payload;

      const requests = {
        ...state.requests,
        [actualCostId]: remoteData.fail(error),
      };

      return { ...state, requests };
    }
    case 'GET_ACTUAL_COST_LINES_SUCCESS': {
      const { actualCostId, actualCostLines } = action.payload;

      const requests = {
        ...state.requests,
        [actualCostId]: remoteData.succeed(undefined),
      };

      const data = {
        ...state.data,
        ...normalizeBy('id', actualCostLines),
      };

      return {
        convertRequests: state.convertRequests,
        requests,
        data,
      };
    }
    case 'PUT_ACTUAL_COST_LINES_CONVERT_STARTED': {
      const { requestId } = action.payload;

      return {
        ...state,
        convertRequests: {
          ...state.convertRequests,
          [requestId]: remoteData.loading,
        },
      };
    }
    case 'PUT_ACTUAL_COST_LINES_CONVERT_FAILURE': {
      const { requestId, error } = action.payload;

      return {
        ...state,
        convertRequests: {
          ...state.convertRequests,
          [requestId]: remoteData.fail(error),
        },
      };
    }
    case 'PUT_ACTUAL_COST_LINES_CONVERT_SUCCESS': {
      const {
        actualCostsDetailLines: updatedActualCostLines = [],
        actualCostId,
      } = action.payload;

      if (updatedActualCostLines.length === 0) {
        return state;
      }

      const data = { ...state.data };

      updatedActualCostLines.forEach((actualCostLine) => {
        const { id } = actualCostLine;

        data[id] = actualCostLine;
      });

      const { requestId } = action.payload;

      return {
        ...state,
        convertRequests: {
          ...state.convertRequests,
          [requestId]: remoteData.succeed(actualCostId),
        },
        data,
      };
    }
  }

  if (isUpdatedEntitiesActionType(action)) {
    const { actualCostsDetailLines: updatedActualCostLines = [] } =
      action.payload;

    if (updatedActualCostLines.length === 0) {
      return state;
    }

    const data = { ...state.data };

    updatedActualCostLines.forEach((actualCostLine) => {
      const { id } = actualCostLine;

      data[id] = actualCostLine;
    });

    return {
      ...state,
      data,
    };
  }

  assertActionPayloadIsNotApiUpdatedEntities(action);

  return state;
};

export const selectActualCostDetailLinesRequests =
  (actualCostId: string) =>
  ({
    actualCostLines: {
      requests: { [actualCostId]: requestState = remoteData.notAsked },
    },
  }: AppState) =>
    requestState;

export const getActualCostLineById =
  (id: string) =>
  ({
    actualCostLines: {
      data: { [id]: actualCostLine },
    },
  }: AppState): ActualCostLine | undefined =>
    actualCostLine;

export const getActualCostLinesByActualCostId =
  (actualCostId: string | null) =>
  (appState: AppState): remoteData.RemoteData<ActualCostLine[]> => {
    if (!actualCostId) {
      return remoteData.succeed([]);
    }

    const actualCostLineIds =
      getActualCostById(actualCostId)(appState)?.actualCostsDetailLineIds ?? [];

    return remoteData.map(
      selectActualCostDetailLinesRequests(actualCostId)(appState),
      (_) =>
        actualCostLineIds
          .map((id) => getActualCostLineById(id)(appState))
          .filter(isDefined)
    );
  };

export function getConvertRequestState(
  requestId: string
): Selector<remoteData.RemoteData['kind']> {
  return ({
    actualCostLines: {
      convertRequests: { [requestId]: requestState = remoteData.notAsked },
    },
  }) => requestState.kind;
}

export const getActualCostLineConverts =
  () =>
  ({ actualCostLines: { convertRequests } }: AppState) => {
    const successfulConverts = Object.values(convertRequests)
      .filter((entry) => entry?.kind === 'Success')
      .filter(isDefined)
      .filter(remoteData.isSuccess)
      .map((entry) => entry.value);

    return successfulConverts;
  };

export default actualCostLineReducer;
