import { combineReducers, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import actualCostAttachmentFiles from './actualCostAttachmentFiles';
import actualCostAttachments from './actualCostAttachments';
import actualCostLines from './actualCostLine';
import actualCosts from './actualCosts';
import analysis from './analysis';
import arrivals from './arrival';
import arrivalRows from './arrivalRow';
import batchGroups from './batchGroup';
import companyReportingPeriods from './companyReportingPeriod';
import invoiceHeaders from './invoice';
import invoiceAttachmentFiles from './invoiceAttachmentFiles';
import invoiceAttachments from './invoiceAttachments';
import invoiceLines from './invoiceLine';
import manualEntries from './manualEntry';
import notification from './notification';
import orders from './order';
import orderRows from './orderRow';
import paymentProgramRowGroups from './paymentProgramRowGroup';
import presettledInvoiceRows from './presettledInvoiceRows';
import procurementAreas from './procurementArea';
import projects from './project';
import projectUsers from './projectUsers';
import purchaseInvoiceReclamations from './purchaseInvoiceReclamation';
import purchaseInvoiceReclamationTemplates from './purchaseInvoiceReclamationTemplate';
import revenues from './revenue';
import schedule from './schedule';
import snapshots from './snapshot';
import target from './target';
import tasks from './tasks';
import topics from './topic';
import ui from './ui';
import user from './user';
import workPackageGroups from './workPackageGroup';
import workPackages from './workSection';

const rootReducer = combineReducers({
  actualCosts,
  actualCostLines,
  actualCostAttachments,
  actualCostAttachmentFiles,
  analysis,
  arrivalRows,
  arrivals,
  batchGroups,
  companyReportingPeriods,
  invoiceAttachmentFiles,
  invoiceAttachments,
  invoiceHeaders,
  invoiceLines,
  manualEntries,
  notification,
  orderRows,
  orders,
  paymentProgramRowGroups,
  presettledInvoiceRows,
  procurementAreas,
  projects,
  revenues,
  schedule,
  snapshots,
  target,
  topics,
  ui,
  user,
  projectUsers,
  workPackages,
  workPackageGroups,
  tasks,
  purchaseInvoiceReclamationTemplates,
  purchaseInvoiceReclamations,
});

export type AppState = ReturnType<typeof rootReducer>;

export type AppThunkDispatch = ThunkDispatch<AppState, undefined, Action>;

export default rootReducer;
