import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';

import { ExtractActionTypes, makeAction } from '../../utils/actionCreators';
import { GET, BackendError, apiErrorHandlingWithDecode } from '../../utils/api';
import { dateString } from '../../utils/decoders';
import { createAsyncThunk } from '../../utils/thunk';

import { ReclaimTemplateMessage } from '../reducers/purchaseInvoiceReclamationTemplate';

const actionCreators = {
  ...makeAction('getReclamationTemplateFieldsStarted')<{
    languageId: string;
  }>(),
  ...makeAction('getReclamationTemplateFieldsSuccess')<
    ReclaimTemplateMessage[]
  >(),
  ...makeAction('getReclamationTemplateFieldsFailure')<{
    error: BackendError | undefined;
  }>(),
};
export type PurchaseInvoiceReclamationTemplateAction = ExtractActionTypes<
  typeof actionCreators
>;
export const {
  getReclamationTemplateFieldsStarted,
  getReclamationTemplateFieldsSuccess,
  getReclamationTemplateFieldsFailure,
} = actionCreators;

const apiReclamationTemplateType = t.exact(
  t.type({
    id: t.string,
    title: t.string,
    message: t.string,
    reason: t.string,
    isDeleted: t.boolean,
    createdAt: dateString,
    updatedAt: dateString,
  })
);

export async function toReclamationTepmlate(
  u: unknown
): Promise<ReclaimTemplateMessage[]> {
  const apiActualCostLines = await tPromise.decode(
    t.array(apiReclamationTemplateType),
    u
  );

  return apiActualCostLines;
}

async function getReclamationTemplateFields(
  languageId: string,
  invoiceHeaderId: string
): Promise<ReclaimTemplateMessage[]> {
  const purchaseInvoiceHeaderId = invoiceHeaderId;

  const response = await GET(
    `v1/purchase-invoice-headers/${purchaseInvoiceHeaderId}/reclaimTemplateMessages/${languageId}`
  );

  return toReclamationTepmlate(response);
}

export const fetchReclamationTemplateFields = (
  languageId: string,
  invoiceHeaderId: string
) =>
  createAsyncThunk(getReclamationTemplateFields, {
    args: [languageId, invoiceHeaderId],
    initialAction: getReclamationTemplateFieldsStarted({ languageId }),
    successActionCreator: (reclaimTemplateFields) =>
      getReclamationTemplateFieldsSuccess(reclaimTemplateFields),
    failureActionCreator: (error) =>
      getReclamationTemplateFieldsFailure({
        error: apiErrorHandlingWithDecode(error),
      }),
  });
