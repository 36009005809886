import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import styled from 'styled-components';

import { getSelectedPaymentProgramRows } from '../../store/reducers/ui';

import CreateGroupSidebar from './components/CreateGroupSidebar';
import MoveModal from './components/MoveModal';
import RevenueTable from './components/RevenueTable';
import Summary from './components/Summary';
import Header from '../../components/Header';
import TabIconTextButton, {
  StickyTabIconContainer,
} from '../../components/Navigation/TabIconTextButton';
import ProjectInfo from '../../components/ProjectInfo';
import { ToolBeltButton, ToolBeltContainer } from '../../components/ToolBelt';
import Txt from '../../components/Txt';

import CAN, {
  CaslPaymentProgramRowGroupRequestParams,
  CaslPaymentProgramRowRequestParams,
} from '../../utils/caslUserPermissions';

import { IconAdd, IconEdit, IconMove } from '../../assets/svg';

import { generateUrl, routes, useParams } from '../../routes';

const Revenue = () => {
  const [showCreateGroupSidebar, setShowCreateGroupSidebar] =
    React.useState(false);
  const [isMoveModalVisible, setMoveModalVisibility] = React.useState(false);

  const toggleCreateGroupSideBar = () =>
    setShowCreateGroupSidebar(!showCreateGroupSidebar);
  const { projectId, viewMode } = useParams(routes.REVENUE);

  const paymentProgramRowIds = useSelector(
    getSelectedPaymentProgramRows(projectId)
  );

  const history = useHistory();

  const toggleMode = () => {
    history.push(
      generateUrl({
        route: routes.REVENUE,
        projectId,
        viewMode: viewMode === 'edit' ? undefined : 'edit',
      })
    );
  };

  const paymentProgramRowAbility = new CaslPaymentProgramRowRequestParams(
    projectId
  );

  const paymentProgramRowGroupAbility =
    new CaslPaymentProgramRowGroupRequestParams(projectId);
  const paymentProgramRowAllowedUser = CAN('write', paymentProgramRowAbility);

  const paymentProgramRowGroupAllowedUser = CAN(
    'write',
    paymentProgramRowGroupAbility
  );

  return (
    <>
      <Container>
        <Header>
          <Txt id="revenue.header" />
        </Header>
        <ProjectInfo projectId={projectId} />
        <StickyTabIconContainer>
          <TabIconTextButton
            isActive={viewMode === 'edit'}
            onClick={toggleMode}
            icon={IconEdit}
            id="revenue.button.edit"
            disabled={!paymentProgramRowAllowedUser}
          />
        </StickyTabIconContainer>
        {viewMode === 'edit' ? (
          <StyledToolBelt>
            <ToolBeltButton
              icon={IconMove}
              disabled={
                !paymentProgramRowAllowedUser ||
                !paymentProgramRowGroupAllowedUser ||
                paymentProgramRowIds.length === 0
              }
              onClick={() => setMoveModalVisibility(true)}
            >
              <Txt id="revenue.toolbelt.button.moveRows" />
            </ToolBeltButton>
            <ToolBeltButton
              icon={IconAdd}
              onClick={toggleCreateGroupSideBar}
              disabled={!paymentProgramRowGroupAllowedUser}
            >
              <Txt id="revenue.toolbelt.button.addGroup" />
            </ToolBeltButton>
          </StyledToolBelt>
        ) : null}
        <Summary projectId={projectId} editMode={viewMode === 'edit'} />
        <RevenueTable projectId={projectId} />
      </Container>
      {isMoveModalVisible ? (
        <MoveModal onClose={() => setMoveModalVisibility(false)} />
      ) : null}
      {showCreateGroupSidebar ? (
        <CreateGroupSidebar
          onClose={() => {
            setShowCreateGroupSidebar(false);
          }}
        />
      ) : null}
    </>
  );
};

const Container = styled.div`
  margin: ${({ theme }) => theme.margin[24]} ${({ theme }) => theme.margin[40]};
`;

const StyledToolBelt = styled(ToolBeltContainer)`
  top: 37px;
`;

export default Revenue;
