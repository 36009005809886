import { Reducer } from 'redux';

import * as api from '../../utils/api';
import * as remoteData from '../../utils/remoteData';

import { ActionTypes } from '../actionTypes';

import { AppState } from '.';

type Err = api.BackendError | undefined;

export type ReclaimTemplateMessage = {
  id: string;
  reason: string;
  title: string;
  message: string;
  isDeleted: boolean;
  createdAt: Date;
  updatedAt: Date;
};

export type PurchaseInvoiceReclamationTemplateState = {
  request: remoteData.RemoteData<undefined | string, Err>;
  data: ReclaimTemplateMessage[];
};

const initialState: PurchaseInvoiceReclamationTemplateState = {
  request: remoteData.notAsked,
  data: [],
};

const purchaseInvoiceReclamationTemplateReducer: Reducer<
  PurchaseInvoiceReclamationTemplateState,
  ActionTypes
> = (state = initialState, action): PurchaseInvoiceReclamationTemplateState => {
  switch (action.type) {
    case 'GET_RECLAMATION_TEMPLATE_FIELDS_STARTED': {
      return {
        ...state,
        request: remoteData.loading,
      };
    }

    case 'GET_RECLAMATION_TEMPLATE_FIELDS_SUCCESS': {
      const purchaseInvoiceReclamationTemplates = action.payload;

      return {
        ...state,
        request: remoteData.succeed(undefined),
        data: purchaseInvoiceReclamationTemplates,
      };
    }
    case 'GET_RECLAMATION_TEMPLATE_FIELDS_FAILURE': {
      const { error } = action.payload;

      return {
        data: [],
        request: remoteData.fail(error),
      };
    }
  }

  return state;
};

export const getReclaimTemplateState = () => (state: AppState) =>
  state.purchaseInvoiceReclamationTemplates;

export default purchaseInvoiceReclamationTemplateReducer;
