import { orderBy } from 'lodash';
import { Reducer } from 'redux';

import { BackendError } from '../../../utils/api';
import { flow } from '../../../utils/function';
import { isDefined } from '../../../utils/general';
import normalizeBy from '../../../utils/normalizeBy';
import * as remoteData from '../../../utils/remoteData';
import {
  assertActionPayloadIsNotApiUpdatedEntities,
  isUpdatedEntitiesActionType,
  Selector,
} from '../utils';

import { AppState } from '..';
import { getAnalysisGroupById } from './group';
import { getSortOrdersForAnalysis } from './sortOrders';
import { AnalysisRow } from '../../actions/analysis';
import { ActionTypes } from '../../actionTypes';

export type AnalysisRowState = Partial<
  Record<
    string,
    remoteData.RemoteData<
      Partial<Record<string, AnalysisRow>>,
      BackendError | undefined
    >
  >
>;

const initialState: AnalysisRowState = {};

type RevenueUpdates = Partial<Record<string, remoteData.RemoteAction>>;

type GetDetailedAnalysisCsvRequests = Partial<
  Record<string, remoteData.RemoteData<undefined, BackendError | undefined>>
>;

export const rowUpdateReducer = (
  state: RevenueUpdates = {},
  action: ActionTypes
) => {
  switch (action.type) {
    case 'PUT_ANALYSIS_ROW_STARTED': {
      return { ...state, [action.payload.requestId]: remoteData.loading };
    }

    case 'PUT_ANALYSIS_ROW_FAILURE': {
      return {
        ...state,
        [action.payload.requestId]: remoteData.fail(action.payload.error),
      };
    }
    case 'PUT_ANALYSIS_ROW_SUCCESS': {
      return {
        ...state,
        [action.payload.requestId]: remoteData.succeed(undefined),
      };
    }
    default:
      return state;
  }
};

export const rowsReducer: Reducer<AnalysisRowState, ActionTypes> = (
  state = initialState,
  action
): AnalysisRowState => {
  switch (action.type) {
    case 'GET_ANALYSIS_ROWS_STARTED': {
      const { projectId } = action.payload;

      return {
        ...state,
        [projectId]: remoteData.loading,
      };
    }
    case 'GET_ANALYSIS_ROWS_FAILURE': {
      const { projectId, error } = action.payload;

      return {
        ...state,
        [projectId]: remoteData.fail(error),
      };
    }
    case 'POST_ANALYSIS_ROW_SUCCESS': {
      const { projectId, analysisRows: updatedAnalysisRows = [] } =
        action.payload;

      if (projectId === undefined) {
        return state;
      }

      return updatedAnalysisRows.reduce((nextState, analysisRow) => {
        const { id } = analysisRow;

        const { [projectId]: remoteAnalysisRows = remoteData.notAsked } =
          nextState;

        return {
          ...nextState,
          [projectId]: remoteData.map(
            remoteAnalysisRows,
            ({ [id]: _, ...analysisRows }) => {
              return { [id]: analysisRow, ...analysisRows };
            }
          ),
        };
      }, state);
    }

    case 'GET_ANALYSIS_ROWS_SUCCESS': {
      const { projectId, analysisRows } = action.payload;

      return {
        ...state,
        [projectId]: remoteData.succeed(normalizeBy('id', analysisRows)),
      };
    }
  }

  if (isUpdatedEntitiesActionType(action)) {
    const { analysisRows: updatedAnalysisRows } = action.payload;

    if (updatedAnalysisRows === undefined) {
      return state;
    }

    const analysisRowProjectId =
      updatedAnalysisRows.map((row) => row.projectId)[0] ?? '';

    const projectId =
      'projectId' in action.payload
        ? action.payload.projectId
        : analysisRowProjectId;

    if (projectId === '') {
      return state;
    }

    return updatedAnalysisRows.reduce((nextState, analysisRow) => {
      const { id } = analysisRow;

      const { [projectId]: remoteAnalysisRows = remoteData.notAsked } =
        nextState;

      return {
        ...nextState,
        [projectId]: remoteData.map(
          remoteAnalysisRows,
          ({ [id]: _, ...analysisRows }) => {
            return analysisRow.isDeleted
              ? { ...analysisRows }
              : { [id]: analysisRow, ...analysisRows };
          }
        ),
      };
    }, state);
  }

  assertActionPayloadIsNotApiUpdatedEntities(action);

  return state;
};

export const getAnalysesCsvReducer = (
  state: GetDetailedAnalysisCsvRequests = {},
  action: ActionTypes
) => {
  switch (action.type) {
    case 'GET_DETAILED_ANALYSIS_CSV_STARTED': {
      return { ...state, [action.payload.analysisGroupId]: remoteData.loading };
    }

    case 'GET_DETAILED_ANALYSIS_CSV_FAILURE': {
      return {
        ...state,
        [action.payload.analysisGroupId]: remoteData.fail(action.payload.error),
      };
    }
    case 'GET_DETAILED_ANALYSIS_CSV_SUCCESS': {
      return {
        ...state,
        [action.payload.analysisGroupId]: remoteData.succeed(undefined),
      };
    }
    default:
      return state;
  }
};

export const toAnalysisRows = (
  rows: Partial<Record<string, AnalysisRow>>
): AnalysisRow[] => {
  return Object.values(rows).filter(isDefined);
};

export const getAnalysisRowsForProject =
  (projectId: string) =>
  ({
    analysis: {
      rows: { [projectId]: analysisRows = remoteData.notAsked },
    },
  }: AppState) => {
    return remoteData.map(analysisRows, (rows) => toAnalysisRows(rows));
  };

type AnalysisRowId = {
  projectId: string;
  analysisRowId: string;
};

export const getAnalysisRow = ({
  projectId,
  analysisRowId,
}: AnalysisRowId): Selector<remoteData.RemoteData<AnalysisRow | undefined>> =>
  flow(getAnalysisRowsForProject(projectId), (remoteRows) =>
    remoteData.map(remoteRows, (rows) =>
      rows.find(({ id }) => id === analysisRowId)
    )
  );

type AttributeType = 'SelectionList' | 'Number' | 'Text' | 'Date';

const convertAttributeValue = (
  attributeType: AttributeType,
  attributeValue: string
): string | Date | number | null => {
  switch (attributeType) {
    case 'Date':
      if (attributeValue === '') {
        return new Date(0);
      }

      return new Date(attributeValue);
    case 'Number':
      if (attributeValue === '') {
        return Number(0);
      }

      return Number(attributeValue);
    default:
      return attributeValue;
  }
};

export const getAnalysisRowsForGroup =
  (projectId: string, groupId: string) =>
  (appState: AppState): remoteData.RemoteData<AnalysisRow[]> => {
    const remoteRows = getAnalysisRowsForProject(projectId)(appState);
    const analysisGroup = getAnalysisGroupById(groupId)(appState);

    const sortOrders = getSortOrdersForAnalysis(appState);

    const sortOrderForAnalysisGroup = sortOrders[groupId];

    const analysisRows = remoteData.map(remoteRows, (rows) => {
      const filteredRows = rows.filter(({ id }) =>
        analysisGroup?.listItemIds.includes(id)
      );

      if (!sortOrderForAnalysisGroup) {
        return filteredRows.sort((a, b) =>
          a.code.localeCompare(b.code, 'fi', { numeric: true })
        );
      }

      const sortOrder =
        sortOrderForAnalysisGroup.sortOrder === 'Ascending' ? 'asc' : 'desc';

      if (sortOrderForAnalysisGroup.key === 'code') {
        const coefficient = sortOrder === 'asc' ? 1 : -1;

        return filteredRows.sort(
          (a, b) =>
            coefficient * a.code.localeCompare(b.code, 'fi', { numeric: true })
        );
      }

      if (sortOrderForAnalysisGroup.key === 'attributeValues') {
        const mappedRows = filteredRows.map((row) => {
          const sortAttribute = row.attributeValues.find(
            (attribute) =>
              attribute.attributeId === sortOrderForAnalysisGroup.attributeId
          );

          if (!sortAttribute) {
            return undefined;
          }

          const convertedSortAttribute = convertAttributeValue(
            sortAttribute.type,
            sortAttribute.value
          );

          return {
            id: row.id,
            sortAttributeValue: convertedSortAttribute,
          };
        });

        const mappedRowsSorted = orderBy(
          mappedRows,
          ['sortAttributeValue'],
          [sortOrder]
        ).map((row) => row?.id);

        const sortedArray = filteredRows.sort(
          (a, b) =>
            mappedRowsSorted.indexOf(a.id) - mappedRowsSorted.indexOf(b.id)
        );

        return sortedArray;
      }

      const rowsWithMappedAmounts = filteredRows.map((row) => {
        return {
          ...row,
          paymentProgramRowsAmount: row.paymentProgramRowsAmount
            ? row.paymentProgramRowsAmount.toNumber()
            : 0,
          orderRowsAmount: row.orderRowsAmount
            ? row.orderRowsAmount.toNumber()
            : 0,
          arrivalsAmount: row.arrivalsAmount
            ? row.arrivalsAmount.toNumber()
            : 0,
        };
      });

      const mappedRowsSorted = orderBy(
        rowsWithMappedAmounts,
        [sortOrderForAnalysisGroup.key],
        [sortOrder]
      ).map((row) => row.id);

      const sortedArray = filteredRows.sort(
        (a, b) =>
          mappedRowsSorted.indexOf(a.id) - mappedRowsSorted.indexOf(b.id)
      );

      return sortedArray;
    });

    return analysisRows;
  };

// TODO: Is this really called?
export function getAnalysisRowCreateRequest(
  requestId: string
): Selector<remoteData.RemoteAction> {
  return ({
    analysis: {
      updateRequests: { [requestId]: request },
    },
  }) => request ?? remoteData.notAsked;
}

export function getAnalysisRowUpdateRequest(
  requestId: string
): Selector<remoteData.RemoteAction> {
  return ({
    analysis: {
      updateRequests: { [requestId]: request },
    },
  }) => request ?? remoteData.notAsked;
}

export const getDetailedAnalysisCsvFetchRequest = (
  projectId: string
): Selector<remoteData.RemoteAction> => {
  return ({
    analysis: {
      getCsvRequests: { [projectId]: request },
    },
  }) => request ?? remoteData.notAsked;
};
