import React from 'react';

import styled, { css } from 'styled-components';

import { getProjectSnapshotPoCEntries } from '../../../../../store/reducers/schedule/projectTimeline';
import { getSnapshots } from '../../../../../store/reducers/snapshot';

import { getProjectTimelineForProject } from '../../../../../store/actions';
import { fetchSnapshots } from '../../../../../store/actions/snapshot';

import useRemoteData from '../../../../../hooks/useRemoteData';
import useScrollTo from '../../../../../hooks/useScrollTo';
import useTxt from '../../../../../hooks/useTxt';

import Tooltip from '../../../../../components/Tooltip';
import Txt from '../../../../../components/Txt';

import { dateFormat } from '../../../../../utils/format';
import { isMonthNumber } from '../../../../../utils/general';

type ThProps = {
  bolded?: boolean;
  current?: boolean;
  freeze?: boolean;
};

const Th = styled.th<ThProps>`
  padding: ${({ theme: { margin } }) => `${margin[8]} ${margin[8]}`};

  align-items: center;
  ${(props) =>
    `${
      props.freeze
        ? css`
            width: 300px;
          `
        : css`
            width: 4rem;
          `
    }`}
  ${({ bolded, theme, current }) =>
    bolded || current
      ? ''
      : css`
          color: ${theme.color.graphiteB76};
        `}
  font-size: ${(props) => `${props.theme.fontSize.small}`};
  font-weight: ${(props) => (props.bolded ? 'bold' : 'normal')};
  ${({ current, theme, freeze }) => {
    if (freeze) {
      return css`
        border-right: 2px solid ${theme.color.graphiteB86};
      `;
    }

    return current
      ? css`
          border-left: 2px solid ${theme.color.blue};
          background: ${theme.color.whisper};
        `
      : css`
          border-left: 1px solid ${theme.color.graphiteB86};
        `;
  }}

  text-align: center;
  white-space: normal;
  ${({ freeze }) =>
    freeze
      ? css`
          position: sticky;
          left: 0px;

          min-width: 300px;
          max-width: 300px;

          background: white;
        `
      : ''}
`;

const textIdHead = 'months.short.';

type MonthTextThProps = {
  date: Date;
  currentPeriod: Date;
  isAllRendered: boolean;
};

const TxtTh = ({ date, currentPeriod, isAllRendered }: MonthTextThProps) => {
  const monthNumber = date.getMonth().toString();
  const yearNumber = date.getFullYear().toString();

  const bolded = date < currentPeriod;

  const [scrollToRef, setShouldScrollTo] = useScrollTo<HTMLTableCellElement>();

  const current =
    date.getMonth() === currentPeriod.getMonth() &&
    date.getFullYear() === currentPeriod.getFullYear();

  React.useEffect(() => {
    if (!isAllRendered) {
      return;
    }

    if (!current) {
      return;
    }

    setShouldScrollTo(true);
  }, [current, isAllRendered, setShouldScrollTo]);

  const month = isMonthNumber(monthNumber) ? monthNumber : undefined;

  if (!month) {
    return null;
  }

  if (month === '0') {
    return (
      <Th current={current} bolded={bolded}>
        {yearNumber}
      </Th>
    );
  }

  return (
    <Th ref={scrollToRef} current={current} bolded={bolded}>
      <Txt id={`${textIdHead}${month}` as const} />
    </Th>
  );
};

type HeaderProps = {
  months: Date[];
  snapshotIds: string[];
  projectId: string;
  currentPeriod: Date;
};

const TableHeader = ({
  months,
  snapshotIds,
  currentPeriod,
  projectId,
}: HeaderProps) => {
  const [isAllRendered, setAllRendered] = React.useState(false);
  const ref = React.useRef<number>();

  const snapshotRows =
    useRemoteData(getSnapshots(projectId), fetchSnapshots(projectId)) ?? [];

  const snapshotEntries =
    useRemoteData(
      getProjectSnapshotPoCEntries({ projectId }),
      getProjectTimelineForProject({ projectId })
    ) ?? [];

  const snapshotIdsAndDescriptions = snapshotIds.map((id) => {
    const snapshot = snapshotRows.find((row) => row.id === id);

    const description = snapshot ? snapshot.description : '';

    const snapshotTypeComment = snapshot ? snapshot.snapshotTypeComment : '';

    const snapshotTypeId = snapshot ? snapshot.snapshotTypeId : '1';

    const createdAt = snapshot?.createdAt;

    return {
      id,
      description,
      snapshotTypeComment,
      snapshotTypeId,
      createdAt,
    };
  });

  React.useEffect(() => {
    ref.current = requestAnimationFrame(() => {
      setAllRendered(true);
    });

    return () => cancelAnimationFrame(ref.current ?? 0);
  }, []);

  return (
    <StyledThead>
      <HeaderTr>
        <Th freeze />
        {snapshotIdsAndDescriptions.map((row) => {
          const projectSnapshotTimelineEntry = snapshotEntries.find(
            (entry) => entry.snapshotId === row.id
          );

          return (
            <TooltipHeader
              key={`snapshotId-timeLine-header-${row.id}`}
              description={row.description}
              snapshotTypeId={row.snapshotTypeId}
              createdAt={row.createdAt}
              snapshotTypeComment={row.snapshotTypeComment}
              date={projectSnapshotTimelineEntry?.date}
            />
          );
        })}
        {months.map((month) => (
          <TxtTh
            date={month}
            currentPeriod={currentPeriod}
            isAllRendered={isAllRendered}
            key={month.toString()}
          />
        ))}
      </HeaderTr>
    </StyledThead>
  );
};

type TooltipThProps = {
  description: string;
  snapshotTypeId: string;
  snapshotTypeComment: string | null;
  createdAt: Date | undefined;
  date: Date | undefined;
};

const TooltipHeader = ({
  description,
  snapshotTypeId,
  snapshotTypeComment,
  createdAt,
  date,
}: TooltipThProps) => {
  const reasonText = useTxt('common.reason');

  const plannedPoCText = useTxt('reporting.table.snapshot.tooltip.planned', {
    snapshotCreationDate: dateFormat.format(createdAt),
    timelineDate: dateFormat.format(date),
  });

  const previouslyPlannedPoCText = useTxt(
    'reporting.table.snapshot.tooltip.previouslyPlanned',
    {
      snapshotCreationDate: dateFormat.format(createdAt),
      timelineDate: dateFormat.format(date),
    }
  );

  const tooltipText = (typeId: string) => {
    switch (typeId) {
      case '2':
        return `${plannedPoCText}. ${reasonText}: ${snapshotTypeComment ?? ''}`;
      case '3':
        return `${previouslyPlannedPoCText}. ${reasonText}: ${
          snapshotTypeComment ?? ''
        }`;
      default:
        return '';
    }
  };

  return (
    <Th bolded>
      <Tooltip
        tip={tooltipText(snapshotTypeId)}
        className="compressed-tooltip"
        place="top"
        delayShow={300}
        disable={snapshotTypeId === '1'}
      >
        {`${snapshotTypeId !== '1' ? '*' : ''}${description}`}
      </Tooltip>
    </Th>
  );
};

const HeaderTr = styled.tr`
  border-bottom: 1px solid ${(props) => props.theme.color.graphiteB91};
`;

const StyledThead = styled.thead`
  position: sticky;
  top: 0;
  background: white;
  z-index: 2;
`;

export default TableHeader;
