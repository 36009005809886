import React from 'react';
import * as RI from 'react-intl';

import styled from 'styled-components';

import { TextId } from '../localization';

import Modal from './Modal/Modal';
import Txt from './Txt';

type ModalWindowProps = {
  onClose: () => void | undefined;
  titleId: TextId;
  titleValues?: React.ComponentProps<typeof RI.FormattedMessage>['values'];
};

const MoveModal: React.FC<ModalWindowProps> = ({
  onClose,
  titleId,
  titleValues,
  children,
}) => {
  return (
    <Modal onClose={onClose}>
      <Container>
        <Header>
          <Txt id={titleId} values={titleValues} />
        </Header>
        {children}
      </Container>
    </Modal>
  );
};

export default MoveModal;

const Container = styled.div`
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;

  padding: 1.5rem;

  display: flex;
  flex-direction: column;

  background-color: ${({ theme: { color } }) => color.white};
`;

const Header = styled.div`
  margin: -1.5rem -1.5rem 1.5rem;

  border-radius: 0.5rem 0.5rem 0 0;

  padding: 2rem;

  height: 2.5rem;

  display: flex;
  justify-content: space-between;
  align-items: center;

  align-self: stretch;

  background-color: ${({ theme: { color } }) => color.modalTitleBarBackground};

  color: ${({ theme: { color } }) => color.white};
  font-family: 'Graphik', sans-serif;
  font-size: 1rem;
  font-weight: 600;
`;
