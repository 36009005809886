import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';

import { getDetailedAnalysisCsvFetchRequest } from '../../../store/reducers/analysis/row';

import {
  AnalysisGroup as AnalysisGroupType,
  fetchDetailedAnalysesCsv,
} from '../../../store/actions';

import useTxt from '../../../hooks/useTxt';

import ArrowIcon from '../../../components/ArrowIcon';
import { IconButton } from '../../../components/Buttons';
import Cell from '../../../components/Cell';
import { DropDownList, DropDownItem } from '../../../components/DropdownList';
import { PrimaryRow } from '../../../components/Table';

import { priceFormat } from '../../../utils/big';
import { isClickOrKeyboardSelection } from '../../../utils/mouseOrKeyInteraction';

import { IconKebabMenu, IconCsvDocument } from '../../../assets/svg';

import { routes, useParams } from '../../../routes';

import { Analysis } from './Analysis';
import { ControlRow } from './ControlRow';

type CellTdProps = {
  paddingLeft?: number;
};

const StyledCell = styled(Cell)<CellTdProps>`
  padding-left: ${({ paddingLeft, theme }) =>
    paddingLeft !== undefined ? `${paddingLeft}rem;` : theme.margin[8]};
  background-color: ${({ theme }) => theme.color.white};
`;

type AnalysisGroupProps = {
  analysisGroup: AnalysisGroupType;
};

export const AnalysisGroup = ({ analysisGroup }: AnalysisGroupProps) => {
  const {
    id,
    name,
    isOrderRowField,
    isPaymentProgramRowField,
    isTargetRowField,
    orderRowsAmountTotal,
    targetRowsAmountTotal,
    paymentProgramRowsAmountTotal,
    arrivalsAmountTotal,
  } = analysisGroup;

  const dispatch = useDispatch();

  const csvRequestState = useSelector(
    getDetailedAnalysisCsvFetchRequest(id)
  ).kind;

  const dynamicColumnAttributeIds = analysisGroup.attributeIds;
  const dynamicColumnHeaderNames = analysisGroup.attributeNames;

  const [isOpen, setIsOpen] = React.useState(false);
  const { viewMode = 'view' } = useParams(routes.ANALYSIS);
  const toggleRowOpen = () => setIsOpen(!isOpen);
  const [dropDownVisible, setDropDownVisibility] = React.useState(false);

  const csvExportTxt = useTxt('common.button.csvExport');

  const buttonRef = React.useRef<HTMLButtonElement>(null);

  const toggleDropDown = (e: React.KeyboardEvent | React.MouseEvent) => {
    e.stopPropagation();

    if (isClickOrKeyboardSelection(e)) {
      e.preventDefault();
      setDropDownVisibility(!dropDownVisible);
    }
  };

  const onAnalysisGroupKeyPress = (
    e: React.KeyboardEvent | React.MouseEvent
  ) => {
    e.stopPropagation();

    if (isClickOrKeyboardSelection(e)) {
      toggleRowOpen();
    }
  };

  const onExportCsv = (e: React.KeyboardEvent | React.MouseEvent) => {
    e.stopPropagation();

    if (isClickOrKeyboardSelection(e)) {
      toggleDropDown(e);
      dispatch(fetchDetailedAnalysesCsv(id));
    }
  };

  // NB: placeholders: These should be reported by the backend
  const revenuesIfSelectedForAnalysisItems = paymentProgramRowsAmountTotal
    ? priceFormat(paymentProgramRowsAmountTotal)
    : '';

  const expensesIfSelectedForAnalysisItems = orderRowsAmountTotal
    ? priceFormat(orderRowsAmountTotal)
    : '';

  const targetIfSelectedForAnalysisItems = targetRowsAmountTotal
    ? priceFormat(targetRowsAmountTotal)
    : '';

  const receivedIfSelectedForAnalysisItems = arrivalsAmountTotal
    ? priceFormat(arrivalsAmountTotal)
    : '';

  return (
    <>
      <PrimaryRow
        onClick={toggleRowOpen}
        onKeyDown={onAnalysisGroupKeyPress}
        data-testid={`analysis-group-row-${id}`}
        tabIndex={0}
      >
        <Cell />
        <Cell>
          <ArrowIcon
            isOpen={isOpen}
            openAltTextKey="analysis.table.mainRow.open"
            closedAltTextKey="analysis.table.mainRow.closed"
          />
        </Cell>
        <Cell>{name}</Cell>
        <Cell align="right">{revenuesIfSelectedForAnalysisItems}</Cell>
        <Cell align="right">{expensesIfSelectedForAnalysisItems}</Cell>
        <Cell align="right">{targetIfSelectedForAnalysisItems}</Cell>
        <Cell align="right">{receivedIfSelectedForAnalysisItems}</Cell>
        <Cell colSpan={2} />
        <Cell>
          <IconButton
            icon={IconKebabMenu}
            onClick={toggleDropDown}
            onKeyDown={toggleDropDown}
            data-testid={`analysis-group-row-kebab-menu-${id}`}
            ref={buttonRef}
          />
          {dropDownVisible ? (
            <StyledDropdownList
              role="menu"
              parentRef={buttonRef}
              onClose={() => setDropDownVisibility(false)}
            >
              <DropDownItem
                disabled={csvRequestState === 'Loading'}
                tabIndex={0}
                role="menuitem"
                onClick={onExportCsv}
                onKeyDown={onExportCsv}
              >
                <IconCsvDocument />
                {csvExportTxt}
              </DropDownItem>
            </StyledDropdownList>
          ) : null}
        </Cell>
      </PrimaryRow>

      {isOpen ? (
        <>
          <PrimaryRow>
            <StyledCell paddingLeft={0} colSpan={10} contentContainer={CellDiv}>
              <Analysis
                analysisGroupId={id}
                dynamicColumnHeaderNames={dynamicColumnHeaderNames}
                dynamicColumnAttributeIds={dynamicColumnAttributeIds}
                isOrderRowField={isOrderRowField}
                isPaymentProgramRowField={isPaymentProgramRowField}
                isTargetRowField={isTargetRowField}
              />
            </StyledCell>
          </PrimaryRow>
          {viewMode === 'edit' ? <ControlRow analysisGroupId={id} /> : null}
        </>
      ) : null}
    </>
  );
};

const CellDiv = styled.div`
  display: block;
  overflow-x: auto;
`;

const StyledDropdownList = styled(DropDownList)`
  padding: ${({ theme }) => theme.margin[16]} 0;
`;
