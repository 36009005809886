import { APIWorkPackage } from '@customtypes/api';

import {
  getWorkPackageId,
  NextgenWorkPackage,
  Team,
  VirtualSpace,
  WorkSectionClass,
} from '../../../store/reducers/schedule/nextgenScheduleData';

import { isDefined, isNotNull } from '../../../utils/general';

export type ExtendedScheduleWorkPackageType = NextgenWorkPackage & {
  parents: string[];
  depth: number;
  workSectionClassId: string | null;
  lowest: boolean;
  teamColor: string | undefined;
  teamName: string | undefined;
  virtualSpaceName: string | undefined;
  workSectionClassName: string | undefined;
  includedInPoC?: boolean;
  deSelectDisabled?: boolean;
  existingLinkages?: {
    id: string;
    code: string;
    name: string;
    scheduleWorkPackageIds: string[];
  }[];
  parentNames?: string[];
  parentVirtualSpaceNames?: string[];
  parentWorkSectionClassNames?: string[];
  parentTeamNames?: string[];
  subRows?: ExtendedScheduleWorkPackageType[];
};

export const getWorkPackageWithChildren = (
  selectedWorkSectionClasses: string[],
  manualLinkedWorkPackages: string[],
  workPackagesWithParents: ExtendedScheduleWorkPackageType[],
  withParents: boolean
) => {
  const filteredWorkPackages = workPackagesWithParents.filter(
    (workPackage) =>
      (workPackage.workSectionClassId &&
        selectedWorkSectionClasses.includes(workPackage.workSectionClassId)) ||
      manualLinkedWorkPackages.includes(workPackage.id)
  );

  // select also children if parent is selected
  const filteredWorkPackagesWithChildren = workPackagesWithParents.filter(
    (wp) => {
      const filteredIds = filteredWorkPackages.map((row) => row.id);

      const parentsAndSelf = [wp.id, ...wp.parents];

      return parentsAndSelf.some((parent) => filteredIds.includes(parent));
    }
  );

  if (!withParents) {
    return filteredWorkPackagesWithChildren;
  }

  const parentsToBeShown = filteredWorkPackagesWithChildren.reduce(
    (acc, workPackage) => {
      return [...acc, ...workPackage.parents];
    },
    [] as string[]
  );

  const uniqueParentsToBeShown = [...new Set(parentsToBeShown)];

  const filteredWorkPackageIds = filteredWorkPackagesWithChildren.map(
    (row) => row.id
  );

  const filteredWorkPackagesWithParents = workPackagesWithParents.filter(
    (workPackage) =>
      uniqueParentsToBeShown.includes(workPackage.id) ||
      filteredWorkPackageIds.includes(workPackage.id)
  );

  return filteredWorkPackagesWithParents;
};

export const processWorkPackagesWithParents = (
  workPackages: NextgenWorkPackage[],
  teams: Team[],
  virtualSpaces: VirtualSpace[],
  workSectionClasses: WorkSectionClass[]
) => {
  const workPackagesMap = new Map(workPackages.map((wp) => [wp.id, wp]));
  let workPackagesWithParents: ExtendedScheduleWorkPackageType[] = [];

  for (let i = 0; i < workPackages.length; i++) {
    const data = workPackages[i];
    let depth = 0; // set max depth of 15
    let latestParentId = data.parent_id;
    const parents: string[] = [];

    const workSectionClassId = data.work_section_id;

    while (depth < 15 && latestParentId !== null) {
      const parentId = latestParentId;

      const parent = workPackagesMap.get(parentId);

      if (parent?.id) {
        parents.push(parent?.id);
      }

      latestParentId = parent?.parent_id ?? null;

      depth += 1;
    }

    const allWorkParentIds = workPackages.map((item) => item.parent_id);

    const teamColor = teams.find((team) => team.id === data.team_ids[0])?.color;
    const teamName = teams.find((team) => team.id === data.team_ids[0])?.name;

    const virtualSpaceName = virtualSpaces.find(
      (vs) => vs.id === data.virtual_space_id
    )?.name;

    const workSectionClassName = workSectionClasses.find(
      (row) => row.id === data.work_section_id
    )?.name;

    workPackagesWithParents.push({
      ...data,
      parents,
      depth,
      workSectionClassId: workSectionClassId ?? null,
      lowest: !allWorkParentIds.includes(data.id),
      teamColor,
      teamName,
      virtualSpaceName,
      workSectionClassName,
    });
  }

  return workPackagesWithParents;
};

type MappedWorkSectionClass = {
  id: string;
  value: string;
  label: string;
  parent_id: string | null | undefined;
  parents: string[];
  depth: number;
  lowest: boolean;
};

export const mapWorkSectionClasses = (
  workSectionClasses: WorkSectionClass[]
): MappedWorkSectionClass[] => {
  let mappedWorkSectionClasses: MappedWorkSectionClass[] = [];

  const allWorkSectionParents = workSectionClasses
    .map((data) => data.parent_id)
    .filter(isNotNull);

  const workSectionClassMap = new Map(
    workSectionClasses.map((wp) => [wp.id, wp])
  );

  for (let i = 0; i < workSectionClasses.length; i++) {
    const data = workSectionClasses[i];
    let depth = 0; // set max depth of 15
    let latestParentId = data.parent_id;
    const parents: string[] = [];

    while (depth < 15 && latestParentId !== null) {
      const parentId = latestParentId;

      const parent = parentId ? workSectionClassMap.get(parentId) : undefined;

      if (parent?.id) {
        parents.push(parent?.id);
      }

      latestParentId = parent?.parent_id ?? null;

      depth += 1;
    }

    mappedWorkSectionClasses.push({
      id: data.id,
      value: data.id,
      label: `${data.code} ${data.name}`,
      parent_id: data.parent_id,
      parents,
      depth,
      lowest: !allWorkSectionParents.includes(data.id),
    });
  }

  return mappedWorkSectionClasses.sort((a, b) => {
    const selfAndParentsA = [a.id, ...a.parents, 'common-fake-parent'];
    const selfAndParentsB = [b.id, ...b.parents, 'common-fake-parent'];

    const intersection = selfAndParentsA.filter((element) =>
      selfAndParentsB.includes(element)
    );

    if (b.parents.includes(a.id)) {
      return -1;
    }

    if (a.parents.includes(b.id)) {
      return 1;
    }

    const lastCommonParent = intersection[0];

    const indexA = selfAndParentsA.indexOf(lastCommonParent) - 1;
    const indexB = selfAndParentsB.indexOf(lastCommonParent) - 1;

    const siblingA = selfAndParentsA[indexA];
    const siblingB = selfAndParentsB[indexB];

    const workSectionCodeA =
      workSectionClasses.find((data) => data.id === siblingA)?.code ?? '';

    const workSectionCodeB =
      workSectionClasses.find((data) => data.id === siblingB)?.code ?? '';

    if (workSectionCodeA < workSectionCodeB) {
      return -1;
    }

    if (workSectionCodeA > workSectionCodeB) {
      return 1;
    }

    return 0;
  });
};

export const mapFinanceWorkSections = (
  otherFinanceWorkSections: APIWorkPackage[],
  mappedWorkSectionClasses: MappedWorkSectionClass[],
  workPackagesWithParents: ExtendedScheduleWorkPackageType[]
): {
  id: string;
  code: string;
  name: string;
  scheduleWorkPackageIds: string[];
}[] => {
  let financeWorkSections: {
    id: string;
    code: string;
    name: string;
    scheduleWorkPackageIds: string[];
  }[] = [];

  for (let i = 0; i < otherFinanceWorkSections.length; i++) {
    const finaWp = otherFinanceWorkSections[i];

    const workSectionClass = finaWp.workSectionClassId;

    const manualLinkages = finaWp.workPackageVirtualSpaceLinkages
      ? finaWp.workPackageVirtualSpaceLinkages?.map((wp) =>
          getWorkPackageId({
            id: wp.nextgenWorkPackageId ?? '',
            virtual_space_id: wp.virtualSpaceId,
          })
        )
      : [];

    const workSectionClassesWithChildren = workSectionClass
      ? mappedWorkSectionClasses
          .filter(
            (data) =>
              data.parents.includes(workSectionClass) ||
              data.id === workSectionClass
          )
          .map((data) => data.id)
      : [];

    const allRelatedWorkPackages = getWorkPackageWithChildren(
      workSectionClassesWithChildren,
      manualLinkages,
      workPackagesWithParents,
      false
    ).map((wp) => wp.id);

    financeWorkSections.push({
      id: finaWp.id,
      code: finaWp.code,
      name: finaWp.name,
      scheduleWorkPackageIds: allRelatedWorkPackages,
    });
  }

  return financeWorkSections;
};

export const getScheduleTreeTableData = (
  allWorkPackagesToBeShown: ExtendedScheduleWorkPackageType[],
  workPackagesToBeIncludedInPoC?: ExtendedScheduleWorkPackageType[],
  deSelectDisabledIds?: string[],
  otherExistingLinkages?: {
    id: string;
    code: string;
    name: string;
    scheduleWorkPackageIds: string[];
  }[]
): (ExtendedScheduleWorkPackageType & {
  includedInPoC: boolean;
  deSelectDisabled: boolean;
  existingLinkages: {
    id: string;
    code: string;
    name: string;
    scheduleWorkPackageIds: string[];
  }[];
  parentNames?: string[];
  parentVirtualSpaceNames?: string[];
  parentWorkSectionClassNames?: string[];
  parentTeamNames?: string[];
  subRows: ExtendedScheduleWorkPackageType[];
})[] => {
  const allRowsGroupedByParentId = allWorkPackagesToBeShown.reduce(
    (acc, row) => {
      if (row.parent_id) {
        if (acc[row.parent_id]) {
          acc[row.parent_id]?.push(row);
        } else {
          acc[row.parent_id] = [row];
        }
      }

      return acc;
    },
    {} as Partial<Record<string, ExtendedScheduleWorkPackageType[]>>
  );

  const idsIncludedInPoC = workPackagesToBeIncludedInPoC
    ? new Set(workPackagesToBeIncludedInPoC.map((row) => row.id))
    : undefined;

  const process = (
    node: ExtendedScheduleWorkPackageType | undefined,
    parentNode?: ExtendedScheduleWorkPackageType
  ) => {
    if (!node) {
      return undefined;
    }

    const parentNames: string[] = parentNode
      ? [
          ...(parentNode?.parentNames ? parentNode.parentNames : []),
          parentNode.name,
        ]
      : [];

    const parentVirtualSpaceNames: string[] = parentNode
      ? [
          ...(parentNode?.parentVirtualSpaceNames
            ? parentNode.parentVirtualSpaceNames
            : []),
          ...(parentNode.virtualSpaceName ? [parentNode.virtualSpaceName] : []),
        ]
      : [];

    const parentWorkSectionClassNames: string[] = parentNode
      ? [
          ...(parentNode?.parentWorkSectionClassNames
            ? parentNode.parentWorkSectionClassNames
            : []),
          ...(parentNode.workSectionClassName
            ? [parentNode.workSectionClassName]
            : []),
        ]
      : [];

    const parentTeamNames: string[] = parentNode
      ? [
          ...(parentNode?.parentTeamNames ? parentNode.parentTeamNames : []),
          ...(parentNode.teamName ? [parentNode.teamName] : []),
        ]
      : [];

    let mappedRows: ExtendedScheduleWorkPackageType[] = [];

    // mimicked from next gen schedule GanttView.expand.ts
    // https://app.shortcut.com/smartservices/story/8088/gantt-special-handling-for-masters-with-just-one-instance
    // If a master has just one instance, we don't want to show the instance in the tree, but combine the information
    if (!(node.type === 'master' && node.instanceIds?.size === 1)) {
      const filteredRows = allRowsGroupedByParentId[node.id] ?? [];

      for (let i = 0; i < filteredRows.length; i++) {
        const workPackage = filteredRows[i];

        const processedRow = process({ ...workPackage }, node);

        if (processedRow) {
          mappedRows.push(processedRow);
        }
      }
    }

    const existingLinkages = otherExistingLinkages?.filter((linkage) =>
      linkage.scheduleWorkPackageIds.includes(node.id)
    );

    return {
      ...node,
      parentTeamNames,
      parentVirtualSpaceNames,
      parentWorkSectionClassNames,
      parentNames,
      includedInPoC:
        idsIncludedInPoC === undefined ? true : !!idsIncludedInPoC.has(node.id),
      deSelectDisabled:
        deSelectDisabledIds?.includes(node.id) ||
        (idsIncludedInPoC ? !idsIncludedInPoC.has(node.id) : false),
      existingLinkages: existingLinkages ?? [],
      subRows: mappedRows.sort(
        (a, b) => (a.order_number ?? Infinity) - (b.order_number ?? Infinity)
      ),
    };
  };

  const parentRows = allWorkPackagesToBeShown.filter((row) => !row.parent_id);

  const tree = parentRows
    .map((node) => process(node, undefined))
    .filter(isDefined)
    .sort(
      (a, b) => (a.order_number ?? Infinity) - (b.order_number ?? Infinity)
    );

  return tree;
};
