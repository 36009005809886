import { useRef } from 'react';

const useDeepCompareMemo = <T>(value: T, isEqual: (a: T, b: T) => boolean) => {
  const ref = useRef<T>();

  if (!ref.current || !isEqual(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
};

export default useDeepCompareMemo;
