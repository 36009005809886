import { useState, useRef, RefObject, useEffect } from 'react';

const useScrollTo = <T extends Element>(): [
  RefObject<T>,
  (val: boolean) => void,
  boolean,
] => {
  const ref = useRef<T>(null);
  const [shouldScrollTo, setShouldScrollTo] = useState(false);

  useEffect(() => {
    if (ref.current && shouldScrollTo) {
      ref.current!.scrollIntoView({
        block: 'center',
        behavior: 'smooth',
        inline: 'center',
      });
      setShouldScrollTo(false);
    }
  }, [shouldScrollTo]);

  return [ref, setShouldScrollTo, shouldScrollTo];
};

export default useScrollTo;
