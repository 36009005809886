import React, { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';

import {
  getProcumentAreaById,
  getProcurementAreaTotals,
} from '../../../store/reducers/procurementArea';

import { SelectOption as Column } from '../../../types/ui';

import useTxt from '../../../hooks/useTxt';

import {
  DeltaBigCostValue,
  BigPriceValue,
  DeltaBigProfitValue,
} from '../../../components/BigValue';
import { IconButton, NewPackageButton } from '../../../components/Buttons';
import Cell from '../../../components/Cell';
import { BoldedPrimaryRow } from '../../../components/Table';
import Txt from '../../../components/Txt';

import CAN, {
  CaslProjectRequestParams,
} from '../../../utils/caslUserPermissions';
import { isClickOrKeyboardSelection } from '../../../utils/mouseOrKeyInteraction';

import { IconPlus, IconDown, IconRight } from '../../../assets/svg';

import { PREDICTION_CHANGE_DISPLAY_TRESHOLD } from './ProcurementArea';

type Props = {
  procurementAreaId: string;
  isOpen: boolean;
  shouldFocusOnMount: boolean;
  onClick: () => void;
  onAddOrder: () => void;
  columns: Column[];
};

const ProcurementAreaRow = ({
  procurementAreaId,
  isOpen,
  shouldFocusOnMount,
  onClick,
  onAddOrder,
  columns,
}: Props) => {
  const rowRef = useRef<HTMLTableRowElement>(null);

  const procurementArea = useSelector(getProcumentAreaById(procurementAreaId));

  const ability = new CaslProjectRequestParams(
    procurementArea?.projectId ?? ''
  );
  const allowedUser = CAN('write', ability);

  const {
    targetTotal,
    predictionTotal,
    predictionChangeTotal,
    changeOrderTotal,
    contractTotal,
    receivedTotal,
    reservesTotal,
    additionalTargetTotal,
  } = useSelector(getProcurementAreaTotals(procurementAreaId));

  useEffect(() => {
    if (rowRef.current && shouldFocusOnMount) {
      rowRef.current.focus();
    }
  }, [shouldFocusOnMount]);

  const procurementAreaOpenText = useTxt(
    'project.procurementTable.procurementAreaRow.open'
  );

  const procurementAreaClosedText = useTxt(
    'project.procurementTable.procurementAreaRow.closed'
  );

  if (!procurementArea) {
    return null;
  }

  const onProcurementAreaClickOrKeyPress = (
    e: React.KeyboardEvent | React.MouseEvent
  ) => {
    e.stopPropagation();

    if (isClickOrKeyboardSelection(e)) {
      e.preventDefault();
      onClick();
    }
  };

  const onAddOrderClickOrKeyPress = (
    e: React.KeyboardEvent | React.MouseEvent
  ) => {
    e.stopPropagation();

    if (isClickOrKeyboardSelection(e)) {
      e.preventDefault();
      onAddOrder();
    }
  };

  const renderRowValue = (column: Column) => {
    const targetDiff = targetTotal.minus(predictionTotal);
    switch (column.id) {
      case '1':
        return procurementArea.code;
      case '2':
        return procurementArea.name;
      case '3':
        return <BigPriceValue value={targetTotal} decimals={0} />;
      case '4':
        return targetDiff.abs().gte(PREDICTION_CHANGE_DISPLAY_TRESHOLD) ? (
          <DeltaBigProfitValue value={targetDiff} decimals={0} />
        ) : null;
      case '5':
        return <BigPriceValue value={predictionTotal} decimals={0} />;
      case '6':
        return predictionChangeTotal
          .abs()
          .gte(PREDICTION_CHANGE_DISPLAY_TRESHOLD) ? (
          <DeltaBigCostValue value={predictionChangeTotal} decimals={0} />
        ) : null;
      case '7':
        return <BigPriceValue value={contractTotal} decimals={0} />;
      case '8':
        return <BigPriceValue value={changeOrderTotal} decimals={0} />;
      case '9':
        return <BigPriceValue value={reservesTotal} decimals={0} />;
      case '11':
        return <BigPriceValue value={receivedTotal} decimals={0} />;
      case '12':
        return (
          <BigPriceValue
            value={predictionTotal.minus(receivedTotal)}
            decimals={0}
          />
        );
      case '15':
        return <BigPriceValue value={additionalTargetTotal} decimals={0} />;

      default:
        return null;
    }
  };

  const setCellAlignment = (
    column: Column
  ): 'right' | 'left' | 'center' | undefined => {
    if (column.id === '1' || column.id === '2') {
      return;
    }

    return 'right';
  };

  return (
    <BoldedPrimaryRow
      data-testid="procurement-area"
      clickable
      onClick={onProcurementAreaClickOrKeyPress}
      onKeyPress={onProcurementAreaClickOrKeyPress}
      tabIndex={0}
      ref={rowRef}
    >
      <Cell>
        <IconButton
          onKeyDown={onProcurementAreaClickOrKeyPress}
          onClick={onProcurementAreaClickOrKeyPress}
          icon={isOpen ? IconDown : IconRight}
          aria-label={
            isOpen ? procurementAreaOpenText : procurementAreaClosedText
          }
        />
      </Cell>
      {columns
        ?.filter((c) => c.selected)
        .map((column) => (
          <Cell
            key={column.id}
            align={setCellAlignment(column)}
            colSpan={column.id === '2' ? 2 : 1}
            data-testid={`procurement-area-prediction-change-${procurementAreaId}-${column.id}`}
          >
            {renderRowValue(column)}
          </Cell>
        ))}
      <Cell
        width="7rem"
        data-testid={`procurement-area-add-order-${procurementAreaId}`}
      >
        {allowedUser ? (
          <NewPackageButton
            onClick={onAddOrderClickOrKeyPress}
            onKeyPress={onAddOrderClickOrKeyPress}
          >
            <IconPlus className="button-icon" />
            <Txt id="project.procurementTable.procurementAreaRow" />
          </NewPackageButton>
        ) : null}
      </Cell>
    </BoldedPrimaryRow>
  );
};

export default ProcurementAreaRow;
