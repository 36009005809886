import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Big from 'big.js';
import { v4 } from 'uuid';

import { getOrderById } from '../../../../store/reducers/order/order';

import { removeOrderRowAnalysisLinkage } from '../../../../store/actions/analysis/orderRowLinkage';

import { APIOrderRow } from '../../../../types/api';

import useRouteParams from '../../../../hooks/useRouteParams';
import useTxt from '../../../../hooks/useTxt';

import { SecondaryRow } from '../../../../components/Table';
import UnlinkIconButton from '../../../../components/UnlinkIconButton';

import { priceFormat } from '../../../../utils/big';
import CAN, {
  CaslAnalysisDefinitionRequestParams,
} from '../../../../utils/caslUserPermissions';

import { IconToLinkedEntity } from '../../../../assets';

import { generateUrl, routes } from '../../../../routes';

import { RightPaddedCell, StyledCell } from '../ModalComponents';

type TableRowProps = {
  orderRow: APIOrderRow;
  analysisRowId: string;
};

export const TableRow = ({
  analysisRowId,
  orderRow: { id, orderId, rowNumber, description, unitPrice, quantity },
}: TableRowProps) => {
  const dispatch = useDispatch();

  const { visibleCode, name } = useSelector(getOrderById(orderId)) ?? {
    visibleCode: '',
    name: '',
  };

  const { projectId } = useRouteParams();
  const requestId = v4();

  const linkedEntityAltText = useTxt('analysis.modal.linkedEntity.altText');

  const unlinkOrderRow = () =>
    dispatch(
      removeOrderRowAnalysisLinkage({
        entityId: id,
        projectId,
        requestId,
        analysisRowId,
      })
    );

  const ability = new CaslAnalysisDefinitionRequestParams(projectId);
  const allowedUser = CAN('write', ability);

  return (
    <SecondaryRow
      key={id}
      data-testid={`analysis-order-row-modal-order-row-${id}`}
    >
      <StyledCell align="right">{rowNumber}</StyledCell>
      <StyledCell>{description}</StyledCell>
      <StyledCell>{`${visibleCode} ${name}`}</StyledCell>
      <StyledCell>
        <UnlinkIconButton
          onClick={unlinkOrderRow}
          testId={`unlink-analysis-row-${id}`}
          disabled={!allowedUser}
        />
      </StyledCell>
      <StyledCell>
        {allowedUser ? (
          <Link
            data-testid={`navigate-to-order-row-${id}`}
            to={generateUrl({
              route: routes.ORDER_WITH_ORDER_ROW_FOCUSED,
              viewMode: 'edit',
              projectId,
              orderId,
              orderRowId: id,
            })}
          >
            <img src={IconToLinkedEntity} alt={linkedEntityAltText} />
          </Link>
        ) : null}
      </StyledCell>
      <RightPaddedCell align="right">
        {priceFormat((unitPrice ?? new Big(0)).mul(quantity ?? new Big(0)))}
      </RightPaddedCell>
    </SecondaryRow>
  );
};
