import Big from 'big.js';
import { sortBy } from 'lodash';
import { Reducer } from 'redux';

import { ID, HandlingState } from '../../types/general';

import {
  assertActionPayloadIsNotApiUpdatedEntities,
  isUpdatedEntitiesActionType,
  Selector,
} from './utils';
import * as api from '../../utils/api';
import { isDefined, ACTUAL_COST_STATUS_NOT_HANDLED } from '../../utils/general';
import normalizeBy from '../../utils/normalizeBy';
import * as remoteData from '../../utils/remoteData';

import { ActionTypes } from '../actionTypes';
import { getSortOrders } from './actualCosts/sortActualCosts';
import { getAnalysisRowsForProject } from './analysis/row';
import { sortItems } from './helpers/sort';
import { getOrderById } from './order/order';

import { AppState } from '.';

export type ActualCost = {
  id: ID;
  entryNo: string;
  orderId: ID;
  projectId: ID | null;
  date: Date;
  documentNumber: string;
  description: string;
  createdAt: Date;
  updatedAt: Date;
  statusId: ID;
  supplierId: ID | null;
  supplierName: string | null;
  invoiceLink: string | null;
  actualCostsDetailLineIds: ID[];
  actualCostsAttachmentIds: ID[];
  amount: Big;
  handleAmountPending: Big;
  handlingState: HandlingState;
  latestUpdater: string | null;
  latestStatusChangeAt: Date | null;
  isMovable: boolean;
  comment: string | null;
};

type Err = api.BackendError | undefined;

export type ActualCostState = {
  requests: Partial<Record<string, remoteData.RemoteData<undefined, Err>>>;
  putRequests: Partial<Record<string, remoteData.RemoteData<undefined, Err>>>;
  analysisRowRequests: Partial<
    Record<string, remoteData.RemoteData<undefined, Err>>
  >;
  moveRequests: Partial<Record<string, remoteData.RemoteData<undefined, Err>>>;
  rehandleRequests: Partial<
    Record<string, remoteData.RemoteData<undefined, Err>>
  >;
  data: Partial<Record<string, ActualCost>>;
};

const initialState: ActualCostState = {
  requests: {},
  putRequests: {},
  analysisRowRequests: {},
  moveRequests: {},
  rehandleRequests: {},
  data: {},
};

const actualCostReducer: Reducer<ActualCostState, ActionTypes> = (
  state = initialState,
  action
): ActualCostState => {
  switch (action.type) {
    case 'GET_ACTUAL_COSTS_STARTED': {
      const { orderId } = action.payload;
      const requests = { ...state.requests, [orderId]: remoteData.loading };

      return {
        ...state,
        requests,
      };
    }
    case 'GET_ACTUAL_COSTS_FAILURE': {
      const { orderId, error } = action.payload;
      const requests = { ...state.requests, [orderId]: remoteData.fail(error) };

      return { ...state, requests };
    }
    case 'GET_ACTUAL_COSTS_SUCCESS': {
      const { orderId, actualCosts } = action.payload;

      const requests = {
        ...state.requests,
        [orderId]: remoteData.succeed(undefined),
      };

      const data = {
        ...state.data,
        ...normalizeBy('id', actualCosts),
      };

      return {
        ...state,
        requests,
        data,
      };
    }
    case 'GET_ACTUAL_COSTS_FOR_ANALYSIS_ROW_STARTED': {
      const { analysisRowId } = action.payload;

      const analysisRowRequests = {
        ...state.analysisRowRequests,
        [analysisRowId]: remoteData.loading,
      };

      return {
        ...state,
        analysisRowRequests,
      };
    }
    case 'GET_ACTUAL_COSTS_FOR_ANALYSIS_ROW_FAILURE': {
      const { analysisRowId, error } = action.payload;

      const analysisRowRequests = {
        ...state.analysisRowRequests,
        [analysisRowId]: remoteData.fail(error),
      };

      return { ...state, analysisRowRequests };
    }
    case 'GET_ACTUAL_COSTS_FOR_ANALYSIS_ROW_SUCCESS': {
      const { analysisRowId, actualCosts } = action.payload;

      const analysisRowRequests = {
        ...state.analysisRowRequests,
        [analysisRowId]: remoteData.succeed(undefined),
      };

      const data = {
        ...state.data,
        ...normalizeBy('id', actualCosts),
      };

      return {
        ...state,
        analysisRowRequests,
        data,
      };
    }
    case 'PUT_ACTUAL_COST_STARTED': {
      const { actualCostId } = action.payload;

      const putRequests = {
        ...state.putRequests,
        [actualCostId]: remoteData.loading,
      };

      return {
        ...state,
        putRequests,
      };
    }
    case 'PUT_ACTUAL_COST_FAILURE': {
      const { actualCostId, error } = action.payload;

      const putRequests = {
        ...state.putRequests,
        [actualCostId]: remoteData.fail(error),
      };

      return { ...state, putRequests };
    }
    case 'PUT_ACTUAL_COST_SUCCESS': {
      const { actualCostId, actualCosts: updatedActualCosts = [] } =
        action.payload;

      const putRequests = {
        ...state.putRequests,
        [actualCostId]: remoteData.succeed(undefined),
      };

      if (updatedActualCosts.length === 0) {
        return {
          ...state,
          ...putRequests,
        };
      }

      const data = { ...state.data };

      updatedActualCosts.forEach((actualCost) => {
        const { id } = actualCost;
        data[id] = actualCost;
      });

      return {
        ...state,
        ...putRequests,
        data,
      };
    }
    case 'PUT_ACTUAL_COST_MOVE_STARTED': {
      const { actualCostId } = action.payload;

      const moveRequests = {
        ...state.moveRequests,
        [actualCostId]: remoteData.loading,
      };

      return {
        ...state,
        moveRequests,
      };
    }
    case 'PUT_ACTUAL_COST_MOVE_FAILURE': {
      const { actualCostId, error } = action.payload;

      const moveRequests = {
        ...state.moveRequests,
        [actualCostId]: remoteData.fail(error),
      };

      return { ...state, moveRequests };
    }
    case 'PUT_ACTUAL_COST_MOVE_SUCCESS': {
      const { actualCostId, actualCosts: updatedActualCosts = [] } =
        action.payload;

      if (updatedActualCosts.length === 0) {
        return state;
      }

      const data = { ...state.data };

      const moveRequests = {
        ...state.moveRequests,
        [actualCostId]: remoteData.succeed(undefined),
      };

      updatedActualCosts.forEach((actualCost) => {
        const { id } = actualCost;
        data[id] = actualCost;
      });

      return {
        ...state,
        moveRequests,
        data,
      };
    }
    case 'PUT_ACTUAL_COST_REHANDLE_STARTED': {
      const { actualCostId } = action.payload;

      const rehandleRequests = {
        ...state.rehandleRequests,
        [actualCostId]: remoteData.loading,
      };

      return {
        ...state,
        rehandleRequests,
      };
    }
    case 'PUT_ACTUAL_COST_REHANDLE_FAILURE': {
      const { actualCostId, error } = action.payload;

      const rehandleRequests = {
        ...state.rehandleRequests,
        [actualCostId]: remoteData.fail(error),
      };

      return { ...state, rehandleRequests };
    }
    case 'PUT_ACTUAL_COST_REHANDLE_SUCCESS': {
      const { actualCostId, actualCosts: updatedActualCosts = [] } =
        action.payload;

      const rehandleRequests = {
        ...state.rehandleRequests,
        [actualCostId]: remoteData.succeed(undefined),
      };

      if (updatedActualCosts.length === 0) {
        return {
          ...state,
          ...rehandleRequests,
        };
      }

      const data = { ...state.data };

      updatedActualCosts.forEach((actualCost) => {
        const { id } = actualCost;
        data[id] = actualCost;
      });

      return {
        ...state,
        ...rehandleRequests,
        data,
      };
    }
  }

  if (isUpdatedEntitiesActionType(action)) {
    const { actualCosts: updatedActualCosts = [] } = action.payload;

    if (updatedActualCosts.length === 0) {
      return state;
    }

    const data = { ...state.data };

    updatedActualCosts.forEach((actualCost) => {
      const { id } = actualCost;
      data[id] = actualCost;
    });

    return {
      ...state,
      data,
    };
  }

  assertActionPayloadIsNotApiUpdatedEntities(action);

  return state;
};

export const selectOrderActualCostsRequests =
  (orderId: string) =>
  ({
    actualCosts: {
      actualCostCollection: {
        requests: { [orderId]: requestState = remoteData.notAsked },
      },
    },
  }: AppState) =>
    requestState;

export const getActualCostMoveRequest =
  (actualCostId: string) =>
  ({
    actualCosts: {
      actualCostCollection: {
        moveRequests: { [actualCostId]: requestState = remoteData.notAsked },
      },
    },
  }: AppState) =>
    requestState;

export const getActualCostRehandleRequest =
  (actualCostId: string) =>
  ({
    actualCosts: {
      actualCostCollection: {
        rehandleRequests: {
          [actualCostId]: requestState = remoteData.notAsked,
        },
      },
    },
  }: AppState) =>
    requestState;

export const selectAnalysisRowActualCostsRequests =
  (analysisRowId: string) =>
  ({
    actualCosts: {
      actualCostCollection: {
        analysisRowRequests: {
          [analysisRowId]: requestState = remoteData.notAsked,
        },
      },
    },
  }: AppState) =>
    requestState;

export const getActualCostById =
  (id: string) =>
  ({
    actualCosts: {
      actualCostCollection: {
        data: { [id]: actualCost },
      },
    },
  }: AppState) =>
    actualCost;

export const getActualCostsByIds =
  (ids: string[]) =>
  ({
    actualCosts: {
      actualCostCollection: { data },
    },
  }: AppState) => {
    const emptyArray: ActualCost[] = [];

    const costs = ids.reduce((array, id) => {
      const cost = data[id];

      if (cost) {
        const newArray = array.concat(cost);

        return newArray;
      }

      return array;
    }, emptyArray);

    return costs;
  };

export const getActualCostsByOrderId =
  (orderId: string) => (appState: AppState) => {
    const actualCosts = getOrderById(orderId)(appState)?.actualCostIds ?? [];

    return actualCosts
      .map((id) => getActualCostById(id)(appState))
      .filter(isDefined);
  };

export const getFirstHandleableActualCostByOrderId =
  (orderId: string) => (appState: AppState) => {
    const orderActualCosts = getActualCostsByOrderId(orderId)(appState);

    const firstHandleableAc = orderActualCosts.find(
      (cost) => cost.handlingState === 'CanBeHandled'
    );

    return firstHandleableAc;
  };

export const getUnhandledActualCostsByOrderId =
  (orderId: string) => (appState: AppState) => {
    const allActualCostsForOrder =
      getSortedActualCostsByOrderId(orderId)(appState);
    const sortOrder = getSortOrders(appState);

    const unhandledCosts = allActualCostsForOrder?.filter(
      (cost) => cost.statusId === ACTUAL_COST_STATUS_NOT_HANDLED
    );

    return sortItems(unhandledCosts, sortOrder);
  };

export const getUnhandledUnhandledActualCostCountByOrderId =
  (orderId: string) => (appState: AppState) => {
    return getUnhandledActualCostsByOrderId(orderId)(appState).length;
  };

export const getHandledActualCostsByOrderId =
  (orderId: string) => (appState: AppState) => {
    const allActualCostsForOrder =
      getSortedActualCostsByOrderId(orderId)(appState);

    const sortOrder = getSortOrders(appState);

    const handledCosts = allActualCostsForOrder?.filter(
      (cost) => cost.statusId !== ACTUAL_COST_STATUS_NOT_HANDLED
    );

    return sortItems(handledCosts, sortOrder);
  };

export const getSortedActualCostsByOrderId =
  (orderId: string) => (appState: AppState) => {
    const orderActualCosts = getActualCostsByOrderId(orderId)(appState);

    const sortedActualCosts = sortBy(
      orderActualCosts,
      (cost) => -Number(cost.id)
    );

    return sortedActualCosts;
  };

export const getActualCostsForAnalysisRow =
  ({
    projectId,
    analysisRowId,
  }: {
    projectId: string;
    analysisRowId: string;
  }) =>
  (appState: AppState): remoteData.RemoteData<ActualCost[]> => {
    const requestState =
      appState.actualCosts.actualCostCollection.analysisRowRequests[
        analysisRowId
      ] ?? remoteData.notAsked;

    const { data } = appState.actualCosts.actualCostCollection;

    const allAnalysisRows = getAnalysisRowsForProject(projectId)(appState);

    const analysisRowWithDefault = remoteData
      .withDefault(allAnalysisRows, [])
      .find((row) => row.id === analysisRowId);

    const remoteState = remoteData.map(requestState, (_) => {
      if (!analysisRowWithDefault) {
        return [];
      }

      const invoiceIds = analysisRowWithDefault.linkedActualCostIds;

      const invoices = Object.values(data)
        .filter(isDefined)
        .filter((invoice) => invoiceIds.includes(invoice.id));

      return invoices;
    });

    return remoteState;
  };

export const getActualCostUpdateRequest =
  (actualCostId: string): Selector<remoteData.RemoteAction> =>
  ({
    actualCosts: {
      actualCostCollection: {
        putRequests: { [actualCostId]: request },
      },
    },
  }) =>
    request ?? remoteData.notAsked;

export default actualCostReducer;
