import styled, { css } from 'styled-components';

import Cell from '../../../../../components/Cell';
import { NumberIndicator } from '../../../../../components/Navigation/Components';

type SectionTitleBarProps = {
  icon?: string;
};

export const SectionTitleBar = styled.div<SectionTitleBarProps>`
  padding: ${(props) => props.theme.margin[24]}
    ${(props) => props.theme.margin[8]};

  height: 2rem;

  display: flex;
  justify-content: space-between;
  align-items: center;

  font-weight: bold;

  ${(props) =>
    props.icon
      ? css`
          padding-left: 2.5rem;
          background-repeat: no-repeat;
          background-position: 1rem center;
          background-image: url(${props.icon});
        `
      : ''}
`;

export const SectionSpacer = styled.div`
  margin-bottom: ${(props) => props.theme.margin[40]};
  width: 100%;
`;

export const ToggleBar = styled.div`
  position: relative;
  margin: 1rem 0;
  display: flex;
  align-items: flex-end;
`;

export const AssignmentBoxContainer = styled.div`
  position: sticky;
  top: 4.25rem;

  padding: ${(props) => `${props.theme.margin[8]} ${props.theme.margin[24]}`};

  height: 2rem;

  display: flex;
  align-items: center;

  background: ${({ theme }) => theme.color.toolbarHighlight};

  z-index: 30;
`;

export const AttachmentCount = styled(NumberIndicator)`
  left: 10px;
  bottom: -0.4rem;
  top: inherit;
  background-color: ${(props) => props.theme.color.indicatorBackground};
`;

type IndicatorImgProps = {
  isCompact?: boolean;
};

export const IndicatorImg = styled.img<IndicatorImgProps>`
  margin-right: ${({ theme, isCompact }) =>
    isCompact ? theme.margin[4] : theme.margin[20]};
`;

type InvoiceActiveProp = {
  isActive?: boolean;
};

export const TableBodyGap = styled.tr<InvoiceActiveProp>`
  border-left: 0.16rem solid ${(props) => props.theme.color.white};
  border-right: 0.12rem solid ${(props) => props.theme.color.white};
  height: 0.5rem;

  ${(props) =>
    props.isActive &&
    css`
      border-top: 0.05rem solid ${props.theme.color.buttonPrimaryDisabled};
    `}
`;

export const InvoiceTableBody = styled.tbody<InvoiceActiveProp>`
  border-right: 1px solid ${(props) => props.theme.color.toolbarHighlight};
  border-left: 1px solid ${(props) => props.theme.color.toolbarHighlight};

  ${(props) =>
    props.isActive &&
    css`
      border-left: 0.15rem solid ${props.theme.color.darkpurple};
    `}
`;

export const ProcessingCell = styled(Cell)<InvoiceActiveProp>`
  border-left: 1px solid ${(props) => props.theme.color.toolbarHighlight};
  border-right: 1px solid ${(props) => props.theme.color.toolbarHighlight};
  border-top: 1px solid white;

  padding: 0;

  height: 2rem;
`;
