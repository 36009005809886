import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';

import {
  APIAssignArrivalRowsPutBody,
  APIUpdatedEntities,
  RawAPIUpdatedEntities,
  APIUpdateArrivalRowsPutBody,
} from '../../types/api';
import { ID } from '../../types/general';
import { mapRawUpdatedEntities } from '../../types/mappers';

import {
  makeApiActions,
  ExtractActionTypes,
  makeAction,
} from '../../utils/actionCreators';
import {
  apiErrorHandlingWithDecode,
  GET,
  PUT,
  DELETE,
  BackendError,
  POST,
} from '../../utils/api';
import { dateString, bigString } from '../../utils/decoders';
import { flow } from '../../utils/function';
import * as remoteData from '../../utils/remoteData';
import { createAsyncThunk, Thunk } from '../../utils/thunk';

import {
  ArrivalRow,
  selectOrderArrivalRowsRequests,
  getArrivalRowDeleteRequest,
} from '../reducers/arrivalRow';

export type ArrivalRowAction = ExtractActionTypes<typeof actionCreators>;

const actionCreators = {
  ...makeAction('getArrivalRowsStarted')<{ orderId: string }>(),
  ...makeAction('getArrivalRowsFailure')<{
    orderId: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('getArrivalRowsSuccess')<{
    orderId: string;
    arrivalRows: ArrivalRow[];
  }>(),
  ...makeApiActions('put', 'arrivalRows')<APIUpdatedEntities>(),
  ...makeAction('deleteArrivalRowStarted')<{
    arrivalRowId: string;
  }>(),
  ...makeAction('deleteArrivalRowSuccess')<
    APIUpdatedEntities & {
      arrivalRowId: string;
    }
  >(),
  ...makeAction('deleteArrivalRowFailure')<{
    arrivalRowId: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('deleteMultipleArrivalRowsStarted')<{
    requestId: string;
    arrivalRowIds: string[];
  }>(),
  ...makeAction('deleteMultipleArrivalRowsSuccess')<
    APIUpdatedEntities & {
      requestId: string;
      arrivalRowIds: string[];
    }
  >(),
  ...makeAction('deleteMultipleArrivalRowsFailure')<{
    requestId: string;
    arrivalRowIds: string[];
    error: BackendError | undefined;
  }>(),
  ...makeApiActions('put', 'arrivalRow')<APIUpdatedEntities>(),
  ...makeAction('getArrivalRowsForAnalysisRowStarted')<{
    analysisRowId: string;
  }>(),
  ...makeAction('getArrivalRowsForAnalysisRowFailure')<{
    analysisRowId: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('getArrivalRowsForAnalysisRowSuccess')<{
    analysisRowId: string;
    arrivalRows: ArrivalRow[];
  }>(),
};
export const {
  getArrivalRowsStarted,
  getArrivalRowsSuccess,
  getArrivalRowsFailure,
  putArrivalRowsStarted,
  putArrivalRowsSuccess,
  putArrivalRowsFailure,
  deleteArrivalRowStarted,
  deleteArrivalRowSuccess,
  deleteArrivalRowFailure,
  deleteMultipleArrivalRowsStarted,
  deleteMultipleArrivalRowsSuccess,
  deleteMultipleArrivalRowsFailure,
  putArrivalRowStarted,
  putArrivalRowSuccess,
  putArrivalRowFailure,
  getArrivalRowsForAnalysisRowFailure,
  getArrivalRowsForAnalysisRowStarted,
  getArrivalRowsForAnalysisRowSuccess,
} = actionCreators;

const apiArrivalRowType = t.exact(
  t.type({
    id: t.string,
    arrivalId: t.string,
    orderRowId: t.union([t.string, t.null]),
    orderId: t.union([t.string, t.null]),
    purchaseInvoiceHeaderId: t.union([t.string, t.null]),
    actualCostId: t.union([t.string, t.null]),
    quantity: bigString,
    unitPrice: bigString,
    createdAt: dateString,
    updatedAt: dateString,
    isDeleted: t.boolean,
    vatCodeId: t.union([t.string, t.null]),
    accountId: t.union([t.string, t.null]),
    description: t.union([t.string, t.null]),
  })
);

export async function toArrivalRows(u: unknown): Promise<ArrivalRow[]> {
  const apiArrivalRows = await tPromise.decode(t.array(apiArrivalRowType), u);

  return apiArrivalRows;
}

async function getArrivalRowsForOrder(orderId: ID): Promise<ArrivalRow[]> {
  const response = await GET(`v1/orders/${orderId}/arrival-rows`);

  return toArrivalRows(response);
}

export const fetchArrivalRowsForOrder = (orderId: ID) =>
  createAsyncThunk(getArrivalRowsForOrder, {
    args: [orderId],
    isPending: flow(
      selectOrderArrivalRowsRequests(orderId),
      remoteData.isLoading
    ),
    initialAction: getArrivalRowsStarted({ orderId }),
    successActionCreator: (arrivalRows) =>
      getArrivalRowsSuccess({ orderId, arrivalRows }),
    failureActionCreator: (error) =>
      getArrivalRowsFailure({
        orderId,
        error: apiErrorHandlingWithDecode(error),
      }),
  });

async function getArrivalRowsForAnalysisRow(
  analysisRowId: ID
): Promise<ArrivalRow[]> {
  const response = await GET(
    `v1/custom-fields/list-items/${analysisRowId}/arrival-rows`
  );

  return toArrivalRows(response);
}

export const fetchArrivalRowsForAnalysisRow = (analysisRowId: ID) =>
  createAsyncThunk(getArrivalRowsForAnalysisRow, {
    args: [analysisRowId],
    isPending: flow(
      selectOrderArrivalRowsRequests(analysisRowId),
      remoteData.isLoading
    ),
    initialAction: getArrivalRowsForAnalysisRowStarted({ analysisRowId }),
    successActionCreator: (arrivalRows) =>
      getArrivalRowsForAnalysisRowSuccess({ analysisRowId, arrivalRows }),
    failureActionCreator: (error) =>
      getArrivalRowsForAnalysisRowFailure({
        analysisRowId,
        error: apiErrorHandlingWithDecode(error),
      }),
  });

// TODO FIXME:validate response;
async function putAssignArrivalRowsToInvoice(
  body: APIAssignArrivalRowsPutBody
): Promise<APIUpdatedEntities> {
  const rawResponse = await PUT<RawAPIUpdatedEntities>(
    'v1/arrival-rows/assign',
    body
  );

  // FIXME: Should let reducers a single dispatched action for this.
  return mapRawUpdatedEntities(rawResponse);
}

export const assignArrivalRowsToInvoice =
  (body: APIAssignArrivalRowsPutBody): Thunk =>
  (dispatch) => {
    dispatch(putArrivalRowsStarted());

    putAssignArrivalRowsToInvoice(body).then(
      (updatedEntities) => {
        dispatch(putArrivalRowsSuccess(updatedEntities));
      },
      (error) => {
        dispatch(putArrivalRowsFailure(apiErrorHandlingWithDecode(error)));
      }
    );
  };

async function deleteArrivalRowById(
  arrivalRowId: ID
): Promise<APIUpdatedEntities> {
  const response = await DELETE<RawAPIUpdatedEntities>(
    `v1/arrival-rows/${arrivalRowId}`
  );

  return mapRawUpdatedEntities(response);
}

type DeleteArrivalRowRequest = {
  arrivalRowId: string;
};

export const deleteArrivalRow =
  ({ arrivalRowId }: DeleteArrivalRowRequest): Thunk =>
  (dispatch) => {
    dispatch(
      createAsyncThunk(deleteArrivalRowById, {
        args: [arrivalRowId],
        isPending: flow(
          getArrivalRowDeleteRequest(arrivalRowId),
          remoteData.isLoading
        ),
        initialAction: deleteArrivalRowStarted({ arrivalRowId }),
        successActionCreator: (updatedEntities) =>
          deleteArrivalRowSuccess({
            ...updatedEntities,
            arrivalRowId,
          }),
        failureActionCreator: (error) =>
          deleteArrivalRowFailure({
            arrivalRowId,
            error: apiErrorHandlingWithDecode(error),
          }),
      })
    );
  };

export const updateArrivalRow =
  (arrivalRowId: ID, updates: APIUpdateArrivalRowsPutBody): Thunk =>
  (dispatch) => {
    dispatch(putArrivalRowStarted());
    PUT<RawAPIUpdatedEntities>(`v1/arrival-rows/${arrivalRowId}`, updates)
      .then(mapRawUpdatedEntities)
      .then(
        (updatedEntities) => {
          dispatch(putArrivalRowSuccess(updatedEntities));
        },
        (error) => {
          dispatch(putArrivalRowFailure(apiErrorHandlingWithDecode(error)));
        }
      );
  };

async function postDeleteMultipleArrivalRowsRequest(
  arrivalRowIds: ID[]
): Promise<APIUpdatedEntities> {
  const response = await POST<RawAPIUpdatedEntities>(
    `v1/arrival-rows/delete-multiple`,
    { arrivalRowIds }
  );

  return mapRawUpdatedEntities(response);
}

export const deleteMultipleArrivalRows =
  (requestId: string, arrivalRowIds: ID[]): Thunk =>
  (dispatch) => {
    dispatch(
      createAsyncThunk(postDeleteMultipleArrivalRowsRequest, {
        args: [arrivalRowIds],
        isPending: flow(
          getArrivalRowDeleteRequest(requestId),
          remoteData.isLoading
        ),
        initialAction: deleteMultipleArrivalRowsStarted({
          arrivalRowIds,
          requestId,
        }),
        successActionCreator: (updatedEntities) =>
          deleteMultipleArrivalRowsSuccess({
            ...updatedEntities,
            arrivalRowIds,
            requestId,
          }),
        failureActionCreator: (error) =>
          deleteMultipleArrivalRowsFailure({
            arrivalRowIds,
            requestId,
            error: apiErrorHandlingWithDecode(error),
          }),
      })
    );
  };
