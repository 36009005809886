import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import FocusLock from 'react-focus-lock';

import styled, { css } from 'styled-components';

import { IconCloseWhite, IconCloseBlack } from '../../assets/svg';

import {
  ButtonGroup,
  IconButton,
  PrimaryButton,
  SecondaryButton,
} from '../Buttons';
import Txt from '../Txt';
import { ModalOverlay, ModalContent } from './Components';

type Props = {
  children: React.ReactNode;
  ariaLabelledBy?: string;
  onClose: () => void;
  closeButton?: React.ComponentType<{ onClose: () => void }>;
  closingConfirmation?: boolean;
  blockEscapeKey?: boolean;
  staticPosition?: boolean;
};

const Modal = ({
  children,
  ariaLabelledBy,
  onClose: originalOnClose,
  closeButton: CloseButtonComponent = DefaultCloseButton,
  closingConfirmation,
  blockEscapeKey,
  staticPosition,
}: Props) => {
  const [showConfirmation, setShowConfirmation] = React.useState(false);

  const onClose = () => {
    if (closingConfirmation) {
      return setShowConfirmation(true);
    }

    return originalOnClose();
  };
  // Disable body scrolling when modal is visible.
  useEffect(() => {
    const originalStyle = document.body.style.overflow;
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = originalStyle;
    };
  }, []);

  // Close modal if user presses ESC key
  const onKeyPress = (e: KeyboardEvent) => {
    if (e.code === 'Escape' && !blockEscapeKey) {
      onClose();
    }
  };

  useEffect(() => {
    window.addEventListener('keyup', onKeyPress);

    return () => {
      window.removeEventListener('keyup', onKeyPress);
    };
  });

  const stopPropagation = (e: React.SyntheticEvent) => {
    e.stopPropagation();
  };

  const modalContent = (
    <>
      {showConfirmation ? (
        <ClosingConfirmation
          onClose={() => setShowConfirmation(false)}
          closeParentModal={originalOnClose}
        />
      ) : null}
      <ModalOverlay onMouseDown={onClose}>
        <ModalContent
          role="dialog"
          onClick={stopPropagation}
          onMouseDown={stopPropagation}
          aria-labelledby={ariaLabelledBy}
          staticPosition={staticPosition}
        >
          <FocusLock>
            <CloseButtonComponent onClose={onClose} />
            {children}
          </FocusLock>
        </ModalContent>
      </ModalOverlay>
    </>
  );

  return ReactDOM.createPortal(modalContent, document.body);
};

const DefaultCloseButton = ({ onClose }: { onClose: () => void }) => {
  return (
    <CloseButton
      onClick={onClose}
      type="button"
      icon={IconCloseWhite}
      tabIndex={0}
    />
  );
};

type ContentProps = {
  noMaxHeight?: boolean;
};

const ClosingConfirmation = ({
  onClose,
  closeParentModal,
}: {
  onClose: () => void;
  closeParentModal: () => void;
}) => {
  // Disable body scrolling when modal is visible.
  useEffect(() => {
    const originalStyle = document.body.style.overflow;
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = originalStyle;
    };
  }, []);

  // Close modal if user presses ESC key
  const onKeyPress = (e: KeyboardEvent) => {
    if (e.code === 'Escape') {
      onClose();
    }
  };

  useEffect(() => {
    window.addEventListener('keyup', onKeyPress);

    return () => {
      window.removeEventListener('keyup', onKeyPress);
    };
  });

  const stopPropagation = (e: React.SyntheticEvent) => {
    e.stopPropagation();
  };

  const modalContent = (
    <ModalOverlay onMouseDown={onClose}>
      <ModalContent
        role="dialog"
        onClick={stopPropagation}
        onMouseDown={stopPropagation}
      >
        <FocusLock>
          <ConfirmationContainer>
            <Txt id="common.closingConfirmation" />
            <StyledButtonGroup>
              <SecondaryButton type="button" onClick={() => onClose()}>
                <Txt id="common.cancel" />
              </SecondaryButton>
              <PrimaryButton
                type="button"
                onClick={() => {
                  onClose();
                  closeParentModal();
                }}
              >
                <Txt id="common.status.yes" />
              </PrimaryButton>
            </StyledButtonGroup>
          </ConfirmationContainer>
        </FocusLock>
      </ModalContent>
    </ModalOverlay>
  );

  return ReactDOM.createPortal(modalContent, document.body);
};

const StyledButtonGroup = styled(ButtonGroup)`
  margin-top: ${({ theme }) => theme.margin[16]};
  justify-content: center;
`;

const ConfirmationContainer = styled.div`
  border-radius: 8px;
  box-shadow: 0px 4px 42px rgba(0, 0, 0, 0.25);

  padding: 1rem;

  min-width: 484px;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;

  background: white;

  text-align: center;
`;

export const Content = styled.div<ContentProps>`
  padding: ${(props) =>
    `${props.theme.margin[16]} ${props.theme.margin[16]} ${props.theme.margin[16]} ${props.theme.margin[32]}`};

  ${(props) => (props.noMaxHeight ? '' : `max-height: 514px;`)}

  min-height: 514px;

  display: flex;
  flex-direction: column;

  flex: 1;

  background: ${(props) => props.theme.color.white};
`;

type ContainerProps = {
  noShadow?: boolean;
};

export const Container = styled.div<ContainerProps>`
  border-radius: ${({ theme }) => theme.radius.large};
  ${({ noShadow }) =>
    !noShadow
      ? css`
          box-shadow: 0px 4px 42px rgba(0, 0, 0, 0.25);
        `
      : null}

  min-width: 484px;
  width: 50vw;

  display: flex;
  flex-flow: column nowrap;

  overflow: hidden;
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
`;

export const Header = styled.div`
  border-radius: ${({ theme }) => theme.radius.medium}
    ${({ theme }) => theme.radius.medium} 0 0;

  padding: ${(props) => `${props.theme.margin[16]} ${props.theme.margin[32]}`};

  max-height: 68px;

  background-color: ${(props) => props.theme.color.modalTitleBarBackground};

  color: ${({ theme }) => theme.color.white};
`;

export const HeaderWithSteps = styled.div`
  border-radius: ${({ theme }) => theme.radius.medium}
    ${({ theme }) => theme.radius.medium} 0 0;

  padding: ${(props) => `${props.theme.margin[32]} ${props.theme.margin[32]}`};

  max-height: ${(props) => props.theme.margin[112]};

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  background-color: white;
`;

export const CircledCloseButton = styled(IconButton)`
  position: absolute;
  top: 32px;
  right: 32px;

  border: 1px solid ${(props) => props.theme.color.graphiteB76};
  border-radius: 50%;

  width: 40px;
  height: 40px;

  cursor: pointer;
`;

const StyledIconCloseBlack = styled(IconCloseBlack)`
  margin: 11px;
`;

export const HeaderWithStepsCloseButton = ({
  onClose,
}: {
  onClose: () => void;
}) => {
  return (
    <CircledCloseButton
      onClick={onClose}
      type="button"
      icon={StyledIconCloseBlack}
      tabIndex={0}
    />
  );
};

export const HeaderText = styled.h2`
  font-family: 'Graphik', sans-serif;
  font-weight: 600;
  font-size: 16px;
`;

export const Footer = styled.div`
  box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.15);
  border-radius: 0 0 ${({ theme }) => theme.radius.medium}
    ${({ theme }) => theme.radius.medium};

  padding: ${(props) =>
    `${props.theme.margin[16]} ${props.theme.margin[16]} ${props.theme.margin[16]} ${props.theme.margin[32]}`};

  display: flex;
  justify-content: flex-end;
  align-items: center;

  background: ${(props) => props.theme.color.white};
`;

export const FilterTextInput = styled.input`
  margin: ${(props) => `${props.theme.margin[16]} 0 `};

  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.color.dropdownBorder};

  padding: ${(props) => `0 ${props.theme.margin[16]}`};

  min-height: 40px;

  background: ${({ theme }) => theme.color.graphiteB96A};

  font-size: 0.75rem;
  color: ${(props) => `${props.theme.color.pitch}`};
  ::placeholder {
    opacity: 0.5;
    color: ${(props) => `${props.theme.color.pitch}`};
  }
`;

export default Modal;
