import * as React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Big from 'big.js';
import styled from 'styled-components';

import { ActualCost } from '../../../../store/reducers/actualCost';
import { ArrivalRow } from '../../../../store/reducers/arrivalRow';
import { InvoiceHeader } from '../../../../store/reducers/invoiceHeader';
import { getOrderRowById } from '../../../../store/reducers/orderRow';

import { APIOrder, APIOrderRow } from '../../../../types/api';

import useRouteParams from '../../../../hooks/useRouteParams';
import useTxt from '../../../../hooks/useTxt';

import ArrowIcon from '../../../../components/ArrowIcon';
import { LeftPaddedCell } from '../../../../components/Cell';
import { PrimaryRow, SecondaryRow } from '../../../../components/Table';

import { amountFormat, priceFormat } from '../../../../utils/big';
import { dateFormat } from '../../../../utils/format';
import { isInvoiceHeader } from '../../../../utils/general';

import { IconToLinkedEntity } from '../../../../assets';

import { generateUrl, routes } from '../../../../routes';

import { fontSizeCss, paddingLeftCss } from '../../../../styles/additionals';
import { RightPaddedCell, StyledCell } from '../ModalComponents';

type OrderTableRowProps = {
  order: APIOrder | undefined;
  invoices: InvoiceHeader[];
  actualCosts: ActualCost[];
  arrivalRows: ArrivalRow[];
  orderRows: APIOrderRow[];
};

export const OrderTableRow = ({
  order,
  invoices,
  actualCosts,
  arrivalRows,
  orderRows,
}: OrderTableRowProps) => {
  const [isOpen, setOpen] = React.useState(true);

  const linkedEntityAltText = useTxt('analysis.modal.linkedEntity.altText');

  const orderRowIds = orderRows.map((row) => row.id);

  const ordersAllArrivalRows = arrivalRows.filter((row) =>
    orderRowIds.includes(row.orderRowId ?? '')
  );

  const arrivalRowsWithoutLinkage = ordersAllArrivalRows.filter(
    (row) => row.purchaseInvoiceHeaderId === null && row.actualCostId === null
  );

  const arrivalRowsSum = ordersAllArrivalRows.reduce((previous, current) => {
    return previous.add(current.quantity.mul(current.unitPrice));
  }, Big(0));

  if (!order) {
    return null;
  }

  const toggleOpen = () => {
    return setOpen(!isOpen);
  };

  const linkPathName = generateUrl({
    route: routes.ORDER,
    projectId: order.projectId,
    orderId: order.id,
    viewMode: 'receive',
    subViewMode: 'invoices',
  });

  return (
    <>
      <StyledPrimaryRow onClick={toggleOpen} depth={2}>
        <StyledLeftPaddedCell depth={2}>
          <StyledIconDiv depth={2}>
            <ArrowIcon
              isOpen={isOpen}
              openAltTextKey="revenue.table.mainRow.open"
              closedAltTextKey="revenue.table.mainRow.closed"
            />
          </StyledIconDiv>
          {order.visibleCode}
        </StyledLeftPaddedCell>
        <StyledCell depth={2}>{order.name}</StyledCell>
        <StyledCell depth={2} />
        <StyledCell depth={2}>
          <Link
            data-testid={`navigate-to-order-${order.id}`}
            to={{ pathname: linkPathName }}
          >
            <img src={IconToLinkedEntity} alt={linkedEntityAltText} />
          </Link>
        </StyledCell>
        <RightPaddedCell align="right" depth={2}>
          {priceFormat(arrivalRowsSum)}
        </RightPaddedCell>
      </StyledPrimaryRow>
      {isOpen ? (
        <>
          {invoices.map((invoice) => {
            const filteredArrivalRows = arrivalRows.filter(
              (row) => row.purchaseInvoiceHeaderId === invoice.id
            );

            return (
              <InvoiceActualCostRow
                key={`analysis-modal-invoice-${invoice.id}`}
                invoiceOrActualCostRow={invoice}
                arrivalRows={filteredArrivalRows}
                orderId={order.id}
              />
            );
          })}
          {actualCosts.map((actualCost) => {
            const filteredArrivalRows = arrivalRows.filter(
              (row) => row.actualCostId === actualCost.id
            );

            return (
              <InvoiceActualCostRow
                key={`analysis-modal-actual-cost-${actualCost.id}`}
                invoiceOrActualCostRow={actualCost}
                arrivalRows={filteredArrivalRows}
                orderId={order.id}
              />
            );
          })}
          {arrivalRowsWithoutLinkage.length > 0 ? (
            <InvoiceActualCostRow
              arrivalRows={arrivalRowsWithoutLinkage}
              orderId={order.id}
            />
          ) : null}
        </>
      ) : null}
    </>
  );
};

type InvoiceActualCostRowProps = {
  orderId: string;
  invoiceOrActualCostRow?: InvoiceHeader | ActualCost;
  arrivalRows: ArrivalRow[];
};

export const InvoiceActualCostRow = ({
  orderId,
  invoiceOrActualCostRow,
  arrivalRows,
}: InvoiceActualCostRowProps) => {
  const { projectId } = useRouteParams();
  const [isOpen, setOpen] = React.useState(false);

  const toggleOpen = () => {
    return setOpen(!isOpen);
  };

  const search = '?showAll=true';
  let description = useTxt('analysis.received.modal.unassignedArrivals');
  let hash: string = '';

  let testId: string = '';

  const arrivalRowsSum = arrivalRows.reduce((previous, current) => {
    return previous.add(current.quantity.mul(current.unitPrice));
  }, Big(0));

  if (invoiceOrActualCostRow) {
    if (isInvoiceHeader(invoiceOrActualCostRow)) {
      description = `${dateFormat.format(
        invoiceOrActualCostRow.documentDate
      )} ${invoiceOrActualCostRow.supplierName}`;
      hash = `#invoiceHeaderId-${invoiceOrActualCostRow.id}`;
      testId = `navigate-to-invoice-header-${invoiceOrActualCostRow.id}`;
    } else {
      description = `${dateFormat.format(invoiceOrActualCostRow.date)} ${
        invoiceOrActualCostRow.supplierName ?? ''
      }`;
      hash = `#actualCostId-${invoiceOrActualCostRow.id}`;
      testId = `navigate-to-actual-cost-${invoiceOrActualCostRow.id}`;
    }
  }

  const linkPathName = generateUrl({
    route: routes.ORDER,
    projectId,
    orderId,
    viewMode: 'receive',
    subViewMode: 'invoices',
  });

  const linkedEntityAltText = useTxt('analysis.modal.linkedEntity.altText');

  const documentIdentifier = (row: ActualCost | InvoiceHeader | undefined) => {
    if (!row) {
      return '';
    }

    return isInvoiceHeader(row) ? row.vendorInvoiceNo : row.documentNumber;
  };

  return (
    <>
      <StyledPrimaryRow onClick={toggleOpen} depth={4}>
        <StyledLeftPaddedCell depth={4}>
          <StyledIconDiv depth={4}>
            <ArrowIcon
              isOpen={isOpen}
              openAltTextKey="revenue.table.mainRow.open"
              closedAltTextKey="revenue.table.mainRow.closed"
            />
          </StyledIconDiv>
          {documentIdentifier(invoiceOrActualCostRow)}
        </StyledLeftPaddedCell>
        <StyledCell depth={4}>{description}</StyledCell>
        <StyledCell depth={4} />
        <StyledCell depth={4}>
          <Link
            data-testid={testId}
            to={{ pathname: linkPathName, search, hash }}
          >
            <img src={IconToLinkedEntity} alt={linkedEntityAltText} />
          </Link>
        </StyledCell>
        <RightPaddedCell align="right" depth={4}>
          {priceFormat(arrivalRowsSum)}
        </RightPaddedCell>
      </StyledPrimaryRow>
      {isOpen
        ? arrivalRows.map((row) => {
            return (
              <ArrivalTableRow key={`arrival-${row.id}`} arrivalRow={row} />
            );
          })
        : null}
    </>
  );
};

type ArrivalRowProps = {
  arrivalRow: ArrivalRow;
};

export const ArrivalTableRow = ({ arrivalRow }: ArrivalRowProps) => {
  const orderRow = useSelector(getOrderRowById(arrivalRow.orderRowId ?? ''));

  return (
    <StyledSecondaryRow>
      <StyledCell />
      <StyledCell>{arrivalRow.description}</StyledCell>
      <RightPaddedCell align="right">
        {amountFormat(arrivalRow.quantity)}
      </RightPaddedCell>
      <RightPaddedCell align="right">
        {`${priceFormat(arrivalRow.unitPrice ?? new Big(0))}${
          orderRow?.unit ? `/${orderRow.unit}` : ''
        }`}
      </RightPaddedCell>
      <RightPaddedCell align="right">
        {priceFormat(arrivalRow.quantity.mul(arrivalRow.unitPrice))}
      </RightPaddedCell>
    </StyledSecondaryRow>
  );
};

type PrimaryRowAndCellProps = {
  depth?: number;
};

const StyledPrimaryRow = styled(PrimaryRow)<PrimaryRowAndCellProps>`
  border: 2px solid ${(props) => props.theme.color.white};
  ${(props) => fontSizeCss(props.depth)}
`;

const StyledSecondaryRow = styled(SecondaryRow)<PrimaryRowAndCellProps>`
  border: 2px solid ${(props) => props.theme.color.white};
  background: ${(props) => props.theme.color.graphiteB98};
  ${(props) => fontSizeCss(props.depth)}
`;

const StyledLeftPaddedCell = styled(LeftPaddedCell)<PrimaryRowAndCellProps>`
  ${(props) => fontSizeCss(props.depth)}
`;

const StyledIconDiv = styled.div<PrimaryRowAndCellProps>`
  ${(props) => paddingLeftCss(props.depth)}
  margin-right: ${(props) => props.theme.margin[4]};
`;
