import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  UserCredential,
  getAuth,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import { isError as checkIfError } from 'lodash';
import styled from 'styled-components';

import * as Actions from '../../store/actions';

import { AuthTitle, AuthButton, AuthText } from '../../components/Auth';
import AuthContainer from '../../components/Auth/AuthPageContainer';
import Txt from '../../components/Txt';

import {
  getAndRemoveOriginalUrl,
  setBaseUrl,
  signInWithGoogle,
  signInWithMicrosoft,
  useAsyncError,
} from '../../utils/auth';

import { IconGoogle, IconMicrosoft, IconMail } from '../../assets/svg';

import { routes, generateUrl } from '../../routes';

const GoogleIconWrapper = styled.svg`
  border-radius: 50%;

  padding: 3px;

  width: 20px;
  height: 20px;

  display: inline-block;

  flex-shrink: 0;

  background-color: rgba(255, 255, 255, 0.85);

  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  transform: scale(1.2);
`;

const MicrosoftWrapper = styled.svg`
  width: 20px;
  height: 20px;

  display: inline-block;

  flex-shrink: 0;

  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`;

const MailWrapper = styled.svg`
  width: 20px;
  height: 20px;

  display: inline-block;

  flex-shrink: 0;

  color: white;

  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  fill: white;
`;

const SpanWrapper = styled.span`
  margin-left: 0px;
  margin-right: 1rem;
  display: inherit;
`;

const EndEmptySpan = styled.span`
  margin-right: -4px;
  margin-left: 8px;
  display: inherit;
`;

const GoogleIcon = () => {
  return (
    <SpanWrapper>
      <GoogleIconWrapper>
        <IconGoogle />
      </GoogleIconWrapper>
    </SpanWrapper>
  );
};

const MicrosoftIcon = () => {
  return (
    <SpanWrapper>
      <MicrosoftWrapper>
        <IconMicrosoft />
      </MicrosoftWrapper>
    </SpanWrapper>
  );
};

const MailIcon = () => {
  return (
    <SpanWrapper>
      <MailWrapper>
        <IconMail />
      </MailWrapper>
    </SpanWrapper>
  );
};

const METHOD_FIELD = 'loginMethod';

const LoginPage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory<{ from?: string }>();

  const { errorMessage, isError, clearError, setError } = useAsyncError();

  const finishAuth = async (authResponse: UserCredential | Error) => {
    try {
      if (checkIfError(authResponse)) {
        setError(authResponse);

        return;
      }

      // Redirect to the previous URL, if it exists, otherwise, redirect to a default page
      const from = history.location?.state?.from;

      const navigate = () => {
        if (from) {
          history.push(from);
        } else {
          window.location.href = getAndRemoveOriginalUrl();
        }
      };

      const { email } = authResponse.user;

      if (email) {
        setBaseUrl(email);
      }

      // TODO: Set back-end env variable

      dispatch(Actions.fetchUserState(navigate, setError));
    } catch (error) {
      // console.log(error);
    }
  };

  const LOGIN_METHODS = {
    Google: {
      value: 'Google',
      backgroundColor: '#4285f4',
      hoverColor: '#76a7fa',
      icon: GoogleIcon,
      onClick: () => {
        clearError();
        signInWithGoogle(finishAuth);
      },
    },
    Microsoft: {
      value: 'Microsoft',
      backgroundColor: '#2f2f2f',
      hoverColor: '#757575',
      icon: MicrosoftIcon,
      onClick: () => {
        clearError();
        signInWithMicrosoft(finishAuth);
      },
    },
    Email: {
      value: 'Email',
      icon: MailIcon,
      onClick: () => {
        clearError();
        history.push(
          generateUrl({
            route: routes.LOGIN_WITH_EMAIL,
          })
        );
      },
    },
  } as const;

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  };

  const handleHiddenLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const auth = getAuth();
    const formData = new FormData(event.currentTarget);
    const email = formData.get('hidden-email') as string;
    const password = formData.get('hidden-password') as string;
    const credentials = await signInWithEmailAndPassword(auth, email, password);
    await finishAuth(credentials);
  };

  return (
    <AuthContainer>
      <ContentsForm onSubmit={onSubmit}>
        <AuthTitle>
          <Txt id="auth.header" component="b" />
        </AuthTitle>

        <ButtonContainer>
          {Object.values(LOGIN_METHODS).map((method) => {
            const id = `auth.signin.method.${method.value}` as const;

            if (
              errorMessage.existsWithDifferentCredentialsError &&
              method.value !== 'Email'
            ) {
              return null;
            }

            return (
              <AuthButton {...method} key={method.value} name={METHOD_FIELD}>
                <Txt id={id} />
                <EndEmptySpan />
              </AuthButton>
            );
          })}
        </ButtonContainer>
        {isError ? <ErrorText>{errorMessage.message}</ErrorText> : null}
      </ContentsForm>
      <HiddenForm method="post" onSubmit={handleHiddenLogin}>
        <input name="hidden-email" id="hidden-email" />
        <input name="hidden-password" id="hidden-password" />
        <input type="submit" id="hidden-submit" />
      </HiddenForm>
    </AuthContainer>
  );
};

const ContentsForm = styled.form`
  display: contents;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1rem;
`;

const ErrorText = styled(AuthText)`
  color: red;
`;

const HiddenForm = styled.form`
  display: none;
`;

export default LoginPage;
