import { ID } from '../../../types/general';

import * as remoteData from '../../../utils/remoteData';
import { Selector } from '../utils';

import { AppState } from '..';
import { ActionTypes as Action } from '../../actionTypes';

export type ProcurementReferenceNumberState = Partial<
  Record<ID, remoteData.RemoteData<string>>
>;

const initialState: ProcurementReferenceNumberState = {};

function procurementReferenceNumberReducer(
  state = initialState,
  action: Action
): ProcurementReferenceNumberState {
  switch (action.type) {
    case 'GET_PROCUREMENT_REFERENCE_NUMBER_STARTED': {
      const { projectId } = action.payload;

      return { ...state, [projectId]: remoteData.loading };
    }
    case 'GET_PROCUREMENT_REFERENCE_NUMBER_FAILURE': {
      const { projectId, error } = action.payload;

      return { ...state, [projectId]: remoteData.fail(error) };
    }
    case 'GET_PROCUREMENT_REFERENCE_NUMBER_SUCCESS': {
      const { projectId, procurementReferenceNumber } = action.payload;

      return {
        ...state,
        [projectId]: remoteData.succeed(procurementReferenceNumber),
      };
    }
    case 'CLEAR_PROCUREMENT_REFERENCE_NUMBER': {
      const { projectId } = action.payload;

      const previousState = { ...state };

      delete previousState[projectId];

      return { ...previousState };
    }
    default:
      return state;
  }
}

export const selectProcurementReferenceNumberRequests =
  (projectId: string) =>
  ({
    orders: {
      procurementReferenceNumbers: {
        [projectId]: requestState = remoteData.notAsked,
      },
    },
  }: AppState) =>
    requestState;

export function getProcurementReferenceNumber(
  projectId: string
): Selector<remoteData.RemoteData<string>> {
  return ({
    orders: {
      procurementReferenceNumbers: {
        [projectId]: requestState = remoteData.notAsked,
      },
    },
  }: AppState) => remoteData.map(requestState, (value) => value);
}

export default procurementReferenceNumberReducer;
