import { Reducer } from 'redux';

import { APIPresettledInvoiceRow } from '../../types/api';

import {
  assertActionPayloadIsNotApiUpdatedEntities,
  isUpdatedEntitiesActionType,
  Selector,
} from './utils';
import * as api from '../../utils/api';
import normalizeBy from '../../utils/normalizeBy';
import * as remoteData from '../../utils/remoteData';

import { ActionTypes } from '../actionTypes';

type Err = api.BackendError | undefined;

export type PresettledInvoiceRowState = {
  requests: Partial<Record<string, remoteData.RemoteData<undefined, Err>>>;
  spreadArrivalRowRequests: Partial<
    Record<string, remoteData.RemoteData<undefined, Err>>
  >;
  data: Partial<Record<string, APIPresettledInvoiceRow>>;
};

const initialState: PresettledInvoiceRowState = {
  requests: {},
  spreadArrivalRowRequests: {},
  data: {},
};

const presettledInvoiceRowReducer: Reducer<
  PresettledInvoiceRowState,
  ActionTypes
> = (state = initialState, action): PresettledInvoiceRowState => {
  switch (action.type) {
    case 'GET_PRESETTLED_INVOICE_ROWS_STARTED': {
      const { invoiceId } = action.payload;

      const requests = {
        ...state.requests,
        [invoiceId]: remoteData.loading,
      };

      return {
        ...state,
        requests,
      };
    }
    case 'GET_PRESETTLED_INVOICE_ROWS_FAILURE': {
      const { invoiceId, error } = action.payload;

      const requests = {
        ...state.requests,
        [invoiceId]: remoteData.fail(error),
      };

      return {
        ...state,
        requests,
      };
    }
    case 'GET_PRESETTLED_INVOICE_ROWS_SUCCESS': {
      const { invoiceId, presettledInvoiceRows } = action.payload;

      const requests = {
        ...state.requests,
        [invoiceId]: remoteData.succeed(undefined),
      };

      const data = {
        ...state.data,
        ...normalizeBy('id', presettledInvoiceRows),
      };

      return {
        ...state,
        requests,
        data,
      };
    }
    case 'POST_SPREAD_ARRIVAL_ROWS_STARTED': {
      const { invoiceId } = action.payload;

      const spreadArrivalRowRequests = {
        ...state.spreadArrivalRowRequests,
        [invoiceId]: remoteData.loading,
      };

      return {
        ...state,
        spreadArrivalRowRequests,
      };
    }
    case 'POST_SPREAD_ARRIVAL_ROWS_FAILURE': {
      const { invoiceId, error } = action.payload;

      const spreadArrivalRowRequests = {
        ...state.spreadArrivalRowRequests,
        [invoiceId]: remoteData.fail(error),
      };

      return {
        ...state,
        spreadArrivalRowRequests,
      };
    }
    case 'POST_SPREAD_ARRIVAL_ROWS_SUCCESS': {
      const { presettledInvoiceRows: updatedRows = [] } = action.payload;

      const spreadArrivalRowRequests = updatedRows.reduce(
        (result, row) => ({
          ...result,
          [row.purchaseInvoiceHeaderId]: remoteData.succeed(undefined),
        }),
        state.spreadArrivalRowRequests
      );

      const data = {
        ...state.data,
        ...normalizeBy('id', updatedRows),
      };

      return {
        ...state,
        spreadArrivalRowRequests,
        data,
      };
    }
  }

  if (isUpdatedEntitiesActionType(action)) {
    const { presettledInvoiceRows: updatedRows = [] } = action.payload;

    const data = {
      ...state.data,
      ...normalizeBy('id', updatedRows),
    };

    return {
      ...state,
      // empty requests object so that data is reloaded
      requests: {},
      data,
    };
  }

  assertActionPayloadIsNotApiUpdatedEntities(action);

  return state;
};

export const selectHasUnassignedPresettledInvoiceRows =
  (invoiceId: string): Selector<boolean> =>
  ({ presettledInvoiceRows: { data } }) =>
    Object.values(data).find(
      (row) =>
        row !== undefined &&
        row.purchaseInvoiceHeaderId === invoiceId &&
        !row.arrivalRowIds
    ) !== undefined;

export const selectPresettledInvoiceRowRequests =
  (invoiceId: string): Selector<remoteData.RemoteAction> =>
  ({
    presettledInvoiceRows: {
      requests: { [invoiceId]: request },
    },
  }) =>
    request ?? remoteData.notAsked;

export const selectSpreadArrivalRowRequests =
  (invoiceId: string): Selector<remoteData.RemoteAction> =>
  ({
    presettledInvoiceRows: {
      spreadArrivalRowRequests: { [invoiceId]: request },
    },
  }) =>
    request ?? remoteData.notAsked;

export default presettledInvoiceRowReducer;
