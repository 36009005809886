import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import Big from 'big.js';

import { APIOrder, APITopic, APIWorkPackage } from '@customtypes/api';

import { getProjectOrders } from '../../../store/reducers/order/order';
import { getTargetRowHierarchyEntriesForProject } from '../../../store/reducers/target/hierarchy';
import { getTargetRowsForProject } from '../../../store/reducers/target/targetRows';
import { getTopics } from '../../../store/reducers/topic';
import { getTargetViewFilters } from '../../../store/reducers/ui';
import { getProjectWorkPackages } from '../../../store/reducers/workPackage';

import * as Actions from '../../../store/actions';

import useDeepCompareMemo from '../../../hooks/useDeepCompareMemo';
import { useRemoteDataWithDeepCompare } from '../../../hooks/useRemoteData';
import useTxt from '../../../hooks/useTxt';

import { FilterState } from '../components/TargetImportModal';
import {
  ExtendedOrder,
  ExtendedTargetRow,
  ExtendedTargetRowHierarchyEntry,
  ExtendedWorkPackage,
  isExtendedOrder,
  isExtendedTargetRow,
  isExtendedTargetRowHierarchyEntry,
  isExtendedWorkPackage,
  processHierarchyEntryUpdateInformation,
  processTargetRowUpdateInformation,
  UpdateInformationTargetImportData,
  ValidatedTargetImportData,
} from '../components/TargetImportModal/utils';

import { DecodeResult } from '../../../utils/decoders';
import {
  areStringifiedArraysSame,
  isDefined,
  isNotNull,
} from '../../../utils/general';
import normalizeBy from '../../../utils/normalizeBy';
import { searchFilter } from '../../../utils/search';
import {
  collectAllChildIds,
  collectAllParentIds,
} from '../../../utils/tableUtils';

import { routes, useParams } from '../../../routes';

export type TargetRowOrTargetRowHierarchyEntry = {
  id: string;
  originalId: string;
  description: string | null;
  projectId: string;
  orderId: string | null;
  workPackageId: string | undefined;
  type: 'targetRow' | 'targetRowHierarchyEntry';
  quantity: Big | null;
  unit: string | null;
  unitPrice: Big | null;
  target: Big | null;
  filteredTarget?: Big | null;
  referenceNo: string | null;
  topicId: string | null;
  topicName?: string | null;
  orderCode?: string | undefined;
  orderName?: string | undefined;
  workPackageCode?: string | undefined;
  workPackageName?: string | undefined;
  isDeleted: boolean;
  isAntiRow: boolean;
  isSplitFrom: string | null;
  splitFrom:
    | (Actions.TargetRow & { orderVisibleCodeAndName: string })
    | null
    | undefined;
  splitTo:
    | (Actions.TargetRow & { orderVisibleCodeAndName: string })[]
    | null
    | undefined;
  isOriginal: boolean;
  isDisabled: boolean;
  targetRowHierarchyEntryId: string | null;
  invalidOrder?: DecodeResult<string | null>;
  invalidWorkPackage?: DecodeResult<string | null>;
  isHighLighted?: boolean;
  validationInformation?: ValidatedTargetImportData;
  updateInformation?: UpdateInformationTargetImportData;
  invalid?: boolean;
  subRows?: TargetRowOrTargetRowHierarchyEntry[] | undefined;
  refForSorting: string;
  orderRowId?: string | null;
  hasUnSavedChanges?: {
    originalOrderId: string | null;
    originalWorkPackageId: string | null;
    originalTopicId: string | null;
    originalOrderCode: string | null;
    originalOrderName: string | null;
    originalWorkPackageCode: string | null;
    originalWorkPackageName: string | null;
    originalTopicName: string | null;
  };
};

const sortingFunction = (
  a: TargetRowOrTargetRowHierarchyEntry,
  b: TargetRowOrTargetRowHierarchyEntry
) => {
  return a.refForSorting.localeCompare(b.refForSorting);
};

const refForSorting = (
  referenceNo: string | null,
  description: string | null
) => {
  const referenceNoTrimmed = referenceNo?.trim() ?? '';
  const descriptionTrimmed = description?.trim() ?? '';

  if (referenceNoTrimmed.length > 0) {
    return `1_${referenceNoTrimmed}_${descriptionTrimmed}`;
  }

  return `2_0_${descriptionTrimmed}`;
};

export default function useTargetViewData(
  filterState?: FilterState | undefined,
  newData?: {
    targetRows: ExtendedTargetRow[];
    targetRowHierarchyEntries: ExtendedTargetRowHierarchyEntry[];
    topics: APITopic[];
    orders: ExtendedOrder[];
    workPackages: ExtendedWorkPackage[];
  }
): TargetRowOrTargetRowHierarchyEntry[] {
  const { projectId } = useParams(routes.TARGET);

  const currentTargetRowHierarchyEntries = useRemoteDataWithDeepCompare(
    getTargetRowHierarchyEntriesForProject(projectId),
    Actions.getTargetRowHierarchyForProject({ projectId }),
    true,
    areStringifiedArraysSame
  );

  const currentTargetRows = useRemoteDataWithDeepCompare(
    getTargetRowsForProject({ projectId }),
    Actions.requestTargetRowsForProject({ projectId }),
    true,
    areStringifiedArraysSame
  );

  const topicsData = useSelector(getTopics, areStringifiedArraysSame);

  const currentTopics = useDeepCompareMemo(
    topicsData,
    areStringifiedArraysSame
  );

  const currentOrders = useRemoteDataWithDeepCompare(
    getProjectOrders(projectId),
    Actions.fetchOrdersForProject(projectId),
    true,
    areStringifiedArraysSame
  );

  const currentWorkPackages = useRemoteDataWithDeepCompare(
    getProjectWorkPackages(projectId),
    Actions.fetchWorkPackagesForProject(projectId),
    true,
    areStringifiedArraysSame
  );

  let targetRowHierarchyEntriesToBeProcessed:
    | (Actions.TargetRowHierarchyEntry | ExtendedTargetRowHierarchyEntry)[]
    | undefined = currentTargetRowHierarchyEntries;

  let targetRowsToBeProcessed:
    | (Actions.TargetRow | ExtendedTargetRow)[]
    | undefined = currentTargetRows;
  let topicsToBeProcessed = currentTopics;

  let ordersToBeProcessed: (APIOrder | ExtendedOrder)[] | undefined =
    currentOrders;

  let workPackagesToBeProcessed:
    | (APIWorkPackage | ExtendedWorkPackage)[]
    | undefined = currentWorkPackages;

  const isNewData = newData !== undefined;

  if (newData) {
    targetRowHierarchyEntriesToBeProcessed = newData.targetRowHierarchyEntries;
    targetRowsToBeProcessed = newData.targetRows;
    topicsToBeProcessed = newData.topics;
    ordersToBeProcessed = newData.orders;
    workPackagesToBeProcessed = newData.workPackages;
  }

  const { targetRowIds, filterSearchWord } = useSelector(
    getTargetViewFilters(),
    shallowEqual
  );

  const useTargetRowIds = targetRowIds && targetRowIds.length > 0;

  const searchParameter = useTargetRowIds ? ' ' : filterSearchWord;

  const sectionTitleText = useTxt('target.noHierarchies.sectionTitle');

  const memoizedTree = useMemo(() => {
    const topics = topicsToBeProcessed;
    const targetRows = targetRowsToBeProcessed ?? [];
    const orders = ordersToBeProcessed ?? [];
    const workPackages = workPackagesToBeProcessed ?? [];
    const targetRowHierarchyEntries = targetRowHierarchyEntriesToBeProcessed;

    if (
      topics.length === 0 ||
      targetRows.length === 0 ||
      orders.length === 0 ||
      workPackages.length === 0 ||
      !targetRowHierarchyEntries
    ) {
      return [];
    }

    const maxDepth = Math.max(
      ...targetRowHierarchyEntries.map((entry) => entry.depth)
    );

    let targetRowsWithCodeNames = targetRows.map((targetRow) => {
      let updateInformation: UpdateInformationTargetImportData | undefined;

      const order = orders.find((o) => o.id === targetRow.orderId);
      const topic = topics.find((t) => t.id === targetRow.topicId);

      const workPackage = workPackages.find((w) => {
        return w.id === topic?.workPackageId;
      });

      if (isNewData) {
        const existingTargetRow = currentTargetRows?.find(
          (row) => row.externalCode === targetRow.externalCode
        );

        const existingTargetRowOrder = currentOrders?.find(
          (o) => o.id === existingTargetRow?.orderId
        );

        const existingTargetRowTopic = currentTopics?.find(
          (t) => t.id === existingTargetRow?.topicId
        );

        const existingTargetRowWorkPackage = currentWorkPackages?.find(
          (wp) => wp.id === existingTargetRowTopic?.workPackageId
        );

        updateInformation = processTargetRowUpdateInformation(
          targetRow,
          existingTargetRow,
          order,
          existingTargetRowOrder,
          workPackage,
          existingTargetRowWorkPackage
        );
      }

      const invalidOrder =
        order &&
        isExtendedOrder(order) &&
        order.validationInformation.kind === 'Invalid'
          ? order.validationInformation
          : undefined;

      const invalidWorkPackage =
        workPackage &&
        isExtendedWorkPackage(workPackage) &&
        workPackage.validationInformation.kind === 'Invalid'
          ? workPackage.validationInformation
          : undefined;

      return {
        ...targetRow,
        invalid: isExtendedTargetRow(targetRow)
          ? targetRow.invalid || !!invalidOrder || !!invalidWorkPackage
          : undefined,
        invalidOrder,
        invalidWorkPackage,
        orderCode: order?.visibleCode,
        orderName: order?.name,
        workPackageCode: workPackage?.code,
        workPackageName: workPackage?.name,
        topicName: topic?.name,
        updateInformation,
      };
    });

    if (filterState) {
      targetRowsWithCodeNames = targetRowsWithCodeNames.filter((row) => {
        if (isExtendedTargetRow(row) && filterState === 'invalid') {
          return row.invalid;
        }

        if (
          row.updateInformation &&
          ['update', 'create', 'delete'].includes(filterState)
        ) {
          return row.updateInformation.changeType === filterState;
        }

        if (row.updateInformation && filterState === 'changed') {
          return row.updateInformation.changeType !== 'noChange';
        }

        if (filterState === 'noOrderRowLink') {
          return (
            row.orderRowId === null &&
            row.isAntiRow === false &&
            row.isDeleted === false &&
            row.isDisabled === false
          );
        }

        return false;
      });
    }

    const filteredTargetRows = (
      useTargetRowIds
        ? targetRowsWithCodeNames.filter(
            (row) => targetRowIds && targetRowIds.includes(row.id)
          )
        : searchFilter(targetRowsWithCodeNames, searchParameter)
    ).filter((row) => row.isAntiRow === false);

    const filteredTargetRowIds = new Set(
      filteredTargetRows.map((row) => row.id)
    );

    const preMappedTargetRowHierarchyEntries = targetRowHierarchyEntries.map(
      (entry) => {
        let updateInformation: UpdateInformationTargetImportData | undefined;

        if (isNewData) {
          const existingEntry = currentTargetRowHierarchyEntries?.find(
            (row) => row.externalId === entry.externalId
          );

          updateInformation = processHierarchyEntryUpdateInformation(
            entry,
            existingEntry
          );
        }

        return {
          ...entry,
          updateInformation,
          invalid: isExtendedTargetRowHierarchyEntry(entry)
            ? entry.invalid
            : undefined,
        };
      }
    );

    let filteredHierarchyEntrys = useTargetRowIds
      ? []
      : searchFilter(preMappedTargetRowHierarchyEntries, searchParameter);

    if (filterState) {
      filteredHierarchyEntrys = filteredHierarchyEntrys.filter((row) => {
        if (filterState === 'invalid') {
          return row.invalid;
        }

        if (
          row.updateInformation &&
          ['update', 'create', 'delete'].includes(filterState)
        ) {
          return row.updateInformation.changeType === filterState;
        }

        if (row.updateInformation && filterState === 'changed') {
          return row.updateInformation.changeType !== 'noChange';
        }

        return false;
      });
    }

    const filteredHierarchyEntryIds = filteredHierarchyEntrys.map(
      (entry) => entry.id
    );

    const mappedTargetRowHierarchyEntries: TargetRowOrTargetRowHierarchyEntry[] =
      preMappedTargetRowHierarchyEntries
        .map((entry) => {
          return {
            id: `hierarchyEntry-${entry.id}`,
            originalId: entry.id,
            description: entry.description,
            projectId: entry.projectId,
            orderId: null,
            workPackageId: undefined,
            type: 'targetRowHierarchyEntry' as const,
            quantity: entry.quantity,
            unit: entry.unit,
            unitPrice: entry.unitPrice,
            target: entry.totalAmount,
            referenceNo: entry.referenceNumber,
            refForSorting: refForSorting(
              entry.referenceNumber,
              entry.description
            ),
            topicId: null,
            isDeleted: entry.isDeleted,
            isAntiRow: false,
            isSplitFrom: null,
            isOriginal: true,
            splitFrom: null,
            isDisabled: false,
            splitTo: null,
            isHighLighted:
              searchParameter.length > 0 &&
              filteredHierarchyEntryIds.includes(entry.id),
            targetRowHierarchyEntryId: entry.parentId
              ? `hierarchyEntry-${entry.parentId}`
              : null,
            invalid: entry.invalid,
            validationInformation: isExtendedTargetRowHierarchyEntry(entry)
              ? entry.validationInformation
              : undefined,
            updateInformation: entry.updateInformation,
          };
        })
        .sort(sortingFunction);

    let mappedForSortingTargetRows: TargetRowOrTargetRowHierarchyEntry[] = [];

    const normalizedTargetRows = normalizeBy('id', targetRows);

    const targetRowsGroupedBySplitFrom = targetRowsWithCodeNames.reduce(
      (acc, row) => {
        if (row.isSplitFrom) {
          if (acc[row.isSplitFrom]) {
            acc[row.isSplitFrom]?.push(row);
          } else {
            acc[row.isSplitFrom] = [row];
          }
        }

        return acc;
      },
      {} as Partial<Record<string, Actions.TargetRow[]>>
    );

    for (let i = 0; i < targetRowsWithCodeNames.length; i++) {
      const targetRow = targetRowsWithCodeNames[i];

      const splitFrom = targetRow.isSplitFrom
        ? normalizedTargetRows[targetRow.isSplitFrom]
        : undefined;

      const splitFromOrder = orders.find(
        (order) => order.id === splitFrom?.orderId
      );
      const splitTo = targetRowsGroupedBySplitFrom[targetRow.id] ?? [];

      const mappedRow = {
        id: `targetRow-${targetRow.id}`,
        originalId: targetRow.id,
        description: targetRow.description,
        type: 'targetRow' as const,
        quantity: targetRow.quantity,
        unit: targetRow.unit,
        unitPrice: targetRow.unitPrice,
        referenceNo: targetRow.referenceNumber,
        refForSorting: refForSorting(
          targetRow.referenceNumber,
          targetRow.description
        ),
        target: targetRow.unitPrice
          ? targetRow.unitPrice.mul(targetRow.quantity ?? new Big(0))
          : new Big(0),
        topicId: isExtendedTargetRow(targetRow) ? null : targetRow.topicId,
        projectId: targetRow.projectId,
        orderId: isExtendedTargetRow(targetRow) ? null : targetRow.orderId,
        workPackageId: isExtendedTargetRow(targetRow)
          ? undefined
          : workPackages.find((workPackage) => {
              return (
                workPackage.id ===
                topics.find((topic) => topic.id === targetRow.topicId)
                  ?.workPackageId
              );
            })?.id,
        orderRowId: targetRow.orderRowId,
        orderCode: targetRow.orderCode,
        orderName: targetRow.orderName,
        workPackageCode: targetRow.workPackageCode,
        workPackageName: targetRow.workPackageName,
        topicName: targetRow.topicName,
        splitFrom: splitFrom
          ? {
              ...splitFrom,
              orderVisibleCodeAndName: `${splitFromOrder?.visibleCode} ${splitFromOrder?.name}`,
            }
          : null,
        splitTo: splitTo.map((row) => ({
          ...row,
          orderVisibleCodeAndName: `${
            orders.find((order) => order.id === row.orderId)?.visibleCode
          } ${orders.find((order) => order.id === row.orderId)?.name}`,
        })),
        isDeleted: targetRow.isDeleted,
        isAntiRow: targetRow.isAntiRow,
        isSplitFrom: targetRow.isSplitFrom,
        isDisabled: targetRow.isDisabled,
        isOriginal: targetRow.isOriginal,
        invalidOrder: targetRow.invalidOrder,
        invalidWorkPackage: targetRow.invalidWorkPackage,
        invalid: targetRow.invalid,
        validationInformation: isExtendedTargetRow(targetRow)
          ? targetRow.validationInformation
          : undefined,
        updateInformation: targetRow.updateInformation,
        isHighLighted:
          searchParameter.length > 0 && filteredTargetRowIds.has(targetRow.id),
        targetRowHierarchyEntryId: targetRow.targetRowHierarchyEntryId
          ? `hierarchyEntry-${targetRow.targetRowHierarchyEntryId}`
          : 'hierarchyEntry-none',
      };

      mappedForSortingTargetRows.push(mappedRow);
    }

    const mappedTargetRows = mappedForSortingTargetRows.sort(sortingFunction);

    const targetRowParentIds = filteredTargetRows
      .map((row) => row.targetRowHierarchyEntryId)
      .filter(isNotNull);

    const allEntryLowLevelIds = [
      ...new Set([...filteredHierarchyEntryIds, ...targetRowParentIds]),
    ];

    const allIncludingParents = collectAllParentIds(
      maxDepth,
      new Set(allEntryLowLevelIds),
      targetRowHierarchyEntries
    );

    const allIncludingChildren = collectAllChildIds(
      0,
      maxDepth,
      new Set(allEntryLowLevelIds),
      targetRowHierarchyEntries
    );

    const allFilteredEntryIds = new Set([
      ...allIncludingParents,
      ...allIncludingChildren,
    ]);

    const mappedAndFilteredHierarchyEntries =
      mappedTargetRowHierarchyEntries.filter((entry) =>
        allFilteredEntryIds.has(entry.originalId)
      );

    const targetRowsForFilteredParents = targetRows
      .filter(
        (row) =>
          row.targetRowHierarchyEntryId &&
          allFilteredEntryIds.has(row.targetRowHierarchyEntryId)
      )
      .map((row) => row.id);

    const allFilteredTargetRowIds = new Set([
      ...filteredTargetRows.map((row) => row.id),
      ...targetRowsForFilteredParents,
    ]);

    const mappedAndFilteredTargetRows = mappedTargetRows.filter((row) =>
      allFilteredTargetRowIds.has(row.originalId)
    );

    const allRows = [
      ...mappedAndFilteredHierarchyEntries,
      ...mappedAndFilteredTargetRows,
    ];

    const targetRowsWithoutParent = mappedTargetRows.filter(
      (row) => row.targetRowHierarchyEntryId === 'hierarchyEntry-none'
    );

    const targetRowsWithoutParentTotal = targetRowsWithoutParent.reduce(
      (acc, val) => {
        const unitPrice = val.unitPrice ?? new Big(0);
        const quantity = val.quantity ?? new Big(0);

        return acc.add(unitPrice.mul(quantity));
      },
      new Big(0)
    );

    const emptyHierarchyEntry: TargetRowOrTargetRowHierarchyEntry = {
      id: 'hierarchyEntry-none',
      originalId: 'none',
      description: sectionTitleText,
      type: 'targetRowHierarchyEntry' as const,
      projectId,
      orderId: null,
      workPackageId: undefined,
      quantity: null,
      unit: null,
      unitPrice: null,
      target: targetRowsWithoutParentTotal,
      referenceNo: null,
      topicId: null,
      isDeleted: false,
      isAntiRow: false,
      isSplitFrom: null,
      isOriginal: true,
      splitFrom: null,
      isDisabled: false,
      splitTo: null,
      targetRowHierarchyEntryId: null,
      refForSorting: '3_0_0',
    };

    const parentRows = [
      ...mappedAndFilteredHierarchyEntries.filter(
        (row) => row.targetRowHierarchyEntryId === null
      ),
      emptyHierarchyEntry,
    ];

    const allRowsGroupedByTargetRowHierarchyEntryId = allRows.reduce(
      (acc, row) => {
        if (row.targetRowHierarchyEntryId) {
          if (acc[row.targetRowHierarchyEntryId]) {
            acc[row.targetRowHierarchyEntryId]?.push(row);
          } else {
            acc[row.targetRowHierarchyEntryId] = [row];
          }
        }

        return acc;
      },
      {} as Partial<Record<string, TargetRowOrTargetRowHierarchyEntry[]>>
    );

    const process = (
      node: TargetRowOrTargetRowHierarchyEntry | undefined
    ): TargetRowOrTargetRowHierarchyEntry | undefined => {
      if (!node) {
        return undefined;
      }

      const filteredRows =
        allRowsGroupedByTargetRowHierarchyEntryId[node.id] ?? [];

      let mappedRows: TargetRowOrTargetRowHierarchyEntry[] = [];

      let filteredTarget: Big = new Big(0);

      if (
        node.type === 'targetRow' &&
        node.isAntiRow === false &&
        node.isDeleted === false &&
        node.isDisabled === false
      ) {
        filteredTarget = filteredTarget.add(node.target ?? new Big(0));
      }

      for (let i = 0; i < filteredRows.length; i++) {
        const targetRow = filteredRows[i];

        const processedRow = process({ ...targetRow });

        if (processedRow) {
          mappedRows.push(processedRow);
        }
      }

      return {
        ...node,
        subRows: mappedRows.sort(sortingFunction),
        filteredTarget: mappedRows.reduce((acc, row) => {
          return acc.add(row.filteredTarget ?? new Big(0));
        }, filteredTarget),
      };
    };

    const tree = parentRows.map(process).filter(isDefined);

    return tree;
  }, [
    topicsToBeProcessed,
    targetRowsToBeProcessed,
    ordersToBeProcessed,
    workPackagesToBeProcessed,
    targetRowHierarchyEntriesToBeProcessed,
    filterState,
    useTargetRowIds,
    searchParameter,
    sectionTitleText,
    projectId,
    targetRowIds,
    currentTargetRows,
    currentTargetRowHierarchyEntries,
    currentTopics,
    currentOrders,
    currentWorkPackages,
    isNewData,
  ]);

  return memoizedTree;
}
