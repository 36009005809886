import { RawAPIUpdatedEntities, APIUpdatedEntities } from '../../../types/api';
import { mapRawUpdatedEntities } from '../../../types/mappers';

import { makeAction, ExtractActionTypes } from '../../../utils/actionCreators';
import {
  PUT,
  BackendError,
  apiErrorHandlingWithDecode,
} from '../../../utils/api';
import { createAsyncThunk } from '../../../utils/thunk';

import { isLoading } from '../../reducers/target/moveTargetRows';

const actionCreators = {
  ...makeAction('moveTargetRowsToTopicStarted')<{
    requestId: string;
  }>(),
  ...makeAction('moveTargetRowsToTopicSuccess')<
    {
      requestId: string;
      targetRowIds: string[];
    } & APIUpdatedEntities
  >(),
  ...makeAction('moveTargetRowsToTopicFailure')<{
    requestId: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('moveTargetRowsToOrderStarted')<{
    requestId: string;
  }>(),
  ...makeAction('moveTargetRowsToOrderSuccess')<
    {
      requestId: string;
      targetRowIds: string[];
    } & APIUpdatedEntities
  >(),
  ...makeAction('moveTargetRowsToOrderFailure')<{
    requestId: string;
    error: BackendError | undefined;
  }>(),
};

export type MoveTargetRowsAction = ExtractActionTypes<typeof actionCreators>;

const {
  moveTargetRowsToTopicStarted,
  moveTargetRowsToTopicSuccess,
  moveTargetRowsToTopicFailure,
  moveTargetRowsToOrderStarted,
  moveTargetRowsToOrderSuccess,
  moveTargetRowsToOrderFailure,
} = actionCreators;

const requestMoveTargetRowsToTopic = async ({
  topicId,
  targetRowIds,
}: {
  topicId: string;
  targetRowIds: string[];
}): Promise<APIUpdatedEntities> => {
  const response = await PUT<RawAPIUpdatedEntities>(
    `v1/topics/${topicId}/move-target-rows`,
    {
      targetRowIds,
    }
  );

  return mapRawUpdatedEntities(response);
};

export const moveTargetRowsToTopic = ({
  requestId,
  topicId,
  targetRowIds,
}: {
  requestId: string;
  topicId: string;
  targetRowIds: string[];
}) =>
  createAsyncThunk(requestMoveTargetRowsToTopic, {
    args: [{ topicId, targetRowIds }],
    initialAction: moveTargetRowsToTopicStarted({ requestId }),
    isPending: isLoading(requestId),
    failureActionCreator: (error) =>
      moveTargetRowsToTopicFailure({
        requestId,
        error: apiErrorHandlingWithDecode(error),
      }),
    successActionCreator: (updatedEntities) =>
      moveTargetRowsToTopicSuccess({
        requestId,
        targetRowIds,
        ...updatedEntities,
      }),
  });

const requestMoveTargetRowsToOrder = async ({
  orderId,
  targetRowIds,
}: {
  orderId: string;
  targetRowIds: string[];
}): Promise<APIUpdatedEntities> => {
  const response = await PUT<RawAPIUpdatedEntities>(
    `v1/orders/${orderId}/move-target-rows`,
    {
      targetRowIds,
    }
  );

  return mapRawUpdatedEntities(response);
};

export const moveTargetRowsToOrder = ({
  requestId,
  orderId,
  targetRowIds,
}: {
  requestId: string;
  orderId: string;
  targetRowIds: string[];
}) =>
  createAsyncThunk(requestMoveTargetRowsToOrder, {
    args: [{ orderId, targetRowIds }],
    initialAction: moveTargetRowsToOrderStarted({ requestId }),
    isPending: isLoading(requestId),
    failureActionCreator: (error) =>
      moveTargetRowsToOrderFailure({
        requestId,
        error: apiErrorHandlingWithDecode(error),
      }),
    successActionCreator: (updatedEntities) =>
      moveTargetRowsToOrderSuccess({
        requestId,
        targetRowIds,
        ...updatedEntities,
      }),
  });
