import React from 'react';

import { DefaultTheme } from 'styled-components';

import { Revenue } from '../../../../store/reducers/revenue/revenue';

import StatusPill from '../../../../components/StatusPill';

import { TextId } from '../../../../localization';

type Color = keyof DefaultTheme['color'];
type Status = Revenue['status'];
type PillDescriptor = {
  textId: TextId;
  colorName: Color;
};

const pillDescriptors: Record<Status, PillDescriptor> = {
  Preliminary: {
    textId: 'revenue.status.Preliminary',
    colorName: 'logan',
  },
  Planned: { textId: 'revenue.status.Planned', colorName: 'logan' },
  Accepted: { textId: 'revenue.status.Accepted', colorName: 'logan' },
  Invoiced: { textId: 'revenue.status.Invoiced', colorName: 'amethystSmoke' },
  Paid: { textId: 'revenue.status.Paid', colorName: 'buttonPrimaryDisabled' },
};

type StatusPillProps = Pick<Revenue, 'status'>;

const RevenueStatusPill = ({ status }: StatusPillProps) => (
  <StatusPill {...pillDescriptors[status]} />
);

export default RevenueStatusPill;
