import {
  APIArrivalPostBody,
  APIArrival,
  RawAPIUpdatedEntities,
  APIUpdatedEntities,
} from '../../types/api';
import { ID } from '../../types/general';
import { mapRawUpdatedEntities } from '../../types/mappers';

import { makeApiActions, ExtractActionTypes } from '../../utils/actionCreators';
import { apiErrorHandlingWithDecode, POST, GET } from '../../utils/api';
import { Thunk } from '../../utils/thunk';

const actionCreators = {
  ...makeApiActions('get', 'arrivals')<APIArrival[]>(),
  ...makeApiActions('post', 'arrival')<APIUpdatedEntities>(),
};
export const {
  postArrivalStarted,
  postArrivalSuccess,
  postArrivalFailure,
  getArrivalsStarted,
  getArrivalsSuccess,
  getArrivalsFailure,
} = actionCreators;
export type ArrivalAction = ExtractActionTypes<typeof actionCreators>;

async function postArrival(orderId: ID, arrival: APIArrivalPostBody) {
  const response = await POST<RawAPIUpdatedEntities>(
    `v1/orders/${orderId}/arrivals`,
    arrival
  );

  return mapRawUpdatedEntities(response);
}

export const createArrival =
  (orderId: ID, arrival: APIArrivalPostBody): Thunk =>
  (dispatch, _) => {
    dispatch(postArrivalStarted());
    postArrival(orderId, arrival).then(
      (updatedEntities) => {
        dispatch(postArrivalSuccess(updatedEntities));
      },
      (error) => {
        dispatch(postArrivalFailure(apiErrorHandlingWithDecode(error)));
      }
    );
  };

async function getArrivalsForOrder(orderId: ID) {
  return GET<APIArrival[]>(`v1/orders/${orderId}/arrivals`);
}

export const fetchArrivalsForOrder =
  (orderId: ID): Thunk =>
  (dispatch, _) => {
    dispatch(getArrivalsStarted());

    getArrivalsForOrder(orderId).then(
      (response) => {
        dispatch(getArrivalsSuccess(response));
      },
      (error) => {
        dispatch(getArrivalsFailure(apiErrorHandlingWithDecode(error)));
      }
    );
  };
