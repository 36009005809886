import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import styled, { css } from 'styled-components';

import { markNotificationRead } from '../../../store/actions/notification';

import { NotificationFeedItem } from '../../../types/general';

import { IconRing, IconRingFilled } from '../../../assets';

import { BaseButton } from '../../Buttons';

export const FeedSidebarHeader = styled.div`
  position: sticky;
  top: 0;
  background-color: ${({ theme }) => theme.color.sidebarBackground};
  z-index: 9999;
`;

export const Filters = styled.div`
  position: relative;
  padding: 1.5rem 2rem 2rem 0;
`;

export const Filter = styled(BaseButton)`
  ${({ theme }) => css`
    position: relative;

    display: grid;
    align-items: center;

    line-height: 1.5rem;
    color: ${theme.color.sidebarFadedText};
    font-size: 14px;

    grid-template-columns: 16px 1fr;
    grid-column-gap: 5px;
    /* stylelint-disable selector-max-class -- TODO: Needs to be refactored away */
    &.selected {
      font-weight: bold;
      color: ${theme.color.white};
    }
    & .notificationIndicator {
      position: absolute;
      top: 2px;
      left: 10px;
    }
    &:hover {
      color: ${theme.color.white};
    }
  `}
`;
export const FilterName = styled.div`
  position: relative;

  display: grid;
  align-items: center;

  justify-items: flex-end;
  grid-template-columns: 1fr 24px;
`;

export const NotificationContainer = styled.div`
  padding-bottom: 1.25rem;
  display: flex;
`;

export const StyledHeader = styled.h4`
  margin: 0;

  padding: 0;

  font-size: ${(props) => props.theme.fontSize.base};
  line-height: ${(props) => props.theme.fontSize.base};
  font-weight: 900;
`;

export const StyledLinkArea = styled.div`
  margin-top: 12px;

  display: flex;
  flex-direction: column;

  color: ${(props) => props.theme.color.white};
  font-size: ${(props) => props.theme.fontSize.small};
  line-height: 1.125rem;
`;

export const StyledLink = styled.a`
  color: ${(props) => props.theme.color.white};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

export const StyledLinkDiv = styled.div`
  position: relative;

  display: flex;

  color: ${(props) => props.theme.color.white};
  text-decoration: none;

  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export const DueDateNotificationIcons = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  color: ${(props) => props.theme.color.red};

  /* stylelint-disable selector-max-type -- svgs inside the button are most easily */

  > svg {
    width: 16px;
    height: 16px;
    background-color: transparent;
  }
`;

export const StyledRouterLink = styled(Link)`
  margin-top: 12px;
  color: ${(props) => props.theme.color.white};
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export const StyledNotificationContent = styled.div`
  position: relative;

  border: 1px solid ${(props) => props.theme.color.notificationContent};
  border-radius: 3px;

  padding: 1rem;

  max-width: 24rem;
  min-width: 24rem;

  flex: 1;

  background: ${(props) => props.theme.color.sidebarNotification};
`;

export const NotificationsListHeader = styled.div`
  padding-bottom: ${(props) => props.theme.margin[16]};
  font-size: 14px;
  font-weight: 500;
`;
export const NotificationsList = styled.div``;

export const Mentions = styled.div``;

export const MentionName = styled.div`
  position: relative;

  display: flex;
  align-items: center;

  font-size: ${(props) => props.theme.fontSize.small};
  font-weight: 600;
`;

export const UpdateTime = styled.div``;

export const MentionText = styled.div`
  padding-left: 14px;
`;

export const StyledIconArea = styled.div`
  padding: 1rem 0 0 1rem;
  flex: 1;
`;

// new notification indicator 'Uusi' / date tag / elapsed time

export const NotificationTimeStamp = styled.div`
  position: absolute;
  right: ${(props) => props.theme.margin[16]};
  top: ${(props) => props.theme.margin[16]};

  border-radius: 5px;

  padding: ${(props) => props.theme.margin[4]}
    ${(props) => props.theme.margin[10]};

  font-size: ${({ theme }) => theme.fontSize.tiny};
`;

export const MarkNotificationReadButton = ({
  notification,
}: {
  notification: NotificationFeedItem;
}) => {
  const dispatch = useDispatch();

  return (
    <BaseButton
      disabled={notification.isRead}
      onClick={() => {
        if (!notification.isRead) {
          dispatch(markNotificationRead(notification));
        }
      }}
    >
      {notification.isRead ? (
        <img src={IconRing} alt="luettu" />
      ) : (
        <img src={IconRingFilled} alt="merkkaa luetuksi" />
      )}
    </BaseButton>
  );
};
