import React from 'react';

import styled from 'styled-components';

import { RenderableOrderRow } from '../../../../store/reducers/orderRow';

import { IconButton, SecondaryButton } from '../../../../components/Buttons';
import Modal, { Header, Content } from '../../../../components/Modal/Modal';
import Txt from '../../../../components/Txt';

import { IconCloseWhite } from '../../../../assets/svg';

import { InvoiceTable } from './Table';

type ModalProps = {
  orderRow: RenderableOrderRow;
  onClose: () => void;
};

export const InvoicesPerOrderRowModal = ({ orderRow, onClose }: ModalProps) => {
  return (
    <Modal onClose={onClose}>
      <FormContainer>
        <StyledHeader>
          <Txt
            id="order.invoicesPerOrderRowModal.title"
            values={{
              orderRowNo: orderRow.rowNumber.toString(),
              orderRowDescription: orderRow.description,
              arrivalTotal: orderRow.arrivalTotal,
            }}
          />
          <IconButton icon={IconCloseWhite} onClick={onClose} />
        </StyledHeader>
        <StyledContent>
          <TableContainer>
            <InvoiceTable orderRowId={orderRow.id} onClose={onClose} />
          </TableContainer>
          <ButtonGroup>
            <CancelButton onClick={onClose}>
              <Txt id="common.close" />
            </CancelButton>
          </ButtonGroup>
        </StyledContent>
      </FormContainer>
    </Modal>
  );
};

const StyledHeader = styled(Header)`
  padding: ${({ theme }) => `${theme.margin[16]}`};

  height: 3rem;

  display: flex;
  justify-content: space-between;
  align-items: center;

  align-self: stretch;
`;

const FormContainer = styled.div`
  box-shadow: 0px 4px 42px rgba(0, 0, 0, 0.25);
  width: 60vw;
  display: flex;
  flex-direction: column;
`;

const StyledContent = styled(Content)`
  padding: ${({ theme }) => `${theme.margin[32]}`};
  min-height: 16rem;
  max-height: none;
`;

const TableContainer = styled.div`
  max-height: 70vh;
  overflow-y: scroll;
`;

const ButtonGroup = styled.div`
  margin-top: 3rem;
  display: flex;
  justify-content: end;
`;

const CancelButton = styled(SecondaryButton)`
  margin: 0 ${({ theme }) => `${theme.margin[8]}`};
  width: 15%;
`;
