import {
  APIUpdatedEntities,
  APIPresettledInvoiceRow,
  RawAPIPresettledInvoiceRow,
  RawAPIUpdatedEntities,
} from '../../types/api';
import {
  mapRawPresettledInvoiceRow,
  mapRawUpdatedEntities,
} from '../../types/mappers';

import { ExtractActionTypes, makeAction } from '../../utils/actionCreators';
import * as api from '../../utils/api';
import { BackendError } from '../../utils/api';
import { flow } from '../../utils/function';
import * as remoteData from '../../utils/remoteData';
import { createAsyncThunk } from '../../utils/thunk';

import {
  selectPresettledInvoiceRowRequests,
  selectSpreadArrivalRowRequests,
} from '../reducers/presettledInvoiceRows';

const actionCreators = {
  ...makeAction('getPresettledInvoiceRowsStarted')<{ invoiceId: string }>(),
  ...makeAction('getPresettledInvoiceRowsSuccess')<{
    invoiceId: string;
    presettledInvoiceRows: APIPresettledInvoiceRow[];
  }>(),
  ...makeAction('getPresettledInvoiceRowsFailure')<{
    invoiceId: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('postSpreadArrivalRowsStarted')<{ invoiceId: string }>(),
  ...makeAction('postSpreadArrivalRowsSuccess')<APIUpdatedEntities>(),
  ...makeAction('postSpreadArrivalRowsFailure')<{
    invoiceId: string;
    error: BackendError | undefined;
  }>(),
};

export type PresettledInvoiceRowsAction = ExtractActionTypes<
  typeof actionCreators
>;

export const {
  getPresettledInvoiceRowsStarted,
  getPresettledInvoiceRowsSuccess,
  getPresettledInvoiceRowsFailure,
  postSpreadArrivalRowsStarted,
  postSpreadArrivalRowsSuccess,
  postSpreadArrivalRowsFailure,
} = actionCreators;

async function getPresettledInvoiceRows(invoiceId: string): Promise<{
  presettledInvoiceRows: APIPresettledInvoiceRow[];
  invoiceId: string;
}> {
  const presettledInvoiceRows = (
    await api.GET<RawAPIPresettledInvoiceRow[]>(
      `v1/purchase-invoice-headers/${invoiceId}/presettled`
    )
  ).map(mapRawPresettledInvoiceRow);

  return {
    presettledInvoiceRows,
    invoiceId,
  };
}

export const getPresettledInvoiceRowsByInvoiceId = (invoiceId: string) =>
  createAsyncThunk(getPresettledInvoiceRows, {
    args: [invoiceId],
    isPending: flow(
      selectPresettledInvoiceRowRequests(invoiceId),
      remoteData.isLoading
    ),
    initialAction: getPresettledInvoiceRowsStarted({ invoiceId }),
    successActionCreator: (response) =>
      getPresettledInvoiceRowsSuccess(response),
    failureActionCreator: (error) =>
      getPresettledInvoiceRowsFailure({
        invoiceId,
        error: api.apiErrorHandlingWithDecode(error),
      }),
  });

async function spreadArrivalRowsByPresettled(
  invoiceId: string
): Promise<APIUpdatedEntities> {
  return mapRawUpdatedEntities(
    await api.POST<RawAPIUpdatedEntities>(
      `v1/purchase-invoice-headers/${invoiceId}/spread-presettled`,
      {}
    )
  );
}

export const spreadArrivalRowsByPresettledInvoiceRows = (invoiceId: string) =>
  createAsyncThunk(spreadArrivalRowsByPresettled, {
    args: [invoiceId],
    isPending: flow(
      selectSpreadArrivalRowRequests(invoiceId),
      remoteData.isLoading
    ),
    initialAction: postSpreadArrivalRowsStarted({ invoiceId }),
    successActionCreator: (response) => postSpreadArrivalRowsSuccess(response),
    failureActionCreator: (error) =>
      postSpreadArrivalRowsFailure({
        invoiceId,
        error: api.apiErrorHandlingWithDecode(error),
      }),
  });
