import React from 'react';

import { TextId } from '../../localization';

import Txt from '../Txt';

export type TextOnlyNotificationProps = {
  textId: TextId;
};

const TextOnlyNotification: React.FC<TextOnlyNotificationProps> = ({
  textId,
}) => {
  return (
    <>
      <Txt id={textId} />
    </>
  );
};

export default TextOnlyNotification;
