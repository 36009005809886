import Big from 'big.js';
import { Reducer } from 'redux';

import { ID } from '../../types/general';

import {
  Selector,
  assertActionPayloadIsNotApiUpdatedEntities,
  isUpdatedEntitiesActionType,
} from './utils';
import { BackendError } from '../../utils/api';
import { isDefined } from '../../utils/general';
import normalizeBy from '../../utils/normalizeBy';
import * as remoteData from '../../utils/remoteData';

import { ActionTypes } from '../actionTypes';

export type Snapshot = {
  id: ID;
  userId: ID;
  userName: string;
  projectId: ID;
  description: string;
  companyReportingPeriodDescription: string;
  predictionChangeBeforeLocking: Big;
  targetChangeBeforeLocking: Big;
  revenuePredictionChangeBeforeLocking: Big;
  targetTotal: Big;
  additionalTargetTotal: Big;
  costPredictionTotal: Big;
  contractTotal: Big;
  changeOrdersTotal: Big;
  reservesTotal: Big;
  revenueTotal: Big;
  isDeleted: boolean;
  statusId: ID;
  createdAt: Date;
  updatedAt: Date;
  snapshotTypeComment: string | null;
  snapshotTypeId: string;
};

type Err = BackendError | undefined;

type State = {
  getRequests: Partial<Record<string, remoteData.RemoteData<undefined, Err>>>;
  getCsvRequests: Partial<
    Record<string, remoteData.RemoteData<undefined, Err>>
  >;
  getOrderSnapshotsRequests: Partial<
    Record<string, remoteData.RemoteData<undefined, Err>>
  >;
  createRequests: Partial<
    Record<string, remoteData.RemoteData<undefined, Err>>
  >;
  data: Partial<Record<string, Snapshot>>;
};

const initialState: State = {
  getRequests: {},
  getCsvRequests: {},
  getOrderSnapshotsRequests: {},
  createRequests: {},
  data: {},
};

const SnapshotReducer: Reducer<State, ActionTypes> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case 'GET_SNAPSHOTS_STARTED': {
      const { projectId } = action.payload;

      const getRequests = {
        ...state.getRequests,
        [projectId]: remoteData.loading,
      };

      return { ...state, getRequests };
    }
    case 'GET_SNAPSHOTS_FAILURE': {
      const { projectId, error } = action.payload;

      const getRequests = {
        ...state.getRequests,
        [projectId]: remoteData.fail(error),
      };

      return { ...state, getRequests };
    }
    case 'GET_SNAPSHOTS_SUCCESS': {
      const { projectId, snapshots } = action.payload;

      const getRequests = {
        ...state.getRequests,
        [projectId]: remoteData.succeed(undefined),
      };

      const data = {
        ...state.data,
        ...normalizeBy('id', snapshots),
      };

      return {
        createRequests: state.createRequests,
        getOrderSnapshotsRequests: state.getOrderSnapshotsRequests,
        getCsvRequests: state.getCsvRequests,
        getRequests,
        data,
      };
    }
    case 'GET_ALL_ORDER_SNAPSHOTS_FOR_SNAPSHOT_STARTED': {
      const { snapshotId } = action.payload;

      const getOrderSnapshotsRequests = {
        ...state.getOrderSnapshotsRequests,
        [snapshotId]: remoteData.loading,
      };

      return { ...state, getOrderSnapshotsRequests };
    }
    case 'GET_ALL_ORDER_SNAPSHOTS_FOR_SNAPSHOT_FAILURE': {
      const { snapshotId, error } = action.payload;

      const getOrderSnapshotsRequests = {
        ...state.getOrderSnapshotsRequests,
        [snapshotId]: remoteData.fail(error),
      };

      return { ...state, getOrderSnapshotsRequests };
    }

    case 'GET_ALL_ORDER_SNAPSHOTS_FOR_SNAPSHOT_SUCCESS': {
      const { snapshotId } = action.payload;

      const getOrderSnapshotsRequests = {
        ...state.getOrderSnapshotsRequests,
        [snapshotId]: remoteData.succeed(undefined),
      };

      return {
        createRequests: state.createRequests,
        getOrderSnapshotsRequests,
        getRequests: state.getRequests,
        getCsvRequests: state.getCsvRequests,
        data: state.data,
      };
    }
    case 'POST_SNAPSHOT_REQUESTED': {
      return {
        ...state,
        createRequests: {
          ...state.createRequests,
          [action.payload.requestId]: remoteData.loading,
        },
      };
    }
    case 'POST_SNAPSHOT_FAILURE': {
      return {
        ...state,
        createRequests: {
          ...state.createRequests,
          [action.payload.requestId]: remoteData.fail(action.payload.error),
        },
      };
    }
    case 'POST_SNAPSHOT_SUCCESS': {
      const { snapshots: updatedSnapshots, requestId } = action.payload;

      if (!updatedSnapshots || !requestId) {
        return state;
      }

      const createRequests = {
        ...state.createRequests,
        [requestId]: remoteData.succeed(undefined),
      };

      const data = { ...state.data };
      updatedSnapshots.forEach((snapshot) => {
        const { isDeleted, id } = snapshot;

        if (isDeleted) {
          delete data[id];
        } else {
          data[id] = snapshot;
        }
      });

      return {
        ...state,
        // force refetching order snapshots
        getOrderSnapshotsRequests: {},
        createRequests,
        data,
      };
    }
    case 'GET_DETAILED_SNAPSHOTS_CSV_STARTED': {
      const { projectId } = action.payload;

      const getCsvRequests = {
        ...state.getCsvRequests,
        [projectId]: remoteData.loading,
      };

      return { ...state, getCsvRequests };
    }
    case 'GET_DETAILED_SNAPSHOTS_CSV_FAILURE': {
      const { projectId, error } = action.payload;

      const getCsvRequests = {
        ...state.getCsvRequests,
        [projectId]: remoteData.fail(error),
      };

      return { ...state, getCsvRequests };
    }
    case 'GET_DETAILED_SNAPSHOTS_CSV_SUCCESS': {
      const { projectId } = action.payload;

      const getCsvRequests = {
        ...state.getCsvRequests,
        [projectId]: remoteData.succeed(undefined),
      };

      return { ...state, getCsvRequests };
    }
  }

  if (isUpdatedEntitiesActionType(action)) {
    const { snapshots: updatedSnapshots } = action.payload;

    if (!updatedSnapshots) {
      return state;
    }

    const data = { ...state.data };
    updatedSnapshots.forEach((snapshot) => {
      const { isDeleted, id } = snapshot;

      if (isDeleted) {
        delete data[id];
      } else {
        data[id] = snapshot;
      }
    });

    return {
      ...state,
      data,
    };
  }

  assertActionPayloadIsNotApiUpdatedEntities(action);

  return state;
};

export default SnapshotReducer;

export const getSnapshotsFetchRequest = (
  projectId: string
): Selector<remoteData.RemoteAction> => {
  return ({
    snapshots: {
      getRequests: { [projectId]: request },
    },
  }) => request ?? remoteData.notAsked;
};

export const getDetailedSnapshotsCsvFetchRequest = (
  projectId: string
): Selector<remoteData.RemoteAction> => {
  return ({
    snapshots: {
      getCsvRequests: { [projectId]: request },
    },
  }) => request ?? remoteData.notAsked;
};

export const getOrderSnapshotsForSnapshotIdFetchRequest = (
  snapshotId: string
): Selector<remoteData.RemoteAction> => {
  return ({
    snapshots: {
      getOrderSnapshotsRequests: { [snapshotId]: request },
    },
  }) => request ?? remoteData.notAsked;
};

export const getSnapshots: (
  projectId: string
) => Selector<remoteData.RemoteData<Snapshot[]>> =
  (projectId) =>
  ({
    snapshots: {
      getRequests: { [projectId]: requestState = remoteData.notAsked },
      data: snapshots = undefined,
    },
  }) =>
    remoteData.map(requestState, (_) => {
      if (!snapshots) {
        return [];
      }

      const values = Object.values(snapshots)
        .filter(isDefined)
        .filter((snapshot) => snapshot.projectId === projectId);

      return values;
    });

export const getSnapshotCreateRequest = (
  requestId: string
): Selector<remoteData.RemoteAction> => {
  return ({
    snapshots: {
      createRequests: { [requestId]: request },
    },
  }) => request ?? remoteData.notAsked;
};

export const getSnapshotCreateRequests = (): Selector<
  Partial<Record<string, remoteData.RemoteData<undefined, Err>>>
> => {
  return ({ snapshots: { createRequests } }) => createRequests;
};
