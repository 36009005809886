import React, { useState, useRef } from 'react';

import styled from 'styled-components';

import { APIPurchaseInvoiceAttachmentFile } from '../../types/api';

import { IconDelete, IconInvalid, IconPaperAdd } from '../../assets/svg';

import { TextId } from '../../localization';

import { SecondaryButton } from '../Buttons';
import ToolTip from '../Tooltip';
import Txt from '../Txt';

const KILO_BYTES_PER_BYTE = 1024;

const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 10 * KILO_BYTES_PER_BYTE ** 2; // 10 MB

const convertNestedObjectToArray = (nestedObj: Record<string, File>) =>
  Object.keys(nestedObj).map((key) => nestedObj[key]);

const convertBytesToKB = (bytes: number) =>
  Math.round(bytes / KILO_BYTES_PER_BYTE);

export type FileUploadProps = {
  updateFilesCb: (files: File[]) => void;
  deleteExistingFile?: (fileId: string) => void;
  maxFileSizeInBytes?: number;
  multiple: boolean;
  lowerHeight?: boolean;
  additionalInfoTextId?: TextId;
  existingFiles?: APIPurchaseInvoiceAttachmentFile[];
};

const FileUpload = ({
  updateFilesCb,
  deleteExistingFile,
  maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
  lowerHeight = false,
  additionalInfoTextId,
  existingFiles,
  ...otherProps
}: FileUploadProps) => {
  const fileInputField = useRef<HTMLInputElement>(null);
  const [files, setFiles] = useState<Record<string, File>>({});
  const [highlightError, setHighlightError] = useState(false);
  const [targeted, setTargeted] = useState(false);
  const lowerHeightProp = lowerHeight;

  const addNewFiles = (newFiles: File[]) => {
    for (const file of newFiles) {
      if (
        file.size <= maxFileSizeInBytes &&
        file.name.toLowerCase().endsWith('.pdf')
      ) {
        if (!otherProps.multiple) {
          return { file };
        }

        files[file.name] = file;
      } else {
        setHighlightError(true);
      }
    }

    return { ...files };
  };

  const callUpdateFilesCb = (updFiles: Record<string, File>) => {
    updateFilesCb(convertNestedObjectToArray(updFiles));
  };

  const handleNewFileUpload = (e: { target: { files: any } }) => {
    setHighlightError(false);
    setTargeted(false);
    const { files: newFiles } = e.target;

    if (newFiles.length) {
      const updatedFiles = addNewFiles(newFiles);
      setFiles(updatedFiles);
      callUpdateFilesCb(updatedFiles);
    } else {
      setHighlightError(true);
    }
  };

  const handleDragEnter = () => {
    setTargeted(true);
  };

  const handleDragLeave = () => {
    setTargeted(false);
  };

  const removeFile = (fileName: string) => {
    delete files[fileName];
    setFiles({ ...files });
    callUpdateFilesCb({ ...files });
  };

  return (
    <>
      <FileUploadContainer lowerHeight={lowerHeightProp} targeted={targeted}>
        <DragDropText isError={false}>
          <Txt id="order.receiveMode.invoice.attachment.upload.dragdrop" />
        </DragDropText>
        <SecondaryButton as="p">
          <UploadIcon />
          <Txt id="order.receiveMode.invoice.attachment.upload.button" />
        </SecondaryButton>
        <DragDropText isError={highlightError}>
          {highlightError ? (
            <>
              <IconError />
              &nbsp;
            </>
          ) : (
            ''
          )}
          {additionalInfoTextId ? (
            <Txt id={additionalInfoTextId} />
          ) : (
            <Txt id="order.receiveMode.invoice.attachment.upload.format" />
          )}
        </DragDropText>
        <FormField
          type="file"
          ref={fileInputField}
          onChange={handleNewFileUpload}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          title=""
          value=""
          {...otherProps}
        />
      </FileUploadContainer>
      {existingFiles?.length && deleteExistingFile ? (
        <FilePreviewContainer>
          <TxtContainer>
            <Txt id="order.receiveMode.invoice.attachemnt.deletable" />
          </TxtContainer>
          <FileContainer>
            {existingFiles.map((file) => {
              return (
                <PreviewContainer key={file.originalFilename} type="button">
                  <FileMetaData>
                    <ToolTip tip={file.originalFilename}>
                      <span>{file.originalFilename}</span>
                    </ToolTip>
                    <StyledAside>
                      <DeleteIcon onClick={() => deleteExistingFile(file.id)} />
                    </StyledAside>
                  </FileMetaData>
                </PreviewContainer>
              );
            })}
          </FileContainer>
        </FilePreviewContainer>
      ) : null}
      <FilePreviewContainer>
        {Object.keys(files).length > 0 ? (
          <TxtContainer>
            <Txt id="order.receiveMode.invoice.attachemnt.new" />
          </TxtContainer>
        ) : null}
        <FileContainer>
          {Object.keys(files).map((fileName) => {
            const file = files[fileName];

            return (
              <PreviewContainer key={fileName} type="button">
                <FileMetaData>
                  <ToolTip tip={file.name}>
                    <FileNameSpan>{file.name}</FileNameSpan>
                  </ToolTip>
                  <StyledAside>
                    <span>{convertBytesToKB(file.size)} kb</span>
                    <DeleteIcon onClick={() => removeFile(file.name)} />
                  </StyledAside>
                </FileMetaData>
              </PreviewContainer>
            );
          })}
        </FileContainer>
      </FilePreviewContainer>
    </>
  );
};

const IconError = styled(IconInvalid)`
  margin: 0 0.25rem 0.25rem 0.25rem;
  width: 1.5rem;
  height: 1.5rem;
`;

const FileUploadContainer = styled.div<{
  targeted: boolean;
  lowerHeight: boolean | undefined;
}>`
  position: relative;

  margin: 0;
  margin-bottom: 15px;

  border: ${({ targeted }) =>
    targeted ? '2px solid black' : '2px dotted lightgray'};
  border-radius: 6px;

  padding: ${({ lowerHeight }) => (lowerHeight ? '0.5rem 2rem' : '8rem 2rem;')};

  display: flex;
  flex-direction: column;
  align-items: center;

  &:hover {
    ${SecondaryButton} {
      background: ${(props) => props.theme.color.graphiteB97};
    }
  }
`;

const UploadIcon = styled(IconPaperAdd)`
  margin-right: 0.5rem;
  width: 1rem;
  height: 1rem;
`;

const FormField = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  border: none;

  width: 100%;

  display: block;

  opacity: 0;
`;

const DragDropText = styled.p<{ isError: boolean }>`
  margin: 1.5rem 0 1rem 0;
  display: flex;
  align-items: center;
  font-weight: ${({ isError }) => (isError ? 'bold' : 'normal')};
`;

const FilePreviewContainer = styled.section`
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

const FileContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const PreviewContainer = styled(SecondaryButton)`
  position: relative;

  margin: 0.25rem;

  width: 10rem;
  height: 5rem;

  flex-shrink: 0;

  cursor: default;
`;

const FileMetaData = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  padding: 10px;

  display: flex;
  flex-direction: column;

  color: rgba(44, 42, 41, 255);

  overflow: hidden;
`;

const FileNameSpan = styled.span`
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const StyledAside = styled.aside`
  margin-top: auto;
  display: flex;
  justify-content: space-between;
`;

const DeleteIcon = styled(IconDelete)`
  &:hover {
    border: 1px solid red;
  }
`;

const TxtContainer = styled.div`
  margin-bottom: 0.5rem;
  display: block;
`;

export default FileUpload;
