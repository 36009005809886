import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FormikErrors, useFormik } from 'formik';
import styled from 'styled-components';
import { v4 } from 'uuid';

import { APIProject, getProjects } from '../../store/reducers/project';
import {
  selectAllUsers,
  selectPostUserRequest,
} from '../../store/reducers/projectUsers';

import { createUser, fetchAllUsers } from '../../store/actions';

import useLocalization from '../../hooks/useLocalization';
import useRemoteData from '../../hooks/useRemoteData';
import useTxt from '../../hooks/useTxt';

import { isDefined } from '../../utils/general';
import { isLoading, withDefault } from '../../utils/remoteData';

import { IconDelete, IconPlus } from '../../assets/svg';

import { isTextId } from '../../localization';

import defaultTheme from '../../styles/theme';
import {
  ButtonGroup,
  IconButton,
  IconTextButton,
  PrimaryButton,
  SecondaryButton,
} from '../Buttons';
import DropDownSelect from '../DropDownSelect';
import TextInput from '../Input/TextInput';
import { Spinner } from '../Loading';
import Modal, { Container, Content, Footer, Header } from '../Modal/Modal';
import { languageOptions } from '../Navigation/UserSettings';
import Txt from '../Txt';
import {
  containerStylingForRoleDropdown,
  RoleOptionDiv,
  roleOptions,
  StyledRoleIcon,
} from './UserRolesModal';

interface AddUserModalProps {
  closeModal: () => void;
  defaultProjectToAdd?: APIProject;
}

interface FormValues {
  email: string;
  name: string;
  languageId?: string;
  roles: {
    id: string;
    roleId: string | null;
    projectId: string | null;
  }[];
}

const AddUserModal = ({
  closeModal,
  defaultProjectToAdd,
}: AddUserModalProps) => {
  const [requestId] = React.useState(v4());

  const dispatch = useDispatch();

  const allUsers = useRemoteData(selectAllUsers(), fetchAllUsers()) ?? [];

  const localization = useLocalization();

  const existingEmails = allUsers.map((user) => user.emails).flat();

  const validate = (values: FormValues): FormikErrors<FormValues> => {
    const errors: FormikErrors<FormValues> = {};

    if (values.name.length === 0 || !values.name) {
      errors.name = 'form.inputError.isRequired';
    }

    if (values.name.length > 100) {
      errors.name = 'validation.string.length.toolTip';
    }

    if (
      !values.email ||
      !values.email.includes('@') ||
      values.email.length < 4
    ) {
      errors.email = 'auth.email.signIn.form.email.error';
    }

    if (existingEmails.includes(values.email)) {
      errors.email = 'navigation.projectMenu.userManagement.unique.email.error';
    }

    if (!values.languageId) {
      errors.name = 'form.inputError.isRequired';
    }

    const roleErrors: FormikErrors<{
      projectId: string | null;
      roleId: string | null;
    }>[] = [];

    values.roles.forEach((role, index) => {
      roleErrors[index] = {};

      if (!role.projectId) {
        roleErrors[index] = { projectId: 'error' };
      }

      if (!role.roleId) {
        roleErrors[index] = { ...roleErrors[index], roleId: 'error' };
      }
    });

    errors.roles = roleErrors;

    if (errors.roles.every((role) => Object.keys(role).length === 0)) {
      delete errors.roles;
    }

    return errors;
  };
  const projects = withDefault(useSelector(getProjects), {});

  const projectOptions = Object.values(projects)
    .filter(isDefined)
    .map((p) => ({
      value: p.id,
      label: `${p.code} - ${p.name}`,
      key: p.id,
    }));

  const defaultRole = {
    id: 'default',
    roleId: null,
    projectId: defaultProjectToAdd?.id ?? null,
  };

  const nameLabel = useTxt('common.name');
  const emailLabel = useTxt('common.email');

  const removeText = useTxt('common.remove');

  const requestState = useSelector(selectPostUserRequest(requestId));

  const showSpinner = isLoading(requestState);

  const isSuccess = requestState.kind === 'Success';

  const formik = useFormik<FormValues>({
    initialValues: {
      email: '',
      name: '',
      languageId: '1',
      roles: [defaultRole],
    },
    validate,
    validateOnMount: true,
    onSubmit: (values) => {
      const mappedRoles = values.roles.map((role) => ({
        projectId: role.projectId ?? '',
        roleId: role.roleId ?? '',
        validFrom: null,
        validTo: null,
        isDeleted: false,
      }));
      dispatch(createUser(requestId, { ...values, roles: mappedRoles }));
    },
  });

  useEffect(() => {
    if (isSuccess) {
      closeModal();
    }
  }, [isSuccess, closeModal]);

  const selectedProjects = formik.values.roles.map((role) => role.projectId);

  const filteredProjects = projectOptions
    .filter((project) => !selectedProjects.includes(project.value))
    .sort((a, b) => a.label.localeCompare(b.label));

  const selectedProject = (projectId: string | null) =>
    projectOptions.filter((p) => p.value === projectId);

  const addNewProjectRole = () => {
    const newRole = { id: v4(), roleId: null, projectId: null };

    formik.setFieldValue('roles', [...formik.values.roles, newRole]);
  };

  const removeProjectRole = (index: number) => {
    const newRoles = formik.values.roles.filter((_, i) => i !== index);

    formik.setFieldValue('roles', [...newRoles]);
  };

  const roleOptionsWithLabel = roleOptions.map((role) => ({
    value: role.value,
    key: role.value,
    label: (
      <RoleOptionDiv>
        <StyledRoleIcon src={role.image} alt={`${role.label}.picture`} />
        <Txt id={role.label} component="b" />
        <span />
        <Txt id={`${role.label}.description` as const} />
      </RoleOptionDiv>
    ),
    shortlabel: localization.formatMessage({ id: role.label }),
  }));

  const formikErrorEmail =
    formik.errors.email && isTextId(formik.errors.email)
      ? localization.formatMessage({ id: formik.errors.email })
      : undefined;

  const formikErrorName =
    formik.errors.name && isTextId(formik.errors.name)
      ? localization.formatMessage(
          {
            id: formik.errors.name,
          },
          { length: 100 }
        )
      : undefined;

  return (
    <Modal onClose={closeModal}>
      <StyledContainer noShadow>
        <StyledForm onSubmit={formik.handleSubmit}>
          <Header>
            <Txt id="navigation.projectMenu.userManagement.addNewUser" />
          </Header>
          <StyledContent>
            <Column>
              <StyledTextInput
                label={nameLabel}
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
                errorMessage={formikErrorName}
              />
              <StyledTextInput
                label={emailLabel}
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
                errorMessage={formikErrorEmail}
              />
              <DropDownDiv>
                <StyledLabel>
                  <Txt id="users.editMode.language" />
                </StyledLabel>
                <DropDownSelect
                  additionalContainerStyles={additionalContainerStyling}
                  defaultValue="1"
                  onChange={(value) =>
                    formik.setFieldValue('languageId', value)
                  }
                  id="languageId"
                  options={languageOptions}
                />
              </DropDownDiv>
              <ProjectsDiv>
                <StyledLabel>
                  <Txt id="common.projects" />
                </StyledLabel>
                {formik.values.roles.map((role, index) => {
                  const errors =
                    typeof formik.errors.roles?.[index] === 'object'
                      ? formik.errors.roles[index]
                      : {};

                  return (
                    <ProjectRoleDiv
                      key={`project-${role.projectId}-role-${role.roleId}-id-${role.id}`}
                    >
                      <DropDownSelect
                        additionalContainerStyles={
                          containerStylingForProjectDropdown
                        }
                        value={
                          formik.values.roles[index].projectId ?? undefined
                        }
                        onChange={(value) =>
                          formik.setFieldValue(
                            `roles[${index}].projectId`,
                            value
                          )
                        }
                        label={`roles-${index}-projectId-select`}
                        id={`roles-${index}-projectId-select`}
                        options={[
                          ...selectedProject(
                            formik.values.roles[index].projectId
                          ),
                          ...filteredProjects,
                        ]}
                        required
                        invalid={
                          typeof errors === 'object' && !!errors.projectId
                        }
                        listHeight={400}
                      />
                      <Row>
                        <DropDownSelect
                          additionalContainerStyles={
                            containerStylingForRoleDropdown
                          }
                          value={formik.values.roles[index].roleId ?? undefined}
                          onChange={(value) =>
                            formik.setFieldValue(
                              `roles[${index}].roleId`,
                              value
                            )
                          }
                          label={`roles-${index}-roleId-select`}
                          id={`roles-${index}-roleId-select`}
                          optionLabelProp="shortlabel"
                          options={roleOptionsWithLabel}
                          listHeight={400}
                          required
                          invalid={
                            typeof errors === 'object' && !!errors.roleId
                          }
                        />
                        <IconButton
                          icon={IconDelete}
                          onClick={() => removeProjectRole(index)}
                          aria-label={removeText}
                        />
                      </Row>
                    </ProjectRoleDiv>
                  );
                })}
                <Toolbar>
                  <IconTextButton
                    icon={IconPlus}
                    id="addUserModal.button.newProject"
                    type="button"
                    onClick={() => addNewProjectRole()}
                  >
                    <Txt id="navigation.projectMenu.userManagement.addProject" />
                  </IconTextButton>
                </Toolbar>
              </ProjectsDiv>
            </Column>
          </StyledContent>
          <Footer>
            <ButtonGroup>
              <CancelButton onClick={closeModal}>
                <Txt id="common.cancel" />
              </CancelButton>
              <ActionButton
                type="submit"
                disabled={!formik.isValid || showSpinner}
              >
                {showSpinner ? (
                  <Spinner size="1rem" />
                ) : (
                  <Txt id="common.save" />
                )}
              </ActionButton>
            </ButtonGroup>
          </Footer>
        </StyledForm>
      </StyledContainer>
    </Modal>
  );
};

export default AddUserModal;

const StyledForm = styled.form`
  margin-bottom: ${(props) => props.theme.margin[16]};
`;

const StyledContainer = styled(Container)`
  width: 60vw;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
`;

const StyledContent = styled(Content)`
  padding: 0 ${({ theme }) => `${theme.margin[32]}`};
  min-height: 30vh;
  max-height: 70vh;
  overflow-y: scroll;
`;

const CancelButton = styled(SecondaryButton)`
  margin: 0 ${({ theme }) => `${theme.margin[8]}`};
`;

const ActionButton = styled(PrimaryButton)`
  margin-right: 4px;
  margin-left: 4px;
`;

const StyledTextInput = styled(TextInput)`
  width: calc(60vw - ${({ theme }) => `${theme.margin[112]}`});
`;

const DropDownDiv = styled.div`
  padding: 0 ${({ theme }) => `${theme.margin[16]}`}
    ${({ theme }) => `${theme.margin[16]}`};
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ProjectsDiv = styled.div`
  padding: 0 ${({ theme }) => `${theme.margin[16]}`}
    ${({ theme }) => `${theme.margin[48]}`};
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledLabel = styled.label`
  padding: ${({ theme }) =>
      `${theme.margin[16]} ${theme.margin[16]} ${theme.margin[8]}`}
    0;
  font-weight: bold;
`;

const additionalContainerStyling: React.CSSProperties = {
  width: '100%',
  height: defaultTheme.margin[36],
  margin: `${defaultTheme.margin[4]} 0 ${defaultTheme.margin[4]} ${defaultTheme.margin[8]}`,
};

const containerStylingForProjectDropdown: React.CSSProperties = {
  width: '28rem',
  height: defaultTheme.margin[36],
  margin: `${defaultTheme.margin[4]} 0 ${defaultTheme.margin[4]} ${defaultTheme.margin[8]}`,
};

const Toolbar = styled.div`
  margin-top: ${(props) => `${props.theme.margin[24]}`};

  width: 100%;

  display: flex;
  align-items: left;
  justify-content: left;
`;

const ProjectRoleDiv = styled.div`
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: 1px solid ${({ theme: { color } }) => color.dropdownBorder};

  padding: ${({ theme }) => `${theme.margin[16]}`} 0;

  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;
