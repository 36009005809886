import React from 'react';
import { Link } from 'react-router-dom';

import { generateUrl, routes } from '../../routes';

import Txt from '../Txt';

interface Props {
  orderId: string;
  orderVisibleCodeName: string;
  projectId: string | null;
  descriptions: string[];
}

const OrderRowOrTargetRowMovedNotification: React.FC<Props> = (props) => {
  const { orderId, orderVisibleCodeName, projectId } = props;

  if (!projectId) {
    return null;
  }

  const textId = 'order.editMode.moveModal.notification';

  return (
    <>
      <Txt
        id={textId}
        values={{ descriptions: props.descriptions.join(', ') }}
      />{' '}
      <Link
        to={generateUrl({
          route: routes.ORDER,
          orderId,
          projectId,
        })}
      >
        {orderVisibleCodeName}
      </Link>
    </>
  );
};

export default OrderRowOrTargetRowMovedNotification;
