import {
  APIOrder,
  APIOrderPostBody,
  APIUpdatedEntities,
  RawAPIOrder,
  RawAPIUpdatedEntities,
  APIOrderPutBody,
} from '../../../types/api';
import { ID } from '../../../types/general';
import { mapRawOrder, mapRawUpdatedEntities } from '../../../types/mappers';

import {
  makeAction,
  makeApiActions,
  ExtractActionTypes,
} from '../../../utils/actionCreators';
import {
  GET,
  apiErrorHandlingWithDecode,
  BackendError,
  POST,
  PUT,
  DELETE,
} from '../../../utils/api';
import { flow } from '../../../utils/function';
import * as remoteData from '../../../utils/remoteData';
import { createAsyncThunk, Thunk } from '../../../utils/thunk';

import { getOrderDeleteRequest } from '../../reducers/order/deleteOrder';
import { selectProjectOrders } from '../../reducers/order/order';
import { SortableKey } from '../../reducers/order/sortOrders';

export type OrderAction = ExtractActionTypes<typeof actionCreators>;

const actionCreators = {
  ...makeAction('getOrdersStarted')<{ projectId: string }>(),
  ...makeAction('getOrdersFailure')<{
    projectId: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('getOrdersSuccess')<{
    projectId: string;
    orders: APIOrder[];
  }>(),

  ...makeAction('orderSortToggled')<{
    sortableKey: SortableKey;
  }>(),

  ...makeAction('postOrderStarted')<{ projectId: string }>(),
  ...makeAction('postOrderFailure')<{
    projectId: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('postOrderSuccess')<
    APIUpdatedEntities & {
      projectId: string;
    }
  >(),
  ...makeApiActions('put', 'order')<APIUpdatedEntities>(),
  ...makeAction('deleteOrderStarted')<{
    requestId: string;
  }>(),
  ...makeAction('deleteOrderFailure')<{
    requestId: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('deleteOrderSuccess')<
    APIUpdatedEntities & { requestId: string }
  >(),
};
export const {
  getOrdersStarted,
  getOrdersFailure,
  getOrdersSuccess,

  postOrderStarted,
  postOrderFailure,
  postOrderSuccess,

  putOrderStarted,
  putOrderFailure,
  putOrderSuccess,

  deleteOrderStarted,
  deleteOrderFailure,
  deleteOrderSuccess,

  orderSortToggled,
} = actionCreators;

export const getProjectOrders = (projectId: ID) =>
  GET<RawAPIOrder[]>(`v1/projects/${projectId}/orders`).then((orders) =>
    orders.map(mapRawOrder)
  );

export const fetchOrdersForProject = (projectId: ID) =>
  createAsyncThunk(getProjectOrders, {
    args: [projectId],
    isPending: flow(selectProjectOrders(projectId), remoteData.isLoading),
    initialAction: getOrdersStarted({ projectId }),
    failureActionCreator: (error) =>
      getOrdersFailure({
        projectId,
        error: apiErrorHandlingWithDecode(error),
      }),
    successActionCreator: (orders) => getOrdersSuccess({ projectId, orders }),
  });

const postOrder = (order: APIOrderPostBody) =>
  POST<RawAPIUpdatedEntities>('v1/orders', order).then(mapRawUpdatedEntities);

export const createOrder =
  (
    projectId: ID,
    order: APIOrderPostBody,
    successCallback?: (updated: APIUpdatedEntities) => void,
    failureCallback?: () => void
  ): Thunk =>
  (dispatch, _) => {
    dispatch(postOrderStarted({ projectId }));
    postOrder(order).then(
      (updatedEntities) => {
        dispatch(postOrderSuccess({ projectId, ...updatedEntities }));

        if (successCallback) {
          successCallback(updatedEntities);
        }
      },
      (error) => {
        dispatch(
          postOrderFailure({
            projectId,
            error: apiErrorHandlingWithDecode(error),
          })
        );

        if (failureCallback) {
          failureCallback();
        }
      }
    );
  };

export const editOrder =
  (orderId: ID, order: APIOrderPutBody, successCallback?: () => void): Thunk =>
  (dispatch, _) => {
    dispatch(putOrderStarted());

    PUT<RawAPIUpdatedEntities>(`v1/orders/${orderId}/`, order)
      .then(mapRawUpdatedEntities)
      .then(
        (updatedEntities) => {
          dispatch(putOrderSuccess(updatedEntities));

          if (successCallback) {
            successCallback();
          }
        },
        (error) => {
          dispatch(putOrderFailure(apiErrorHandlingWithDecode(error)));
        }
      );
  };

type DeleteOrderRequest = {
  requestId: string;
  orderId: string;
};

const deleteOrder = async (orderId: string): Promise<APIUpdatedEntities> => {
  const response = await DELETE<RawAPIUpdatedEntities>(`v1/orders/${orderId}`);

  return mapRawUpdatedEntities(response);
};

export const requestDeleteOrder =
  ({ requestId, orderId }: DeleteOrderRequest): Thunk =>
  (dispatch) => {
    dispatch(
      createAsyncThunk(deleteOrder, {
        args: [orderId],
        isPending: flow(getOrderDeleteRequest(requestId), remoteData.isLoading),
        initialAction: deleteOrderStarted({ requestId }),
        successActionCreator: (updatedEntities) =>
          deleteOrderSuccess({ ...updatedEntities, requestId }),
        failureActionCreator: (error) =>
          deleteOrderFailure({
            requestId,
            error: apiErrorHandlingWithDecode(error),
          }),
      })
    );
  };
