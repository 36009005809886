import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';

import { AppState } from '../../../store/reducers';
import { getOrdersByProcurementAreaId } from '../../../store/reducers/order/order';
import { getProcumentAreaById } from '../../../store/reducers/procurementArea';
import { getColumns } from '../../../store/reducers/ui';

import {
  uiStateProcurementAreaOpened,
  uiStateProcurementAreaClosed,
} from '../../../store/actions/ui';

import { ID } from '../../../types/general';

import NewOrderSidebar from './NewOrderSidebar/NewOrderSidebar';
import ProcurementAreaRow from './ProcurementAreaRow';
import ProjectViewOrderRow from './ProjectViewOrderRow';

// This is used to filter out prediction changes which have an
// absolute value that is less than this value.
export const PREDICTION_CHANGE_DISPLAY_TRESHOLD = 1.0;

type Props = {
  procurementAreaId: string;
  orderStatusFilterValues: string[];
};

type StateProps = {
  openProcurementAreas: ID[];
  lastActiveProcurementArea?: ID;
};

const ProcurementArea = ({
  procurementAreaId,
  openProcurementAreas,
  lastActiveProcurementArea,
}: Props & StateProps) => {
  const orders = useSelector(getOrdersByProcurementAreaId(procurementAreaId));

  const dispatch = useDispatch();
  const { projectColumns } = useSelector(getColumns);
  const procurementArea = useSelector(getProcumentAreaById(procurementAreaId));

  const [showOrders, setShowOrders] = useState(
    openProcurementAreas.includes(procurementAreaId)
  );
  const [showCreateOrderSidebar, setShowCreateOrderSidebar] = useState(false);

  const toggleShowOrders = () => {
    if (showOrders) {
      dispatch(uiStateProcurementAreaClosed(procurementAreaId));
    } else {
      dispatch(uiStateProcurementAreaOpened(procurementAreaId));
    }

    setShowOrders((visible) => !visible);
  };

  useEffect(() => {
    setShowOrders(openProcurementAreas.includes(procurementAreaId));
  }, [openProcurementAreas, procurementAreaId]);

  if (!procurementArea) {
    return null;
  }

  return (
    <>
      {showCreateOrderSidebar && (
        <NewOrderSidebar
          onClose={() => setShowCreateOrderSidebar(false)}
          procurementAreaId={procurementArea.id}
        />
      )}

      <tbody>
        <ProcurementAreaRow
          key={`procurement-area-row-${procurementArea.id}`}
          procurementAreaId={procurementArea.id}
          isOpen={showOrders}
          onClick={toggleShowOrders}
          onAddOrder={() => setShowCreateOrderSidebar(true)}
          shouldFocusOnMount={lastActiveProcurementArea === procurementArea.id}
          columns={projectColumns}
        />
        {showOrders
          ? orders.map((order) => (
              <ProjectViewOrderRow
                key={`order-${order.id}`}
                order={order}
                columns={projectColumns}
              />
            ))
          : null}
      </tbody>
    </>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  openProcurementAreas: state.ui.openProcurementAreas,
  lastActiveProcurementArea: state.ui.lastActiveProcurementArea,
});

export default connect(mapStateToProps)(ProcurementArea);
