// Usage: priceFormat.format(number). Formats e.g. 12345 as 12 345,00€
export const priceFormat = Intl.NumberFormat('fi-FI', {
  style: 'currency',
  currency: 'EUR',
});

// Same as above. Shows 4 decimals if needed, used mainly for unitPrices which
// need more precision.
export const precisePriceFormat = Intl.NumberFormat('fi-FI', {
  style: 'currency',
  currency: 'EUR',
  maximumFractionDigits: 4,
});

export const dateTimeFormat = new Intl.DateTimeFormat('fi-FI', {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
});

export const compactDateFormat = new Intl.DateTimeFormat('fi-FI', {
  year: '2-digit',
  month: '2-digit',
  day: '2-digit',
});

export const dateFormat = new Intl.DateTimeFormat('fi-FI', {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
});

export const dateMonthAndYearFormat = new Intl.DateTimeFormat('fi-FI', {
  year: 'numeric',
  month: '2-digit',
});

export const longDateMonthAndYearFormat = new Intl.DateTimeFormat('fi-FI', {
  year: 'numeric',
  month: 'long',
});

export const nullifyIfEmpty = (str: string): string | null =>
  str === '' ? null : str;
