import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';

import {
  makeApiActions,
  ExtractActionTypes,
} from '../../../utils/actionCreators';
import { GET, apiErrorHandlingWithDecode } from '../../../utils/api';
import { dateString } from '../../../utils/decoders';
import { createAsyncThunk } from '../../../utils/thunk';

export type OrderOptionsAction = ExtractActionTypes<typeof actionCreators>;

const actionCreators = {
  ...makeApiActions('get', 'orderDropdownOptions')<OrderOptions>(),
};
export const {
  getOrderDropdownOptionsStarted,
  getOrderDropdownOptionsFailure,
  getOrderDropdownOptionsSuccess,
} = actionCreators;

const commonProps = t.type({
  id: t.string,
  name: t.string,
  createdAt: dateString,
  updatedAt: dateString,
});

const orderOptionType = t.intersection([
  commonProps,
  t.type({
    externalCode: t.string,
  }),
]);

const supplierType = t.intersection([
  commonProps,
  t.type({
    businessId: t.string,
    defaultAccountCode: t.union([t.string, t.null]),
    defaultVatCode: t.union([t.string, t.null]),
    defaultCostType: t.union([t.string, t.null]),
  }),
]);

export const orderStatusIds = ['1', '2', '3'] as const;

function keyObject<T extends readonly string[]>(
  arr: T
): { [K in T[number]]: null } {
  return Object.fromEntries(arr.map((v) => [v, null])) as any;
}

const orderStatusIdType = t.keyof(keyObject(orderStatusIds));

const orderStatusType = t.type({
  id: orderStatusIdType,
  name: t.string,
  englishName: t.string,
  description: t.string,
});

const disallowedAccountingCombinationType = t.type({
  id: t.string,
  description: t.union([t.string, t.null]),
  accountId: t.union([t.string, t.null]),
  vatCodeId: t.union([t.string, t.null]),
  costTypeId: t.union([t.string, t.null]),
  manualEntryDimensionItemId: t.union([t.string, t.null]),
  projectId: t.union([t.string, t.null]),
  orderId: t.union([t.string, t.null]),
  workPackageId: t.union([t.string, t.null]),
  isDeleted: t.boolean,
  createdAt: dateString,
  updatedAt: dateString,
});

export type OrderOption = t.TypeOf<typeof orderOptionType>;
export type Supplier = t.TypeOf<typeof supplierType>;
export type OrderStatus = t.TypeOf<typeof orderStatusType>;
export type DisallowedAccountingCombination = t.TypeOf<
  typeof disallowedAccountingCombinationType
>;

export type OrderOptions = {
  accounts: OrderOption[];
  costTypes: OrderOption[];
  vatCodes: OrderOption[];
  suppliers: Supplier[];
  statuses: OrderStatus[];
  disallowedCombinations: DisallowedAccountingCombination[];
};

const getOrderOptions = async (url: string) =>
  tPromise.decode(t.array(orderOptionType), await GET(url));

const getSuppliers = async () =>
  tPromise.decode(t.array(supplierType), await GET('v1/suppliers'));

const getStatuses = async () =>
  tPromise.decode(
    t.record(t.string, orderStatusType),
    await GET('v1/statusflows/order')
  );

const getDisallowedCombinations = async () =>
  tPromise.decode(
    t.array(disallowedAccountingCombinationType),
    await GET('v1/accounts/disallowed-combinations')
  );

async function fetchDropdownOptions(): Promise<OrderOptions> {
  const [
    accounts,
    costTypes,
    statusValues,
    vatCodes,
    suppliers,
    disallowedCombinations,
  ] = await Promise.all([
    getOrderOptions('v1/accounts'),
    getOrderOptions('v1/cost-types'),
    getStatuses(),
    getOrderOptions('v1/vat-codes'),
    getSuppliers(),
    getDisallowedCombinations(),
  ]);

  return {
    accounts,
    costTypes,
    vatCodes,
    suppliers,
    statuses: Object.values(statusValues),
    disallowedCombinations,
  };
}

export const getDropDowns = createAsyncThunk(fetchDropdownOptions, {
  args: [],
  isPending: ({ orders: { options } }) => options.kind === 'Loading',
  initialAction: getOrderDropdownOptionsStarted(),
  successActionCreator: (response) => getOrderDropdownOptionsSuccess(response),
  failureActionCreator: (error) =>
    getOrderDropdownOptionsFailure(apiErrorHandlingWithDecode(error)),
});
