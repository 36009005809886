import React from 'react';

import styled from 'styled-components';

import { APIProjectUser } from '@customtypes/api';

import { ListItem } from '../../../../../../components/SelectOptions/Item';
import Txt from '../../../../../../components/Txt';

import { TextId } from '../../../../../../localization';

interface ProjectUsersProps {
  users: APIProjectUser[];
  assigneeUserIds: string[];
  assignedUserIds: string[];
  setAssigneeUsers: (users: string[]) => void;
}

const ProjectUsers = ({
  users,
  assigneeUserIds,
  setAssigneeUsers,
  assignedUserIds,
}: ProjectUsersProps) => {
  const selectReviewers: TextId =
    'order.receiveMode.invoice.delegate.modal.selectReviewers';

  const createItemFromProjectUser = (user: APIProjectUser) => ({
    id: user.id,
    selected:
      assigneeUserIds.includes(user.id) || assignedUserIds.includes(user.id),
    name: user.name as TextId,
    disabled: assignedUserIds.includes(user.id),
  });

  const onChangeHandler = (selectOption: any) => {
    const alreadySelected = assigneeUserIds.includes(selectOption.id);

    if (alreadySelected) {
      setAssigneeUsers(
        assigneeUserIds.filter((item) => item !== selectOption.id)
      );
    } else {
      setAssigneeUsers([...assigneeUserIds, selectOption.id]);
    }
  };

  return (
    <UserListContainer>
      <ListHeader>
        <Txt id={selectReviewers} />
      </ListHeader>
      {users.map((user: APIProjectUser) => {
        return ListItem(createItemFromProjectUser(user), onChangeHandler);
      })}
    </UserListContainer>
  );
};

const UserListContainer = styled.div`
  position: relative;

  margin-bottom: 0.75rem;

  padding-bottom: ${(props) => `${props.theme.margin[32]}`};

  height: 15rem;

  font-weight: bold;

  overflow-y: scroll;
`;

const ListHeader = styled.header`
  position: sticky;
  top: 0;

  padding-bottom: ${(props) => `${props.theme.margin[10]}`};

  background-color: ${(props) => `${props.theme.color.white}`};

  z-index: 1;
`;

export default ProjectUsers;
