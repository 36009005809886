import React from 'react';
import { useHistory } from 'react-router-dom';

import { NotificationFeedItem } from '../../../types/general';

import { useElapsedTime } from '../../../hooks/ui';
import useTxt from '../../../hooks/useTxt';

import { IconChat } from '../../../assets/svg';

import { generateUrl, routes } from '../../../routes';

import {
  NotificationContainer,
  StyledNotificationContent,
  StyledHeader,
  StyledLinkArea,
  NotificationTimeStamp,
  StyledRouterLink,
  Mentions,
  MentionText,
  MentionName,
} from './Components';

type ChatMentionProps = {
  notification: NotificationFeedItem;
  onCloseSidebar: () => void;
  markAsRead: (item: NotificationFeedItem) => void;
  isNew?: boolean;
};

const NotificationChatMessages = ({
  notification,
  onCloseSidebar,
  markAsRead,
  isNew,
}: ChatMentionProps) => {
  const history = useHistory();
  const NewTxt = useTxt('navigation.notifications.new');

  const { orderChatMessages, project, order } = notification;
  const latestMention = orderChatMessages[orderChatMessages.length - 1];
  const elapsedTimeText = useElapsedTime(notification.updatedAt);

  const defaultDescription = useTxt(
    'navigation.notifications.defaultDescription'
  );

  const newMessageDescription = useTxt(
    'navigation.notifications.description.newMessage'
  );

  if (!project || !order || !latestMention) {
    return null;
  }

  const selectDescription = (notificationType: string) => {
    switch (notificationType) {
      case '4':
        return newMessageDescription;

      default:
        return defaultDescription;
    }
  };

  const handleNotificationClick = (): void => {
    if (!notification.isRead) {
      markAsRead(notification);
    }

    if (!(project?.id && order?.id)) {
      return;
    }

    history.push(
      generateUrl({
        route: routes.ORDER,
        projectId: project.id,
        orderId: order.id,
        viewMode: 'comments',
      })
    );

    onCloseSidebar();
  };

  return (
    <NotificationContainer onClick={handleNotificationClick}>
      <StyledNotificationContent>
        <StyledHeader>
          {`${isNew ? NewTxt : ''} ${selectDescription(
            notification.notificationType
          )}`}
        </StyledHeader>
        {/* new indicator */}
        <NotificationTimeStamp>
          {isNew
            ? elapsedTimeText
            : notification.updatedAt.toLocaleDateString()}
        </NotificationTimeStamp>

        <StyledLinkArea>
          {/* project info */}
          {notification.project ? notification.project.label : null}

          {/* notifications */}
          {notification.project && notification.order ? (
            <StyledRouterLink
              key={`order-link-${notification.order.id}`}
              to={generateUrl({
                route: routes.ORDER,
                projectId: notification.project.id,
                orderId: notification.order.id,
                viewMode: 'receive',
                subViewMode: 'invoices',
              })}
            >
              {notification.order.label}
            </StyledRouterLink>
          ) : null}
          <Mentions>
            <StyledRouterLink
              key={latestMention.id}
              to={generateUrl({
                route: routes.ORDER,
                projectId: project.id,
                orderId: order.id,
                viewMode: 'comments',
              })}
            >
              <MentionName>
                <IconChat fill="white" />
                {latestMention.senderName}
              </MentionName>
              <MentionText>{latestMention.text}</MentionText>
            </StyledRouterLink>
          </Mentions>
        </StyledLinkArea>
      </StyledNotificationContent>
    </NotificationContainer>
  );
};

export default NotificationChatMessages;
