import Big from 'big.js';
import * as t from 'io-ts';
import { v4 } from 'uuid';

import { APIOrder, APITopic, APIWorkPackage } from '@customtypes/api';

import { TargetRow, TargetRowHierarchyEntry } from '../../../../store/actions';

import { LocalizationShape } from '../../../../hooks/useLocalization';

import {
  bigNumber,
  bigString,
  booleanString,
  decode,
  DecodeResult,
  maxLengthString,
} from '../../../../utils/decoders';
import { getDuplicates, isNotNull } from '../../../../utils/general';
import { collectAllChildIds } from '../../../../utils/tableUtils';

import {
  fillOrder,
  fillWorkPackage,
  fillTopic,
} from '../../../../__test-utils__/fixtures';

export const dataFields = {
  rowCode: {
    translation: 'target.targetImportModal.csv.headers.rowCode',
    optional: false,
  },
  parentCode: {
    translation: 'target.targetImportModal.csv.headers.parentCode',
    optional: false,
  },
  referenceNo: {
    translation: 'target.targetImportModal.csv.headers.referenceNo',
    optional: false,
  },
  description: {
    translation: 'target.table.header.description',
    optional: false,
  },
  quantity: { translation: 'target.table.header.quantity', optional: false },
  unit: { translation: 'target.table.header.unit', optional: false },
  unitPrice: { translation: 'target.table.header.unitPrice', optional: false },
  target: { translation: 'target.table.header.target', optional: false },
  procurementCode: {
    translation: 'target.targetImportModal.csv.headers.procurementCode',
    optional: false,
  },
  procurementName: {
    translation: 'target.targetImportModal.csv.headers.procurementName',
    optional: false,
  },
  workSectionCode: {
    translation: 'target.targetImportModal.csv.headers.workSectionCode',
    optional: false,
  },
  workSectionName: {
    translation: 'target.targetImportModal.csv.headers.workSectionName',
    optional: false,
  },
  isDeleted: {
    translation: 'target.targetImportModal.csv.headers.isDeleted',
    optional: true,
  },
} as const;

export type TargetImportData = {
  rowCode: string;
  parentCode: string | null;
  referenceNo: string | null;
  description: string | null;
  quantity: Big | number | null;
  unit: string | null;
  unitPrice: Big | number | null;
  target: Big | number | null;
  procurementCode: string | null;
  procurementName: string | null;
  workSectionCode: string | null;
  workSectionName: string | null;
  isDeleted?: boolean;
};

const targetImportDataType = t.exact(
  t.type({
    rowCode: maxLengthString(255),
    parentCode: t.union([maxLengthString(255), t.null]),
    referenceNo: t.union([maxLengthString(255), t.null]),
    description: t.union([maxLengthString(255), t.null]),
    quantity: t.union([bigString, bigNumber, t.null]),
    unit: t.union([maxLengthString(255), t.null]),
    unitPrice: t.union([bigString, bigNumber, t.null]),
    target: t.union([bigString, bigNumber, t.null]),
    procurementCode: t.union([maxLengthString(20), t.null]),
    procurementName: t.union([maxLengthString(255), t.null]),
    workSectionCode: t.union([maxLengthString(20), t.null]),
    workSectionName: t.union([maxLengthString(255), t.null]),
    isDeleted: t.union([t.undefined, t.null, booleanString]),
  })
);

export type ValidatedTargetImportData = {
  rowCode: DecodeResult<string>;
  parentCode: DecodeResult<string | null>;
  referenceNo: DecodeResult<string | null>;
  description: DecodeResult<string | null>;
  quantity: DecodeResult<Big | null>;
  unit: DecodeResult<string | null>;
  unitPrice: DecodeResult<Big | null>;
  target: DecodeResult<Big | null>;
  procurementCode: DecodeResult<string | null>;
  procurementName: DecodeResult<string | null>;
  workSectionCode: DecodeResult<string | null>;
  workSectionName: DecodeResult<string | null>;
  isDeleted?: DecodeResult<boolean>;
};

type UpdateInformation<T> =
  | {
      oldData: T;
      newData: T;
    }
  | undefined;

export type UpdateInformationTargetImportData = {
  parentCode: UpdateInformation<string | null | undefined>;
  referenceNo: UpdateInformation<string | null | undefined>;
  description: UpdateInformation<string | null | undefined>;
  quantity: UpdateInformation<Big | null | undefined>;
  unit: UpdateInformation<string | null | undefined>;
  unitPrice: UpdateInformation<Big | null | undefined>;
  target: UpdateInformation<Big | null | undefined>;
  procurementCode: UpdateInformation<string | null | undefined>;
  procurementName: UpdateInformation<string | null | undefined>;
  workSectionCode: UpdateInformation<string | null | undefined>;
  workSectionName: UpdateInformation<string | null | undefined>;
  isDeleted?: UpdateInformation<boolean | null | undefined>;
  changeType: 'update' | 'create' | 'delete' | 'noChange';
};

function compareUpdateInformationValues<T>(
  newValue: T,
  oldValue: T
): UpdateInformation<T> {
  if (newValue instanceof Big && oldValue instanceof Big) {
    if (newValue.eq(oldValue)) {
      return undefined;
    }

    return {
      oldData: oldValue,
      newData: newValue,
    };
  }

  if (newValue === oldValue) {
    return undefined;
  }

  if (!newValue && !oldValue) {
    return undefined;
  }

  return {
    oldData: oldValue,
    newData: newValue,
  };
}

export const processTargetRowUpdateInformation = (
  targetRow: TargetRow,
  existingTargetRow: TargetRow | undefined,
  order: APIOrder | undefined,
  existingTargetRowOrder: APIOrder | undefined,
  workPackage: APIWorkPackage | undefined,
  existingTargetRowWorkPackage: APIWorkPackage | undefined
) => {
  const updateInformation = {
    parentCode: compareUpdateInformationValues(
      targetRow.externalHierarchyEntryId,
      existingTargetRow?.externalHierarchyEntryId
    ),
    referenceNo: compareUpdateInformationValues(
      targetRow.referenceNumber,
      existingTargetRow?.referenceNumber
    ),
    description: compareUpdateInformationValues(
      targetRow.description,
      existingTargetRow?.description
    ),
    quantity: compareUpdateInformationValues(
      targetRow.quantity,
      existingTargetRow?.quantity
    ),
    unit: compareUpdateInformationValues(
      targetRow.unit,
      existingTargetRow?.unit
    ),
    unitPrice: compareUpdateInformationValues(
      targetRow.unitPrice,
      existingTargetRow?.unitPrice
    ),
    target: compareUpdateInformationValues(
      targetRow.totalPrice,
      existingTargetRow?.totalPrice
    ),
    procurementCode: compareUpdateInformationValues(
      order?.visibleCode,
      existingTargetRowOrder?.visibleCode
    ),
    procurementName: compareUpdateInformationValues(
      order?.name,
      existingTargetRowOrder?.name
    ),
    workSectionCode: compareUpdateInformationValues(
      workPackage?.code,
      existingTargetRowWorkPackage?.code
    ),
    workSectionName: compareUpdateInformationValues(
      workPackage?.name,
      existingTargetRowWorkPackage?.name
    ),
    isDeleted: compareUpdateInformationValues(
      targetRow.isDeleted,
      existingTargetRow?.isDeleted
    ),
  };

  const detectChangeType = (): 'update' | 'create' | 'delete' | 'noChange' => {
    if (!existingTargetRow) {
      return 'create';
    }

    // count only as deleted if row existed earlier
    if (targetRow.isDeleted && existingTargetRow) {
      return 'delete';
    }

    if (Object.values(updateInformation).some((value) => value !== undefined)) {
      return 'update';
    }

    return 'noChange';
  };

  return { ...updateInformation, changeType: detectChangeType() };
};

export const processHierarchyEntryUpdateInformation = (
  entry: TargetRowHierarchyEntry,
  existingEntry: TargetRowHierarchyEntry | undefined
) => {
  const updateInformation = {
    parentCode: compareUpdateInformationValues(
      entry.externalParentId,
      existingEntry?.externalParentId
    ),
    referenceNo: compareUpdateInformationValues(
      entry.referenceNumber,
      existingEntry?.referenceNumber
    ),
    description: compareUpdateInformationValues(
      entry.description,
      existingEntry?.description
    ),
    quantity: compareUpdateInformationValues(
      entry.quantity,
      existingEntry?.quantity
    ),
    unit: compareUpdateInformationValues(entry.unit, existingEntry?.unit),
    unitPrice: undefined,
    target: undefined,
    isDeleted: compareUpdateInformationValues(
      entry.isDeleted,
      existingEntry?.isDeleted
    ),
    procurementCode: undefined,
    procurementName: undefined,
    workSectionCode: undefined,
    workSectionName: undefined,
  };

  const detectChangeType = (): 'update' | 'create' | 'delete' | 'noChange' => {
    if (!existingEntry) {
      return 'create';
    }

    if (entry.isDeleted) {
      return 'delete';
    }

    if (Object.values(updateInformation).some((value) => value !== undefined)) {
      return 'update';
    }

    return 'noChange';
  };

  return { ...updateInformation, changeType: detectChangeType() };
};

export const csvExampleData = [
  {
    rowCode: 'RC001',
    parentCode: null,
    referenceNo: '1.',
    description: 'Example hierarchy level 1',
    quantity: 1000,
    unit: 'kg',
    unitPrice: null,
    target: null,
    procurementCode: null,
    procurementName: null,
    workSectionCode: null,
    workSectionName: null,
  },
  {
    rowCode: 'RC002',
    parentCode: null,
    referenceNo: '2.',
    description: 'Example hierarchy level 2',
    quantity: 2000,
    unit: 'pc',
    unitPrice: null,
    target: null,
    procurementCode: null,
    procurementName: null,
    workSectionCode: null,
    workSectionName: null,
  },
  {
    rowCode: 'RC003',
    parentCode: 'RC001',
    referenceNo: '1.1.',
    description: 'Example hierarchy level 3, child of RC001',
    quantity: 150,
    unit: 'pc',
    unitPrice: null,
    target: null,
    procurementCode: null,
    procurementName: null,
    workSectionCode: null,
    workSectionName: null,
  },
  {
    rowCode: 'RC004',
    parentCode: 'RC002',
    referenceNo: '2.1.',
    description: 'Example hierarchy level 4, child of RC002',
    quantity: 120,
    unit: 'kg',
    unitPrice: null,
    target: null,
    procurementCode: null,
    procurementName: null,
    workSectionCode: null,
    workSectionName: null,
  },
  {
    rowCode: 'RC005',
    parentCode: 'RC001',
    referenceNo: '1.2.',
    description: 'Example hierarchy level 5, child of RC001',
    quantity: 180,
    unit: 'kg',
    unitPrice: null,
    target: null,
    procurementCode: null,
    procurementName: null,
    workSectionCode: null,
    workSectionName: null,
  },
  {
    rowCode: 'RC006',
    parentCode: 'RC002',
    referenceNo: '2.2.',
    description: 'Example hierarchy level 6, child of RC002',
    quantity: 130.3,
    unit: 'kg',
    unitPrice: null,
    target: null,
    procurementCode: null,
    procurementName: null,
    workSectionCode: null,
    workSectionName: null,
  },
  {
    rowCode: 'RC007',
    parentCode: 'RC003',
    referenceNo: '1.1.1.',
    description: 'Example hierarchy level 7, child of RC003',
    quantity: 110.1234,
    unit: 'kg',
    unitPrice: null,
    target: null,
    procurementCode: null,
    procurementName: null,
    workSectionCode: null,
    workSectionName: null,
  },
  {
    rowCode: 'RC008',
    parentCode: 'RC003',
    referenceNo: '1.1.2.',
    description: 'Example hierarchy level 8, child of RC003',
    quantity: 140.5,
    unit: 'kg',
    unitPrice: null,
    target: null,
    procurementCode: null,
    procurementName: null,
    workSectionCode: null,
    workSectionName: null,
  },
  {
    rowCode: 'RC009',
    parentCode: 'RC007',
    referenceNo: '1.1.1.1000',
    description: 'Example target row 9, child of RC007',
    quantity: 140,
    unit: 'kg',
    unitPrice: 10.5,
    target: 1470,
    procurementCode: 'PROC09',
    procurementName: 'Procurement Example 9',
    workSectionCode: 'WS009',
    workSectionName: 'Work Section Example 9',
  },
  {
    rowCode: 'RC010',
    parentCode: 'RC007',
    referenceNo: '1.1.1.1.',
    description: 'Example hierarchy level 10, child of RC007',
    quantity: 140,
    unit: 'kg',
    unitPrice: null,
    target: null,
    procurementCode: null,
    procurementName: null,
    workSectionCode: null,
    workSectionName: null,
  },
  {
    rowCode: 'RC011',
    parentCode: 'RC010',
    referenceNo: '1.1.1.1.1234',
    description: 'Example target row 11, child of RC010',
    quantity: 8,
    unit: 'kg',
    unitPrice: 999.999,
    target: 7999.992,
    procurementCode: 'PROC09',
    procurementName: 'Procurement Example 9',
    workSectionCode: 'WS009',
    workSectionName: 'Work Section Example 9',
  },
  {
    rowCode: 'RC012',
    parentCode: 'RC008',
    referenceNo: '1.1.2.4000',
    description: 'Example target row 12, child of RC008',
    quantity: 1,
    unit: 'kg',
    unitPrice: 1000,
    target: 1000,
    procurementCode: 'PROC10',
    procurementName: 'Procurement Example 10',
    workSectionCode: 'WS009',
    workSectionName: 'Work Section Example 9',
  },
  {
    rowCode: 'RC013',
    parentCode: 'RC006',
    referenceNo: '2.2.1.',
    description: 'Example hierarchy level 13, child of RC006',
    quantity: 1,
    unit: 'kg',
    unitPrice: null,
    target: null,
    procurementCode: null,
    procurementName: null,
    workSectionCode: null,
    workSectionName: null,
  },
  {
    rowCode: 'RC014',
    parentCode: 'RC013',
    referenceNo: '2.2.1.1234',
    description: 'Example target row 14, child of RC013',
    quantity: 10,
    unit: 'kg',
    unitPrice: 1000,
    target: 1000,
    procurementCode: 'PROC01',
    procurementName: 'Procurement Example 1',
    workSectionCode: 'WS001',
    workSectionName: 'Work Section Example 1',
  },
];

// Usage: decimalFormat.format(number). Formats e.g. 12345 as 12345,00
const decimalFormat = (locale: string = 'fi-FI', value: number | bigint) => {
  // replace minus sign used by fi-fi with hyphen minus
  return Intl.NumberFormat(locale, {
    style: 'decimal',
    maximumFractionDigits: 4,
    useGrouping: false,
  })
    .format(value)
    .replace(/[\u2212-]/g, '\u002D');
};

export const convertToCSV = (
  headers: Record<keyof TargetImportData, string>,
  data: TargetImportData[],
  locale?: string
) => {
  const csvDelimiter = locale === 'fi-FI' ? ';' : ',';
  const localizedHeaders = Object.values(headers);
  const header = Object.keys(headers) as (keyof TargetImportData)[];

  const addChildrenAfterParents = (
    parentCode: string | null,
    allData: TargetImportData[],
    result: TargetImportData[]
  ) => {
    allData.forEach((row) => {
      if (row.parentCode === parentCode) {
        result.push(row);
        addChildrenAfterParents(row.rowCode, allData, result);
      }
    });
  };

  const dataChildrenAfterParents: TargetImportData[] = [];
  addChildrenAfterParents(null, data, dataChildrenAfterParents);

  const csv = [
    localizedHeaders.join(csvDelimiter), // header row first
    ...dataChildrenAfterParents.map((row) =>
      header
        .map((fieldName) => {
          const fieldData = row[fieldName];

          if (typeof fieldData === 'number') {
            return decimalFormat(locale, Number(fieldData));
          }

          if (typeof fieldData === 'string') {
            return `"${fieldData.replace(/"/g, '""')}"`;
          }

          if (fieldData instanceof Big) {
            return fieldData.toFixed(4);
          }

          if (typeof fieldData === 'boolean') {
            return fieldData ? 'true' : 'false';
          }

          if (!fieldData) {
            return '';
          }

          return fieldData;
        })
        .join(csvDelimiter)
    ),
  ].join('\r\n');

  // add \ufeff to force utf-8 -> utf-8 with bom (MS excel csv trick)
  return '\ufeff'.concat(csv);
};

export const downloadCSV = (
  headers: Record<keyof TargetImportData, string>,
  data: TargetImportData[] = csvExampleData,
  locale?: string // es6 doesn't support Intl.LocalesArgument, should upgrade typescript + tsconfig.target
) => {
  const csvData = new Blob([convertToCSV(headers, data, locale)], {
    type: 'text/csv',
  });

  const csvURL = URL.createObjectURL(csvData);
  const link = document.createElement('a');
  link.href = csvURL;
  link.download = `target_example.csv`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const automaticPreMapping = (
  parsedData: unknown[],
  localization: LocalizationShape
): Partial<Record<keyof TargetImportData, string>> | undefined => {
  if (parsedData.length === 0) {
    return undefined;
  }

  const keys = Object.keys(dataFields) as (keyof TargetImportData)[];

  const distinctDataKeys = [
    ...new Set(
      parsedData
        .map((data) =>
          typeof data === 'object' && data !== null ? Object.keys(data) : []
        )
        .flat()
    ),
  ];

  const mapping: Partial<Record<keyof TargetImportData, string>> = {};

  const translatedKeys = keys.map((key) => ({
    key,
    translation: localization.formatMessage({
      id: dataFields[key].translation,
    }),
  }));
  // first based on headers
  // then based on translations
  translatedKeys.forEach((dataField) => {
    const matchingKey = distinctDataKeys.find((datakey) =>
      datakey.toLowerCase().includes(dataField.key.toLowerCase())
    );

    if (matchingKey) {
      mapping[dataField.key] = matchingKey;
    }

    const matchingTranslation = distinctDataKeys.find((datakey) =>
      datakey.toLowerCase().includes(dataField.translation.toLowerCase())
    );

    if (matchingTranslation) {
      mapping[dataField.key] = matchingTranslation;
    }
  });

  return mapping;
};

export const hasAllTargetImportDataFields = (
  input: Partial<Record<keyof TargetImportData, string>> | undefined
): boolean => {
  if (!input) {
    return false;
  }

  const keys = Object.keys(dataFields) as (keyof TargetImportData)[];

  const missingProperties = keys
    .filter(
      (key) => input[key] === undefined && dataFields[key].optional === false
    )
    .map((key) => key as keyof TargetImportData);

  return missingProperties.length === 0;
};

export const processCsvData = (
  data: unknown[],
  mapping: Partial<Record<keyof TargetImportData, string>> | undefined
): ValidatedTargetImportData[] => {
  if (!mapping) {
    return [];
  }

  return data.map((row: any) => {
    const newRow: ValidatedTargetImportData = {
      rowCode: { kind: 'Invalid' },
      parentCode: { kind: 'Invalid' },
      referenceNo: { kind: 'Invalid' },
      description: { kind: 'Invalid' },
      quantity: { kind: 'Invalid' },
      unit: { kind: 'Invalid' },
      unitPrice: { kind: 'Invalid' },
      target: { kind: 'Invalid' },
      procurementCode: { kind: 'Invalid' },
      procurementName: { kind: 'Invalid' },
      workSectionCode: { kind: 'Invalid' },
      workSectionName: { kind: 'Invalid' },
      isDeleted: { kind: 'Decoded', value: false },
    };

    const keys = Object.keys(dataFields) as (keyof TargetImportData)[];

    keys.forEach((key) => {
      const mappingValue = mapping[key];

      if (mappingValue) {
        const value = row[mappingValue];

        const unionType = t.union([t.string, t.null, bigString, t.boolean]);

        const codec = targetImportDataType.type.props[
          key
        ] as unknown as typeof unionType;

        const decodedValue = decode(codec, value) as any;

        newRow[key] = decodedValue;
      }
    });

    return newRow;
  });
};

const getValueFromDecodeResult = <T>(result: DecodeResult<T>): T | null => {
  if (result.kind === 'Decoded') {
    return result.value;
  }

  return null;
};

export type ExtendedTargetRowHierarchyEntry = TargetRowHierarchyEntry & {
  externalId: string;
  validationInformation: ValidatedTargetImportData;
  invalid: boolean;
};

export type ExtendedTargetRow = TargetRow & {
  externalCode: string;
  workSectionCode: string | null;
  procurementCode: string | null;
  validationInformation: ValidatedTargetImportData;
  invalid: boolean;
};

export type ExtendedOrder = APIOrder & {
  validationInformation: DecodeResult<string | null>;
  invalid: boolean;
};

export type ExtendedTopic = APITopic;

export type ExtendedWorkPackage = APIWorkPackage & {
  validationInformation: DecodeResult<string | null>;
  invalid: boolean;
};

export const isExtendedTargetRow = (
  row: TargetRow | ExtendedTargetRow
): row is ExtendedTargetRow => {
  return 'validationInformation' in row;
};

export const isExtendedTargetRowHierarchyEntry = (
  row: TargetRowHierarchyEntry | ExtendedTargetRowHierarchyEntry
): row is ExtendedTargetRowHierarchyEntry => {
  return 'validationInformation' in row;
};

export const isExtendedOrder = (
  row: APIOrder | ExtendedOrder
): row is ExtendedOrder => {
  return 'validationInformation' in row;
};

export const isExtendedWorkPackage = (
  row: APIWorkPackage | ExtendedWorkPackage
): row is ExtendedWorkPackage => {
  return 'validationInformation' in row;
};

export const validatedDataToTempTargetRows = (
  projectId: string,
  data: ValidatedTargetImportData[],
  unSpecifiedWorkPackage: APIWorkPackage | undefined,
  unSpecifiedOrder: APIOrder | undefined
): ExtendedTargetRow[] => {
  const allRowCodes = data
    .map((row) => getValueFromDecodeResult(row.rowCode))
    .filter(isNotNull);

  const duplicateCodes = getDuplicates(allRowCodes);

  const allDeletedRowCodes = data
    .filter(
      (row) => row.isDeleted && getValueFromDecodeResult(row.isDeleted) === true
    )
    .map((row) => getValueFromDecodeResult(row.rowCode))
    .filter(isNotNull);

  const allParentCodes = data
    .map((row) => getValueFromDecodeResult(row.parentCode))
    .filter(isNotNull);

  const allChildRows = data.filter((row) => {
    const value = getValueFromDecodeResult(row.rowCode);
    const unitPrice = getValueFromDecodeResult(row.unitPrice);
    const target = getValueFromDecodeResult(row.target);

    if (value && allParentCodes.includes(value)) {
      return false;
    }

    // if no unitPrice is set, it's a hierarchy entry
    if (!unitPrice && (!target || target.eq(0))) {
      return false;
    }

    return true;
  });

  const now = new Date();

  return allChildRows.map((row) => {
    const rowCode = getValueFromDecodeResult(row.rowCode);
    let parentCode = getValueFromDecodeResult(row.parentCode);
    let validationInformation = row;

    if (rowCode === parentCode) {
      parentCode = null;
      validationInformation = {
        ...validationInformation,
        parentCode: { kind: 'Invalid', invalidValue: rowCode },
      };
    }

    const isDeleted = row.isDeleted
      ? getValueFromDecodeResult(row.isDeleted) || false
      : false;

    const validParentCode = parentCode
      ? allRowCodes.includes(parentCode)
      : true;

    if (!validParentCode) {
      validationInformation = {
        ...validationInformation,
        parentCode: { kind: 'Invalid', invalidValue: parentCode },
      };
    }

    // check that parent isn't deleted
    if (!isDeleted && parentCode) {
      const isParentDeleted = allDeletedRowCodes.includes(parentCode);

      if (isParentDeleted) {
        validationInformation = {
          ...validationInformation,
          parentCode: {
            kind: 'Invalid',
            invalidValue: parentCode,
            customMessage: 'validation.parentIsDeleted',
          },
        };
      }
    }

    if (rowCode && duplicateCodes.includes(rowCode)) {
      validationInformation = {
        ...validationInformation,
        rowCode: {
          kind: 'Invalid',
          invalidValue: rowCode,
          customMessage: 'validation.duplicateValue',
        },
      };
    }

    const parentRow = data.find(
      (item) => getValueFromDecodeResult(item.rowCode) === parentCode
    );

    const procurementCode =
      row.procurementCode.kind === 'Decoded'
        ? getValueFromDecodeResult(row.procurementCode) ||
          unSpecifiedOrder?.visibleCode ||
          ''
        : '';

    const workSectionCode =
      row.workSectionCode.kind === 'Decoded'
        ? getValueFromDecodeResult(row.workSectionCode) ||
          unSpecifiedWorkPackage?.code ||
          null
        : null;

    const orderId = () => {
      const ownOrderId = getValueFromDecodeResult(row.procurementCode);

      if (!ownOrderId && parentRow) {
        return getValueFromDecodeResult(parentRow.procurementCode);
      }

      return ownOrderId;
    };

    const topicId = () => {
      const ownTopicId = getValueFromDecodeResult(row.workSectionCode);

      if (!ownTopicId && parentRow) {
        return getValueFromDecodeResult(parentRow.workSectionCode);
      }

      return ownTopicId;
    };

    const invalid = Object.values(validationInformation).some(
      (validationValue) => validationValue && validationValue.kind === 'Invalid'
    );

    return {
      id: rowCode || v4(),
      externalCode: rowCode || v4(),
      externalHierarchyEntryId: validParentCode ? parentCode : null,
      projectId,
      orderId: orderId() ?? procurementCode,
      topicId: topicId() ?? workSectionCode,
      orderRowId: null,
      referenceNumber: getValueFromDecodeResult(row.referenceNo),
      description: getValueFromDecodeResult(row.description),
      quantity: new Big(getValueFromDecodeResult(row.quantity) || 0),
      unit: getValueFromDecodeResult(row.unit),
      isDeleted: isDeleted,
      createdAt: now,
      updatedAt: now,
      unitPrice: new Big(getValueFromDecodeResult(row.unitPrice) || 0),
      isOriginal: true,
      isDisabled: false,
      isSplitFrom: null,
      isDeletable: false,
      isAntiRow: false,
      targetRowHierarchyEntryId: validParentCode ? parentCode : null,
      analysisListItemIds: [],
      totalPrice: new Big(getValueFromDecodeResult(row.quantity) || 0).mul(
        new Big(getValueFromDecodeResult(row.unitPrice) || 0)
      ),
      validationInformation,
      invalid,
      workSectionCode: topicId() ?? workSectionCode,
      procurementCode: orderId() ?? procurementCode,
    };
  });
};

export const validatedDataToTempHierarchyEntries = (
  projectId: string,
  data: ValidatedTargetImportData[],
  targetRows: ExtendedTargetRow[]
): ExtendedTargetRowHierarchyEntry[] => {
  const allRowCodes = data
    .map((row) => getValueFromDecodeResult(row.rowCode))
    .filter(isNotNull);

  const allDeletedRowCodes = data
    .filter(
      (row) => row.isDeleted && getValueFromDecodeResult(row.isDeleted) === true
    )
    .map((row) => getValueFromDecodeResult(row.rowCode))
    .filter(isNotNull);

  const duplicateCodes = getDuplicates(allRowCodes);

  const allParentCodes = data
    .map((row) => getValueFromDecodeResult(row.parentCode))
    .filter(isNotNull);

  const parentChildMappedData = data.map((row) => {
    const rowCode = getValueFromDecodeResult(row.rowCode);
    let parentCode = getValueFromDecodeResult(row.parentCode);

    let validationInformation = row;

    if (rowCode === parentCode) {
      parentCode = null;
      validationInformation = {
        ...validationInformation,
        parentCode: { kind: 'Invalid', invalidValue: rowCode },
      };
    }

    const validParentCode = parentCode
      ? allRowCodes.includes(parentCode)
      : true;

    if (!validParentCode) {
      validationInformation = {
        ...validationInformation,
        parentCode: {
          kind: 'Invalid',
          invalidValue: parentCode,
          customMessage: 'validation.invalidParent',
        },
      };
    }

    const isDeleted = row.isDeleted
      ? getValueFromDecodeResult(row.isDeleted) || false
      : false;

    // check that parent isn't deleted
    if (!isDeleted && parentCode) {
      const isParentDeleted = allDeletedRowCodes.includes(parentCode);

      if (isParentDeleted) {
        validationInformation = {
          ...validationInformation,
          parentCode: {
            kind: 'Invalid',
            invalidValue: parentCode,
            customMessage: 'validation.parentIsDeleted',
          },
        };
      }
    }

    if (rowCode && duplicateCodes.includes(rowCode)) {
      validationInformation = {
        ...validationInformation,
        rowCode: {
          kind: 'Invalid',
          invalidValue: rowCode,
          customMessage: 'validation.duplicateValue',
        },
      };
    }

    return {
      ...row,
      id: rowCode || v4(),
      parentId: validParentCode ? parentCode : null,
      validationInformation,
    };
  });

  const dataWithDepth = parentChildMappedData.map((row) => {
    let depth = 0; // set max depth of 15
    let latestParentId = row.parentId;
    const parents: string[] = [];

    while (depth < 15 && latestParentId !== null) {
      const parentId = latestParentId;

      const parent = parentChildMappedData.find((item) => item.id === parentId);

      if (parent?.id) {
        parents.push(parent?.id);
      }

      latestParentId = parent?.parentId ?? null;

      depth += 1;
    }

    return {
      ...row,
      depth: parents.length,
    };
  });

  const allParentRows = dataWithDepth
    .filter((row) => {
      const value = getValueFromDecodeResult(row.rowCode);
      const unitPrice = getValueFromDecodeResult(row.unitPrice);
      const target = getValueFromDecodeResult(row.target);

      if (value && allParentCodes.includes(value)) {
        return true;
      }

      // if no unitPrice is set, it's a hierarchy entry
      if (!unitPrice && (!target || target.eq(0))) {
        return true;
      }

      return false;
    })
    .map((row) => ({
      ...row,
      allChildCodes: collectAllChildIds(
        0,
        10,
        new Set([row.id]),
        dataWithDepth
      ),
    }));

  const now = new Date();

  return allParentRows.map((row) => {
    const targetAmount = targetRows
      .filter((targetRow) => row.allChildCodes.includes(targetRow.id))
      .reduce((acc, targetRow) => acc.add(targetRow.totalPrice), new Big(0));

    const targetRowIds = targetRows
      .filter((targetRow) => targetRow.targetRowHierarchyEntryId === row.id)
      .map((targetRow) => targetRow.id);

    const childIds = allParentRows
      .filter((entry) => entry.parentId === row.id)
      .map((entry) => entry.id);

    const quantity = new Big(getValueFromDecodeResult(row.quantity) || 0);

    const unitPrice = targetAmount.div(quantity.eq(0) ? 1 : quantity);

    let validationInformation = row.validationInformation;
    let parentId = row.parentId;
    let externalParentId = row.parentId;

    if (row.parentId && childIds.includes(row.parentId)) {
      parentId = null;
      externalParentId = null;
      validationInformation = {
        ...validationInformation,
        rowCode: {
          kind: 'Invalid',
          invalidValue: row.parentId,
          customMessage: 'validation.invalidParentChildRelationship',
        },
      };
    }

    const invalid = Object.values(validationInformation).some(
      (validationValue) => validationValue && validationValue.kind === 'Invalid'
    );

    return {
      id: row.id,
      externalId: row.id,
      externalParentId,
      projectId,
      parentId,
      referenceNumber: getValueFromDecodeResult(row.referenceNo),
      description: getValueFromDecodeResult(row.description),
      quantity: new Big(getValueFromDecodeResult(row.quantity) || 0),
      unit: getValueFromDecodeResult(row.unit),
      isDeleted: row.isDeleted
        ? getValueFromDecodeResult(row.isDeleted) || false
        : false,
      createdAt: now,
      updatedAt: now,
      childIds,
      targetRowIds,
      depth: row.depth,
      unitPrice,
      totalAmount: targetAmount,
      validationInformation: validationInformation,
      invalid,
    };
  });
};

export const validatedDataToOtherTempData = (
  projectId: string,
  data: ValidatedTargetImportData[],
  unSpecifiedWorkPackage: APIWorkPackage | undefined,
  unSpecifiedOrder: APIOrder | undefined
): {
  orders: ExtendedOrder[];
  workPackages: ExtendedWorkPackage[];
  topics: ExtendedTopic[];
} => {
  const allProcurementCodes = [
    ...new Set(
      data
        .map((row) => getValueFromDecodeResult(row.procurementCode))
        .filter(isNotNull)
    ),
  ];

  const allWorkSectionCodes = [
    ...new Set(
      data
        .map((row) => getValueFromDecodeResult(row.workSectionCode))
        .filter(isNotNull)
    ),
  ];

  const mappedOrders: ExtendedOrder[] = allProcurementCodes.map(
    (procurementCode) => {
      const nameData = [
        ...new Set(
          data
            .filter(
              (row) =>
                getValueFromDecodeResult(row.procurementCode) ===
                procurementCode
            )
            .map((row) => getValueFromDecodeResult(row.procurementName))
        ),
      ];

      let validationInformation: DecodeResult<string | null> = {
        kind: 'Decoded',
        value: procurementCode,
      };

      let invalid = false;

      if (nameData.length > 1) {
        validationInformation = {
          kind: 'Invalid',
          invalidValue: nameData.join(','),
          customMessage: 'validation.tooManyValuesFound',
        };
        invalid = true;
      }

      const name = nameData[0] ?? null;

      const fakeOrder = fillOrder({
        id: procurementCode,
        name: name || '',
        visibleCode: procurementCode,
      });

      return {
        ...fakeOrder,
        validationInformation,
        invalid,
      };
    }
  );

  const mappedWorkSections: ExtendedWorkPackage[] = allWorkSectionCodes.map(
    (workSectionCode) => {
      const nameData = [
        ...new Set(
          data
            .filter(
              (row) =>
                getValueFromDecodeResult(row.workSectionCode) ===
                workSectionCode
            )
            .map((row) => getValueFromDecodeResult(row.workSectionName))
        ),
      ];

      let validationInformation: DecodeResult<string | null> = {
        kind: 'Decoded',
        value: workSectionCode,
      };

      let invalid = false;

      if (nameData.length > 1) {
        validationInformation = {
          kind: 'Invalid',
          invalidValue: nameData.join(','),
          customMessage: 'validation.tooManyValuesFound',
        };
        invalid = true;
      }

      const name = nameData[0] ?? null;

      const fakeWorkSection = fillWorkPackage({
        id: workSectionCode,
        name: name || '',
        code: workSectionCode,
      });

      return {
        ...fakeWorkSection,
        validationInformation,
        invalid,
      };
    }
  );

  let orders = mappedOrders;
  let workSections = mappedWorkSections;

  if (unSpecifiedOrder) {
    orders = [
      ...mappedOrders.filter(
        (order) => order.visibleCode !== unSpecifiedOrder.visibleCode
      ),
      {
        ...unSpecifiedOrder,
        invalid: false,
        id: unSpecifiedOrder.visibleCode,
        validationInformation: {
          kind: 'Decoded',
          value: unSpecifiedOrder.visibleCode,
        },
      },
    ];
  }

  if (unSpecifiedWorkPackage) {
    workSections = [
      ...mappedWorkSections.filter(
        (wp) => wp.code !== unSpecifiedWorkPackage.code
      ),
      {
        ...unSpecifiedWorkPackage,
        invalid: false,
        id: unSpecifiedWorkPackage.code,
        validationInformation: {
          kind: 'Decoded',
          value: unSpecifiedWorkPackage.code,
        },
      },
    ];
  }

  const mappedTopics: ExtendedTopic[] = workSections.map((workSection) => {
    const fakeTopic = fillTopic({
      id: workSection.id,
      name: workSection.name,
      workPackageId: workSection.id,
    });

    return fakeTopic;
  });

  return {
    orders,
    workPackages: workSections,
    topics: mappedTopics,
  };
};

// Type guard to check if externalCode / externalId is a string
export function propertyIsNotNull<
  P extends keyof T,
  T extends Record<P, string | null>,
>(entry: T, key: P): entry is T & Record<P, string> {
  return entry[key] !== null;
}

export const targetRowToTargetImportData = (
  row: TargetRow & { externalCode: string },
  order: APIOrder | undefined,
  workPackage: APIWorkPackage | undefined
): TargetImportData => {
  return {
    rowCode: row.externalCode,
    parentCode: row.externalHierarchyEntryId,
    referenceNo: row.referenceNumber,
    description: row.description,
    quantity: row.quantity,
    unit: row.unit,
    unitPrice: row.unitPrice,
    target: row.totalPrice,
    procurementCode: order?.visibleCode ?? null,
    procurementName: order?.name ?? null,
    workSectionCode: workPackage?.code ?? null,
    workSectionName: workPackage?.name ?? null,
  };
};

export const targetRowHierarchyEntryToTargetImportData = (
  row: TargetRowHierarchyEntry & { externalId: string }
): TargetImportData => {
  return {
    rowCode: row.externalId,
    parentCode: row.externalParentId,
    referenceNo: row.referenceNumber,
    description: row.description,
    quantity: row.quantity,
    unit: row.unit,
    unitPrice: row.unitPrice,
    target: row.totalAmount,
    procurementCode: null,
    procurementName: null,
    workSectionCode: null,
    workSectionName: null,
  };
};
