import Big from 'big.js';

// Converts number to string with comma as the decimal separators
export const toInputString = (value: Big | null): string => {
  if (value === null) {
    return '';
  }

  return value.toString().replace('.', ',');
};

export const fromInputString = (
  value: string,
  defaultToOrThrow?: string | number
): Big => {
  try {
    return new Big(
      value
        .replace(',', '.')
        // Remove non numerics and - from the beginning of the string.
        .replace(/^[^\d-]*(.*)$/g, '$1')
        // Replace non numerics from the ends of the string.
        .replace(/[^\d]*$/g, '')
        // Replace space chars.
        .replace(/\s/g, '')
    );
  } catch (err) {
    if (defaultToOrThrow !== undefined) {
      return new Big(defaultToOrThrow);
    }

    throw err;
  }
};

export const format = (
  value: Big,
  decimals?: number,
  maxNWholeNumbersForDecimals?: number
) => {
  if (
    maxNWholeNumbersForDecimals !== undefined &&
    !hasMaxNWholeNumbers(value, maxNWholeNumbersForDecimals)
  ) {
    return value
      .toFixed(0)
      .replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1\u00a0')
      .replace('.', ',')
      .replace(/,(..)00$/, ',$1');
  }

  return value
    .toFixed(decimals === undefined ? 2 : decimals)
    .replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1\u00a0')
    .replace('.', ',')
    .replace(/,(..)00$/, ',$1');
};

export const amountFormat = (
  value: Big,
  decimals?: number,
  maxNWholeNumbersForDecimals?: number
) => {
  if (
    maxNWholeNumbersForDecimals !== undefined &&
    !hasMaxNWholeNumbers(value, maxNWholeNumbersForDecimals)
  ) {
    return value
      .toFixed(0)
      .replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1\u00a0')
      .replace('.', ',')
      .replace(/,0+$/, '');
  }

  return value
    .toFixed(decimals === undefined ? 2 : decimals)
    .replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1\u00a0')
    .replace('.', ',')
    .replace(/,0+$/, '');
};

export const taxFormat = (value: Big) => {
  return value.toFixed().replace('.', ',');
};

export const priceFormat = (
  value: Big,
  decimals?: number,
  maxNWholeNumbersForDecimals?: number
) => `${format(value, decimals, maxNWholeNumbersForDecimals)}\u00a0€`;

export const percentageFormat = (
  value: Big,
  decimals?: number,
  maxNWholeNumbersForDecimals?: number
) => `${format(value, decimals, maxNWholeNumbersForDecimals)}\u00a0%`;

export const priceFormatRounded = (
  value: Big,
  decimals?: number,
  maxNWholeNumbersForDecimals?: number
) => `${amountFormat(value, decimals, maxNWholeNumbersForDecimals)}\u00a0€`;

export const min = (a: Big, b: Big) => (a < b ? a : b);
export const max = (a: Big, b: Big) => (a > b ? a : b);

export const sum = (...xs: Big[]) =>
  xs.reduce((x, acc) => acc.add(x), new Big(0));

export const hasMaxNDecimals = (value: Big, n: number): boolean => {
  const splitted = value.toString().split('.');

  if (splitted.length < 2) {
    return true;
  }

  return splitted[1].length <= n;
};

export const hasMaxNWholeNumbers = (value: Big, n: number): boolean =>
  value.toString().split('.')[0].length <= n;
