import * as React from 'react';

import styled from 'styled-components';

import useTxt from '../hooks/useTxt';

import { IconUnlinked } from '../assets/svg';

import ToolTip from './Tooltip';

type UnlinkIconButtonProps = {
  onClick?: () => any;
  disabled?: boolean;
  testId?: string;
};

const UnlinkIconButton = ({
  onClick,
  disabled,
  testId,
}: UnlinkIconButtonProps) => {
  const tip = useTxt('analysis.modal.unlink');

  return (
    <ToolTip tip={tip} className="compressed-tooltip" place="top">
      <LinkContainer onClick={onClick} data-testid={testId} disabled={disabled}>
        <LinkIcon />
      </LinkContainer>
    </ToolTip>
  );
};

const LinkContainer = styled.button`
  border: none;

  display: grid;
  align-items: center;

  background: inherit;

  cursor: pointer;

  /* stylelint-disable selector-max-class -- Pls. see AnalysisLinker/Row for explanation */
  &:hover .separator,
  &:focus .separator {
    opacity: 1;
    transition: opacity 0.5s ease-out;
  }
`;

const LinkIcon = styled(IconUnlinked)`
  justify-self: end;

  /* stylelint-disable-next-line -- SVG element has "break" line which we do not wish to show */
  .separator {
    opacity: 0;
  }
`;

export default UnlinkIconButton;
