import React, { Fragment } from 'react';

import Big from 'big.js';
import { FormikErrors } from 'formik';
import { cloneDeep } from 'lodash';
import styled from 'styled-components';
import { v4 } from 'uuid';

import { TargetRow as APITargetRow } from '../../../../../store/actions/target';

import { APIOrderRow } from '../../../../../types/api';

import useTxt from '../../../../../hooks/useTxt';

import { IconTextButton } from '../../../../../components/Buttons';
import { Table } from '../../../../../components/Table';
import Txt from '../../../../../components/Txt';

import * as big from '../../../../../utils/big';

import { IconPlus } from '../../../../../assets/svg';

import { SplitTargetModalOrderRow } from './OrderRow';
import { TableHeader } from './TableHeader';
import { TargetRow } from './TargetRow';
import { TargetRowToBeSplit } from './TargetRowToBeSplit';

import {
  FormValueShape,
  calculateOrderSum,
  calculateToBeSplitTargetSum,
} from '.';

type SplitOrderAndTargetRowsTableProps = {
  errors: FormikErrors<FormValueShape>;
  values: FormValueShape;
  setValues: (
    values: React.SetStateAction<FormValueShape>,
    shouldValidate?: boolean | undefined
  ) => void;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T = string | React.ChangeEvent<any>>(
      field: T
    ): T extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
  orderRows?: APIOrderRow[];
  selectedTargetRows: APITargetRow[];
  allRelatedTargetRows?: APITargetRow[];
  setFormOrderRowInvalid?: (orderRowId: string, invalid: boolean) => void;
};

export const SplitOrderAndTargetRowsTable = ({
  errors,
  values,
  setValues,
  setFieldValue,
  handleChange,
  orderRows,
  selectedTargetRows,
  allRelatedTargetRows,
  setFormOrderRowInvalid,
}: SplitOrderAndTargetRowsTableProps) => {
  const MAX_CREATABLE_ROWS = 50;

  const targetRowsWithoutOrderRowId = selectedTargetRows.filter(
    ({ orderRowId }) => orderRowId === null
  );

  const addingRowsDisabled = () =>
    Object.keys(values.rows).length >= MAX_CREATABLE_ROWS;

  const addNewRowButtonTitle = useTxt('common.addRow');

  const addNewRowButtonTitleDisabled = useTxt('common.maxRowsExceeded', {
    maxRows: MAX_CREATABLE_ROWS,
  });

  const removeRow = (index: number, isSplitFrom: string) => {
    const newValues = cloneDeep(values.rows[isSplitFrom]);
    newValues?.splice(index, 1);

    setValues({ rows: { ...values.rows, [isSplitFrom]: newValues } });
  };

  const removeAllRows = (isSplitFrom: string) => {
    const { [isSplitFrom]: _, ...rest } = values.rows;

    setValues({ rows: { ...rest } });
  };

  const removeAllRelatedRows = (isSplitFromIds: string[]) => {
    const raw = values;

    const allowed = isSplitFromIds;

    const filtered = Object.keys(raw.rows)
      .filter((key) => !allowed.includes(key))
      .reduce(
        (obj, key) => {
          return { rows: { ...obj.rows, [key]: raw.rows[key] } };
        },
        { rows: {} } as FormValueShape
      );

    setValues({ ...filtered });
  };

  const addNewTargetRow = (targetRow: APITargetRow) => {
    const existingRows = values.rows[targetRow.id];

    if (!existingRows) {
      return;
    }

    // get the last row's dropdown values and use those as a base
    const {
      orderId: rowOrderId,
      topicId,
      analysisId,
    } = existingRows.slice(-1)[0];

    const newRow = {
      rowId: v4(),
      orderId: rowOrderId,
      topicId,
      description: '',
      quantity: '',
      unit: '',
      unitPrice: '',
      analysisId,
      splitFromTargetRowId: targetRow.id,
      orderRowId: targetRow.orderRowId,
    };

    setValues({
      rows: { ...values.rows, [targetRow.id]: [...existingRows, newRow] },
    });
  };

  const addRestOfTargetRow = (targetRow: APITargetRow) => {
    const existingRows = values.rows[targetRow.id];

    if (!existingRows) {
      return;
    }

    // get the last row's dropdown values and use those as a base
    const {
      orderId: rowOrderId,
      topicId,
      analysisId,
    } = existingRows.slice(-1)[0];

    const remainingAmount = new Big(targetRow.totalPrice).minus(
      totalSplitRows(targetRow.id)
    );

    const newQuantity = (targetRow.unitPrice ?? new Big(0)).eq(0)
      ? targetRow.quantity ?? new Big(0)
      : remainingAmount.div(targetRow.unitPrice ?? 1);

    let newRow = {
      rowId: v4(),
      orderId: rowOrderId,
      topicId,
      description: targetRow.description ?? '',
      quantity: newQuantity.toFixed(4),
      unit: targetRow.unit ?? '',
      unitPrice: targetRow.unitPrice ? targetRow.unitPrice.toFixed(4) : '',
      analysisId,
      splitFromTargetRowId: targetRow.id,
      orderRowId: targetRow.orderRowId,
    };

    const diffIsNotZero = new Big(targetRow.totalPrice)
      .minus(totalSplitRows(targetRow.id))
      .minus(
        new Big(newQuantity.toFixed(4)).mul(
          new Big(targetRow.unitPrice?.toFixed(4) ?? 0)
        )
      )
      .abs()
      .gte(0.01);

    if (diffIsNotZero) {
      newRow = {
        rowId: v4(),
        orderId: rowOrderId,
        topicId,
        description: targetRow.description ?? '',
        quantity: remainingAmount.toFixed(4),
        unit: targetRow.unit ?? '',
        unitPrice: '1',
        analysisId,
        splitFromTargetRowId: targetRow.id,
        orderRowId: targetRow.orderRowId,
      };
    }

    setValues({
      rows: { ...values.rows, [targetRow.id]: [...existingRows, newRow] },
    });
  };

  const totalSplitRows = (targetRowId: string) => {
    const splitRows = values.rows[targetRowId];

    const total = splitRows?.reduce(
      (acc, val) =>
        acc.add(
          big
            .fromInputString(val?.quantity, 0)
            .mul(big.fromInputString(val?.unitPrice, 0))
        ),

      new Big(0)
    );

    return total || new Big(0);
  };

  return (
    <Table>
      <TableHeader />
      <tbody>
        {targetRowsWithoutOrderRowId.map((row) => {
          return (
            <Fragment key={`target-row-to-be-split-${row.id}`}>
              <TargetRowToBeSplit row={row} removeAllRows={removeAllRows} />
              {values.rows[row.id]?.map((splitRow, i) => (
                <Fragment key={splitRow.rowId}>
                  <TargetRow
                    key={splitRow.rowId}
                    row={splitRow}
                    index={i}
                    errors={errors}
                    isDeleteDisabled={values.rows[row.id]?.length === 1}
                    onChange={handleChange}
                    setFieldValue={setFieldValue}
                    onRemove={() => {
                      const removeFnWithFormikValues = (index: number) =>
                        removeRow(index, row.id);

                      return removeFnWithFormikValues(i);
                    }}
                  />
                </Fragment>
              ))}
              <tr key={`new-split-row-for-${row.id}`}>
                <LeftPaddedCell colSpan={2}>
                  <ToolBarAndStatusGroup>
                    <IconTextButton
                      icon={IconPlus}
                      id="targetRow.button.new"
                      type="button"
                      disabled={addingRowsDisabled()}
                      title={
                        addingRowsDisabled()
                          ? addNewRowButtonTitleDisabled
                          : addNewRowButtonTitle
                      }
                      onClick={() => addNewTargetRow(row)}
                    >
                      <Txt id="common.addRow" />
                    </IconTextButton>
                  </ToolBarAndStatusGroup>
                </LeftPaddedCell>
                <td colSpan={6}>
                  <ToolBarAndStatusGroup>
                    <IconTextButton
                      icon={IconPlus}
                      id="targetRow.button.addRest"
                      type="button"
                      disabled={addingRowsDisabled()}
                      title={
                        addingRowsDisabled()
                          ? addNewRowButtonTitleDisabled
                          : addNewRowButtonTitle
                      }
                      onClick={() => addRestOfTargetRow(row)}
                    >
                      <Txt id="order.targetSplitModal.button.addRest" />
                    </IconTextButton>
                  </ToolBarAndStatusGroup>
                </td>
                <RightAlignCell colSpan={2}>
                  <RedSpan>
                    {new Big(row.totalPrice)
                      .minus(totalSplitRows(row.id))
                      .abs()
                      .gt(0.01) ? (
                      <Txt
                        id="order.targetSplitModal.remainingAmount.row"
                        values={{
                          remainingAmount: big.priceFormat(
                            new Big(row.totalPrice).minus(
                              totalSplitRows(row.id)
                            )
                          ),
                        }}
                      />
                    ) : null}
                  </RedSpan>
                </RightAlignCell>
              </tr>
            </Fragment>
          );
        })}
        {orderRows?.map((orderRow) => {
          const selectedAndRelatedTargetRows = selectedTargetRows.filter(
            (targetRow) => targetRow.orderRowId === orderRow.id
          );

          const allTargetRowsRelatedToOrderRow =
            allRelatedTargetRows?.filter(
              (targetRow) => targetRow.orderRowId === orderRow.id
            ) ?? [];

          const orderRowSumToBeSplit = calculateOrderSum(
            selectedAndRelatedTargetRows,
            allTargetRowsRelatedToOrderRow,
            [orderRow]
          );

          return (
            <Fragment key={`order-row-${orderRow.id}`}>
              <SplitTargetModalOrderRow
                orderRow={orderRow}
                selectedAndRelatedTargetRows={selectedAndRelatedTargetRows}
                orderRowSumToBeSplit={orderRowSumToBeSplit}
                setFormInvalid={setFormOrderRowInvalid}
                removeAllRows={() =>
                  removeAllRelatedRows(
                    selectedAndRelatedTargetRows.map((row) => row.id)
                  )
                }
              />
              {selectedAndRelatedTargetRows.map((targetRow) => {
                const ratio = calculateToBeSplitTargetSum(
                  selectedAndRelatedTargetRows
                ).eq(0)
                  ? new Big(0)
                  : targetRow.totalPrice.div(
                      calculateToBeSplitTargetSum(selectedAndRelatedTargetRows)
                    );

                const orderRowAmount = orderRowSumToBeSplit.mul(ratio);

                return (
                  <Fragment key={`targetRow-${targetRow.id}`}>
                    <TargetRowToBeSplit
                      row={targetRow}
                      key={`target-row-${targetRow.id}`}
                      removeAllRows={removeAllRows}
                    />
                    {values.rows[targetRow.id]?.map((splitRow, i) => {
                      const splitRowAmount = big
                        .fromInputString(splitRow.unitPrice, 0)
                        .times(big.fromInputString(splitRow.quantity, 0));

                      const relativeOrderRowAmount = big.priceFormat(
                        targetRow.totalPrice.eq(0)
                          ? new Big(0)
                          : orderRowAmount.mul(
                              splitRowAmount.div(targetRow.totalPrice)
                            ),
                        2
                      );

                      return (
                        <Fragment key={splitRow.rowId}>
                          <TargetRow
                            key={splitRow.rowId}
                            row={splitRow}
                            index={i}
                            errors={errors}
                            orderRowAmount={relativeOrderRowAmount}
                            isDeleteDisabled={
                              values.rows[targetRow.id]?.length === 1
                            }
                            onChange={handleChange}
                            setFieldValue={setFieldValue}
                            onRemove={() => {
                              const removeFnWithFormikValues = (
                                index: number
                              ) => removeRow(index, targetRow.id);

                              return removeFnWithFormikValues(i);
                            }}
                          />
                        </Fragment>
                      );
                    })}
                    <tr>
                      <td />
                      <LeftPaddedCell>
                        <ToolBarAndStatusGroup>
                          <IconTextButton
                            icon={IconPlus}
                            id="targetRow.button.new"
                            type="button"
                            disabled={addingRowsDisabled()}
                            title={
                              addingRowsDisabled()
                                ? addNewRowButtonTitleDisabled
                                : addNewRowButtonTitle
                            }
                            onClick={() => addNewTargetRow(targetRow)}
                          >
                            <Txt id="common.addRow" />
                          </IconTextButton>
                        </ToolBarAndStatusGroup>
                      </LeftPaddedCell>
                      <td colSpan={6}>
                        <ToolBarAndStatusGroup>
                          <IconTextButton
                            icon={IconPlus}
                            id="targetRow.button.addRest"
                            type="button"
                            disabled={addingRowsDisabled()}
                            title={
                              addingRowsDisabled()
                                ? addNewRowButtonTitleDisabled
                                : addNewRowButtonTitle
                            }
                            onClick={() => addRestOfTargetRow(targetRow)}
                          >
                            <Txt id="order.targetSplitModal.button.addRest" />
                          </IconTextButton>
                        </ToolBarAndStatusGroup>
                      </td>

                      <RightAlignCell colSpan={2}>
                        <RedSpan>
                          {new Big(targetRow.totalPrice)
                            .minus(totalSplitRows(targetRow.id))
                            .abs()
                            .gt(0.01) ? (
                            <Txt
                              id="order.targetSplitModal.remainingAmount.row"
                              values={{
                                remainingAmount: big.priceFormat(
                                  new Big(targetRow.totalPrice).minus(
                                    totalSplitRows(targetRow.id)
                                  )
                                ),
                              }}
                            />
                          ) : null}
                        </RedSpan>
                      </RightAlignCell>
                    </tr>
                  </Fragment>
                );
              })}
            </Fragment>
          );
        })}
      </tbody>
    </Table>
  );
};

const ToolBarAndStatusGroup = styled.div`
  margin: ${(props) =>
    `${props.theme.margin[8]} ${props.theme.margin[8]} ${props.theme.margin[16]}`};

  width: 100%;

  display: flex;
  align-items: left;
  justify-content: left;
`;

const RedSpan = styled.span`
  color: ${({ theme: { color } }) => color.red};
`;

const LeftPaddedCell = styled.td`
  padding: ${(props) => props.theme.margin[4]}
    ${(props) => props.theme.margin[4]} ${(props) => props.theme.margin[4]}
    ${(props) => props.theme.margin[32]};
`;

const RightAlignCell = styled.td`
  padding-right: ${(props) => props.theme.margin[16]};
  text-align: right;
`;
