import styled, { css } from 'styled-components';

import { IconDiagonalLinePattern } from '../../assets';

export const Table = styled.table`
  border: 0;

  width: 100%;

  line-height: ${(props) => props.theme.fontSize.base};

  border-collapse: collapse;
  table-layout: fixed;
`;

export const TableSeparateBorderCollapse = styled(Table)`
  border: 0;

  width: 100%;

  line-height: ${(props) => props.theme.fontSize.base};

  border-collapse: separate;
  table-layout: fixed;
  border-spacing: 0 2px;
`;

export const TableHeader = styled.thead`
  font-weight: bold;
`;

type HeaderThProps = {
  width?: string;
  textAlign?: 'right' | 'left' | 'center';
  name?: string;
};

export const HeaderTh = styled.th<HeaderThProps>`
  padding: ${(props) => props.theme.margin[8]};
  width: ${({ width }) => width};
  ${({ textAlign }) =>
    textAlign
      ? css`
          text-align: ${textAlign};
        `
      : ''}
`;

export const StickyHeaderTh = styled(HeaderTh)`
  position: sticky;
  top: 0;

  padding-top: ${(props) => props.theme.margin[8]};

  background: white;

  z-index: 1;
`;

export const StickyTableHeader = styled(TableHeader)`
  position: sticky;
  top: 0;

  padding-top: ${(props) => props.theme.margin[8]};

  background: white;

  z-index: 1;
`;

export const HeaderNumber = styled.div`
  padding: ${(props) => props.theme.margin[16]} 0 0 0;

  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: end;

  text-align: right;
  color: ${(props) => props.theme.color.graphiteB57};
  font-weight: 900;
`;

export const HeaderName = styled.div`
  height: 1rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  &:hover {
    white-space: normal;
    overflow: visible;
  }
`;

export const CompactTableHeader = styled.thead`
  font-weight: bold;
`;

export const CompactHeaderTh = styled.th<HeaderThProps>`
  padding: 0 ${({ theme }) => theme.margin[4]};
  height: ${({ theme }) => theme.margin[32]};
  width: ${({ width }) => width};
`;

type RowProps = {
  clickable?: boolean;
};

export const BaseRow = styled.tr<RowProps>`
  ${({ clickable }) =>
    clickable
      ? css`
          cursor: pointer;
        `
      : ''}
`;

export const PrimaryRow = styled(BaseRow)`
  background: ${(props) => props.theme.color.primaryRowBackground};
`;

export const BoldedPrimaryRow = styled(PrimaryRow)`
  border: 2px solid ${(props) => props.theme.color.white};
`;

export const SecondaryRow = styled(BaseRow)`
  /* stylelint-disable selector-max-type -- TODO Should be refactored */
  & + & > td {
    border-top: 1px solid ${(props) => props.theme.color.rowBorder};
  }
`;

type InvoiceRowProps = {
  selected?: boolean;
  rowHighlighted?: boolean;
  isHandled?: boolean;
};

export const InvoiceRow = styled(BoldedPrimaryRow)<InvoiceRowProps>`
  @keyframes flash-animation {
    from {
      background: ${(props) => props.theme.color.infoNotification};
      opacity: 0.3;
    }
    to {
      background: default;
    }
  }

  border: 1px solid ${(props) => props.theme.color.toolbarHighlight};
  ${(props) =>
    props.isHandled &&
    css`
      background: url(${IconDiagonalLinePattern});
    `}

  ${(props) =>
    props.selected &&
    css`
      border-top: 1px solid ${({ theme }) => theme.color.toolbarHighlight};
      border-bottom: 1px solid ${({ theme }) => theme.color.toolbarHighlight};
    `}

  ${({ rowHighlighted }) =>
    rowHighlighted
      ? css`
          animation: flash-animation linear 3s 1;
        `
      : css``}
`;
