import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import styled from 'styled-components';

import { InvoiceHeader } from '../../../../../store/reducers/invoiceHeader';
import { getOrderById } from '../../../../../store/reducers/order/order';

import useTxt from '../../../../../hooks/useTxt';

import { PrimaryButtonSmall } from '../../../../../components/Buttons';

import { getInvoiceHeaderStatus } from './utils';

import { routes, generateUrl } from '../../../../../routes';

type HandlableItemProps = {
  orderId: string;
  invoiceHeader: InvoiceHeader;
  first: boolean;
};

const Wrapper = styled.div`
  padding: 0 ${(props) => props.theme.margin[8]} 0
    ${(props) => props.theme.margin[16]};

  height: 2rem;

  display: flex;
  justify-content: space-between;
  align-items: center;

  flex: 1;

  background: ${({ theme }) => theme.color.graphiteB96A};

  font-weight: bold;
`;

const ArrivalRowOrderGroup = ({
  orderId,
  invoiceHeader,
  first,
}: HandlableItemProps) => {
  const order = useSelector(getOrderById(orderId));

  const history = useHistory();

  const text = useTxt(
    first
      ? 'order.receiveMode.arrivalRowOrderGroup.receivedInProcurement'
      : 'order.receiveMode.arrivalRowOrderGroup.receivedInProcurement.another'
  );

  const showInfoText = useTxt('order.mainButtons.showInfo');

  if (!order) {
    return null;
  }

  const linkPathName = generateUrl({
    route: routes.ORDER_WITH_INVOICE_FOCUSED,
    projectId: order.projectId,
    orderId,
    viewMode: 'receive',
    subViewMode: 'invoices',
    invoiceHeaderId: invoiceHeader.id,
  });

  const status = getInvoiceHeaderStatus(invoiceHeader);

  const toggleShowInfoSidebar = () => {
    const nextUrl = generateUrl({
      route: routes.ORDER,
      projectId: order.projectId,
      orderId,
      viewMode: 'receive',
      subViewMode: 'invoices',
      showInfo: 'info',
    });
    history.push(nextUrl);
  };

  return (
    <Wrapper>
      <HandlingText>
        {`${text}: `}
        <Link to={linkPathName}>{`${order.visibleCode}, ${order.name}`}</Link>
      </HandlingText>
      {invoiceHeader &&
      !order.costTypeId &&
      status.text === 'order.receiveMode.utils.invoiceNoCostType' ? (
        <SecondaryButton
          data-testid={`arrivalrow-order-group-open-info-button-invoice${invoiceHeader.id}`}
          onClick={() => toggleShowInfoSidebar()}
        >
          {showInfoText}
        </SecondaryButton>
      ) : null}
    </Wrapper>
  );
};

const HandlingText = styled.span`
  background-repeat: no-repeat;
  background-position: 2px center;
`;

const SecondaryButton = styled(PrimaryButtonSmall)`
  margin: 0 1rem 0 0;

  padding: ${(props) => props.theme.margin[4]};

  height: auto;
  width: 12rem;

  font-size: ${(props) => props.theme.fontSize.small};
  font-weight: 500;
  color: ${(props) => props.theme.color.white};
`;

export default ArrivalRowOrderGroup;
