type RecordKey = string | number | symbol;

export default function groupBy<
  Key extends RecordKey,
  Obj extends Record<Key, any>,
  GroupingKey extends Key,
>(
  key: Obj[GroupingKey] extends RecordKey ? GroupingKey : never,
  objects: Obj[]
): Record<Obj[GroupingKey], Obj[]> {
  return objects.reduce(
    (grouped: Partial<Record<Obj[GroupingKey], Obj[]>>, object) => {
      const groupKey = object[key];
      const group: Obj[] = grouped[groupKey] ?? [];

      return { ...grouped, [groupKey]: [...group, object] };
    },
    {}
  );
}
