import Big from 'big.js';
import { orderBy } from 'lodash';
import { Reducer } from 'redux';

import { APIOrderRow } from '../../types/api';

import {
  assertActionPayloadIsNotApiUpdatedEntities,
  isUpdatedEntitiesActionType,
  Selector,
} from './utils';
import * as big from '../../utils/big';
import {
  isDefined,
  ORDER_ROW_STATUS_CONTRACT,
  ORDER_ROW_STATUS_RESERVES,
  ORDER_ROW_STATUS_CHANGEORDER,
} from '../../utils/general';
import normalizeBy from '../../utils/normalizeBy';
import * as nullable from '../../utils/nullable';
import { pickBy } from '../../utils/record';
import * as remoteData from '../../utils/remoteData';

import { ActionTypes } from '../actionTypes';
import { getArrivalRowById, getArrivalRows } from './arrivalRow';
import {
  getTopic,
  getTopicsByOrderId,
  getTopicsByWorkPackageId,
} from './topic';

import { AppState } from '.';

export type OrderRowState = {
  orderRequests: Partial<Record<string, remoteData.RemoteData>>;
  workPackageRequests: Partial<Record<string, remoteData.RemoteData>>;
  deleteRequests: Partial<Record<string, remoteData.RemoteData>>;
  analysisRowRequests: Partial<Record<string, remoteData.RemoteData>>;
  data: Partial<Record<string, APIOrderRow>>;
};

const initialState: OrderRowState = {
  orderRequests: {},
  analysisRowRequests: {},
  deleteRequests: {},
  workPackageRequests: {},
  data: {},
};

const orderRowReducer: Reducer<OrderRowState, ActionTypes> = (
  state = initialState,
  action
): OrderRowState => {
  switch (action.type) {
    case 'DELETE_ORDER_ROW_STARTED': {
      const { orderRowId } = action.payload;

      return {
        ...state,
        deleteRequests: { [orderRowId]: remoteData.loading },
      };
    }

    case 'DELETE_ORDER_ROW_FAILURE': {
      const { orderRowId } = action.payload;

      return {
        ...state,
        deleteRequests: {
          [orderRowId]: remoteData.fail(action.payload.error),
        },
      };
    }

    case 'DELETE_MULTIPLE_ORDER_ROWS_STARTED': {
      const { orderRowIds, requestId } = action.payload;

      const idDeleteRequests = orderRowIds.reduce((prev, orderRowId) => {
        return { ...prev, [orderRowId]: remoteData.loading };
      }, {});

      return {
        ...state,
        deleteRequests: {
          ...state.deleteRequests,
          ...idDeleteRequests,
          [requestId]: remoteData.loading,
        },
      };
    }

    case 'DELETE_MULTIPLE_ORDER_ROWS_FAILURE': {
      const { orderRowIds, requestId } = action.payload;

      const idDeleteRequests = orderRowIds.reduce((prev, orderRowId) => {
        return { ...prev, [orderRowId]: remoteData.fail(action.payload.error) };
      }, {});

      return {
        ...state,
        deleteRequests: {
          ...state.deleteRequests,
          ...idDeleteRequests,
          [requestId]: remoteData.fail(action.payload.error),
        },
      };
    }

    case 'DELETE_MULTIPLE_ORDER_ROWS_SUCCESS': {
      const {
        orderRowIds,
        requestId,
        orderRows: updatedOrderRows = [],
      } = action.payload;

      if (!updatedOrderRows) {
        return state;
      }

      const data = { ...state.data };

      updatedOrderRows.forEach((orderRow) => {
        const { id, isDeleted } = orderRow;

        if (isDeleted) {
          delete data[id];
        } else {
          data[id] = orderRow;
        }
      });

      const idDeleteRequests = orderRowIds.reduce((prev, orderRowId) => {
        return { ...prev, [orderRowId]: remoteData.succeed(undefined) };
      }, {});

      return {
        ...state,
        deleteRequests: {
          ...state.deleteRequests,
          ...idDeleteRequests,
          [requestId]: remoteData.succeed(undefined),
        },
      };
    }

    case 'GET_ORDER_ROWS_STARTED': {
      const { orderId } = action.payload;

      const orderRequests = {
        ...state.orderRequests,
        [orderId]: remoteData.loading,
      };

      return { ...state, orderRequests };
    }
    case 'GET_ORDER_ROWS_FAILURE': {
      const { orderId, error } = action.payload;

      const orderRequests = {
        ...state.orderRequests,
        [orderId]: remoteData.fail(error),
      };

      return { ...state, orderRequests };
    }
    case 'GET_ORDER_ROWS_SUCCESS': {
      const { orderRows, orderId } = action.payload;

      const orderRequests = {
        ...state.orderRequests,
        [orderId]: remoteData.succeed(undefined),
      };

      const data = { ...state.data, ...normalizeBy('id', orderRows) };

      return { ...state, orderRequests, data };
    }
    case 'GET_ORDER_ROWS_FOR_WORK_PACKAGE_STARTED': {
      const { workPackageId } = action.payload;

      const workPackageRequests = {
        ...state.workPackageRequests,
        [workPackageId]: remoteData.loading,
      };

      return { ...state, workPackageRequests };
    }
    case 'GET_ORDER_ROWS_FOR_WORK_PACKAGE_FAILURE': {
      const { workPackageId, error } = action.payload;

      const workPackageRequests = {
        ...state.orderRequests,
        [workPackageId]: remoteData.fail(error),
      };

      return { ...state, workPackageRequests };
    }
    case 'GET_ORDER_ROWS_FOR_WORK_PACKAGE_SUCCESS': {
      const { orderRows, workPackageId } = action.payload;

      const workPackageRequests = {
        ...state.orderRequests,
        [workPackageId]: remoteData.succeed(undefined),
      };

      const data = { ...state.data, ...normalizeBy('id', orderRows) };

      return { ...state, workPackageRequests, data };
    }
    case 'GET_ORDER_ROWS_FOR_ANALYSIS_ROW_STARTED': {
      const { analysisRowId } = action.payload;

      const analysisRowRequests = {
        ...state.analysisRowRequests,
        [analysisRowId]: remoteData.loading,
      };

      return { ...state, analysisRowRequests };
    }
    case 'GET_ORDER_ROWS_FOR_ANALYSIS_ROW_FAILURE': {
      const { analysisRowId, error } = action.payload;

      const analysisRowRequests = {
        ...state.analysisRowRequests,
        [analysisRowId]: remoteData.fail(error),
      };

      return { ...state, analysisRowRequests };
    }
    case 'GET_ORDER_ROWS_FOR_ANALYSIS_ROW_SUCCESS': {
      const { orderRows, analysisRowId } = action.payload;

      const analysisRowRequests = {
        ...state.analysisRowRequests,
        [analysisRowId]: remoteData.succeed(undefined),
      };

      const data = { ...state.data, ...normalizeBy('id', orderRows) };

      return { ...state, analysisRowRequests, data };
    }
  }

  if (isUpdatedEntitiesActionType(action)) {
    const { orderRows: updatedOrderRows } = action.payload;

    if (!updatedOrderRows) {
      return state;
    }

    const data = { ...state.data };
    updatedOrderRows.forEach((orderRow) => {
      const { isDeleted, id } = orderRow;

      if (isDeleted) {
        delete data[id];
      } else {
        data[id] = orderRow;
      }
    });

    return {
      ...state,
      data,
    };
  }

  assertActionPayloadIsNotApiUpdatedEntities(action);

  return state;
};

export function getOrderRows({
  orderId,
}: {
  orderId: string;
}): Selector<remoteData.RemoteData<APIOrderRow[]>> {
  return ({
    orderRows: {
      orderRequests: { [orderId]: requestState = remoteData.notAsked },
      data,
    },
  }: AppState) => {
    const filteredRemoteData = remoteData.map(requestState, (_) =>
      Object.values(data)
        .filter((row) => row && row.orderId === orderId)
        .filter(isDefined)
    );

    const sortedRemoteData = remoteData.map(filteredRemoteData, (rows) =>
      orderBy(rows, ['rowNumber', 'id'], ['asc', 'asc'])
    );

    return sortedRemoteData;
  };
}

export function getOrderRowsForWorkPackage({
  workPackageId,
}: {
  workPackageId: string;
}): Selector<remoteData.RemoteData<APIOrderRow[]>> {
  return (appState: AppState) => {
    const requestState =
      appState.orderRows.workPackageRequests[workPackageId] ??
      remoteData.notAsked;
    const remoteTopics = getTopicsByWorkPackageId(workPackageId)(appState);

    const topics = remoteData.withDefault(remoteTopics, []);

    if (requestState.kind !== 'Success') {
      return requestState;
    }

    const topicIds = topics.map((topic) => topic.id);

    return remoteData.map(requestState, (_) =>
      Object.values(appState.orderRows.data)
        .filter((row) => row && topicIds.includes(row.topicId))
        .filter(isDefined)
    );
  };
}

export function getOrderRowsForAnalysisRow(
  analysisRowId: string
): Selector<remoteData.RemoteData<APIOrderRow[]>> {
  return ({
    orderRows: {
      analysisRowRequests: {
        [analysisRowId]: requestState = remoteData.notAsked,
      },
      data,
    },
  }: AppState) =>
    remoteData.map(requestState, (_) =>
      Object.values(data)
        .filter(
          (orderRow) =>
            orderRow &&
            orderRow.analysisListItemIds.some(
              (listItemId) => listItemId === analysisRowId
            )
        )
        .filter(isDefined)
    );
}

export const getOrderRowById =
  (id: string) =>
  ({
    orderRows: {
      data: { [id]: orderRow },
    },
  }: AppState): APIOrderRow | undefined =>
    orderRow;

export type RenderableOrderRow = {
  id: string;
  rowNumber: number;
  description: string;
  topicId: string;
  quantity: nullable.Nullable<string>;
  unit: string;
  unitPrice: nullable.Nullable<string>;
  arrivalTotal: string;
  arrivalQuantity: string;
  totalPrice: nullable.Nullable<string>;
  status: Status;
  analysisListItemIds: string[];
  arrivalRowIds: string[];
  remainingAmount: nullable.Nullable<string>;
  percentage: nullable.Nullable<string | number>;
};

export type Status = 'Contract' | 'Reserves' | 'ChangeOrder';

const toStatus = (statusId: string): Status => {
  switch (statusId) {
    case ORDER_ROW_STATUS_CONTRACT:
      return 'Contract';
    case ORDER_ROW_STATUS_CHANGEORDER:
      return 'ChangeOrder';
    case ORDER_ROW_STATUS_RESERVES:
    default:
      return 'Reserves';
  }
};

// TODO: this should be combined with the toTargetOrderRow mapper function
// there's no need to process order rows into 2 independent types

export const toRenderableOrderRow = (
  apiOrderRow: APIOrderRow,
  decimals?: number
): RenderableOrderRow => {
  const {
    id,
    rowNumber,
    description,
    quantity,
    unit,
    unitPrice,
    arrivalQuantity,
    arrivalTotal,
    statusId,
    analysisListItemIds,
    arrivalRowIds,
    topicId,
  } = apiOrderRow;

  const totalPrice = nullable.map(
    nullable.append(quantity, unitPrice),
    ([_quantity, _unitPrice]) => _quantity.mul(_unitPrice)
  );

  const percentage = totalPrice?.toNumber()
    ? parseFloat(arrivalTotal.div(totalPrice).times(100).toFixed(0))
    : 0;

  const remainingAmount = totalPrice && totalPrice.minus(arrivalTotal);

  const amountFormat = (value: Big) => {
    return big.amountFormat(
      value,
      decimals,
      decimals && decimals > 0 ? 6 : undefined
    );
  };

  const priceFormat = (value: Big) => {
    return big.priceFormat(
      value,
      decimals,
      decimals && decimals > 0 ? 6 : undefined
    );
  };

  const priceFormatRounded = (value: Big) => {
    return big.priceFormatRounded(
      value,
      decimals,
      decimals && decimals > 0 ? 6 : undefined
    );
  };

  return {
    id,
    rowNumber,
    description,
    topicId,
    quantity: nullable.map(quantity, amountFormat),
    unit,
    unitPrice: nullable.map(unitPrice, priceFormat),
    arrivalQuantity: amountFormat(arrivalQuantity),
    arrivalTotal: priceFormat(arrivalTotal),
    status: toStatus(statusId),
    totalPrice: nullable.map(totalPrice, priceFormatRounded),
    analysisListItemIds,
    arrivalRowIds,
    percentage,
    remainingAmount: nullable.map(remainingAmount, priceFormatRounded),
  };
};

export const getRenderableOrderRowById =
  (id: string, decimals?: number) => (appState: AppState) => {
    const orderRow = getOrderRowById(id)(appState);

    return orderRow ? toRenderableOrderRow(orderRow, decimals) : undefined;
  };

export const getRenderableOrderRowByArrivalRowId =
  (arrivalRowId: string) => (appState: AppState) => {
    const arrivalRow = getArrivalRowById(arrivalRowId)(appState);

    return arrivalRow
      ? getRenderableOrderRowById(arrivalRow.orderRowId ?? '')(appState)
      : undefined;
  };

export const getStatus =
  (orderRowId: string) =>
  (appState: AppState): Status => {
    const orderRow = getOrderRowById(orderRowId)(appState);

    return nullable.map(orderRow?.statusId, toStatus) ?? 'Reserves';
  };

const toApiStatus = {
  Reserves: ORDER_ROW_STATUS_RESERVES,
  Contract: ORDER_ROW_STATUS_CONTRACT,
  ChangeOrder: ORDER_ROW_STATUS_CHANGEORDER,
};

export const getNextStatusId = (id: string) => (appState: AppState) => {
  const currentStatus = getStatus(id)(appState);

  const hasArrivals =
    getArrivalRows(appState).filter(({ orderRowId }) => orderRowId === id)
      .length > 0;

  if (!currentStatus) {
    return undefined;
  }

  const stateMapping: Record<Status, Status> = {
    Reserves: 'Contract',
    Contract: 'ChangeOrder',
    ChangeOrder: hasArrivals ? 'Contract' : 'Reserves',
  };

  return toApiStatus[stateMapping[currentStatus]];
};

export const toRawApiOrderRow = ({
  quantity,
  unitPrice,
  arrivalQuantity,
  arrivalTotal,
  ...rest
}: APIOrderRow) => ({
  ...rest,
  quantity: quantity?.toString() ?? null,
  unitPrice: unitPrice?.toString() ?? null,
  arrivalQuantity: arrivalQuantity.toString(),
  arrivalTotal: arrivalTotal.toString(),
});

export const getOrderRowsByTopicId =
  (topicId: string) =>
  (appState: AppState): APIOrderRow[] => {
    const topic = getTopic(topicId)(appState);
    const orderRowIds = topic?.orderRowIds ?? [];

    const orderRows = orderRowIds
      .map((id) => getOrderRowById(id)(appState))
      .filter(isDefined);

    const sortedOrderRows = orderBy(
      orderRows,
      ['rowNumber', 'id'],
      ['asc', 'asc']
    );

    return sortedOrderRows;
  };

export const getOrderRowsByOrderId =
  (orderId: string) =>
  ({ orderRows: { data } }: AppState) =>
    pickBy(data, (row) => !!row && row.orderId === orderId);

export function getOrderRowsDeleteRequest(
  requestId: string
): Selector<remoteData.RemoteAction> {
  return ({
    orderRows: {
      deleteRequests: { [requestId]: request },
    },
  }) => request ?? remoteData.notAsked;
}

export const getOrderRowsDeleteMultipleRequest = (
  requestId: string
): Selector<remoteData.RemoteAction> => {
  return ({
    orderRows: {
      deleteRequests: { [requestId]: request },
    },
  }) => request ?? remoteData.notAsked;
};

export const getOrderRowReceivedTotalByTopicId =
  (topicId: string) =>
  (appState: AppState): Big => {
    const orderRows = getOrderRowsByTopicId(topicId)(appState);

    return big.sum(...orderRows.map((o) => o.arrivalTotal));
  };

export const getOrderRowTotalByTopicId =
  (topicId: string) =>
  (appState: AppState): Big => {
    const orderRows = getOrderRowsByTopicId(topicId)(appState);

    const orderRowTotals = orderRows.map((o) => {
      const quantity = o.quantity ?? new Big(0);
      const unitPrice = o.unitPrice ?? new Big(0);

      return quantity.mul(unitPrice);
    });

    return big.sum(...orderRowTotals);
  };

export const getAnalysisOrderRowsSum =
  (analysisRowId: string) =>
  (appState: AppState): Big => {
    const orderRows = getOrderRowsForAnalysisRow(analysisRowId)(appState);

    const orderRowTotals = remoteData.withDefault(orderRows, []).map((o) => {
      const quantity = o.quantity ?? new Big(0);
      const unitPrice = o.unitPrice ?? new Big(0);

      return quantity.mul(unitPrice);
    });

    return big.sum(...orderRowTotals);
  };

type OrderRowTotalsParams = {
  orderId: string;
  workPackageId: string;
};

type OrderRowTotals = {
  changeOrdersTotal: Big;
  targetTotal: Big;
  additionalTargetTotal: Big;
  contractTotal: Big;
  reservesTotal: Big;
  predictionTotal: Big;
  predictionChangeFromLatest: Big;
  receivedTotal: Big;
};

export const getOrderRowTotalsByWorkpackageId =
  ({ orderId, workPackageId }: OrderRowTotalsParams) =>
  (appState: AppState): OrderRowTotals => {
    const topics = Object.values(getTopicsByOrderId(orderId)(appState))
      .filter(isDefined)
      .filter((topic) => topic.workPackageId === workPackageId);

    const changeOrdersTotal = topics.reduce(
      (acc, val) => acc.add(val.changeOrdersTotal),
      new Big(0)
    );

    const reservesTotal = topics.reduce(
      (acc, val) => acc.add(val.reservesTotal),
      new Big(0)
    );

    const targetTotal = topics.reduce(
      (acc, val) => acc.add(val.targetTotal),
      new Big(0)
    );

    const additionalTargetTotal = topics.reduce(
      (acc, val) => acc.add(val.additionalTargetTotal),
      new Big(0)
    );

    const contractTotal = topics.reduce(
      (acc, val) => acc.add(val.contractTotal),
      new Big(0)
    );

    const predictionTotalSums = topics.map((topic) =>
      getOrderRowTotalByTopicId(topic.id)(appState)
    );

    const predictionChangeFromLatest = topics.reduce(
      (acc, val) => acc.add(val.predictionChangeFromLatest),
      new Big(0)
    );

    const receivedTotalSums = topics.map((topic) =>
      getOrderRowReceivedTotalByTopicId(topic.id)(appState)
    );

    return {
      changeOrdersTotal,
      reservesTotal,
      targetTotal,
      additionalTargetTotal,
      contractTotal,
      predictionTotal: big.sum(...predictionTotalSums),
      predictionChangeFromLatest,
      receivedTotal: big.sum(...receivedTotalSums),
    };
  };

export const getOrderRowIdsByTopicId =
  (topicId: string) =>
  (appState: AppState): string[] => {
    const topic = getTopic(topicId)(appState);
    const orderRowIds = topic?.orderRowIds ?? [];

    return orderRowIds.filter(isDefined);
  };

export const getOrderRowsByIds =
  (ids: string[], orderId: string) => (appState: AppState) => {
    const allOrderRowsByOrderId = getOrderRows({ orderId })(appState);

    const rowsMatchingIds = remoteData.withDefault(allOrderRowsByOrderId, []);

    return rowsMatchingIds.filter((row) => ids.includes(row.id));
  };

export const getTargetRowIdsByOrderRowIds =
  (ids: string[], orderId: string) => (appState: AppState) => {
    const orderRows = getOrderRowsByIds(ids, orderId)(appState);

    const initialValue: string[] = [];

    const targetRows = orderRows.reduce((previous, row) => {
      const { targetRowIds } = row;

      return previous.concat(targetRowIds);
    }, initialValue);

    const uniqueTargetRowIds = [...new Set(targetRows)];

    return uniqueTargetRowIds;
  };

export default orderRowReducer;
