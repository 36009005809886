import { combineReducers } from 'redux';

import * as remoteData from '../../../utils/remoteData';
import { Selector } from '../utils';

import groupsReducer from './group';
import possibleOrderRowLinksReducer from './possibleOrderRowLinks';
import possibleRevenueLinksReducer from './possibleRevenueLinks';
import possibleTargetRowLinksReducer from './possibleTargetRowLinks';
import {
  rowsReducer,
  rowUpdateReducer,
  toAnalysisRows,
  getAnalysesCsvReducer,
} from './row';
import selectListItemReducer from './selectListItem';
import sortOrderReducer from './sortOrders';
import { AnalysisRow } from '../../actions/analysis';

export default combineReducers({
  groups: groupsReducer,
  rows: rowsReducer,
  selectListItems: selectListItemReducer,
  possibleOrderRowLinks: possibleOrderRowLinksReducer,
  possibleTargetRowLinks: possibleTargetRowLinksReducer,
  possibleRevenueLinks: possibleRevenueLinksReducer,
  updateRequests: rowUpdateReducer,
  sortOrders: sortOrderReducer,
  getCsvRequests: getAnalysesCsvReducer,
});

type SelectableAnalysisRowsId = {
  projectId: string;
  revenueId: string;
};

export const getSelectableAnalysisRowsForRevenue: (
  ids: SelectableAnalysisRowsId
) => Selector<remoteData.RemoteData<AnalysisRow[], unknown>> =
  ({ projectId, revenueId }) =>
  ({
    analysis: {
      rows: { [projectId]: remoteRows = remoteData.notAsked },
      possibleRevenueLinks: { [revenueId]: remoteIds = remoteData.notAsked },
    },
  }) => {
    return remoteData.map(
      remoteData.append(remoteRows, remoteIds),
      ([rows, possibleRowIds]) => {
        const analysisRows = toAnalysisRows(rows);

        return analysisRows.filter(({ id }) => possibleRowIds.includes(id));
      }
    );
  };

type SelectableAnalysisRowsForOrderRowId = {
  projectId: string;
  orderRowId?: string;
  targetRowId?: string;
};

export const getSelectableAnalysisRowsForOrderOrTargetRow: (
  ids: SelectableAnalysisRowsForOrderRowId
) => Selector<remoteData.RemoteData<AnalysisRow[], unknown>> =
  ({ projectId, orderRowId, targetRowId }) =>
  ({
    analysis: {
      rows: { [projectId]: remoteRows = remoteData.notAsked },
      possibleOrderRowLinks,
      possibleTargetRowLinks,
    },
  }) => {
    const remoteIdsForOrderRow = orderRowId
      ? possibleOrderRowLinks[orderRowId] || remoteData.notAsked
      : remoteData.succeed([]);

    const remoteIdsForTargetRow = targetRowId
      ? possibleTargetRowLinks[targetRowId] || remoteData.notAsked
      : remoteData.succeed([]);

    const remoteIds = orderRowId ? remoteIdsForOrderRow : remoteIdsForTargetRow;

    return remoteData.map(
      remoteData.append(remoteRows, remoteIds),
      ([rows, possibleRowIds]) => {
        const analysisRows = toAnalysisRows(rows);

        return analysisRows.filter(({ id }) => possibleRowIds.includes(id));
      }
    );
  };
