import React from 'react';

import styled from 'styled-components';
import { v1 as uuid } from 'uuid';

import Cell from '../../../../../../components/Cell';
import {
  Table,
  TableHeader,
  SecondaryRow,
  HeaderTh,
} from '../../../../../../components/Table';
import Txt from '../../../../../../components/Txt';

import { ParsedResultRow } from './utils';
import * as big from '../../../../../../utils/big';

import { ErrorText, InvalidityIndicator } from './Components';

type Props = {
  rows: ParsedResultRow[];
};

const PreviewTable = ({ rows }: Props) => (
  <Table>
    <TableHeader>
      <tr>
        <HeaderTh width="2.5rem">
          <Txt id="common.row" />
        </HeaderTh>
        <HeaderTh width="auto">
          <Txt id="common.description" />
        </HeaderTh>
        <HeaderTh width="8rem" textAlign="right">
          <Txt id="common.quantity" />
        </HeaderTh>
        <HeaderTh width="8rem">
          <Txt id="common.unit.short" />
        </HeaderTh>
        <HeaderTh width="10rem" textAlign="right">
          <Txt id="common.unitPrice" />
        </HeaderTh>
        <HeaderTh width="10rem" textAlign="right">
          <Txt id="common.amount" />
        </HeaderTh>
      </tr>
    </TableHeader>
    <tbody>
      {rows.map((row, rowNumber) => {
        // The rows don't have proper ID's yet and using index as an key would
        // cause issues. Let's generate key with UUID as we don't except the
        // input to be edited manually in the text area, thus it should not
        // affect performance too much.
        const rowKey = `preview-row-${uuid()}`;

        if (row === null) {
          return (
            <SecondaryRow key={rowKey} data-testid={`row-${rowNumber}`}>
              <Cell>{rowNumber + 1}</Cell>
              <Cell colSpan={5}>
                <ErrorText>
                  <Txt id="order.excelImport.previewTable.error" />
                </ErrorText>
              </Cell>
            </SecondaryRow>
          );
        }

        const quantityAsBig = big.fromInputString(row.quantity.value, 0);
        const unitPriceAsBig = big.fromInputString(row.unitPrice.value, 0);

        const displayQuantity =
          row.quantity.value !== '' && row.quantity.validationError === null;

        const displayUnitPrice =
          row.unitPrice.value !== '' && row.unitPrice.validationError === null;

        return (
          <SecondaryRow key={rowKey} data-testid={`row-${rowNumber}`}>
            <Cell>{rowNumber + 1}</Cell>
            <Cell>
              <Text>{row.description.value}</Text>
              <InvalidityIndicator field={row.description} />
            </Cell>

            <Cell align="right">
              {displayQuantity ? big.amountFormat(quantityAsBig) : '-'}
              <InvalidityIndicator field={row.quantity} showValue />
            </Cell>

            <Cell>
              <Text>{row.unit.value}</Text>
              <InvalidityIndicator field={row.unit} />
            </Cell>

            <Cell align="right">
              {displayUnitPrice ? big.priceFormat(unitPriceAsBig, 4) : '- €'}
              <InvalidityIndicator field={row.unitPrice} showValue />
            </Cell>
            <Cell align="right">
              {displayQuantity && displayUnitPrice
                ? big.priceFormat(quantityAsBig.mul(unitPriceAsBig))
                : '- €'}
            </Cell>
          </SecondaryRow>
        );
      })}
    </tbody>
  </Table>
);

const Text = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export default PreviewTable;
