import React from 'react';

import styled from 'styled-components';

import { IconTextButton, IconTextButtonProps } from '../Buttons';
import Txt, { TxtProps } from '../Txt';

type ButtonProps = IconTextButtonProps & {
  isActive: boolean;
};

const Button = styled(IconTextButton)<ButtonProps>`
  border-bottom: ${({ theme, isActive }) =>
      isActive ? theme.color.darkpurple : 'transparent'}
    3px solid;
  padding: ${({ theme }) => `${theme.margin[10]} ${theme.margin[24]}`};
  height: 14px;
  box-sizing: content-box;

  :hover {
    border-color: ${(props) => props.theme.color.graphiteB76};
  }

  :disabled {
    border-color: transparent;
    color: ${(props) => props.theme.color.buttonPrimaryDisabled};
    cursor: initial;
  }
`;

type TabIconTextButtonProps = ButtonProps &
  TxtProps &
  React.ButtonHTMLAttributes<HTMLButtonElement>;

const TabIconTextButton: React.FC<TabIconTextButtonProps> = ({
  id,
  values,
  component,

  children,
  ...props
}) => (
  <Button {...props}>
    <Txt id={id} values={values} component={component} />
    {children}
  </Button>
);

export const StickyTabIconContainer = styled.div`
  position: sticky;
  top: 0;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  background: white;

  line-height: ${(props) => props.theme.margin[32]};

  z-index: 2;
`;

export default TabIconTextButton;
