import React from 'react';
import { useSelector } from 'react-redux';

import {
  getIsOuterBarOpen,
  getSavedReceiveAmountStateForOrder,
} from '../../../store/reducers/ui';

import { ViewModeOptions } from '../../../types/general';

import useRouteParams from '../../../hooks/useRouteParams';
import useTxt from '../../../hooks/useTxt';
import useChangeViewMode from '../hooks/useChangeViewMode';

import { PrimaryButton } from '../../../components/Buttons';
import {
  OrderViewContent,
  OrderViewFooter,
  OrderViewEmptyDiv,
} from '../../../components/Containers';
import { TableSeparateBorderCollapse } from '../../../components/Table';
import { OrderTableHeader } from '../components/OrderTableHeader';
import Summary from '../components/Summary';
import Topics from '../components/Topics';

const NormalModeWrapper = () => {
  const { projectId, orderId, showTargetRows } = useRouteParams();
  const outerBarOpen = useSelector(getIsOuterBarOpen());

  const continueReseption = useTxt('order.mainButtons.continueReception');
  const startReception = useTxt('order.mainButtons.startReception');

  const tableRef = React.useRef<HTMLTableElement>(null);

  const hasReceiveInProgress = !!useSelector(
    getSavedReceiveAmountStateForOrder(orderId)
  );

  const viewModeOptions: ViewModeOptions = {
    type: 'normal',
    showTargetRows,
  };

  const changeViewMode = useChangeViewMode();
  const changeViewModeToReceive = () => changeViewMode('receive');

  return (
    <>
      <OrderViewContent outerBarOpen={outerBarOpen}>
        <Summary />
        <TableSeparateBorderCollapse ref={tableRef}>
          <OrderTableHeader orderViewMode={viewModeOptions} orderId={orderId} />
          <Topics
            projectId={projectId}
            orderId={orderId}
            viewModeOptions={viewModeOptions}
            ref={tableRef}
          />
        </TableSeparateBorderCollapse>
        <OrderViewEmptyDiv />
      </OrderViewContent>
      <OrderViewFooter>
        <PrimaryButton onClick={changeViewModeToReceive}>
          {hasReceiveInProgress ? continueReseption : startReception}
        </PrimaryButton>
      </OrderViewFooter>
    </>
  );
};

export default NormalModeWrapper;
