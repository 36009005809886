import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useMeasure from 'react-use-measure';

import styled from 'styled-components';

import {
  getWorkSectionClasses,
  getWorkPackages,
  getTeams,
  getVirtualNamespaces,
  getWorkPackageId,
  parseWorkPackageId,
  isLoading,
} from '../../../store/reducers/schedule/nextgenScheduleData';
import { getActiveProjectId } from '../../../store/reducers/ui';
import { getProjectWorkPackages } from '../../../store/reducers/workPackage';

import { fetchWorkPackagesForProject } from '../../../store/actions';
import { fetchNextgenScheduleDataForProject } from '../../../store/actions/schedule/nextgenScheduleData';

import { APIWorkPackage } from '../../../types/api';

import useDeepCompareMemo from '../../../hooks/useDeepCompareMemo';
import { useRemoteDataWithDeepCompare } from '../../../hooks/useRemoteData';
import useTxt from '../../../hooks/useTxt';

import { IconButton, PrimaryButton } from '../../../components/Buttons';
import { Spinner } from '../../../components/Loading';
import ToolTip from '../../../components/Tooltip';
import Txt from '../../../components/Txt';

import {
  getScheduleTreeTableData,
  getWorkPackageWithChildren,
  mapFinanceWorkSections,
  mapWorkSectionClasses,
  processWorkPackagesWithParents,
} from './utils';
import { areStringifiedArraysSame } from '../../../utils/general';
import { isClickOrKeyboardSelection } from '../../../utils/mouseOrKeyInteraction';

import { IconExclamationMark, IconRefresh } from '../../../assets/svg';

import LinkWorkPackagesModal from './LinkWorkPackagesModal';
import {
  StyledInfoSpan,
  StyledPoCContainer,
  StyledPocValue,
} from './NewEditWorkPackageForm';
import ScheduleTreeTable from './ScheduleTreeTable';
import WorkSectionClassDropdown from './WorkSectionClassDropdown';

type NewScheduleTreeProps = {
  workPackage: APIWorkPackage;
  setScheduleTreeSelection: React.Dispatch<
    React.SetStateAction<{
      workSectionClassId?: string | null | undefined;
      workPackageVirtualSpaceLinkages?: {
        nextgenWorkPackageId: string | null;
        virtualSpaceId: string | null;
      }[];
    }>
  >;
  scheduleTreeSelection: {
    workSectionClassId?: string | null | undefined;
    workPackageVirtualSpaceLinkages?: {
      nextgenWorkPackageId: string | null;
      virtualSpaceId: string | null;
    }[];
  };
};

const NewScheduleTree = ({
  workPackage,
  setScheduleTreeSelection,
  scheduleTreeSelection,
}: NewScheduleTreeProps) => {
  const projectId = useSelector(getActiveProjectId) ?? '';

  const dispatch = useDispatch();

  const isDataLoading = useSelector(isLoading(projectId));

  const [pocValue, setPoCValue] = React.useState<number>(0);

  const headerInfo = {
    code: workPackage.code,
    name: workPackage.name,
  };

  const [linkWorkpackagesModalOpen, setLinkWorkPackagesModalOpen] =
    React.useState<boolean>(false);

  const setSelectedWorkSectionClass = (worksection: string) => {
    setScheduleTreeSelection({
      ...scheduleTreeSelection,
      workSectionClassId: worksection === 'NONE' ? null : worksection,
    });
  };
  const selectedWorkSectionClass = scheduleTreeSelection.workSectionClassId;

  const projectWorkPackages = useRemoteDataWithDeepCompare(
    getProjectWorkPackages(projectId),
    fetchWorkPackagesForProject(projectId),
    true,
    areStringifiedArraysSame
  );

  const manualLinkedWorkPackages = React.useMemo(
    () =>
      scheduleTreeSelection.workPackageVirtualSpaceLinkages?.map((wp) =>
        getWorkPackageId({
          id: wp.nextgenWorkPackageId ?? '',
          virtual_space_id: wp.virtualSpaceId,
        })
      ) ?? [],
    [scheduleTreeSelection.workPackageVirtualSpaceLinkages]
  );

  const setManualLinkedWorkPackages = React.useCallback(
    (
      workPackages: {
        nextgenWorkPackageId: string | null;
        virtualSpaceId: string | null;
      }[]
    ) => {
      setScheduleTreeSelection({
        ...scheduleTreeSelection,
        workPackageVirtualSpaceLinkages: workPackages,
      });
    },
    [scheduleTreeSelection, setScheduleTreeSelection]
  );

  const selectedWorkSectionClassWithoutNone =
    selectedWorkSectionClass === 'NONE' ? null : selectedWorkSectionClass;

  const refreshToolTipText = useTxt('worksection.workpackage.refreshToolTip');

  const [measureRef, measures] = useMeasure({ debounce: 100 });

  // force refetch data from backend on every modal open
  React.useEffect(() => {
    dispatch(fetchNextgenScheduleDataForProject({ projectId }));
  }, [projectId, dispatch]);

  const workSectionClasses = useDeepCompareMemo(
    useSelector(getWorkSectionClasses(projectId), areStringifiedArraysSame),
    areStringifiedArraysSame
  );

  const workPackages = useDeepCompareMemo(
    useSelector(getWorkPackages(projectId), areStringifiedArraysSame),
    areStringifiedArraysSame
  );

  const virtualSpaces = useDeepCompareMemo(
    useSelector(getVirtualNamespaces(projectId), areStringifiedArraysSame),
    areStringifiedArraysSame
  );

  const teams = useDeepCompareMemo(
    useSelector(getTeams(projectId), areStringifiedArraysSame),
    areStringifiedArraysSame
  );

  const workPackagesWithParents = useMemo(() => {
    return processWorkPackagesWithParents(
      workPackages,
      teams,
      virtualSpaces,
      workSectionClasses
    );
  }, [teams, workPackages, virtualSpaces, workSectionClasses]);

  // force refetch data from backend on every modal open
  React.useEffect(() => {
    dispatch(fetchNextgenScheduleDataForProject({ projectId }));
  }, [projectId, dispatch]);

  const mappedWorkSectionClasses = useMemo(() => {
    return mapWorkSectionClasses(workSectionClasses);
  }, [workSectionClasses]);

  const selectedWorkSectionClasses = useMemo(
    () =>
      selectedWorkSectionClassWithoutNone
        ? mappedWorkSectionClasses
            .filter(
              (data) =>
                data.parents.includes(selectedWorkSectionClassWithoutNone) ||
                data.id === selectedWorkSectionClassWithoutNone
            )
            .map((data) => data.id)
        : [],
    [mappedWorkSectionClasses, selectedWorkSectionClassWithoutNone]
  );

  const mappedOtherFinanceWorkSections = useMemo(() => {
    const otherFinanceWorkSections =
      projectWorkPackages?.filter((wp) => wp.id !== workPackage.id) ?? [];

    return mapFinanceWorkSections(
      otherFinanceWorkSections,
      mappedWorkSectionClasses,
      workPackagesWithParents
    );
  }, [
    mappedWorkSectionClasses,
    workPackagesWithParents,
    projectWorkPackages,
    workPackage,
  ]);

  const filteredWorkPackagesWithChildren = useMemo(() => {
    return getWorkPackageWithChildren(
      selectedWorkSectionClasses,
      manualLinkedWorkPackages,
      workPackagesWithParents,
      false
    );
  }, [
    manualLinkedWorkPackages,
    selectedWorkSectionClasses,
    workPackagesWithParents,
  ]);

  const filteredWorkPackagesWithParents = useMemo(() => {
    return getWorkPackageWithChildren(
      selectedWorkSectionClasses,
      manualLinkedWorkPackages,
      workPackagesWithParents,
      true
    );
  }, [
    manualLinkedWorkPackages,
    selectedWorkSectionClasses,
    workPackagesWithParents,
  ]);

  const filteredWorkPackagesWithChildrenWithoutManualIds = useMemo(() => {
    return getWorkPackageWithChildren(
      selectedWorkSectionClasses,
      [],
      workPackagesWithParents,
      false
    ).map((wp) => wp.id);
  }, [selectedWorkSectionClasses, workPackagesWithParents]);

  const scheduleTreeTableData = useMemo(
    () =>
      getScheduleTreeTableData(
        filteredWorkPackagesWithParents,
        filteredWorkPackagesWithChildren,
        filteredWorkPackagesWithChildrenWithoutManualIds,
        mappedOtherFinanceWorkSections
      ),
    [
      filteredWorkPackagesWithChildren,
      filteredWorkPackagesWithChildrenWithoutManualIds,
      filteredWorkPackagesWithParents,
      mappedOtherFinanceWorkSections,
    ]
  );

  const allDataAsTree = useMemo(
    () =>
      getScheduleTreeTableData(
        workPackagesWithParents,
        undefined,
        filteredWorkPackagesWithChildrenWithoutManualIds,
        []
      ),
    [filteredWorkPackagesWithChildrenWithoutManualIds, workPackagesWithParents]
  );

  const otherFinanceWorkPackagesRelatedScheduleWorkPackageIds = [
    ...new Set(
      mappedOtherFinanceWorkSections
        .map((wp) => wp.scheduleWorkPackageIds)
        .flat()
    ),
  ];

  React.useEffect(() => {
    if (filteredWorkPackagesWithChildren.length !== 0) {
      const childInstancesOnly = filteredWorkPackagesWithChildren.filter(
        (wp) => wp.lowest
      );

      const totalProgress = childInstancesOnly.reduce(
        (acc, task) => {
          const progress =
            (task.progress_percentage / 100) *
            (task.cur_dur ?? task.s_high - task.s_low);
          const total = task.cur_dur ?? task.s_high - task.s_low;

          return {
            progress: acc.progress + progress,
            total: acc.total + total,
          };
        },
        {
          progress: 0,
          total: 0,
        }
      );

      const poc =
        totalProgress.total !== 0
          ? (totalProgress.progress / totalProgress.total) * 100
          : 0;

      setPoCValue(poc);
    }
  }, [filteredWorkPackagesWithChildren, setPoCValue]);

  const overlappingLinkagesExist = filteredWorkPackagesWithChildren
    .map((e) => e.id)
    .some((e) =>
      otherFinanceWorkPackagesRelatedScheduleWorkPackageIds.includes(e)
    );

  const setUnParsedManualLinkedWorkPackages = (ids: string[]) => {
    const parsedIds = ids.map(parseWorkPackageId).map((wp) => ({
      nextgenWorkPackageId: wp.id,
      virtualSpaceId: wp.virtual_space_id,
    }));

    setManualLinkedWorkPackages(parsedIds);
  };

  const onRefreshButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (isClickOrKeyboardSelection(e)) {
      e.preventDefault();
      dispatch(fetchNextgenScheduleDataForProject({ projectId }));
    }
  };

  const deSelectWorkPackage = React.useCallback(
    (id: string) => {
      const childrenIds = workPackagesWithParents
        .filter((wp) => wp.parents.includes(id))
        .map((wp) => wp.id);

      const parentIds = workPackagesWithParents
        .filter((wp) => wp.id === id)
        .map((wp) => wp.parents)
        .flat();

      const uniqueParentIds = [...new Set(parentIds)];

      const allIds = [...childrenIds, id, ...uniqueParentIds];

      const newSelection = manualLinkedWorkPackages
        .filter((wp) => {
          return !allIds.includes(wp);
        })
        .map(parseWorkPackageId)
        .map((wp) => ({
          nextgenWorkPackageId: wp.id,
          virtualSpaceId: wp.virtual_space_id,
        }));

      setManualLinkedWorkPackages(newSelection);
    },
    [
      manualLinkedWorkPackages,
      setManualLinkedWorkPackages,
      workPackagesWithParents,
    ]
  );

  /**
   * TODO: This is a hack, but with current version of @tanstack/react-virtual the useVirtualizer hook
   * produces error when the parent element (scrollElement) size is changed.
   *
   * Error only shows itself to developers and does not affect the end user experience.
   *
   * Future versions of the library
   * have enabled-option which might solve the issue. Other option would be to copy table state to higher
   * component and re-render the table out of scratch when the size changes.
   */

  window.addEventListener('error', (e) => {
    if (
      e.message === 'ResizeObserver loop limit exceeded' ||
      e.message ===
        'ResizeObserver loop completed with undelivered notifications.'
    ) {
      const resizeObserverErrDiv = document.getElementById(
        'webpack-dev-server-client-overlay-div'
      );

      const resizeObserverErr = document.getElementById(
        'webpack-dev-server-client-overlay'
      );

      if (resizeObserverErr) {
        resizeObserverErr.setAttribute('style', 'display: none');
      }

      if (resizeObserverErrDiv) {
        resizeObserverErrDiv.setAttribute('style', 'display: none');
      }
    }
  });

  return (
    <>
      <StyledSection smallerMargin>
        <StyledInfoSpan>
          <Txt id="worksection.workpackage.linkedSchedule.explanation" />
        </StyledInfoSpan>
        {isDataLoading ? (
          <StyledSpinner size="2rem" />
        ) : (
          <ToolTip tip={refreshToolTipText}>
            <StyledIconButton
              icon={IconRefresh}
              onClick={onRefreshButtonClick}
              customSize="2rem"
            />
          </ToolTip>
        )}
      </StyledSection>
      <StyledSection>
        <WorkSectionClassDropdown
          onSelectionChange={setSelectedWorkSectionClass}
          workSectionClassOptions={mappedWorkSectionClasses}
          initialValue={selectedWorkSectionClass}
        />
        <StyledPrimaryButton onClick={() => setLinkWorkPackagesModalOpen(true)}>
          <StyledButtonSpan>
            <Txt id="worksection.workpackage.linkToWorkpackages" />
          </StyledButtonSpan>
        </StyledPrimaryButton>
      </StyledSection>
      <ConnectedWorkPackagesContainer ref={measureRef}>
        <ScheduleTreeTable
          scheduleTreeSelection={scheduleTreeSelection}
          deSelectWorkPackage={deSelectWorkPackage}
          anyDataAvailable={workPackages.length !== 0}
          scheduleTreeTableData={scheduleTreeTableData}
          parentMeasures={measures}
        />
      </ConnectedWorkPackagesContainer>
      <StyledPoCContainer>
        <Txt id="worksection.workpackage.pocToday" />
        <StyledPocValue>{`${pocValue.toFixed(0)}%`}</StyledPocValue>
        {overlappingLinkagesExist ? (
          <>
            <ExistingIcon />
            <Txt id="worksection.workpackage.overlappingLinkages" />
          </>
        ) : null}
      </StyledPoCContainer>
      <EmptyDiv />
      {linkWorkpackagesModalOpen ? (
        <LinkWorkPackagesModal
          onClose={() => setLinkWorkPackagesModalOpen(false)}
          scheduleWorkPackages={allDataAsTree}
          headerInfo={headerInfo}
          manualLinkedWorkPackages={manualLinkedWorkPackages}
          selectedBasedOnWorkSection={
            filteredWorkPackagesWithChildrenWithoutManualIds
          }
          setLinkedWorkPackages={setUnParsedManualLinkedWorkPackages}
        />
      ) : null}
    </>
  );
};

export default NewScheduleTree;

const StyledIconButton = styled(IconButton)`
  margin: 0 ${(props) => props.theme.margin[8]};
`;

const StyledSpinner = styled(Spinner)`
  margin: 0 ${(props) => props.theme.margin[8]};
`;

const StyledSection = styled.div<{ smallerMargin?: boolean }>`
  margin-top: ${(props) =>
    props.smallerMargin ? props.theme.margin[8] : props.theme.margin[16]};

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const StyledButtonSpan = styled.span`
  width: 100%;

  display: inline-block;

  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: ${(props) => props.theme.fontSize.small};

  overflow: hidden;
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  width: 16rem;

  align-self: flex-end;

  background-color: ${(props) =>
    props.theme.color['M3/sys/light/secondary-container']};

  color: ${(props) => props.theme.color['M3/sys/light/on-secondary-container']};
  font-size: ${(props) => props.theme.fontSize.textButton};
  font-weight: 700;
`;

const ConnectedWorkPackagesContainer = styled.div`
  position: relative;
  margin-top: ${(props) => props.theme.margin[16]};
  flex-grow: 1;
  font-size: 0.8rem;
`;

const EmptyDiv = styled.div`
  height: 4.5rem;
`;

export const ExpandCollapseButton = styled(IconButton)`
  /** give user some room for error click*/
  padding: 4px;
  width: 20px;
  height: 20px;
`;

const ExistingIcon = styled(IconExclamationMark)`
  margin: 0 ${(props) => props.theme.margin[8]} 0;
  width: ${(props) => props.theme.sizes.iconButtonSmall};
  height: ${(props) => props.theme.sizes.iconButtonSmall}
    ${(props) => props.theme.margin[16]};
  fill: ${(props) => props.theme.color.orange};
`;
