import React from 'react';

import { flexRender, HeaderContext, Table } from '@tanstack/react-table';
import { Updater } from '@tanstack/react-table';
import { ColumnDefTemplate } from '@tanstack/react-table';
import styled from 'styled-components';

import useTxt from '../../../../hooks/useTxt';

import { IconButton } from '../../../../components/Buttons';
import { GridCellTh } from '../../../../components/Cell';
import SearchInput from '../../../../components/Input/SearchInput';
import Txt from '../../../../components/Txt';

import { UpdateProcurementRow } from './utils';
import { useDebouncedValue } from '../../../../utils/hooks';

import {
  IconUp,
  IconDown,
  IconDoubleDown,
  IconDoubleRight,
} from '../../../../assets/svg';

import { StyledCheckbox } from '../TargetTable/TableCells';

type ThProps = {
  width: number;
  align: 'left' | 'center' | 'right' | undefined;
  borderWidthRight?: number;
  headerDef:
    | ColumnDefTemplate<HeaderContext<UpdateProcurementRow, unknown>>
    | undefined;
  context: HeaderContext<UpdateProcurementRow, unknown>;
};

const Th = ({
  width,
  align,
  borderWidthRight,
  headerDef,
  context,
}: ThProps) => {
  return (
    <StyledTh width={width} align={align} borderWidthRight={borderWidthRight}>
      {flexRender(headerDef, context)}
    </StyledTh>
  );
};

type ThCellProps = {
  width: number;
  borderWidthRight?: number;
};

const StyledTh = styled(GridCellTh)<ThCellProps>`
  border-right: ${({ borderWidthRight }) => borderWidthRight ?? 0}px solid
    ${(props) => props.theme.color.rowBorder};
  width: ${({ width }) => width}px;
  height: 48px;
  font-size: ${({ theme }) => theme.fontSize.h2};
`;

export default Th;

type CustomThProps = {
  table: Table<UpdateProcurementRow>;
};

type SearchInputThProps = {
  globalFilter: string;
  setGlobalFilter: (updater: Updater<any>) => void;
};

export const TargetTableSearchInput = React.memo(
  ({ globalFilter, setGlobalFilter }: SearchInputThProps) => {
    const inputRef = React.useRef<HTMLInputElement>(null);
    const value = globalFilter;

    React.useLayoutEffect(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, []);

    const [filterValue, setFilterValue] = React.useState(value);

    const debouncedValue = useDebouncedValue(filterValue, 500);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setFilterValue(e.target.value);
    };

    React.useEffect(() => {
      if (debouncedValue !== value) {
        setGlobalFilter(debouncedValue);
      }
    }, [debouncedValue, setGlobalFilter, value]);

    return (
      <SearchDiv>
        <SearchInput
          name="updateProcurementsModalSearch"
          onChange={onChange}
          value={filterValue}
          handleClearButtonClick={() => {
            setFilterValue('');
            setGlobalFilter('');
          }}
          noMargin
          ref={inputRef}
        />
      </SearchDiv>
    );
  },
  (prevProps, nextProps) => {
    return prevProps.globalFilter === nextProps.globalFilter;
  }
);

const SearchDiv = styled.div`
  margin: ${(props) => props.theme.margin[4]};
`;

const textIdHead = 'target.table.header.';

export const CodeTh = ({ table }: CustomThProps) => {
  const expandedState = table.getState().expanded;
  const allOpenText = useTxt('target.table.hierarchies.open');
  const allClosedText = useTxt('target.table.hierarchies.closed');
  const openLevelText = useTxt('target.table.hierarchies.openLevel');
  const closeLevelText = useTxt('target.table.hierarchies.closeLevel');

  const tableToggleAll = table.getToggleAllRowsExpandedHandler();

  const allExpanded = table.getIsAllRowsExpanded();

  const { flatRows } = table.getExpandedRowModel();

  const mappedFlatRows = new Map(flatRows.map((row) => [row.id, row]));

  const maxDepth = Math.max(...flatRows.map((row) => row.depth));

  let openDepth = -1;

  if (expandedState === true) {
    openDepth = maxDepth;
  } else {
    const expandedStateEntries = Object.entries(expandedState);

    for (let i = 0; i < expandedStateEntries.length; i++) {
      const entry = expandedStateEntries[i];
      const entryId = entry[0];
      const entryOpen = entry[1];

      if (entryOpen === true) {
        const rowDepth = mappedFlatRows.get(entryId)?.depth ?? -1;
        openDepth = Math.max(openDepth, rowDepth);
      }
    }
  }

  const toggleLevels = (
    e: React.MouseEvent<HTMLButtonElement>,
    depthChange: number
  ) => {
    let newDepth = openDepth + depthChange;

    // targetRows don't have subrows
    if (newDepth === maxDepth - 1 && depthChange === 1) {
      return tableToggleAll(e);
    }

    if (newDepth === maxDepth - 1 && depthChange === -1) {
      newDepth = newDepth - 1;
    }

    const newState = flatRows
      .filter((row) => row.depth <= newDepth)
      .reduce(
        (acc, row) => {
          acc[row.id] = true;

          return acc;
        },
        {} as Record<string, boolean>
      );

    table.setExpanded(newState);
  };

  const toggleAll = (e: React.MouseEvent<HTMLButtonElement>) => {
    tableToggleAll(e);
  };

  const isSomeRowsSelected = () => {
    const selectionState = table.getState().rowSelection;

    return Object.values(selectionState).some(
      (isSelected) => isSelected === true
    );
  };

  return (
    <StyledToggleLevelDiv>
      <StyledCheckbox
        indeterminate={isSomeRowsSelected()}
        checked={table.getIsAllRowsSelected()}
        onChange={table.getToggleAllRowsSelectedHandler()}
      />
      <StyledIconButton
        icon={table.getIsAllRowsExpanded() ? IconDoubleDown : IconDoubleRight}
        onClick={toggleAll}
        aria-label={table.getIsAllRowsExpanded() ? allOpenText : allClosedText}
      />
      <StyledIconButton
        icon={IconDown}
        onClick={(e) => toggleLevels(e, 1)}
        disabled={allExpanded}
        aria-label={openLevelText}
      />
      <StyledIconButton
        icon={IconUp}
        onClick={(e) => toggleLevels(e, -1)}
        disabled={openDepth === -1}
        aria-label={closeLevelText}
      />
      <Txt id={`${textIdHead}referenceNo` as const} component="b" />
    </StyledToggleLevelDiv>
  );
};

const StyledIconButton = styled(IconButton)`
  margin-right: ${(props) => props.theme.margin[4]};
`;

const StyledToggleLevelDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
