import React from 'react';

import { Row, Header } from '@tanstack/react-table';
import Big from 'big.js';
import styled from 'styled-components';

import useTxt from '../../../../hooks/useTxt';
import { TargetRowOrTargetRowHierarchyEntry } from '../../hooks/useTargetViewData';

import { BigPriceValue } from '../../../../components/BigValue';
import { Summary } from '../../../../components/Table/SelectionSummary';

type SelectionSummaryProps = {
  data: Row<TargetRowOrTargetRowHierarchyEntry>[];
  selectionState: Record<string, boolean>;
  tableHeaders: Header<TargetRowOrTargetRowHierarchyEntry, unknown>[];
  containerWidth: number;
};

const SelectionSummary = ({
  data,
  selectionState,
  tableHeaders,
  containerWidth,
}: SelectionSummaryProps) => {
  const targetTotalText = useTxt(
    'target.table.selectionSummary.selectionTotal'
  );

  const targetTotalNoOrderRowText = useTxt(
    'target.table.selectionSummary.selectionTotal.noOrderRow'
  );

  const tableHeadersTotalWidth = tableHeaders
    .map((h) => h.getSize())
    .reduce((a, b) => a + b, 0);

  const targetTotalHeaderIndex = tableHeaders.findIndex(
    (header) => header.id === 'target'
  );

  const selectionTotalPlacement = tableHeaders.reduce((acc, header, index) => {
    if (index <= targetTotalHeaderIndex) {
      const thWidth = Math.round(
        (header.getSize() / tableHeadersTotalWidth) * (containerWidth - 20)
      );

      return acc + thWidth;
    }

    return acc;
  }, 0);

  const selectedRows = data.filter((row) => selectionState[row.id] === true);

  const selectedRowsChildren = selectedRows
    .map((row) => row.getLeafRows())
    .flat();

  const selectedData = [...new Set([...selectedRowsChildren, ...selectedRows])]
    .filter((row) => row.original.type === 'targetRow')
    .reduce((acc, row) => {
      if (
        row.original.target &&
        row.original.isDeleted === false &&
        row.original.isDisabled === false &&
        row.original.isAntiRow === false
      ) {
        return acc.plus(row.original.target ?? 0);
      }

      return acc;
    }, new Big(0));

  const selectedDataNoOrderRow = [
    ...new Set([...selectedRowsChildren, ...selectedRows]),
  ]
    .filter(
      (row) =>
        row.original.type === 'targetRow' && row.original.orderRowId === null
    )
    .reduce((acc, row) => {
      if (
        row.original.target &&
        row.original.isDeleted === false &&
        row.original.isDisabled === false &&
        row.original.isAntiRow === false
      ) {
        return acc.plus(row.original.target ?? 0);
      }

      return acc;
    }, new Big(0));

  return (
    <Summary>
      <ColumnDiv width={selectionTotalPlacement}>
        <FlexColumn>
          <FlexRow>
            <StyledSpan>{`${targetTotalText}: `}</StyledSpan>
            <PriceDiv>
              <BigPriceValue value={selectedData} decimals={2} />
            </PriceDiv>
          </FlexRow>
          <FlexRow>
            <StyledSpan>{`${targetTotalNoOrderRowText}: `}</StyledSpan>
            <PriceDiv>
              <BigPriceValue value={selectedDataNoOrderRow} decimals={2} />
            </PriceDiv>
          </FlexRow>
        </FlexColumn>
      </ColumnDiv>
    </Summary>
  );
};

const ColumnDiv = styled.div<{ width?: number }>`
  width: ${({ width }) => width}px;

  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-end;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.margin[8]};
`;

const StyledSpan = styled.div`
  margin-right: ${({ theme }) => theme.margin[8]};
  text-align: left;
  font-size: ${({ theme }) => theme.fontSize.h2};
`;

const PriceDiv = styled.div`
  font-size: ${({ theme }) => theme.fontSize.h2};
  font-weight: bold;
`;

export default SelectionSummary;
