import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';

import {
  APIPurchaseInvoiceHeaderPutBody,
  RawAPIUpdatedEntities,
  APIUpdatedEntities,
} from '../../types/api';
import { ID } from '../../types/general';
import { mapRawUpdatedEntities, solveHandlingState } from '../../types/mappers';

import {
  makeApiActions,
  ExtractActionTypes,
  makeAction,
} from '../../utils/actionCreators';
import {
  GET,
  BackendError,
  apiErrorHandlingWithDecode,
  PUT,
} from '../../utils/api';
import { dateString, bigString } from '../../utils/decoders';
import { flow } from '../../utils/function';
import * as remoteData from '../../utils/remoteData';
import { createAsyncThunk, Thunk } from '../../utils/thunk';

import { SortableKey } from '../reducers/invoice/sortInvoiceHeaders';
import {
  InvoiceHeader,
  selectOrderInvoiceHeadersRequests,
  selectAnalysisRowInvoiceHeadersRequests,
  getInvoiceUpdateRequest,
} from '../reducers/invoiceHeader';

const actionCreators = {
  ...makeAction('getInvoiceHeadersStarted')<{ orderId: string }>(),
  ...makeAction('getInvoiceHeadersFailure')<{
    orderId: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('getInvoiceHeadersSuccess')<{
    orderId: string;
    invoiceHeaders: InvoiceHeader[];
  }>(),
  ...makeAction('putInvoiceHeaderStarted')<{ invoiceHeaderId: string }>(),
  ...makeAction('putInvoiceHeaderFailure')<{
    invoiceHeaderId: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('putInvoiceHeaderSuccess')<
    APIUpdatedEntities & { invoiceHeaderId: string }
  >(),
  ...makeApiActions('put', 'invoiceHeaderMove')<
    {
      invoiceHeaderId: string;
      orderId: ID;
    } & APIUpdatedEntities
  >(),
  ...makeApiActions('put', 'invoiceHeaderDecline')<APIUpdatedEntities>(),
  ...makeAction('putInvoiceHeaderComplaintClose')<{
    purchaseInvoiceHeaderId: string;
  }>(),
  ...makeAction('putInvoiceHeaderStartCorrectionStarted')<{
    purchaseInvoiceHeaderId: string;
  }>(),
  ...makeAction('putInvoiceHeaderStartCorrectionFailure')<{
    purchaseInvoiceHeaderId: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('putInvoiceHeaderStartCorrectionSuccess')<
    {
      purchaseInvoiceHeaderId: string;
    } & APIUpdatedEntities
  >(),
  ...makeAction('putInvoiceHeaderFinishCorrectionStarted')<{
    purchaseInvoiceHeaderId: string;
  }>(),
  ...makeAction('putInvoiceHeaderFinishCorrectionFailure')<{
    purchaseInvoiceHeaderId: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('putInvoiceHeaderFinishCorrectionSuccess')<
    {
      purchaseInvoiceHeaderId: string;
    } & APIUpdatedEntities
  >(),
  ...makeAction('toggleInvoiceHeaderSort')<{
    sortableKey: SortableKey;
    invoiceType: 'handled' | 'unhandled';
  }>(),
  ...makeApiActions('put', 'cancelInvoiceHeaderComplaint')<
    { purchaseInvoiceHeaderId: string } & APIUpdatedEntities
  >(),
  ...makeApiActions('put', 'invoiceHeaderConvert')<APIUpdatedEntities>(),
  ...makeAction('getInvoiceHeadersForAnalysisRowStarted')<{
    analysisRowId: string;
  }>(),
  ...makeAction('getInvoiceHeadersForAnalysisRowFailure')<{
    analysisRowId: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('getInvoiceHeadersForAnalysisRowSuccess')<{
    analysisRowId: string;
    invoiceHeaders: InvoiceHeader[];
  }>(),
};
export type InvoiceHeaderAction = ExtractActionTypes<typeof actionCreators>;
export const {
  getInvoiceHeadersStarted,
  getInvoiceHeadersSuccess,
  getInvoiceHeadersFailure,
  putInvoiceHeaderStarted,
  putInvoiceHeaderFailure,
  putInvoiceHeaderSuccess,
  putInvoiceHeaderConvertStarted,
  putInvoiceHeaderConvertFailure,
  putInvoiceHeaderMoveStarted,
  putInvoiceHeaderMoveSuccess,
  putInvoiceHeaderConvertSuccess,
  putInvoiceHeaderDeclineStarted,
  putInvoiceHeaderDeclineSuccess,
  putInvoiceHeaderDeclineFailure,
  putInvoiceHeaderComplaintClose,
  putCancelInvoiceHeaderComplaintStarted,
  putCancelInvoiceHeaderComplaintSuccess,
  putCancelInvoiceHeaderComplaintFailure,
  putInvoiceHeaderStartCorrectionStarted,
  putInvoiceHeaderStartCorrectionSuccess,
  putInvoiceHeaderStartCorrectionFailure,
  putInvoiceHeaderFinishCorrectionStarted,
  putInvoiceHeaderFinishCorrectionSuccess,
  putInvoiceHeaderFinishCorrectionFailure,
  toggleInvoiceHeaderSort,
  getInvoiceHeadersForAnalysisRowFailure,
  getInvoiceHeadersForAnalysisRowStarted,
  getInvoiceHeadersForAnalysisRowSuccess,
} = actionCreators;

const apiInvoiceHeaderType = t.exact(
  t.type({
    id: t.string,
    isDeleted: t.boolean,
    orderId: t.string,
    purchaseInvoiceExternalId: t.string,
    projectId: t.string,
    supplierId: t.union([t.string, t.null]),
    supplierName: t.union([t.string, t.null]),
    orderDate: t.union([dateString, t.null]),
    postingDate: t.union([dateString, t.null]),
    paymentDate: t.union([dateString, t.null]),
    dueDate: dateString,
    documentDate: dateString,
    currencyCode: t.string,
    currencyFactor: bigString,
    amount: bigString,
    amountIncludingVat: bigString,
    invoiceNumber: t.union([t.string, t.null]),
    yourReference: t.union([t.string, t.null]),
    comment: t.union([t.string, t.null]),
    vendorOrderNo: t.union([t.string, t.null]),
    vendorInvoiceNo: t.string,
    canceled: t.boolean,
    paid: t.boolean,
    approved: t.boolean,
    approvalDate: t.union([dateString, t.null]),
    remainingAmount: bigString,
    invoiceLink: t.union([t.string, t.null]),
    messageType: t.union([t.string, t.null]),
    messageTypeText: t.union([t.string, t.null]),
    invoiceMessage: t.union([t.string, t.null]),
    invoiceMessage2: t.union([t.string, t.null]),
    isHandleable: t.boolean,
    isMissingArrivalRowAccount: t.boolean,
    isMissingArrivalRowVat: t.boolean,
    isIncorrectVatAmount: t.boolean,
    isMovable: t.boolean,
    isMissingCostType: t.boolean,
    isUsingNonSpecifiedWorkPackages: t.boolean,
    handleAmountPending: bigString,
    handleVatAmountPending: bigString,
    statusId: t.string,
    createdAt: dateString,
    updatedAt: dateString,
    purchaseInvoiceAttachmentIds: t.array(t.string),
    purchaseInvoiceLineIds: t.array(t.string),
    latestStatusChangeAt: t.union([dateString, t.null]),
    latestUpdater: t.union([t.string, t.null]),
    isSpreadAvailable: t.boolean,
  })
);

export async function toInvoiceHeaders(u: unknown): Promise<InvoiceHeader[]> {
  const apiInvoiceHeaders = await tPromise.decode(
    t.array(apiInvoiceHeaderType),
    u
  );

  return apiInvoiceHeaders.map(
    ({
      handleAmountPending,
      isHandleable,
      isMissingArrivalRowAccount,
      isMissingArrivalRowVat,
      isIncorrectVatAmount,
      handleVatAmountPending,
      isMissingCostType,
      isUsingNonSpecifiedWorkPackages,
      ...rest
    }) => {
      return {
        ...rest,
        handleVatAmountPending,
        handleNetAmountPending: handleAmountPending,
        handlingState: solveHandlingState(
          isHandleable,
          handleAmountPending,
          handleVatAmountPending,
          isMissingArrivalRowAccount,
          isMissingArrivalRowVat,
          isIncorrectVatAmount,
          isMissingCostType,
          isUsingNonSpecifiedWorkPackages
        ),
      };
    }
  );
}

async function getInvoiceHeadersForOrder(
  orderId: ID
): Promise<InvoiceHeader[]> {
  const response = await GET(`v1/orders/${orderId}/purchase-invoice-headers`);

  return toInvoiceHeaders(response);
}

async function getInvoiceHeadersForAnalysisRow(
  analysisRowId: ID
): Promise<InvoiceHeader[]> {
  const response = await GET(
    `v1/custom-fields/list-items/${analysisRowId}/purchase-invoice-headers`
  );

  return toInvoiceHeaders(response);
}

export const fetchInvoiceHeadersForOrder = (orderId: ID) =>
  createAsyncThunk(getInvoiceHeadersForOrder, {
    args: [orderId],
    isPending: flow(
      selectOrderInvoiceHeadersRequests(orderId),
      remoteData.isLoading
    ),
    initialAction: getInvoiceHeadersStarted({ orderId }),
    successActionCreator: (invoiceHeaders) =>
      getInvoiceHeadersSuccess({ orderId, invoiceHeaders }),
    failureActionCreator: (error) =>
      getInvoiceHeadersFailure({
        orderId,
        error: apiErrorHandlingWithDecode(error),
      }),
  });

export const fetchInvoiceHeadersForAnalysisRow = (analysisRowId: ID) =>
  createAsyncThunk(getInvoiceHeadersForAnalysisRow, {
    args: [analysisRowId],
    isPending: flow(
      selectAnalysisRowInvoiceHeadersRequests(analysisRowId),
      remoteData.isLoading
    ),
    initialAction: getInvoiceHeadersForAnalysisRowStarted({ analysisRowId }),
    successActionCreator: (invoiceHeaders) =>
      getInvoiceHeadersForAnalysisRowSuccess({ analysisRowId, invoiceHeaders }),
    failureActionCreator: (error) =>
      getInvoiceHeadersForAnalysisRowFailure({
        analysisRowId,
        error: apiErrorHandlingWithDecode(error),
      }),
  });

const putInvoiceHeaderUpdate = async ({
  invoiceHeaderId,
  body,
}: {
  invoiceHeaderId: ID;
  body: APIPurchaseInvoiceHeaderPutBody;
}): Promise<APIUpdatedEntities> =>
  PUT<RawAPIUpdatedEntities>(
    `v1/purchase-invoice-headers/${invoiceHeaderId}`,
    body
  ).then(mapRawUpdatedEntities);

export const updateInvoiceHeader = (
  invoiceHeaderId: ID,
  body: APIPurchaseInvoiceHeaderPutBody
) =>
  createAsyncThunk(putInvoiceHeaderUpdate, {
    args: [{ invoiceHeaderId, body }],
    initialAction: putInvoiceHeaderStarted({ invoiceHeaderId }),
    isPending: flow(
      getInvoiceUpdateRequest(invoiceHeaderId),
      remoteData.isLoading
    ),
    failureActionCreator: (error) =>
      putInvoiceHeaderFailure({
        invoiceHeaderId,
        error: apiErrorHandlingWithDecode(error),
      }),
    successActionCreator: (updatedEntities) =>
      putInvoiceHeaderSuccess({ ...updatedEntities, invoiceHeaderId }),
  });

export const convertInvoiceHeader =
  (invoiceHeaderId: ID): Thunk =>
  (dispatch, _) => {
    dispatch(putInvoiceHeaderConvertStarted());

    PUT<RawAPIUpdatedEntities>(
      `v1/purchase-invoice-headers/${invoiceHeaderId}/convert`,
      {}
    )
      .then(mapRawUpdatedEntities)
      .then(
        (updatedEntities) => {
          dispatch(putInvoiceHeaderConvertSuccess(updatedEntities));
        },
        (error) => {
          dispatch(
            putInvoiceHeaderConvertFailure(apiErrorHandlingWithDecode(error))
          );
        }
      );
  };

export const moveInvoiceHeader =
  (invoiceHeaderId: ID, orderId: ID): Thunk =>
  (dispatch, _) => {
    dispatch(putInvoiceHeaderMoveStarted());

    PUT<RawAPIUpdatedEntities>(
      `v1/purchase-invoice-headers/${invoiceHeaderId}/move`,
      {
        orderId,
      }
    )
      .then(mapRawUpdatedEntities)
      .then(
        (updatedEntities) => {
          dispatch(
            putInvoiceHeaderMoveSuccess({
              ...updatedEntities,
              invoiceHeaderId,
              orderId,
            })
          );
        },
        (error) => {
          dispatch(
            putInvoiceHeaderConvertFailure(apiErrorHandlingWithDecode(error))
          );
        }
      );
  };

async function postDeclineInvoiceHeader(
  invoiceHeaderId: string,
  comment: string
): Promise<APIUpdatedEntities> {
  const response = await PUT<RawAPIUpdatedEntities>(
    `v1/purchase-invoice-headers/${invoiceHeaderId}/decline`,
    {
      comment,
    }
  );

  return mapRawUpdatedEntities(response);
}

export const declineInvoiceHeader = (
  invoiceHeaderId: string,
  comment: string
) =>
  createAsyncThunk(postDeclineInvoiceHeader, {
    args: [invoiceHeaderId, comment],
    initialAction: putInvoiceHeaderDeclineStarted(),
    successActionCreator: (response) =>
      putInvoiceHeaderDeclineSuccess(response),
    failureActionCreator: (error) =>
      putInvoiceHeaderDeclineFailure(apiErrorHandlingWithDecode(error)),
  });

async function putInvoiceHeaderStartCorrection(
  invoiceHeaderId: string,
  comment: string
): Promise<APIUpdatedEntities> {
  const response = await PUT<RawAPIUpdatedEntities>(
    `v1/purchase-invoice-headers/${invoiceHeaderId}/start-correction`,
    {
      comment,
    }
  );

  return mapRawUpdatedEntities(response);
}

export const startInvoiceHeaderCorrection = (
  invoiceHeaderId: string,
  comment: string
) =>
  createAsyncThunk(putInvoiceHeaderStartCorrection, {
    args: [invoiceHeaderId, comment],
    initialAction: putInvoiceHeaderStartCorrectionStarted({
      purchaseInvoiceHeaderId: invoiceHeaderId,
    }),
    successActionCreator: (response) =>
      putInvoiceHeaderStartCorrectionSuccess({
        purchaseInvoiceHeaderId: invoiceHeaderId,
        ...response,
      }),
    failureActionCreator: (error) =>
      putInvoiceHeaderStartCorrectionFailure({
        purchaseInvoiceHeaderId: invoiceHeaderId,
        error: apiErrorHandlingWithDecode(error),
      }),
  });

async function putInvoiceHeaderFinishCorrection(
  invoiceHeaderId: string
): Promise<APIUpdatedEntities> {
  const response = await PUT<RawAPIUpdatedEntities>(
    `v1/purchase-invoice-headers/${invoiceHeaderId}/finish-correction`,
    {}
  );

  return mapRawUpdatedEntities(response);
}

export const finishInvoiceHeaderCorrection = (invoiceHeaderId: string) =>
  createAsyncThunk(putInvoiceHeaderFinishCorrection, {
    args: [invoiceHeaderId],
    initialAction: putInvoiceHeaderFinishCorrectionStarted({
      purchaseInvoiceHeaderId: invoiceHeaderId,
    }),
    successActionCreator: (response) =>
      putInvoiceHeaderFinishCorrectionSuccess({
        purchaseInvoiceHeaderId: invoiceHeaderId,
        ...response,
      }),
    failureActionCreator: (error) =>
      putInvoiceHeaderFinishCorrectionFailure({
        purchaseInvoiceHeaderId: invoiceHeaderId,
        error: apiErrorHandlingWithDecode(error),
      }),
  });

async function postCancelInvoiceHeaderComplaint(
  invoiceHeaderId: string
): Promise<APIUpdatedEntities> {
  const response = await PUT<RawAPIUpdatedEntities>(
    `v1/purchase-invoice-headers/${invoiceHeaderId}/cancel-reclaim`,
    {}
  );

  return mapRawUpdatedEntities(response);
}

export const cancelInvoiceComplaintHeader = (invoiceHeaderId: string) =>
  createAsyncThunk(postCancelInvoiceHeaderComplaint, {
    args: [invoiceHeaderId],
    initialAction: putCancelInvoiceHeaderComplaintStarted(),
    successActionCreator: (response) =>
      putCancelInvoiceHeaderComplaintSuccess({
        ...response,
        purchaseInvoiceHeaderId: invoiceHeaderId,
      }),
    failureActionCreator: (error) =>
      putCancelInvoiceHeaderComplaintFailure(apiErrorHandlingWithDecode(error)),
  });
