import React from 'react';
import { useParams } from 'react-router-dom';

import styled, { css } from 'styled-components';

import { getAnalysisGroupsForProject } from '../../../store/reducers/analysis/group';

import * as Actions from '../../../store/actions';

import useRemoteData from '../../../hooks/useRemoteData';

import { TableSeparateBorderCollapse } from '../../../components/Table';

import { AnalysisGroup } from './AnalysisGroup';
import { TableHeader, tableColumns } from './TableHeader';

type RouteParams = {
  projectId: string;
};

export const AnalysisTable = () => {
  const { projectId } = useParams<RouteParams>();

  const analysisGroups =
    useRemoteData(
      getAnalysisGroupsForProject(projectId),
      Actions.requestAnalysisGroups({ projectId })
    ) ?? [];

  // The trick is that relationship between the first <col> and the empty second <col>.
  // If we apply a width to the first <col> (it’s 200px in the snippet above), then the second column will be eaten up when the fixed table layout
  // divides up the available space to distribute to the columns. But the width of the first column (200px) is respected and remains in place.

  return (
    <RelativeTableSeparateBorderCollapse>
      <colgroup>
        <Col name="empty" />
        <Col name="empty" />
        <Col name="value" />
        <Col name="paymentProgramRowsAmount" />
        <Col name="orderRowsAmount" />
        <Col name="targetRowsAmount" />
        <Col name="arrivalsAmount" />
        <Col name="none" />
        <Col />
        <Col name="delete" />
      </colgroup>
      <TableHeader />
      <tbody>
        {analysisGroups.map((group) => (
          <AnalysisGroup key={group.id} analysisGroup={group} />
        ))}
      </tbody>
    </RelativeTableSeparateBorderCollapse>
  );
};

type ColProps = {
  name?: keyof typeof tableColumns;
};

export const Col = styled.col<ColProps>`
  ${({ name }) =>
    name
      ? css`
          width: ${tableColumns[name].width};
        `
      : ''}
`;

// relative positioning needed for date picker absolute positioning
const RelativeTableSeparateBorderCollapse = styled(TableSeparateBorderCollapse)`
  position: relative;
`;
