import React from 'react';

import {
  ExpandedState,
  FilterFnOption,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { getFilteredRowModel } from '@tanstack/react-table';
import { useVirtualizer } from '@tanstack/react-virtual';
import styled from 'styled-components';

import useScheduleTreeTableColumns, {
  tableColumns,
} from '../hooks/useScheduleTreeTableColumns';

import { searchFunctionWithFields } from '../../../../utils/search';
import { ExtendedScheduleWorkPackageType } from '../utils';

import NewScheduleTreeRow from '../NewScheduleTreeRow';
import {
  StyledTable,
  StyledTBody,
  StyledThead,
  StyledTr,
} from '../ScheduleTreeTable';
import Th from '../ScheduleTreeTh';

type WorkPackageListProps = {
  workPackages: ExtendedScheduleWorkPackageType[];
  setSelectedIds: React.Dispatch<React.SetStateAction<string[]>>;
  selectedBasedOnWorkSection: string[];
  manualLinkedWorkPackages: string[];
  parentMeasures: { width: number; height: number | string };
};

/**
 * should show rows and its children and parents if the row or its children are filtered
 */
const filterFunction: FilterFnOption<ExtendedScheduleWorkPackageType> = (
  row,
  _,
  value
) => {
  const isPassed = searchFunctionWithFields(
    row.original,
    [
      'name',
      'virtualSpaceName',
      'workSectionClassName',
      'teamName',
      'parentNames',
      'parentVirtualSpaceNames',
      'parentWorkSectionClassNames',
      'parentTeamNames',
    ],
    value
  );

  return isPassed;
};

const WorkPackageList = React.forwardRef(
  (
    {
      workPackages,
      selectedBasedOnWorkSection,
      manualLinkedWorkPackages,
      parentMeasures,
      setSelectedIds,
    }: WorkPackageListProps,
    ref: React.Ref<{
      toggleExpandAll: (expand: boolean) => void;
      setFilter: (filter: string) => void;
      setRowSelection: React.Dispatch<
        React.SetStateAction<Record<string, boolean>>
      >;
    }>
  ) => {
    const columns = useScheduleTreeTableColumns(undefined, undefined, true);

    const initialIds = [
      ...new Set([...selectedBasedOnWorkSection, ...manualLinkedWorkPackages]),
    ];

    const initialSelection = initialIds.reduce(
      (acc, id) => {
        acc[id] = true;

        return acc;
      },
      {} as Record<string, boolean>
    );

    const deselectDisabledIds = selectedBasedOnWorkSection.reduce(
      (acc, id) => {
        acc[id] = true;

        return acc;
      },
      {} as Record<string, boolean>
    );

    const [globalFilter, setGlobalFilter] = React.useState('');
    const [expanded, setExpanded] = React.useState<ExpandedState>({});

    const [rowSelection, setRowSelection] =
      React.useState<Record<string, boolean>>(initialSelection);

    React.useEffect(() => {
      setSelectedIds(
        Object.entries(rowSelection).reduce((acc, curr) => {
          const newArray = acc;

          if (curr[1]) {
            newArray.push(curr[0]);
          }

          return newArray;
        }, [] as string[])
      );
    }, [setSelectedIds, rowSelection]);

    const onRowSelectionChange: React.Dispatch<
      React.SetStateAction<Record<string, boolean>>
    > = (action) => {
      let newState: {
        [x: string]: boolean;
      } = {};

      if (typeof action === 'function') {
        setRowSelection((previousState) => {
          const stateAfterAction = action(previousState);

          newState = { ...stateAfterAction, ...deselectDisabledIds };

          return newState;
        });
      } else {
        newState = { ...action, ...deselectDisabledIds };
        setRowSelection(newState);
      }
    };

    const parentRef = React.useRef<HTMLDivElement>(null);

    const table = useReactTable({
      data: workPackages,
      columns,
      state: {
        globalFilter,
        expanded,
        rowSelection,
      },
      onGlobalFilterChange: setGlobalFilter,
      onExpandedChange: setExpanded,
      onRowSelectionChange,
      getRowId: (row) => row.id,
      globalFilterFn: filterFunction,
      getSubRows: (row) => row.subRows,
      getCoreRowModel: getCoreRowModel(),
      getFilteredRowModel: getFilteredRowModel(),
      getExpandedRowModel: getExpandedRowModel(),
      manualSorting: true,
      filterFromLeafRows: true,
      enableSubRowSelection: true,
      enableRowSelection: (row) => !selectedBasedOnWorkSection.includes(row.id),
    });

    React.useImperativeHandle(ref, () => {
      const toggleExpandAll = table.toggleAllRowsExpanded;
      const setFilter = (text: string) => setGlobalFilter(text);

      return {
        toggleExpandAll,
        setFilter,
        setRowSelection,
      };
    });

    const { rows } = table.getRowModel();

    const rowVirtualizer = useVirtualizer({
      count: rows.length,
      getScrollElement: () => parentRef.current,
      estimateSize: () => 40,
      overscan: 50,
      measureElement:
        typeof window !== 'undefined' &&
        navigator.userAgent.indexOf('Firefox') === -1
          ? (element) => element?.getBoundingClientRect().height
          : undefined,
    });

    const measureElement = React.useCallback(
      (node: Element | null) => {
        return rowVirtualizer.measureElement(node);
      },
      [rowVirtualizer]
    );

    const virtualItems = rowVirtualizer.getVirtualItems();

    const visibleRows = React.useMemo(() => {
      return virtualItems.map((virtualRow) => ({
        ...virtualRow,
        row: rows[virtualRow.index],
      }));
    }, [virtualItems, rows]);

    const containerWidth = parentMeasures.width;

    return (
      <UusiDiv height={parentMeasures.height}>
        <StyledContainer ref={parentRef}>
          <StyledTable>
            <StyledThead>
              {table.getHeaderGroups().map((headerGroup) => {
                const headers = headerGroup.headers.filter(
                  (header) => header.id !== 'buttons'
                );

                return (
                  <StyledTr key={headerGroup.id}>
                    {headers.map((header) => {
                      const headerId = header.id as keyof typeof tableColumns;

                      const thWidth = tableColumns[headerId]?.width * 16; // 1rem = 16px
                      const fixedWidth = tableColumns[headerId]?.fixedWidth;

                      const containerWidthMinusFixedWidths =
                        headers.reduce((acc, currentHeader) => {
                          const currentHeaderId =
                            currentHeader.id as keyof typeof tableColumns;

                          const currentHeaderFixedWidth =
                            tableColumns[currentHeaderId]?.fixedWidth;

                          return (
                            acc -
                            (currentHeaderFixedWidth
                              ? tableColumns[currentHeaderId]?.width * 16
                              : 0)
                          );
                        }, containerWidth) - 20;

                      const dynamicWidthTotal = headers.reduce(
                        (acc, currentHeader) => {
                          const currentHeaderId =
                            currentHeader.id as keyof typeof tableColumns;

                          const currentHeaderFixedWidth =
                            tableColumns[currentHeaderId]?.fixedWidth;

                          return (
                            acc +
                            (!currentHeaderFixedWidth
                              ? tableColumns[currentHeaderId]?.width * 16
                              : 0)
                          );
                        },
                        0
                      );

                      const dynamicWidth = Math.max(
                        (thWidth / dynamicWidthTotal) *
                          containerWidthMinusFixedWidths,
                        thWidth // use as minimum width
                      );

                      return (
                        <Th
                          key={header.id}
                          header={header}
                          width={fixedWidth ? thWidth : dynamicWidth}
                        />
                      );
                    })}
                  </StyledTr>
                );
              })}
            </StyledThead>
            <StyledTBody height={rowVirtualizer.getTotalSize()}>
              {visibleRows.map((virtualRow) => {
                const isRowSelected = virtualRow.row.getIsSelected();

                const leafRows = virtualRow.row.getLeafRows();

                const isChildSelected = leafRows.some((childRow) =>
                  childRow.getIsSelected()
                );

                return (
                  <NewScheduleTreeRow
                    key={virtualRow.row.id}
                    scheduleWorkPackage={virtualRow.row}
                    measureElement={measureElement}
                    virtualRow={virtualRow}
                    containerWidth={containerWidth}
                    showButtons={false}
                    isSelected={isRowSelected}
                    data-testid={`link-workPackage-tree-row-${virtualRow.row.id}`}
                    indeterminate={isChildSelected}
                  />
                );
              })}
            </StyledTBody>
          </StyledTable>
        </StyledContainer>
      </UusiDiv>
    );
  }
);

export default WorkPackageList;

const StyledContainer = styled.div`
  position: relative;

  height: 100%;
  width: 100%;

  display: flex;
  flex-wrap: wrap;
  align-content: space-between;

  overflow: auto;

  transform: translate3d(0, 0, 0);
`;

const UusiDiv = styled.div<{ height: number | string }>`
  height: ${(props) =>
    typeof props.height === 'string' ? props.height : `${props.height}px`};
  width: 100%;
`;
