import React from 'react';

import { flexRender, Header } from '@tanstack/react-table';
import styled from 'styled-components';

import { GridCellTh } from '../../../components/Cell';

import { ExtendedScheduleWorkPackageType } from './utils';

type ThProps = {
  header: Header<ExtendedScheduleWorkPackageType, unknown>;
  width: number;
};

const Th = ({ header, width }: ThProps) => {
  return (
    <StyledTh align={header.column.columnDef?.meta?.align} width={width}>
      {flexRender(header.column.columnDef.header, header.getContext())}
    </StyledTh>
  );
};

type ThCellProps = {
  width: number;
};

const StyledTh = styled(GridCellTh)<ThCellProps>`
  border-right: ${(props) =>
    `1px solid ${props.theme.color['M3/ref/neutral/neutral90']}`};
  box-shadow: inset 1px 0 white;

  padding: ${(props) => props.theme.margin[8]};

  width: ${({ width }) => width}px;

  font-size: 0.9rem;
  font-weight: 600;
  text-align: left;
`;

export default Th;
