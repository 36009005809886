import React from 'react';

import styled from 'styled-components';

import useLocalization from '../../../../hooks/useLocalization';

import { TextId } from '../../../../localization';

const CsvTableIcon = () => {
  const localization = useLocalization();

  const csvExampleHeaders = [
    'target.targetImportModal.csv.headers.rowCode',
    'target.targetImportModal.csv.headers.parentCode',
    'target.table.header.description',
    'target.table.header.quantity',
    'target.table.header.unit',
    'target.table.header.unitPrice',
    'target.table.header.target',
    'order.options.visibleCode',
    'order.options.name',
    'worksection.workpackage.code',
    'worksection.workpackage.name',
  ] as TextId[];

  const mappedHeaders = csvExampleHeaders.reduce(
    (acc, header) => {
      return {
        ...acc,
        [header]: localization.formatMessage({ id: header }),
      };
    },
    {} as Record<TextId, string>
  );

  return (
    <StyledCsvTableIcon>
      <svg
        width="1112"
        height="270"
        viewBox="0 0 1112 270"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_17491_7825)">
          <rect
            x="31"
            y="46.5"
            width="1050"
            height="192"
            fill="white"
            shapeRendering="crispEdges"
          />
          <rect
            x="30.5"
            y="46"
            width="1051"
            height="193"
            stroke="#CAC4D0"
            shapeRendering="crispEdges"
          />
          <mask id="path-3-inside-1_17491_7825" fill="white">
            <path d="M31 46.5H1081V78.5H31V46.5Z" />
          </mask>
          <path d="M31 46.5H1081V78.5H31V46.5Z" fill="#387A47" />
          <path
            d="M1081 77.5H31V79.5H1081V77.5Z"
            fill="#CAC4D0"
            mask="url(#path-3-inside-1_17491_7825)"
          />
          <text
            x="39"
            y="66"
            fill="white"
            fontSize="8px"
            fontFamily="Graphik"
            fontWeight="400"
            clipPath="polygon(0 0, 85px 0, 85px 100%, 0 100%)"
          >
            {mappedHeaders['target.targetImportModal.csv.headers.rowCode']}
          </text>
          <mask id="path-6-inside-2_17491_7825" fill="white">
            <path d="M126.455 46.5H221.91V78.5H126.455V46.5Z" />
          </mask>
          <path
            d="M127.455 78.5V46.5H125.455V78.5H127.455Z"
            fill="#CAC4D0"
            mask="url(#path-6-inside-2_17491_7825)"
          />
          <text
            x="134"
            y="66"
            fill="white"
            fontSize="8px"
            fontFamily="Graphik"
            fontWeight="400"
            clipPath="polygon(0 0, 85px 0, 85px 100%, 0 100%)"
          >
            {mappedHeaders['target.targetImportModal.csv.headers.parentCode']}
          </text>
          <mask id="path-9-inside-3_17491_7825" fill="white">
            <path d="M221.908 46.5H317.363V78.5H221.908V46.5Z" />
          </mask>
          <path
            d="M222.908 78.5V46.5H220.908V78.5H222.908Z"
            fill="#CAC4D0"
            mask="url(#path-9-inside-3_17491_7825)"
          />
          <text
            x="230"
            y="66"
            fill="white"
            fontSize="8px"
            fontFamily="Graphik"
            fontWeight="400"
            clipPath="polygon(0 0, 85px 0, 85px 100%, 0 100%)"
          >
            {mappedHeaders['target.table.header.description']}
          </text>
          <mask id="path-12-inside-4_17491_7825" fill="white">
            <path d="M317.363 46.5H412.818V78.5H317.363V46.5Z" />
          </mask>
          <path
            d="M318.363 78.5V46.5H316.363V78.5H318.363Z"
            fill="#CAC4D0"
            mask="url(#path-12-inside-4_17491_7825)"
          />
          <text
            x="325"
            y="66"
            fill="white"
            fontSize="8px"
            fontFamily="Graphik"
            fontWeight="400"
            clipPath="polygon(0 0, 85px 0, 85px 100%, 0 100%)"
          >
            {mappedHeaders['target.table.header.quantity']}
          </text>
          <mask id="path-15-inside-5_17491_7825" fill="white">
            <path d="M412.818 46.5H508.273V78.5H412.818V46.5Z" />
          </mask>
          <path
            d="M413.818 78.5V46.5H411.818V78.5H413.818Z"
            fill="#CAC4D0"
            mask="url(#path-15-inside-5_17491_7825)"
          />
          <text
            x="420"
            y="66"
            fill="white"
            fontSize="8px"
            fontFamily="Graphik"
            fontWeight="400"
            clipPath="polygon(0 0, 85px 0, 85px 100%, 0 100%)"
          >
            {mappedHeaders['target.table.header.unit']}
          </text>
          <mask id="path-18-inside-6_17491_7825" fill="white">
            <path d="M508.273 46.5H603.728V78.5H508.273V46.5Z" />
          </mask>
          <path
            d="M509.273 78.5V46.5H507.273V78.5H509.273Z"
            fill="#CAC4D0"
            mask="url(#path-18-inside-6_17491_7825)"
          />
          <text
            x="515"
            y="66"
            fill="white"
            fontSize="8px"
            fontFamily="Graphik"
            fontWeight="400"
            clipPath="polygon(0 0, 85px 0, 85px 100%, 0 100%)"
          >
            {mappedHeaders['target.table.header.unitPrice']}
          </text>
          <mask id="path-21-inside-7_17491_7825" fill="white">
            <path d="M603.727 46.5H699.181V78.5H603.727V46.5Z" />
          </mask>
          <path
            d="M604.727 78.5V46.5H602.727V78.5H604.727Z"
            fill="#CAC4D0"
            mask="url(#path-21-inside-7_17491_7825)"
          />
          <text
            x="612"
            y="66"
            fill="white"
            fontSize="8px"
            fontFamily="Graphik"
            fontWeight="400"
            clipPath="polygon(0 0, 85px 0, 85px 100%, 0 100%)"
          >
            {mappedHeaders['target.table.header.target']}
          </text>
          <mask id="path-24-inside-8_17491_7825" fill="white">
            <path d="M699.182 46.5H794.636V78.5H699.182V46.5Z" />
          </mask>
          <path
            d="M700.182 78.5V46.5H698.182V78.5H700.182Z"
            fill="#CAC4D0"
            mask="url(#path-24-inside-8_17491_7825)"
          />
          <text
            x="707"
            y="66"
            fill="white"
            fontSize="8px"
            fontFamily="Graphik"
            fontWeight="400"
            clipPath="polygon(0 0, 85px 0, 85px 100%, 0 100%)"
          >
            {mappedHeaders['order.options.visibleCode']}
          </text>
          <mask id="path-27-inside-9_17491_7825" fill="white">
            <path d="M794.637 46.5H890.091V78.5H794.637V46.5Z" />
          </mask>
          <path
            d="M795.637 78.5V46.5H793.637V78.5H795.637Z"
            fill="#CAC4D0"
            mask="url(#path-27-inside-9_17491_7825)"
          />
          <text
            x="802"
            y="66"
            fill="white"
            fontSize="8px"
            fontFamily="Graphik"
            fontWeight="400"
            clipPath="polygon(0 0, 85px 0, 85px 100%, 0 100%)"
          >
            {mappedHeaders['order.options.name']}
          </text>
          <mask id="path-30-inside-10_17491_7825" fill="white">
            <path d="M890.092 46.5H985.546V78.5H890.092V46.5Z" />
          </mask>
          <path
            d="M891.092 78.5V46.5H889.092V78.5H891.092Z"
            fill="#CAC4D0"
            mask="url(#path-30-inside-10_17491_7825)"
          />
          <text
            x="898"
            y="66"
            fill="white"
            fontSize="8px"
            fontFamily="Graphik"
            fontWeight="400"
            clipPath="polygon(0 0, 85px 0, 85px 100%, 0 100%)"
          >
            {mappedHeaders['worksection.workpackage.code']}
          </text>
          <mask id="path-33-inside-11_17491_7825" fill="white">
            <path d="M985.545 46.5H1081V78.5H985.545V46.5Z" />
          </mask>
          <path
            d="M986.545 78.5V46.5H984.545V78.5H986.545Z"
            fill="#CAC4D0"
            mask="url(#path-33-inside-11_17491_7825)"
          />
          <text
            x="994"
            y="66"
            fill="white"
            fontSize="8px"
            fontFamily="Graphik"
            fontWeight="400"
            clipPath="polygon(0 0, 85px 0, 85px 100%, 0 100%)"
          >
            {mappedHeaders['worksection.workpackage.name']}
          </text>
          <mask id="path-36-inside-12_17491_7825" fill="white">
            <path d="M31 78.5H1081V110.5H31V78.5Z" />
          </mask>
          <path
            d="M1081 109.5H31V111.5H1081V109.5Z"
            fill="#CAC4D0"
            mask="url(#path-36-inside-12_17491_7825)"
          />
          <mask id="path-38-inside-13_17491_7825" fill="white">
            <path d="M126.455 78.5H221.91V110.5H126.455V78.5Z" />
          </mask>
          <path
            d="M127.455 110.5V78.5H125.455V110.5H127.455Z"
            fill="#CAC4D0"
            mask="url(#path-38-inside-13_17491_7825)"
          />
          <mask id="path-40-inside-14_17491_7825" fill="white">
            <path d="M221.908 78.5H317.363V110.5H221.908V78.5Z" />
          </mask>
          <path
            d="M222.908 110.5V78.5H220.908V110.5H222.908Z"
            fill="#CAC4D0"
            mask="url(#path-40-inside-14_17491_7825)"
          />
          <mask id="path-42-inside-15_17491_7825" fill="white">
            <path d="M317.363 78.5H412.818V110.5H317.363V78.5Z" />
          </mask>
          <path
            d="M318.363 110.5V78.5H316.363V110.5H318.363Z"
            fill="#CAC4D0"
            mask="url(#path-42-inside-15_17491_7825)"
          />
          <mask id="path-44-inside-16_17491_7825" fill="white">
            <path d="M412.818 78.5H508.273V110.5H412.818V78.5Z" />
          </mask>
          <path
            d="M413.818 110.5V78.5H411.818V110.5H413.818Z"
            fill="#CAC4D0"
            mask="url(#path-44-inside-16_17491_7825)"
          />
          <mask id="path-46-inside-17_17491_7825" fill="white">
            <path d="M508.273 78.5H603.728V110.5H508.273V78.5Z" />
          </mask>
          <path
            d="M509.273 110.5V78.5H507.273V110.5H509.273Z"
            fill="#CAC4D0"
            mask="url(#path-46-inside-17_17491_7825)"
          />
          <mask id="path-48-inside-18_17491_7825" fill="white">
            <path d="M603.727 78.5H699.181V110.5H603.727V78.5Z" />
          </mask>
          <path
            d="M604.727 110.5V78.5H602.727V110.5H604.727Z"
            fill="#CAC4D0"
            mask="url(#path-48-inside-18_17491_7825)"
          />
          <mask id="path-50-inside-19_17491_7825" fill="white">
            <path d="M699.182 78.5H794.636V110.5H699.182V78.5Z" />
          </mask>
          <path
            d="M700.182 110.5V78.5H698.182V110.5H700.182Z"
            fill="#CAC4D0"
            mask="url(#path-50-inside-19_17491_7825)"
          />
          <mask id="path-52-inside-20_17491_7825" fill="white">
            <path d="M794.637 78.5H890.091V110.5H794.637V78.5Z" />
          </mask>
          <path
            d="M795.637 110.5V78.5H793.637V110.5H795.637Z"
            fill="#CAC4D0"
            mask="url(#path-52-inside-20_17491_7825)"
          />
          <mask id="path-54-inside-21_17491_7825" fill="white">
            <path d="M890.092 78.5H985.546V110.5H890.092V78.5Z" />
          </mask>
          <path
            d="M891.092 110.5V78.5H889.092V110.5H891.092Z"
            fill="#CAC4D0"
            mask="url(#path-54-inside-21_17491_7825)"
          />
          <mask id="path-56-inside-22_17491_7825" fill="white">
            <path d="M985.545 78.5H1081V110.5H985.545V78.5Z" />
          </mask>
          <path
            d="M986.545 110.5V78.5H984.545V110.5H986.545Z"
            fill="#CAC4D0"
            mask="url(#path-56-inside-22_17491_7825)"
          />
          <mask id="path-58-inside-23_17491_7825" fill="white">
            <path d="M31 110.5H1081V142.5H31V110.5Z" />
          </mask>
          <path
            d="M1081 141.5H31V143.5H1081V141.5Z"
            fill="#CAC4D0"
            mask="url(#path-58-inside-23_17491_7825)"
          />
          <mask id="path-60-inside-24_17491_7825" fill="white">
            <path d="M126.455 110.5H221.91V142.5H126.455V110.5Z" />
          </mask>
          <path
            d="M127.455 142.5V110.5H125.455V142.5H127.455Z"
            fill="#CAC4D0"
            mask="url(#path-60-inside-24_17491_7825)"
          />
          <mask id="path-62-inside-25_17491_7825" fill="white">
            <path d="M221.908 110.5H317.363V142.5H221.908V110.5Z" />
          </mask>
          <path
            d="M222.908 142.5V110.5H220.908V142.5H222.908Z"
            fill="#CAC4D0"
            mask="url(#path-62-inside-25_17491_7825)"
          />
          <mask id="path-64-inside-26_17491_7825" fill="white">
            <path d="M317.363 110.5H412.818V142.5H317.363V110.5Z" />
          </mask>
          <path
            d="M318.363 142.5V110.5H316.363V142.5H318.363Z"
            fill="#CAC4D0"
            mask="url(#path-64-inside-26_17491_7825)"
          />
          <mask id="path-66-inside-27_17491_7825" fill="white">
            <path d="M412.818 110.5H508.273V142.5H412.818V110.5Z" />
          </mask>
          <path
            d="M413.818 142.5V110.5H411.818V142.5H413.818Z"
            fill="#CAC4D0"
            mask="url(#path-66-inside-27_17491_7825)"
          />
          <mask id="path-68-inside-28_17491_7825" fill="white">
            <path d="M508.273 110.5H603.728V142.5H508.273V110.5Z" />
          </mask>
          <path
            d="M509.273 142.5V110.5H507.273V142.5H509.273Z"
            fill="#CAC4D0"
            mask="url(#path-68-inside-28_17491_7825)"
          />
          <mask id="path-70-inside-29_17491_7825" fill="white">
            <path d="M603.727 110.5H699.181V142.5H603.727V110.5Z" />
          </mask>
          <path
            d="M604.727 142.5V110.5H602.727V142.5H604.727Z"
            fill="#CAC4D0"
            mask="url(#path-70-inside-29_17491_7825)"
          />
          <mask id="path-72-inside-30_17491_7825" fill="white">
            <path d="M699.182 110.5H794.636V142.5H699.182V110.5Z" />
          </mask>
          <path
            d="M700.182 142.5V110.5H698.182V142.5H700.182Z"
            fill="#CAC4D0"
            mask="url(#path-72-inside-30_17491_7825)"
          />
          <mask id="path-74-inside-31_17491_7825" fill="white">
            <path d="M794.637 110.5H890.091V142.5H794.637V110.5Z" />
          </mask>
          <path
            d="M795.637 142.5V110.5H793.637V142.5H795.637Z"
            fill="#CAC4D0"
            mask="url(#path-74-inside-31_17491_7825)"
          />
          <mask id="path-76-inside-32_17491_7825" fill="white">
            <path d="M890.092 110.5H985.546V142.5H890.092V110.5Z" />
          </mask>
          <path
            d="M891.092 142.5V110.5H889.092V142.5H891.092Z"
            fill="#CAC4D0"
            mask="url(#path-76-inside-32_17491_7825)"
          />
          <mask id="path-78-inside-33_17491_7825" fill="white">
            <path d="M985.545 110.5H1081V142.5H985.545V110.5Z" />
          </mask>
          <path
            d="M986.545 142.5V110.5H984.545V142.5H986.545Z"
            fill="#CAC4D0"
            mask="url(#path-78-inside-33_17491_7825)"
          />
          <mask id="path-80-inside-34_17491_7825" fill="white">
            <path d="M31 142.5H1081V174.5H31V142.5Z" />
          </mask>
          <path
            d="M1081 173.5H31V175.5H1081V173.5Z"
            fill="#CAC4D0"
            mask="url(#path-80-inside-34_17491_7825)"
          />
          <mask id="path-82-inside-35_17491_7825" fill="white">
            <path d="M126.455 142.5H221.91V174.5H126.455V142.5Z" />
          </mask>
          <path
            d="M127.455 174.5V142.5H125.455V174.5H127.455Z"
            fill="#CAC4D0"
            mask="url(#path-82-inside-35_17491_7825)"
          />
          <mask id="path-84-inside-36_17491_7825" fill="white">
            <path d="M221.908 142.5H317.363V174.5H221.908V142.5Z" />
          </mask>
          <path
            d="M222.908 174.5V142.5H220.908V174.5H222.908Z"
            fill="#CAC4D0"
            mask="url(#path-84-inside-36_17491_7825)"
          />
          <mask id="path-86-inside-37_17491_7825" fill="white">
            <path d="M317.363 142.5H412.818V174.5H317.363V142.5Z" />
          </mask>
          <path
            d="M318.363 174.5V142.5H316.363V174.5H318.363Z"
            fill="#CAC4D0"
            mask="url(#path-86-inside-37_17491_7825)"
          />
          <mask id="path-88-inside-38_17491_7825" fill="white">
            <path d="M412.818 142.5H508.273V174.5H412.818V142.5Z" />
          </mask>
          <path
            d="M413.818 174.5V142.5H411.818V174.5H413.818Z"
            fill="#CAC4D0"
            mask="url(#path-88-inside-38_17491_7825)"
          />
          <mask id="path-90-inside-39_17491_7825" fill="white">
            <path d="M508.273 142.5H603.728V174.5H508.273V142.5Z" />
          </mask>
          <path
            d="M509.273 174.5V142.5H507.273V174.5H509.273Z"
            fill="#CAC4D0"
            mask="url(#path-90-inside-39_17491_7825)"
          />
          <mask id="path-92-inside-40_17491_7825" fill="white">
            <path d="M603.727 142.5H699.181V174.5H603.727V142.5Z" />
          </mask>
          <path
            d="M604.727 174.5V142.5H602.727V174.5H604.727Z"
            fill="#CAC4D0"
            mask="url(#path-92-inside-40_17491_7825)"
          />
          <mask id="path-94-inside-41_17491_7825" fill="white">
            <path d="M699.182 142.5H794.636V174.5H699.182V142.5Z" />
          </mask>
          <path
            d="M700.182 174.5V142.5H698.182V174.5H700.182Z"
            fill="#CAC4D0"
            mask="url(#path-94-inside-41_17491_7825)"
          />
          <mask id="path-96-inside-42_17491_7825" fill="white">
            <path d="M794.637 142.5H890.091V174.5H794.637V142.5Z" />
          </mask>
          <path
            d="M795.637 174.5V142.5H793.637V174.5H795.637Z"
            fill="#CAC4D0"
            mask="url(#path-96-inside-42_17491_7825)"
          />
          <mask id="path-98-inside-43_17491_7825" fill="white">
            <path d="M890.092 142.5H985.546V174.5H890.092V142.5Z" />
          </mask>
          <path
            d="M891.092 174.5V142.5H889.092V174.5H891.092Z"
            fill="#CAC4D0"
            mask="url(#path-98-inside-43_17491_7825)"
          />
          <mask id="path-100-inside-44_17491_7825" fill="white">
            <path d="M985.545 142.5H1081V174.5H985.545V142.5Z" />
          </mask>
          <path
            d="M986.545 174.5V142.5H984.545V174.5H986.545Z"
            fill="#CAC4D0"
            mask="url(#path-100-inside-44_17491_7825)"
          />
          <mask id="path-102-inside-45_17491_7825" fill="white">
            <path d="M31 174.5H1081V206.5H31V174.5Z" />
          </mask>
          <path
            d="M1081 205.5H31V207.5H1081V205.5Z"
            fill="#CAC4D0"
            mask="url(#path-102-inside-45_17491_7825)"
          />
          <mask id="path-104-inside-46_17491_7825" fill="white">
            <path d="M126.455 174.5H221.91V206.5H126.455V174.5Z" />
          </mask>
          <path
            d="M127.455 206.5V174.5H125.455V206.5H127.455Z"
            fill="#CAC4D0"
            mask="url(#path-104-inside-46_17491_7825)"
          />
          <mask id="path-106-inside-47_17491_7825" fill="white">
            <path d="M221.908 174.5H317.363V206.5H221.908V174.5Z" />
          </mask>
          <path
            d="M222.908 206.5V174.5H220.908V206.5H222.908Z"
            fill="#CAC4D0"
            mask="url(#path-106-inside-47_17491_7825)"
          />
          <mask id="path-108-inside-48_17491_7825" fill="white">
            <path d="M317.363 174.5H412.818V206.5H317.363V174.5Z" />
          </mask>
          <path
            d="M318.363 206.5V174.5H316.363V206.5H318.363Z"
            fill="#CAC4D0"
            mask="url(#path-108-inside-48_17491_7825)"
          />
          <mask id="path-110-inside-49_17491_7825" fill="white">
            <path d="M412.818 174.5H508.273V206.5H412.818V174.5Z" />
          </mask>
          <path
            d="M413.818 206.5V174.5H411.818V206.5H413.818Z"
            fill="#CAC4D0"
            mask="url(#path-110-inside-49_17491_7825)"
          />
          <mask id="path-112-inside-50_17491_7825" fill="white">
            <path d="M508.273 174.5H603.728V206.5H508.273V174.5Z" />
          </mask>
          <path
            d="M509.273 206.5V174.5H507.273V206.5H509.273Z"
            fill="#CAC4D0"
            mask="url(#path-112-inside-50_17491_7825)"
          />
          <mask id="path-114-inside-51_17491_7825" fill="white">
            <path d="M603.727 174.5H699.181V206.5H603.727V174.5Z" />
          </mask>
          <path
            d="M604.727 206.5V174.5H602.727V206.5H604.727Z"
            fill="#CAC4D0"
            mask="url(#path-114-inside-51_17491_7825)"
          />
          <mask id="path-116-inside-52_17491_7825" fill="white">
            <path d="M699.182 174.5H794.636V206.5H699.182V174.5Z" />
          </mask>
          <path
            d="M700.182 206.5V174.5H698.182V206.5H700.182Z"
            fill="#CAC4D0"
            mask="url(#path-116-inside-52_17491_7825)"
          />
          <mask id="path-118-inside-53_17491_7825" fill="white">
            <path d="M794.637 174.5H890.091V206.5H794.637V174.5Z" />
          </mask>
          <path
            d="M795.637 206.5V174.5H793.637V206.5H795.637Z"
            fill="#CAC4D0"
            mask="url(#path-118-inside-53_17491_7825)"
          />
          <mask id="path-120-inside-54_17491_7825" fill="white">
            <path d="M890.092 174.5H985.546V206.5H890.092V174.5Z" />
          </mask>
          <path
            d="M891.092 206.5V174.5H889.092V206.5H891.092Z"
            fill="#CAC4D0"
            mask="url(#path-120-inside-54_17491_7825)"
          />
          <mask id="path-122-inside-55_17491_7825" fill="white">
            <path d="M985.545 174.5H1081V206.5H985.545V174.5Z" />
          </mask>
          <path
            d="M986.545 206.5V174.5H984.545V206.5H986.545Z"
            fill="#CAC4D0"
            mask="url(#path-122-inside-55_17491_7825)"
          />
          <mask id="path-124-inside-56_17491_7825" fill="white">
            <path d="M31 206.5H1081V238.5H31V206.5Z" />
          </mask>
          <path
            d="M1081 237.5H31V239.5H1081V237.5Z"
            fill="#CAC4D0"
            mask="url(#path-124-inside-56_17491_7825)"
          />
          <mask id="path-126-inside-57_17491_7825" fill="white">
            <path d="M126.455 206.5H221.91V238.5H126.455V206.5Z" />
          </mask>
          <path
            d="M127.455 238.5V206.5H125.455V238.5H127.455Z"
            fill="#CAC4D0"
            mask="url(#path-126-inside-57_17491_7825)"
          />
          <mask id="path-128-inside-58_17491_7825" fill="white">
            <path d="M221.908 206.5H317.363V238.5H221.908V206.5Z" />
          </mask>
          <path
            d="M222.908 238.5V206.5H220.908V238.5H222.908Z"
            fill="#CAC4D0"
            mask="url(#path-128-inside-58_17491_7825)"
          />
          <mask id="path-130-inside-59_17491_7825" fill="white">
            <path d="M317.363 206.5H412.818V238.5H317.363V206.5Z" />
          </mask>
          <path
            d="M318.363 238.5V206.5H316.363V238.5H318.363Z"
            fill="#CAC4D0"
            mask="url(#path-130-inside-59_17491_7825)"
          />
          <mask id="path-132-inside-60_17491_7825" fill="white">
            <path d="M412.818 206.5H508.273V238.5H412.818V206.5Z" />
          </mask>
          <path
            d="M413.818 238.5V206.5H411.818V238.5H413.818Z"
            fill="#CAC4D0"
            mask="url(#path-132-inside-60_17491_7825)"
          />
          <mask id="path-134-inside-61_17491_7825" fill="white">
            <path d="M508.273 206.5H603.728V238.5H508.273V206.5Z" />
          </mask>
          <path
            d="M509.273 238.5V206.5H507.273V238.5H509.273Z"
            fill="#CAC4D0"
            mask="url(#path-134-inside-61_17491_7825)"
          />
          <mask id="path-136-inside-62_17491_7825" fill="white">
            <path d="M603.727 206.5H699.181V238.5H603.727V206.5Z" />
          </mask>
          <path
            d="M604.727 238.5V206.5H602.727V238.5H604.727Z"
            fill="#CAC4D0"
            mask="url(#path-136-inside-62_17491_7825)"
          />
          <mask id="path-138-inside-63_17491_7825" fill="white">
            <path d="M699.182 206.5H794.636V238.5H699.182V206.5Z" />
          </mask>
          <path
            d="M700.182 238.5V206.5H698.182V238.5H700.182Z"
            fill="#CAC4D0"
            mask="url(#path-138-inside-63_17491_7825)"
          />
          <mask id="path-140-inside-64_17491_7825" fill="white">
            <path d="M794.637 206.5H890.091V238.5H794.637V206.5Z" />
          </mask>
          <path
            d="M795.637 238.5V206.5H793.637V238.5H795.637Z"
            fill="#CAC4D0"
            mask="url(#path-140-inside-64_17491_7825)"
          />
          <mask id="path-142-inside-65_17491_7825" fill="white">
            <path d="M890.092 206.5H985.546V238.5H890.092V206.5Z" />
          </mask>
          <path
            d="M891.092 238.5V206.5H889.092V238.5H891.092Z"
            fill="#CAC4D0"
            mask="url(#path-142-inside-65_17491_7825)"
          />
          <mask id="path-144-inside-66_17491_7825" fill="white">
            <path d="M985.545 206.5H1081V238.5H985.545V206.5Z" />
          </mask>
          <path
            d="M986.545 238.5V206.5H984.545V238.5H986.545Z"
            fill="#CAC4D0"
            mask="url(#path-144-inside-66_17491_7825)"
          />
        </g>
        <rect
          x="29.5"
          y="78"
          width="1052"
          height="192"
          fill="url(#paint0_linear_17491_7825)"
        />
        <defs>
          <filter
            id="filter0_d_17491_7825"
            x="0"
            y="0.5"
            width="1112"
            height="254"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="-15" />
            <feGaussianBlur stdDeviation="15" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_17491_7825"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_17491_7825"
              result="shape"
            />
          </filter>
          <linearGradient
            id="paint0_linear_17491_7825"
            x1="555.5"
            y1="78"
            x2="555.5"
            y2="231.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FBF8FC" stopOpacity="0" />
            <stop offset="1" stopColor="#FBF8FC" />
          </linearGradient>
        </defs>
      </svg>
    </StyledCsvTableIcon>
  );
};

const StyledCsvTableIcon = styled.svg`
  margin: ${(props) =>
    `${props.theme.margin[72]} ${props.theme.margin[112]} ${props.theme.margin[16]} ${props.theme.margin[112]}`};
  width: 1112px;
  height: 270px;
`;

export default CsvTableIcon;
