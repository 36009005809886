import { Reducer } from 'redux';

import { ID } from '@customtypes/general';

import { APIPurchaseInvoiceAttachmentFile } from '../../types/api';

import { Selector } from './utils';
import { BackendError } from '../../utils/api';
import { updateRecord } from '../../utils/general';
import * as remoteData from '../../utils/remoteData';

import { ActionTypes } from '../actionTypes';

type InvoiceAttachmentFilesState = Record<ID, APIPurchaseInvoiceAttachmentFile>;

type GetInvoiceImageRequestsState = Partial<
  Record<string, remoteData.RemoteData<string, BackendError | undefined>>
>;

type InvoiceFilesState = {
  invoiceAttachments: InvoiceAttachmentFilesState;
  invoiceImageRequests: GetInvoiceImageRequestsState;
};

const initialState = { invoiceAttachments: {}, invoiceImageRequests: {} };

const invoiceHeaderAttachmentFilesReducer: Reducer<
  InvoiceFilesState,
  ActionTypes
> = (state = initialState, action): InvoiceFilesState => {
  switch (action.type) {
    case 'GET_INVOICE_ATTACHMENT_FILES_SUCCESS': {
      const newState = updateRecord(state.invoiceAttachments, action.payload);

      return { ...state, invoiceAttachments: newState };
    }
    case 'POST_INVOICE_ATTACHMENT_FILES_SUCCESS': {
      const newState = action.payload.purchaseInvoiceAttachmentFiles
        ? updateRecord(
            state.invoiceAttachments,
            action.payload.purchaseInvoiceAttachmentFiles
          )
        : state.invoiceAttachments;

      return { ...state, invoiceAttachments: newState };
    }
    case 'GET_INVOICE_IMAGE_FILE_STARTED': {
      const { invoiceHeaderId } = action.payload;

      const newState = {
        ...state.invoiceImageRequests,
        [invoiceHeaderId]: remoteData.loading,
      };

      return {
        ...state,
        invoiceImageRequests: newState,
      };
    }

    case 'GET_INVOICE_IMAGE_FILE_SUCCESS': {
      const { invoiceHeaderId, imageUrl } = action.payload;

      const newState = {
        ...state.invoiceImageRequests,
        [invoiceHeaderId]: remoteData.succeed(imageUrl),
      };

      return {
        ...state,
        invoiceImageRequests: newState,
      };
    }

    case 'GET_INVOICE_IMAGE_FILE_FAILURE': {
      const { invoiceHeaderId } = action.payload;

      const newState = {
        ...state.invoiceImageRequests,
        [invoiceHeaderId]: remoteData.fail(action.payload.error),
      };

      return {
        ...state,
        invoiceImageRequests: newState,
      };
    }

    case 'DELETE_INVOICE_ATTACHMENT_FILE_STARTED': {
      const { invoiceHeaderId } = action.payload;

      return {
        ...state,
        invoiceImageRequests: {
          ...state.invoiceImageRequests,
          [invoiceHeaderId]: remoteData.loading,
        },
      };
    }

    case 'DELETE_INVOICE_ATTACHMENT_FILE_SUCCESS': {
      const {
        invoiceHeaderId,
        purchaseInvoiceAttachmentFiles: updatedInvoiceAttachmentFiles = [],
      } = action.payload;

      if (updatedInvoiceAttachmentFiles.length === 0) {
        return state;
      }

      const data = { ...state.invoiceAttachments };
      updatedInvoiceAttachmentFiles.forEach((attachement) => {
        const { id, isDeleted } = attachement;

        if (isDeleted) {
          delete data[id];
        } else {
          data[id] = attachement;
        }
      });

      return {
        ...state,
        invoiceAttachments: data,
        invoiceImageRequests: {
          ...state.invoiceImageRequests,
          [invoiceHeaderId]: remoteData.succeed(''),
        },
      };
    }

    case 'DELETE_INVOICE_ATTACHMENT_FILE_FAILURE': {
      const { invoiceHeaderId } = action.payload;

      const newState = {
        ...state.invoiceImageRequests,
        [invoiceHeaderId]: remoteData.fail(action.payload.error),
      };

      return {
        ...state,
        invoiceImageRequests: newState,
      };
    }

    default: {
      return state;
    }
  }
};

export function getInvoiceHeaderImageRequest(
  invoiceHeaderId: string
): Selector<remoteData.RemoteData<string>> {
  return ({
    invoiceAttachmentFiles: {
      invoiceImageRequests: { [invoiceHeaderId]: request },
    },
  }) => request ?? remoteData.notAsked;
}

export function getInvoiceAttachmentFileDeleteRequest(
  invoiceHeaderId: string
): Selector<remoteData.RemoteData<string>> {
  return ({
    invoiceAttachmentFiles: {
      invoiceImageRequests: { [invoiceHeaderId]: request },
    },
  }) => request ?? remoteData.notAsked;
}

export function getDeletableInvoiceHeaderAttachmentFiles(
  invoiceHeaderId: string
): Selector<APIPurchaseInvoiceAttachmentFile[]> {
  return ({ invoiceAttachmentFiles: { invoiceAttachments } }) => {
    return Object.values(invoiceAttachments).filter(
      (attachment) =>
        attachment.purchaseInvoiceHeaderId === invoiceHeaderId &&
        attachment.userCanDelete
    );
  };
}

export default invoiceHeaderAttachmentFilesReducer;
