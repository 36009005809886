import * as React from 'react';

import Big from 'big.js';

import { getAnalysisRow } from '../../../../store/reducers/analysis/row';

import { requestAnalysisRows } from '../../../../store/actions';

import useRemoteData from '../../../../hooks/useRemoteData';
import useRouteParams from '../../../../hooks/useRouteParams';

import Modal from '../../../../components/Modal/Modal';
import Txt from '../../../../components/Txt';

import { priceFormat } from '../../../../utils/big';

import {
  Container,
  StyledContent,
  StyledHeader,
  SumRow,
} from '../ModalComponents';
import { InspectionTable } from './Table';

type PaymentProgramRowAnalysisModalProps = {
  analysisRowId: string;
  onClose: () => void;
};

export const PaymentProgramRowAnalysisModal = ({
  analysisRowId,
  onClose,
}: PaymentProgramRowAnalysisModalProps) => {
  const { projectId } = useRouteParams();

  const analysisRow = useRemoteData(
    getAnalysisRow({ projectId, analysisRowId }),
    requestAnalysisRows({ projectId })
  );

  const sum = priceFormat(analysisRow?.paymentProgramRowsAmount || new Big(0));

  return (
    <Modal onClose={onClose}>
      <Container>
        <StyledHeader>
          <Txt
            id="analysis.paymentProgramRow.modal.title"
            values={{
              analysisRowCode: analysisRow?.code ?? '',
              analysisRowName: analysisRow?.value,
            }}
          />
        </StyledHeader>
        <StyledContent>
          <InspectionTable analysisRowId={analysisRowId} />
          <SumRow>
            <Txt
              id="analysis.modal.sum"
              values={{ sum: sum.toString() }}
              component="b"
            />
          </SumRow>
        </StyledContent>
      </Container>
    </Modal>
  );
};
