import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';

import {
  APIUpdatedEntities,
  PutActualCostLinesConvertBody,
  RawAPIUpdatedEntities,
} from '../../types/api';
import { ID } from '../../types/general';
import { mapRawUpdatedEntities } from '../../types/mappers';

import { ExtractActionTypes, makeAction } from '../../utils/actionCreators';
import {
  GET,
  BackendError,
  apiErrorHandlingWithDecode,
  PUT,
} from '../../utils/api';
import { dateString, bigString } from '../../utils/decoders';
import { flow } from '../../utils/function';
import * as remoteData from '../../utils/remoteData';
import { createAsyncThunk, Thunk } from '../../utils/thunk';

import {
  selectActualCostDetailLinesRequests,
  ActualCostLine,
} from '../reducers/actualCostLine';

const actionCreators = {
  ...makeAction('getActualCostLinesStarted')<{ actualCostId: string }>(),
  ...makeAction('getActualCostLinesFailure')<{
    actualCostId: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('getActualCostLinesSuccess')<{
    actualCostId: string;
    actualCostLines: ActualCostLine[];
  }>(),
  ...makeAction('putActualCostLinesConvertStarted')<{
    requestId: string;
  }>(),
  ...makeAction('putActualCostLinesConvertFailure')<{
    requestId: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('putActualCostLinesConvertSuccess')<
    {
      requestId: string;
      actualCostId: string;
    } & APIUpdatedEntities
  >(),
  ...makeAction('moveActualCostLineSuccess')<{
    onClick: () => void;
    topicName: string | undefined;
  }>(),
};

export type ActualCostLineAction = ExtractActionTypes<typeof actionCreators>;
export const {
  getActualCostLinesStarted,
  getActualCostLinesSuccess,
  getActualCostLinesFailure,
  putActualCostLinesConvertStarted,
  putActualCostLinesConvertSuccess,
  putActualCostLinesConvertFailure,
  moveActualCostLineSuccess,
} = actionCreators;

const apiActualCostLineType = t.exact(
  t.type({
    id: t.string,
    actualCostsDetailLineExternalId: t.string,
    actualCostsEntryNo: t.string,
    orderRowId: t.union([t.string, t.null]),
    arrivalRowId: t.union([t.string, t.null]),
    accountCode: t.union([t.string, t.null]),
    costControlItemCode: t.union([t.string, t.null]),
    costType: t.union([t.string, t.null]),
    date: dateString,
    description: t.union([t.string, t.null]),
    quantity: bigString,
    unit: t.string,
    unitPrice: bigString,
    createdAt: dateString,
    updatedAt: dateString,
  })
);

export async function toActualCostLines(u: unknown): Promise<ActualCostLine[]> {
  const apiActualCostLines = await tPromise.decode(
    t.array(apiActualCostLineType),
    u
  );

  return apiActualCostLines;
}

async function getDetailLinesForActualCost(
  actualCostId: ID | null
): Promise<ActualCostLine[]> {
  if (actualCostId === null) {
    return [];
  }

  const response = await GET(`v1/actual-costs/${actualCostId}/detail-lines`);

  return toActualCostLines(response);
}

export const fetchDetailLinesForActualCost = (
  actualCostId: ID | null
): Thunk => {
  const actualCostIdString = actualCostId === null ? 'NA' : actualCostId;

  return createAsyncThunk(getDetailLinesForActualCost, {
    args: [actualCostId],
    isPending: flow(
      selectActualCostDetailLinesRequests(
        actualCostId !== null ? actualCostId : 'NA'
      ),
      remoteData.isLoading
    ),
    initialAction: getActualCostLinesStarted({
      actualCostId: actualCostIdString,
    }),
    successActionCreator: (actualCostLines) =>
      getActualCostLinesSuccess({
        actualCostId: actualCostIdString,
        actualCostLines,
      }),
    failureActionCreator: (error) =>
      getActualCostLinesFailure({
        actualCostId: actualCostIdString,
        error: apiErrorHandlingWithDecode(error),
      }),
  });
};

export const convertActualCostLines =
  (
    actualCostId: ID,
    requestId: string,
    body: PutActualCostLinesConvertBody
  ): Thunk =>
  (dispatch, _) => {
    dispatch(putActualCostLinesConvertStarted({ requestId }));

    PUT<RawAPIUpdatedEntities>(
      `v1/actual-costs/${actualCostId}/actual-cost-lines-convert`,
      body
    )
      .then(mapRawUpdatedEntities)
      .then(
        (updatedEntities) => {
          dispatch(
            putActualCostLinesConvertSuccess({
              requestId,
              actualCostId,
              ...updatedEntities,
            })
          );
        },
        (error) => {
          dispatch(
            putActualCostLinesConvertFailure({
              requestId,
              error: apiErrorHandlingWithDecode(error),
            })
          );
        }
      );
  };

export const moveActualCostLineNotification =
  (onClick: () => void, topicName?: string): Thunk =>
  (dispatch, _) => {
    dispatch(moveActualCostLineSuccess({ onClick, topicName }));
  };
